import { useLocation } from "react-router";
import useMaintanance from "./useMaintanance";
import { Calender } from "../../../../../assets/images";
import { Loading } from "../../../../../assets/images";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const MaintananceTab = ({ tab, tabId, t }) => {
  const location = useLocation();
  const {
    loader,
    kmsAtLastService,
    serviceIntervalKm,
    maintananceLastServiceDate,
    maintananceNextServiceDate,
    oilServiceDate,
    amount,
    invoiceNumber,
    description,
    kmReading,
    oilServiceNextServiceDate,
    garage,
    paymentMethod,
    maintainenceExpenseDate,
    maintainenceExpenseInvoice,
    maintainenceExpenseDescription,
    maintainenceExpenseKmReading,
    maintainenceExpenseGarage,
    maintainenceExpensePaymentMethod,
    maintainenceExpenseServiceType,
    maintainenceExpenseAmount,
    checkRes,
    submit,
    id,
    totalPages1,
    page1,
    oilListData,
    totalPages2,
    page2,
    maintainanceListData,
    oilExpenseId,
    maintExpenseId,
    submitMaint,
    paymentTypeArray,
    vehicleExpenseArray,
    listTable1,
    listTable2,
    columns,
    columns1,
    handleOilExpensePageClick,
    handleMaintainanceExpense,
    handleOilMaintainance,
    handleKmsAtLastService,
    handleServiceIntervalKm,
    handleMaintananceLastServiceDate,
    handleMaintananceNextServiceDate,
    handleOilServiceDate,
    handleOilAmount,
    handleOilInvoiceNumber,
    handleOilDescription,
    handleOilKmReading,
    handleOilServiceNextServiceDate,
    handleOilGarage,
    handleOilPayment,
    handleMaintainenceExpenseDate,
    handleMaintainenceExpenseInvoice,
    handleMaintainenceExpenseDescription,
    handleMaintainenceExpenseKmReading,
    handleMaintainenceExpenseGarage,
    handleMaintainenceExpensePaymentMethod,
    handleMaintainenceExpenseServiceType,
    handleMaintainanceAmount,
    handleMaintainance,
    handleEditMaintainance,
    handleMaintainanceExpensePageClick,
    handleRowOilExpense,
    handleRowMaintainance,
    handleEditOilMaintainance,
    handleEditMaintainanceExpense,
  } = useMaintanance(location, tab, tabId);

  //oil expense list

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const maintDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
            <>
              <div className="E_comp_details12 flt">
                <div className="main_boxShadow flt">
                  <div className="Company_name flt">
                    <div className="comp_name_details flt">
                      {t("Maintenance")}
                    </div>
                  </div>
                  <div className="main_company flt">
                    <div className="main_row flt">
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Kms_Service")}
                              </label>
                              <input
                                type="number"
                                placeholder="Kms at last Service"
                                name="kmsAtLastService"
                                id="kmsAtLastService"
                                onChange={handleKmsAtLastService}
                                value={kmsAtLastService}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Service_Interval_Km")}
                              </label>
                              <input
                                type="number"
                                name="serviceIntervalKm"
                                id="serviceIntervalKm"
                                onChange={handleServiceIntervalKm}
                                value={serviceIntervalKm}
                                placeholder=" Service Interval Km"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className=" company_name vehicle_maintanance flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Last_Service_Date")}
                              </label>
                              <div className="make_img flt">
                                <Datetime
                                  dateFormat="DD/MM/YYYY"
                                  onChange={handleMaintananceLastServiceDate}
                                  value={maintananceLastServiceDate}
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  inputProps={{
                                    placeholder: "Select From date",
                                    disabled: false,
                                    readOnly: true,
                                    onKeyDown: (e) => e.preventDefault(),
                                  }}
                                />
                                <div className="cal_img">
                                  <img src={Calender} alt="calender" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className=" company_name vehicle_ntservice flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Next_Service_Date")}
                              </label>
                              <div className="make_img flt">
                                <Datetime
                                  dateFormat="DD/MM/YYYY"
                                  onChange={handleMaintananceNextServiceDate}
                                  value={maintananceNextServiceDate}
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  inputProps={{
                                    placeholder: "Select From date",
                                    disabled: false,
                                    readOnly: true,
                                    onKeyDown: (e) => e.preventDefault(),
                                  }}
                                />
                                <div className="cal_img">
                                  <img src={Calender} alt="calender" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="main_ownbutton flt">
                        <div className="own_button flt">
                          {checkRes === false && (
                            <button
                              className="submitbutton"
                              onClick={(e) => handleMaintainance(e, tabId)}
                            >
                              {t("Save")}
                            </button>
                          )}
                          {checkRes === true && (
                            <button
                              className="submitbutton"
                              onClick={(e) => handleEditMaintainance(e, tabId)}
                            >
                              {t("Update")}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Maintenance end  */}

              {/* Oil services start */}
              <div className="E_comp_details12 flt">
                <div className="main_boxShadow flt">
                  <div classN ame="main_emission flt">
                    <div className="Company_name flt">
                      <div className="comp_name_details flt">
                        {t("Oil_Service_Expense")}
                      </div>
                    </div>
                    <div className="main_company flt">
                      <div className="main_row flt">
                        <div className="row">
                          <div className="col-sm-3">
                            <div className=" company_name vehicle_oilservice flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Date")} *</label>
                                <div className="make_img flt">
                                  <Datetime
                                    dateFormat="DD/MM/YYYY"
                                    onChange={handleOilServiceDate}
                                    value={oilServiceDate}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    inputProps={{
                                      placeholder: "Select From date",
                                      disabled: false,
                                      readOnly: true,
                                      onKeyDown: (e) => e.preventDefault(),
                                    }}
                                  />
                                  <div className="cal_img">
                                    <img src={Calender} alt="calender" />
                                  </div>
                                </div>
                                {submit && !oilServiceDate && (
                                  <div className="req_field">
                                    {t("Date_required")}
                                  </div>
                                )}
                              </div>
                            </div>

                          </div>
                          <div className="col-sm-3">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Amount")}</label>
                                <input
                                  type="number"
                                  placeholder="Amount"
                                  name="amount"
                                  id="amount"
                                  onChange={handleOilAmount}
                                  value={amount}
                                />
                              </div>
                            </div>


                          </div>

                          <div className="col-sm-3">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">
                                  {t("Ref_Invoice_No")}
                                </label>
                                <input
                                  className="comp_input"
                                  type="text"
                                  placeholder="Ref / Invoice No#"
                                  name="invoiceNumber"
                                  id="invoiceNumber"
                                  onChange={handleOilInvoiceNumber}
                                  value={invoiceNumber}
                                />
                              </div>
                            </div>

                          </div>
                          <div className="col-sm-3">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Garage")}</label>
                                <input
                                  type="text"
                                  placeholder="Garage"
                                  name="garage"
                                  id="garage"
                                  onChange={handleOilGarage}
                                  value={garage}
                                />
                              </div>
                            </div>


                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3"> <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Description")}
                              </label>
                              <input
                                type="text"
                                placeholder="Description"
                                name="description"
                                id="description"
                                onChange={handleOilDescription}
                                value={description}
                              />
                            </div>
                          </div></div>
                          <div className="col-sm-3"> <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Km_Reading")}
                              </label>
                              <input
                                className="comp_input"
                                type="number"
                                placeholder="Km Reading"
                                name="kmReading"
                                id="kmReading"
                                onChange={handleOilKmReading}
                                value={kmReading}
                              />
                            </div>
                          </div></div>
                          <div className="col-sm-3"><div className=" company_name vehicle_oilservice flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Next_Service_Date")}
                              </label>
                              <div className="make_img flt">
                                <Datetime
                                  dateFormat="DD/MM/YYYY"
                                  onChange={handleOilServiceNextServiceDate}
                                  value={oilServiceNextServiceDate}
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  inputProps={{
                                    placeholder: "Select From date",
                                    disabled: false,
                                    readOnly: true,
                                    onKeyDown: (e) => e.preventDefault(),
                                  }}
                                />
                                <div className="cal_img">
                                  <img src={Calender} alt="calender" />
                                </div>
                              </div>
                            </div>
                          </div></div>
                          <div className="col-sm-3"><div className=" company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Payment_Method")}
                              </label>
                              <select
                                name="paymentMethod"
                                id="paymentMethod"
                                onChange={handleOilPayment}
                                value={paymentMethod}
                              >
                                <option value="">
                                  {t("Select_Payment_Method")}
                                </option>
                                {paymentTypeArray &&
                                  paymentTypeArray.map((pay) => (
                                    <option>{pay.paymentType}</option>
                                  ))}
                              </select>
                            </div>
                          </div>
                            <div className="main_ownbutton flt">
                              {oilExpenseId === "" && (
                                <div className="own_button flt">
                                  <button
                                    className="submitbutton"
                                    onClick={(e) =>
                                      handleOilMaintainance(e, tabId)
                                    }
                                  >
                                    {t("Save")}
                                  </button>
                                </div>
                              )}
                              {oilExpenseId !== "" && (
                                <div className="own_button flt">
                                  <button
                                    className="submitbutton"
                                    onClick={(e) => handleEditOilMaintainance(e)}
                                  >
                                    {t("Update")}
                                  </button>
                                </div>
                              )}
                            </div></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="main_general flt">
                    <div className="main_location flt">
                      <div className="location flt">
                        {t("OIL_SERVICE_History")}
                      </div>
                    </div>
                  </div>
                  <div className="E_comp_details flt">
                    <div className="main_boxShadow flt">
                      <div className="main_tablehead flt">
                        <table className="main_table1 flt ">
                          {oilListData?.data?.data?.docs?.length !== 0 ? (
                            <DataTable
                              columns={columns}
                              data={listTable1 || []}
                              customStyles={customStyles}
                              onRowClicked={(row) => handleRowOilExpense(row)}
                            />
                          ) : (
                            <div className="no-data">{t("No_Data_Found")}</div>
                          )}
                        </table>
                        {oilListData?.data?.data?.docs?.length !== 0 && (
                          <div>
                            <ReactPaginate
                              nextLabel="&rarr;"
                              onPageChange={handleOilExpensePageClick}
                              pageRangeDisplayed={1}
                              marginPagesDisplayed={1}
                              pageCount={totalPages1}
                              previousLabel="&larr;"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              currentPage={page1}
                              // forcePage={page1 - 1}
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* oil services end  */}

              {/* Maintainence Expense start */}
              <div className="E_comp_details12 flt">
                <div className="main_boxShadow flt">
                  <div classN ame="main_emission flt">
                    <div className="Company_name flt">
                      <div className="comp_name_details flt">
                        {t("Maintenance_Expense")}
                      </div>
                    </div>
                    <div className="main_company flt">
                      <div className="main_row flt">
                        <div className="row">
                          <div className="col-sm-3">
                            <div className=" company_name vehicle_maintanance flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Date")} *</label>
                                <div className="make_img flt">
                                  <Datetime
                                    dateFormat="DD/MM/YYYY"
                                    onChange={handleMaintainenceExpenseDate}
                                    value={maintainenceExpenseDate}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    inputProps={{
                                      placeholder: "Select From date",
                                      disabled: false,
                                      readOnly: true,
                                      onKeyDown: (e) => e.preventDefault(),
                                    }}
                                  />
                                  <div className="cal_img">
                                    <img src={Calender} alt="calender" />
                                  </div>
                                </div>
                                {submitMaint && !maintainenceExpenseDate && (
                                  <div className="req_field">
                                    {t("Date_required")}
                                  </div>
                                )}
                              </div>
                            </div>

                          </div>
                          <div className="col-sm-3">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">
                                  {t("Service_Type")}
                                </label>
                                <select
                                  name="maintainenceExpenseServiceType"
                                  id="maintainenceExpenseServiceType"
                                  onChange={handleMaintainenceExpenseServiceType}
                                  value={maintainenceExpenseServiceType}
                                >
                                  <option value="">
                                    {t("Select_Service_Type")}
                                  </option>
                                  {vehicleExpenseArray &&
                                    vehicleExpenseArray.map((expense) => (
                                      <option>{expense.expenseType}</option>
                                    ))}
                                </select>
                              </div>
                            </div>


                          </div>

                          <div className="col-sm-3">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Amount")}</label>
                                <input
                                  className="comp_input"
                                  type="number"
                                  placeholder="Amount"
                                  name="maintainenceExpenseAmount"
                                  id="maintainenceExpenseAmount"
                                  onChange={handleMaintainanceAmount}
                                  value={maintainenceExpenseAmount}
                                />
                              </div>
                            </div>

                          </div>
                          <div className="col-sm-3">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Garage")}</label>
                                <input
                                  type="text"
                                  placeholder="Garage"
                                  name="maintainenceExpenseGarage"
                                  id="maintainenceExpenseGarage"
                                  onChange={handleMaintainenceExpenseGarage}
                                  value={maintainenceExpenseGarage}
                                />
                              </div>
                            </div>


                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3"> <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Ref_Invoice_No")}
                              </label>
                              <input
                                type="text"
                                placeholder="Ref / Invoice no"
                                name="maintainenceExpenseInvoice"
                                id="maintainenceExpenseInvoice"
                                onChange={handleMaintainenceExpenseInvoice}
                                value={maintainenceExpenseInvoice}
                              />
                            </div>
                          </div></div>
                          <div className="col-sm-3"> <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Description")}
                              </label>
                              <input
                                className="comp_input"
                                type="text"
                                placeholder="Description"
                                name="maintainenceExpenseDescription"
                                id="maintainenceExpenseDescription"
                                onChange={handleMaintainenceExpenseDescription}
                                value={maintainenceExpenseDescription}
                              />
                            </div>
                          </div></div>
                          <div className="col-sm-3"> <div className=" company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Km_Reading")}
                              </label>
                              <div className="make_img flt">
                                <input
                                  type="number"
                                  placeholder="Km Reading"
                                  id="maintainenceExpenseKmReading"
                                  name="maintainenceExpenseKmReading"
                                  onChange={handleMaintainenceExpenseKmReading}
                                  value={maintainenceExpenseKmReading}
                                ></input>
                              </div>
                            </div>
                          </div></div>
                          <div className="col-sm-3">  <div className=" company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Payment Method")}
                              </label>
                              <select
                                name="maintainenceExpensePaymentMethod"
                                id="maintainenceExpensePaymentMethod"
                                onChange={
                                  handleMaintainenceExpensePaymentMethod
                                }
                                value={maintainenceExpensePaymentMethod}
                              >
                                <option value="">
                                  {t("Select_Payment_Method")}
                                </option>
                                {paymentTypeArray &&
                                  paymentTypeArray.map((pay) => (
                                    <option>{pay.paymentType}</option>
                                  ))}
                              </select>
                            </div>
                          </div>
                            <div className="main_ownbutton flt">
                              {maintExpenseId === "" && (
                                <div className="own_button flt">
                                  <button
                                    className="submitbutton"
                                    onClick={(e) =>
                                      handleMaintainanceExpense(e, tabId)
                                    }
                                  >
                                    {t("Save")}
                                  </button>
                                </div>
                              )}
                              {maintExpenseId !== "" && (
                                <div className="own_button flt">
                                  <button
                                    className="submitbutton"
                                    onClick={(e) =>
                                      handleEditMaintainanceExpense(e)
                                    }
                                  >
                                    {t("Update")}
                                  </button>
                                </div>
                              )}
                            </div></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="main_general flt">
                    <div className="main_location flt">
                      <div className="location flt">
                        {t("Maintenance_EXPENSE_History")}
                      </div>
                    </div>
                  </div>
                  <div className="E_comp_details flt">
                    <div className="main_boxShadow flt">
                      <div className="main_tablehead flt">
                        <table className="main_table1 flt ">
                          {maintainanceListData?.data?.data?.docs?.length !==
                            0 ? (
                            <DataTable
                              columns={columns1}
                              data={listTable2 || []}
                              customStyles={customStyles}
                              onRowClicked={(row) => handleRowMaintainance(row)}
                            />
                          ) : (
                            <div className="no-data">{t("No_Data_Found")}</div>
                          )}
                        </table>
                        {maintainanceListData?.data?.data?.docs?.length !== 0 && (
                          <div>
                            <ReactPaginate
                              nextLabel="&rarr;"
                              onPageChange={handleMaintainanceExpensePageClick}
                              pageRangeDisplayed={1}
                              marginPagesDisplayed={1}
                              pageCount={totalPages2}
                              previousLabel="&larr;"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              currentPage={page2}
                              // forcePage={page1 - 1}
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="main_ownbutton flt">
                    <div className="own_button flt">
                      {(checkRes === true || id !== "") && (
                        <button className="backbutton" onClick={() => tab(2)}>
                          {t("Back")}
                        </button>
                      )}

                      <button className="backbutton" onClick={() => tab(4)}>
                        {t("Continue")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </>
    );
  };
  return <>{maintDetails()}</>;
};
export default MaintananceTab;
