import * as axios from "../network/Network";

const config = require("../network/api.json");

class DayToDayService {
  /**
   * Add day to day task
   * @param {*} date 
   * @param {*} vehicleName 
   * @param {*} description 
   * @param {*} assignedTo 
   * @param {*} completed 
   * @returns 
   */
  static addDayTask(
    date,
    vehicleName,
    description,
    assignedTo,
    completed
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addDayTask,
      data: {
        date,
        vehicleName,
        description,
        assignedTo,
        completed,
      },
    });
    return Promise.resolve(result);
  }


  /**
   * Edit day to day task
   * @param {*} id 
   * @param {*} date 
   * @param {*} vehicleName 
   * @param {*} description 
   * @param {*} assignedTo 
   * @param {*} completed 
   * @returns 
   */
  static editDayTask(
    id,
    date,
    vehicleName,
    description,
    assignedTo,
    completed
  ) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editDayTask + `/${id}`,
      data: {
        date,
        vehicleName,
        description,
        assignedTo,
        completed,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * Get dashboard count
   * @param {*} id 
   * @returns 
   */
  static getDashboardCounts(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getDashboardCounts + `?vehicleClassId=${id}`,
    });
    return Promise.resolve(result);
  }


  /**
 * Get pending payments in dashboard
 * @param {*} id
 * @returns
 */

  //new api for getPending payments in Dashboard
  static getPendingPayments(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getDashboardPayments + `?vehicleClassId=${id}`,
    })
    return Promise.resolve(result);

  }

  /**
   * get day to day details
   * @param {*} page 
   * @param {*} value 
   * @param {*} search 
   * @returns 
   */
  static getDaytoDayDetails(page, value, search) {
    const result = axios.appRequest({
      method: axios.GET,
      url:
        config.user.getDaytoDayDetails +
        `?page=${page}&searchby=${value}&value=${search}`,
    });
    return Promise.resolve(result);
  }

  /**
   * delete daytoday task
   * @param {*} id 
   * @returns 
   */
  static deleteDayTasks(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deletedaytask + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * Get vehicle name
   * @returns 
   */
  static getVehicleName() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getVehicleName,
    });
    return Promise.resolve(result);
  }

  /**
   * delete daytotask record
   * @param {*} id 
   * @returns 
   */
  static deleteDayToDayTask(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deleteDayToDayTask + `/${id}`,
    });
    return Promise.resolve(result);
  }
}
export { DayToDayService };
