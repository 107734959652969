import Menu from "../../../_common/menu/Menu";
import TopBar from "../../../_common/topbar/TopBar";
import sideday from "../../../../assets/images/Sidedaytask.png";
import { Calender, Loading } from "../../../../assets/images";
import { addtask } from "../../../../assets/images";
import "../Daytask.css";
import useDayToDayDetailsHook from "./useDayToDayDetailsHook";
import { useLocation } from "react-router";
import { withTranslation } from "react-i18next";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

function AddDaytaskDetail(props) {
  const { t } = props;
  const location = useLocation();

  const {
    loader,
    date,
    assignedTo,
    description,
    vehicleName,
    submit,
    vehicleType,
    completed,
    dayId,
    handleDate,
    handleVehicleName,
    handleDescription,
    handleAssignedTo,
    handleCompleted,
    handleSubmit,
    navigateToGetTask,
    handleEditSubmit,
    handleDeleteSubmit,
  } = useDayToDayDetailsHook(location);

  const daytodayTaskDetails = () => {
    return (
      <>
        <div className="main_location flt">
          <div className="location flt">
            <img src={sideday} alt="sideday" />
            {t("Day to Day Task")}
          </div>
        </div>

        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="Company_name flt">
              {dayId === "" && (
                <div className="comp_name_details flt">
                  <img src={addtask} alt="img avilable" />
                  {t("Add Task")}
                </div>
              )}
              {dayId !== "" && (
                <>
                  <div className="comp_name_details flt">
                    <img src={addtask} alt="img avilable" />
                    {t("Edit_Task")}
                  </div>
                  <div className="delete_b flt">
                    <button className="delete_btn" onClick={handleDeleteSubmit}>
                      {t("Delete")}
                    </button>
                  </div>
                </>
              )}
            </div>
            {loader === "loading" && (
              <div className="main_load ">
                <img src={Loading} alt="loader" />
              </div>
            )}
            {(loader === "isfull" ||
              loader === "isempty" ||
              loader === "notset") && (
                <>
                  <div className="main_company flt">
                    <div className="main_row flt">
                      <div className="row">
                        <div className="col-sm-3">
                          <div className=" company_name company_nameddtask flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Start_Date")} *
                              </label>

                              <Datetime
                                dateFormat="DD/MM/YYYY"
                                onChange={handleDate}
                                value={new Date(date)}
                                closeOnSelect={true}
                                timeFormat={false}
                                inputProps={{
                                  placeholder: "Select From date",
                                  disabled: false,
                                  readOnly: true,
                                  onKeyDown: (e) => e.preventDefault(),
                                }}
                              />
                              <div className="cal_img">
                                <img src={Calender} alt="calender" />
                              </div>

                              {submit && !date && (
                                <div className="req_field">
                                  {t("Date_required")}
                                </div>
                              )}
                            </div>


                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Vehicle_Plate_Number")} *
                              </label>
                              <select
                                name="vehicleName"
                                id="vehicleName"
                                placeholder={t("Vehicle_type")}
                                onChange={handleVehicleName}
                                value={vehicleName}
                              >
                                <option value="">
                                  {t("Select_Vehicle_Plate_Number")}
                                </option>
                                {vehicleType &&
                                  vehicleType.map(({ _id, regNumber }) => (
                                    <option value={_id}>{regNumber}</option>
                                  ))}
                              </select>

                              {submit && !vehicleName && (
                                <div className="req_field">
                                  {t("Vehicle_Plate_Number_required")}
                                </div>
                              )}
                            </div>
                          </div>

                        </div>
                        <div className="col-sm-3">
                          <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Description")} *
                              </label>
                              <input
                                type="text"
                                placeholder={t("Description")}
                                name="description"
                                id="description"
                                onChange={handleDescription}
                                value={description}
                              />
                              {submit && !description && (
                                <div className="req_field">
                                  {t("Description_required")}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>


                      </div>

                      <div className="row">
                        <div className="col-sm-3">
                          <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Assigned_To")} *
                              </label>
                              <input
                                name="assignedTo"
                                id="assignedTo"
                                placeholder={t("Assigned_To")}
                                onChange={handleAssignedTo}
                                value={assignedTo}
                              />
                              {submit && !assignedTo && (
                                <div className="req_field">
                                  {t("Assignee_required")}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Task_Completed")}
                              </label>
                              <div className="daily_basis flt">
                                <ul
                                  className="basis_price"
                                  onChange={handleCompleted}
                                >
                                  <li className="cheak_days">
                                    <input
                                      type="radio"
                                      id="completed"
                                      name="completed"
                                      value="Yes"
                                      checked={completed === "Yes" ? true : false}
                                    />
                                    <label for="completed">{t("YES")}</label>
                                  </li>
                                  <li className="cheak_days">
                                    <input
                                      type="radio"
                                      id="completed"
                                      name="completed"
                                      value="No"
                                      checked={completed === "No" ? true : false}
                                    />
                                    <label for="completed">{t("NO")}</label>
                                  </li>
                                  <li className="cheak_days">
                                    <input
                                      type="radio"
                                      id="completed"
                                      name="completed"
                                      value="inprogress"
                                      checked={
                                        completed === "inprogress" ? true : false
                                      }
                                    />
                                    <label for="completed">
                                      {t("In_Progress")}
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-3"></div>
                      </div>
                      <div className="main_ownbutton flt">
                        <div className="own_button flt">
                          <button
                            className="backbutton"
                            onClick={navigateToGetTask}
                          >
                            {t("Back")}
                          </button>
                          {dayId === "" && (
                            <button
                              className="submitbutton"
                              onClick={handleSubmit}
                            >
                              {t("Submit")}
                            </button>
                          )}
                          {dayId !== "" && (
                            <button
                              className="submitbutton"
                              onClick={handleEditSubmit}
                            >
                              {t("Update")}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      {daytodayTaskDetails()}
    </div>
  );
}
export default withTranslation()(AddDaytaskDetail);
