import { useEffect, useState } from "react";
import {
  AgreementService,
  SetUpService,
} from "../../../../../../../../services";
import { alertActions } from "../../../../../../../../actions";
import { useDispatch } from "react-redux";
import { StartrentService } from "../../../../../../../../services";
import moment from "moment";

const PaymentDetailsHooks = (displayData, cid, bid) => {
  const [NoOfDays, setNoOfDays] = useState(0);
  const [RentalCharges, setRentalCharges] = useState("");
  const [HelmetCharges, setHelmetCharges] = useState("");
  const [LuggageCharges, setLuggageCharges] = useState("");
  const [GrandTotal, setGrandTotal] = useState("");
  const [gst, setgst] = useState("");
  const [TotalRental, setTotalRental] = useState("");
  const [Damages, setDamages] = useState("");
  const [ExKmCharges, setExKmCharges] = useState("");
  const [OtherCharges, setOtherCharges] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [carddate, setCardDate] = useState("");
  const [advanceamount, setadvanceamount] = useState("");
  const [paymentType, setpaymentType] = useState("");
  const [balanceamount, setbalanceamount] = useState("");
  const [paymentdetails, setpaymentdetails] = useState("");
  const [paymentamount, setpaymentamount] = useState("");
  const [cardno, setcardno] = useState("");
  const [cvv, setcvv] = useState("");
  const [expiry, setexpiry] = useState("");
  const [holdername, setholdername] = useState("");
  const [cardType, setcardType] = useState("");
  const [transactionid, settransactionid] = useState("");
  const [fileName1, setFileName1] = useState("");
  const [selectedFile1, setSelectedFile1] = useState("");
  const [paymentImage, setPaymentImage] = useState("");
  const [displayMain, setDisplayMain] = useState(0);
  const [ref_number, setRefNumber] = useState("");
  const [paymentRes, setPaymentRes] = useState("");
  const [loader, setLoader] = useState("notset");
  const [acceptCheck, setAcceptCheck] = useState(false);
  const [gstRate, setGstRate] = useState();
  const [payment_dueDate, setPayment_DueDate] = useState("");
  const [paymentArray, setPaymentArray] = useState([]);
  const [paidAmount, setPaidAmount] = useState(0);
  const [newResponseData, setResponseData] = useState("");
  const bookidpay = bid;
  const dispatch = useDispatch();

  /**
   * handle gst amount
   */
  useEffect(() => {
    handleGstAmount();
    // eslint-disable-next-line
  }, [TotalRental]);

  /**
   * get due list
   */
  useEffect(() => {
    getDueList(bid);
    // eslint-disable-next-line
  }, [bid, displayMain === 0, localStorage.getItem("upState")]);

  /**
   * get payment type
   */
  useEffect(() => {
    AgreementService.getPaymentTypeName()
      .then((response) => {
        setPaymentArray(response);
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   * get info
   */
  useEffect(() => {
    setLoader("loading");
    SetUpService.getGeneralInfo()
      .then((response) => {
        if (response?.data?.data?.docs.length !== 0) {
          setLoader("isfull");
          setGstRate(
            response?.data?.data?.docs?.[0]?.RegionalSettings?.gstRate
          );
        }
      })
      .catch((err) => console.log(err));

    StartrentService.getPayment(bookidpay)
      .then((res) => {
        console.log(res);
        if (res?.data?.status?.code === 0) {
          setLoader("isfull");
          setAcceptCheck(
            res?.data?.data?.acceptCheck ? res?.data?.data?.acceptCheck : false
          );
          setNoOfDays(
            res?.data?.data?.NoOFDays ? res?.data?.data?.NoOFDays : 0
          );
          setRentalCharges(
            res?.data?.data?.RentalCharges ? res?.data?.data?.RentalCharges : 0
          );
          setHelmetCharges(
            res?.data?.data?.HelmetCharges ? res?.data?.data?.HelmetCharges : 0
          );
          setLuggageCharges(
            res?.data?.data?.LagguageCharges
              ? res?.data?.data?.LagguageCharges
              : 0
          );
          setGrandTotal(
            res?.data?.data?.Grand_Total ? res?.data?.data?.Grand_Total : 0
          );
          setgst(res?.data?.data?.GST ? res?.data?.data?.GST : 0);
          setTotalRental(
            res?.data?.data?.TotalRental_othercharges
              ? res?.data?.data?.TotalRental_othercharges
              : 0
          );
          setDamages(res?.data?.data?.Damages ? res?.data?.data?.Damages : 0);
          setExKmCharges(
            res?.data?.data?.ExkmCharges ? res?.data?.data?.ExkmCharges : 0
          );

          setOtherCharges(
            res?.data?.data?.OtherCharges ? res?.data?.data?.OtherCharges : 0
          );
          setPayment_DueDate(
            res?.data?.data?.payment_dueDate
              ? moment(res?.data?.data?.payment_dueDate).format("DD/MM/YYYY")
              : ""
          );
          // setPaidAmount(paymentRes?.data?.[0]?.paidamount ?paymentRes?.data?.[0]?.paidamount:0)
        } else {
          console.log("error");
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);
  console.log(HelmetCharges, "HelmetCharges");
  console.log(RentalCharges, "rrrelmetCharges");

  useEffect(() => {
    AgreementService.no_of_days(bookidpay)
      .then((res) => {
        if (res?.data?.status?.code === 0) {
          setLoader("isfull");
          setNoOfDays(res?.data?.data?.[0]?.noofdays);

          if (res?.data?.data?.[0]?.vehicleCharges.length !== 0) {
            let selectedDay =
              res?.data?.data?.[0]?.vehicleCharges?.[0]?.RatePerDay[
                res?.data?.data?.[0]?.noofdays - 1
              ];
            let selecetdCost = selectedDay?.Cost ? selectedDay?.Cost : 0;
            setResponseData(selecetdCost);
            // setRentalCharges(selecetdCost);
            console.log(paymentRes?.data?.[0]?.paidamount, "oooop");
            if (!paymentRes?.data?.[0]?.paidamount && paymentRes !== "") {
              setRentalCharges(selecetdCost);
            }
            // if (RentalCharges == "") {
            //   setTotalRental(
            //     Number(
            //       Number(
            //         !paymentRes?.data?.[0]?.paidamount
            //           ? Number(Number(0) + Number(selecetdCost))
            //           : Number(
            //               Number(paymentRes?.data?.[0]?.pendingamount) +
            //                 Number(paymentRes?.data?.[0]?.paidamount)
            //             )
            //       )
            //     )
            //   );
            // } else {
            //   if (RentalCharges < selecetdCost) {
            //     setTotalRental(Number(selecetdCost));
            //   } else {
            //     setTotalRental(Number(RentalCharges + selecetdCost));
            //   }
            // }
            // if (selecetdCost < RentalCharges) {
            //   setTotalRental(Number(selecetdCost));
            // } else {
            //   setTotalRental(Number(RentalCharges + selecetdCost));
            // }
          }
        }
      })
      .catch((err) => console.log(err));
  }, [NoOfDays]);

  //new changes
  useEffect(() => {
    let newValue =
      Number(RentalCharges) +
      Number(HelmetCharges) +
      Number(Damages) +
      Number(LuggageCharges) +
      Number(OtherCharges) +
      Number(ExKmCharges);
    if (RentalCharges !== newValue) {
      setTotalRental(newValue);
    } else {
      setTotalRental(RentalCharges);
    }
  }, [RentalCharges, newResponseData]);

  /**
   * image
   */
  useEffect(() => {
    if (!selectedFile1) {
      setPaymentImage(undefined);
      return;
    }

    const objectUrl1 = URL.createObjectURL(selectedFile1);

    setPaymentImage(objectUrl1);
    return () => URL.revokeObjectURL(objectUrl1);
  }, [selectedFile1]);

  /**
   * get due list
   * @param {*} bid
   */
  const getDueList = (bid) => {
    setLoader("loading");
    AgreementService.getDueList(bid)
      .then((response) => {
        setLoader("isfull");
        setPaymentRes(response?.data);
      })
      .catch((err) => console.log(err));
  };

  /**
   * checkbox handler
   * @param {*} e
   */
  function changeAcceptCheckboxHandler(e) {
    setAcceptCheck(!acceptCheck);
    if (e.target.checked === true) {
      setgst(Number((TotalRental * gstRate) / 100));
      var grandDisplay = Number(
        (TotalRental * gstRate) / 100 + TotalRental
      ).toFixed(2);

      setGrandTotal(grandDisplay);
    } else {
      if (e.target.checked === false) {
        setgst("");
        setGrandTotal(Number(TotalRental));
      }
    }
  }

  /**
   * handle due date
   * @param {*} e
   */
  const handlePaymentDueDate = (e) => {
    setPayment_DueDate(moment(e).format("DD/MM/YYYY"));
  };

  const goBack = () => {
    setDisplayMain(0);
  };

  /**
   * handle change
   * @param {*} e
   */
  const handleChange = (e) => {
    var values = 0;
    if (e.target.name === "NoOfDays") {
      setNoOfDays(e.target.value);
    }
    if (e.target.name === "RentalCharges") {
      setTotalRental(
        Number(TotalRental) - Number(RentalCharges) + Number(e.target.value)
      );
      setRentalCharges(e.target.value);
      values =
        Number(TotalRental) - Number(RentalCharges) + Number(e.target.value);
    }
    if (e.target.name === "HelmetCharges") {
      setTotalRental(
        Number(TotalRental) - Number(HelmetCharges) + Number(e.target.value)
      );
      setHelmetCharges(e.target.value);
      values =
        Number(TotalRental) - Number(HelmetCharges) + Number(e.target.value);
    }
    if (e.target.name === "LuggageCharges") {
      setTotalRental(
        Number(TotalRental) - Number(LuggageCharges) + Number(e.target.value)
      );
      setLuggageCharges(e.target.value);
      values =
        Number(TotalRental) - Number(LuggageCharges) + Number(e.target.value);
    }

    if (e.target.name === "Damages") {
      setTotalRental(
        Number(TotalRental) - Number(Damages) + Number(e.target.value)
      );
      setDamages(e.target.value);
      values = Number(TotalRental) - Number(Damages) + Number(e.target.value);
    }
    if (e.target.name === "ExKmCharges") {
      setTotalRental(
        Number(TotalRental) - Number(ExKmCharges) + Number(e.target.value)
      );
      setExKmCharges(e.target.value);
      values =
        Number(TotalRental) - Number(ExKmCharges) + Number(e.target.value);
    }
    if (e.target.name === "OtherCharges") {
      setTotalRental(
        Number(TotalRental) - Number(OtherCharges) + Number(e.target.value)
      );
      setOtherCharges(e.target.value);
      values =
        Number(TotalRental) - Number(OtherCharges) + Number(e.target.value);
    }

    setGrandTotal(Number(values));
  };

  /**
   * api call for gst amount
   */
  const handleGstAmount = () => {
    var values = 0;
    if (acceptCheck === false) {
      values = Number(TotalRental);
      setGrandTotal(Number(values));
    } else {
      setgst(Number((TotalRental * gstRate) / 100));
      var grandDisplay = Number(
        (TotalRental * gstRate) / 100 + TotalRental
      ).toFixed(2);

      setGrandTotal(grandDisplay);
    }
  };

  /**
   * handle gst
   * @param {*} e
   */
  const handleGst = (e) => {
    var values = 0;
    if (acceptCheck === false) {
      setgst(e.target.value);

      values = Number(TotalRental) + Number(e.target.value);
      setGrandTotal(Number(values));
    } else {
      setgst(Number((TotalRental * gstRate) / 100));

      var grandDisplay = Number(
        (TotalRental * gstRate) / 100 + TotalRental
      ).toFixed(2);

      setGrandTotal(grandDisplay);
    }
  };
  const handleCardDate = (e) => {
    setCardDate(moment(e).format("DD/MM/YYYY"));
  };

  const changeHandling = (e) => {
    if (e.target.name === "advanceamount") {
      setadvanceamount(e.target.value);
      var tot;
      if (paymentRes?.data?.length === 0) {
        tot = Number(GrandTotal) - 0 - Number(e.target.value);
        setbalanceamount(tot.toFixed(2));
      } else if (paymentRes?.data?.length > 0) {
        var tot1 = paymentRes?.data?.[0]?.paidamount;
        tot = Number(GrandTotal) - tot1 - Number(e.target.value);
        setbalanceamount(tot.toFixed(2));
      }
    }
    if (e.target.name === "paymentType") {
      setpaymentType(e.target.value);
    }

    if (e.target.name === "paymentdetails") {
      setpaymentdetails(e.target.value);
    }
    if (e.target.name === "ref_number") {
      setRefNumber(e.target.value);
    }
    if (e.target.name === "paymentamount") {
      setpaymentamount(e.target.value);
    }
    if (e.target.name === "cardno") {
      setcardno(e.target.value);
    }
    if (e.target.name === "cvv") {
      setcvv(e.target.value);
    }
    if (e.target.name === "expiry") {
      setexpiry(e.target.value);
    }
    if (e.target.name === "holdername") {
      setholdername(e.target.value);
    }
    if (e.target.name === "cardType") {
      setcardType(e.target.value);
    }
    if (e.target.name === "transactionid") {
      settransactionid(e.target.value);
    }
  };
  function onSelectFile1(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile1(undefined);
      return;
    }

    setFileName1(e.target.files[0].name);
    setSelectedFile1(e.target.files[0]);
  }
  /**
   * navigation
   * @param {*} e
   */
  const navigateToAgreement = (e) => {
    makepaymentContinue(e);
  };

  /**
   * make payment
   * @param {*} e
   */
  const makepayment = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!RentalCharges) {
      dispatch(alertActions.error("Rental charge is required"));
    } else if (payment_dueDate === "") {
      dispatch(alertActions.error("Payment Due Date is required"));
    } else {
      setLoader("loading");
      StartrentService.makePayment(
        NoOfDays,
        RentalCharges,
        HelmetCharges,
        LuggageCharges,
        OtherCharges,
        ExKmCharges,
        Damages,
        TotalRental,
        gst,
        GrandTotal,
        bid,
        acceptCheck,
        payment_dueDate,
        Number(paymentRes?.data?.[0]?.paidamount)
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setSubmitted(false);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
            setDisplayMain(1);
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * make payment
   * @param {*} e
   */
  const makepaymentContinue = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!RentalCharges) {
      dispatch(alertActions.error("Rental charge is required"));
    } else if (payment_dueDate === "") {
      dispatch(alertActions.error("Payment Due Date is required"));
    } else {
      setLoader("loading");
      StartrentService.makePayment(
        NoOfDays,
        RentalCharges,
        HelmetCharges,
        LuggageCharges,
        OtherCharges,
        ExKmCharges,
        Damages,
        TotalRental,
        gst,
        GrandTotal,
        bid,
        acceptCheck,
        payment_dueDate,
        paidAmount
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setSubmitted(false);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
            displayData(4);
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * payment type
   * @param {*} e
   */
  const creditPayment = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (balanceamount < 0) {
      dispatch(alertActions.error("Enter Proper amount"));
    } else {
      if (carddate && paymentType && advanceamount && paymentdetails) {
        if (paymentType === "cash") {
          setLoader("loading");
          StartrentService.makePaymentCash(
            carddate,
            paymentType,
            paymentdetails,
            advanceamount,
            ref_number,
            bid
          )
            .then((response) => {
              if (response?.data?.status?.code === 0) {
                setSubmitted(false);
                setLoader("isfull");
                setadvanceamount("");
                setbalanceamount("");
                setCardDate("");
                setpaymentType("");
                setpaymentdetails("");
                setDisplayMain(0);

                dispatch(alertActions.success(response?.data?.status?.message));
              } else {
                setLoader("isempty");
                dispatch(alertActions.error(response?.data?.status?.message));
              }
            })
            .catch((err) => console.log(err));
        } else if (paymentType === "card") {
          setLoader("loading");
          StartrentService.makePaymentCard(
            carddate,
            paymentType,
            paymentdetails,
            advanceamount,
            cardType,
            cardno,
            holdername,
            cvv,
            expiry,
            ref_number,
            bid
          )
            .then((response) => {
              if (response?.data?.status?.code === 0) {
                setSubmitted(false);
                setLoader("isfull");
                setadvanceamount("");
                setbalanceamount("");
                setCardDate("");
                setpaymentType("");
                setpaymentdetails("");
                setDisplayMain(0);
                dispatch(alertActions.success(response?.data?.status?.message));
              } else {
                setLoader("isempty");
                dispatch(alertActions.error(response?.data?.status?.message));
              }
            })
            .catch((err) => console.log(err));
        } else {
          if (selectedFile1 === "") {
            setLoader("loading");
            StartrentService.makePaymentOnline(
              carddate,
              paymentType,
              paymentdetails,
              advanceamount,
              transactionid,
              paymentImage,
              ref_number,
              bid
            )
              .then((response) => {
                if (response?.data?.status?.code === 0) {
                  setSubmitted(false);
                  setLoader("isfull");
                  setadvanceamount("");
                  setbalanceamount("");
                  setCardDate("");
                  setpaymentType("");
                  setPaymentImage("");
                  setSelectedFile1("");
                  setpaymentdetails("");
                  setDisplayMain(0);
                  dispatch(
                    alertActions.success(response?.data?.status?.message)
                  );
                } else {
                  setLoader("isempty");
                  dispatch(alertActions.error(response?.data?.status?.message));
                }
              })
              .catch((err) => console.log(err));
          } else {
            setLoader("loading");
            StartrentService.makePaymentOnline(
              carddate,
              paymentType,
              paymentdetails,
              advanceamount,
              transactionid,
              selectedFile1,
              ref_number,
              bid
            )
              .then((response) => {
                if (response?.data?.status?.code === 0) {
                  setSubmitted(false);
                  setLoader("isfull");
                  setadvanceamount("");
                  setbalanceamount("");
                  setCardDate("");
                  setpaymentType("");
                  setPaymentImage("");
                  setSelectedFile1("");
                  setpaymentdetails("");
                  setDisplayMain(0);
                  dispatch(
                    alertActions.success(response?.data?.status?.message)
                  );
                } else {
                  setLoader("isempty");
                  dispatch(alertActions.error(response?.data?.status?.message));
                }
              })
              .catch((err) => console.log(err));
          }
        }
      }
    }
  };

  return {
    loader,
    NoOfDays,

    RentalCharges,
    HelmetCharges,
    LuggageCharges,
    GrandTotal,
    gst,
    TotalRental,
    Damages,
    ExKmCharges,
    OtherCharges,
    submitted,
    carddate,
    advanceamount,
    paymentType,
    balanceamount,
    paymentdetails,
    paymentamount,
    cardno,
    cvv,
    expiry,
    holdername,
    cardType,
    transactionid,
    fileName1,
    paymentImage,
    selectedFile1,
    displayMain,
    paymentRes,
    payment_dueDate,
    ref_number,
    acceptCheck,
    gstRate,
    paymentArray,
    changeHandling,
    handleCardDate,
    makepayment,
    creditPayment,
    onSelectFile1,
    goBack,
    navigateToAgreement,
    handleChange,
    handleGst,
    changeAcceptCheckboxHandler,
    handlePaymentDueDate,
  };
};
export default PaymentDetailsHooks;
