import { useEffect, useState } from "react";
import {
  AgreementService,
  StartrentService,
  VehicleService,
} from "../../../../../../../../services";
import { useNavigate } from "react-router";
import { alertActions } from "../../../../../../../../actions/alert.actions";
import { useDispatch } from "react-redux";

const CheckOutHook = (displayData, cid, bid) => {
  const [KMLimit, setKMLimit] = useState("");
  const [odometer, setOdometer] = useState("");
  const [fuellevel, setFuellevel] = useState("");
  const [helmetqty, setHelmetqty] = useState("");
  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState("notset");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * get rental data
   */
  useEffect(() => {
    if (bid) {
      setLoader("loading");
      StartrentService.getAllRentalData(bid)
        .then((res) => {
          if (res?.data?.status?.code === 0) {
            setLoader("isfull");
            if (res?.data?.data?.[0]?.vehicalid) {
              getVehicleDetails(res?.data?.data?.[0]?.vehicalid);
            }
          } else {
            console.log("error");
          }
        })
        .catch((err) => console.log(err));
      kmUpdate(bid);
    }
    // eslint-disable-next-line
  }, []);

  /**
   * vehicle details
   * @param {*} id
   */
  const getVehicleDetails = (id) => {
    setLoader("loading");
    VehicleService.getSingleInfoVehicle(id)
      .then((res) => {
        setLoader("isfull");
        setOdometer(res?.data?.data?.[0]?.ODOMeterNo);
      })
      .catch((err) => console.log(err));
  };

  /**
   * km update
   */
  const kmUpdate = () => {
    setLoader("loading");
    AgreementService.no_of_days(bid)
      .then((res) => {
        if (res?.data?.status?.code === 0) {
          setLoader("isfull");
          if (res?.data?.data?.[0]?.vehicleCharges.length !== 0) {
            let selectedkm =
              res?.data?.data?.[0]?.vehicleCharges?.[0]?.RatePerDay[
                res?.data?.data?.[0]?.noofdays - 1
              ];
            let selecetdKmRate = selectedkm?.Cost ? selectedkm?.Km : "";
            setKMLimit(selecetdKmRate);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  /**
   * change handle
   * @param {*} e
   */
  const Changehandler = (e) => {
    if (e.target.name === "KMLimit") {
      setKMLimit(e.target.value);
    }
    if (e.target.name === "odometer") {
      setOdometer(e.target.value);
    }
    if (e.target.name === "fuellevel") {
      setFuellevel(e.target.value);
    }
    if (e.target.name === "helmetqty") {
      setHelmetqty(e.target.value);
    }
  };

  /**
   * store checkout
   */
  const checkOutStore = () => {
    setSubmit(true);
    if (KMLimit && odometer && fuellevel) {
      setLoader("loading");
      StartrentService.store_checkout(
        KMLimit,
        odometer,
        fuellevel,
        helmetqty,
        bid
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setSubmit(false);
            navigate("/agreement");
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return {
    loader,
    KMLimit,
    odometer,
    fuellevel,
    helmetqty,
    submit,
    Changehandler,
    checkOutStore,
  };
};
export default CheckOutHook;
