import * as axios from "../network/Network";

const config = require("../network/api.json");

class StartrentService {

  /**
   * get booking details
   * @param {*} page 
   * @param {*} value 
   * @param {*} search 
   * @returns 
   */
  static getBookingDetails(page, value, search) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.store_booking}?page=${page}&searchby=${value}&value=${search}`,
    });

    return Promise.resolve(result);
  }


  /**
   * send otp
   * @param {*} mobileno 
   * @returns 
   */
  static getotpvalue(mobileno) {
    const country_code = 91;
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.getotpvalue,
      data: { mobileno, country_code },
    });
    return Promise.resolve(result);
  }

  /**
   * verify otp verifyOtp
   * @param {*} mobileno 
   * @param {*} otp1 
   * @param {*} otp2 
   * @param {*} otp3 
   * @param {*} otp4 
   * @param {*} otp5 
   * @returns 
   */
  static verifyOtp(mobileno, otp1, otp2, otp3, otp4, otp5) {
    var otp = otp1 + otp2 + otp3 + otp4 + otp5;
    const country_code = 91;
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.verifyOtp,
      data: { mobileno, country_code, otp },
    });
    return Promise.resolve(result);
  }

  /**
   * get data getAllData
   * @param {*} id 
   * @returns 
   */
  static getAllData(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getAllData + `/${id}`,
    });
    return Promise.resolve(result);
  }


  /**
   * get data getAllData
   * @param {*} id 
   * @returns 
   */
  static getCustData(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getCustomers + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * add booking for new customer
   * @param {*} fullname 
   * @param {*} email 
   * @param {*} mobileno 
   * @param {*} alternatemobileno 
   * @param {*} date_of_birth 
   * @param {*} id 
   * @returns 
   */
  static addCustomerDetails(
    fullname,
    email,
    mobileno,
    alternatemobileno,
    date_of_birth,
    id
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.update_personal_information + `/${id}`,
      data: { fullname, email, mobileno, alternatemobileno, date_of_birth },
    });
    return Promise.resolve(result);
  }


  /**
   * getAllRentalData
   * @param {*} id 
   * @returns 
   */
  static getAllRentalData(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.rental_details + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * get data getAllData getAllData
   * @param {*} id 
   * @returns 
   */
  static getCheckin(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getCheckin + `/${id}`,
    });
    return Promise.resolve(result);
  }


  /**
   * get all vehicle
   * @param {*} page 
   * @param {*} mid 
   * @param {*} vclass 
   * @param {*} vmodel 
   * @returns 
   */
  static getBookingDetailsdata(page, mid, vclass, vmodel, value, search) {
    const result = axios.appRequest({
      method: axios.GET,
      url:
        config.user.searchvehicledata +
        `?vehicalMake=${mid}&vehicalClass=${vclass}&vehicalModel=${vmodel}&page=${page}&searchby=${value}&value=${search}`,
    });
    return Promise.resolve(result);
  }


  /**
   * get payment
   * @param {*} id 
   * @returns 
   */
  static getPayment(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.paymentdetail + `/${id}`,
    });
    return Promise.resolve(result);
  }


  /**
   * make payment
   * @param {*} NoOFDays 
   * @param {*} RentalCharges 
   * @param {*} HelmetCharges 
   * @param {*} LagguageCharges 
   * @param {*} OtherCharges 
   * @param {*} ExkmCharges 
   * @param {*} Damages 
   * @param {*} TotalRental_othercharges 
   * @param {*} GST 
   * @param {*} Grand_Total 
   * @param {*} id 
   * @param {*} acceptCheck 
   * @param {*} paymentDueDate 
   * @returns 
   */
  static makePayment(
    NoOFDays,
    RentalCharges,
    HelmetCharges,
    LagguageCharges,
    OtherCharges,
    ExkmCharges,
    Damages,
    TotalRental_othercharges,
    GST,
    Grand_Total,
    id,
    acceptCheck,
    paymentDueDate,
    discountCharges,
    paidamount
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.makepayment + `/${id}`,
      data: {
        NoOFDays,
        RentalCharges,
        HelmetCharges,
        LagguageCharges,
        OtherCharges,
        ExkmCharges,
        Damages,
        TotalRental_othercharges,
        GST,
        Grand_Total,
        acceptCheck,
        paymentDueDate,
        discountCharges,
        paidamount
      },
    });
    return Promise.resolve(result);
  }


  /**
   * make payment
   * @param {*} Payment_Date 
   * @param {*} Payment_type 
   * @param {*} Payment_Details 
   * @param {*} Payment_Amount 
   * @param {*} Reference_number 
   * @param {*} id 
   * @returns 
   */
  static makePaymentCash(
    Payment_Date,
    Payment_type,
    Payment_Details,
    Payment_Amount,
    Reference_number,
    id,
    discountCharges
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.makePaymentConfirm + `/${id}`,
      data: {
        Payment_Date,
        Payment_type,
        Payment_Details,
        Payment_Amount,
        Reference_number,
        discountCharges
      },
    });
    return Promise.resolve(result);
  }


  /**
   * make payment
   * @param {*} Payment_Date 
   * @param {*} Payment_type 
   * @param {*} Payment_Details 
   * @param {*} Payment_Amount 
   * @param {*} card_type 
   * @param {*} card_number 
   * @param {*} card_holdername 
   * @param {*} card_cvv 
   * @param {*} card_expirydate 
   * @param {*} Reference_number 
   * @param {*} id 
   * @returns 
   */
  static makePaymentCard(
    Payment_Date,
    Payment_type,
    Payment_Details,
    Payment_Amount,
    card_type,
    card_number,
    card_holdername,
    card_cvv,
    card_expirydate,
    Reference_number,
    id
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.makePaymentConfirm + `/${id}`,
      data: {
        Payment_Date,
        Payment_type,
        Payment_Details,
        Payment_Amount,
        card_type,
        card_number,
        card_holdername,
        card_cvv,
        card_expirydate,
        Reference_number,
      },
    });
    return Promise.resolve(result);
  }


  /**
   * make payment
   * @param {*} Payment_Date 
   * @param {*} Payment_type 
   * @param {*} Payment_Details 
   * @param {*} Payment_Amount 
   * @param {*} online_transactionid 
   * @param {*} online_transactionscreenshot 
   * @param {*} Reference_number 
   * @param {*} id 
   * @returns 
   */
  static makePaymentOnline(
    Payment_Date,
    Payment_type,
    Payment_Details,
    Payment_Amount,
    online_transactionid,
    online_transactionscreenshot,
    Reference_number,
    id
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("Payment_Date", Payment_Date);
    bodyFormData.append("Payment_type", Payment_type);
    bodyFormData.append("Payment_Details", Payment_Details);
    bodyFormData.append("Payment_Amount", Payment_Amount);
    bodyFormData.append("online_transactionid", online_transactionid);
    bodyFormData.append(
      "online_transactionscreenshot",
      online_transactionscreenshot
    );
    bodyFormData.append("Reference_number", Reference_number);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.makePaymentConfirm + `/${id}`,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  /**
   * getbook
   * @param {*} id 
   * @returns 
   */
  static getbook(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getAllData + `/${id}`,
    });
    return Promise.resolve(result);
  }


  /**
   * store rentals
   * @param {*} StartDateTime 
   * @param {*} EndDateTime 
   * @param {*} PickupLocation 
   * @param {*} ReturnLocation 
   * @param {*} helmetqty 
   * @param {*} TravelLocation 
   * @param {*} vehicalClass 
   * @param {*} vehicalMake 
   * @param {*} vehicalModel 
   * @param {*} id 
   * @param {*} vehicalLocation 
   * @param {*} vehicalid 
   * @returns 
   */
  static storeRentals(
    StartDateTime,
    EndDateTime,
    PickupLocation,
    ReturnLocation,
    helmetqty,
    TravelLocation,
    vehicalClass,
    vehicalMake,
    vehicalModel,
    id,
    vehicalLocation,
    vehicalid
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.storeRentals + `/${id}`,
      data: {
        StartDateTime,
        EndDateTime,
        PickupLocation,
        ReturnLocation,
        helmetqty,
        TravelLocation,
        vehicalClass,
        vehicalMake,
        vehicalModel,
        vehicalLocation,
        vehicalid,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * store checkout store_checkout
   * @param {*} KM_limit 
   * @param {*} Odo_meter 
   * @param {*} Fuel_level 
   * @param {*} Helmet_qty 
   * @param {*} id 
   * @returns 
   */
  static store_checkout(KM_limit, Odo_meter, Fuel_level, Helmet_qty, id) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.store_checkout + `/${id}`,
      data: { KM_limit, Odo_meter, Fuel_level, Helmet_qty },
    });
    return Promise.resolve(result);
  }

  /**
   * store checkout store_checkout
   * @param {*} KM_limit 
   * @param {*} Odo_meter 
   * @param {*} Fuel_level 
   * @param {*} id 
   * @returns 
   */
  static store_checkoutWithNoHelmet(KM_limit, Odo_meter, Fuel_level, Helmet_qty, id) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.store_checkout + `/${id}`,
      data: { KM_limit, Odo_meter, Fuel_level, Helmet_qty },
    });
    return Promise.resolve(result);
  }

  /**
   * Edit customer details
   * @param {*} id 
   * @param {*} fullname 
   * @param {*} email 
   * @param {*} mobileno 
   * @param {*} alternatemobileno 
   * @param {*} date_of_birth 
   * @param {*} status 
   * @param {*} adhar_number 
   * @param {*} drivinglicense_number 
   * @param {*} drivinglicense_expirydate 
   * @param {*} age 
   * @param {*} drivinglicense_frontsideimage 
   * @param {*} drivinglicense_backsideimage 
   * @param {*} id_frontside 
   * @param {*} id_backside 
   * @returns 
   */
  static editCustomerDetails(
    id,
    fullname,
    email,
    mobileno,
    alternatemobileno,
    date_of_birth,
    status,
    adhar_number,
    drivinglicense_number,
    drivinglicense_expirydate,
    age,
    drivinglicense_frontsideimage,
    drivinglicense_backsideimage,
    id_frontside,
    id_backside
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("fullname", fullname);
    bodyFormData.append("email", email);
    bodyFormData.append("mobileno", mobileno);
    bodyFormData.append("alternatemobileno", alternatemobileno);
    bodyFormData.append("date_of_birth", date_of_birth);
    bodyFormData.append("status", status);
    bodyFormData.append("adhar_number", adhar_number);
    bodyFormData.append("drivinglicense_number", drivinglicense_number);
    bodyFormData.append("drivinglicense_expirydate", drivinglicense_expirydate);
    bodyFormData.append("age", age);
    bodyFormData.append(
      "drivinglicense_frontsideimage",
      drivinglicense_frontsideimage
    );
    bodyFormData.append(
      "drivinglicense_backsideimage",
      drivinglicense_backsideimage
    );
    bodyFormData.append("id_frontside", id_frontside);
    bodyFormData.append("id_backside", id_backside);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.storedocuments + `/${id}`,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }
}
export { StartrentService };
