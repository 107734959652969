import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { CustomerService } from "../../../../../../../../services";

const useBookingHistrory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("mobileno");
  const [id, setId] = useState(location?.state?.row?.id);
  const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);

  const columns = [
    {
      name: "Sr No ",
      selector: (row) => row.number,

      width: "5%",
    },
    {
      name: "Customer Name",
      selector: (row) => row.fullname,
      width: "150px",
    },

    {

      name: "Mobile Number",
      selector: (row) => row.mobileno,
      width: "150px",
    },
    {

      name: "Start Date Time",
      selector: (row) => row.StartDateTime,
      width: "180px",
    },
    {

      name: "End Date Time",
      selector: (row) => row.EndDateTime,
      width: "180px",
    },
    {

      name: "No. of Days",
      selector: (row) => row.NoOFDays,
      width: "120px",
    },
    {

      name: "Rent Status",

      selector: (row) => (
        <div
          className={
            row.payment_status === "onrent"
              ? "button_style_onrent"
              : row.payment_status === "completed"
                ? "button_style_completed"
                : ""
          }
        >
          {row.payment_status === "onrent"
            ? "On Rent"
            : row.payment_status === "completed"
              ? "Completed"
              : ""}
        </div>
      ),
      width: "150px",
    },
    {
      name: "Payment Status",
      selector: (row) => (
        <div
          className={
            row.paymentStatus === "Pending"
              ? "button_style_pending"
              : row.paymentStatus === "Paid"
                ? "button_style_completed1"
                : ""
          }
        >
          {row.paymentStatus}
        </div>
      ),
      width: "150px",
    },
    {
      name: "View Agreement",
      selector: (row) => (
        <>
          {row.payment_status === "onrent" && (
            <button
              className="viewdata"
              onClick={() => {
                navigateToAgreement(row);
              }}
            >
              view
            </button>
          )}
        </>
      ),
      width: "150px",
    },
    {
      name: "Make Payment",
      selector: (row) => (
        <>
          {row.paymentStatus === "Pending" && (
            <button className="viewdata" onClick={() => handleRowHistory(row)}>
              Make Payment
            </button>
          )}
        </>
      ),
      width: "150px",
    },
  ];
  let order = [];
  let obj = {};

  /**
   * list api call
   */
  useEffect(() => {
    bookingResponse(id, page, item, search);
    // eslint-disable-next-line
  }, []);

  /**
   * table list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * table list
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      let startDate = item?.startdate ? new Date(item?.startdate) : "";
      let endDate = item?.enddate ? new Date(item?.enddate) : "";
      obj = {
        id: item?._id,
        number:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        fullname: item?.customername,
        mobileno: item?.customermobileno,
        StartDateTime: startDate?.toLocaleString("en-IN"),
        EndDateTime: endDate?.toLocaleString("en-IN"),
        NoOFDays: item?.noofdays,
        payment_status: item?.bookingstatus,
        paymentStatus: item?.paymentstatus,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };
  /**
   * list history api call
   * @param {*} id
   * @param {*} page
   * @param {*} item
   * @param {*} search
   */
  const bookingResponse = (id, page, item, search) => {
    setLoader("loading");
    CustomerService.getCustomersHistory(id, page, item, search.trim())
      .then((response) => {
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        setLoader("isfull");
      })
      .catch((err) => console.log(err));
  };

  /**
   * change handler
   * @param {*} e
   */
  const changeItemHandler = (e) => {
    setSearch("");
    if (e.target.value === "fullname") {
      setItem(e.target.value);
      bookingResponse(id, 1, e.target.value, "");
    } else {
      setItem(e.target.value);
      bookingResponse(id, 1, e.target.value, "");
    }
  };

  /**
   * search handler
   * @param {*} e
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    bookingResponse(id, "", item, e.target.value);
  };

  /**
   * navigation
   */
  const navigateToList = () => {
    navigate("/StartRentWalkin");
  };

  /**
   * navigate to agreement
   * @param {*} row
   */
  const navigateToAgreement = (row) => {
    navigate(`/ExtendedDateTab?bid=${row?.id}`);
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRowHistory = (row) => {
    navigate(`/PendingPaymentTab?bid=${row?.id}`);
  };

  /**
   * handle page
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(id, e.selected + 1, item, search);
  };

  return {
    listData,
    search,
    page,
    loader,
    totalPages,
    columns,
    listTable,
    setId,
    handlePageClick,
    navigateToList,
    changeItemHandler,
    changeSearchHandler, 
  };
};
export default useBookingHistrory;
