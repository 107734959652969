import { useEffect, useState } from "react";
import { alertActions } from "../../../../../actions";
import { SetUpService } from "../../../../../services";
import { useDispatch } from "react-redux";

const useVehicleClassTabHook = () => {
  let [page1, setPage1] = useState(1);
  const [listData1, setListData1] = useState([]);
  const [totalPages1, setTotalPages1] = useState(0);
  const [vehicleClass, setVehicleClass] = useState("");
  const [id, setId] = useState("");
  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState("notset");
  const [listTable1, setListTable1] = useState([]);

  const dispatch = useDispatch();

  const columns = [
    {
      name: "Sr No.",
      selector: (row) => row.number,
    },
    {
      name: "Vehicle Class",
      selector: (row) => row.vehicleClass,
    },
  ];
  let order = [];
  let obj = {};

  /**
   * call list response
   */
  useEffect(() => {
    vehicleClassListResponse(page1);
  }, [page1]);
  /**
   * table list
   */
  useEffect(() => {
    if (listData1?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData1?.data?.data?.docs]);

  /**
   * table list
   */
  const list = () => {
    listData1.data.data.docs.map((item, index) => {
      obj = {
        id: item._id,
        number:
          listData1.data.data.limit * (listData1.data.data.page - 1) +
          index +
          1,
        vehicleClass: item.vehicleClass,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable1(order);
  };
  /**
   * handle vehicle class
   * @param {*} e 
   */
  const handleVehicleClass = (e) => {
    setVehicleClass(e.target.value);
  };


  /**
   * get vehicle list
   * @param {*} page1 
   */
  const vehicleClassListResponse = (page1) => {
    setLoader("loading");
    SetUpService.getVehicleClass(page1)
      .then((response) => {
        setLoader("isfull");
        setListData1(response);
        setTotalPages1(response?.data?.data?.totalPages);
        setPage1(response?.data?.data?.page);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * handle page change
   * @param {*} e 
   */
  const handlePageClick1 = (e) => {
    setPage1(e.selected + 1);
  };


  /**
   * get row
   * @param {*} row 
   */
  const handleEditVehicleClass = (row) => {
    setVehicleClass(row?.vehicleClass);
    setId(row?.id);
  };

  /**
   * add api call
   * @param {*} e 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (vehicleClass) {
      setLoader("loading");
      SetUpService.addVehicleClass(vehicleClass)
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            vehicleClassListResponse(page1);
            setVehicleClass("");
            setLoader("isfull");
            setSubmit(false);

            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };


  /**
   * edit api call
   * @param {*} e 
   */
  const handleEditSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (id && vehicleClass) {
      setLoader("loading");
      SetUpService.editVehicleClass(id, vehicleClass)
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            vehicleClassListResponse(page1);
            setVehicleClass("");
            setId("");
            setLoader("isfull");
            setSubmit(false);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };
  return {
    loader,
    totalPages1,
    vehicleClass,
    submit,
    id,
    columns,
    listTable1,
    page1,
    handlePageClick1,
    handleVehicleClass,
    handleSubmit,
    handleEditVehicleClass,
    handleEditSubmit,
  };
};
export default useVehicleClassTabHook;
