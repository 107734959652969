import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ReportService } from "../../../../services";
import { alertActions } from "../../../../actions";
import moment from "moment";

const useVehiclePLHooks = (props) => {
  const { t } = props;
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loader, setLoader] = useState("notset");
  const navigate = useNavigate();
  const authdata = useSelector((state) => state?.userData);
  const [listTable, setListTable] = useState([]);
  const [loaderDownLoad, setLoaderDownload] = useState("notset");
  const dispatch = useDispatch();

  const columns = [
    {
      name: "Sr No",
      selector: (row) => row.srnumber,
      width: "80px",
    },
    {
      name: "Reg No",
      selector: (row) => row.regNo,
    },
    {
      name: "Vehicle Details",
      selector: (row) => row.vehicleDetails,
      width: "250px",
    },
    {
      name: "Rental Revenues",
      selector: (row) =>
        row.totalRentExp !== 0 ? `₹${row.totalRentExp}` : "N/A",
      width: "180px",
    },
    {
      name: "Vehicle Expenses",
      selector: (row) => (row.totalExp !== 0 ? `₹${row.totalExp}` : "N/A"),
      width: "180px",
    },

    {
      name: "Net P/L",
      selector: (row) => (row.Amount !== 0 ? `₹${row.Amount}` : "N/A"),
      width: "180px",
    },
    {
      name: "View",
      selector: (row) => (
        <button className="viewdata" onClick={() => handleRow(row)}>
          {t("View")}
        </button>
      ),
    },
  ];
  let order = [];
  let obj = {};

  /**
   * list call
   */
  useEffect(() => {
    if (fromDate !== "" || toDate !== "") {
      vehiclePlListResponse(page);
    }
    // eslint-disable-next-line
  }, [page, fromDate, toDate]);

  /**
   * table list call
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * table list
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      obj = {
        srnumber:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        vehicleDetails: item?.vehicleClass?.[0]?.vehicleClass
          ? item?.vehicleClass?.[0]?.vehicleClass +
            " " +
            item?.vehicleMake?.[0]?.make +
            " " +
            item?.vehicleModel?.[0]?.model
          : "N/A",
        odometer: item?.ODOMeterNo ? item?.ODOMeterNo : "N/A",
        totalRentExp: item?.totalRentExp ? item?.totalRentExp : "N/A",
        totalExp: item?.totalExp,
        id: item._id,
        Amount: item?.netpl,
        bookingDetails: item?.bookingDetails,
        maintexpenses: item?.maintexpenses,
        oilexpenses: item?.oilexpenses,
        paymentsDetails: item?.paymentsDetails,
        rentalDetails: item?.rentalDetails,
        regNo: item?.regNumber ? item?.regNumber : "N/A",
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * list api call
   */
  const vehiclePlListResponse = () => {
    if (toDate) {
      if (Date.parse(fromDate) > Date.parse(toDate)) {
        dispatch(
          alertActions.error("End Date Should be Greater than Start Date")
        );
      } else {
        setLoader("loading");
        ReportService.getplExpense(page, fromDate, toDate)
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setLoader("isfull");
              getvehiclePlAmount();
              setListData(response);
              setTotalPages(response?.data?.data?.totalPages);
              setPage(response.data.data.page);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  /**
   * get amount
   */
  const getvehiclePlAmount = () => {
    ReportService.getplExpensePaymentAmount(fromDate, toDate)
      .then((response) => {
        setTotalAmount(
          response?.data?.data?.docs?.[0]?.total
            ? response?.data?.data?.docs?.[0]?.total
            : 0
        );
      })
      .catch((err) => console.log(err));
  };

  /**
   * download
   */
  const downloadReport = () => {
    setLoaderDownload("loading");
    axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_APIURL}/admin/reports/pl-excel?from=${fromDate}&to=${toDate}`,

      method: "GET",
      responseType: "arraybuffer",

      headers: {
        // Add any auth token here
        authorization: authdata?.type + " " + authdata?.token,
        "Content-Type": "blob",
      },
      // Attaching the form data
    }).then((response) => {
      setLoaderDownload("isfull");
      const resp = response;
      const link = document.createElement("a");
      const fileName = `${Date.now()}.xlsx`;
      link.setAttribute("download", fileName);
      link.href = URL.createObjectURL(new Blob([resp.data]));
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    navigate("/VehiclePLDetails", { state: { row: row } });
  };

  /**
   * handle change
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };
  const handleStartDate = (e) => {
    setFromDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleEndDate = (e) => {
    setToDate(moment(e).format("DD/MM/YYYY"));
  };

  return {
    page,
    loader,
    listData,
    totalPages,
    fromDate,
    toDate,
    totalAmount,
    columns,
    listTable,
    loaderDownLoad,
    handlePageClick,
    handleStartDate,
    handleEndDate,
    downloadReport,
  };
};
export default useVehiclePLHooks;
