import "./App.css";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Login from "./components/pages/Ez-RentalLoginScreen/Login";
import { Provider } from "react-redux";
import store from "./store";
import { useEffect } from "react";
import { alertActions } from "./actions/alert.actions";
import "./assets/css/style.css";
import UserAccess from "./components/pages/Ez-RentalSetUpScreen/UserAccessTab/UserAccess";
import Location from "./components/pages/Ez-RentalSetUpScreen/LocationTab/LocationList/LocationList";
import LocationDetail from "./components/pages/Ez-RentalSetUpScreen/LocationTab/LocationDetail/Add-Edit-LocationDetails";
import Company from "./components/pages/Ez-RentalSetUpScreen/CompanyTab/CompanyList/Company";
import CompanyDetails from "./components/pages/Ez-RentalSetUpScreen/CompanyTab/CompanyDetail/EditCompanyDetails";
import Dashboard from "./components/pages/Ez-RentalDashboard/Dashboard";
import DayTask from "./components/pages/Ez-RentalDayTaskScreen/DayToDayTaskList/DayToDayTask";
import DayToDayTask from "./components/pages/Ez-RentalDayTaskScreen/DayToDayTaskDetail/Add-Edit-DayToDayDetails";
import InvestorDetail from "./components/pages/Ez-RentalInvestorScreen/InvestorDetail/Add-Edit-InvestorDetails";
import Investor from "./components/pages/Ez-RentalInvestorScreen/InvestorList/Investor";

import Generals from "./components/pages/Ez-RentalSetUpScreen/GeneralOptionTab/Generals";
import VehicleLists from "./components/pages/Ez-RentalVehicles_Screen/Vehicles_Lists/vehicleLists";
import VehicleDetails from "./components/pages/Ez-RentalVehicles_Screen/Vehicles_Details/vehicleDetails";
import CustomerLists from "./components/pages/Ez-RentalCustomer/Customers_Lists/Lists/customersLists";
import CustomerHistory from "./components/pages/Ez-RentalCustomer/Customers_Lists/History_Lists/customerHistory";
import CustomerDetails from "./components/pages/Ez-RentalCustomer/Customer_Details/CustomerDetails";
import Booking from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/BookAgreeement/BookingList/Booking";
import Agreement from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/Agreement";
import AddPersonalInfo from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/BookAgreeement/DetailPage/AddPersonalDetails/PersonalInfoTab/AddPersonalInfo";
import AgreeTab from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/BookAgreeement/DetailPage/AddPersonalDetails/AgreeTab";
import AddRentalDetailsInfo from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/BookAgreeement/DetailPage/AddPersonalDetails/RentalInfoTab/AddRentalDetailsInfo";
import BookingHistrory from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/BookAgreeement/BookingList/BookingHistrory/BookingHistrory";
import Vehicle from "./components/pages/Ez-RentalSetUpScreen/VehicleTab/SetupVehicle";
import Help from "./components/pages/Ez-RentalHelp/Help";
import Report from "./components/pages/Ez-RentalReports/Reports";
import InvoiceList from "./components/pages/Ez-RentalInvoices/InvoiceList/InvoiceList";
import ReservationList from "./components/pages/Ez-RentalAgreement/AgreementList/Reservation/ReservationList";
import PartialBookingList from "./components/pages/Ez-RentalAgreement/AgreementList/PartialBooking/PartialBookingList";
import OnRentList from "./components/pages/Ez-RentalAgreement/AgreementList/OnRent/OnRentList";
import ReturnList from "./components/pages/Ez-RentalAgreement/AgreementList/ReturnList/ReturnList";
import ServiceAlert from "./components/pages/Ez-RentalVehicles_Screen/Vehicles_Lists/ServiceAlerts/ServiceAlertList";
import MaintainanceAlert from "./components/pages/Ez-RentalVehicles_Screen/Vehicles_Lists/MaintainanceAlert/MaintainanceAlertList";
import PendingPayment from "./components/pages/Ez-RentalAgreement/AgreementList/PendingPayment/PendingPaymentList";
//start rent
import BookingStartRent from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/StartRent/Bookingflow/StartRentBookingList/StartRentBooking";
import BookingAgreeTab from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/StartRent/Bookingflow/BookingAddPersonalDetails/BookingAgreeTab";
import StartRentWalkin from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/StartRent/Walkinflow/StartRentWalkinList/StartRentWalkin";
import WalkinBookingHistrory from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/StartRent/Walkinflow/StartRentWalkinList/WalkinBookingHistrory/WalkinBookingHistrory";
import WalkinBookingAgreeTab from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/StartRent/Walkinflow/WalkinAddPersonalDetails/WalkinBookingAgreeTab";
import EndrentList from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/EndRent/EndRentList/EndrentList";
import EndRentDetailsTab from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/EndRent/EndRentDetails/EndRentDetailsTab";
import ExtendedDate from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/ExtendedDate/EndRentDetails/EndRentDetailsTab";
import BookAddCust from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/BookAgreeement/BookingList/BookAddCustomer/BookAddCust";
import VehicleStatusLists from "./components/pages/Ez-RentalVehicles_Screen/Vehicles_Lists/VehicleAvailableList";
import VehicleMaintanance from "./components/pages/Ez-RentalVehicles_Screen/Vehicles_Lists/InMaintananceList";
import VehicleExpirationList from "./components/pages/Ez-RentalVehicles_Screen/Vehicles_Lists/VehicleExpiration/VehicleExpirationList";
import WalkinAddCustomer from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/StartRent/Walkinflow/StartRentWalkinList/WalkingAddCustomer/WalkinAddCust";
import VehicleSoldList from "./components/pages/Ez-RentalVehicles_Screen/Vehicles_Lists/VehicleSoldList";
import StartRentBookingOtp from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/StartRent/Bookingflow/BookingAddPersonalDetails/BookingOtpScreen";
import WalkInOtp from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/StartRent/Walkinflow/WalkinAddPersonalDetails/WalkInOtpScreen";
import PendingPaymentTab from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/PendingPayment/EndRentDetails/EndRentDetailsTab";
import StartRentOtp from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/StartRent/Walkinflow/WalkinAddPersonalDetails/StartRentWalkInOtpScreen ";
//Exchange
import ExchageList from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/Exchange/ExchangeList/ExchageList";
import ExchangeOtpScreen from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/Exchange/ExchangeDetails/ExchangeOtpScreen";
import ExchangeDetails from "./components/pages/Ez-RentalAgreement/AgreementMainScreen/Exchange/ExchangeDetails/ExchangeDetails";
import { userLogout } from "./actions";
import PaymentTab from "./components/pages/Ez-RentalSetUpScreen/PaymentTab/PaymentTab";
import VehicleExpenseTab from "./components/pages/Ez-RentalSetUpScreen/VehicleExpenseTab/VehicleExpenseTab";
import VehicleExpenseDetails from "./components/pages/Ez-RentalReports/VehicleExpenses/VehicleExpenseDetails";
import VehiclePLDetails from "./components/pages/Ez-RentalReports/VehiclePL/VehiclePLDetails";
import InvestorListDetails from "./components/pages/Ez-RentalReports/Investor/InvestorListDetails";
import { withTranslation } from "react-i18next";
// import VehicleQrCode from "./components/pages/Ez-RentalVehicles_Screen/Vehicle_QR_Code/vehilceQrCode";

//App
const Main = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state?.alert);
  const user = JSON.parse(localStorage.getItem("state"));
  const isUser = user ? Object.keys(user.userData).length : 0;
  const session_expired = user?.userData?.session_expired;

  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => dispatch(alertActions.clear()), 4000);
    // eslint-disable-next-line
  }, [alert.message]);

  //session expire
  useEffect(() => {
    if (session_expired) {
      dispatch(alertActions.error("Authentication error..Please login"));
      setTimeout(() => {
        dispatch(userLogout());
        navigate("/login");
      }, 100);
    }
    // eslint-disable-next-line
  }, [session_expired]);

  const location = useLocation();
  const cid = location.search.split("=")[1]?.substring(0, 24);

  return (
    <div>
      {alert.message && (
        <div className={`alert ${alert.type}`}>{alert.message}</div>
      )}

      <Routes>
        <Route path="/" element={isUser ? <Dashboard /> : <Login />} />

        <Route path="/setup-useraccess" element={<UserAccess />} />
        <Route path="/setup-location" element={<Location />} />
        <Route path="/setup-locationdetails" element={<LocationDetail />} />
        <Route path="/setup-vehicle" element={<Vehicle />} />
        <Route path="setup-payment-type" element={<PaymentTab />} />
        <Route path="/setup-vehicle-expense" element={<VehicleExpenseTab />} />
        <Route path="/setup-company" element={<Company />} />
        <Route path="/setup-companyDetails" element={<CompanyDetails />} />
        <Route
          path="/VehicleExpenseDetails"
          element={<VehicleExpenseDetails />}
        />

        <Route path="/VehiclePLDetails" element={<VehiclePLDetails />} />
        <Route path="/InvestorListDetails" element={<InvestorListDetails />} />
        <Route path="/vehicles" element={<VehicleLists />} />
        <Route path="/vehicles-sold" element={<VehicleSoldList />} />
        <Route path="/vehicles-available" element={<VehicleStatusLists />} />
        <Route path="/vehicles-maintanance" element={<VehicleMaintanance />} />
        <Route path="/vehicle-details" element={<VehicleDetails />} />
        <Route path="/vehicle-expiration" element={<VehicleExpirationList />} />
        <Route path="/maintenance-alert" element={<ServiceAlert />} />
        <Route path="/oilChange-alert" element={<MaintainanceAlert />} />
        <Route path="/pending-payment" element={<PendingPayment />} />

        <Route path="/daytodaytask" element={<DayTask />} />
        <Route path="/daytodaytask-detail" element={<DayToDayTask />} />
        <Route path="/investor-detail" element={<InvestorDetail />} />
        <Route path="/investors" element={<Investor />} />
        <Route path="/setup-general-option" element={<Generals />} />

        <Route path="/customers" element={<CustomerLists />} />
        <Route path="/customers-history" element={<CustomerHistory />} />

        <Route path="/customers-details" element={<CustomerDetails />} />
        <Route path="/BookingHistrory" element={<BookingHistrory />} />

        {/* Agreement module */}
        <Route path="/booking" element={<Booking />} />
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/BookAddCust" element={<BookAddCust />} />
        <Route path="/BookingAgreeTab/:title" element={<BookingAgreeTab />} />
        <Route path="/addPersonalInfo" element={<AddPersonalInfo />} />
        <Route path="/agreeTab" element={<AgreeTab />} />
        <Route path={`/agreeTab?cid=${cid}`} element={<AgreeTab />} />
        <Route
          path="/AddRentalDetailsInfo"
          element={<AddRentalDetailsInfo />}
        />
        <Route path="/BookingList" element={<ReservationList />} />
        <Route path="/Partial-BookingList" element={<PartialBookingList />} />
        <Route path="/OnRentList" element={<OnRentList />} />
        <Route path="/ReturnList" element={<ReturnList />} />

        {/* start rent */}

        <Route path="/WalkInOtpscreen" element={<WalkInOtp />} />
        <Route path="/StartRentWalkInOtpscreen" element={<StartRentOtp />} />
        <Route path="/BookingOtpscreen" element={<StartRentBookingOtp />} />
        <Route path="/BookingStartRent" element={<BookingStartRent />} />
        <Route path="/BookingAgreeTab" element={<BookingAgreeTab />} />
        {/* walkin  */}
        <Route path="/StartRentWalkin" element={<StartRentWalkin />} />
        <Route
          path="/WalkinBookingHistrory"
          element={<WalkinBookingHistrory />}
        />
        <Route path="/WalkinAddCustomer" element={<WalkinAddCustomer />} />
        <Route
          path="/WalkinBookingAgreeTab"
          element={<WalkinBookingAgreeTab />}
        />

        {/* end rent */}
        <Route path="/EndrentList" element={<EndrentList />} />
        <Route path="/EndRentDetailsTab" element={<EndRentDetailsTab />} />
        <Route path="/ExtendedDateTab" element={<ExtendedDate />} />
        <Route path="/PendingPaymentTab" element={<PendingPaymentTab />} />

        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/help" element={<Help />} />
        <Route path="/report" element={<Report />} />
        <Route path="/closed-agreementList" element={<InvoiceList />} />

        {/* Exchange */}
        <Route path="/exchange-list" element={<ExchageList />} />
        <Route path="/exchange-otpScreen" element={<ExchangeOtpScreen />} />
        <Route path="/exchange-details" element={<ExchangeDetails />} />

        {/* Vehicle QR Code */}
        {/* <Route path="/vehicle-qr-code" element={<VehicleQrCode />} /> */}
      </Routes>
    </div>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
};
export default withTranslation()(App);
