import Menu from "../../../../_common/menu/Menu";
import TopBar from "../../../../_common/topbar/TopBar";
import { Location } from "../../../../../assets/images";
import "../Location.css";
import LeftSidebar from "../../../../_common/sidebar/LeftSidebar";
import useLocationDetailsHook from "./useLocationDetailsHook";
import { useLocation } from "react-router";
import { Loading } from "../../../../../assets/images";

import { withTranslation } from "react-i18next";

function LocationDetails(props) {
  const { t } = props;
  const locationDetail = useLocation();

  const {
    handleLocationCode,
    handleAddress,
    handleFax,
    handleCity,
    handleLocationName,
    handleAddressTwo,
    handleState,
    handleEmail,
    handleOwnerName,
    handlePhoneNumber,
    handleZip,
    handleSubmit,
    navigateToLocation,
    handleEditSubmit,
    submit,
    locationCode,
    address,
    fax,
    city,
    locationName,
    addressTwo,
    states,
    email,
    phoneNumber,
    ownerName,
    zip,
    id,
    loader,
  } = useLocationDetailsHook(locationDetail);

  const titleName = () => {
    return (
      <>
        <div className="Details_location flt">
          <div className="main_location flt">
            <div className="location flt">
              <img src={Location} alt="images" />
              {t("LOCATION")}
            </div>
          </div>
        </div>
      </>
    )
  }

  const locationDetails = () => {
    return (
      <>
        <div className="E_comp_details flt">
          {id === "" && (
            <div className="Company_name flt">
              <div className="comp_name_details flt">
                {t("Add Location Details")}
              </div>
            </div>
          )}{" "}
          {id !== "" && (
            <div className="Company_name flt">
              <div className="comp_name_details flt">
                {t("Edit Location Details")}
              </div>

            </div>
          )}
          {loader === "loading" && (
            <div className="main_load ">
              <img src={Loading} alt="loader" />
            </div>
          )}
          {(loader === "isfull" ||
            loader === "isempty" ||
            loader === "notset") && (
              <div className="main_company flt">
                <div className="main_row flt">

                  <div className="row">
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Location Code")} *</label>
                          <input
                            type="text"
                            placeholder="Location Code"
                            name="locationCode"
                            id="locationCode"
                            onChange={handleLocationCode}
                            value={locationCode}
                          />
                          {submit && !locationCode && (
                            <div className="req_field">
                              {t("Location Code is required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Location Name")} *</label>
                          <input
                            type="text"
                            placeholder="Location Name"
                            name="locationName"
                            id="locationName"
                            onChange={handleLocationName}
                            value={locationName}
                          />
                          {submit && !locationName && (
                            <div className="req_field">
                              {t("Location name is required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Owner Name")}</label>
                          <input
                            type="text"
                            placeholder="Owner Name"
                            name="ownerName"
                            id="ownerName"
                            onChange={handleOwnerName}
                            value={ownerName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Address")} *</label>
                          <input
                            className="comp_input"
                            type="text"
                            placeholder="Address"
                            name="address"
                            id="address"
                            onChange={handleAddress}
                            value={address}
                          />
                          {submit && !address && (
                            <div className="req_field">{t("Address is required")}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Address 2")}</label>
                          <input
                            className="comp_input"
                            type="text"
                            placeholder="Address 2"
                            name="addressTwo"
                            id="addressTwo"
                            onChange={handleAddressTwo}
                            value={addressTwo}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Phone Number")} *</label>
                          <input
                            type="number"
                            placeholder="Phone number"
                            name="phoneNumber"
                            id="phoneNumber"
                            onChange={handlePhoneNumber}
                            value={phoneNumber}
                          />
                          {submit && !phoneNumber && (
                            <div className="req_field">
                              {t("Phone number is required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Fax Number")} *</label>
                          <input
                            type="number"
                            placeholder="Fax Number"
                            name="fax"
                            id="fax"
                            onChange={handleFax}
                            value={fax}
                          />
                          {submit && !fax && (
                            <div className="req_field">
                              {t("Fax number is required")}
                            </div>
                          )}
                        </div>
                      </div>

                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("State")}</label>
                          <select
                            name="states"
                            id="states"
                            onChange={handleState}
                            value={states}
                          >
                            <option value="Karnataka">{t("Karnataka")}</option>
                            <option value="kerala">{t("Kerala")}</option>
                            <option value="tamilnadu">{t("tamilnadu")}</option>
                            <option value="goa">{t("Goa")}</option>
                            <option value="Maharastra">{t("Maharastra")}</option>
                            <option value="west bangal">{t("West bangal")}</option>
                            <option value="jammu&kashmir">{t("Jammu&Kashmir")}</option>
                            <option value="gujarat">{t("Gujarat")}</option>
                          </select>
                          {submit && !states && (
                            <div className="req_field">{t("State is required")}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Zip / Postal")} *</label>
                          <input
                            className="comp_input"
                            type="number"
                            placeholder="Zip / Postal"
                            name="zip"
                            id="zip"
                            onChange={handleZip}
                            value={zip}
                          />
                          {submit && !zip && (
                            <div className="req_field">
                              {t("Zip code is required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("City ")}*</label>
                          <input
                            type="text"
                            placeholder="city"
                            name="city"
                            id="city"
                            onChange={handleCity}
                            value={city}
                          />

                          {submit && !city && (
                            <div className="req_field">{t("City is required")}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="comp_details flt">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">{t("Email")}</label>
                            <input
                              type="text"
                              placeholder="Email"
                              name="email"
                              id="email"
                              onChange={handleEmail}
                              value={email}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="main_ownbutton flt">
                        <div className="own_button flt">
                          <button
                            className="backbutton"
                            onClick={navigateToLocation}
                          >
                            {t("Back")}
                          </button>
                          {id === "" && (
                            <button
                              className="submitbutton"
                              onClick={handleSubmit}
                            >
                              {t("Submit")}
                            </button>
                          )}
                          {id !== "" && (
                            <button
                              className="submitbutton"
                              onClick={handleEditSubmit}
                            >
                              {t("Update")}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            )}
        </div>
      </>
    )
  }
  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      <div className="flt">
        <LeftSidebar />
        <div className="chat_right1 flt">
          {titleName()}
          {locationDetails()}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(LocationDetails);
