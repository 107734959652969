const SetUpDashboard = ({ dashboardUA,
  handleDashboard }) => {
  let dashboardData = [];
  dashboardData = dashboardUA ? dashboardUA : [];

  const dashBoardDetails = () => {
    return (
      <>
        <div className="main_others flt">

          <div className="secound_config flt">
            <ul className="make_new flt">
              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="Bookings"
                  name="Bookings"
                  value="Bookings"
                  checked={
                    dashboardData.includes("Bookings")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="Bookings">Bookings</label>
              </li>

              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="draftBooking"
                  name="draftBooking"
                  value="draftBooking"
                  checked={
                    dashboardData.includes("draftBooking")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="draftBooking">Draft Bookings</label>
              </li>

              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="returns"
                  name="returns"
                  value="returns"
                  checked={
                    dashboardData.includes("returns")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="returns">Returns</label>
              </li>
              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="onRent"
                  name="onRent"
                  value="onRent"
                  checked={
                    dashboardData.includes("onRent")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="onRent">On Rent</label>
              </li>
              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="maintAlerts"
                  name="maintAlerts"
                  value="maintAlerts"
                  checked={
                    dashboardData.includes("maintAlerts")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="maintAlerts">Maint. Alerts</label>
              </li>
              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="oilChangeAlert"
                  name="oilChangeAlert"
                  value="oilChangeAlert"
                  checked={
                    dashboardData.includes("oilChangeAlert")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="oilChangeAlert">Oil Change Alerts</label>
              </li>
              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="vehiclesInFleet"
                  name="vehiclesInFleet"
                  value="vehiclesInFleet"
                  checked={
                    dashboardData.includes("vehiclesInFleet")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="vehiclesInFleet">Vehicles In Fleet</label>
              </li>
              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="availableInFleet"
                  name="availableInFleet"
                  value="availableInFleet"
                  checked={
                    dashboardData.includes("availableInFleet")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="availableInFleet">Available In Fleet</label>
              </li>
              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="expirationDetails"
                  name="expirationDetails"
                  value="expirationDetails"
                  checked={
                    dashboardData.includes("expirationDetails")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="expirationDetails">Expiration Details</label>

              </li>

              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="dayToDayTask"
                  name="dayToDayTask"
                  value="dayToDayTask"
                  checked={
                    dashboardData.includes("dayToDayTask")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="dayToDayTask">Day to Day Task</label>
              </li>

              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="pendingPayment"
                  name="pendingPayment"
                  value="pendingPayment"
                  checked={
                    dashboardData.includes("pendingPayment")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="pendingPayment">Pending Payment</label>
              </li>
              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="vehicleStatusChart"
                  name="vehicleStatusChart"
                  value="vehicleStatusChart"
                  checked={
                    dashboardData.includes("vehicleStatusChart")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="vehicleStatusChart">Vehicle Status Chart</label>
              </li>
              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="quickLookUp"
                  name="quickLookUp"
                  value="quickLookUp"
                  checked={
                    dashboardData.includes("quickLookUp")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="quickLookUp">Quick Lookup</label>
              </li>
              <li className="check_btn3">
                <input
                  type="checkbox"
                  id="maintenance"
                  name="maintenance"
                  value="maintenance"
                  checked={
                    dashboardData.includes("maintenance")}
                  onChange={(e) => handleDashboard(e, dashboardData)
                  }
                />
                <label for="maintenance">Maintenance</label>
              </li>
            </ul>
          </div>


        </div>
      </>
    )
  }
  return (
    <>
      {dashBoardDetails()}
    </>
  );
};
export default SetUpDashboard;
