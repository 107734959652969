import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import useVehicleClassTabHook from "./useVehicleClassTabHook";
import { withTranslation } from "react-i18next";
import { Loading } from "../../../../../assets/images";

const VehicleClassTab = (props) => {
  const { t } = props;
  const {
    loader,
    totalPages1,
    vehicleClass,
    submit,
    id,
    columns,
    listTable1,
    page1,
    handlePageClick1,
    handleVehicleClass,
    handleSubmit,
    handleEditVehicleClass,
    handleEditSubmit,
  } = useVehicleClassTabHook();


  const vehicleClassDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" || loader === "isempty" || loader === "notset") && (
          <div className="main_row flt">
            <div className="row">
              <div className="col-sm-3">
                <div className="company_name flt">
                  <div className="form-group flt">
                    <label className="labelName">{t("Vehicle Class")} *</label>
                    <input
                      type="text"
                      placeholder="Enter Vehicle Class"
                      name="vehicleClass"
                      id="vehicleClass"
                      onChange={handleVehicleClass}
                      value={vehicleClass}
                    />
                    {submit && !vehicleClass && (
                      <div className="req_field">{t("Vehicle Class is required")}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-2">
                <div className="edit_details1 edit_details1bb flt">
                  {id === "" && (
                    <button className="edit_button1" onClick={handleSubmit}>
                      <i class="fas fa-save"></i>{t("Save")}
                    </button>
                  )}
                  {id !== "" && (
                    <button className="edit_button1" onClick={handleEditSubmit}>
                      <i class="fas fa-save"></i>{t("Update")}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-lg-6">
                <div className="main_tab_ flt">
                  <div className="main_boxShadow flt">
                    <div className="main_tablehead flt">
                      {loader === "loading" || loader === "notset" ? (
                        <div className="table_loader ">
                          <img src={Loading} alt="loader" />
                        </div>
                      ) : (
                        <table className="main_table1 flt ">
                          {listTable1.length > 0 ? (
                            <DataTable
                              columns={columns}
                              data={listTable1 || []}
                              onRowClicked={(row) => handleEditVehicleClass(row)}
                            />
                          ) : (
                            <div className="no-data">{t("No Data Found")}</div>
                          )}
                          {listTable1.length > 0 && (
                            <ReactPaginate
                              nextLabel="&rarr;"
                              onPageChange={handlePageClick1}
                              pageRangeDisplayed={1}
                              marginPagesDisplayed={1}
                              pageCount={totalPages1}
                              previousLabel="&larr;"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                              forcePage={page1 - 1}
                            />
                          )}
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}</>
    )

  }





  return (
    <>
      {vehicleClassDetails()}
    </>
  );
};

export default withTranslation()(VehicleClassTab);
