import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerService } from "../../../../../services/CustomerService";

const useCustomersList = (props) => {
  const { t } = props;
  let [page, setPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("drivinglicense_number");
  const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);
  const navigate = useNavigate();

  const columns = [
    {
      name: t("Sr_No"),
      selector: (row) => row.srnumber,
      width: "80px"
    },
    {
      name: t("Customer_Name"),
      selector: (row) => row.fullname,
      width: "180px"
    },
    {
      name: t("Mobile_Number"),
      selector: (row) => row.mobileno,
      width: "180px"
    },
    {
      name: t("Email_Address"),
      selector: (row) => row.email,
      width: "200px"
    },
    {
      name: t("Driving_Licence_No"),
      selector: (row) => row.drivinglicense_number,
      width: "200px"
    },
    {
      name: t("No_of_Rents"),
      selector: (row) => row.no_of_rents,
      width: "180px"
    },
    {
      name: t("Status"),
      selector: (row) => (
        <div
          className={row.status === "Active" ? "button_style" : "button_style1"}
        >
          {row.status}
        </div>
      ),
      width: "180px"
    },
    {
      name: t("History"),
      selector: (row) => (
        <button
          className="viewdata"
          onClick={() => {
            navigateTohistory(row.id);
          }}
        >
          {t("view")}
        </button>
      ),
      width: "180px"
    },
  ];
  let order = [];
  let obj = {};

  /**
   * get customer list
   */
  useEffect(() => {
    customerListResponse(page, item, search);
    // eslint-disable-next-line
  }, []);

  /**
   * listing
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * list data
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      obj = {
        srnumber:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        fullname: item?.fullname,
        mobileno: item?.mobileno,
        email: item?.email,
        drivinglicense_number: item?.drivinglicense_number,
        status: item?.status,
        id: item?._id,
        adhar_number: item?.adhar_number,
        alternatemobileno: item?.alternatemobileno,
        drivinglicense_expirydate: item?.drivinglicense_expirydate,
        date_of_birth: item?.date_of_birth,
        drivinglicense_frontsideimage: item?.drivinglicense_frontsideimage,
        drivinglicense_backsideimage: item?.drivinglicense_backsideimage,
        id_frontside: item?.id_frontside,
        id_backside: item?.id_backside,
        no_of_rents: item?.no_of_rents,
        alternate_id: item?.alternate_id,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * navigate to add customer
   */
  const navigateToaddCustomer = () => {
    navigate("/customers-details");
  };
  /**
   * navigate to history
   * @param {*} id 
   */
  const navigateTohistory = (id) => {
    navigate("/customers-history", {
      state: {
        id: id,
      },
    });
  };

  /**
   * customer list response
   * @param {*} page 
   * @param {*} item 
   * @param {*} search 
   */
  const customerListResponse = (page, item, search) => {
    setLoader("loading");
    CustomerService.getAllCustomers(page, item, search.trim())
      .then((response) => {
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        setLoader("isfull");
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * change handler
   * @param {*} e 
   */
  const changeItemHandler = (e) => {
    setSearch("");
    if (e.target.value === "mobileno") {
      setItem(e.target.value);

      customerListResponse(1, e.target.value, search);
    } else if (e.target.value === "email") {
      setItem(e.target.value);

      customerListResponse(1, e.target.value, search);
    } else if (e.target.value === "fullname") {
      setItem(e.target.value);

      customerListResponse(1, e.target.value, search);
    } else {
      setItem(e.target.value);
      customerListResponse(1, e.target.value, search);
    }
  };

  /**
   * search handler
   * @param {*} e 
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    customerListResponse("", item, e.target.value);
  };

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    customerListResponse(e.selected + 1, item, search);
  };

  /**
   * handle row
   * @param {*} row 
   */
  const handleRow = (row) => {
    navigate("/customers-details", {
      state: {
        row: row,
      },
    });
  };

  return {
    listData,
    totalPages,
    page,
    loader,
    columns,
    listTable,
    handleRow,
    handlePageClick,
    navigateToaddCustomer,
    changeItemHandler,
    changeSearchHandler,
  };
};
export default useCustomersList;
