import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { CustomerService } from "../../../../../../services";

const useBookingHooks = (location, props) => {
  const { t } = props;
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayData, setDisplayData] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("drivinglicense_number");
  const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);
  const columns = [
    {
      name: t("Sr_No"),
      selector: (row) => row.srnumber,
      width: "80px",
    },
    {
      name: t("Customer_Name"),
      selector: (row) => row.fullname,
      width: "250px",
    },
    {
      name: t("Mobile_Number"),
      selector: (row) => row.mobileno,
      width: "180px",
    },
    {
      name: t("Email_Address"),
      selector: (row) => row.email,
      width: "300px",
    },
    {
      name: t("Driving_Licence_No"),
      selector: (row) => row.drivinglicense_number,
      width: "180px",
    },
    {
      name: t("No_of_Rents"),
      selector: (row) => row.no_of_rents,
      width: "120px",
    },
    {
      name: t("Status"),
      selector: (row) => (
        <div
          className={row.status === "Active" ? "button_style" : "button_style1"}
        >
          {row.status}
        </div>
      ),
      minWidth: "100px",
      grow: 3,
    },
    {
      name: t("History"),
      selector: (row) => (
        <button
          className="viewdata"
          onClick={() => navigateTohistoryData(row.id)}
        >
          {t("view")}
        </button>
      ),
      minWidth: "100px",
      grow: 3,
    },
    {
      name: t("Select_Customer"),
      selector: (row) => (
        <>
          {row.status === "Active" && (
            <button className="viewdata" onClick={() => handleRow(row)}>
              {t("Select")}
            </button>
          )}
        </>
      ),
      minWidth: "100px",
      width: "150px",
    },
  ];
  let order = [];
  let obj = {};

  /**
   * list call
   */
  useEffect(() => {
    bookingResponse(page, item, search);
    // eslint-disable-next-line
  }, []);

  /**
   * table list call
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * table list
   */
  const list = () => {
    listData.data.data.docs.map((item, index) => {
      obj = {
        srnumber:
          listData.data.data.limit * (listData.data.data.page - 1) + index + 1,
        fullname: item.fullname,
        mobileno: item.mobileno,
        email: item.email,
        drivinglicense_number: item.drivinglicense_number,
        status: item.status,
        alternatemobileno: item.alternatemobileno,
        date_of_birth: item.date_of_birth,
        id: item._id,
        no_of_rents: item?.no_of_rents,
        alternate_id: item?.alternate_id,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };
  /**
   * list api call
   * @param {*} page
   * @param {*} item
   * @param {*} search
   */
  const bookingResponse = (page, item, search) => {
    setLoader("loading");
    CustomerService.getAllCustomers(page, item, search.trim())
      .then((response) => {
        setLoader("isfull");
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response.data.data.page);
      })
      .catch((err) => console.log(err));
  };

  /**
   * search handler
   * @param {*} e
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    bookingResponse("", item, e.target.value);
  };

  /**
   * change handler
   * @param {*} e
   */
  const changeItemHandler = (e) => {
    setSearch("");
    if (e.target.value === "mobileno") {
      setItem(e.target.value);

      bookingResponse(1, e.target.value, "");
    } else if (e.target.value === "email") {
      setItem(e.target.value);

      bookingResponse(1, e.target.value, "");
    } else if (e.target.value === "fullname") {
      setItem(e.target.value);

      bookingResponse(1, e.target.value, "");
    } else {
      setItem(e.target.value);
      bookingResponse(1, e.target.value, "");
    }
  };


  const personalData = (val) => {
    setDisplayData(val);
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    navigate(`/agreeTab?cid=${row.id}`, {
      state: {
        row: row,
      },
    });
  };

  /**
   * handle page
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    bookingResponse(e.selected + 1, item, search);
  };

  /**
   * navigation
   * @param {*} val
   */
  const navigateToaddAgreement = (val) => {
    navigate("/BookAddCust");
  };
  const navigateTohistoryData = (id) => {
    navigate("/BookingHistrory", {
      state: {
        id: id,
      },
    });
  };

  return {
    loader,
    page,
    listData,
    displayData,
    totalPages,
    search,
    columns,
    listTable,
    handlePageClick,
    navigateToaddAgreement,
    personalData,
    changeItemHandler,
    changeSearchHandler,
  };
};
export default useBookingHooks;
