import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../../actions";
import { SetUpService } from "../../../../../services";

const useVehicleModelTabHook = (props) => {
  const { t } = props;
  const [vehicleList, setVehicleList] = useState([]);
  const [vehClass, setVehClass] = useState("");
  const [vehId, setVehId] = useState("");
  const [makeList, setMakeList] = useState([]);
  const [make, setMake] = useState("");
  const [makeId, setMakeId] = useState("");
  const [page3, setPage3] = useState(1);
  const [listData3, setListData3] = useState([]);
  const [totalPages3, setTotalPages3] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [model, setModel] = useState("");
  const [id, setId] = useState("");
  const [loader, setLoader] = useState("notset");
  const [listTable3, setListTable3] = useState([]);

  const dispatch = useDispatch();

  const columns = [
    {
      name: "Sr No.",
      selector: (row) => row.number,
    },
    {
      name: "Vehicle Class",
      selector: (row) => row.vehicleClass,
    },
    {
      name: "Make",
      selector: (row) => row.make,
    },
    {
      name: "Model",
      selector: (row) => row.model,
    },
    {
      name: "Delete",
      selector: (row) => (
        <button className="viewdata" onClick={() => deleteModelRecord(row)}>
          {t("Delete")}
        </button>
      ),
    },
  ];
  let order = [];
  let obj = {};
  /**
   * get classListResponse
   */
  useEffect(() => {
    vehicleClassListResponse();
  }, []);

  /**
   * get model response
   */
  useEffect(() => {
    modelListResponse(page3);
  }, [page3]);
  /**
   * call table list
   */
  useEffect(() => {
    if (listData3?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData3?.data?.data?.docs]);

  /**
   * table list call
   */
  const list = () => {
    listData3.data.data.docs.map((item, index) => {
      obj = {
        id: item._id,
        number:
          listData3.data.data.limit * (listData3.data.data.page - 1) +
          index +
          1,
        vehicleClass: item?.vehicleClass?.[0]?.vehicleClass,
        vehicleId: item?.vehicleClass?.[0]?._id,
        make: item?.vehicleMake?.[0]?.make,
        makeId: item?.vehicleMake?.[0]?._id,
        model: item.model,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable3(order);
  };
  /**
   * get make list
   * @param {*} page3 
   */
  const modelListResponse = (page3) => {
    setLoader("loading");
    SetUpService.getModel(page3)
      .then((response) => {
        setLoader("isfull");
        setListData3(response);
        setTotalPages3(response?.data?.data?.totalPages);
        setPage3(response?.data?.data?.page);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * handle veh class
   * @param {*} e 
   */
  const handleVehClass = (e) => {
    makeListResponse(e.target.value);

    setVehId(e.target.value);
    setMakeId("");
    setModel("");
  };

  /**
   * handle make
   * @param {*} e 
   */
  const handleMake = (e) => {
    setMakeId(e.target.value);
    setModel("");
  };

  /**
   * handle model
   * @param {*} e 
   */
  const handleModel = (e) => {
    setModel(e.target.value);
  };

  /**
   * get row
   * @param {*} row 
   */
  const handleEditModel = (row) => {
    makeListResponse(row?.vehicleId);
    setVehId(row?.vehicleId);
    setVehClass(row?.vehicleClass);
    setMake(row?.make);
    setId(row?.id);
    setMakeId(row?.makeId);
    setModel(row?.model);
  };
  /**
   * handle page
   * @param {*} e 
   */
  const handlePageClick3 = (e) => {
    setPage3(e.selected + 1);
  };

  /**
   * get all vehicle list
   */
  const vehicleClassListResponse = () => {
    SetUpService.getVehicleClassWithoutPage()
      .then((response) => {
        setVehicleList(response?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * get all make list
   * @param {*} mid 
   */
  const makeListResponse = (mid) => {
    SetUpService.getMakeWithoutPage(mid)
      .then((response) => {
        setMakeList(response?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * add api call
   * @param {*} e 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (vehId && makeId && model) {
      setLoader("loading");
      SetUpService.addModal(vehId, makeId, model)
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            modelListResponse(page3);
            setVehClass("");
            setVehId("");
            setMake("");
            setMakeId("");
            setModel("");
            setId("");
            setLoader("isfull");
            setSubmit(false);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };


  /**
   * delete model record
   * @param {*} row 
   */
  const deleteModelRecord = (row) => {
    setLoader("loading");
    SetUpService.deleteModel(row?.id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          dispatch(alertActions.success(response?.data?.status?.message));
          modelListResponse(page3);
          setLoader("isfull");
        } else {
          setLoader("isempty");
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * add api call
   * @param {*} e 
   */
  const handleEditSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (id && vehId && makeId && model) {
      setLoader("loading");
      SetUpService.editModel(id, vehId, makeId, model)
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            modelListResponse(page3);
            setVehClass("");
            setVehId("");
            setMake("");
            setMakeId("");
            setModel("");
            setId("");
            setLoader("isfull");
            setSubmit(false);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };
  return {
    loader,
    vehicleList,
    vehId,
    makeList,
    makeId,
    model,
    totalPages3,
    submit,
    id,
    page3,
    columns,
    listTable3,
    vehClass,
    make,
    handleVehClass,
    handleMake,
    handleSubmit,
    handleModel,
    handleEditModel,
    handlePageClick3,
    handleEditSubmit,

  };
};
export default useVehicleModelTabHook;
