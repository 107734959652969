import Menu from "../../../../../_common/menu/Menu";
import TopBar from "../../../../../_common/topbar/TopBar";
import "../../../Agreement.css";
import { agreement, Loading } from "../../../../../../assets/images";
import useBookingHooks from "./useBookingHooks";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import AddPersonalInfo from "../DetailPage/AddPersonalDetails/PersonalInfoTab/AddPersonalInfo";
import { useLocation } from "react-router";
import { withTranslation } from "react-i18next";


function Booking(props) {
  const { t } = props;
  const location = useLocation();
  const {
    loader,
    page,
    listData,
    totalPages,
    displayData,
    search,
    columns,
    listTable,
    handlePageClick,
    navigateToaddAgreement,
    changeItemHandler,
    changeSearchHandler,
  } = useBookingHooks(location, props);

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const agreementtiltle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img src={agreement} alt="agreement" />
          {t("New Agreement")}
        </div>
      </div>
    );
  };

  const agreementtab = () => {
    return (
      <div className="main_general flt">
        <div className="walk_in">
          <div className="rent_walk">{t("Booking_List")}</div>
        </div>
        <div className="main_search_b2 booking_newb44 flt">
          <div className="ex_customer">{t("Existing_Customer_List")}</div>

          <div className="filler"></div>
          <div className="search_l booking_searchn44">
            <select name="item" id="item" onChange={changeItemHandler}>
              <option default value="drivinglicense_number">
                {t("Search by Licence No.")}
              </option>
              <option value="mobileno">{t("Search by Mobile No.")}</option>
              <option value="email">{t("Search by Email Id.")}</option>
              <option value="fullname">{t("Search by Customer Name.")}</option>
            </select>
          </div>
          <div className="search_b1 booking_srchmm ">
            <input
              type="text"
              placeholder={t("Search..")}
              name="search"
              onChange={changeSearchHandler}
              value={search}
            />
            <button className="search_button">
              <i class="fa fa-search"></i>
            </button>
          </div>
          <div className="fliter_button2 bookingflbtn">
            <button onClick={() => navigateToaddAgreement(1)}>
              <i class="fas fa-user-plus"></i>
              {t("New_Customer")}
            </button>
          </div>


        </div>
      </div>
    );
  };

  const tableListDisplay = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          <div className="main_tableheadnew flt">
            {loader === "loading" || loader === "notset" ? (
              <div className="table_loader ">
                <img src={Loading} alt="loader" />
              </div>
            ) : (
              <table className="main_table1 flt ">
                {listData?.data?.data?.docs?.length !== 0 ? (
                  <DataTable
                    columns={columns}
                    data={listTable || []}
                    customStyles={customStyles}
                  />
                ) : (
                  <div className="no-data">{t("No Data Found")}</div>
                )}

                {listData?.data?.data?.docs.length !== 0 && (
                  <div>
                    <ReactPaginate
                      nextLabel="&rarr;"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      pageCount={totalPages}
                      previousLabel="&larr;"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakClassName="page-item"
                      currentPage={page}
                      forcePage={page - 1}
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}
              </table>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />

      {agreementtiltle()}
      {agreementtab()}
      {displayData === 1 && <AddPersonalInfo />}

      {displayData === 0 && tableListDisplay()}
    </div>
  );
}
export default withTranslation()(Booking);
