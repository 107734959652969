import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Menu from "../../../../../../_common/menu/Menu";
import TopBar from "../../../../../../_common/topbar/TopBar";
import { agreement } from "../../../../../../../assets/images";
import AddPersonalInfo from "./PersonalInfoTab/AddPersonalInfo";
import AddRentalDetailsInfo from "./RentalInfoTab/AddRentalDetailsInfo";
import DocumentDetails from "./DocumentDetailsInfo/DocumentDetailsInfo";
import PaymentDetail from "./PaymentDetails/PaymentDetails";
import { withTranslation } from "react-i18next";

const AgreeTab = (props) => {
  const {t}=props;
  const location = useLocation();
  const cid = location.search.split("=")[1]?.substring(0, 24);
  const bid = location.search.split("=")[2]?.substring(0, 24);

  const [displayData, setDisplayData] = useState(0);
  const [bookingid, setbookingid] = useState(0);
  const bookid = bookingid;

  const agreementtiltle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img src={agreement} alt="agreement" />
          New Agreement
        </div>
      </div>
    );
  };

  const agreementtab = () => {
    return (
      <div className="main_general flt">
        <div className="main_booking_info1 flt">
          <div className="booking_vehicle flt">Booking</div>
        </div>
      </div>
    );
  };

  const tabDetails = () => {
    return (
      <div className="main_general flt">
        <div className="general_informaion flt">
          <ul className="insurance_info">
            <li
              className={
                displayData === 0
                  ? "insurance_infos"
                  : bid
                    ? "insuranceinfo_show1"
                    : "insuranceinfo_show"
              }
              onClick={() => setDisplayData(0)}
            >
              <Link to={`?cid=${cid}&bid=${bid}`}>Personal Information</Link>
            </li>
            <li
              className={
                displayData === 1
                  ? "insurance_infos"
                  : bid
                    ? "insuranceinfo_show1"
                    : "insuranceinfo_show"
              }
              onClick={() => setDisplayData(1)}
            >
              <Link to={`?cid=${cid}&bid=${bid}`}>Rental Details</Link>
            </li>
            <li
              className={
                displayData === 2
                  ? "insurance_infos"
                  : displayData >= 2
                    ? "insuranceinfo_show1"
                    : "insuranceinfo_show"
              }
              onClick={() => setDisplayData(2)}
            >
              <Link to={`?cid=${cid}&bid=${bid}`}>Document Details</Link>
            </li>
            <li
              className={
                displayData === 3
                  ? "insurance_infos"
                  : displayData >= 2
                    ? "insuranceinfo_show1"
                    : "insuranceinfo_show"
              }
              onClick={() => setDisplayData(3)}
            >
              <Link to={`?cid=${cid}&bid=${bid}`}>Payment Details </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <>
      <TopBar />
      <Menu />
      {agreementtiltle()}
      {agreementtab()}
      {tabDetails()}
      {displayData === 0 && (
        <AddPersonalInfo
          displayData={setDisplayData}
          tab={displayData}
          bookingid={bookingid}
          setbookingid={setbookingid}
          cid={cid}
          t={t}
        />
      )}
      {displayData === 1 && (
        <AddRentalDetailsInfo
          displayData={setDisplayData}
          tab={displayData}
          bookingid={bookingid}
          cid={cid}
          t={t}
        />
      )}
      {displayData === 2 && (
        <DocumentDetails displayData={setDisplayData} bookid={bookid} t={t} />
      )}
      {displayData === 3 && (
        <PaymentDetail
          displayData={setDisplayData}
          tab={displayData}
          bookid={bid}
          t={t}
        />
      )}
    </>
  );
};
export default withTranslation()(AgreeTab);
