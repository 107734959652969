import Menu from "../../../_common/menu/Menu";
import LeftSidebar from "../../../_common/sidebar/LeftSidebar";
import TopBar from "../../../_common/topbar/TopBar";
import { useState } from "react";
import { Link } from "react-router-dom";
import VehicleClassTab from "./VehicleClassTab/VehicleClassTab";
import VehicleMakeTab from "./VehicleMakeTab/VehicleMakeTab";
import VehicleModelTab from "./VehicleModelTab/VehicleModelTab";
import { vehicle } from "../../../../assets/images";
import VehicleCost from "./VehicleCostTab/VehicleCost";
import { withTranslation } from "react-i18next";

const SetupVehicle = (props) => {
  const { t } = props;
  const [tab, setTab] = useState(1);
  const tabDetails = () => {
    return (
      <>
        <div className="E_comp_deta flt">
          <div className="main_boxShadow flt">
            <div className="general_informaion flt">
              <ul className="insurance_info">
                <li className={tab === 1 ? `activeborder` : ""}>
                  <Link onClick={() => setTab(1)} to="">
                    {t("Vehicle Class")}
                  </Link>
                </li>
                <li className={tab === 2 ? `activeborder` : ""}>
                  <Link onClick={() => setTab(2)} to="">
                    {t("Make")}
                  </Link>
                </li>
                <li className={tab === 3 ? `activeborder` : ""}>
                  <Link onClick={() => setTab(3)} to="">
                    {t("Model")}
                  </Link>
                </li>
                <li className={tab === 4 ? `activeborder` : ""}>
                  <Link onClick={() => setTab(4)} to="">
                    {t("Cost")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="main_company flt">
              {tab === 1 && (
                <VehicleClassTab
                  tab={setTab}

                />
              )}
              {tab === 2 && (
                <VehicleMakeTab
                  tab={setTab}

                />
              )}
              {tab === 3 && (
                <VehicleModelTab
                  tab={setTab}

                />
              )}
              {tab === 4 && (
                <VehicleCost
                  tab={setTab}

                />
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
  const titleName = () => {
    return (
      <>
        <div className="company_hedding flt">
          <div className="company_icon flt">
            <img src={vehicle} alt="vehicle" />
            {t("VEHICLES")}
          </div>
        </div>
      </>
    )
  }
  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      <div className="flt">
        <LeftSidebar />
        <div className="chat_right1 compst_vehicle flt">
          {titleName()}
          {tabDetails()}
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(SetupVehicle);
