import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { alertActions } from "../../../../actions/alert.actions";
import { useDispatch } from "react-redux";
import { InvestorService } from "../../../../services/InvestorService";
import moment from "moment";

const useInvestorDetailsHook = (location, t) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [investorName, setInvestorName] = useState(
    location?.state?.row?.investorname ? location?.state?.row?.investorname : ""
  );
  const [mobile, setMobile] = useState(
    location?.state?.row?.mobile ? location?.state?.row?.mobile : ""
  );
  const [email, setEmail] = useState(
    location?.state?.row?.email ? location?.state?.row?.email : ""
  );
  const [investmentAmount, setInvestmentAmount] = useState(
    location?.state?.row?.investmentAmount
      ? location?.state?.row?.investmentAmount
      : ""
  );
  const [invStartDate, setInvStartDate] = useState(
    location?.state?.row?.invStartDate ? location?.state?.row?.invStartDate : ""
  );
  const [invPeriod, setInvPeriod] = useState(
    location?.state?.row?.invPeriod ? location?.state?.row?.invPeriod : ""
  );
  const [bankName, setBankName] = useState(
    location?.state?.row?.bank_Name ? location?.state?.row?.bank_Name : ""
  );
  const [bankHolderName, setBankHolderName] = useState(
    location?.state?.row?.bank_HolderName
      ? location?.state?.row?.bank_HolderName
      : ""
  );
  const [bankIfsc, setBankIfsc] = useState(
    location?.state?.row?.bank_Ifsc ? location?.state?.row?.bank_Ifsc : ""
  );
  const [bankAccountNumber, setBankAccountNumber] = useState(
    location?.state?.row?.bank_AccountNumber
      ? location?.state?.row?.bank_AccountNumber
      : ""
  );
  const [id, setId] = useState(
    location?.state?.row?.id ? location?.state?.row?.id : ""
  );

  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState("notset");

  /**
   * handle change
   * @param {*} e
   */
  const handleInvestorName = (e) => {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setInvestorName(e.target.value);
    } else if (!e.target.value) {
      setInvestorName("");
    }
  };

  const handleMobile_no = (e) => {
    setMobile(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleAmount = (e) => {
    setInvestmentAmount(e.target.value);
  };

  const handleStart_date = (e) => {
    setInvStartDate(moment(e).format("DD/MM/YYYY"));
  };

  const handlePeriod = (e) => {
    setInvPeriod(e.target.value);
  };

  const handleBankName = (e) => {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setBankName(e.target.value);
    } else if (!e.target.value) {
      setBankName("");
    }
  };
  const handleBankHolderName = (e) => {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setBankHolderName(e.target.value);
    } else if (!e.target.value) {
      setBankHolderName("");
    }
  };
  const handleBankIfsc = (e) => {
    if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
      setBankIfsc(e.target.value.toUpperCase());
    } else if (!e.target.value) {
      setBankIfsc("");
    }
  };
  const handleBankAccountNumber = (e) => {
    setBankAccountNumber(e.target.value);
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    navigate("/edit-investor", {
      state: {
        row: row,
      },
    });
  };

  /**
   * navigate
   */
  const navigateToInvestorList = () => {
    navigate("/investors");
  };

  /**
   * add investor api call
   * @param {*} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (investorName) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error(t("enter_valid_email")));
      } else if (mobile && !filter.test(mobile)) {
        dispatch(alertActions.error(t("enter_mobile_no")));
      } else {
        setLoader("loading");
        InvestorService.addInvestor(
          investorName,
          mobile,
          email,
          investmentAmount,
          invStartDate,
          invPeriod,
          bankName,
          bankHolderName,
          bankIfsc,
          bankAccountNumber
        )
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setInvestorName("");
              setMobile("");
              setEmail("");
              setInvestmentAmount("");
              setInvStartDate("");
              setInvPeriod("");
              setBankName("");
              setBankHolderName("");
              setBankAccountNumber("");
              setBankIfsc("");
              setLoader("isfull");
              setSubmitted(false);
              navigate("/investors");
              dispatch(alertActions.success(response?.data?.status?.message));
            } else {
              setLoader("isempty");
              dispatch(alertActions.error(response?.data?.status?.message));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  /**
   * edit investor api call
   * @param {*} e
   */
  const handleEditSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (id && investorName) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error(t("enter_valid_email")));
      } else if (mobile && !filter.test(mobile)) {
        dispatch(alertActions.error(t("enter_mobile_no")));
      } else {
        setLoader("loading");
        InvestorService.editInvestor(
          id,
          investorName,
          mobile,
          email,
          investmentAmount,
          invStartDate,
          invPeriod,
          bankName,
          bankHolderName,
          bankIfsc,
          bankAccountNumber
        )
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setInvestorName("");
              setMobile("");
              setEmail("");
              setInvestmentAmount("");
              setInvStartDate("");
              setInvPeriod("");
              setBankName("");
              setBankHolderName("");
              setBankAccountNumber("");
              setBankIfsc("");
              setId("");
              setLoader("isfull");
              setSubmitted(false);
              navigate("/investors");
              dispatch(alertActions.success(response?.data?.status?.message));
            } else {
              setLoader("isempty");

              dispatch(alertActions.error(response?.data?.status?.message));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  /**
   * delete api call
   * @param {*} e
   */
  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    InvestorService.deleteInvestors(id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setSubmitted(false);
          navigate("/investors");
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((err) => console.log(err));
  };

  return {
    navigateToInvestorList,
    handleSubmit,
    handleInvestorName,
    handleMobile_no,
    handleEmail,
    handleAmount,
    handleStart_date,
    handlePeriod,
    handleBankName,
    handleBankHolderName,
    handleBankIfsc,
    handleBankAccountNumber,
    handleRow,
    handleDeleteSubmit,
    handleEditSubmit,
    loader,
    submitted,
    investorName,
    mobile,
    email,
    investmentAmount,
    invStartDate,
    invPeriod,
    bankName,
    bankHolderName,
    bankIfsc,
    bankAccountNumber,
    id,
  };
};
export default useInvestorDetailsHook;
