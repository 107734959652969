import { useState, useEffect } from "react";
import { ExchangeService } from "../../../../../../../services";
import { useNavigate } from "react-router-dom";

const useExchageCheckoutHook = (setTab, tab, bookid) => {
  const [KMLimit, setKMLimit] = useState("");
  const [odometer, setOdometer] = useState("");
  const [fuellevel, setFuellevel] = useState("");
  const [helmetqty, setHelmetqty] = useState("");
  const [vehicleRes, setVehicleRes] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState("notset");

  const navigate = useNavigate();

  /**
   * get checkout etails
   */
  useEffect(() => {
    setLoader("loading");
    ExchangeService.getCheckoutDetails(bookid)
      .then((res) => {
        if (res?.data?.status?.code === 0) {
          setKMLimit(res?.data?.data?.KM_limit);
          setOdometer(res?.data?.data?.Odo_meter);
          setFuellevel(res?.data?.data?.Fuel_level);
          setHelmetqty(res?.data?.data?.Helmet_qty);
          setLoader("isfull");
        }
      })
      .catch((err) => console.log(err));
    getVehicleDetails(bookid);
    // eslint-disable-next-line
  }, []);

  /**
   * handle change
   * @param {*} e
   */
  const Changehandler = (e) => {
    if (e.target.name === "helmetqty") {
      setHelmetqty(e.target.value);
    }
  };

  /**
   * get vehicle details
   * @param {*} bookid
   */
  const getVehicleDetails = (bookid) => {
    ExchangeService.getVehicleDetails(bookid)
      .then((res) => {
        setVehicleRes(res);
      })
      .catch((err) => console.log(err));
  };

  /**
   * checkout store
   */
  const checkOutStore = () => {
    setSubmit(true);
    if (KMLimit && odometer && fuellevel) {
      setTab(2);
    }
  };
  /**
   * navigation
   */
  const navigateToList = () => {
    navigate("/exchange-list");
  };
  return {
    KMLimit,
    odometer,
    fuellevel,
    helmetqty,
    vehicleRes,
    loader,
    Changehandler,
    checkOutStore,
    navigateToList,
  };
};
export default useExchageCheckoutHook;
