import { useState, useEffect } from "react";
import { EndrentService } from "../../../../../../../services";
import { alertActions } from "../../../../../../../actions/alert.actions";
import { useDispatch } from "react-redux";

const useEndRentCheckIn = (displayData, bookid) => {
  const [KMLimit, setKMLimit] = useState("");
  const [odometer, setOdometer] = useState("");
  const [checkInOdometer, setcheckInOdometer] = useState("");
  const [fuellevel, setFuellevel] = useState("");
  const [checkInfuellevel, setCheckInFuellevel] = useState("");
  const [helmetqty, setHelmetqty] = useState("");
  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState("notset");
  const dispatch = useDispatch();

  /**
   * get checkout details
   */
  useEffect(() => {
    setLoader("loading");
    EndrentService.getCheckoutDetails(bookid)
      .then((res) => {
        if (res?.data?.status?.code === 0) {
          setKMLimit(res?.data?.data?.KM_limit);
          setOdometer(res?.data?.data?.Odo_meter);
          setFuellevel(res?.data?.data?.Fuel_level);
          setHelmetqty(res?.data?.data?.Helmet_qty);
          setLoader("isfull");
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  /**
   * get check in
   */
  useEffect(() => {
    setLoader("loading");
    EndrentService.getCheckin(bookid)
      .then((res) => {
        if (res?.data?.status?.code === 0) {
          setcheckInOdometer(res?.data?.data?.Odo_meter);
          setCheckInFuellevel(res?.data?.data?.Fuel_level);
          setLoader("isfull");
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  /**
   * handle change
   * @param {*} e
   */
  const Changehandler = (e) => {
    if (e.target.name === "odometer") {
      setcheckInOdometer(e.target.value);
    }
    if (e.target.name === "fuellevel") {
      setCheckInFuellevel(e.target.value);
    }
  };

  /**
   * navigate
   */
  const navigateToCheckIn = () => {
    displayData(1);
  };

  /**
   * checkout store
   */
  const checkOutStore = () => {
    setSubmit(true);
    if (checkInOdometer && checkInfuellevel) {
      setLoader("loading");
      EndrentService.store_checkout(checkInOdometer, checkInfuellevel, bookid)
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setSubmit(false);
            setLoader("isfull");
            displayData(3);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return {
    loader,
    checkInOdometer,
    checkInfuellevel,
    submit,
    Changehandler,
    checkOutStore,
    navigateToCheckIn,
  };
};
export default useEndRentCheckIn;
