import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Menu from "../../../_common/menu/Menu";
import TopBar from "../../../_common/topbar/TopBar";
import useVehicleList from "./useVehicleListHook";
import { vehicle } from "../../../../assets/images";
import { Loading } from "../../../../assets/images";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

const InMaintananceList = (props) => {
  const { t } = props;
  const {
    listData,
    totalPages,
    search,
    page,
    loader,
    listTable,
    columnsMaint,
    handlePageClick,
    changeItemHandler,
    changeSearchHandler,
    handleRow,
  } = useVehicleList();
  const dashBoardDisplay = useSelector((state) => state?.userData?.user?.dashboardUA)


  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };
  const customStyles1 = {
    rows: {
      style: {
        cursor: "auto",

      },
    },
  };
  const searchDetails = () => {
    return (<>
      <div className="main_location flt">
        <div className="location flt">
          {" "}
          <img src={vehicle} alt="vehicle" />
          {t("Vehicle Maintenance Lists")}
        </div>

        <div className="main_search_b1 flt">
          <div className="main_search_dd1 ">
            <div className="search_l ">
              <select name="item" id="item" onChange={changeItemHandler}>

                <option value="regNum">{t("Search by Registration No.")}</option>
              </select>
            </div>
            <div className="search_b1 ">
              <input
                type="text"
                placeholder={t("Search..")}
                name="search"
                value={search}
                onChange={changeSearchHandler}
              />
              <button className="search_button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div></>)
  }
  const tableDetails = () => {
    return (
      <>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="table_loader ">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  {listData?.data?.data?.docs?.length !== 0 ? (
                    <>
                      {dashBoardDisplay?.includes("maintenance") ?
                        <DataTable
                          columns={columnsMaint}
                          data={listTable || []}
                          customStyles={customStyles}
                          onRowClicked={(row) => handleRow(row)}
                        /> : <DataTable
                          columns={columnsMaint}
                          data={listTable || []}
                          customStyles={customStyles1}

                        />}</>
                  ) : (
                    <div className="no-data">{t("No Data Found")}</div>
                  )}
                </table>
              )}
              {listData?.data?.data?.docs.length !== 0 && (
                <div>
                  <ReactPaginate
                    nextLabel="&rarr;"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={totalPages}
                    previousLabel="&larr;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    currentPage={page}
                    forcePage={page - 1}
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              )}
            </div>
          </div>
        </div></>
    )
  }




  return (
    <div className="header_location flt">
      <div className="main_container flt">
        <TopBar />
        <Menu />
        {searchDetails()}
        {tableDetails()}


      </div>
    </div>
  );
};
export default withTranslation() (InMaintananceList);
