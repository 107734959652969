import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { agreement } from "../../../../../../assets/images";
import TopBar from "../../../../../_common/topbar/TopBar";
import Menu from "../../../../../_common/menu/Menu";
import EndRentPayment from "./EndRentPayment/EndRentPayment";

const EndRentDetailsTab = () => {
  const location = useLocation();
  const [displayData, setDisplayData] = useState(0);
  const bookid = location?.search?.split("=")[1].substring(0, 24);

  const agreementtiltle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img src={agreement} alt="agreement" />
          Edit Agreement
        </div>
      </div>
    );
  };

  const agreementtab = () => {
    return (
      <div className="main_general flt">
        <div className="main_booking_info1 flt">
          <div className="booking_vehicle flt">Pending Payment</div>
        </div>
      </div>
    );
  };

  const tabDetails = () => {
    return (
      <div className="main_general flt">
        <div className="general_informaion flt">
          <ul className="insurance_info">
            <li
              className={
                displayData === 1 ? "insurance_infos" : "insuranceinfo_show"
              }
              onClick={() => setDisplayData(1)}
            >
              <Link to={`?bid=${bookid}`}>Payment Details </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const tabView = () => {
    return (
      <>
        {displayData === 0 && (
          <EndRentPayment
            displayData={setDisplayData}
            tab={displayData}
            bookid={bookid}
          />
        )}
      </>
    );
  };
  return (
    <>
      <TopBar />
      <Menu />
      {agreementtiltle()}
      {agreementtab()}
      {tabDetails()}
      {tabView()}
    </>
  );
};
export default EndRentDetailsTab;
