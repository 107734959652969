import { useEffect, useState } from "react";
import { alertActions } from "../../../../actions";
import { SetUpService } from "../../../../services";
import { useDispatch } from "react-redux";

const usePaymentTabHook = (props) => {
  const { t } = props;
  let [page1, setPage1] = useState(1);
  const [listData1, setListData1] = useState([]);
  const [totalPages1, setTotalPages1] = useState(0);
  const [paymentType, setPaymentType] = useState("");
  const [id, setId] = useState("");
  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState("notset");
  const [listTable1, setListTable1] = useState([]);

  const dispatch = useDispatch();

  const columns = [
    {
      name: "Sr No.",
      selector: (row) => row.number,
    },
    {
      name: "Payment Type",
      selector: (row) => row.paymentType,
    },
    {
      name: "Delete",
      selector: (row) => (
        <button className="viewdata" onClick={() => deletePaymentTypeList(row)}>
         {t("Delete")} 
        </button>
      ),

    },

  ];
  let order = [];
  let obj = {};

  /**
   * call list response
   */
  useEffect(() => {
    paymentTypeListResponse(page1);
  }, [page1]);

  /**
   * list
   */
  useEffect(() => {
    if (listData1?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData1?.data?.data?.docs]);

  /**
   * list for table
   */

  const list = () => {
    listData1.data.data.docs.map((item, index) => {
      obj = {
        id: item._id,
        number:
          listData1.data.data.limit * (listData1.data.data.page - 1) +
          index +
          1,
        paymentType: item.paymentType,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable1(order);
  };

  /**
   * handle vehicle class
   * @param {*} e 
   */
  const handlePaymentType = (e) => {
    setPaymentType(e.target.value);
  };


  /**
   * get vehicle list
   * @param {*} page1 
   */
  const paymentTypeListResponse = (page1) => {
    setLoader("loading");
    SetUpService.getPaymentType(page1)
      .then((response) => {
        setLoader("isfull");
        setListData1(response);
        setTotalPages1(response?.data?.data?.totalPages);
        setPage1(response?.data?.data?.page);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * handle page change
   * @param {*} e 
   */
  const handlePageClick1 = (e) => {
    setPage1(e.selected + 1);
  };


  /**
   * get row data
   * @param {*} row 
   */
  const handleEditPaymentType = (row) => {
    setPaymentType(row?.paymentType);
    setId(row?.id);
  };

  /**
   * api call
   * @param {*} e 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (paymentType) {
      setLoader("loading");
      SetUpService.addPaymentType(paymentType)
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            paymentTypeListResponse(page1);
            setPaymentType("");
            setLoader("isfull");
            setSubmit(false);

            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };


  /**
   * delete payment type
   * @param {*} row 
   */
  const deletePaymentTypeList = (row) => {
    setLoader("loading");
    SetUpService.deletePaymentType(row?.id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          dispatch(alertActions.success(response?.data?.status?.message));
          paymentTypeListResponse(page1);
          setLoader("isfull");
        } else {
          setLoader("isempty");
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };


  /**
   * edit api call
   * @param {*} e 
   */
  const handleEditSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (id && paymentType) {
      setLoader("loading");
      SetUpService.editPaymentType(id, paymentType)
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            paymentTypeListResponse(page1);
            setPaymentType("");
            setId("");
            setLoader("isfull");
            setSubmit(false);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };
  return {
    loader,
    totalPages1,
    paymentType,
    submit,
    id,
    columns,
    listTable1,
    page1,
    handlePageClick1,
    handlePaymentType,
    handleSubmit,
    handleEditPaymentType,
    handleEditSubmit,
  };
};
export default usePaymentTabHook;
