import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InvestorService } from "../../../../services/InvestorService";

const useInvestorListHook = (t) => {
  let [page, setPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("investorName");
  const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);
  const navigate = useNavigate();

  const columns = [
    {
      name: t("Sr_No"),
      selector: (row) => row.srnumber,
      grow: 1,
    },
    {
      name: t("Investor_Name"),
      selector: (row) => row.investorname,
      grow: 3,
    },
    {
      name: t("Mobile_Number"),
      selector: (row) => row.mobile === "" ? "N/A" : row.mobile,
      grow: 3,
    },
    {
      name: t("Email_Address"),
      selector: (row) => row.email === "" ? "N/A" : row.email,
      grow: 3,
    },
  ];
  let order = [];
  let obj = {};
  /**
   * list
   */
  const list = () => {
    listData?.data?.data?.docs?.map((item, index) => {
      obj = {
        srnumber:
          listData.data.data.limit * (listData.data.data.page - 1) + index + 1,
        investorname: item.investorName ? item.investorName : "",
        mobile: item.mobile_no ? item.mobile_no : "",
        email: item.email ? item.email : "",
        investmentAmount: item.amount ? item.amount : "",
        invStartDate: item.start_date ? item.start_date : "",
        invPeriod: item.period ? item.period : "",
        bank_Name: item.bank_Name ? item.bank_Name : "",
        bank_Ifsc: item.bank_Ifsc ? item.bank_Ifsc : "",
        bank_HolderName: item.bank_HolderName ? item.bank_HolderName : "",
        bank_AccountNumber: item.bank_AccountNumber ? item.bank_AccountNumber : "",
        id: item._id,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };
  /**
   * table list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);
  /**
   * CALL INVEST RESPONSE
   */
  useEffect(() => {
    investorResponse(page, "investorName", "");
  }, []);

  /**
   * invest response
   * @param {*} page 
   * @param {*} item 
   * @param {*} search 
   */
  const investorResponse = (page, item, search) => {
    setLoader("loading");
    InvestorService.getAllInvestors(page, item, search.trim())
      .then((response) => {
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        setLoader("isfull");
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * handle page check
   * @param {*} e 
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    investorResponse(e.selected + 1, item, search);
  };

  /**
   * change handler
   * @param {*} e 
   */
  const changeItemHandler = (e) => {
    if (e.target.value === "mobile_no") {
      setSearch("");
      setItem(e.target.value);

      investorResponse(page, e.target.value, "");
    } else {
      setItem(e.target.value);
      investorResponse(page, e.target.value, "");
    }
  };

  /**
   * search handler
   * @param {*} e 
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    investorResponse("", item, e.target.value);
  };

  /**
   * handle row
   * @param {*} row 
   */
  const handleRow = (row) => {
    navigate("/investor-detail", {
      state: {
        row: row,
      },
    });
  };
  /**
   * navigate
   */
  const navigateToAddInvestor = () => {
    navigate("/investor-detail");
  };

  return {
    listData,
    totalPages,
    search,
    page,
    loader,
    columns,
    listTable,
    handleRow,
    handlePageClick,
    navigateToAddInvestor,
    changeItemHandler,
    changeSearchHandler,
  };
};
export default useInvestorListHook;
