import "../../../../Agreement.css";
import { arrow, Loading } from "../../../../../../../assets/images";
import useExchangeSummaryHook from "./useExchangeSummaryHook";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";

const ExchangeSummary = ({ setTab, tab, bookid }) => {
  const { listData, totalPages, page, columns, listTable, loader, handlePageClick } =
    useExchangeSummaryHook(setTab, tab, bookid);

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "auto",
      },
    },
  };

  const tableListDisplay = () => {
    return (
      <>
        <div className="main_general flt">
          <div className="main_search_b2 flt">
            <img src={arrow} alt="arrow" onClick={() => setTab(1)} />

            <div className="ex_customer flt">Exchange Summary</div>
          </div>
        </div>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="table_loader">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  {listData?.data?.data?.docs?.length !== 0 ? (
                    <DataTable
                      columns={columns}
                      data={listTable || []}
                      customStyles={customStyles}
                    />
                  ) : (
                    <div className="no-data">No Data Found</div>
                  )}

                  {listData?.data?.data?.docs.length !== 0 && (
                    <div>
                      <ReactPaginate
                        nextLabel="&rarr;"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalPages}
                        previousLabel="&larr;"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        forcePage={page - 1}
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  )}
                </table>)}
            </div>
          </div>
        </div>
      </>
    );
  };

  return <div className="main_container flt">{tableListDisplay()}</div>;
};
export default ExchangeSummary;
