import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { Loading } from "../../../../assets/images";
import Menu from "../../../_common/menu/Menu";
import LeftSidebar from "../../../_common/sidebar/LeftSidebar";
import TopBar from "../../../_common/topbar/TopBar";
import useVehicleExpenseTabHook from "./useVehicleExpenseTabHook";
import { withTranslation } from "react-i18next";

const VehicleExpenseTab = (props) => {
  const { t } = props;
  const {
    loader,
    totalPages1,
    vehicleExpense,
    submit,
    id,
    columns,
    listTable1,
    page1,
    handlePageClick1,
    handleVehicleExpense,
    handleSubmit,
    handleEditVehicleExpense,
    handleEditSubmit,
  } = useVehicleExpenseTabHook();



  const expenseTypeDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" || loader === "isempty" || loader === "notset") && (
          <>
            <div className="main_container flt">
              <TopBar />
              <Menu />
              <div className="flt">
                <LeftSidebar />
                <div className="chat_right1 flt">
                  <div className="company_hedding flt">
                    <div className="company_icon flt">
                      {/* <img src={vehicle} alt="vehicle" /> */}
                      {t("VEHICLE EXPENSE")}
                    </div>
                  </div>
                  <div className="E_comp_deta flt">
                    <div className="main_boxShadow flt">
                      <div className="general_informaion flt"></div>
                      <div className="main_company flt">
                        <div className="main_row flt">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="company_name flt">
                                <div className="form-group flt">
                                  <label className="labelName">
                                    {t("Vehicle Expense")} *
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Enter Vehicle Expense"
                                    name="vehicleExpense"
                                    id="vehicleExpense"
                                    onChange={handleVehicleExpense}
                                    value={vehicleExpense}
                                  />
                                  {submit && !vehicleExpense && (
                                    <div className="req_field">
                                      {t("Vehicle Expense is required")}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-2">
                              <div className="edit_details1 edit_details1bb flt">
                                {id === "" && (
                                  <button
                                    className="edit_button1"
                                    onClick={handleSubmit}
                                  >
                                    <i class="fas fa-save"></i>{t("Save")}
                                  </button>
                                )}
                                {id !== "" && (
                                  <button
                                    className="edit_button1"
                                    onClick={handleEditSubmit}
                                  >
                                    <i class="fas fa-save"></i>{t("Update")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12 col-lg-6">
                              <div className="main_tab_ flt">
                                <div className="main_boxShadow flt">
                                  <div className="main_tablehead flt">
                                    <table className="main_table1 flt ">
                                      <DataTable
                                        columns={columns}
                                        data={listTable1 || []}
                                        onRowClicked={(row) =>
                                          handleEditVehicleExpense(row)
                                        }
                                      />
                                    </table>
                                    {listTable1.length > 0 && (
                                      <ReactPaginate
                                        nextLabel="&rarr;"
                                        onPageChange={handlePageClick1}
                                        pageRangeDisplayed={1}
                                        marginPagesDisplayed={1}
                                        pageCount={totalPages1}
                                        previousLabel="&larr;"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                        forcePage={page1 - 1}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )
  }




  return (
    <>
      {expenseTypeDetails()}

    </>
  );
};

export default withTranslation()(VehicleExpenseTab);
