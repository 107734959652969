const SetUpLocation = ({ locationsUA, handleLocation }) => {
  let locationsData = [];
  locationsData = locationsUA ? locationsUA : [];

  const locationDetails = () => {
    return (
      <>
        <div className="main_others flt">

          <ul className="make_new flt">
            <li className="check_btn1">
              <input type="checkbox" id="accessToLocations" name="accessToLocations" value="accessToLocations" checked={
                locationsData.includes("accessToLocations")}
                onChange={(e) => handleLocation(e, locationsData)
                } />
              <label for="accessToLocations">Access to locations</label>
            </li>
          </ul>
        </div>

      </>
    )
  }

  return (
    <>
      {locationDetails()}
    </>
  );
};
export default SetUpLocation;
