import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { ReportService, VehicleService } from "../../../../services";
import { useDispatch, useSelector } from "react-redux";
import { commaValidation } from "../../../../utility";
import { alertActions } from "../../../../actions";
import moment from "moment";

const useInvestorListExpenseHooks = (props) => {
  const { t } = props;
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [investorArray, setInvestorArray] = useState([]);
  const [investorName, setInvestorName] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);
  const [loaderDownLoad, setLoaderDownload] = useState("notset");

  const authdata = useSelector((state) => state?.userData);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    {
      name: t("Sr_No"),
      selector: (row) => row.srnumber,
      width: "80px",
    },
    {
      name: t("Reg_No"),
      selector: (row) => row.regNo,
    },
    {
      name: t("Vehicle_Details"),
      selector: (row) => row.vehicleDetails,
      width: "250px",
    },
    {
      name: t("Rental_Revenues"),
      selector: (row) =>
        row.totalRentExp !== 0 ? `₹${row.totalRentExp}` : "N/A",
    },
    {
      name: t("Vehicle_Expenses"),
      selector: (row) => (row.totalExp !== 0 ? `₹${row.totalExp}` : "N/A"),
    },

    {
      name: t("Net_P/L"),
      selector: (row) => (row.Amount !== 0 ? `₹${row.Amount}` : "N/A"),
    },

    {
      name: t("View"),
      selector: (row) => (
        <button className="viewdata" onClick={() => handleRow(row)}>
          {t("View")}
        </button>
      ),
    },
  ];
  let order = [];
  let obj = {};

  /**
   * call response
   */
  useEffect(() => {
    if (investorName !== "" && (fromDate !== "" || toDate !== "")) {
      investorListResponseExpense(page);
    }
    // eslint-disable-next-line
  }, [page, fromDate, toDate, investorName]);

  /**
   * get investor name
   */
  useEffect(() => {
    VehicleService.getInvestorName()
      .then((response) => {
        setInvestorArray(response?.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   * list call
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * list
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      obj = {
        srnumber:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        vehicleDetails: item?.vehicleClass?.[0]?.vehicleClass
          ? item?.vehicleClass?.[0]?.vehicleClass +
            " " +
            item?.vehicleMake?.[0]?.make +
            " " +
            item?.vehicleModel?.[0]?.model
          : "N/A",
        checkinDetails: item?.checkinDetails,
        checkoutDetails: item?.checkoutDetails,
        paymentDetails: item?.paymentDetails,
        rentalDetails: item?.rentalDetails,
        totalPayments: item?.totalPayments
          ? `₹${commaValidation(item?.totalPayments)}`
          : "N/A",
        regNo: item?.regNumber ? item?.regNumber : "N/A",
        totalRentExp: item?.totalRentExp,
        totalExp: item?.totalExp,
        Amount: item?.netpl,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * handle change
   * @param {*} e
   */
  const handleInvestorName = (e) => {
    setInvestorName(e.target.value);
  };

  /**
   * api call for list
   */
  const investorListResponseExpense = () => {
    if (toDate) {
      if (Date.parse(fromDate) > Date.parse(toDate)) {
        dispatch(
          alertActions.error("End Date Should be Greater than Start Date")
        );
      } else {
        setLoaderDownload("loading");
        ReportService.getInvestorReport(page, fromDate, toDate, investorName)
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setLoaderDownload("isfull");
              getInvestorPaymentAmount();
              setListData(response);
              setTotalPages(response?.data?.data?.totalPages);
              setPage(response.data.data.page);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  /**
   * api call for amount
   */
  const getInvestorPaymentAmount = () => {
    ReportService.getInvestorPaymentAmount(fromDate, toDate, investorName)
      .then((response) => {
        setTotalAmount(
          response?.data?.data?.docs?.[0]?.total
            ? response?.data?.data?.docs?.[0]?.total
            : 0
        );
      })
      .catch((err) => console.log(err));
  };

  /**
   * download
   */
  const downloadReport = () => {
    setLoader("loading");
    axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_APIURL}/admin/reports/investors-excel/${investorName}?from=${fromDate}&to=${toDate}`,

      method: "GET",
      responseType: "arraybuffer",

      headers: {
        // Add any auth token here
        authorization: authdata?.type + " " + authdata?.token,
        "Content-Type": "blob",
      },
      // Attaching the form data
    }).then((response) => {
      setLoader("isfull");
      const resp = response;
      const link = document.createElement("a");
      const fileName = `${Date.now()}.xlsx`;
      link.setAttribute("download", fileName);
      link.href = URL.createObjectURL(new Blob([resp.data]));
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    console.log("row", row);
    navigate("/InvestorListDetails", { state: { row: row } });
  };

  /**
   * handle change
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };
  const handleStartDate = (e) => {
    setFromDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleEndDate = (e) => {
    setToDate(moment(e).format("DD/MM/YYYY"));
  };

  return {
    page,
    loader,
    listData,
    totalPages,
    fromDate,
    toDate,
    investorArray,
    investorName,
    totalAmount,
    columns,
    listTable,
    loaderDownLoad,
    handlePageClick,
    handleStartDate,
    handleEndDate,
    handleInvestorName,
    downloadReport,
  };
};
export default useInvestorListExpenseHooks;
