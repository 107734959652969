import { SAVE_USER_DATA, SESSION_EXPIRE, USER_LOGOUT } from "./types";
import { UserService } from "../services/index";

/**
 * LOgin action
 * @param {*} userName 
 * @param {*} password 
 * @returns 
 */
export const userLogin = (userName, password) => async (dispatch) => {
  const data = await UserService.login(userName, password);
  if (data.data.status.code === 0) {
    dispatch({
      type: SAVE_USER_DATA,
      payload: {
        token: data.data.data.access_token,
        user: data.data.data.user,
        type: data.data.data.token_type,
      },
    });
  }
  return await Promise.resolve(data);
};

/**
 * Logout action
 * @returns 
 */
export function userLogout() {
  return function (dispatch) {
    const action = {
      type: USER_LOGOUT,
    };
    localStorage.clear();

    dispatch(action);
  };
}
/**
 * Session expire
 * @param {*} dispatch 
 * @returns 
 */
export const sessionExpire = (dispatch) => {
  dispatch({
    type: SESSION_EXPIRE,
  });
  return;
};
