import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Menu from "../../../../../../_common/menu/Menu";
import TopBar from "../../../../../../_common/topbar/TopBar";
import agreements from "../../../../../../../assets/images/Agreement.png";
import BookingAddPersonalInfo from "./WalkinPersonalInfoTab/WalkinAddPersonalInfo";
import AddRentalDetailsInfo from "./WalkinRentalInfoTab/WalkinAddRentalDetailsInfo";
import DocumentDetails from "./BookingDocumentDetailsInfo/BookingDocumentDetailsInfo";
import PaymentDetail from "./BookingPaymentDetails/BookingPaymentDetails";
import CheckOut from "./BookingCheckOut/CheckOut";

const WalkinBookingAgreeTab = () => {
  const [displayData, setDisplayData] = useState(0);

  const location = useLocation();
  const cid = location.search.split("=")[1]?.substring(0, 24);
  const bid = location.search.split("=")[2]?.substring(0, 24);

  const agreementtiltle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img src={agreements} alt="agreement" />
          New Agreement
        </div>
      </div>
    );
  };

  const agreementtab = () => {
    return (
      <div className="main_general flt">
        <div className="main_booking_info1 flt">
          <div className="booking_vehicle flt">Start Rent Booking</div>
        </div>
      </div>
    );
  };

  const tabDetails = () => {
    return (
      <div className="main_general flt">
        <div className="general_informaion flt">
          <ul className="insurance_info">
            <li
              className={
                displayData === 0
                  ? "insurance_infos"
                  : bid
                  ? "insuranceinfo_show1"
                  : "insuranceinfo_show"
              }
              onClick={() => setDisplayData(0)}
            >
              <Link to={`?cid=${cid}&bid=${bid}`}>Personal Information</Link>
            </li>
            <li
              className={
                displayData === 1
                  ? "insurance_infos"
                  : bid
                  ? "insuranceinfo_show1"
                  : "insuranceinfo_show"
              }
              onClick={() => setDisplayData(1)}
            >
              <Link to={`?cid=${cid}&bid=${bid}`}>Rental Details</Link>
            </li>
            <li
              className={
                displayData === 2
                  ? "insurance_infos"
                  : displayData >= 2
                  ? "insuranceinfo_show1"
                  : "insuranceinfo_show"
              }
              onClick={() => setDisplayData(2)}
            >
              <Link to={`?cid=${cid}&bid=${bid}`}>Document Details</Link>
            </li>
            <li
              className={
                displayData === 3
                  ? "insurance_infos"
                  : displayData >= 2
                  ? "insuranceinfo_show1"
                  : "insuranceinfo_show"
              }
              onClick={() => setDisplayData(3)}
            >
              <Link to={`?cid=${cid}&bid=${bid}`}>Payment Details </Link>
            </li>
            <li
              className={
                displayData === 4
                  ? "insurance_infos"
                  : displayData >= 2
                  ? "insuranceinfo_show1"
                  : "insuranceinfo_show"
              }
              onClick={() => setDisplayData(4)}
            >
              <Link to={`?cid=${cid}&bid=${bid}`}>CheckOut </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const tabView = () => {
    return (
      <>
        {displayData === 0 && (
          <BookingAddPersonalInfo
            displayData={setDisplayData}
            cid={cid}
            bid={bid}
          />
        )}
        {displayData === 1 && (
          <AddRentalDetailsInfo
            displayData={setDisplayData}
            cid={cid}
            bid={bid}
          />
        )}
        {displayData === 2 && (
          <DocumentDetails displayData={setDisplayData} cid={cid} bid={bid} />
        )}
        {displayData === 3 && (
          <PaymentDetail displayData={setDisplayData} cid={cid} bid={bid} />
        )}
        {displayData === 4 && (
          <CheckOut displayData={setDisplayData} cid={cid} bid={bid} />
        )}
      </>
    );
  };
  return (
    <>
      <TopBar />
      <Menu />
      {agreementtiltle()}
      {agreementtab()}
      {tabDetails()}
      {tabView()}
    </>
  );
};
export default WalkinBookingAgreeTab;
