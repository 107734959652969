import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Menu from "../../../../_common/menu/Menu";
import TopBar from "../../../../_common/topbar/TopBar";
import { pending } from "../../../../../assets/images";
import usePendingPaymentList from "./usePendingPaymentList";
import { Loading } from "../../../../../assets/images";
import { Calender } from "../../../../../assets/images";
import { withTranslation } from "react-i18next";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const PendingPaymentList = (props) => {
  const { t } = props;
  const {
    listData,
    totalPages,
    page,
    loader,
    search,
    item,
    fromDate,
    noData,
    columns,
    listTable,
    handleStartDate,
    handlePageClick,
    changeSearchHandler,
    changeItemHandler,
  } = usePendingPaymentList(props);

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };
  const searchDetails = () => {
    return (
      <>
        <div className="main_location flt">
          <div className="location flt">
            {" "}
            <img src={pending} alt="images" />
            {t("Pending Payment Lists")}
          </div>
          <div className="main_search_b1 flt">
            <div className="main_search_dd1 pendpay_main">
              <div className="search_l ">
                <select name="item" id="item" onChange={changeItemHandler}>
                  <option default value="mobileno">
                    {t("Search by Mobile No.")}
                  </option>
                  <option value="customername">
                    {t("Search by Customer name.")}
                  </option>

                  <option value="regNumber">
                    {t("Search by Registration No.")}
                  </option>
                  <option value="duedate">{t("Search by Due Date.")}</option>
                </select>
              </div>
              {item !== "duedate" ? (
                <div className="search_b1 ">
                  <input
                    type="text"
                    placeholder={t("Search")}
                    name="search"
                    value={search}
                    onChange={changeSearchHandler}
                  />
                  <button className="search_button">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              ) : (
                <div className="form-group flt">
                  <Datetime
                    dateFormat="DD/MM/YYYY"
                    onChange={handleStartDate}
                    value={fromDate}
                    closeOnSelect={true}
                    timeFormat={false}
                    inputProps={{
                      placeholder: t("Select Date"),
                      disabled: false,
                      readOnly: true,
                      onKeyDown: (e) => e.preventDefault(),
                    }}
                  />
                  <div className="cal_img">
                    <img src={Calender} alt="calender" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  const tableDetails = () => {
    return (
      <>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="table_loader ">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  {listData?.data?.data?.docs?.length !== 0 &&
                    noData === false ? (
                    <DataTable
                      columns={columns}
                      data={listTable || []}
                      customStyles={customStyles}
                    />
                  ) : (
                    <div className="no-data">{t("No Data Found")}</div>
                  )}

                  {listData?.data?.data?.docs.length !== 0 && noData === false && (
                    <div>
                      <ReactPaginate
                        nextLabel="&rarr;"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalPages}
                        previousLabel="&larr;"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        currentPage={page}
                        forcePage={page - 1}
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  )}
                </table>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="header_location flt">
      <div className="main_container flt">
        <TopBar />
        <Menu />
        {searchDetails()}
        {tableDetails()}
      </div>
    </div>
  );
};
export default withTranslation()(PendingPaymentList);
