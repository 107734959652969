import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { AgreementService } from "../../../../../../../services";

const useBookingHooks = (location) => {
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayData, setDisplayData] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("mobileno");
  const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);
  let order = [];
  let obj = {};

  const columns = [
    {
      name: "Sr No ",
      selector: (row) => row.number,
      width: "80px",
    },
    {
      name: "Customer Name",
      selector: (row) => row.fullname,
      width: "220px",
    },

    {
      name: "Mobile Number",
      selector: (row) => row.mobileno,
      width: "150px",
    },
    {
      name: "Start Date Time",
      selector: (row) => row.StartDateTime,
      width: "180px",
    },
    {
      name: "End Date Time",
      selector: (row) => row.EndDateTime,
      width: "180px",
    },
    {
      name: "No. of Days",
      selector: (row) => (row.NoOFDays ? row.NoOFDays : "N/A"),
      width: "120px",
    },
    {
      name: "Vehicle Details",
      selector: (row) => row.vehicleDetails,
      width: "300px",
    },
    {
      name: "Paid Amount",
      selector: (row) =>
        row.Advance_Amount !== 0 ? `₹${row.Advance_Amount.toFixed(2)}` : "N/A",
      width: "150px",
    },
    {
      name: "Balanced Amount",
      selector: (row) =>
        row.Balance_Amount !== 0 ? `₹${row.Balance_Amount.toFixed(2)}` : "N/A",
      width: "150px",
    },
    {
      name: "View",
      selector: (row) => (
        <button className="viewdata" onClick={() => handleRow(row)}>
          View
        </button>
      ),
      width: "150px",
    },
  ];

  /**
   * list call
   */
  useEffect(() => {
    bookingResponse(page, item, search);
    // eslint-disable-next-line
  }, []);

  /**
   * table list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * table list
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      let startDate = item?.startdate ? new Date(item?.startdate) : "N/A";
      let endDate = item?.enddate ? new Date(item?.enddate) : "N/A";
      obj = {
        id: item?._id,
        number:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        fullname: item?.customername ? item?.customername : "N/A",
        mobileno: item?.customermobileno ? item?.customermobileno : "N/A",
        StartDateTime: startDate?.toLocaleString("en-IN"),
        EndDateTime: endDate?.toLocaleString("en-IN"),
        NoOFDays: item?.noofdays ? item?.noofdays : "N/A",
        vehicleDetails: item?.vehicledetailsclass
          ? item?.vehicledetailsclass
            ? item?.vehicledetailsclass +
            " " +
            item?.vehicledetailsmake +
            " " +
            item?.vehicledetailsmodel
            : ""
          : "N/A",

        Advance_Amount: item?.paidamount,
        Balance_Amount: item?.pendingamount,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };
  /**
   * list api call
   * @param {*} page
   * @param {*} item
   * @param {*} search
   */
  const bookingResponse = (page, item, search) => {
    setLoader("loading");
    AgreementService.getReservationList(page, item, search.trim())
      .then((response) => {
        setLoader("isfull");
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
      })
      .catch((err) => console.log(err));
  };

  /**
   * search handler
   * @param {*} e
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    bookingResponse("", item, e.target.value);
  };

  /**
   * change handler
   * @param {*} e
   */
  const changeItemHandler = (e) => {
    setSearch("");
    if (e.target.value === "fullname") {
      setItem(e.target.value);

      bookingResponse(1, e.target.value, "");
    } else {
      setItem(e.target.value);
      bookingResponse(1, e.target.value, "");
    }
  };
  /**
   * navigation
   */
  const navigateTohistory = () => {
    setDisplayData(1);
  };

  const personalData = (val) => {
    setDisplayData(val);
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    navigate(`/BookingOtpscreen?bid=${row.id}`, {
      state: {
        row: row,
      },
    });
  };

  /**
   * handle page
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    bookingResponse(e.selected + 1, item, search);
  };

  const navigateToaddAgreement = (val) => {
    navigate("/agreeTab");
  };
  /**
   * navigate to history list
   * @param {*} row
   */
  const navigateTohistoryData = (row) => {
    navigate("/BookingHistrory", {
      state: {
        row: row,
      },
    });
  };

  return {
    loader,
    listData,
    totalPages,
    displayData,
    page,
    search,
    columns,
    listTable,
    handlePageClick,
    navigateToaddAgreement,
    navigateTohistory,
    personalData,
    navigateTohistoryData,
    changeItemHandler,
    changeSearchHandler,
  };
};
export default useBookingHooks;
