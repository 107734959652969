import { useEffect, useState } from "react";
import { AgreementService, VehicleService } from "../../../../../services";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../../../../actions";
import moment from "moment";

const useReservationList = (props) => {
  const { t } = props;
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("mobileno");
  const navigate = useNavigate();
  const [loader, setLoader] = useState("notset");
  const [fromDate, setFromDate] = useState("");
  const [noData, setNoData] = useState(false);
  const [locationArray, setLocationArray] = useState([]);
  const [listTable, setListTable] = useState([]);
  const dispatch = useDispatch();
  const dashBoardDisplay = useSelector(
    (state) => state?.userData?.user?.dashboardUA
  );
  const columns = [
    {
      name: t("Sr_No"),
      selector: (row) => row.number,
      width: "80px",
    },
    {
      name: t("Name"),
      selector: (row) => row.fullname,
      width: "250px",
    },

    {
      name: t("Mobile"),
      selector: (row) => row.mobileno,
      width: "150px",
    },
    {
      name: t("Model"),
      selector: (row) => row.vehicleDetails,
      width: "150px",
    },
    {
      name: t("Start_D-T"),
      selector: (row) => row.StartDateTime,
      width: "180px",
    },
    {
      name: t("End_D-T"),
      selector: (row) => row.EndDateTime,
      width: "180px",
    },
    {
      name: t("Days"),
      selector: (row) => (row.NoOFDays ? row.NoOFDays : "N/A"),
      width: "150px",
    },

    {
      name: t("Paid_Amt"),
      selector: (row) =>
        row.Advance_Amount !== 0 ? `₹${row.Advance_Amount.toFixed(2)}` : "N/A",
      width: "150px",
    },
    {
      name: t("Bal_Amt"),
      selector: (row) =>
        row.Balance_Amount !== 0 ? `₹${row.Balance_Amount.toFixed(2)}` : "N/A",
      width: "150px",
    },
    {
      name: t("Checkout-Loc"),
      selector: (row) => row.pickupLocation,
      width: "150px",
    },
    {
      name: t("View"),
      selector: (row) =>
        dashBoardDisplay?.includes("Bookings") ? (
          <button className="viewdata" onClick={() => handleRow(row)}>
            {t("View")}
          </button>
        ) : (
          <button
            style={{ pointerEvents: "none", opacity: "0.5" }}
            className="viewdata"
            onClick={() => handleRow(row)}
          >
            {t("View")}
          </button>
        ),
      width: "150px",
    },
    {
      name: t("Delete"),
      selector: (row) =>
        dashBoardDisplay?.includes("Bookings") ? (
          <button className="viewdata" onClick={() => deleteBooking(row)}>
            {t("Delete")}
          </button>
        ) : (
          <button
            style={{ pointerEvents: "none", opacity: "0.5" }}
            className="viewdata"
            onClick={() => deleteBooking(row)}
          >
            {t("Delete")}
          </button>
        ),
      width: "150px",
    },
  ];
  let order = [];
  let obj = {};

  /**
   * get reservation response
   */
  useEffect(() => {
    reservationResponse(page, item, search, fromDate);

    // eslint-disable-next-line
  }, []);

  /**
   * call location
   */
  useEffect(() => {
    if (item === "location") {
      selectLocation();
    }
  }, [item]);

  /**
   * table list call
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);
  /**
   * table list
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      let startDate = item?.startdate ? new Date(item?.startdate) : "N/A";
      let endDate = item?.enddate ? new Date(item?.enddate) : "N/A";
      obj = {
        id: item?._id,
        number:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        fullname: item?.customername ? item?.customername : "N/A",
        mobileno: item?.customermobileno ? item?.customermobileno : "N/A",
        StartDateTime: startDate?.toLocaleString("en-IN"),
        EndDateTime: endDate?.toLocaleString("en-IN"),
        NoOFDays: item?.noofdays ? item?.noofdays : "N/A",
        vehicleDetails: item?.vehicledetailsmodel
          ? item?.vehicledetailsmodel
          : "N/A",

        Advance_Amount: item?.paidamount,
        Balance_Amount: item?.pendingamount,
        pickupLocation: item?.pickupLocation ? item?.pickupLocation : "N/A",
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * list api call
   * @param {*} page
   * @param {*} item
   * @param {*} search
   * @param {*} fromDate
   */
  const reservationResponse = (page, item, search, fromDate) => {
    setLoader("loading");
    AgreementService.getReservationList(page, item, search.trim(), fromDate)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setNoData(false);
          setListData(response);
          setTotalPages(response?.data?.data?.totalPages);
          setPage(response?.data?.data?.page);
          setLoader("isfull");
        } else {
          setLoader("empty");
          setNoData(true);
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * get location
   */
  const selectLocation = () => {
    VehicleService.getLocationName()
      .then((response) => {
        setLocationArray(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  /**
   * handle change
   * @param {*} e
   */
  const handleStartDate = (e) => {
    setFromDate(moment(e).format("DD/MM/YYYY"));
    reservationResponse(page, item, search, moment(e).format("DD/MM/YYYY"));
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    navigate(`/BookingAgreeTab?bid=${row?.id}`);
  };

  /**
   * change handler
   * @param {*} e
   */
  const changeItemHandler = (e) => {
    setSearch("");
    setFromDate("");

    if (e.target.value === "fullname") {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", "");
    } else if (e.target.value === "model") {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", "");
    } else if (e.target.value === "location") {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", fromDate);
    } else {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", "");
    }
  };

  /**
   * search handler
   * @param {*} e
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    reservationResponse("", item, e.target.value, fromDate);
  };

  /**
   * handle page
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    reservationResponse(e.selected + 1, item, search, fromDate);
  };

  /**
   * delete booking list
   * @param {*} row
   */
  const deleteBooking = (row) => {
    setLoader("loading");
    AgreementService.deleteBookingList(row?.id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          dispatch(alertActions.success(response?.data?.status?.message));
          reservationResponse(page, item, search, fromDate);
          setLoader("isfull");
        } else {
          setLoader("isempty");
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  return {
    loader,
    listData,
    totalPages,
    search,
    page,
    item,
    fromDate,
    noData,
    locationArray,
    columns,
    listTable,
    handlePageClick,
    changeItemHandler,
    changeSearchHandler,
    handleStartDate,
  };
};
export default useReservationList;
