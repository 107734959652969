const SetUpTopRibbon = ({ topRibbonUA,
  handleTopRibbon, }) => {
  let topRibbonData = [];
  topRibbonData = topRibbonUA ? topRibbonUA : [];
  const topRibbonDetails = () => {
    return (
      <>
        <div className="configuration_setup">

          <ul className="make_new flt">
            <li className="check_btn3">
              <input type="checkbox" id="newAgreement" name="newAgreement" value="newAgreement" checked={
                topRibbonData.includes("newAgreement")}
                onChange={(e) => handleTopRibbon(e, topRibbonData)
                } />
              <label for="newAgreement">New Agreement</label>
            </li>

            <li className="check_btn3">
              <input type="checkbox" id="dayToDayTask" name="dayToDayTask" value="dayToDayTask" checked={
                topRibbonData.includes("dayToDayTask")}
                onChange={(e) => handleTopRibbon(e, topRibbonData)
                } />
              <label for="dayToDayTask"> Day-to Day Task</label>
            </li>

            <li className="check_btn3">
              <input type="checkbox" id="vehicles" name="vehicles" value="vehicles" checked={
                topRibbonData.includes("vehicles")}
                onChange={(e) => handleTopRibbon(e, topRibbonData)
                } />
              <label for="vehicles">Vehicles</label>
            </li>
            <li className="check_btn3">
              <input type="checkbox" id="invoices" name="invoices" value="invoices" checked={
                topRibbonData.includes("invoices")}
                onChange={(e) => handleTopRibbon(e, topRibbonData)
                } />
              <label for="invoices">Invoices</label>
            </li>
            <li className="check_btn3">
              <input type="checkbox" id="customers" name="customers" value="customers" checked={
                topRibbonData.includes("customers")}
                onChange={(e) => handleTopRibbon(e, topRibbonData)
                } />
              <label for="customers">Customers</label>
            </li>
            <li className="check_btn3">
              <input type="checkbox" id="investors" name="investors" value="investors" checked={
                topRibbonData.includes("investors")}
                onChange={(e) => handleTopRibbon(e, topRibbonData)
                } />
              <label for="investors">Investors</label>
            </li>
            <li className="check_btn3">
              <input type="checkbox" id="reports" name="reports" value="reports" checked={
                topRibbonData.includes("reports")}
                onChange={(e) => handleTopRibbon(e, topRibbonData)
                } />
              <label for="reports">Reports</label>
            </li>
            <li className="check_btn3">
              <input type="checkbox" id="setUp" name="setUp" value="setUp" checked={
                topRibbonData.includes("setUp")}
                onChange={(e) => handleTopRibbon(e, topRibbonData)
                } />
              <label for="setUp">Setup</label>
            </li>
          </ul>
        </div>
      </>
    )
  }

  return (
    <>
      {topRibbonDetails()}
    </>
  );
};
export default SetUpTopRibbon;
