import * as axios from "../network/Network";

const config = require("../network/api.json");

class VehicleService {
  /**
   * Add veh gen info
   * @param {*} stockNumber 
   * @param {*} regNumber 
   * @param {*} chasisNumber 
   * @param {*} make 
   * @param {*} model 
   * @param {*} year 
   * @param {*} ODOMeterNo 
   * @param {*} color 
   * @param {*} transmission 
   * @param {*} vehicleClass 
   * @param {*} startDate 
   * @param {*} dateSold 
   * @param {*} fuelTypeCapacity 
   * @param {*} salePrice 
   * @param {*} location 
   * @param {*} purchasePrice 
   * @param {*} investorName 
   * @param {*} basis 
   * @param {*} availabilty 
   * @returns 
   */
  static addvehicleGeneralInfo(
    stockNumber,
    regNumber,
    chasisNumber,
    make,
    model,
    year,
    ODOMeterNo,
    color,
    transmission,
    vehicleClass,
    startDate,
    dateSold,
    fuelTypeCapacity,
    salePrice,
    location,
    purchasePrice,
    investorName,
    basis,
    availabilty
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addvehiclesGeneralInfo,
      data: {
        stockNumber,
        regNumber,
        chasisNumber,
        make,
        model,
        year,
        ODOMeterNo,
        color,
        transmission,
        vehicleClass,
        startDate,
        dateSold,
        fuelTypeCapacity,
        salePrice,
        location,
        purchasePrice,
        investorName,
        basis,
        availabilty,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * Edit veh gen info
   * @param {*} id 
   * @param {*} stockNumber 
   * @param {*} regNumber 
   * @param {*} chasisNumber 
   * @param {*} make 
   * @param {*} model 
   * @param {*} year 
   * @param {*} ODOMeterNo 
   * @param {*} color 
   * @param {*} transmission 
   * @param {*} vehicleClass 
   * @param {*} startDate 
   * @param {*} dateSold 
   * @param {*} fuelTypeCapacity 
   * @param {*} salePrice 
   * @param {*} location 
   * @param {*} purchasePrice 
   * @param {*} investorName 
   * @param {*} basis 
   * @param {*} availabilty 
   * @returns 
   */
  static editvehicleGeneralInfo(
    id,
    stockNumber,
    regNumber,
    chasisNumber,
    make,
    model,
    year,
    ODOMeterNo,
    color,
    transmission,
    vehicleClass,
    startDate,
    dateSold,
    fuelTypeCapacity,
    salePrice,
    location,
    purchasePrice,
    investorName,
    basis,
    availabilty
  ) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editvehiclesGeneralInfo + `/${id}`,
      data: {
        stockNumber,
        regNumber,
        chasisNumber,
        make,
        model,
        year,
        ODOMeterNo,
        color,
        transmission,
        vehicleClass,
        startDate,
        dateSold,
        fuelTypeCapacity,
        salePrice,
        location,
        purchasePrice,
        investorName,
        basis,
        availabilty,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * Update odometer info
   * @param {*} id 
   * @param {*} ODOMeterNo 
   * @returns 
   */
  static updateOdometerInfo(id, ODOMeterNo) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editvehiclesGeneralInfo + `/${id}`,
      data: {
        ODOMeterNo,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * Add veh doc
   * @param {*} id 
   * @param {*} insuranceCompanyName 
   * @param {*} insuranceNo 
   * @param {*} insuranceYear 
   * @param {*} insuranceType 
   * @param {*} insuranceAmount 
   * @param {*} insuranceStartDate 
   * @param {*} insuranceExpireDate 
   * @param {*} pucExpiryDate 
   * @param {*} fitnessExpiryDate 
   * @param {*} RCExpiryDate 
   * @param {*} permitExpiryDate 
   * @param {*} pucExpImage 
   * @param {*} fitnessExpImage 
   * @param {*} rcExpImage 
   * @param {*} permitExpImage 
   * @param {*} insuranceExpImage 
   * @returns 
   */
  static addvehicleDocument(
    id,
    insuranceCompanyName,
    insuranceNo,
    insuranceYear,
    insuranceType,
    insuranceAmount,
    insuranceStartDate,
    insuranceExpireDate,
    pucExpiryDate,
    fitnessExpiryDate,
    RCExpiryDate,
    permitExpiryDate,
    pucExpImage,
    fitnessExpImage,
    rcExpImage,
    permitExpImage,
    insuranceExpImage,
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("insuranceCompanyName", insuranceCompanyName);
    bodyFormData.append("insuranceNo", insuranceNo);
    bodyFormData.append("insuranceYear", insuranceYear);
    bodyFormData.append("insuranceType", insuranceType);
    bodyFormData.append("insuranceAmount", insuranceAmount);
    bodyFormData.append("insuranceStartDate", insuranceStartDate);
    bodyFormData.append("insuranceExpireDate", insuranceExpireDate);
    bodyFormData.append("pucExpiryDate", pucExpiryDate);
    bodyFormData.append("fitnessExpiryDate", fitnessExpiryDate);
    bodyFormData.append("RCExpiryDate", RCExpiryDate);
    bodyFormData.append("permitExpiryDate", permitExpiryDate);
    bodyFormData.append("pucExpImage", pucExpImage);
    bodyFormData.append("fitnessExpImage", fitnessExpImage);
    bodyFormData.append("rcExpImage", rcExpImage);
    bodyFormData.append("permitExpImage", permitExpImage);
    bodyFormData.append("insuranceExpImage", insuranceExpImage);

    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addvehiclesDocument + `/${id}`,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  /**
   * Update veh doc
   * @param {*} id 
   * @param {*} insuranceCompanyName 
   * @param {*} insuranceNo 
   * @param {*} insuranceYear 
   * @param {*} insuranceType 
   * @param {*} insuranceAmount 
   * @param {*} insuranceStartDate 
   * @param {*} insuranceExpireDate 
   * @param {*} pucExpiryDate 
   * @param {*} fitnessExpiryDate 
   * @param {*} RCExpiryDate 
   * @param {*} permitExpiryDate 
   * @param {*} pucExpImage 
   * @param {*} fitnessExpImage 
   * @param {*} rcExpImage 
   * @param {*} permitExpImage 
   * @param {*} insuranceExpImage 
   * @returns 
   */
  static editvehicleDocument(
    id,
    insuranceCompanyName,
    insuranceNo,
    insuranceYear,
    insuranceType,
    insuranceAmount,
    insuranceStartDate,
    insuranceExpireDate,
    pucExpiryDate,
    fitnessExpiryDate,
    RCExpiryDate,
    permitExpiryDate,
    pucExpImage,
    fitnessExpImage,
    rcExpImage,
    permitExpImage,
    insuranceExpImage,
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("insuranceCompanyName", insuranceCompanyName);
    bodyFormData.append("insuranceNo", insuranceNo);
    bodyFormData.append("insuranceYear", insuranceYear);
    bodyFormData.append("insuranceType", insuranceType);
    bodyFormData.append("insuranceAmount", insuranceAmount);
    bodyFormData.append("insuranceStartDate", insuranceStartDate);
    bodyFormData.append("insuranceExpireDate", insuranceExpireDate);
    bodyFormData.append("pucExpiryDate", pucExpiryDate);
    bodyFormData.append("fitnessExpiryDate", fitnessExpiryDate);
    bodyFormData.append("RCExpiryDate", RCExpiryDate);
    bodyFormData.append("permitExpiryDate", permitExpiryDate);
    bodyFormData.append("pucExpImage", pucExpImage);
    bodyFormData.append("fitnessExpImage", fitnessExpImage);
    bodyFormData.append("rcExpImage", rcExpImage);
    bodyFormData.append("permitExpImage", permitExpImage);
    bodyFormData.append("insuranceExpImage", insuranceExpImage);

    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editvehiclesDocument + `/${id}`,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  /**
   * Get all veh
   * @param {*} page 
   * @param {*} value 
   * @param {*} search 
   * @param {*} status 
   * @returns 
   */
  static getAllVehicles(page, value, search, status) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getallvehicles}?page=${page}&searchby=${value}&value=${search}&status=${status}`,
    });
    return Promise.resolve(result);
  }

  /**
   * get single doc
   * @param {*} id 
   * @returns 
   */
  static getSingleVehicleDoc(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getSingleVehicleDoc + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * get single veh maint.
   * @param {*} id 
   * @returns 
   */
  static getSingleVehicleMaintainance(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getSingleVehicleMaintainance + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * Get single veh damage
   * @param {*} id 
   * @returns 
   */
  static getSingleVehicleDamage(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getSingleVehicleDamage + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * get single veh sold
   * @param {*} id 
   * @returns 
   */
  static getSingleVehicleSold(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getSingleVehicleSold + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * get single veh charge
   * @param {*} id 
   * @returns 
   */
  static getSingleVehicleCharge(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getSingleVehicleCharge + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * add maint.
   * @param {*} id 
   * @param {*} kms_at_last_service 
   * @param {*} Service_Interval_Km 
   * @param {*} maintanance_Last_Service_Date 
   * @param {*} maintanance_Next_Service_Date 
   * @returns 
   */
  static addMaintenanceData(
    id,
    kms_at_last_service,
    Service_Interval_Km,
    maintanance_Last_Service_Date,
    maintanance_Next_Service_Date
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addMaintanance + `/${id}`,
      data: {
        kms_at_last_service,
        Service_Interval_Km,
        maintanance_Last_Service_Date,
        maintanance_Next_Service_Date,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * edit maint.
   * @param {*} id 
   * @param {*} kms_at_last_service 
   * @param {*} Service_Interval_Km 
   * @param {*} maintanance_Last_Service_Date 
   * @param {*} maintanance_Next_Service_Date 
   * @returns 
   */
  static editMaintenanceData(
    id,
    kms_at_last_service,
    Service_Interval_Km,
    maintanance_Last_Service_Date,
    maintanance_Next_Service_Date
  ) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editMaintanance + `/${id}`,
      data: {
        kms_at_last_service,
        Service_Interval_Km,
        maintanance_Last_Service_Date,
        maintanance_Next_Service_Date,
      },
    });
    return Promise.resolve(result);
  }
  /**
   * add damage data
   * @param {*} id 
   * @param {*} Customer_Name 
   * @param {*} Rental_Start_Date 
   * @param {*} Rental_End_Date 
   * @param {*} Damage_Cost_Received 
   * @param {*} Damage_Repair_Charges 
   * @returns 
   */
  static addDamageData(
    id,
    Customer_Name,
    Rental_Start_Date,
    Rental_End_Date,
    Damage_Cost_Received,
    Damage_Repair_Charges
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addDamageData + `/${id}`,
      data: {
        Customer_Name,
        Rental_Start_Date,
        Rental_End_Date,
        Damage_Cost_Received,
        Damage_Repair_Charges,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * Edit damage
   * @param {*} id 
   * @param {*} vehicleID 
   * @param {*} Customer_Name 
   * @param {*} Rental_Start_Date 
   * @param {*} Rental_End_Date 
   * @param {*} Damage_Cost_Received 
   * @param {*} Damage_Repair_Charges 
   * @returns 
   */
  static editDamageData(
    id,
    vehicleID,
    Customer_Name,
    Rental_Start_Date,
    Rental_End_Date,
    Damage_Cost_Received,
    Damage_Repair_Charges
  ) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editDamageData + `/${id}`,
      data: {
        vehicleID,
        Customer_Name,
        Rental_Start_Date,
        Rental_End_Date,
        Damage_Cost_Received,
        Damage_Repair_Charges,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * add sold data
   * @param {*} id 
   * @param {*} Customer_Name 
   * @param {*} Sold_Date 
   * @param {*} Sales_Price 
   * @param {*} RTO_Work_Cost 
   * @param {*} Fitting_Charges 
   * @param {*} Other_Cost 
   * @returns 
   */
  static addSoldData(
    id,
    Customer_Name,
    Sold_Date,
    Sales_Price,
    RTO_Work_Cost,
    Fitting_Charges,
    Other_Cost
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addSoldData + `/${id}`,
      data: {
        Customer_Name,
        Sold_Date,
        Sales_Price,
        RTO_Work_Cost,
        Fitting_Charges,
        Other_Cost,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * edit sold data
   * @param {*} id 
   * @param {*} Customer_Name 
   * @param {*} Sold_Date 
   * @param {*} Sales_Price 
   * @param {*} RTO_Work_Cost 
   * @param {*} Fitting_Charges 
   * @param {*} Other_Cost 
   * @returns 
   */
  static editSoldData(
    id,
    Customer_Name,
    Sold_Date,
    Sales_Price,
    RTO_Work_Cost,
    Fitting_Charges,
    Other_Cost
  ) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editSoldData + `/${id}`,
      data: {
        Customer_Name,
        Sold_Date,
        Sales_Price,
        RTO_Work_Cost,
        Fitting_Charges,
        Other_Cost,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * delete veh
   * @param {*} id 
   * @returns 
   */
  static deleteVehicle(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deleteVehicle + `/${id}`,
    });
    return Promise.resolve(result);
  }


  /**
   * get gen info vehicle
   * @param {*} id 
   * @returns 
   */
  static getSingleInfoVehicle(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getSingleInfoVehicle + `/${id}`,
    });
    return Promise.resolve(result);
  }







  /**
   * get investor name
   * @returns 
   */
  static getInvestorName() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getInvestorName,
    });
    return Promise.resolve(result);
  }

  /**
   * get payment type
   * @returns 
   */
  static getPaymentTypeName() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getPaymentTypeName,
    });
    return Promise.resolve(result);
  }

  /**
   * get vehicle expense
   * @returns 
   */
  static getVehicleExpenseName() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getVehicleExpenseName,
    });
    return Promise.resolve(result);
  }

  /**
   * get location name
   * @returns 
   */
  static getLocationName() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getLocationName,
    });
    return Promise.resolve(result);
  }


  /**
   * get doc vehicle list
   * @param {*} page 
   * @param {*} value 
   * @param {*} search 
   * @param {*} from 
   * @returns 
   */
  static getDocVehicleList(page, value, search, from) {
    const result = axios.appRequest({
      method: axios.GET,
      url:
        config.user.getDocVehicleList +
        `?page=${page}&searchby=${value}&value=${search}&from=${from}`,
    });
    return Promise.resolve(result);
  }


  /**
   * get service alert list
   * @param {*} page 
   * @param {*} value 
   * @param {*} search 
   * @returns 
   */
  static getServiceAlertList(page, value, search) {
    const result = axios.appRequest({
      method: axios.GET,
      url:
        config.user.getServiceAlertList +
        `?page=${page}&searchby=${value}&value=${search}`,
    });
    return Promise.resolve(result);
  }

  /**
   * get mainatinance alert list
   * @param {*} page 
   * @returns 
   */
  static getMaintainanceAlertList(page, searchby,value) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getMaintainanceAlertList + `?page=${page}&searchby=${searchby}&value=${value}`,
    });
    return Promise.resolve(result);
  }

  /**
   * add oil service
   * @param {*} id 
   * @param {*} Oil_service_Date 
   * @param {*} Amount 
   * @param {*} InvoiceNumber 
   * @param {*} Description 
   * @param {*} Km_Reading 
   * @param {*} Oil_service_Next_Service_Date 
   * @param {*} Garage 
   * @param {*} Payment_Method 
   * @returns 
   */
  static addOilMaintenanceData(
    id,
    Oil_service_Date,
    Amount,
    InvoiceNumber,
    Description,
    Km_Reading,
    Oil_service_Next_Service_Date,
    Garage,
    Payment_Method
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addOilMaintenanceData + `/${id}`,
      data: {
        Oil_service_Date,
        Amount,
        InvoiceNumber,
        Description,
        Km_Reading,
        Oil_service_Next_Service_Date,
        Garage,
        Payment_Method,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * edit oil service
   * @param {*} vehicleID 
   * @param {*} id 
   * @param {*} Oil_service_Date 
   * @param {*} Amount 
   * @param {*} InvoiceNumber 
   * @param {*} Description 
   * @param {*} Km_Reading 
   * @param {*} Oil_service_Next_Service_Date 
   * @param {*} Garage 
   * @param {*} Payment_Method 
   * @returns 
   */
  static editOilMaintenanceData(
    vehicleID,
    id,
    Oil_service_Date,
    Amount,
    InvoiceNumber,
    Description,
    Km_Reading,
    Oil_service_Next_Service_Date,
    Garage,
    Payment_Method
  ) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editOilMaintenanceData + `/${id}`,
      data: {
        vehicleID,
        Oil_service_Date,
        Amount,
        InvoiceNumber,
        Description,
        Km_Reading,
        Oil_service_Next_Service_Date,
        Garage,
        Payment_Method,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * oil maintanance 
   * @param {*} id 
   * @param {*} Maintainence_Expense_Date 
   * @param {*} Maintainence_Expense_Invoice 
   * @param {*} Maintainence_Expense_Description 
   * @param {*} Maintainence_Expense_Km_Reading 
   * @param {*} Maintainence_Expense_Garage 
   * @param {*} Maintainence_Expense_Payment_Method 
   * @param {*} Maintainence_Expense_Service_Type 
   * @param {*} Maintainence_Expense_Amount 
   * @returns 
   */
  static getoilMaintainanceAlertList(page, searchby, value) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getMaintainanceAlertList + `?page=${page}&searchby=${searchby}&value=${value}`,
    });
    return Promise.resolve(result);
  }


  /**
   * add maintainanace expense
   * @param {*} id 
   * @param {*} Maintainence_Expense_Date 
   * @param {*} Maintainence_Expense_Invoice 
   * @param {*} Maintainence_Expense_Description 
   * @param {*} Maintainence_Expense_Km_Reading 
   * @param {*} Maintainence_Expense_Garage 
   * @param {*} Maintainence_Expense_Payment_Method 
   * @param {*} Maintainence_Expense_Service_Type 
   * @param {*} Maintainence_Expense_Amount 
   * @returns 
   */
  static addMaintenanceExpense(
    id,
    Maintainence_Expense_Date,
    Maintainence_Expense_Invoice,
    Maintainence_Expense_Description,
    Maintainence_Expense_Km_Reading,
    Maintainence_Expense_Garage,
    Maintainence_Expense_Payment_Method,
    Maintainence_Expense_Service_Type,
    Maintainence_Expense_Amount
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addMaintenanceExpense + `/${id}`,
      data: {
        Maintainence_Expense_Date,
        Maintainence_Expense_Invoice,
        Maintainence_Expense_Description,
        Maintainence_Expense_Km_Reading,
        Maintainence_Expense_Garage,
        Maintainence_Expense_Payment_Method,
        Maintainence_Expense_Service_Type,
        Maintainence_Expense_Amount,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * edit maintainanace expense
   * @param {*} id 
   * @param {*} vehicleID 
   * @param {*} Maintainence_Expense_Date 
   * @param {*} Maintainence_Expense_Invoice 
   * @param {*} Maintainence_Expense_Description 
   * @param {*} Maintainence_Expense_Km_Reading 
   * @param {*} Maintainence_Expense_Garage 
   * @param {*} Maintainence_Expense_Payment_Method 
   * @param {*} Maintainence_Expense_Service_Type 
   * @param {*} Maintainence_Expense_Amount 
   * @returns 
   */
  static editMaintenanceExpense(
    id,
    vehicleID,
    Maintainence_Expense_Date,
    Maintainence_Expense_Invoice,
    Maintainence_Expense_Description,
    Maintainence_Expense_Km_Reading,
    Maintainence_Expense_Garage,
    Maintainence_Expense_Payment_Method,
    Maintainence_Expense_Service_Type,
    Maintainence_Expense_Amount
  ) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editMaintenanceExpense + `/${id}`,
      data: {
        vehicleID,
        Maintainence_Expense_Date,
        Maintainence_Expense_Invoice,
        Maintainence_Expense_Description,
        Maintainence_Expense_Km_Reading,
        Maintainence_Expense_Garage,
        Maintainence_Expense_Payment_Method,
        Maintainence_Expense_Service_Type,
        Maintainence_Expense_Amount,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * get oil expense
   * @param {*} page 
   * @param {*} id 
   * @returns 
   */
  static getAllOilExpense(page, id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getAllOilExpense}/${id}?page=${page}`,
    });
    return Promise.resolve(result);
  }

  /**
   * get mainatainance expense
   * @param {*} page 
   * @param {*} id 
   * @returns 
   */
  static getAllMaintainanaceExpense(page, id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getAllMaintainanaceExpense}/${id}?page=${page}`,
    });
    return Promise.resolve(result);
  }

  /**
   * Get all damage list
   * @param {*} page 
   * @param {*} id 
   * @returns 
   */
  static getAllDamageList(page, id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getAllDamageList}/${id}?page=${page}`,
    });
    return Promise.resolve(result);
  }



  /**
   * delete maintenance alert
   * @param {*} id 
   * @returns 
   */
  static deleteMaintenanceAlertList(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deleteMaintenanceAlertList + `/${id}`,
    });
    return Promise.resolve(result);
  }


  static getQuickLookup(page, from, to, location) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.quickLookUp}?page=${page}&from=${from}&to=${to}&location=${location}`,
    });

    return Promise.resolve(result);
  }
  /**
   * delete deleteoilservice
   */
  static deleteoilservice(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deleteoilservice + `/${id}`,
    });

    return Promise.resolve(result);
  }
  /**
   * delete maintanace expense
  *deletemainexp
  */
  static deletemainexp(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deletemainexp + `/${id}`,
    });

    return Promise.resolve(result);
  }
}
export { VehicleService };
