import * as axios from "../network/Network";

const config = require("../network/api.json");

class ExchangeService {

  /**
   *  Get Checkout details
   * @param {*} id 
   * @returns 
   */
  static getCheckoutDetails(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.checkout_details + `/${id}`,
    });
    return Promise.resolve(result);
  }


  /**
   * Get vehicle details
   * @param {*} id 
   * @returns 
   */
  static getVehicleDetails(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.vehicle_details + `/${id}`,
    });
    return Promise.resolve(result);
  }


  /**
   * add checkout details
   * @param {*} id 
   * @param {*} KM_limit 
   * @param {*} Odo_meter 
   * @param {*} Fuel_level 
   * @param {*} Helmet_qty 
   * @param {*} new_vehicle_checkoutid 
   * @returns 
   */
  static addCheckoutDetails(
    id,
    KM_limit,
    Odo_meter,
    Fuel_level,
    Helmet_qty,
    new_vehicle_checkoutid
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addCheckoutDetails + `/${id}`,
      data: {
        KM_limit,
        Odo_meter,
        Fuel_level,
        Helmet_qty,
        new_vehicle_checkoutid,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * Store exchange rental 
   * @param {*} new_vehicleid 
   * @param {*} old_vehicleid 
   * @param {*} old_vehicle_odometer 
   * @param {*} old_vehicle_fuel 
   * @param {*} vehicle_class 
   * @param {*} vehicle_make 
   * @param {*} vehicle_model 
   * @param {*} exchange_date 
   * @param {*} old_vehicle_status 
   * @param {*} id 
   * @returns 
   */
  static storeExchangeRentals(
    new_vehicleid,
    old_vehicleid,
    old_vehicle_odometer,
    old_vehicle_fuel,
    vehicle_class,
    vehicle_make,
    vehicle_model,
    exchange_date,
    old_vehicle_status,
    id
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.exchange_store_rental + `/${id}`,
      data: {
        new_vehicleid,
        old_vehicleid,
        old_vehicle_odometer,
        old_vehicle_fuel,
        vehicle_class,
        vehicle_make,
        vehicle_model,
        exchange_date,
        old_vehicle_status,
      },
    });
    return Promise.resolve(result);
  }


  /**
   * Get exchange summary details
   * @param {*} page 
   * @param {*} id 
   * @returns 
   */
  static getExchangeSummaryDetails(page, id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.exchangeSummary + `/${id}?page=${page}`,
    });
    return Promise.resolve(result);
  }
}
export { ExchangeService };
