import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { alertActions } from "../../../../actions/alert.actions";
import { useDispatch } from "react-redux";
import { DayToDayService } from "../../../../services";
import moment from "moment";

const useDayToDayDetailsHook = (location) => {
  const [submit, setSubmit] = useState(false);

  const [date, setDate] = useState(
    location?.state?.row?.date ? location?.state?.row?.date : ""
  );
  const [vehicleName, setVehicleName] = useState(
    location?.state?.row?.vehicleId ? location?.state?.row?.vehicleId : ""
  );

  const [description, setDescription] = useState(
    location?.state?.row?.description ? location?.state?.row?.description : ""
  );
  const [assignedTo, setAssignedTo] = useState(
    location?.state?.row?.assignedTo ? location?.state?.row?.assignedTo : ""
  );
  const [completed, setCompleted] = useState(
    location?.state?.row?.completed ? location?.state?.row?.completed : "No"
  );
  const [dayId, setDayId] = useState(
    location?.state?.row?.id ? location?.state?.row?.id : ""
  );
  const [vehicleType, setVehicleType] = useState([]);
  const [loader, setLoader] = useState("notset");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * get vehicle name
   */
  useEffect(() => {
    DayToDayService.getVehicleName()
      .then((response) => {
        setVehicleType(response?.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   *
   * @param {handle change} e
   */
  const handleDate = (e) => {
    setDate(moment(e).format("DD/MM/YYYY"));
  };

  const handleVehicleName = (e) => {
    setVehicleName(e.target.value);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleAssignedTo = (e) => {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setAssignedTo(e.target.value);
    } else if (!e.target.value) {
      setAssignedTo("");
    }
  };

  const handleCompleted = (e) => {
    setCompleted(e.target.value);
  };
  /**
   * navigate
   */
  const navigateToGetTask = () => {
    navigate("/daytodaytask");
  };

  /**
   * add api call
   * @param {*} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    if (date && vehicleName && description && assignedTo && completed) {
      setLoader("loading");
      DayToDayService.addDayTask(
        date,
        vehicleName,
        description,
        assignedTo,
        completed
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setDate("");
            setVehicleName("");
            setDescription("");
            setAssignedTo("");
            setCompleted("No");
            setLoader("isfull");
            setSubmit(false);
            navigate("/daytodaytask");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * delete api call
   * @param {*} e
   */
  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    DayToDayService.deleteDayTasks(location?.state?.row?.id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setSubmit(false);
          navigate("/daytodaytask");
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * edit api call
   * @param {*} e
   */
  const handleEditSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    if (
      dayId &&
      date &&
      vehicleName &&
      description &&
      assignedTo &&
      completed
    ) {
      setLoader("loading");
      DayToDayService.editDayTask(
        dayId,
        date,
        vehicleName,
        description,
        assignedTo,
        completed
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setDate("");
            setVehicleName("");
            setDescription("");
            setAssignedTo("");
            setCompleted("No");
            setLoader("isfull");
            setSubmit(false);
            navigate("/daytodaytask");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return {
    loader,
    date,
    vehicleName,
    description,
    assignedTo,
    completed,
    submit,
    vehicleType,
    dayId,
    setDayId,
    handleDate,
    handleVehicleName,
    handleDescription,
    handleAssignedTo,
    handleCompleted,
    handleSubmit,
    handleEditSubmit,
    handleDeleteSubmit,
    navigateToGetTask,
  };
};
export default useDayToDayDetailsHook;
