import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import useAgreementHook from "./useAgreementHook";
import { Loading } from "../../../../assets/images";

const PaymentTable = () => {
  const {
    listData1,
    totalPages1,
    page1,
    loader,
    columns,
    listTable,
    handlePageClick1,
  } = useAgreementHook();

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "auto",
      },
    },
  };

  const PaymentTableView = () => {
    return (
      <>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="table_loader ">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  {listData1?.data?.data?.docs?.length !== 0 ? (
                    <DataTable
                      columns={columns}
                      data={listTable || []}
                      customStyles={customStyles}
                    />
                  ) : (
                    <div className="no-data">No Data Found</div>
                  )}

                  {listData1?.data?.data?.docs.length !== 0 && (
                    <div>
                      <ReactPaginate
                        nextLabel="&rarr;"
                        onPageChange={handlePageClick1}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalPages1}
                        previousLabel="&larr;"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        currentPage={page1}
                        forcePage={page1 - 1}
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  )}
                </table>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  const titleDisplay = () => {
    return (
      <>
        <div className="main_location flt">
          <div className="location flt"> Payment History</div>
        </div>
      </>
    );
  };
  return (
    <>
      <div class="main_general flt">{titleDisplay()}</div>
      {PaymentTableView()}
    </>
  );
};

export default PaymentTable;
