import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../../../actions";
import { VehicleService } from "../../../../../services";
import moment from "moment";

const useSold = (location, tab, tabId) => {
  const [id, setId] = useState(
    location?.state?.row?.id ? location?.state?.row?.id : tabId
  );
  const [checkRes, setCheckRes] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [soldCustomerName, setSoldCustomerName] = useState("");
  const [soldDate, setSoldDate] = useState("");
  const [salesPrice, setSalesPrice] = useState("");
  const [workCost, setWorkCost] = useState("");
  const [fittingCharges, setFittingCharges] = useState("");
  const [otherCost, setOtherCost] = useState("");
  const [loader, setLoader] = useState("notset");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * tab change
   */
  useEffect(() => {
    getSingleSoldInfo();
    // eslint-disable-next-line
  }, [tab === 5, id !== ""]);

  /**
   * handle change
   * @param {*} e
   */
  const handleSoldCustomerName = (e) => {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setSoldCustomerName(e.target.value);
    } else if (!e.target.value) {
      setSoldCustomerName("");
    }
  };
  const handleSoldDate = (e) => {
    setSoldDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleSalesPrice = (e) => {
    setSalesPrice(e.target.value);
  };
  const handleWorkCost = (e) => {
    setWorkCost(e.target.value);
  };
  const handleFittingCharges = (e) => {
    setFittingCharges(e.target.value);
  };
  const handleOtherCost = (e) => {
    setOtherCost(e.target.value);
  };

  /**
   * get single info
   */
  const getSingleSoldInfo = () => {
    setLoader("loading");
    VehicleService.getSingleVehicleSold(tabId)
      .then((response) => {
        if (response.data.status.code === 0) {
          setCheckRes(true);
        }
        tab(6);
        setSoldCustomerName(response?.data?.data?.vehicleSold?.Customer_Name);
        setSoldDate(
          response?.data?.data?.vehicleSold?.Sold_Date
            ? response?.data?.data?.vehicleSold?.Sold_Date
            : ""
        );
        setSalesPrice(response?.data?.data?.vehicleSold?.Sales_Price);
        setWorkCost(response?.data?.data?.costOfSelling?.RTO_Work_Cost);
        setFittingCharges(response?.data?.data?.costOfSelling?.Fitting_Charges);
        setOtherCost(response?.data?.data?.costOfSelling?.Other_Cost);
        setLoader("isfull");
      })
      .catch((err) => console.log(err));
  };

  /**
   * add vehicle sold
   * @param {*} e
   * @param {*} tabId
   */
  const handleVehicleSold = (e, tabId) => {
    e.preventDefault();
    setSubmit(true);
    if (tabId) {
      setLoader("loading");
      VehicleService.addSoldData(
        tabId,
        soldCustomerName,
        soldDate,
        salesPrice,
        workCost,
        fittingCharges,
        otherCost
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setSubmit(false);
            dispatch(alertActions.success(response?.data?.status?.message));

            navigate("/vehicles");
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * edit vehicle sold
   * @param {*} e
   * @param {*} tabId
   */
  const handleEditVehicleSold = (e, tabId) => {
    e.preventDefault();
    setSubmit(true);
    if (tabId) {
      setLoader("loading");
      VehicleService.editSoldData(
        tabId,
        soldCustomerName,
        soldDate,
        salesPrice,
        workCost,
        fittingCharges,
        otherCost
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setSubmit(false);
            dispatch(alertActions.success(response?.data?.status?.message));
            navigate("/vehicles");
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return {
    loader,
    soldCustomerName,
    soldDate,
    salesPrice,
    workCost,
    fittingCharges,
    checkRes,
    otherCost,
    submit,
    id,
    setId,
    handleSoldCustomerName,
    handleSoldDate,
    handleSalesPrice,
    handleWorkCost,
    handleFittingCharges,
    handleOtherCost,
    handleVehicleSold,
    handleEditVehicleSold,
  };
};
export default useSold;
