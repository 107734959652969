import PaymentDetailsHooks from "./PaymentDetailsHooks";
import PaymentDetails from "./PaymentDetails.css";
import { atmcard } from "../../../../../../../../assets/images";
import { cards } from "../../../../../../../../assets/images";
import { payment } from "../../../../../../../../assets/images";
import { Ic_Home14 } from "../../../../../../../../assets/images";
import { Calender } from "../../../../../../../../assets/images";
import PaymentTable from "../../../../PaymentTable";
import { Loading } from "../../../../../../../../assets/images";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const PaymentDetail = ({ displayData, bookid, t }) => {

  const {
    loader,
    NoOfDays,
    RentalCharges,
    HelmetCharges,
    LuggageCharges,
    GrandTotal,
    gst,
    TotalRental,
    Damages,
    ExKmCharges,
    OtherCharges,
    submitted,
    carddate,
    advanceamount,
    paymentType,
    balanceamount,
    paymentdetails,
    cardno,
    cvv,
    expiry,
    holdername,
    cardType,
    transactionid,
    fileName1,
    paymentImage,
    selectedFile1,
    displayMain,
    ref_number,
    paymentRes,
    acceptCheck,
    gstRate,
    payment_dueDate,
    paymentArray,
    discount,
    handlePaymentDueDate,
    handleGst,
    changeAcceptCheckboxHandler,
    navigateToAgreement,
    handleChange,
    makepayment,
    handleCardDate,
    changeHandling,
    onSelectFile1,
    creditPayment,
    goBack,
  } = PaymentDetailsHooks(displayData, bookid, t);

  let diffBalance;
  if (paymentRes) {
    diffBalance = GrandTotal - paymentRes?.data?.[0]?.paidamount;
  }

  const paymentDetailsInfo = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
            <div className="E_comp_details flt">
              <div className="main_boxShadow flt">
                <div className="Company_name flt">
                  <div className="comp_name_details flt">
                    {t("Payment Details")}
                  </div>
                </div>
                <div className="main_payment flt">
                  <div className="row">
                    <div className="col-sm-6"></div>
                    <div className="main_grand_total flt">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="grand_ttl1 flt">
                            <div className="main_paid_class flt">
                              <ul className="paid_class flt">
                                <li>
                                  {t("Total_Paid_Amount")}
                                  <span>:</span>
                                </li>
                                {paymentRes?.data?.length === 0 && (
                                  <li className="pay_lst">0</li>
                                )}
                                {paymentRes?.data?.length > 0 && (
                                  <li className="pay_lst">{`₹${(paymentRes?.data?.[0]?.paidamount).toFixed(
                                    2
                                  )}`}</li>
                                )}
                                <li>
                                  {t("Total_Pending")}
                                  <span>:</span>
                                </li>
                                {paymentRes?.data?.length === 0 && (
                                  <li className="pay_lst">
                                    {GrandTotal ? `₹${GrandTotal}` : 0}
                                  </li>
                                )}
                                {paymentRes?.data?.length > 0 && (
                                  <li className="pay_lst">
                                    {`₹${(
                                      GrandTotal -
                                      paymentRes?.data?.[0]?.paidamount
                                    ).toFixed(2)}`}
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6"></div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="main_boxShadow flt">
                        <div className="main_helmet_padd flt">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="company_name1 flt">
                                <div className="form-group1 flt">
                                  <div className="info_circle flt">
                                    <label className="labelName2">
                                      {t("Number_of_Days")}
                                    </label>

                                    {/* <div className="info_pop flt">
                                      <i class="fas fa-info-circle"></i>
                                      <div className="hover_me">
                                        {t("EZ-RENTAL")}
                                      </div>
                                    </div> */}
                                  </div>

                                  <div className="make_img1 flt">
                                    <input
                                      type="number"
                                      placeholder=""
                                      name="NoOfDays"
                                      value={NoOfDays}
                                      onChange={handleChange}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="company_name1 flt">
                                <div className="form-group1 flt">
                                  <label className="labelName2">
                                    {t("Rental_Charges")}
                                  </label>
                                  <div className="make_img1 flt">
                                    <i class="fas fa-rupee-sign"></i>
                                    <input
                                      type="number"
                                      placeholder=""
                                      name="RentalCharges"
                                      value={RentalCharges}
                                      onChange={handleChange}
                                      onWheelCapture={(e) => {
                                        e.target.blur();
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="top_helmet_pad flt">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="pay_mode flt">
                                  <div className="helmet_change flt">
                                    {t("Helmet_Charges")}
                                  </div>
                                  <div className="company_name4 flt">
                                    <div className="form-group4 flt">
                                      <div className="make_img4 flt">
                                        <i class="fas fa-rupee-sign"></i>
                                        <input
                                          type="number"
                                          placeholder=""
                                          name="HelmetCharges"
                                          value={HelmetCharges}
                                          onChange={handleChange}
                                          onWheelCapture={(e) => {
                                            e.target.blur();
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="pay_mode flt">
                                  <div className="helmet_change flt">
                                    {t("Luggage_Charges")}
                                  </div>
                                  <div className="company_name4 flt">
                                    <div className="form-group4 flt">
                                      <div className="make_img4 flt">
                                        <i class="fas fa-rupee-sign"></i>
                                        <input
                                          type="number"
                                          placeholder=""
                                          name="LuggageCharges"
                                          value={LuggageCharges}
                                          onChange={handleChange}
                                          onWheelCapture={(e) => {
                                            e.target.blur();
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="pay_mode flt">
                                  <div className="helmet_change flt">
                                    {t("Other_Charges")}
                                  </div>
                                  <div className="company_name4 flt">
                                    <div className="form-group4 flt">
                                      <div className="make_img4 flt">
                                        <i class="fas fa-rupee-sign"></i>
                                        <input
                                          type="number"
                                          placeholder=""
                                          name="OtherCharges"
                                          value={OtherCharges}
                                          onChange={handleChange}
                                          onWheelCapture={(e) => {
                                            e.target.blur();
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="pay_mode flt">
                                  <div className="helmet_change flt">
                                    {t("Ex_Km_Charges")}
                                  </div>
                                  <div className="company_name4 flt">
                                    <div className="form-group4 flt">
                                      <div className="make_img4 flt">
                                        <i class="fas fa-rupee-sign"></i>
                                        <input
                                          type="number"
                                          placeholder=""
                                          name="ExKmCharges"
                                          value={ExKmCharges}
                                          onChange={handleChange}
                                          onWheelCapture={(e) => {
                                            e.target.blur();
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="pay_mode flt">
                                  <div className="helmet_change flt">
                                    {t("Damages")}
                                  </div>
                                  <div className="company_name4 flt">
                                    <div className="form-group4 flt">
                                      <div className="make_img1 flt">
                                        <i class="fas fa-rupee-sign"></i>
                                        <input
                                          type="number"
                                          placeholder=""
                                          name="Damages"
                                          value={Damages}
                                          onChange={handleChange}
                                          onWheelCapture={(e) => {
                                            e.target.blur();
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="total_rental flt">
                            <div className="col-sm-12">
                              <div className="pay_mode flt">
                                <div className="helmet_change flt">
                                  {t("Total_Rental_Other_Charges")}
                                </div>
                                <div className="company_name4 flt">
                                  <div className="form-group4 flt">
                                    <div className="make_img4 flt">
                                      <i class="fas fa-rupee-sign"></i>
                                      <input
                                        type="number"
                                        placeholder=""
                                        name="TotalRental"
                                        value={TotalRental}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="pay_mode flt">
                                <div className="helmet_change flt">
                                {t("Discount")}
                                </div>
                                <div className="company_name4 flt">
                                  <div className="form-group4 flt">
                                    <div className="make_img4 flt">
                                      <i class="fas fa-rupee-sign"></i>
                                      <input
                                        type="number"
                                        placeholder=""
                                        name="discount"
                                        value={discount}
                                        onChange={handleChange}
                                        onWheelCapture={(e) => {
                                          e.target.blur();
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* discount */}

                            <div className="col-sm-12">
                              <div className="pay_mode flt">
                                <div className="helmet_change flt">
                                  {t("GST")} {gstRate}% &nbsp;&nbsp;&nbsp;
                                  <input
                                    type="checkbox"
                                    id="password1"
                                    name="acceptCheck"
                                    value={acceptCheck}
                                    onClick={changeAcceptCheckboxHandler}
                                    checked={acceptCheck}
                                  />
                                </div>

                                {acceptCheck === true && (
                                  <div className="company_name4 flt">
                                    <div className="form-group4 flt">
                                      <div className="make_img4 flt">
                                        <i class="fas fa-rupee-sign"></i>
                                        <input
                                          type="number"
                                          placeholder=""
                                          name="gst"
                                          value={gst}
                                          readOnly
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {acceptCheck === false && (
                                  <div className="company_name4 flt">
                                    <div className="form-group4 flt">
                                      <div className="make_img4 flt">
                                        <i class="fas fa-rupee-sign"></i>
                                        <input
                                          type="number"
                                          placeholder=""
                                          name="gst"
                                          value={gst}
                                          onChange={handleGst}
                                          onWheelCapture={(e) => {
                                            e.target.blur();
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="pay_mode flt">
                                <div className="helmet_change flt">
                                  {t("Grand_Total")}
                                </div>
                                <div className="company_name4 flt">
                                  <div className="form-group4 flt">
                                    <div className="make_img4 flt">
                                      <i class="fas fa-rupee-sign"></i>
                                      <input
                                        type="number"
                                        placeholder=""
                                        name="GrandTotal"
                                        value={GrandTotal}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Due_Date")}*
                              </label>
                              <div className="make_img flt">
                                <Datetime
                                  dateFormat="DD/MM/YYYY"
                                  onChange={handlePaymentDueDate}
                                  value={payment_dueDate}
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  inputProps={{
                                    placeholder: "Select date",
                                    disabled: false,
                                    readOnly: true,
                                    onKeyDown: (e) => e.preventDefault(),
                                  }}
                                />
                                <div className="cal_img">
                                  <img src={Calender} alt="calender" />
                                </div>
                              </div>
                              {submitted && !payment_dueDate && (
                                <div className="req_field">
                                  {t("Payment_Due_Date_required")}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="main_ownbutton flt">
                        <div className="own_button flt">
                          <button
                            className="submitbutton"
                            onClick={() => displayData(2)}
                          >
                            {t("Back")}
                          </button>
                        </div>
                        {diffBalance !== 0 && (
                          <div className="own_button flt">
                            <button
                              className="submitbutton"
                              onClick={(e) => makepayment(e)}
                            >
                              {t("Make_Payment")}
                            </button>
                          </div>
                        )}
                        <div className="own_button flt">
                          <button
                            className="submitbutton"
                            onClick={(e) => navigateToAgreement(e)}
                          >
                            {t("Continue")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  };
  const paymentDetailsInfopage2 = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
            <div className="E_comp_details flt">
              <div className="main_boxShadow flt">
                <div className="Company_name flt">
                  <div className="comp_name_details flt">{t("Payment")}</div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">{t("Date")} *</label>
                        <div className="make_img flt">
                          <Datetime
                            dateFormat="DD/MM/YYYY"
                            onChange={handleCardDate}
                            value={carddate}
                            closeOnSelect={true}
                            timeFormat={false}
                            // isValidDate={(currentDate, selectedDate) =>
                            //   currentDate.isAfter(
                            //     new Date().getTime() - 24 * 60 * 60 * 1000
                            //   )
                            // }
                            inputProps={{
                              placeholder: t("Select date"),
                              disabled: false,
                              readOnly: true,
                              onKeyDown: (e) => e.preventDefault(),
                            }}
                          />
                          <div className="cal_img">
                            <img src={Calender} alt="calender" />
                          </div>
                        </div>
                        {submitted && !carddate && (
                          <div className="req_field">{t("Dat_required")}</div>
                        )}
                      </div>
                    </div>


                  </div>
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">{t("Payment Type")} *</label>
                        <div className="make_img flt">
                          <select
                            name="paymentType"
                            value={paymentType}
                            onChange={changeHandling}
                            id="lang"
                          >
                            <option value="">{t("Select_Payment_Type")}</option>
                            <option value="card">{t("Card")}</option>
                            <option value="cash">{t("Cash")}</option>
                            <option value="online">{t("online")}</option>

                          </select>
                        </div>
                        {submitted && !paymentType && (
                          <div className="req_field">
                            {t("Payment Type is required")}
                          </div>
                        )}
                      </div>
                    </div>

                  </div>
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">
                          {t("Payment_Amount")} *
                        </label>
                        <div className="make_img flt">
                          <i className="fas fa-rupee-sign"></i>
                          <input
                            type="text"
                            placeholder={t("Payment_Amount")}
                            name="advanceamount"
                            id="email"
                            value={advanceamount}
                            onChange={changeHandling}
                          />
                        </div>
                        {submitted && !advanceamount && (
                          <div className="req_field">
                            {t("Payment_Amoun_required")}
                          </div>
                        )}
                      </div>
                    </div>

                  </div>

                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">
                          {t("Grand_Total_Amount")}
                        </label>
                        <div className="make_img flt">
                          <i className="fas fa-rupee-sign"></i>
                          <input
                            type="text"
                            placeholder={t("Grand_Total_Amount")}
                            name="paymentamount"
                            id="email"
                            value={GrandTotal}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">
                          {t("Total_Paid_Amount")}
                        </label>
                        <div className="make_img flt">
                          <i className="fas fa-rupee-sign"></i>
                          <input
                            type="text"
                            placeholder={t("Total_Paid_Amount")}
                            name="advanceamount"
                            id="email"
                            value={
                              paymentRes?.data?.length === 0
                                ? 0
                                : (paymentRes?.data?.[0]?.paidamount).toFixed(2)
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">
                          {t("Total_Due_Amount")}
                        </label>
                        <div className="make_img flt">
                          <i className="fas fa-rupee-sign"></i>
                          <input
                            type="text"
                            placeholder={t("Total_Due_Amount")}
                            name="balanceamount"
                            id="email"
                            value={
                              paymentRes?.data?.length === 0
                                ? GrandTotal
                                : (paymentRes?.data?.[0]?.pendingamount).toFixed(
                                  2
                                )
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">
                          {t("Payment Details")}
                        </label>
                        <div className="make_img flt">

                          <div className="make_img flt">
                            <select
                              name="paymentdetails"
                              value={paymentdetails}
                              onChange={changeHandling}
                              id="lang"
                            >
                              <option value="">{t("Select_Payment_Details")}</option>

                              {paymentArray &&
                                paymentArray?.data?.data?.map(({ paymentType }) => (
                                  <option value={paymentType}>{paymentType}</option>
                                ))}
                            </select>
                          </div>

                        </div>
                        {submitted && !paymentdetails && (
                          <div className="req_field">
                            {t("Payment_Details_is_required")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">{t("Balance_Amount")}</label>
                        <div className="make_img flt">
                          <i className="fas fa-rupee-sign"></i>
                          <input
                            type="text"
                            placeholder={t("Balance_Amount")}
                            name="balanceamount"
                            id="email"
                            value={balanceamount}
                            onChange={changeHandling}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="company_name flt">


                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Reference_Number")}
                          </label>
                          <div className="make_img flt">

                            <input
                              type="text"
                              placeholder={t("Reference_Number")}
                              name="ref_number"
                              id="ref_number"
                              value={ref_number}
                              onChange={changeHandling}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3"></div>
                  <div className="col-sm-3"></div>
                  <div className="col-sm-3"></div>
                </div>
                {paymentType === "card" && creditdebitinfo()}
                {paymentType === "online" && onlinePayment()}

                <div className="main_row flt">
                  <div className="row">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6">
                      <div className="main_ownbutton flt">
                        <div className="own_button flt">

                          <button className="backbutton" onClick={goBack}>
                            {t("Back")}
                          </button>
                          <button
                            className="submitbutton"
                            onClick={creditPayment}
                          >
                            {t("Confirm_Payment_or_Save")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          )}
      </>
    );
  };

  const creditdebitinfo = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
            <div className="main_headbox flt">
              <div className="main_boxShadow flt">
                <div className="main_headbox2 flt ">
                  <div className="main_atm_card flt">
                    <div className="atm_info flt">
                      <label className="credit_card">
                        <img src={atmcard} alt="calender" />
                        {t("Credit_Card")}
                      </label>
                    </div>
                    <div className="main_row debit_card_box flt">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="main_pay_debit flt">
                            <div className="row">
                              <div className="col-sm-8">
                                <div className="main_card_type flt">
                                  <div className="company_name8 flt">
                                    <div className="form-group flt">
                                      <label className="labelName">
                                        {t("Card_Type")}
                                      </label>
                                      <div className="make_img flt">
                                        <select
                                          value={cardType}
                                          onChange={changeHandling}
                                          name="cardType"
                                          id="lang"
                                        >
                                          <option value="card" disable>
                                            {t("Select_Card_Type")}
                                          </option>
                                          <option value="credit">
                                            {" "}
                                            {t("Credit_Card")}
                                          </option>
                                          <option value="debit">
                                            {t("Debit_Card")}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="main_ex_date flt">
                                <div className="row">
                                  <div className="col-sm-8">
                                    <div className="company_name8 flt">
                                      <div className="form-group flt">
                                        <label className="labelName">
                                          {t("Card_Number")}
                                        </label>
                                        <div className="make_im flt">
                                          <input
                                            type="text"
                                            placeholder={t("Valid_Card_Number")}
                                            name="cardno"
                                            onChange={changeHandling}
                                            value={cardno}
                                          />
                                          <img src={cards} alt="calender" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-sm-4">
                                    <div className="main_cvv_head flt">
                                      <div className="cvv_number flt">
                                        <div className="cvv_date">
                                          <label className="cvv_num">
                                            {t("CVV")}
                                          </label>
                                          <input
                                            type="number"
                                            placeholder="CVV"
                                            name="cvv"
                                            id="email"
                                            value={cvv}
                                            onChange={changeHandling}
                                          />
                                        </div>
                                        <div className="ex_date">
                                          <label className="cvv_num">
                                            {t("Expiry_Date")}
                                          </label>
                                          <input
                                            type="number"
                                            placeholder="MM/YY"
                                            name="expiry"
                                            value={expiry}
                                            id="email"
                                            onChange={changeHandling}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-8">
                                <div className="company_name8 flt">
                                  <div className="form-group flt">
                                    <label className="labelName">
                                      {t("Card_Holder_Name")}
                                    </label>
                                    <div className="make_im flt">
                                      <input
                                        type="text"
                                        placeholder=""
                                        name="holdername"
                                        value={holdername}
                                        onChange={changeHandling}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="money_image flt">
                            <img src={payment} alt="calender" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  };
  const onlinePayment = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
            <div className="E_comp_details flt">
              <div className="main_boxShadow flt">

                <div className="row">
                  <div className="main_atm_card flt">
                    <div className="atm_info flt">
                      <label className="credit_card">
                        <img src={atmcard} alt="card" />
                        {t("Transaction")}
                      </label>
                    </div>
                    <div className="main_row flt">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="main_pay_debit1 flt">
                            <div className="row">
                              <div className="col-sm-8">
                                <div className="main_card_type flt">
                                  <div className="company_name8 flt">
                                    <div className="form-group flt">
                                      <label className="labelName">
                                        {t("Transaction_ID")}
                                      </label>
                                      <div className="make_im flt">
                                        <input
                                          type="text"
                                          placeholder=""
                                          name="transactionid"
                                          value={transactionid}
                                          onChange={changeHandling}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group flt">
                                  <label className="labelName">
                                    {" "}
                                    {t("Upload_Transaction_Screenshot")}
                                  </label>
                                </div>

                                <div className="upload_img1 flt">
                                  <div className="upload_img_btn1 flt">
                                    <button>
                                      {/* {!selectedFile1 && paymentImage && (
                                      <img
                                        src={`${process.env.REACT_APP_MEDIA_DOMAIN}${paymentImage}`}
                                        width="102%"
                                        height="220"
                                      />
                                    )} */}

                                      {selectedFile1 && (
                                        <img
                                          src={paymentImage}
                                          width="102%"
                                          height="220"
                                          alt="pay"
                                        />
                                      )}
                                      {!selectedFile1 && (
                                        <img src={Ic_Home14} alt="pay" />
                                      )}
                                      {/* {!selectedFile1 && !paymentImage && (
                                      <img
                                        src={Ic_Home14}
                                        alt="image avlilable"
                                      />
                                    )}
                                    {!selectedFile1 && !paymentImage && (
                                      <div className="browse_logo_img">
                                        Transaction Screenshot
                                      </div>
                                    )} */}
                                    </button>

                                    <input
                                      type="file"
                                      name="myfile"
                                      onChange={onSelectFile1}
                                      key={fileName1}
                                      id="myfile2"
                                      accept="image/png,image/jpeg"
                                    />
                                  </div>{" "}
                                </div>
                                {/* {paymentImage && (
                                <a
                                  href={`${process.env.REACT_APP_MEDIA_DOMAIN}${paymentImage}`}
                                >
                                  Download
                                </a>
                              )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="money_image flt">
                            <img src={payment} alt="pay" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  };
  const renderPaymentScreem = () => {
    return <PaymentTable />;
  };
  return (
    <>
      {displayMain === 0 && paymentDetailsInfo()}
      {displayMain === 1 && paymentDetailsInfopage2()}
      {displayMain === 0 && loader !== "loading" && renderPaymentScreem()}
    </>
  );
};
export default PaymentDetail;
