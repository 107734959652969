import { useState, useEffect } from "react";
import {
  AgreementService,
  EndrentService,
  ExchangeService,
  SetUpService,
} from "../../../../../../../services";
import { VehicleService } from "../../../../../../../services";
import { StartrentService } from "../../../../../../../services";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../../../../actions/alert.actions";
import { useNavigate } from "react-router";

const useEndRentPersonalInfoHoook = (displayDatas, bookid, rowinfo) => {
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayData, setDisplayData] = useState(0);
  const [dataSelected, setDataSelected] = useState([]);
  const [datetimevalue, setdatetimevalue] = useState();
  const [datetimeendvalue, setdatetimeendvalue] = useState();
  const [locations, setlocations] = useState([]);
  const [rlocations, setrlocations] = useState("");
  const [helmets_qty, sethelmets_qty] = useState("");
  const [travel, settravel] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [vehicleRes, setVehicleRes] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [returnLocation, setReturnLocation] = useState("");
  const dispatch = useDispatch();
  const [datavalue, setdatavalue] = useState(0);

  const [vehicleClass, setVehicleClass] = useState("");
  const [classId, setClassId] = useState("");
  const [makeId, setMakeId] = useState("");
  const [modelId, setModelId] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [loader, setLoader] = useState("notset");
  const [vehicleList, setVehicleList] = useState([]);
  const navigate = useNavigate();

  /**
   * get info
   */
  useEffect(() => {
    getVehicleDetails();
    if (bookid) {
      setLoader("loading");
      EndrentService.getAllRentalData(bookid)
        .then((res) => {
          if (res?.data?.status?.code === 0) {
            setLoader("isfull");
            setdatetimevalue(res?.data?.data?.[0]?.StartDateTime);
            setReturnLocation(res?.data?.data?.[0]?.ReturnLocation);
            sethelmets_qty(res?.data?.data?.[0]?.helmetqty);
            setrlocations(res?.data?.data[0]?.PickupLocation);
            settravel(res?.data?.data?.[0]?.TravelLocation);
            setSelectedVehicle(res?.data?.data?.[0]?.vehicalClass);
            setdatetimeendvalue(res?.data?.data?.[0]?.EndDateTime);
            setMakeId(res?.data?.data?.[0]?.vehiclemake?.[0]?._id);
            setModelId(res?.data?.data?.[0]?.vehicalmodel?.[0]?._id);
            setClassId(res?.data?.data?.[0]?.vehicleclass?.[0]._id);
            setModel(res?.data?.data?.[0]?.vehicalmodel?.[0]?.model);
            setVehicleClass(
              res?.data?.data?.[0]?.vehicleclass?.[0]?.vehicleClass
            );
            setMake(res?.data?.data?.[0]?.vehiclemake?.[0]?.make);
          } else {
            setLoader("isempty");
            console.log("error");
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, []);

  /**
   * get vehicle class
   */
  useEffect(() => {
    vehicleClassListResponse();
  }, []);

  /**
   * get location
   */
  useEffect(() => {
    VehicleService.getLocationName()
      .then((response) => {
        setlocations(response?.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   * vehicle details
   */
  const getVehicleDetails = () => {
    setLoader("loading");
    ExchangeService.getVehicleDetails(bookid)
      .then((res) => {
        setLoader("isfull");
        setVehicleRes(res);
      })
      .catch((err) => console.log(err));
  };

  /**
   * get all vehicle list
   */
  const vehicleClassListResponse = () => {
    SetUpService.getVehicleClassWithoutPage()
      .then((response) => {
        setVehicleList(response?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  let data;

  /**
   * handle change
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };

  const navigateTohistory = () => {
    setDisplayData(1);
  };

  const navigateToEndRentList = () => {
    navigate("/EndrentList");
  };

  const onChangeValue = (e) => {
    setdatetimevalue(e);
  };
  const onChangeendValue = (e) => {
    setdatetimeendvalue(e);
  };
  const changeHandlerlocation = (e) => {
    setReturnLocation(e.target.value);
  };
  const changeHandlerrelocation = (e) => {
    setrlocations(e.target.value);
  };
  const helmets_qtys = (e) => {
    sethelmets_qty(e.target.value);
  };
  const travelLocation = (e) => {
    settravel(e.target.value);
  };
  const changeVehicleClass = (e) => {
    setSelectedVehicle(e.target.value);
  };
  const validdatetimevalue = () => {
    if (datetimevalue === "") {
      return false;
    }
  };
  const validrlocations = () => {
    if (rlocations === "" || selectedVehicle === undefined) {
      return false;
    }
  };
  const validreturnLocation = () => {
    if (returnLocation === "" || selectedVehicle === undefined) {
      return false;
    }
  };
  const validdatetimeendvalue = () => {
    if (datetimeendvalue === "") {
      return false;
    }
  };

  const validselectedVehicle = () => {
    if (selectedVehicle === "" || selectedVehicle === undefined) {
      return false;
    }
  };

  /**
   * search vehicle
   * @param {*} e
   */
  const SearchVehicle = (e) => {
    setDisplayData(1);
    setSubmitted(true);

    if (selectedVehicle && bookid) {
      setLoader("loading");
      StartrentService.getBookingDetailsdata(page, makeId, classId, modelId)
        .then((response) => {
          setLoader("isfull");
          setListData(response);
          setTotalPages(response?.data?.data?.totalPages);
          setPage(response?.data?.data?.page);
          setdatavalue(1);
          setSubmitted(false);
        })

        .catch((err) => console.log(err));
    }
  };

  /**
   * store rentals
   */
  const ContinueInfo = () => {
    setSubmitted(true);
    if (new Date(datetimeendvalue) < new Date(datetimevalue)) {
      dispatch(
        alertActions.error("End Date Should be Greater than Start Date")
      );
    } else {
      setLoader("loading");
      AgreementService.storeRentals(
        datetimevalue,
        datetimeendvalue,
        rlocations,
        returnLocation,
        helmets_qty,
        travel,
        rowinfo.vehicleclassid,
        rowinfo.makeid,
        rowinfo.modelid,
        bookid ? bookid : ""
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            dispatch(alertActions.success(response?.data?.status?.message));
            setLoader("isfull");
            setSubmitted(false);
            displayDatas(1);
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * store rentals
   */
  const continueGetData = () => {
    setSubmitted(true);
    if (new Date(datetimeendvalue) < new Date(datetimevalue)) {
      dispatch(
        alertActions.error("End Date Should be Greater than Start Date")
      );
    } else {
      setLoader("loading");
      StartrentService.storeRentals(
        datetimevalue,
        datetimeendvalue,
        rlocations,
        returnLocation,
        helmets_qty,
        travel,
        classId,
        makeId,
        modelId,
        rowinfo.location,

        bookid
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            dispatch(alertActions.success(response?.data?.status?.message));
            displayDatas(2);
            setSubmitted(false);
            setLoader("isfull");
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return {
    loader,
    submitted,
    listData,
    totalPages,
    dataSelected,
    data,
    datetimevalue,
    datetimeendvalue,
    locations,
    rlocations,
    helmets_qty,
    travel,
    vehicleClass,
    datavalue,
    make,
    model,
    returnLocation,
    vehicleRes,

    handlePageClick,
    navigateTohistory,
    onChangeValue,
    onChangeendValue,
    changeHandlerlocation,
    changeHandlerrelocation,
    helmets_qtys,
    travelLocation,
    SearchVehicle,
    ContinueInfo,
    changeVehicleClass,
    validdatetimevalue,
    validrlocations,
    validreturnLocation,
    validdatetimeendvalue,
    validselectedVehicle,
    setdatavalue,
    continueGetData,
    navigateToEndRentList,
  };
};
export default useEndRentPersonalInfoHoook;
