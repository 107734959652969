import useExchageCheckoutHook from "./useExchageCheckoutHook";
import { fuel } from "../../../../../../../assets/images";
import { odo } from "../../../../../../../assets/images";
import { Loading } from "../../../../../../../assets/images";

const ExchageCheckout = ({ setTab, tab, bookid }) => {
  const {
    KMLimit,
    odometer,
    fuellevel,
    helmetqty,
    vehicleRes,
    submit,
    loader,
    checkOutStore,
    navigateToList,
  } = useExchageCheckoutHook(setTab, tab, bookid);

  const checkoutDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {loader !== "loading" && (
          <div className="E_comp_details flt">
            <div className="main_boxShadow flt">
              <div className="main_company flt">
                <div className="main_row flt">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">KM Limit</label>
                          <input
                            type="number"
                            placeholder="KM Limit"
                            name="KMLimit"
                            id="KMLimit"
                            value={KMLimit}
                            readOnly
                          />

                          {submit && !KMLimit && (
                            <div className="req_field">
                              KM Limit is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">ODO Meter</label>
                          <div className="make_img12 flt">
                            <img src={odo} alt="upload" />
                            <input
                              type="number"
                              placeholder="odometer"
                              name="odometer"
                              id="odometer"
                              value={odometer}
                              readOnly
                            />
                          </div>
                          {submit && !odometer && (
                            <div className="req_field">
                              ODO Meter is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">Fuel Level</label>
                          <div className="make_img12 flt">
                            <img src={fuel} alt="upload" />
                            <input
                              type="text"
                              placeholder="fuellevel"
                              name="fuellevel"
                              id="fuellevel"
                              value={fuellevel}
                              readOnly
                            />
                          </div>
                          {submit && !fuellevel && (
                            <div className="req_field">
                              Fuel Level is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">Helmet Numbers</label>
                          <input
                            type="text"
                            placeholder="Helmet Numbers"
                            name="helmetqty"
                            id="helmetqty"
                            value={helmetqty}
                            readOnly
                            // onChange={Changehandler}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="main_row flt">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="main_car_padding flt">
                            <div className="car_details flt">
                              Old Vehicle Details
                            </div>
                            <div className="details_car flt">
                              <div className="model_details flt">
                                <ul className="make_details flt">
                                  <li>
                                    Reg Number<span>:</span>
                                  </li>

                                  <li>{vehicleRes?.data?.data?.regNumber}</li>
                                  <li>
                                    Vehicle Class<span>:</span>
                                  </li>

                                  <li>
                                    {vehicleRes?.data?.data?.vehicleclass}
                                  </li>
                                  <li>
                                    Make<span>:</span>
                                  </li>

                                  <li>{vehicleRes?.data?.data?.vehiclemake}</li>

                                  <li>
                                    Model<span>:</span>
                                  </li>

                                  <li>
                                    {vehicleRes?.data?.data?.vehiclemodel}
                                  </li>

                                  <li>
                                    Location<span>:</span>
                                  </li>

                                  <li>{vehicleRes?.data?.data?.location}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                          <div className="But_padding flt">
                            <div className="main_ownbutton flt">
                              <div className="own_button exch_check77 flt">
                                <button
                                  className="backbutton"
                                  onClick={navigateToList}
                                >
                                  Back
                                </button>
                                <button
                                  className="submitbutton"
                                  onClick={checkOutStore}
                                >
                                  Check-Out
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return <>{checkoutDetails()}</>;
};
export default ExchageCheckout;
