import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { agreement } from "../../../../../../assets/images";
import TopBar from "../../../../../_common/topbar/TopBar";
import Menu from "../../../../../_common/menu/Menu";
import ExchageCheckout from "./ExchageCheckout/ExchageCheckout";
import ExchangeCheckIn from "./ExchangeCheckIn/ExchangeCheckIn";
import ExchangeRentalDetails from "./ExchangeRentalDetails/ExchangeRentalDetails";
import ExchangeNewVehicleCheckout from "./ExchangeNewVehicleCheckout/ExchangeNewVehicleCheckout";
import ExchangePayment from "./ExchangePayment/ExchangePayment";
import ExchangeSummary from "./ExchangeSummary/ExchangeSummary";

const ExchangeDetails = () => {
  const location = useLocation();

  const [tab, setTab] = useState(1);

  const bookid = location.search.split("=")[1]?.substring(0, 24);

  const agreementtiltle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img src={agreement} alt="agreement" />
          Exchange
        </div>
        {tab === 1 && (
          <div className="delete_b flt">
            <button
              className="delete_btn"
              onClick={(e) => {
                setTab(6);
              }}
            >
              Exchange Summary
            </button>
          </div>
        )}
      </div>
    );
  };

  const tabDetails = () => {
    return (
      <div className="main_general flt">
        <div className="general_informaion flt">
          <ul className="insurance_info">
            <li
              className={tab === 1 ? "insurance_infos" : "insuranceinfo_show"}
              onClick={() => setTab(1)}
            >
              <Link to={`?bid=${bookid}`}>Check-Out Details</Link>
            </li>
            <li
              className={tab === 2 ? "insurance_infos" : "insuranceinfo_show"}
              onClick={() => setTab(2)}
            >
              <Link to={`?bid=${bookid}`}>Check-In Details </Link>
            </li>
            <li
              className={tab === 3 ? "insurance_infos" : "insuranceinfo_show"}
              onClick={() => setTab(3)}
            >
              <Link to={`?bid=${bookid}`}>Exchange Vehicle details</Link>
            </li>
            <li
              className={tab === 4 ? "insurance_infos" : "insuranceinfo_show"}
              onClick={() => setTab(4)}
            >
              <Link to={`?bid=${bookid}`}>New Vehicle Checkout Details </Link>
            </li>
            <li
              className={tab === 5 ? "insurance_infos" : "insuranceinfo_show"}
              onClick={() => setTab(5)}
            >
              <Link to={`?bid=${bookid}`}>Payment Details </Link>
            </li>{" "}
          </ul>
        </div>
      </div>
    );
  };

  const tabView = () => {
    return (
      <>
        {tab === 1 && (
          <ExchageCheckout setTab={setTab} tab={tab} bookid={bookid} />
        )}
        {tab === 2 && (
          <ExchangeCheckIn setTab={setTab} tab={tab} bookid={bookid} />
        )}
        {tab === 3 && (
          <ExchangeRentalDetails setTab={setTab} tab={tab} bookid={bookid} />
        )}{" "}
        {tab === 4 && (
          <ExchangeNewVehicleCheckout
            setTab={setTab}
            tab={tab}
            bookid={bookid}
          />
        )}{" "}
        {tab === 5 && (
          <ExchangePayment setTab={setTab} tab={tab} bookid={bookid} />
        )}
        {tab === 6 && (
          <ExchangeSummary setTab={setTab} tab={tab} bookid={bookid} />
        )}
      </>
    );
  };

  return (
    <>
      <TopBar />
      <Menu />
      {agreementtiltle()}
      {tab !== 6 && tabDetails()}
      {tabView()}
    </>
  );
};
export default ExchangeDetails;
