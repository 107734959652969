import { useState, useEffect } from "react";
import {
  SetUpService,
  VehicleService,
  StartrentService,
  ExchangeService,
} from "../../../../../../../services";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../../../../actions/alert.actions";
import moment from "moment";

const useExchangeRentalDetails = (setTab, tab, bookid, location, rowinfo) => {
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayData, setDisplayData] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("regNo");
  const [datetimevalue, setdatetimevalue] = useState();
  const [datetimeendvalue, setdatetimeendvalue] = useState();
  const [locations, setlocations] = useState([]);
  const [rlocations, setrlocations] = useState("");
  const [helmets_qty, sethelmets_qty] = useState("");
  const [travel, settravel] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [exchangeDate, setExchangeDate] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [returnLocation, setReturnLocation] = useState("");
  const dispatch = useDispatch();
  const [datavalue, setdatavalue] = useState(0);
  const [vehicleClass, setVehicleClass] = useState("");
  const [classId, setClassId] = useState("");
  const [makeId, setMakeId] = useState("");
  const [modelId, setModelId] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [loader, setLoader] = useState("notset");

  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);

  const [odometer, setOdometer] = useState(location?.state?.odometer);
  const [fuel, setFuel] = useState(location?.state?.fuellevel);
  const [vehicleRes, setVehicleRes] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [oldVehicleStatus, setOldVehicleStatus] = useState("maintanance");
  const [pop, showPop] = useState(false);
  const [response, setResponse] = useState(false);
  const [vehId, setVehId] = useState("");

  /**
   * get rental data
   */
  useEffect(() => {
    setLoader("loading");
    getVehicleDetails();
    if (bookid) {
      StartrentService.getAllRentalData(bookid).then((res) => {
        if (res?.data?.status?.code === 0) {
          setVehicleClass(
            res?.data?.data?.[0]?.vehicleclass?.[0]?.vehicleClass
          );
          setdatetimevalue(res?.data?.data?.[0]?.StartDateTime);
          setReturnLocation(
            res?.data?.data?.[0]?.ReturnLocationName?.[0]?.locationName
          );
          sethelmets_qty(res?.data?.data?.[0]?.helmetqty);
          setrlocations(
            res?.data?.data?.[0]?.PickupLocationName?.[0]?.locationName
          );
          settravel(res?.data?.data?.[0]?.TravelLocation);
          setSelectedVehicle(res?.data?.data?.[0]?.vehicalClass);
          setdatetimeendvalue(res?.data?.data?.[0]?.EndDateTime);
          setMakeId(res?.data?.data?.[0]?.vehiclemake?.[0]?._id);
          setModelId(res?.data?.data?.[0]?.vehicalmodel?.[0]?._id);
          setClassId(res?.data?.data?.[0]?.vehicleclass?.[0]?._id);
          setMake(res?.data?.data?.[0]?.vehiclemake?.[0]?._id);
          setModel(res?.data?.data?.[0]?.vehicalmodel?.[0]?._id);
          setLoader("isfull");
        } else {
          console.log("error");
          setLoader("isempty");
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  /**
   * get make list
   */
  useEffect(() => {
    makeListResponse(classId);
  }, [classId]);

  useEffect(() => {
    searchingVehicle(item, search);
  }, [page, search])

  /**
   * get model list
   */
  useEffect(() => {
    modelListResponse(classId, makeId);
  }, [makeId, classId]);

  /**
   * get location name
   */
  useEffect(() => {
    VehicleService.getLocationName().then((response) => {
      setlocations(response?.data?.data);
    });
  }, []);

  /**
   * get vehicle details
   */
  const getVehicleDetails = () => {
    setLoader("loading");
    ExchangeService.getVehicleDetails(bookid)
      .then((res) => {
        setVehicleRes(res);
        setLoader("isfull");
      })
      .catch((err) => console.log(err));
  };

  /**
   * get make list response
   * @param {*} mid
   */
  const makeListResponse = (mid) => {
    if (mid !== "") {
      SetUpService.getMakeWithoutPage(mid)
        .then((response) => {
          setMakeList(response?.data?.data);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };


  /**
 * change handler
 * @param {*} e
 */
  const changeItemHandler = (e) => {
    setSearch("");
    if (e.target.value === "regNo") {
      setItem(e.target.value);

      searchingVehicle(e.target.value, search);
    }
  };


  /**
    * searchItem
    */


  /**
* search handler
* @param {*} e
*/
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    searchingVehicle(item, e.target.value);
  };
  /**
   * get all model list
   * @param {*} vid
   * @param {*} mid
   */
  const modelListResponse = (vid, mid) => {
    if (vid !== "" && mid !== "") {
      SetUpService.getModelWithoutPage(vid, mid)
        .then((response) => {
          setModelList(response?.data?.data);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  /**
   * handle change
   * @param {*} e
   */
  const handleExchangeDate = (e) => {
    setExchangeDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleMake = (e) => {
    setMake("");
    setMakeId("");

    if (e.target.value !== make) {
      setdatavalue(0);
    }
    makeList.filter((val, index) =>
      val._id === e.target.value ? setMake(e.target.value) : ""
    );
    setModel("");
    setModelId("");
    setMakeId(e.target.value);
  };
  const handleModel = (e) => {
    if (e.target.value !== model) {
      setdatavalue(0);
    }
    modelList.filter((val, index) =>
      val._id === e.target.value ? setModel(e.target.value) : ""
    );
    setModelId(e.target.value);
  };

  /**
   * handle page change
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };

  const searchingVehicle = (items, searchs) => {
    setSubmitted(true);
    if (
      classId &&
      bookid &&
      modelId &&
      makeId &&
      make &&
      vehicleClass &&
      model &&
      exchangeDate
    ) {
      if (!vehicleClass) {
        dispatch(alertActions.error("Vehicle class is required"));
      } else if (!make) {
        dispatch(alertActions.error("Make is required"));
      } else if (!model) {
        dispatch(alertActions.error("Model is required"));
      }
      else if (!exchangeDate) {
        // dispatch(alertActions.error("ExchangeDate is required"));
      } else {
        setLoader("loading");
        StartrentService.getBookingDetailsdata(1, makeId, classId, modelId, items, searchs)
          .then((response) => {
            setSubmitted(false);
            setDisplayData(1);
            setListData(response);
            setTotalPages(response?.data?.data?.totalPages);
            setPage(response?.data?.data?.page);
            setLoader("isfull");
            setdatavalue(1);
          })
          .catch((err) => console.log(err));
      }
    }
  }

  /**
   * search vehicle
   * @param {*} e
   */
  const SearchVehicle = (e) => {
    setSubmitted(true);
    if (
      classId &&
      bookid &&
      modelId &&
      makeId &&
      make &&
      vehicleClass &&
      model
    ) {
      if (!vehicleClass) {
        dispatch(alertActions.error("Vehicle class is required"));
      } else if (!make) {
        dispatch(alertActions.error("Make is required"));
      } else if (!model) {
        dispatch(alertActions.error("Model is required"));
      } else {
        setLoader("loading");
        StartrentService.getBookingDetailsdata(page, makeId, classId, modelId)
          .then((response) => {
            setSubmitted(false);
            setDisplayData(1);
            setListData(response);
            setTotalPages(response?.data?.data?.totalPages);
            setPage(response?.data?.data?.page);
            setLoader("isfull");
            setdatavalue(1);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  /**
   * store rental details
   */
  const continueGetData = () => {
    setSubmit(true);

    if (
      (rowinfo?.id && vehicleRes?.data?.data?.vehicleid,
        odometer && fuel && classId && makeId && modelId && exchangeDate)
    ) {
      setLoader("loading");
      ExchangeService.storeExchangeRentals(
        rowinfo?.id,
        vehicleRes?.data?.data?.vehicleid,
        odometer,
        fuel,
        classId,
        makeId,
        modelId,
        exchangeDate,
        oldVehicleStatus,
        bookid
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setVehId(response?.data?.data?._id);
            setSubmit(false);
            showPop(false);
            setResponse(true);
            setLoader("isfull");
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleOldVehicleStatus = (e) => {
    setOldVehicleStatus(e.target.value);
  };

  /**
   * handle continue
   */
  const handleContinue = () => {
    setSubmit(true);
    if (!exchangeDate) {
      dispatch(alertActions.error("Enter Exchange Date"));
    } else if (!makeId) {
      dispatch(alertActions.error("Enter Vehicle Make"));
    } else if (!modelId) {
      dispatch(alertActions.error("Enter Vehicle Model"));
    } else if (odometer === "") {
      dispatch(alertActions.error("Enter Odometer value"));
    } else if (fuel === "") {
      dispatch(alertActions.error("Enter Fuel Level"));
    } else {
      showPop(true);
    }
  };

  return {
    loader,
    submitted,
    listData,
    totalPages,
    datetimevalue,
    datetimeendvalue,
    rlocations,
    helmets_qty,
    travel,
    vehicleClass,
    datavalue,
    make,
    makeList,
    returnLocation,
    model,
    modelList,
    exchangeDate,
    vehicleRes,
    submit,
    oldVehicleStatus,
    response,
    pop,
    vehId,
    item,
    search,
    handleExchangeDate,
    handlePageClick,
    SearchVehicle,
    setdatavalue,
    continueGetData,
    handleMake,
    handleModel,
    handleOldVehicleStatus,
    showPop,
    handleContinue,
    changeItemHandler,
    changeSearchHandler
  };
};
export default useExchangeRentalDetails;
