import { agreement } from "../../../../../../../assets/images";
import { StartrentService } from "../../../../../../../services";
import { useLocation } from "react-router";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../../../../actions/index";
import { error } from "../../../../../../../assets/images";
import { verify } from "../../../../../../../assets/images";
import { useNavigate } from "react-router";
import TopBar from "../../../../../../_common/topbar/TopBar";
import Menu from "../../../../../../_common/menu/Menu";
import { Loading } from "../../../../../../../assets/images";

const StartRentWalkInOtpScreen = () => {
  const dispatch = useDispatch();
  const emailInputRef = useRef(null);
  const otp2InputRef = useRef(null);
  const otp3InputRef = useRef(null);
  const otp4InputRef = useRef(null);
  const otp5InputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [verifystatus, setverifystatus] = useState(false);
  const [mobilenum, setmobilenum] = useState(
    location?.state?.row?.mobileno ? location?.state?.row?.mobileno : ""
  );
  const [loader, setLoader] = useState("notset");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [success, setsuccess] = useState();
  const bid = location.search.split("=")[1]?.substring(0, 24);
  const [sub, setSub] = useState(false);

  /**
   * change otp
   * @param {*} e
   */
  const onChangeOtp = (e) => {
    setmobilenum(e.target.value);
  };

  /**
   * store otp
   * @param {*} e
   */
  const storeotp = (e) => {
    if (e.target.name === "otp1") {
      setOtp1(e.target.value);
      otp2InputRef.current.focus();
    }
    if (e.target.name === "otp2") {
      setOtp2(e.target.value);
      otp3InputRef.current.focus();
    }
    if (e.target.name === "otp3") {
      setOtp3(e.target.value);
      otp4InputRef.current.focus();
    }
    if (e.target.name === "otp4") {
      setOtp4(e.target.value);
      otp5InputRef.current.focus();
    }
    if (e.target.name === "otp5") {
      setOtp5(e.target.value);
    }
  };

  /**
   * validation
   * @returns
   */
  const findmblvalid = () => {
    if (mobilenum === "") {
      return true;
    }
  };
  const otp1valid = () => {
    if (otp1 === "") {
      return true;
    }
  };
  const otp2valid = () => {
    if (otp2 === "") {
      return true;
    }
  };
  const otp3valid = () => {
    if (otp3 === "") {
      return true;
    }
  };
  const otp4valid = () => {
    if (otp4 === "") {
      return true;
    }
  };
  const otp5valid = () => {
    if (otp5 === "") {
      return true;
    }
  };

  /**
   * get otp
   */
  const getOtp = () => {
    setsuccess();
    setSubmitted(true);
    if (mobilenum) {
      var filter = /^[0-9]{10}$/;
      if (mobilenum && !filter.test(mobilenum)) {
        dispatch(alertActions.error("Enter valid Mobile Number"));
      } else {
        setSubmitted(true);
        StartrentService.getotpvalue(mobilenum)
          .then((res) => {
            if (res?.data?.status?.code === 0) {
              setOtp1("");
              setOtp2("");
              setOtp3("");
              setOtp4("");
              setOtp5("");
              setLoader("isfull");
              setSubmitted(false);
              setverifystatus(true);
              emailInputRef.current.focus();
            } else {
              setLoader("isempty");
              dispatch(alertActions.error("Failed"));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  /**
   * navigation
   */
  const goBack = () => {
    navigate("/WalkinBookingAgreeTab",
      {
        state: {
          row: { id: location?.state?.row?.id },
        },
      });
  };
  var isvalid = findmblvalid();
  var validotp1 = otp1valid();
  var validotp2 = otp2valid();
  var validotp3 = otp3valid();
  var validotp4 = otp4valid();
  var validotp5 = otp5valid();

  /**
   * verify otp
   */
  const verifyOtpsubmit = () => {
    setSub(true);
    if (otp1 && otp2 && otp3 && otp4 && otp5) {
      StartrentService.verifyOtp(mobilenum, otp1, otp2, otp3, otp4, otp5).then(
        (res) => {
          if (res?.data?.status?.code === 0) {
            setsuccess(true);
          } else {
            setsuccess(false);
          }
        }
      );
    }
  };
  const agreementtiltle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img src={agreement} alt="agreement" />
          New Agreement
        </div>
      </div>
    );
  };
  /**
   * navigation
   */
  const navigateDetails = () => {
    setTimeout(() => {
      navigate(`/WalkinBookingAgreeTab?cid=${bid}`);
    }, 1000);
  };
  const otpdetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
            <div className="E_comp_details flt">
              <div className="main_boxShadow flt">
                <div className="main_row flt">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            Customer Mobile Number
                          </label>
                          <div className="make_img flt">
                            <div className="mob_number">+91</div>
                            <input
                              type="number"
                              placeholder="Mobile Number"
                              name="hourCost"
                              value={mobilenum}
                              onChange={onChangeOtp}
                            />
                          </div>
                          {submitted && isvalid && (
                            <div className="invalid-feedback">
                              Mobile number is required
                            </div>
                          )}
                        </div>
                      </div>
                      {verifystatus === false && (
                        <div className="otp_button flt">
                          <button className="otp_back" onClick={goBack}>
                            Back
                          </button>
                          <button onClick={getOtp} className="otp_back">
                            Get OTP
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  };
  const verifyotp = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_row flt">
          <div className="row">
            <div className="col-sm-3">
              <div className="company_name flt">
                <div className="form-group flt">
                  <label className="labelName">Enter Code</label>
                  <div className="otp_code flt">
                    <input
                      type="tel"
                      placeholder=""
                      name="otp1"
                      className={sub && validotp1 ? "is-invalid" : ""}
                      value={otp1}
                      maxLength="1"
                      onChange={storeotp}
                      ref={emailInputRef}
                    />
                    <input
                      type="tel"
                      placeholder=""
                      name="otp2"
                      className={sub && validotp2 ? "is-invalid" : ""}
                      value={otp2}
                      maxLength="1"
                      onChange={storeotp}
                      ref={otp2InputRef}
                    />
                    <input
                      type="tel"
                      placeholder=""
                      name="otp3"
                      className={sub && validotp3 ? "is-invalid" : ""}
                      value={otp3}
                      maxLength="1"
                      onChange={storeotp}
                      ref={otp3InputRef}
                    />
                    <input
                      type="tel"
                      placeholder=""
                      name="otp4"
                      className={sub && validotp4 ? "is-invalid" : ""}
                      value={otp4}
                      maxLength="1"
                      onChange={storeotp}
                      ref={otp4InputRef}
                    />
                    <input
                      type="tel"
                      placeholder=""
                      name="otp5"
                      className={sub && validotp5 ? "is-invalid" : ""}
                      value={otp5}
                      maxLength="1"
                      onChange={storeotp}
                      ref={otp5InputRef}
                    />
                  </div>
                </div>
              </div>
              <div className="resend_otp flt">
                <button className="re_button" onClick={getOtp}>
                  Resend OTP
                </button>
              </div>
              <div className="otp_button flt">
                <button className="otp_back" onClick={navigateDetails}>
                  Skip
                </button>
                <button className="otp_back" onClick={verifyOtpsubmit}>
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
        {success === true && (
          <div className="verify_msg flt">
            <div className="main_msg">
              <div className="success_img flt">
                <img src={verify} alt="img" />
                Your Mobile Number has been successfully verified
              </div>
              {navigateDetails()}
            </div>
          </div>
        )}
        {success === false && (
          <div className="verify_msg1 flt">
            <div className="main_msg">
              <div className="success_img flt">
                <img src={error} alt="img" />
                Your Mobile Number verification failed
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <TopBar />
      <Menu />
      {agreementtiltle()}
      {otpdetails()}
      {verifystatus && verifyotp()}
    </>
  );
};
export default StartRentWalkInOtpScreen;
