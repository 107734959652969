import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router";
import useGeneralInfo from "./useGeneralInfo";
import { Calender } from "../../../../../assets/images";
import { clone } from "../../../../../assets/images";
import { Loading } from "../../../../../assets/images";
import { vehicle } from "../../../../../assets/images";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const GeneralInfoTab = ({ tab, setTabId, tabId, t }) => {
  const location = useLocation();

  const {
    loader,
    submit,
    stockNumber,
    regNumber,
    chasisNumber,
    make,
    model,
    year,
    ODOMeterNo,
    color,
    transmission,
    vehicleClass,
    startDate,
    dateSold,
    fuelTypeCapacity,
    salePrice,
    locations,
    purchasePrice,
    investorName,
    basis,
    availabilty,
    investorArray,
    locationArray,
    showVehicle,
    listData,
    totalPages,
    checkRes,
    fuelTypeCapacityRef,
    colorRef,
    yearRef,
    id,
    vehicleList,
    makeList,
    modelList,
    columns,
    listTable,
    handleStockNumber,
    handleRegNumber,
    handleChasisNumber,
    handleMake,
    handleModel,
    handleYear,
    handleODOMeterNo,
    handleColor,
    handleVehicleClass,
    handleTransmission,
    handleStartDate,
    handleDateSold,
    handleFuelTypeCapacity,
    handlePurchasePrice,
    handleSalePrice,
    handleLocation,
    handleAvailabilty,
    handleInvestorName,
    handleBasis,
    handleGeneralInfo,
    handlePageClick,
    handleModalRow,
    handleEditGeneralInfo,
    navigateToVehiclesListsPage,
    openVehicle,
    closeVehicle,
    setShowVehicle,
  } = useGeneralInfo(location, tab, tabId);

  let basisData = [];
  basisData = basis ? basis : [];

  const getGenInfo = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
          <>
            <div className="main_boxShadow flt">
              <div className="Company_name flt">
                <div className="comp_name_details flt">
                  {t("General_Information")}
                </div>
              </div>
              <div className="main_company flt">
                <div className="main_row flt">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Stock_Number")} *
                          </label>
                          <input
                            type="text"
                            placeholder="Stock Number "
                            name="stockNumber"
                            id="stockNumber"
                            value={stockNumber}
                            onChange={handleStockNumber}
                          />
                          {submit && !stockNumber && (
                            <div className="req_field">
                              {t("Stock_Number_required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Reg_Number")} *
                          </label>
                          <input
                            type="text"
                            placeholder="Reg Number"
                            name="regNumber"
                            id="regNumber"
                            value={regNumber}
                            onChange={handleRegNumber}
                          />
                          {submit && !regNumber && (
                            <div className="req_field">
                              {t("RegNumber_required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Chasis No")} *
                          </label>
                          <input
                            className="comp_input"
                            type="text"
                            placeholder="Chasis No"
                            name="chasisNumber"
                            id="chasisNumber"
                            value={chasisNumber}
                            onChange={handleChasisNumber}
                          />
                          {submit && !chasisNumber && (
                            <div className="req_field">
                              {t("Chasis_number_required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Engine number")} *
                          </label>
                          <input
                            className="comp_input"
                            type="text"
                            placeholder="Fuel Tank Capacity"
                            name="fuelTypeCapacity"
                            id="fuelTypeCapacity"
                            value={fuelTypeCapacity}
                            ref={fuelTypeCapacityRef}
                            onChange={handleFuelTypeCapacity}
                          />
                          {submit && !fuelTypeCapacity && (
                            <div className="req_field">
                              {t("Fuel_type_capacity_required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      {" "}
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Vehicle Class")} *
                          </label>
                          <select
                            name="vehicleClass"
                            id="vehicleClass"
                            onChange={handleVehicleClass}
                            value={vehicleClass}
                          >
                            <option value="">
                              {t("Select Vehicle Class")}
                            </option>
                            {vehicleList &&
                              vehicleList.map(({ _id, vehicleClass }) => (
                                <option value={_id}>{vehicleClass}</option>
                              ))}
                          </select>
                          {submit && !vehicleClass && (
                            <div className="req_field">
                              {t("Vehicle Class is required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      {" "}
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Make")} *</label>
                          <div className="make_img flt">
                            <select
                              name="make"
                              id="make"
                              onChange={handleMake}
                              value={make}
                            >
                              <option value="">{t("Select_Make")}</option>
                              {makeList &&
                                makeList.map(({ _id, make }) => (
                                  <option value={_id}>{make}</option>
                                ))}
                            </select>

                            <button onClick={openVehicle} className="abc">
                              <img src={clone} alt="clone" />
                            </button>
                          </div>
                          {submit && !make && (
                            <div className="req_field">
                              {t("Make is required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      {" "}
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Model")} *</label>
                          <select
                            name="model"
                            id="model"
                            onChange={handleModel}
                            value={model}
                          >
                            <option value="">{t("Select Model")}</option>
                            {modelList &&
                              modelList.map(({ _id, model }) => (
                                <option value={_id}>{model}</option>
                              ))}
                          </select>

                          {submit && !model && (
                            <div className="req_field">
                              {t("Model is required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Purchase_Price")}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Permit Number"
                            name="purchasePrice"
                            id="purchasePrice"
                            value={purchasePrice}
                            onChange={handlePurchasePrice}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      {" "}
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Year")}</label>
                          <select
                            name="year"
                            id="year"
                            onChange={handleYear}
                            ref={yearRef}
                            value={year}
                          >
                            <option default value="2000">
                              2000
                            </option>
                            {Array.apply(0, Array(99)).map((item, index) => {
                              return (
                                <option value={2000 + index + 1}>
                                  {2000 + index + 1}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !year && (
                            <div className="req_field">
                              {t("year_required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      {" "}
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Color")} *</label>
                          <input
                            className="comp_input"
                            type="text"
                            placeholder="Color"
                            name="color"
                            id="color"
                            ref={colorRef}
                            value={color}
                            onChange={handleColor}
                          />
                          {submit && !color && (
                            <div className="req_field">
                              {t("Color_required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      {" "}
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Transmission")}
                          </label>
                          <select
                            name="transmission"
                            id="transmission"
                            onChange={handleTransmission}
                            value={transmission}
                          >
                            <option value="Automatice">{t("automatic")}</option>
                            <option value="Manual">{t("Manual")} </option>
                          </select>
                          {submit && !transmission && (
                            <div className="req_field">
                              {t("Transmission_required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      {" "}
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Location")} *</label>
                          <select
                            name="location"
                            id="location"
                            onChange={handleLocation}
                            value={locations}
                          >
                            <option value="">
                              {t("Select Location Name")}
                            </option>
                            {locationArray &&
                              locationArray.map(({ _id, locationName }) => (
                                <option value={_id}>{locationName}</option>
                              ))}
                          </select>
                          {submit && !locations && (
                            <div className="req_field">
                              {t("Location is required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("ODO_Meter_Reading")} *
                          </label>
                          <input
                            className="comp_input"
                            type="number"
                            placeholder="ODO Meter No"
                            name="ODOMeterNo"
                            id="ODOMeterNo"
                            value={ODOMeterNo}
                            onChange={handleODOMeterNo}
                          />
                          {submit && !ODOMeterNo && (
                            <div className="req_field">
                              {t("ODO_Meter_Reading_required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      {" "}
                      <div className=" company_name vehicle_geninfo flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Start_Date")}</label>

                          <Datetime
                            dateFormat="DD/MM/YYYY"
                            onChange={handleStartDate}
                            value={startDate}
                            closeOnSelect={true}
                            timeFormat={false}
                            inputProps={{
                              placeholder: "Select From date",
                              disabled: false,
                              readOnly: true,
                              onKeyDown: (e) => e.preventDefault(),
                            }}
                          />
                          <div className="cal_img">
                            <img src={Calender} alt="calender" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      {" "}
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Investor_Name")}*
                          </label>
                          <select
                            name="Vehicle"
                            id="investorName"
                            value={investorName}
                            onChange={handleInvestorName}
                          >
                            <option value="">
                              {t("Select_Investor_Name")}
                            </option>
                            {investorArray &&
                              investorArray.map(({ _id, investorName }) => (
                                <option value={_id}>{investorName}</option>
                              ))}
                          </select>
                          {submit && !investorName && (
                            <div className="req_field">
                              {t("Investor_name_required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      {" "}
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Basis")}</label>
                          <div className="daily_basis flt">
                            <ul className="basis_price">
                              <li className="cheak_days">
                                <input
                                  type="checkbox"
                                  id="Daily"
                                  name="basis"
                                  value="Daily"
                                  checked={basisData.includes("Daily")}
                                  onChange={(e) => handleBasis(e, basisData)}
                                />
                                <label for="Daily">{t("Daily_Basis")}</label>
                              </li>
                              <li className="cheak_days">
                                <input
                                  type="checkbox"
                                  id="Monthly"
                                  name="basis"
                                  value="Monthly"
                                  checked={basisData.includes("Monthly")}
                                  onChange={(e) => handleBasis(e, basisData)}
                                />
                                <label for="Monthly">
                                  {t("Monthly_Basis")}
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      {" "}
                      {availabilty !== "onrent" && (
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Vehicle Status")}
                            </label>
                            <select
                              name="Vehicle"
                              id="availabilty"
                              value={availabilty}
                              onChange={handleAvailabilty}
                            >
                              <option value="available">
                                {t("Available")}
                              </option>
                              <option value="sold">{t("Sold")}</option>
                              <option value="maintanance">
                                {t("In Maintenance")}
                              </option>
                              <option value="service">{t("In_Service")}</option>
                            </select>
                            {submit && !availabilty && (
                              <div className="req_field">
                                {t("Availablity_required")}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {availabilty === "onrent" && (
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Availablity")}
                            </label>
                            <input
                              className="comp_input"
                              type="availabilty"
                              placeholder="availabilty"
                              name="availabilty"
                              id="availabilty"
                              value={availabilty}
                              disabled
                            />
                            {submit && !availabilty && (
                              <div className="req_field">
                                {t("Availablity_required")}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-3">
                      {" "}
                      {availabilty === "sold" && (
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Sale_Price")}
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Sale Price"
                              name="salePrice"
                              id="salePrice"
                              value={salePrice}
                              onChange={handleSalePrice}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-3">
                      {" "}
                      {availabilty === "sold" && (
                        <div className=" company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Date Sold")}
                            </label>

                            <Datetime
                              dateFormat="DD/MM/YYYY"
                              onChange={handleDateSold}
                              value={dateSold}
                              closeOnSelect={true}
                              timeFormat={false}
                              inputProps={{
                                placeholder: "Select From date",
                                disabled: false,
                                readOnly: true,
                                onKeyDown: (e) => e.preventDefault(),
                              }}
                            />
                            <div className="cal_img">
                              <img src={Calender} alt="calender" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                  <div className="main_ownbutton flt">
                    <div className="own_button flt">
                      {checkRes === true && (
                        <button
                          className="backbutton"
                          onClick={navigateToVehiclesListsPage}
                        >
                          {t("Back")}
                        </button>
                      )}
                      {id === "" && (
                        <button
                          className="submitbutton"
                          onClick={(e) => {
                            handleGeneralInfo(e, tab, setTabId);
                          }}
                        >
                          {t("Save_Continue")}
                        </button>
                      )}
                      {id !== "" && (
                        <button
                          className="submitbutton"
                          onClick={(e) => {
                            handleEditGeneralInfo(e);
                          }}
                        >
                          {t("Update_Continue")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const getTableDetails = () => {
    return (
      <>
        {showVehicle && (
          <div className="modal" id="myModal">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="main_clone flt">
                  <div className="clone_vehicle flt">
                    <img src={vehicle} alt="Vehicle" />
                    {t("Clone_Vehicle")}
                  </div>
                </div>
                <div className="modal-body">
                  <div className="close_btn flt">
                    <button
                      type="button"
                      className="close_button"
                      data-dismiss="modal"
                      onClick={closeVehicle}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="main_tablehead flt">
                    <table className="main_table1 flt ">
                      <DataTable
                        columns={columns}
                        data={listTable || []}
                        onRowClicked={(row) =>
                          handleModalRow(row, setShowVehicle(false))
                        }
                      />
                    </table>
                    {listData?.data?.data?.docs.length !== 0 && (
                      <div>
                        <ReactPaginate
                          nextLabel="next"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={1}
                          marginPagesDisplayed={1}
                          pageCount={totalPages}
                          previousLabel="prev"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {getGenInfo()}
      {getTableDetails()}
    </>
  );
};

export default GeneralInfoTab;
