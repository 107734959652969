import { useState } from "react";
import { Addinvestor } from "../../../../../../../../assets/images";
import { Calender } from "../../../../../../../../assets/images";
import { Block } from "../../../../../../../../assets/images";
import { Ic_Home14 } from "../../../../../../../../assets/images";
import { useLocation } from "react-router";
import WalkinAddCustHook from "./WalkinAddCustHook";
import Menu from "../../../../../../../_common/menu/Menu";
import TopBar from "../../../../../../../_common/topbar/TopBar";
import { Loading } from "../../../../../../../../assets/images";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const WalkinAddCustomer = () => {
  const {
    fullname,
    email,
    mobileno,
    alternatemobileno,
    date_of_birth,
    adhar_number,
    drivinglicense_number,
    drivinglicense_expirydate,
    age,
    fileName1,
    selectedFile1,
    drivinglicense_frontsideimage,
    fileName2,
    selectedFile2,
    drivinglicense_backsideimage,
    fileName3,
    selectedFile3,
    id_frontside,
    fileName4,
    selectedFile4,
    id_backside,
    status,
    id,
    submit,
    alternate_id,
    loader,
    handleAlternateId,
    navigateToListPage,
    handleFullnameChange,
    handleDate_of_birthChange,
    handleEmailChange,
    handleMobileNoChange,
    handleAlternatemobilenoChange,
    handleAdhar_numberChange,
    handleDrivingLicense_numberChange,
    handleDrivingLicense_expirydateChange,
    handleSubmit,
    onSelectFile1,
    onSelectFile2,
    onSelectFile3,
    onSelectFile4,
    changeAcceptCheckboxHandler,
    handleDeleteSubmit,
    handleEditSubmit,
  } = WalkinAddCustHook();

  const location = useLocation();
  const [tab, setTab] = useState(1);

  const PersonalDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
          <div className="main_company flt">
            <div className="main_row flt">
              <div className="row">
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Full Name *</label>
                      <input
                        type="text"
                        placeholder="Name"
                        name="fullname"
                        id="fullname"
                        value={fullname}
                        onChange={handleFullnameChange}
                      />
                      {submit && !fullname && (
                        <div className="req_field">Full Name is required</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Email ID </label>
                      <input
                        type="text"
                        placeholder="Email ID"
                        name="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      {/* {submit && !email && (
                        <div className="req_field">Email ID is required</div>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Mobile Number *</label>
                      <input
                        type="number"
                        placeholder="Mobile Number"
                        name="mobileno"
                        id="mobileno"
                        value={mobileno}
                        onChange={handleMobileNoChange}
                      />
                      {submit && !mobileno && (
                        <div className="req_field">
                          Mobile Number is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">
                        Alternative Mobile Number
                      </label>
                      <input
                        type="number"
                        placeholder="Alternative Mobile Number"
                        name="alternatemobileno"
                        id="alternatemobileno"
                        value={alternatemobileno}
                        onChange={handleAlternatemobilenoChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Date of Birth </label>

                      <Datetime
                        dateFormat="DD/MM/YYYY"
                        onChange={handleDate_of_birthChange}
                        value={date_of_birth}
                        closeOnSelect={true}
                        timeFormat={false}
                        inputProps={{
                          placeholder: "Select date",
                          disabled: false,
                          readOnly: true,
                          onKeyDown: (e) => e.preventDefault(),
                        }}
                      />
                      <div className="cal_img">
                        <img src={Calender} alt="calender" />
                      </div>
                      {/* {submit && !date_of_birth && (
                        <div className="req_field">
                          Date of Birth is required
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Age</label>
                      <input
                        type="text"
                        placeholder=""
                        name="age"
                        id="age"
                        value={age}
                        className={
                          parseInt(age.substring(0, 2)) >= 18
                            ? "green dateform"
                            : "red dateform"
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-3"></div>
                <div className="col-sm-3"></div>
              </div>
              <div className="main_ownbutton flt">
                {id !== "" && (
                  <div className="main_bloack flt">
                    <div className="blocking ">
                      <label for="vehicle1" className="label_block">
                        <img src={Block} alt="book" />
                        <div className="add_cls">
                          Add to Blocklist
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="status"
                            onClick={changeAcceptCheckboxHandler}
                            checked={status === "Active" ? false : true}
                          ></input>
                        </div>
                      </label>
                    </div>
                  </div>
                )}

                <div className="own_button flt">
                  <button className="backbutton" onClick={navigateToListPage}>
                    Back
                  </button>
                  <button
                    className="submitbutton"
                    onClick={() => {
                      setTab(2);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  const DocumentDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
          <div className="main_company flt">
            <div className="main_row flt">
              <div className="row">
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Aadhaar Number</label>
                      <input
                        type="text"
                        placeholder="Aadhaar Number"
                        name="adhar_number"
                        id="adhar_number"
                        value={adhar_number}
                        onChange={handleAdhar_numberChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">
                        Driving Licence Number
                      </label>
                      <input
                        type="text"
                        placeholder="Driving Licence Number"
                        name="drivinglicense_number"
                        id="drivinglicense_number"
                        value={drivinglicense_number}
                        onChange={handleDrivingLicense_numberChange}
                      />
                      {/* {submit && !drivinglicense_number && (
                        <div className="req_field">
                          Driving Licence Number is required
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Licence Expiry Date </label>

                      <Datetime
                        dateFormat="DD/MM/YYYY"
                        onChange={handleDrivingLicense_expirydateChange}
                        value={drivinglicense_expirydate}
                        closeOnSelect={true}
                        timeFormat={false}
                        inputProps={{
                          placeholder: "Select date",
                          disabled: false,
                          readOnly: true,
                          onKeyDown: (e) => e.preventDefault(),
                        }}
                      />
                      <div className="cal_img">
                        <img src={Calender} alt="calender" />
                      </div>
                      {/* {submit && !drivinglicense_expirydate && (
                        <div className="req_field">
                          Licence Expiry Date is required
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">ID Number</label>
                      <input
                        type="text"
                        placeholder="ID Number"
                        name="alternate_id"
                        id="alternate_id"
                        value={alternate_id}
                        onChange={handleAlternateId}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3">
                  <div className="form-group flt">
                    <label className="labelName">DL Front Side Image </label>
                  </div>

                  <div className="upload_img flt">
                    <div className="upload_img_btn flt">
                      <button>
                        {!selectedFile1 &&
                          location?.state?.row
                            ?.drivinglicense_frontsideimage && (
                            <img
                              src={`${process.env.REACT_APP_MEDIA_DOMAIN}${location?.state?.row?.drivinglicense_frontsideimage}`}
                              width="100%"
                              height="150"
                              alt="upload"
                            />
                          )}

                        {selectedFile1 && (
                          <img
                            src={drivinglicense_frontsideimage}
                            width="100%"
                            height="150"
                            alt="upload"
                          />
                        )}
                        {!selectedFile1 &&
                          !location?.state?.row
                            ?.drivinglicense_frontsideimage && (
                            <img src={Ic_Home14} alt="upload" />
                          )}

                        <div className="browse_logo_img">
                          DL Front Side Image
                        </div>
                      </button>

                      <input
                        type="file"
                        name="myfile1"
                        onChange={onSelectFile1}
                        key={fileName1}
                        id="myfile1"
                        accept="image/png,image/jpeg"
                      />

                      {/* {submit &&
                          selectedFile1 &&
                          !location?.state?.row
                            ?.drivinglicense_frontsideimage && (
                            <div className="req_field">
                              DL Front Side Image is required
                            </div>
                          )} */}
                    </div>{" "}
                  </div>
                  {location?.state?.row?.drivinglicense_frontsideimage && (
                    <a
                      href={`${process.env.REACT_APP_MEDIA_DOMAIN}${location?.state?.row?.drivinglicense_frontsideimage}`}
                    >
                      Download
                    </a>
                  )}
                </div>

                <div className="col-sm-3">
                  <div className="form-group flt">
                    <label className="labelName">DL Back Side Image </label>
                  </div>

                  <div className="upload_img flt">
                    <div className="upload_img_btn flt">
                      <button>
                        {!selectedFile2 &&
                          location?.state?.row
                            ?.drivinglicense_backsideimage && (
                            <img
                              src={`${process.env.REACT_APP_MEDIA_DOMAIN}${location?.state?.row?.drivinglicense_backsideimage}`}
                              width="100%"
                              height="150"
                              alt="upload"
                            />
                          )}

                        {selectedFile2 && (
                          <img
                            src={drivinglicense_backsideimage}
                            width="100%"
                            height="150"
                            alt="upload"
                          />
                        )}
                        {!selectedFile2 &&
                          !location?.state?.row
                            ?.drivinglicense_backsideimage && (
                            <img src={Ic_Home14} alt="upload" />
                          )}

                        <div className="browse_logo_img">
                          DL Back Side Image
                        </div>
                      </button>

                      <input
                        type="file"
                        name="myfile2"
                        onChange={onSelectFile2}
                        key={fileName2}
                        id="myfile2"
                        accept="image/png,image/jpeg"
                      />
                      {/* {submit &&
                          selectedFile2 &&
                          !location?.state?.row?.drivinglicense_backsideimage && (
                            <div className="req_field">
                              DL Back Side Image is required
                            </div>
                          )} */}
                    </div>
                  </div>
                  {location?.state?.row?.drivinglicense_backsideimage && (
                    <a
                      href={`${process.env.REACT_APP_MEDIA_DOMAIN}${location?.state?.row?.drivinglicense_backsideimage}`}
                    >
                      Download
                    </a>
                  )}
                </div>

                <div className="col-sm-3">
                  <div className="form-group flt">
                    <label className="labelName">ID Front Side </label>
                  </div>
                  <div className="upload_img flt">
                    <div className="upload_img_btn flt">
                      <button>
                        {!selectedFile3 &&
                          location?.state?.row?.id_frontside && (
                            <img
                              src={`${process.env.REACT_APP_MEDIA_DOMAIN}${location?.state?.row?.id_frontside}`}
                              width="100%"
                              height="150"
                              alt="upload"
                            />
                          )}
                        {selectedFile3 && (
                          <img
                            src={id_frontside}
                            width="100%"
                            height="150"
                            alt="upload"
                          />
                        )}
                        {!selectedFile3 &&
                          !location?.state?.row?.id_frontside && (
                            <img src={Ic_Home14} alt="upload" />
                          )}
                        <div className="browse_logo_img">ID Front Side</div>
                      </button>
                      <input
                        type="file"
                        name="myfile3"
                        onChange={onSelectFile3}
                        key={fileName3}
                        id="myfile3"
                        accept="image/png,image/jpeg"
                      />
                      {/* {submit &&
                          selectedFile3 &&
                          !location?.state?.row?.id_frontside && (
                            <div className="req_field">
                              ID Front Side Image is required
                            </div>
                          )} */}
                    </div>
                  </div>
                  {location?.state?.row?.id_frontside && (
                    <a
                      href={`${process.env.REACT_APP_MEDIA_DOMAIN}${location?.state?.row?.id_frontside}`}
                    >
                      Download
                    </a>
                  )}
                </div>
                <div className="col-sm-3">
                  <div className="form-group flt">
                    <label className="labelName">ID Back Side </label>
                  </div>
                  <div className="upload_img flt">
                    <div className="upload_img_btn flt">
                      <button>
                        {!selectedFile4 &&
                          location?.state?.row?.id_backside && (
                            <img
                              src={`${process.env.REACT_APP_MEDIA_DOMAIN}${location?.state?.row?.id_backside}`}
                              width="100%"
                              height="150"
                              alt="upload"
                            />
                          )}
                        {!selectedFile4 &&
                          !location?.state?.row?.id_backside && (
                            <img src={Ic_Home14} alt="upload" />
                          )}{" "}
                        {selectedFile4 && (
                          <img
                            src={id_backside}
                            width="100%"
                            height="150"
                            alt="upload"
                          />
                        )}
                        <div className="browse_logo_img">ID Back Side</div>
                      </button>
                      <input
                        type="file"
                        name="myfile4"
                        onChange={onSelectFile4}
                        key={fileName4}
                        id="myfile4"
                        accept="image/png,image/jpeg"
                      />
                      {/* {submit &&
                          selectedFile4 &&
                          !location?.state?.row?.id_backside && (
                            <div className="req_field">
                              ID Back Side Image is required
                            </div>
                          )} */}
                    </div>
                  </div>
                  {location?.state?.row?.id_backside && (
                    <a
                      href={`${process.env.REACT_APP_MEDIA_DOMAIN}${location?.state?.row?.id_backside}`}
                    >
                      Download
                    </a>
                  )}
                </div>
                <div className="main_ownbutton flt">
                  <div className="own_button flt">
                    <button
                      className="backbutton"
                      onClick={() => {
                        setTab(1);
                      }}
                    >
                      Back
                    </button>
                    {id !== "" && (
                      <button
                        className="submitbutton"
                        onClick={handleEditSubmit}
                      >
                        Submit
                      </button>
                    )}
                    {id === "" && (
                      <button className="submitbutton" onClick={handleSubmit}>
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const titleDisplay = () => {
    return (
      <>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="Company_name flt">
              {!location?.state?.row?.id && (
                <div className="comp_name_details flt">
                  <img src={Addinvestor} alt="investor" />
                  Add New Customer
                </div>
              )}
              {location?.state?.row?.id && (
                <div className="comp_name_details flt">
                  <img src={Addinvestor} alt="invest" />
                  Edit Customer
                </div>
              )}
              {location?.state?.row?.id && (
                <div className="delete_b flt">
                  <button className="delete_btn" onClick={handleDeleteSubmit}>
                    Delete
                  </button>
                </div>
              )}
            </div>
            <div className="main_general1 flt">
              <div className="general_informaion flt">
                <ul className="insurance_info ">
                  <li className={tab === 1 ? `activeborder` : ""}>
                    <button
                      className="per_info"
                      onClick={() => {
                        setTab(1);
                      }}
                    >
                      Personal Information
                    </button>
                  </li>
                  <li className={tab === 2 ? `activeborder` : ""}>
                    <button
                      className="per_info"
                      onClick={() => {
                        setTab(2);
                      }}
                    >
                      Documents Details
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />

      {titleDisplay()}

      <div className="E_comp_details flt">
        {tab === 1 && PersonalDetails()}
        {tab === 2 && DocumentDetails()}
      </div>
    </div>
  );
};
export default WalkinAddCustomer;
