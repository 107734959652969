import useEndRentCheckIn from "./useEndRentCheckIn";
import { fuel } from "../../../../../../../assets/images";
import { odo } from "../../../../../../../assets/images";
import { Loading } from "../../../../../../../assets/images";

const EndRentCheckIn = ({ displayData, tab, bookid }) => {
  const {
    checkInOdometer,
    checkInfuellevel,
    submit,
    loader,
    Changehandler,
    checkOutStore,
    navigateToCheckIn,
  } = useEndRentCheckIn(displayData, bookid);
  const PersonalDetails = () => {
    return (
      <>
        {(loader === "loading" || loader === "notset") && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" || loader === "isempty") && (
          <div className="E_comp_details flt">
            <div className="main_boxShadow flt">
              <div className="main_company flt">
                <div className="main_row flt">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">ODO Meter *</label>

                          <div className="make_img12 flt">
                            <img src={odo} alt="ADO" />
                            <input
                              type="number"
                              placeholder="ODO Meter"
                              name="odometer"
                              id="odometer"
                              value={checkInOdometer}
                              onChange={Changehandler}
                            />
                          </div>
                          {submit && !checkInOdometer && (
                            <div className="req_field">
                              ODO Meter is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">Fuel Level *</label>
                          <div className="make_img12 flt">
                            <img src={fuel} alt="fuel" />
                            <select
                              name="fuellevel"
                              id="fuellevel"
                              onChange={Changehandler}
                              value={checkInfuellevel}
                            >
                              <option value="">Select Fuel Level</option>
                              <option value="full">Full</option>
                              <option value="half">Half</option>
                              <option value="reserve">Reserve</option>
                            </select>
                          </div>
                          {submit && !checkInfuellevel && (
                            <div className="req_field">
                              Select Fuel Level is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="main_ownbutton flt">
                    <div className="own_button flt">
                      <button
                        className="backbutton"
                        onClick={navigateToCheckIn}
                      >
                        Back
                      </button>
                      <button className="submitbutton" onClick={checkOutStore}>
                        CheckIn
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  return <>{PersonalDetails()}</>;
};
export default EndRentCheckIn;
