import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ReportService } from "../../../../services";
import { alertActions } from "../../../../actions";

const usePaymentHooks = (t) => {
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);
  const authdata = useSelector((state) => state?.userData);
  const [loaderDownLoad, setLoaderDownload] = useState("notset");
  const dispatch = useDispatch();

  const columns = [
    {
      name: t("Sr_No"),
      selector: (row) => row.srnumber,
      width: "80px",
    },
    {
      name: t("Reg_No"),
      selector: (row) => row.regNo,
      width: "180px",
    },
    {
      name: t("Payment_Date"),
      selector: (row) => row.paymentDate,
      width: "180px",
    },

    {
      name: t("Customer_Name"),
      selector: (row) => row.CustomerName,
      width: "250px",
    },
    {
      name: t("Vehicle_Details"),
      selector: (row) => row.vehicleDetails,
      width: "250px",
    },
    {
      name: t("Payment Type"),
      selector: (row) => row.PaymentType,
      width: "180px",
    },

    {
      name: t("Payment_Details"),
      selector: (row) => row.Payment_Details,
      width: "180px",
    },
    {
      name: t("Total Amount"),
      selector: (row) =>
        row.TotalAmount !== 0 ? `₹${row.TotalAmount}` : "N/A",
      width: "180px",
    },
  ];

  /**
   * call response
   */
  useEffect(() => {
    if (fromDate !== "" || toDate !== "") {
      paymentListResponse(page);
    }
    // eslint-disable-next-line
  }, [page, fromDate, toDate]);

  /**
   * list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  let order = [];
  let obj = {};

  function parseDate(dateString) {
    if (!dateString) return "N/A";

    const [datePart, timePart] = dateString.split(", ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [time, modifier] = timePart.split(" ");

    let [hours, minutes] = time.split(":").map(Number);
    if (modifier === "pm" && hours < 12) {
      hours += 12; // Convert to 24-hour format
    } else if (modifier === "am" && hours === 12) {
      hours = 0; // Midnight case
    }

    const parsedDate = new Date(year, month - 1, day, hours, minutes);
    return isNaN(parsedDate) ? "N/A" : parsedDate;
  }

  /**
   * list
   */
  const list = () => {
    listData.data.data.docs.map((item, index) => {
      // let startDate = item?.rentalData?.[0]?.StartDateTime
      //   ? new Date(item?.rentalData?.[0]?.StartDateTime)
      //   : "N/A";
      // let endDate = item?.rentalData?.[0]?.EndDateTime
      //   ? new Date(item?.rentalData?.[0]?.EndDateTime)
      //   : "N/A";
      let startDate = parseDate(item?.StartDateTime);
      let endDate = parseDate(item?.EndDateTime);
      obj = {
        srnumber:
          listData.data.data.limit * (listData.data.data.page - 1) + index + 1,
        StartDate: startDate
          ? moment(startDate).format("DD/MM/YYYY, hh:mm a")
          : "N/A",
        EndDate: endDate
          ? moment(endDate).format("DD/MM/YYYY, hh:mm a")
          : "N/A",
        CustomerName: item?.customersData?.[0]?.fullname
          ? item?.customersData?.[0]?.fullname
          : "N/A",
        PaymentType: item?.Payment_type,
        TotalAmount: item?.Payment_Amount,
        id: item._id,
        vehicleDetails: item?.vehicleClass?.[0]?.vehicleClass
          ? item?.vehicleClass?.[0]?.vehicleClass +
            " " +
            item?.vehicleMake?.[0]?.make +
            " " +
            item?.vehicleModel?.[0]?.model
          : "N/A",
        paymentDate: item?.Payment_Date
          ? moment.utc(item?.Payment_Date).format("DD/MM/YYYY")
          : "N/A",
        regNo: item?.vehicles?.[0]?.regNumber
          ? item?.vehicles?.[0]?.regNumber
          : "N/A",
        Payment_Details: item?.Payment_Details ? item?.Payment_Details : "N/A",
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };
  /**
   * api call for list
   */
  const paymentListResponse = () => {
    if (toDate) {
      if (Date.parse(fromDate) > Date.parse(toDate)) {
        dispatch(
          alertActions.error("End Date Should be Greater than Start Date")
        );
      } else {
        setLoader("loading");
        ReportService.getPaymentReportList(page, fromDate, toDate)
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setLoader("isfull");
              getPaymentAmount();
              setListData(response);
              setTotalPages(response?.data?.data?.totalPages);
              setPage(response?.data?.data?.page);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  /**
   * get payment
   */
  const getPaymentAmount = () => {
    ReportService.getPaymentAmount(fromDate, toDate)
      .then((response) => {
        setTotalAmount(
          response?.data?.data?.docs?.[0]?.paymentsTotal
            ? response?.data?.data?.docs?.[0]?.paymentsTotal
            : 0
        );
      })
      .catch((err) => console.log(err));
  };

  /**
   * handle change
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };
  const handleStartDate = (e) => {
    setFromDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleEndDate = (e) => {
    setToDate(moment(e).format("DD/MM/YYYY"));
  };

  /**
   * download report
   */
  const downloadReport = () => {
    setLoaderDownload("loading");
    axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_APIURL}/admin/reports/payments-excel?from=${fromDate}&to=${toDate}`,

      method: "GET",
      responseType: "arraybuffer",

      headers: {
        // Add any auth token here
        authorization: authdata?.type + " " + authdata?.token,
        "Content-Type": "blob",
      },
      // Attaching the form data
    }).then((response) => {
      setLoaderDownload("isfull");
      const resp = response;
      const link = document.createElement("a");
      const fileName = `${Date.now()}.xlsx`;
      link.setAttribute("download", fileName);
      link.href = URL.createObjectURL(new Blob([resp.data]));
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  return {
    loader,
    listData,
    totalPages,
    fromDate,
    toDate,
    totalAmount,
    page,
    columns,
    listTable,
    loaderDownLoad,
    downloadReport,
    handlePageClick,
    handleStartDate,
    handleEndDate,
  };
};
export default usePaymentHooks;
