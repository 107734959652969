import { useEffect, useState } from "react";
import { VehicleService } from "../../../../../services/VehicleService";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { SetUpService } from "../../../../../services";

const useVehicleExpiration = () => {
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState("notset");
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("regno");
  const [fromDate, setFromDate] = useState("");
  const [noData, setNoData] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [nextPuc, setNextPuc] = useState("");
  const [nextRc, setNextRc] = useState("");
  const [nextFitness, setNextFitness] = useState("");
  const [nextPermit, setNextPermit] = useState("");
  const [nextInsurance, setNextInsurance] = useState("");
  const navigate = useNavigate();

  const columns = [
    {
      name: "Sr",
      selector: (row) => row.number,
      width: "60px",
    },

    {
      name: "Reg. No",
      selector: (row) => row.regNumber,
      width: "150px"
    },
    {
      name: "PUC Exp",

      selector: (row) => {
        return (
          <>
            <div
              className={
                moment(row.pucExpiryDate, "DD/MM/YYYY") <=
                  moment(new Date(), "DD/MM/YYYY").add(nextPuc - 1, "days")
                  ? "button_style_expiry"
                  : ""
              }
            >
              {row.pucExpiryDate}
            </div>
          </>
        );
      },
    },
    {
      name: "Insurance Exp",

      selector: (row) => {
        return (
          <>
            <div
              className={
                moment(row.insuranceExpireDate, "DD/MM/YYYY") <=
                  moment(new Date(), "DD/MM/YYYY").add(nextInsurance - 1, "days")
                  ? "button_style_expiry"
                  : ""
              }
            >
              {row.insuranceExpireDate}
            </div>
          </>
        );
      },
    },
    {
      name: "Fitness Exp",
      selector: (row) => {
        return (
          <>
            <div
              className={
                moment(row.fitnessExpiryDate, "DD/MM/YYYY") <=
                  moment(new Date(), "DD/MM/YYYY").add(nextFitness - 1, "days")
                  ? "button_style_expiry"
                  : ""
              }
            >
              {row.fitnessExpiryDate}
            </div>
          </>
        );
      },
    },

    {
      name: "Permit Expiry",
      selector: (row) => {
        return (
          <>
            <div
              className={
                moment(row.permitExpiryDate, "DD/MM/YYYY") <=
                  moment(new Date(), "DD/MM/YYYY").add(nextPermit - 1, "days")
                  ? "button_style_expiry"
                  : ""
              }
            >
              {row.permitExpiryDate}
            </div>
          </>
        );
      },
    },
    {
      name: "RC Expiry",

      selector: (row) => {
        return (
          <>
            <div
              className={
                moment(row.RCExpiryDate, "DD/MM/YYYY") <=
                  moment(new Date(), "DD/MM/YYYY").add(nextRc - 1, "days")
                  ? "button_style_expiry"
                  : ""
              }
            >
              {row.RCExpiryDate}
            </div>
          </>
        );
      },
    },
  ];

  let order = [];
  let obj = {};

  /**
   * vehicle list call
   */
  useEffect(() => {
    vehicleDocResponse(page, item, search, fromDate);
    // eslint-disable-next-line
  }, []);

  /**
   * list call
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * get gen info
   */
  useEffect(() => {
    getGeneralInfo();
    // eslint-disable-next-line
  }, []);

  /**
   * get general details
   */
  function getGeneralInfo() {
    SetUpService.getGeneralInfo()
      .then((response) => {
        if (response?.data?.data?.docs.length !== 0) {
          setNextPuc(
            response?.data?.data?.docs?.[0]?.vehicleReminders?.NextPucDate
              ?.daysInAdvance
              ? response?.data?.data?.docs?.[0]?.vehicleReminders?.NextPucDate
                ?.daysInAdvance
              : ""
          );
          setNextRc(
            response?.data?.data?.docs?.[0]?.vehicleReminders?.NextRCDate
              ?.daysInAdvance
              ? response?.data?.data?.docs?.[0]?.vehicleReminders?.NextRCDate
                ?.daysInAdvance
              : ""
          );
          setNextFitness(
            response?.data?.data?.docs?.[0]?.vehicleReminders?.NextFitnessDate
              ?.daysInAdvance
              ? response?.data?.data?.docs?.[0]?.vehicleReminders
                ?.NextFitnessDate?.daysInAdvance
              : ""
          );
          setNextPermit(
            response?.data?.data?.docs?.[0]?.vehicleReminders?.NextPermitDate
              ?.daysInAdvance
              ? response?.data?.data?.docs?.[0]?.vehicleReminders
                ?.NextPermitDate?.daysInAdvance
              : ""
          );
          setNextInsurance(
            response?.data?.data?.docs?.[0]?.vehicleReminders?.NextInsuranceDate
              ?.daysInAdvance
              ? response?.data?.data?.docs?.[0]?.vehicleReminders
                ?.NextInsuranceDate?.daysInAdvance
              : ""
          );
        }
      })
      .catch((err) => console.log(err));
  }

  /**
   * table list call
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      obj = {
        id: item?._id,
        number:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        RCExpiryDate: item?.documentDetails?.RCExpiryDate
          ? moment(item?.documentDetails?.RCExpiryDate).format("DD/MM/YYYY")
          : "N/A",
        insuranceExpireDate: item?.insuranceDetails?.insuranceExpireDate
          ? moment(item?.insuranceDetails?.insuranceExpireDate).format(
            "DD/MM/YYYY"
          )
          : "N/A",
        fitnessExpiryDate: item?.documentDetails?.fitnessExpiryDate
          ? moment(item?.documentDetails?.fitnessExpiryDate).format(
            "DD/MM/YYYY"
          )
          : "N/A",
        permitExpiryDate: item?.documentDetails?.permitExpiryDate
          ? moment(item?.documentDetails?.permitExpiryDate).format("DD/MM/YYYY")
          : "N/A",
        pucExpiryDate: item?.documentDetails?.pucExpiryDate
          ? moment(item?.documentDetails?.pucExpiryDate).format("DD/MM/YYYY")
          : "N/A",
        regNumber: item?.vehicleDetails?.[0]?.regNumber,
        stockNumber: item?.vehicleDetails?.[0]?.stockNumber,
      };
      return order.push(obj);
    });
    if (order.length) setListTable(order);
  };
  /**
   * list api call
   * @param {*} page
   * @param {*} item
   * @param {*} search
   * @param {*} fromDate
   */
  const vehicleDocResponse = (page, item, search, fromDate) => {
    setLoader("loading");
    VehicleService.getDocVehicleList(page, item, search.trim(), fromDate)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setNoData(false);
          setListData(response);
          setTotalPages(response?.data?.data?.totalPages);
          setPage(response?.data?.data?.page);
          setLoader("isfull");
        } else {
          setLoader("empty");
          setNoData(true);
        }
      })
      .catch((err) => console.log(err));
  };
  /**
   * handle change
   * @param {*} e
   */
  const handleStartDate = (e) => {
    setFromDate(moment(e).format("DD/MM/YYYY"));
    vehicleDocResponse(page, item, "", moment(e).format("DD/MM/YYYY"));
  };

  /**
   * change handler
   * @param {*} e
   */
  const changeItemHandler = (e) => {
    setSearch("");
    setFromDate("");
    if (e.target.value === "model") {
      setItem(e.target.value);
      vehicleDocResponse(1, e.target.value, "", fromDate);
    } else if (e.target.value === "expdate") {
      setItem(e.target.value);
      vehicleDocResponse(1, e.target.value, "", fromDate);
    } else {
      setItem(e.target.value);
      vehicleDocResponse(1, e.target.value, "", fromDate);
    }
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    navigate(`/vehicle-details?vid=${row.id}`, {
      state: {
        row: row,
      },
    });
  };

  /**
   * search handler
   * @param {*} e
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    vehicleDocResponse("", item, e.target.value, fromDate);
  };
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    vehicleDocResponse(e.selected + 1, item, search, fromDate);
  };

  return {
    loader,
    listData,
    totalPages,
    page,
    search,
    item,
    fromDate,
    noData,
    columns,
    listTable,
    nextPuc,
    nextRc,
    nextFitness,
    nextPermit,
    nextInsurance,
    handlePageClick,
    handleRow,
    changeSearchHandler,
    changeItemHandler,
    handleStartDate,
  };
};
export default useVehicleExpiration;
