import { Link } from "react-router-dom";
import "./Menu.css";
import { Ic_Home1 } from "../../../assets/images";
import { Ic_Home2 } from "../../../assets/images";
import { Ic_Home5 } from "../../../assets/images";
import { Ic_Home6 } from "../../../assets/images";
import { Ic_Home7 } from "../../../assets/images";
import { Ic_Home10 } from "../../../assets/images";
import { Ic_Home11 } from "../../../assets/images";
import { Ic_Home12 } from "../../../assets/images";
import { Investors } from "../../../assets/images";
import { Day } from "../../../assets/images";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

function Menu(props) {
  const { t } = props;
  const location = useLocation();
  const topRibbonDisplay = useSelector((state) => state?.userData?.user?.topRibbonUA)
  return (
    /* header icon start */

    <ul className="menu_h flt">

      <li className={location.pathname === "/" ? "menuActive" : ""}>
        <Link to="/dashboard">
          <img src={Ic_Home1} alt="images avilable" />
          <br />
          {t("Dashboard")}
        </Link>
      </li>

      <li
        className={
          location.pathname === "/agreement" ||
            location.pathname === "/BookingList" ||
            location.pathname === "/OnRentList" ||
            location.pathname === "/agreeTab" ||
            location.pathname === "/booking" ||
            location.pathname === "/ReturnList" ||
            location.pathname === "/BookingStartRent" ||
            location.pathname === "/BookingAgreeTab" ||
            location.pathname === "/StartRentWalkin" ||
            location.pathname === "/WalkinBookingAgreeTab" ||
            location.pathname === "/exchange-list" ||
            location.pathname === "/exchange-otpScreen" ||
            location.pathname === "/exchange-details" ||
            location.pathname === "/BookAddCust" ||
            location.pathname === "/WalkinAddCustomer" ||
            location.pathname === "/ExtendedDateTab" ||
            location.pathname === "/pending-payment" ||
            location.pathname === "/PendingPaymentTab" ||
            location.pathname === "/Partial-BookingList" ||
            location.pathname === "/StartRentWalkInOtpscreen"
            ? "menuActive"
            : ""
        }
      >
        {topRibbonDisplay?.includes("newAgreement") ?
          <Link to="/agreement">
            <img src={Ic_Home2} alt="agreement" />
            <br />
            New Agreement
          </Link> : <Link style={{ pointerEvents: "none", opacity: "0.5" }} to="/agreement">
            <img src={Ic_Home2} alt="agreement" />
            <br />
            New Agreement
          </Link>}
      </li>
      <li
        className={
          location.pathname === `/daytodaytask` ||
            location.pathname === "/daytodaytask-detail"
            ? "menuActive"
            : ""
        }
      >
        {topRibbonDisplay?.includes("dayToDayTask") ?
          <Link to="/daytodaytask">
            <img src={Day} alt="daytodaytask" />
            <br />
            Day to Day Task
          </Link> : <Link style={{ pointerEvents: "none", opacity: "0.5" }} to="/daytodaytask">
            <img src={Day} alt="daytodaytask" />
            <br />
            Day to Day Task
          </Link>}
      </li>

      <li
        className={
          location.pathname === "/vehicles" ||
            location.pathname === "/vehicles-available" ||
            location.pathname === "/vehicle-expiration" ||
            location.pathname === "/vehicles-maintanance" ||
            location.pathname === "/vehicles-service" ||
            location.pathname === "/vehicle-details" ||
            location.pathname === "/vehicles-sold" ||
            location.pathname === "/oilChange-alert" ||
            location.pathname === "/maintenance-alert"
            ? "menuActive"
            : "" || location.pathname === "/Insurance-Alert"
              ? "menuActive"
              : ""
        }
      >
        {topRibbonDisplay?.includes("vehicles") ?
          <Link to="/vehicles">
            <img src={Ic_Home5} alt="vehicles" />
            <br />
            Vehicles
          </Link> : <Link style={{ pointerEvents: "none", opacity: "0.5" }} to="/vehicles">
            <img src={Ic_Home5} alt="vehicles" />
            <br />
            Vehicles
          </Link>}
      </li>
      <li className={location.pathname === "/closed-agreementList" ? "menuActive" : ""}>
        {topRibbonDisplay?.includes("invoices") ?
          <Link to="/closed-agreementList">
            <img src={Ic_Home6} alt="dashboard" />
            <br />
            Closed Agreements
          </Link> : <Link style={{ pointerEvents: "none", opacity: "0.5" }} to="/closed-agreementList">
            <img src={Ic_Home6} alt="dashboard" />
            <br />
            Closed Agreements
          </Link>}
      </li>
      <li
        className={
          location.pathname === "/customers" ||
            location.pathname === "/customers-details" ||
            location.pathname === "/customers-history"
            ? "menuActive"
            : ""
        }
      >
        {topRibbonDisplay?.includes("customers") ?
          <Link to="/customers">
            <img src={Ic_Home7} alt="dashboard" />
            <br />
            Customers
          </Link> : <Link style={{ pointerEvents: "none", opacity: "0.5" }} to="/customers">
            <img src={Ic_Home7} alt="dashboard" />
            <br />
            Customers
          </Link>}
      </li>
      <li
        className={
          location.pathname === "/investors" ||
            location.pathname === "/investor-detail"
            ? "menuActive"
            : ""
        }
      >
        {topRibbonDisplay?.includes("investors") ?
          <Link to="/investors">
            <img src={Investors} alt="investors" />
            <br />
            Investors
          </Link> : <Link style={{ pointerEvents: "none", opacity: "0.5" }} to="/investors">
            <img src={Investors} alt="investors" />
            <br />
            Investors
          </Link>}
      </li>

      <li
        className={
          location.pathname === "/report" ||
            location.pathname === "/InvestorListDetails" ||
            location.pathname === "/VehicleExpenseDetails" ||
            location.pathname === "/VehiclePLDetails"
            ? "menuActive"
            : ""
        }
      >
        {topRibbonDisplay?.includes("reports") ?
          <Link to="/report">
            <img src={Ic_Home10} alt="dashboard" />
            <br />
            Reports
          </Link> : <Link style={{ pointerEvents: "none", opacity: "0.5" }} to="/report">
            <img src={Ic_Home10} alt="dashboard" />
            <br />
            Reports
          </Link>}
      </li>
      <li
        className={
          location.pathname === "/setup-useraccess" ||
            location.pathname === "/setup-company" ||
            location.pathname === "/setup-location" ||
            location.pathname === "/setup-general-option" ||
            location.pathname === "/setup-vehicle" ||
            location.pathname === "/setup-payment-type" ||
            location.pathname === "/setup-vehicle-expense" ||
            location.pathname === "/setup-companyDetails"
            ? "menuActive"
            : ""
        }
      >
        {topRibbonDisplay?.includes("setUp") ?
          <Link to="/setup-useraccess">
            <img src={Ic_Home11} alt="useraccess" />
            <br />
            Setup
          </Link> : <Link style={{ pointerEvents: "none", opacity: "0.5" }} to="/setup-useraccess">
            <img src={Ic_Home11} alt="useraccess" />
            <br />
            Setup
          </Link>}
      </li>
      <li className={location.pathname === "/help" ? "menuActive" : ""}>
        <Link to="/help">
          <img src={Ic_Home12} alt="help" />
          <br />
          Help
        </Link>
      </li>
    </ul>
    /* header icon end */
  );
}
export default withTranslation()(Menu);
