import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../../actions";
import { VehicleService } from "../../../../../services";
import moment from "moment";

const UseDamages = (location, tab, tabId) => {
  const [id, setId] = useState(
    location?.state?.row?.id ? location?.state?.row?.id : tabId
  );
  const [damageId, setDamageId] = useState("");
  const [checkRes, setCheckRes] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [customerName, setCustomerName] = useState("");
  const [rentalStartDate, setRentalStartDate] = useState("");
  const [rentalEndDate, setRentalEndDate] = useState("");
  const [damageCostReceived, setDamageCostReceived] = useState("");
  const [damageRepairCharges, setDamageRepairCharges] = useState("");
  const [loader, setLoader] = useState("notset");
  //damage cost list
  const [list, setList] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const [listTable, setListTable] = useState([]);

  const columns = [
    {
      name: "Customer Name",
      selector: (row) => row.Customer_Name,
    },
    {
      name: "Rental Start Date",
      selector: (row) =>
        row?.Rental_Start_Date !== ""
          ? moment(row?.Rental_Start_Date).format("DD/MM/YYYY")
          : "N/A",
    },
    {
      name: "Rental End Date",
      selector: (row) =>
        row?.Rental_End_Date !== ""
          ? moment(row?.Rental_End_Date).format("DD/MM/YYYY")
          : "N/A",
    },
    {
      name: "Damage Cost Received",
      selector: (row) =>
        row.Damage_Cost_Received ? `₹${row.Damage_Cost_Received}` : "N/A",
    },
    {
      name: "Damage Repair Charges",
      selector: (row) =>
        row.Damage_Repair_Charges ? `₹${row.Damage_Repair_Charges}` : "N/A",
    },
  ];
  let order = [];
  let obj = {};

  /**
   * get damage list
   */
  useEffect(() => {
    vehicleDamageListResponse(page, tabId);
    // eslint-disable-next-line
  }, [page]);
  /**
   * call table list
   */
  useEffect(() => {
    if (list?.data?.data?.docs) list1();
    // eslint-disable-next-line
  }, [list?.data?.data?.docs]);

  /**
   * table list
   */

  const list1 = () => {
    list?.data?.data?.docs.map((item, index) => {
      obj = {
        id: item?._id ? item?._id : "",
        Customer_Name: item?.Customer_Name ? item?.Customer_Name : "",
        Rental_Start_Date: item?.Rental_Start_Date,
        Rental_End_Date: item?.Rental_End_Date,
        Damage_Cost_Received: item?.Damage_Cost_Received
          ? item?.Damage_Cost_Received
          : "",
        Damage_Repair_Charges: item?.Damage_Repair_Charges
          ? item?.Damage_Repair_Charges
          : "",
        vehicleId: item?.vehicleID ? item?.vehicleID : "",
      };
      return order.push(obj);
    });
    if (order.length) setListTable(order);
  };

  /**
   * oil list display
   * @param {*} page
   * @param {*} tabId
   */
  const vehicleDamageListResponse = (page, tabId) => {
    setLoader("loading");
    VehicleService.getAllDamageList(page, tabId).then((response) => {
      setList(response);
      setTotalPages(response?.data?.data?.totalPages);
      setPage(response?.data?.data?.page);
      setLoader("isfull");
    });
  };
  /**
   * handle page change
   * @param {*} e
   */
  const handleDamagePageClick = (e) => {
    setPage(e.selected + 1, tabId);
    vehicleDamageListResponse(e.selected + 1, tabId);
  };
  const handleCustName = (e) => {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setCustomerName(e.target.value);
    } else if (!e.target.value) {
      setCustomerName("");
    }
  };
  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    setCustomerName(row?.Customer_Name);
    setRentalStartDate(moment(row?.Rental_Start_Date).format("DD/MM/YYYY"));
    setDamageCostReceived(row?.Damage_Cost_Received);
    setRentalEndDate(moment(row?.Rental_End_Date).format("DD/MM/YYYY"));
    setDamageRepairCharges(row?.Damage_Repair_Charges);
    setDamageId(row?.id);
  };
  /**
   * handle change
   * @param {*} e
   */
  const handleDamageCostReceived = (e) => {
    setDamageCostReceived(e.target.value);
  };
  const handleRentalStartDate = (e) => {
    setRentalStartDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleDamageRepairCharges = (e) => {
    setDamageRepairCharges(e.target.value);
  };
  const handleRentalEndDate = (e) => {
    setRentalEndDate(moment(e).format("DD/MM/YYYY"));
  };


  /**
   * add damage
   * @param {*} e
   * @param {*} tabId
   */
  const handleDamage = (e, tabId) => {
    e.preventDefault();
    setSubmit(true);

    if (rentalStartDate > rentalEndDate) {
      dispatch(
        alertActions.error("End Date Should be Greater than Start Date")
      );
    } else if (tabId && customerName) {
      setLoader("loading");
      VehicleService.addDamageData(
        tabId,
        customerName,
        rentalStartDate,
        rentalEndDate,
        damageCostReceived,
        damageRepairCharges
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setSubmit(false);
            setCustomerName("");
            setRentalStartDate("");
            setRentalEndDate("");
            setDamageCostReceived("");
            setDamageRepairCharges("");
            vehicleDamageListResponse(page, tabId);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * edit damage
   * @param {*} e
   */
  const handleEditDamage = (e) => {
    e.preventDefault();
    setSubmit(true);

    if (rentalStartDate > rentalEndDate) {
      dispatch(
        alertActions.error("End Date Should be Greater than Start Date")
      );
    } else if (tabId && customerName) {
      setLoader("loading");

      VehicleService.editDamageData(
        damageId,
        tabId,
        customerName,
        rentalStartDate,
        rentalEndDate,
        damageCostReceived,
        damageRepairCharges
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setSubmit(false);
            setCustomerName("");
            setRentalStartDate("");
            setRentalEndDate("");
            setDamageCostReceived("");
            setDamageRepairCharges("");
            vehicleDamageListResponse(page, tabId);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return {
    loader,
    customerName,
    rentalStartDate,
    rentalEndDate,
    damageCostReceived,
    damageRepairCharges,
    checkRes,
    submit,
    id,
    page,
    totalPages,
    list,
    damageId,
    listTable,
    columns,
    setId,
    setCheckRes,
    handleRow,
    handleCustName,
    handleDamageCostReceived,
    handleRentalStartDate,
    handleDamageRepairCharges,
    handleRentalEndDate,
    handleDamage,
    handleEditDamage,
    handleDamagePageClick,
  };
};
export default UseDamages;
