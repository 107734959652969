import Menu from "../../_common/menu/Menu";
import TopBar from "../../_common/topbar/TopBar";
import { withTranslation } from "react-i18next";

const Help = (props) => {
  const { t } = props
  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      <div className="classOne flt">
        <div className="cooming_soon1">{t("COMING_SOON")}</div>
      </div>
    </div>
  );
};
export default withTranslation()(Help);
