import { useState, useEffect } from "react";
import { EndrentService } from "../../../../../../../services";

const useEndCheckoutHook = (displayData, bookid) => {
  const [KMLimit, setKMLimit] = useState("");
  const [odometer, setOdometer] = useState("");
  const [fuellevel, setFuellevel] = useState("");
  const [helmetqty, setHelmetqty] = useState("");
  const [submit, setSubmit] = useState(false);

  /**
   * get checkout details
   */
  useEffect(() => {
    EndrentService.getCheckoutDetails(bookid)
      .then((res) => {
        if (res?.data?.status?.code === 0) {
          setKMLimit(res?.data?.data?.KM_limit);
          setOdometer(res?.data?.data?.Odo_meter);
          setFuellevel(res?.data?.data?.Fuel_level);
          setHelmetqty(res?.data?.data?.Helmet_qty);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  /**
   * handle change
   * @param {*} e
   */
  const Changehandler = (e) => {
    if (e.target.name === "KMLimit") {
      setKMLimit(e.target.value);
    }
    if (e.target.name === "odometer") {
      setOdometer(e.target.value);
    }
    if (e.target.name === "fuellevel") {
      setFuellevel(e.target.value);
    }
    if (e.target.name === "helmetqty") {
      setHelmetqty(e.target.value);
    }
  };

  const navigateToRentalDetails = () => {
    displayData(0);
  };

  /**
   * checkout store
   */
  const checkOutStore = () => {
    setSubmit(true);
    if (KMLimit && odometer && fuellevel) {
      displayData(2);
    }
  };
  return {
    KMLimit,
    odometer,
    fuellevel,
    helmetqty,
    Changehandler,
    checkOutStore,
    navigateToRentalDetails,
  };
};
export default useEndCheckoutHook;
