import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../actions";
import { SetUpService } from "../../../../services";

const useGeneralHook = () => {
  //Deafult Pick & Drop Location
  const [checkInspection, setCheckInspection] = useState(false);
  const [checkNextOil, setCheckNextOil] = useState(false);
  const [checkNextPuc, setCheckNextPuc] = useState(false);
  const [checkNextRc, setCheckNextRc] = useState(false);
  const [checkNextFitness, setCheckNextFitness] = useState(false);
  const [checkNexPermit, setCheckNexPermit] = useState(false);
  const [checkNextInsurance, setCheckNextInsurance] = useState(false);

  const [inspection, setInspection] = useState("");
  const [nextOil, setNextOil] = useState("");
  const [nextPuc, setNextPuc] = useState("");
  const [nextRc, setNextRc] = useState("");
  const [nextFitness, setNextFitness] = useState("");
  const [nextPermit, setNextPermit] = useState("");
  const [nextInsurance, setNextInsurance] = useState("");

  const [id, setId] = useState("");
  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState("notset");
  const [gstRate, setGstRate] = useState();
  const dispatch = useDispatch();


  /**
   * get general info
   */
  useEffect(() => {
    getGeneralInfo();
    // eslint-disable-next-line
  }, [id !== ""]);

  /**
   * get general details
   */
  function getGeneralInfo() {
    SetUpService.getGeneralInfo()
      .then((response) => {
        if (response?.data?.data?.docs.length !== 0) {
          setId(response?.data?.data?.docs?.[0]?._id ? response?.data?.data?.docs?.[0]?._id : "");

          setInspection(
            response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextMaintananceDate?.daysInAdvance ? response?.data?.data?.docs?.[0]?.vehicleReminders
                ?.NextMaintananceDate?.daysInAdvance : ""
          );
          setCheckInspection(
            response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextMaintananceDate?.show ? response?.data?.data?.docs?.[0]?.vehicleReminders
                ?.NextMaintananceDate?.show : ""
          );
          setCheckNextOil(response?.data?.data?.docs?.[0]?.vehicleReminders
            ?.NextOilChangeDate?.show ? response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextOilChangeDate?.show : "");
          setCheckNextPuc(response?.data?.data?.docs?.[0]?.vehicleReminders
            ?.NextPucDate?.show ? response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextPucDate?.show : "");
          setCheckNextRc(response?.data?.data?.docs?.[0]?.vehicleReminders
            ?.NextRCDate?.show ? response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextRCDate?.show : "");
          setCheckNextFitness(response?.data?.data?.docs?.[0]?.vehicleReminders
            ?.NextFitnessDate?.show ? response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextFitnessDate?.show : "");
          setCheckNexPermit(response?.data?.data?.docs?.[0]?.vehicleReminders
            ?.NextPermitDate?.show ? response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextPermitDate?.show : "");
          setCheckNextInsurance(response?.data?.data?.docs?.[0]?.vehicleReminders
            ?.NextInsuranceDate?.show ? response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextInsuranceDate?.show : "");
          setGstRate(
            response?.data?.data?.docs?.[0]?.RegionalSettings?.gstRate ? response?.data?.data?.docs?.[0]?.RegionalSettings?.gstRate : 0
          );
          setNextOil(
            response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextOilChangeDate?.daysInAdvance ? response?.data?.data?.docs?.[0]?.vehicleReminders
                ?.NextOilChangeDate?.daysInAdvance : ""
          );
          setNextPuc(
            response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextPucDate?.daysInAdvance ? response?.data?.data?.docs?.[0]?.vehicleReminders
                ?.NextPucDate?.daysInAdvance : ""
          );
          setNextRc(
            response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextRCDate?.daysInAdvance ? response?.data?.data?.docs?.[0]?.vehicleReminders
                ?.NextRCDate?.daysInAdvance : ""
          );
          setNextFitness(
            response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextFitnessDate?.daysInAdvance ? response?.data?.data?.docs?.[0]?.vehicleReminders
                ?.NextFitnessDate?.daysInAdvance : ""
          );
          setNextPermit(
            response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextPermitDate?.daysInAdvance ? response?.data?.data?.docs?.[0]?.vehicleReminders
                ?.NextPermitDate?.daysInAdvance : ""
          );
          setNextInsurance(
            response?.data?.data?.docs?.[0]?.vehicleReminders
              ?.NextInsuranceDate?.daysInAdvance ? response?.data?.data?.docs?.[0]?.vehicleReminders
                ?.NextInsuranceDate?.daysInAdvance : ""
          );
        }
      })
      .catch((err) => console.log(err));
  }



  /**
   * handle checkbox
   * @param {*} e 
   */
  function changeCheckInspectionHandler(e) {
    setCheckInspection(!checkInspection);
  }
  function changeCheckOilChangeDateHandler(e) {
    setCheckNextOil(!checkNextOil);
  }
  function changeCheckNextPucDateHandler(e) {
    setCheckNextPuc(!checkNextPuc);
  }
  function changeCheckNextRcDateHandler(e) {
    setCheckNextRc(!checkNextRc);
  }
  function changeCheckNextFitnessDateHandler(e) {
    setCheckNextFitness(!checkNextFitness);
  }
  function changeCheckPermitDateHandler(e) {
    setCheckNexPermit(!checkNexPermit);
  }
  function changeCheckInsuranceDateHandler(e) {
    setCheckNextInsurance(!checkNextInsurance);
  }



  /**
   * handle input box
   * @param {*} e 
   */
  function handleInspectionDate(e) {
    setInspection(e.target.value);
  }
  function handleOilChangeDate(e) {
    setNextOil(e.target.value);
  }
  function handleNextPucDate(e) {
    setNextPuc(e.target.value);
  }
  function handleNextRcDate(e) {
    setNextRc(e.target.value);
  }
  function handleNextFitnessDate(e) {
    setNextFitness(e.target.value);
  }
  function handleNextPermitDate(e) {
    setNextPermit(e.target.value);
  }
  function handleNextInsuranceDate(e) {
    setNextInsurance(e.target.value);
  }


  function handleGstRate(e) {

    setGstRate(e.target.value);
  }

  /**
   * add investor api call
   * @param {*} e 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (gstRate) {
      setLoader("loading");
      SetUpService.addGeneralInfo(
        id,
        checkInspection,
        inspection,
        checkNextOil,
        nextOil,
        checkNextPuc,
        nextPuc,
        checkNextRc,
        nextRc,
        checkNextFitness,
        nextFitness,
        checkNexPermit,
        nextPermit,
        checkNextInsurance,
        nextInsurance,
        gstRate
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setSubmit(false);
            getGeneralInfo();
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return {
    loader,
    inspection,
    checkInspection,
    gstRate,
    submit,
    checkNextOil,
    nextOil,
    checkNextPuc,
    nextPuc,
    checkNextRc,
    nextRc,
    checkNextFitness,
    nextFitness,
    checkNexPermit,
    nextPermit,
    checkNextInsurance,
    nextInsurance,
    handleGstRate,
    changeCheckInspectionHandler,
    handleInspectionDate,
    handleSubmit,
    changeCheckOilChangeDateHandler,
    changeCheckNextPucDateHandler,
    changeCheckNextRcDateHandler,
    changeCheckNextFitnessDateHandler,
    changeCheckPermitDateHandler,
    changeCheckInsuranceDateHandler,
    handleOilChangeDate,
    handleNextPucDate,
    handleNextRcDate,
    handleNextFitnessDate,
    handleNextPermitDate,
    handleNextInsuranceDate
  };
};
export default useGeneralHook;
