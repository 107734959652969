import * as axios from "../network/Network";

const config = require("../network/api.json");

class UserService {
  /**
   * Login
   * @param {*} userName 
   * @param {*} password 
   * @returns 
   */
  static login(userName, password) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.login,
      data: { userName, password },
    });
  }
}
export { UserService };
