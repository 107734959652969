import { config } from "../../../assets/images";
import { Link } from "react-router-dom";
import "./LeftSidebar.css";
import { useLocation } from "react-router-dom";

/* chat left start */

function LeftSidebar() {
  const location = useLocation();

  return (
    <div className="chat_left flt">
      <div className="configration flt">
        <img src={config} alt="config" />
        Configuration
      </div>

      <ul className="company flt">
        <li
          className={
            location.pathname === "/setup-useraccess" ? "companyActive" : ""
          }
        >
          <Link to="/setup-useraccess">User Access</Link>
        </li>
        <li
          className={
            location.pathname === "/setup-company" ||
            location.pathname === "/setup-editcompany"
              ? "companyActive"
              : ""
          }
        >
          <Link to="/setup-company">Company Details</Link>
        </li>
        <li
          className={
            location.pathname === "/setup-location" ||
            location.pathname === "/setup-locationdetails"
              ? "companyActive"
              : ""
          }
        >
          <Link to="/setup-location">Location Details</Link>
        </li>

        <li
          className={
            location.pathname === "/setup-general-option" ? "companyActive" : ""
          }
        >
          <Link to="/setup-general-option">General Option</Link>
        </li>
        <li
          className={
            location.pathname === "/setup-vehicle" ? "companyActive" : ""
          }
        >
          <Link to="/setup-vehicle">Vehicle Option</Link>
        </li>
        <li
          className={
            location.pathname === "/setup-payment-type" ? "companyActive" : ""
          }
        >
          <Link to="/setup-payment-type">Payment Type</Link>
        </li>
        <li
          className={
            location.pathname === "/setup-vehicle-expense"
              ? "companyActive"
              : ""
          }
        >
          <Link to="/setup-vehicle-expense">Vehicle Expense</Link>
        </li>

        {/* <li>
          <Link to="/">Tax&Charges</Link>
        </li>

        <li>
          <Link to="/">Agreement/Invoice</Link>
        </li>

        <li>
          <Link to="/"> Online&Data Settings</Link>
        </li>

        <li>
          <Link to="/">Optional Agreement Fields</Link>
        </li>

        <li>
          <Link to="/rentaldata">Rental Rates Options</Link>
        </li>

        <li>
          <Link to="/">Payment&Deposit</Link>
        </li>

        <li>
          <Link to="/userAccess">User Access</Link>
        </li>

        <li>
          <Link to="/">Planner Options</Link>
        </li>

        <li>
          <Link to="/"> Email Options</Link>
        </li>

        <li>
          <Link to="/"> QuickBooks Integration</Link>
        </li>

        <li>
          <Link to="/"> Other Options</Link>
        </li>

        <li>
          <Link to="/"> Customer Categories</Link>
        </li>

        <li>
          <Link to="/"> Vehicle Expenses </Link>
        </li> */}
      </ul>
    </div>
  );
}
export default LeftSidebar;
