import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SetUpService } from "../../../../../services";

const useCompanyListHook = () => {
  const [ownerName, setOwnerName] = useState("");
  const [vatId, setVatId] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [webPage, setWebPage] = useState("");
  const [address, setAddress] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [states, setStates] = useState("");
  const [id, setId] = useState("");
  const [loader, setLoader] = useState("notset");
  const ownerNameRef = useRef("");
  const vatIdRef = useRef("");
  const cityRef = useRef("udupi");
  const countryRef = useRef("India");
  const webPageRef = useRef("");
  const addressRef = useRef("");
  const faxRef = useRef("");
  const emailRef = useRef("");
  const addressTwoRef = useRef("");
  const zipRef = useRef("");
  const phoneRef = useRef("");
  const bankAccountRef = useRef("");
  const companyNameRef = useRef("");
  const statesRef = useRef("Karnataka");

  const navigate = useNavigate();
  /**
   * get comapny details response
   */
  useEffect(() => {
    displayCompanyDetails();
  }, []);

  /**
   * get company details
   */
  const displayCompanyDetails = () => {
    setLoader("loading");
    SetUpService.getCompanyDetails()
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setOwnerName(response?.data?.data?.[0]?.ownerName ? response?.data?.data?.[0]?.ownerName : "");
          setVatId(response?.data?.data?.[0]?.vat_tax_id_number ? response?.data?.data?.[0]?.vat_tax_id_number : "");
          setCity(response?.data?.data?.[0]?.city ? response?.data?.data?.[0]?.city : "");
          setCountry(response?.data?.data?.[0]?.country ? response?.data?.data?.[0]?.country : "");
          setWebPage(response?.data?.data?.[0]?.webPage ? response?.data?.data?.[0]?.webPage : "");
          setAddress(response?.data?.data?.[0]?.address ? response?.data?.data?.[0]?.address : "");
          setFax(response?.data?.data?.[0]?.faxNumber ? response?.data?.data?.[0]?.faxNumber : "");
          setEmail(response?.data?.data?.[0]?.email ? response?.data?.data?.[0]?.email : "");
          setAddressTwo(response?.data?.data?.[0]?.address2 ? response?.data?.data?.[0]?.address2 : "");
          setZip(response?.data?.data?.[0]?.zip_post_code ? response?.data?.data?.[0]?.zip_post_code : "");
          setPhone(response?.data?.data?.[0]?.phoneNumber ? response?.data?.data?.[0]?.phoneNumber : "");
          setBankAccount(response?.data?.data?.[0]?.bankAccount ? response?.data?.data?.[0]?.bankAccount : "");
          setCompanyName(response?.data?.data?.[0]?.companyName ? response?.data?.data?.[0]?.companyName : "");
          setStates(response?.data?.data?.[0]?.state ? response?.data?.data?.[0]?.state : "");
          setId(response?.data?.data?.[0]?._id ? response?.data?.data?.[0]?._id : "");
          setLoader("isfull");
        }
      })
      .catch((err) => console.log(err));

  };

  /**
   * navigation
   */
  const navigateToEditCompanyDetails = () => {
    navigate("/setup-companyDetails");
  };

  return {
    id,
    ownerName,
    vatId,
    city,
    country,
    webPage,
    address,
    fax,
    email,
    addressTwo,
    zip,
    phone,
    bankAccount,
    companyName,
    states,
    ownerNameRef,
    vatIdRef,
    cityRef,
    countryRef,
    webPageRef,
    addressRef,
    zipRef,
    phoneRef,
    bankAccountRef,
    companyNameRef,
    faxRef,
    addressTwoRef,
    emailRef,
    statesRef,
    loader,
    navigateToEditCompanyDetails,
  };
};

export default useCompanyListHook;
