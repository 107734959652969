import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Menu from "../../../_common/menu/Menu";
import TopBar from "../../../_common/topbar/TopBar";
import useVehicleList from "./useVehicleListHook";
import { withTranslation } from "react-i18next";
import { vehicle } from "../../../../assets/images";
import { Loading } from "../../../../assets/images";

const VehicleSoldList = (props) => {
  const { t } = props;
  const {
    listData,
    totalPages,
    search,
    page,
    loader,
    handlePageClick,
    changeItemHandler,
    changeSearchHandler,
    handleRow,
  } = useVehicleList();

  const columns = [
    {
      name: "Stock Number",
      selector: (row) => row.stockNumber,
    },
    {
      name: "Reg Number",
      selector: (row) => row.regNumber,
    },

    {
      name: "Make",
      selector: (row) => row.make,
    },
    {
      name: "Model",
      selector: (row) => row.model,
    },
    {
      name: "Year",
      selector: (row) => row.year,
    },
    {
      name: "ODO Meter No",
      selector: (row) => row.ODOMeterNo,
    },
    {
      name: "Color",
      selector: (row) => row.color,
    },

    {
      name: "Vehicle Class",
      selector: (row) => row.vehicleClass,
    },

    {
      name: "Transmission",
      selector: (row) => row.transmission,
    },
    // {
    //   name: "Date Sold",
    //   selector: (row) => row.dateSold,
    // },
    {
      name: "Location",
      selector: (row) => row.location,
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={row.vehicle_status === "sold" ? "button_style_sold" : ""}
        >
          {row.vehicle_status === "sold" ? "Sold" : ""}
        </div>
      ),
    },
  ];

  const [listTable, setListTable] = useState([]);
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  let order = [];
  let obj = {};

  const list = () => {
    listData.data.data.docs.map((item, index) => {
      obj = {
        id: item._id,
        stockNumber: item.stockNumber,
        regNumber: item.regNumber,
        make: item?.make?.[0]?.make,
        makeId: item?.make?.[0]?._id,
        model: item?.model?.[0]?.model,
        modelId: item?.model?.[0]?._id,
        year: item.year,
        color: item.color,
        vehicleClass: item?.vehicleClass?.[0]?.vehicleClass,
        vehicleClassId: item?.vehicleClass?.[0]?._id,
        transmission: item.transmission,
        dateSold: item.dateSold,
        location: item.location?.[0].locationName,
        ODOMeterNo: item.ODOMeterNo,
        availabilty: item.availabilty,
        basis: item.basis,
        chasisNumber: item.chasisNumber,
        fuelTypeCapacity: item.fuelTypeCapacity,
        investorName: item.investorName,
        // location: item.location,
        purchasePrice: item.purchasePrice,
        salePrice: item.salePrice,
        startDate: item.startDate,
        vehicle_status: item.vehicle_status,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  return (
    <div className="header_location flt">
      <div className="main_container flt">
        <TopBar />
        <Menu />

        <div className="main_location flt">
          <div className="location flt">
            {" "}
            <img src={vehicle} alt="vehicle" />
            {t("Vehicle Sold Lists")}
          </div>

          <div className="main_search_b1 flt">
            <div className="main_search_dd1 ">
              <div className="search_l ">
                <select name="item" id="item" onChange={changeItemHandler}>
                  <option default value="stockNum">
                    {t("Search by Stock No.")}
                  </option>
                  <option value="regNum">{t("Search by Registration No.")}</option>
                </select>
              </div>
              <div className="search_b1 ">
                <input
                  type="text"
                  placeholder="Search.."
                  name="search"
                  value={search}
                  onChange={changeSearchHandler}
                />
                <button className="search_button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="main_load ">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  {listData?.data?.data?.docs?.length !== 0 ? (
                    <DataTable
                      columns={columns}
                      data={listTable || []}
                      customStyles={customStyles}
                      onRowClicked={(row) => handleRow(row)}
                    />
                  ) : (
                    <div className="no-data">{t("No Data Found")}</div>
                  )}
                </table>
              )}
              {listData?.data?.data?.docs.length !== 0 && (
                <div>
                  <ReactPaginate
                    nextLabel="&rarr;"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={totalPages}
                    previousLabel="&larr;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    currentPage={page}
                    forcePage={page - 1}
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withTranslation() (VehicleSoldList);
