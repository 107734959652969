import { useState, useEffect } from "react";
import { ExchangeService } from "../../../../../../../services";

const useExchangeSummaryHook = (setTab, tab, bookid) => {
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [listTable, setListTable] = useState([]);
  const [loader, setLoader] = useState("notset");

  const columns = [
    {
      name: "Sr.No.",
      selector: (row) => row.srnumber,
      width: "80px"
    },
    {
      name: "Old Vehicle Details",
      selector: (row) => row.old_vehicle_Details,
      width: "300px"
    },
    {
      name: "ODO Meter In",
      selector: (row) => row.odo_meter_in,
      width: "150px"
    },
    {
      name: "Fuel In",
      selector: (row) => row.fuel_in,
      width: "150px"
    },
    {
      name: "New Vehicle Details",
      selector: (row) => row.new_vehicle_details,
      width: "300px"
    },
    {
      name: "ODO Meter Out",
      selector: (row) => row.odo_meter_out,
      width: "200px"
    },
    {
      name: "Fuel Out",
      selector: (row) => row.fuel_out,
      width: "150px"
    },
    {
      name: "Exchange Date",
      selector: (row) => row.exchange_date,
      width: "200px"
    },
  ];
  let order = [];
  let obj = {};

  /**
   * list call
   */
  useEffect(() => {
    bookingResponse(page, bookid);
    // eslint-disable-next-line
  }, [page]);

  /**
   * table list call
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * table list
   */
  const list = () => {
    listData.data.data.docs.map((item, index) => {
      obj = {
        srnumber:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        old_vehicle_Details:
          item?.old_vehicle_class +
          " " +
          item?.old_vehicle_make +
          " " +
          item?.old_vehicle_model +
          " " +
          item?.old_vehicle_regnumber,
        odo_meter_in: item?.old_vehicle_odometer,
        fuel_in: item?.old_vehicle_fuel,
        new_vehicle_details:
          item?.new_vehicle_class +
          " " +
          item?.new_vehicle_make +
          " " +
          item?.new_vehicle_model +
          " " +
          item?.new_vehicle_regnumber,
        odo_meter_out: item?.new_vehicle_odometer,
        fuel_out: item?.new_vehicle_fuel,
        exchange_date: item?.exchange_date,

        id: item?._id,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * get summary details
   * @param {*} page
   * @param {*} bookid
   */
  const bookingResponse = (page, bookid) => {
    setLoader("loading");
    ExchangeService.getExchangeSummaryDetails(page, bookid).then((response) => {
      if (response?.data?.status?.code === 0) {
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response.data.data.page);
        setLoader("isfull");
      }
    }).catch((err) => console.log(err));;
  };

  /**
   * page click
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    bookingResponse(e.selected + 1, bookid);
  };

  return {
    listData,
    totalPages,
    page,
    columns,
    listTable,
    loader,
    handlePageClick,
  };
};
export default useExchangeSummaryHook;
