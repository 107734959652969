import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../../actions";
import { VehicleService } from "../../../../../services";
import moment from "moment";

const useMaintanance = (location, tab, tabId) => {
  const [id, setId] = useState(
    location?.state?.row?.id ? location?.state?.row?.id : tabId
  );
  const [submit, setSubmit] = useState(false);
  const [submitMaint, setSubmitMaint] = useState(false);

  const [checkRes, setCheckRes] = useState(false);

  const [kmsAtLastService, setKmsAtLastService] = useState("");
  const [serviceIntervalKm, setServiceIntervalKm] = useState("");
  const [maintananceLastServiceDate, setMaintananceLastServiceDate] =
    useState("");
  const [maintananceNextServiceDate, setMaintananceNextServiceDate] =
    useState("");
  const [oilServiceDate, setOilServiceDate] = useState("");
  const [amount, setAmount] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [description, setDescription] = useState("");
  const [kmReading, setKmReading] = useState("");
  const [oilServiceNextServiceDate, setOilServiceNextServiceDate] =
    useState("");
  const [garage, setGarage] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [maintainenceExpenseDate, setMaintainenceExpenseDate] = useState("");
  const [maintainenceExpenseInvoice, setMaintainenceExpenseInvoice] =
    useState("");
  const [maintainenceExpenseDescription, setMaintainenceExpenseDescription] =
    useState("");
  const [maintainenceExpenseKmReading, setMaintainenceExpenseKmReading] =
    useState("");
  const [maintainenceExpenseGarage, setMaintainenceExpenseGarage] =
    useState("");
  const [
    maintainenceExpensePaymentMethod,
    setMaintainenceExpensePaymentMethod,
  ] = useState("");
  const [maintainenceExpenseServiceType, setMaintainenceExpenseServiceType] =
    useState("");
  const [maintainenceExpenseAmount, setMaintainenceExpenseAmount] =
    useState("");
  const [loader, setLoader] = useState("notset");

  const [oilListData, setOilListData] = useState([]);
  let [page1, setPage1] = useState(1);
  const [totalPages1, setTotalPages1] = useState(0);
  const [oilExpenseId, setOilExpenseId] = useState("");

  const [maintainanceListData, setMaintainanceListData] = useState([]);
  let [page2, setPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(0);
  const [maintExpenseId, setMaintExpenseId] = useState("");

  const [paymentTypeArray, setPaymentTypeArray] = useState([]);
  const [vehicleExpenseArray, setVehicleExpenseArray] = useState([]);
  const [listTable1, setListTable1] = useState([]);
  const [listTable2, setListTable2] = useState([]);
  const dispatch = useDispatch();
  const columns = [
    {
      name: "Invoice No",
      selector: (row) => row.invoice_no,
      width: "180px"
    },
    {
      name: "Oil Service Date",
      selector: (row) =>
        row?.oil_service_date !== null
          ? moment(row?.oil_service_date).format("DD/MM/YYYY")
          : "N/A",
      width: "180px"
    },
    {
      name: "Next Service Date",
      selector: (row) =>
        row?.next_service_date !== null
          ? moment(row?.next_service_date).format("DD/MM/YYYY")
          : "N/A",
      width: "180px"
    },
    {
      name: "KM Reading",
      selector: (row) => (row?.km_reading ? row?.km_reading : "N/A"),
      width: "180px"
    },
    {
      name: "Amount",
      selector: (row) => (row?.amount !== null ? `₹${row?.amount}` : "N/A"),
      width: "180px"
    },
    {
      name: "Description",
      selector: (row) => (row?.Description ? row?.Description : "N/A"),
      width: "180px"
    },
    {
      name: "Delete",
      selector: (row) =>
      (
        <button className="viewdata" onClick={() => { onDelete(row?.id) }}>
          Delete
        </button>
      ),
      width: "150px",
    },
  ];
  const columns1 = [
    {
      name: "Invoice No",
      selector: (row) => (row.invoice_no ? row.invoice_no : "N/A"),
      width: "180px"
    },
    {
      name: "KM Reading",
      selector: (row) => (row.km_reading ? row.km_reading : "N/A"),
      width: "180px"
    },
    {
      name: "Date",
      selector: (row) =>
        row?.date !== "" ? moment(row?.date).format("DD/MM/YYYY") : "N/A",
      width: "180px"
    },

    {
      name: "Amount",
      selector: (row) => (row.amount ? `₹${row.amount}` : "N/A"),
      width: "180px"
    },
    {
      name: "Description",
      selector: (row) => (row.Description ? row.Description : "N/A"),
      width: "180px"
    },
    {
      name: "Service Type",
      selector: (row) => (row.service_type ? row.service_type : "N/A"),
      width: "180px"
    },
    {
      name: "Delete",
      selector: (row) =>
      (
        <button className="viewdata" onClick={() => { onDeleteMaintainance(row?.id) }}>
          Delete
        </button>
      ),
      width: "150px",
    },
  ];
  let order = [];
  let obj = {};
  let order1 = [];
  let obj1 = {};

  /**
   * get payment type and expense type
   */
  useEffect(() => {
    VehicleService.getPaymentTypeName()
      .then((response) => {
        setPaymentTypeArray(response?.data?.data);
      })
      .catch((err) => console.log(err));
    VehicleService.getVehicleExpenseName()
      .then((response) => {
        setVehicleExpenseArray(response?.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   * refresh page after delete
   */
  // useEffect(() => {
  //   console.log("update")
  // }, [deleteData])

  /**
   * get oil expense response
   */
  useEffect(() => {
    vehicleOilExpenseResponse(page1, tabId);
    // eslint-disable-next-line
  }, [page1]);

  /**
   * get maint. expense response
   */
  useEffect(() => {
    vehicleMaintainanceExpenseResponse(page2, tabId);
    // eslint-disable-next-line
  }, [page2]);

  /**
   * get maint. info
   */
  useEffect(() => {
    getSingleMaintananceInfo();
    // eslint-disable-next-line
  }, [tab === 3, id !== ""]);

  /**
   * get oil table list
   */
  useEffect(() => {
    if (oilListData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [oilListData?.data?.data?.docs]);

  /**
   * get maint. table list
   */
  useEffect(() => {
    if (maintainanceListData?.data?.data?.docs) list1();
    // eslint-disable-next-line
  }, [maintainanceListData?.data?.data?.docs]);


  /**
   * handle change
   * @param {*} e
   */
  const handleKmsAtLastService = (e) => {
    setKmsAtLastService(e.target.value);
  };
  const handleServiceIntervalKm = (e) => {
    setServiceIntervalKm(e.target.value);
  };

  const handleMaintananceLastServiceDate = (e) => {
    setMaintananceLastServiceDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleMaintananceNextServiceDate = (e) => {
    setMaintananceNextServiceDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleOilServiceDate = (e) => {
    setOilServiceDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleOilAmount = (e) => {
    setAmount(e.target.value);
  };
  const handleOilInvoiceNumber = (e) => {
    setInvoiceNumber(e.target.value);
  };
  const handleOilDescription = (e) => {
    setDescription(e.target.value);
  };
  const handleOilKmReading = (e) => {
    setKmReading(e.target.value);
  };
  const handleOilServiceNextServiceDate = (e) => {
    setOilServiceNextServiceDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleOilGarage = (e) => {
    setGarage(e.target.value);
  };
  const handleOilPayment = (e) => {
    setPaymentMethod(e.target.value);
  };
  const handleMaintainenceExpenseDate = (e) => {
    setMaintainenceExpenseDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleMaintainenceExpenseInvoice = (e) => {
    setMaintainenceExpenseInvoice(e.target.value);
  };
  const handleMaintainenceExpenseDescription = (e) => {
    setMaintainenceExpenseDescription(e.target.value);
  };
  const handleMaintainenceExpenseKmReading = (e) => {
    setMaintainenceExpenseKmReading(e.target.value);
  };
  const handleMaintainenceExpensePaymentMethod = (e) => {
    setMaintainenceExpensePaymentMethod(e.target.value);
  };
  const handleMaintainenceExpenseGarage = (e) => {
    setMaintainenceExpenseGarage(e.target.value);
  };
  const handleMaintainenceExpenseServiceType = (e) => {
    setMaintainenceExpenseServiceType(e.target.value);
  };
  const handleMaintainanceAmount = (e) => {
    setMaintainenceExpenseAmount(e.target.value);
  };

  /**
   * oil list
   */
  const list = () => {
    oilListData?.data?.data?.docs.map((item, index) => {
      obj = {
        id: item?._id,
        oil_service_date: item?.Oil_service_expense?.Oil_service_Date,
        next_service_date:
          item?.Oil_service_expense?.Oil_service_Next_Service_Date,
        amount: item?.Oil_service_expense?.Amount,
        km_reading: item?.Oil_service_expense?.Km_Reading,
        invoice_no: item?.Oil_service_expense?.InvoiceNumber,
        garage: item?.Oil_service_expense?.Garage,
        Description: item?.Oil_service_expense?.Description,
        Payment_Method: item?.Oil_service_expense?.Payment_Method,
      };
      return order.push(obj);
    });
    if (order.length) setListTable1(order);
  };
  /**
   * maint. list
   */
  const list1 = () => {
    maintainanceListData?.data?.data?.docs.map((item, index) => {
      obj1 = {
        id: item?._id,
        date: item?.Maintainence_Expense?.Maintainence_Expense_Date,
        amount: item?.Maintainence_Expense?.Maintainence_Expense_Amount,
        km_reading: item?.Maintainence_Expense?.Maintainence_Expense_Km_Reading,
        invoice_no: item?.Maintainence_Expense?.Maintainence_Expense_Invoice,
        garage: item?.Maintainence_Expense?.Maintainence_Expense_Garage,
        service_type:
          item?.Maintainence_Expense?.Maintainence_Expense_Service_Type,
        Description:
          item?.Maintainence_Expense?.Maintainence_Expense_Description,
        Payment_Method:
          item?.Maintainence_Expense?.Maintainence_Expense_Payment_Method,
      };
      return order1.push(obj1);
    });
    if (order1.length) setListTable2(order1);
  };

  /**
   * oil list display
   * @param {*} page1
   * @param {*} tabId
   */
  const vehicleOilExpenseResponse = (page1, tabId) => {
    VehicleService.getAllOilExpense(page1, tabId).then((response) => {
      setOilListData(response);
      setTotalPages1(response?.data?.data?.totalPages);
      setPage1(response?.data?.data?.page);
    });
  };

  /**
   * handle page oil change
   * @param {*} e
   */
  const handleOilExpensePageClick = (e) => {
    setPage1(e.selected + 1, tabId);
    vehicleOilExpenseResponse(e.selected + 1, tabId);
  };

  /**
   * maintainance list display
   * @param {*} page2
   * @param {*} tabId
   */
  const vehicleMaintainanceExpenseResponse = (page2, tabId) => {
    VehicleService.getAllMaintainanaceExpense(page2, tabId).then((response) => {
      setMaintainanceListData(response);
      setTotalPages2(response?.data?.data?.totalPages);
      setPage2(response?.data?.data?.page);
    });
  };

  /**
   * handle maint. page change
   * @param {*} e
   */
  const handleMaintainanceExpensePageClick = (e) => {
    setPage2(e.selected + 1, tabId);
    vehicleMaintainanceExpenseResponse(e.selected + 1, tabId);
  };

  /**
   * get maint.response
   */
  const getSingleMaintananceInfo = () => {
    setLoader("loading");
    VehicleService.getSingleVehicleMaintainance(tabId)
      .then((response) => {
        if (response.data.status.code === 0) {
          setCheckRes(true);
        }
        setKmsAtLastService(
          response?.data?.data?.maintanance?.kms_at_last_service
        );
        setServiceIntervalKm(
          response?.data?.data?.maintanance?.Service_Interval_Km
        );

        setMaintananceLastServiceDate(
          response?.data?.data?.maintanance?.maintanance_Last_Service_Date
            ? moment(
              response?.data?.data?.maintanance?.maintanance_Last_Service_Date
            ).format("DD/MM/YYYY")
            : ""
        );
        setMaintananceNextServiceDate(
          response?.data?.data?.maintanance?.maintanance_Next_Service_Date
            ? moment(
              response?.data?.data?.maintanance?.maintanance_Next_Service_Date
            ).format("DD/MM/YYYY")
            : ""
        );
        setLoader("isfull");
      })
      .catch((err) => console.log(err));
  };

  /**
   * fetch ol expense
   * @param {*} row
   */
  const handleRowOilExpense = (row) => {
    setOilExpenseId(row?.id);

    setOilServiceDate(
      row?.oil_service_date !== null
        ? moment(row?.oil_service_date).format("DD/MM/YYYY")
        : ""
    );
    setAmount(row?.amount !== null ? row?.amount : "");
    setInvoiceNumber(row?.invoice_no);
    setDescription(row?.Description);
    setGarage(row?.garage);
    setKmReading(row?.km_reading);
    setOilServiceNextServiceDate(
      row?.next_service_date !== null
        ? moment(row?.next_service_date).format("DD/MM/YYYY")
        : ""
    );
    setPaymentMethod(row?.Payment_Method);
  };

  /**
   * maint. expense fetch
   * @param {*} row
   */
  const handleRowMaintainance = (row) => {
    setMaintainenceExpenseDate(
      row?.date !== null ? moment(row?.date).format("DD/MM/YYYY") : ""
    );
    setMaintainenceExpenseInvoice(row?.invoice_no);
    setMaintainenceExpenseServiceType(row?.service_type);
    setMaintainenceExpenseAmount(row?.amount !== "" ? row?.amount : "");
    setMaintainenceExpenseGarage(row?.garage);
    setMaintainenceExpenseDescription(row?.Description);
    setMaintainenceExpensePaymentMethod(row?.Payment_Method);
    setMaintainenceExpenseKmReading(row?.km_reading);
    setMaintExpenseId(row?.id);
  };

  /**
   * add maint.
   * @param {*} e
   * @param {*} tabId
   */
  const handleMaintainance = (e, tabId) => {
    e.preventDefault();
    setSubmit(true);
    if (tabId) {
      setLoader("loading");
      VehicleService.addMaintenanceData(
        tabId,
        kmsAtLastService,
        serviceIntervalKm,
        maintananceLastServiceDate,
        maintananceNextServiceDate
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setSubmit(false);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * add maint. expense
   * @param {*} e
   * @param {*} tabId
   */
  const handleMaintainanceExpense = (e, tabId) => {
    e.preventDefault();

    setSubmitMaint(true);
    if (tabId && maintainenceExpenseDate) {
      setLoader("loading");
      VehicleService.addMaintenanceExpense(
        tabId,
        maintainenceExpenseDate,
        maintainenceExpenseInvoice,
        maintainenceExpenseDescription,
        maintainenceExpenseKmReading,
        maintainenceExpenseGarage,
        maintainenceExpensePaymentMethod,
        maintainenceExpenseServiceType,
        maintainenceExpenseAmount
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setMaintainenceExpenseDate("");
            setMaintainenceExpenseInvoice("");
            setMaintainenceExpenseDescription("");
            setMaintainenceExpenseKmReading("");
            setMaintainenceExpenseGarage("");
            setMaintainenceExpensePaymentMethod("");
            setMaintainenceExpenseServiceType("");
            setMaintainenceExpenseAmount("");
            vehicleMaintainanceExpenseResponse(page2, tabId);
            setSubmitMaint(false);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * edit maintainance expense
   * @param {*} e
   */
  const handleEditMaintainanceExpense = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (maintExpenseId && maintainenceExpenseDate) {
      setLoader("loading");
      VehicleService.editMaintenanceExpense(
        maintExpenseId,
        tabId,
        maintainenceExpenseDate,
        maintainenceExpenseInvoice,
        maintainenceExpenseDescription,
        maintainenceExpenseKmReading,
        maintainenceExpenseGarage,
        maintainenceExpensePaymentMethod,
        maintainenceExpenseServiceType,
        maintainenceExpenseAmount
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setMaintainenceExpenseDate("");
            setMaintainenceExpenseInvoice("");
            setMaintainenceExpenseDescription("");
            setMaintainenceExpenseKmReading("");
            setMaintainenceExpenseGarage("");
            setMaintainenceExpensePaymentMethod("");
            setMaintainenceExpenseServiceType("");
            setMaintainenceExpenseAmount("");
            vehicleMaintainanceExpenseResponse(page2, tabId);
            setSubmit(false);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * add oil maint.Expense
   * @param {*} e
   * @param {*} tabId
   */
  const handleOilMaintainance = (e, tabId) => {
    e.preventDefault();
    setSubmit(true);
    if (tabId && oilServiceDate) {
      setLoader("loading");
      VehicleService.addOilMaintenanceData(
        tabId,
        oilServiceDate,
        amount,
        invoiceNumber,
        description,
        kmReading,
        oilServiceNextServiceDate,
        garage,
        paymentMethod
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setOilServiceDate("");
            setAmount("");
            setInvoiceNumber("");
            setDescription("");
            setKmReading("");
            setOilServiceNextServiceDate("");
            setGarage("");
            setPaymentMethod("");
            vehicleOilExpenseResponse(page1, tabId);
            setSubmit(false);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * edit oil maint.Expense
   * @param {*} e
   */
  const handleEditOilMaintainance = (e) => {
    e.preventDefault();
    setSubmit(true);

    if (oilExpenseId && oilServiceDate) {
      setLoader("loading");
      VehicleService.editOilMaintenanceData(
        tabId,
        oilExpenseId,
        oilServiceDate,
        amount,
        invoiceNumber,
        description,
        kmReading,
        oilServiceNextServiceDate,
        garage,
        paymentMethod
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setOilServiceDate("");
            setAmount("");
            setInvoiceNumber("");
            setDescription("");
            setKmReading("");
            setOilServiceNextServiceDate("");
            setGarage("");
            setPaymentMethod("");
            vehicleOilExpenseResponse(page1, tabId);
            setSubmit(false);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  /**
   * handle edit maint.
   * @param {*} e
   * @param {*} tabId
   */
  const handleEditMaintainance = (e, tabId) => {
    e.preventDefault();
    setSubmit(true);

    if (tabId) {
      setLoader("loading");
      VehicleService.editMaintenanceData(
        tabId,
        kmsAtLastService,
        serviceIntervalKm,
        maintananceLastServiceDate,
        maintananceNextServiceDate
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setSubmit(false);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  /**
   * on delete of maintanance
   * 
   */
  const onDelete = (id) => {
    setLoader("loading");
    VehicleService.deleteoilservice(id).then((response) => {
      if (response?.data?.status?.code === 0) {
        setLoader("isfull");
        dispatch(alertActions.success(response?.data?.status?.message));
        vehicleOilExpenseResponse(page1, tabId);
      } else {
        setLoader("isempty");
        dispatch(alertActions.error(response?.data?.status?.message));
      }
    });

  }

  /**
 * 
 * deletemainexp
 */
  const onDeleteMaintainance = (id) => {
    setLoader("loading");
    VehicleService.deletemainexp(id).then((response) => {
      if (response?.data?.status?.code === 0) {
        setLoader("isfull");
        dispatch(alertActions.success(response?.data?.status?.message));
        vehicleMaintainanceExpenseResponse(page2, tabId);
      } else {
        setLoader("isempty");
        dispatch(alertActions.error(response?.data?.status?.message));
      }
    });
    vehicleOilExpenseResponse(page1, tabId);
  }

  return {
    loader,
    kmsAtLastService,
    serviceIntervalKm,
    maintananceLastServiceDate,
    maintananceNextServiceDate,
    oilServiceDate,
    amount,
    invoiceNumber,
    description,
    kmReading,
    oilServiceNextServiceDate,
    garage,
    paymentMethod,
    maintainenceExpenseDate,
    maintainenceExpenseInvoice,
    maintainenceExpenseDescription,
    maintainenceExpenseKmReading,
    maintainenceExpenseGarage,
    maintainenceExpensePaymentMethod,
    maintainenceExpenseServiceType,
    maintainenceExpenseAmount,
    checkRes,
    submit,
    id,
    totalPages1,
    page1,
    oilListData,
    totalPages2,
    page2,
    maintainanceListData,
    oilExpenseId,
    maintExpenseId,
    submitMaint,
    paymentTypeArray,
    vehicleExpenseArray,
    listTable1,
    listTable2,
    columns,
    columns1,
    setId,
    handleKmsAtLastService,
    handleServiceIntervalKm,
    handleMaintananceLastServiceDate,
    handleMaintananceNextServiceDate,
    handleOilServiceDate,
    handleOilAmount,
    handleOilInvoiceNumber,
    handleOilDescription,
    handleOilKmReading,
    handleOilServiceNextServiceDate,
    handleOilGarage,
    handleOilPayment,
    handleMaintainenceExpenseDate,
    handleMaintainenceExpenseInvoice,
    handleMaintainenceExpenseDescription,
    handleMaintainenceExpenseKmReading,
    handleMaintainenceExpenseGarage,
    handleMaintainenceExpensePaymentMethod,
    handleMaintainenceExpenseServiceType,
    handleMaintainanceAmount,
    handleMaintainance,
    handleEditMaintainance,
    handleOilMaintainance,
    handleMaintainanceExpense,
    handleOilExpensePageClick,
    handleMaintainanceExpensePageClick,
    handleRowOilExpense,
    handleRowMaintainance,
    handleEditOilMaintainance,
    handleEditMaintainanceExpense,
  };
};
export default useMaintanance;
