import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerService } from "../../../../../services/CustomerService";

const useCustomerHistory = (location, props) => {
  const { t } = props;
  let [page, setPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("mobileno");
  const [loader, setLoader] = useState("notset");
  const [id, setId] = useState(location?.state?.id);
  const [listTable, setListTable] = useState([]);
  const navigate = useNavigate();
  const columns = [
    {
      name: t("Sr_No"),
      selector: (row) => row.number,
      width: "80px",
    },
    {
      name: t("Customer_Name"),
      selector: (row) => row.fullname,
      width: "150px",
    },

    {
      name: t("Mobile_Number"),
      selector: (row) => row.mobileno,
      width: "150px",
    },
    {
      name: t("Start_Date_Time"),
      selector: (row) => row.StartDateTime,
      width: "180px",
    },
    {
      name: t("End_Date_Time"),
      selector: (row) => row.EndDateTime,
      width: "180px",
    },
    {
      name: t("Number_of_Days"),
      selector: (row) => row.NoOFDays,
      width: "150px",
    },
    {
      name: t("Rent_Status"),

      selector: (row) => (
        <div
          className={
            row.payment_status === "onrent"
              ? "button_style_onrent"
              : row.payment_status === "completed"
                ? "button_style_completed"
                : ""
          }
        >
          {row.payment_status === "onrent"
            ? "On Rent"
            : row.payment_status === "completed"
              ? "Completed"
              : ""}
        </div>
      ),
      width: "150px",
    },
    {
      name: "Payment Status",
      selector: (row) => {
        return (
          <div
            className={
              row.paymentStatus === "Pending"
                ? "button_style_pending"
                : row.paymentStatus === "Paid"
                  ? "button_style_completed1"
                  : ""
            }
          >
            {row.paymentStatus}
          </div>
        );
      },
      width: "150px",
    },
    {
      name: "View Agreement",
      selector: (row) => (
        <>
          {row.payment_status === "onrent" && (
            <button
              className="viewdata"
              onClick={() => {
                navigateToAgreement(row);
              }}
            >
              {t("view")}
            </button>
          )}
        </>
      ),
      width: "150px",
    },

    {
      name: "Make Payment",
      selector: (row) => (
        <>
          {row.paymentStatus === "Pending" && (
            <button className="viewdata" onClick={() => handleRow(row)}>
              {t("Make_Payment")}
            </button>
          )}
        </>
      ),
      width: "150px",

    },
  ];
  let order = [];
  let obj = {};
  /**
   * customer list call
   */
  useEffect(() => {
    customerListResponse(id, page, item, search);
    // eslint-disable-next-line
  }, []);

  /**
   * list useeffect
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) {
      list();
    }
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);



  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      let startDate = item?.startdate ? new Date(item?.startdate) : "";
      let endDate = item?.enddate ? new Date(item?.enddate) : "";
      obj = {
        id: item?._id,
        number:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        fullname: item?.customername,
        mobileno: item?.customermobileno,
        StartDateTime: startDate?.toLocaleString("en-IN"),
        EndDateTime: endDate?.toLocaleString("en-IN"),
        NoOFDays: item?.noofdays,
        payment_status: item?.bookingstatus,
        paymentStatus: item?.paymentstatus,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };


  /**
   * customer list response
   * @param {*} id 
   * @param {*} page 
   * @param {*} item 
   * @param {*} search 
   */
  const customerListResponse = (id, page, item, search) => {
    setLoader("loading");
    CustomerService.getCustomersHistory(id, page, item, search.trim()).then(
      (response) => {
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        setLoader("isfull");
      }
    );
  };

  /**
   * change handler
   * @param {*} e 
   */
  const changeItemHandler = (e) => {
    if (e.target.value === "fullname") {
      setItem(e.target.value);
      customerListResponse(id, page, e.target.value, "");
    } else {
      setItem(e.target.value);
      customerListResponse(id, page, e.target.value, "");
    }
  };

  /**
   * handle row
   * @param {*} row 
   */
  const handleRow = (row) => {
    navigate(`/PendingPaymentTab?bid=${row?.id}`);
  };

  /**
   * navigate to agreement
   * @param {*} row 
   */
  const navigateToAgreement = (row) => {
    navigate(`/ExtendedDateTab?bid=${row?.id}`);
  };

  /**
   * search handler
   * @param {*} e 
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    customerListResponse(id, "", item, e.target.value);
  };
  /**
   * handle page click
   * @param {*} e 
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    customerListResponse(id, e.selected + 1, item, search);
  };
  /**
   * nvigate to list
   */
  const navigateToList = () => {
    navigate("/customers");
  };

  return {
    listData,
    totalPages,
    search,
    page,
    loader,
    columns,
    listTable,
    setId,
    handleRow,
    navigateToAgreement,
    handlePageClick,
    changeItemHandler,
    changeSearchHandler,
    navigateToList,
  };
};
export default useCustomerHistory;
