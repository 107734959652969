import { useEffect, useState } from "react";
import { AgreementService } from "../../../../../services";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const usePendingPaymentList = (props) => {
  const { t } = props;
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState("notset");
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("mobileno");
  const [fromDate, setFromDate] = useState("");
  const [noData, setNoData] = useState(false);
  const navigate = useNavigate();
  const [listTable, setListTable] = useState([]);
  const dashBoardDisplay = useSelector(
    (state) => state?.userData?.user?.dashboardUA
  );

  const columns = [
    {
      name: t("Sr_No"),
      selector: (row) => row.number,
      width: "80px",
    },
    {
      name: t("Name"),
      selector: (row) => row.customername,
      width: "250px",
    },
    {
      name: t("Mobile"),
      selector: (row) => row.customermobileno,
      width: "150px",
    },
    {
      name: t("Reg._No"),
      selector: (row) => row.vehicleregnumber,
      width: "150px",
    },
    {
      name: t("Start_D-T"),
      selector: (row) => row.startdate,
      width: "180px",
    },
    {
      name: t("End_D-T"),
      selector: (row) => row.enddate,
      width: "180px",
    },

    {
      name: t("Days"),
      selector: (row) => (row.noofdays ? row.noofdays : "N/A"),
      width: "150px",
    },

    {
      name: t("Paid_Amt"),
      selector: (row) =>
        row.paidamount !== 0 ? `₹${row.paidamount.toFixed(2)}` : "N/A",
      width: "150px",
    },
    {
      name: t("Bal_Amt"),
      selector: (row) =>
        row.pendingamount !== 0 ? `₹${row.pendingamount.toFixed(2)}` : "N/A",
      width: "150px",
    },
    {
      name: t("Due-Date"),

      selector: (row) => {
        return (
          <>
            <div
              className={
                moment(row.dueDate, "DD/MM/YYYY") <=
                  moment(new Date(), "DD/MM/YYYY").add(4, "days")
                  ? "button_style_expiry"
                  : ""
              }
            >
              {row.dueDate}
            </div>
          </>
        );
      },
      width: "150px",
    },

    {
      name: t("Apply_Payment"),
      selector: (row) =>
        dashBoardDisplay?.includes("pendingPayment") ? (
          <button className="viewdata" onClick={() => handleRow(row)}>
            {t("Make Payment")}
          </button>
        ) : (
          <button
            style={{ pointerEvents: "none", opacity: "0.5" }}
            className="viewdata"
            onClick={() => handleRow(row)}
          >
            {t("Make Payment")}
          </button>
        ),
      width: "150px",
    },
  ];
  let order = [];
  let obj = {};

  /**
   * list call
   */
  useEffect(() => {
    reservationResponse(page, item, search, fromDate);
    // eslint-disable-next-line
  }, []);
  /**
   * table list call
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * table list
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      let startDate = item?.startdate ? new Date(item?.startdate) : "N/A";
      let endDate = item?.enddate ? new Date(item?.enddate) : "N/A";
      obj = {
        id: item?._id,
        number:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        customername: item?.customername ? item?.customername : "N/A",
        customermobileno: item?.customermobileno
          ? item?.customermobileno
          : "N/A",
        startdate: startDate ? startDate?.toLocaleString("en-IN") : "N/A",
        enddate: endDate ? endDate?.toLocaleString("en-IN") : "N/A",
        noofdays: item?.noofdays ? item?.noofdays : "N/A",
        paidamount: item?.paidamount,
        pendingamount: item?.pendingamount,
        vehicleregnumber: item?.vehicleregnumber
          ? item?.vehicleregnumber
          : "N/A",
        vehicledetailsclass: item?.vehicledetailsclass
          ? item?.vehicledetailsclass
          : "N/A",
        vehicledetailsmake: item?.vehicledetailsmake
          ? item?.vehicledetailsmake
          : "N/A",
        vehicledetailsmodel: item?.vehicledetailsmodel
          ? item?.vehicledetailsmodel
          : "N/A",
        dueDate: item?.dueDate
          ? moment(item?.dueDate).format("DD/MM/YYYY")
          : "N/A",
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };
  /**
   * list api call
   * @param {*} page
   * @param {*} item
   * @param {*} search
   * @param {*} fromDate
   */
  const reservationResponse = (page, item, search, fromDate) => {
    setLoader("loading");
    AgreementService.getPendingPayment(page, item, search.trim(), fromDate)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setNoData(false);
          setListData(response);
          setTotalPages(response?.data?.data?.totalPages);
          setPage(response?.data?.data?.page);
          setLoader("isfull");
        } else {
          setLoader("empty");
          setNoData(true);
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * handle change
   * @param {*} e
   */
  const handleStartDate = (e) => {
    setFromDate(moment(e).format("DD/MM/YYYY"));
    reservationResponse(page, item, "", moment(e).format("DD/MM/YYYY"));
  };

  /**
   * change handler
   * @param {*} e
   */
  const changeItemHandler = (e) => {
    setSearch("");
    setFromDate("");

    if (e.target.value === "customername") {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", fromDate);
    } else if (e.target.value === "regNumber") {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", fromDate);
    } else if (e.target.value === "duedate") {
      setItem(e.target.value);

      reservationResponse(1, e.target.value, "", fromDate);
    } else {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", fromDate);
    }
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    navigate(`/PendingPaymentTab?bid=${row?.id}`);
  };

  /**
   * search handle
   * @param {*} e
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    reservationResponse("", item, e.target.value, fromDate);
  };
  /**
   * handle page
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    reservationResponse(e.selected + 1, item, search, fromDate);
  };

  return {
    listData,
    totalPages,
    page,
    loader,
    search,
    item,
    fromDate,
    noData,
    columns,
    listTable,
    handleStartDate,
    handlePageClick,
    changeSearchHandler,
    changeItemHandler,
  };
};
export default usePendingPaymentList;
