import { agreements } from "../../../../assets/images";
import Menu from "../../../_common/menu/Menu";
import TopBar from "../../../_common/topbar/TopBar";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { commaValidation } from "../../../../utility";
import { withTranslation } from "react-i18next";

const InvestorListDetails = (props) => {
  const { t } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const moveToExpense = () => {
    navigate("/Report", { state: { val: 2 } });
  };

  const agreementtiltle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img onClick={moveToExpense} src={agreements} alt="agreements" />
          {t("Investers Details")}
        </div>
      </div>
    );
  };
  const tableView = () => {
    return (
      <div className="dataView_main flt">
        <div className="dataView_title flt"></div>
        <div className="data title flt">
          <div className="sub_details  flt">
            {location?.state?.row?.checkinDetails &&
              location?.state?.row?.checkinDetails?.length > 0 && (
                <ul>
                  <li>
                    <b>{t("Checkin Details")}</b>
                  </li>
                  <li>&nbsp;</li>
                </ul>
              )}
            {location?.state?.row?.checkinDetails &&
              location?.state?.row?.checkinDetails.map((res) => (
                <ul className="looparea flt">
                  <li>
                    {t("Odo_meter")} <span>:</span>
                  </li>
                  <li>{res?.Odo_meter ? res?.Odo_meter : "N/A"}</li>
                  <div className="divider flt"></div>
                </ul>
              ))}
          </div>
          <div className="sub_details flt">
            {location?.state?.row?.checkoutDetails &&
              location?.state?.row?.checkoutDetails?.length > 0 && (
                <ul>
                  <li>
                    <b>{t("Checkout Details")} </b>
                  </li>
                  <li>&nbsp;</li>
                </ul>
              )}
            {location?.state?.row?.checkoutDetails &&
              location?.state?.row?.checkoutDetails.map((res) => (
                <ul className="looparea flt">
                  <li>
                    {t("Odo_meter")} <span>:</span>
                  </li>
                  <li>{res?.Odo_meter ? res?.Odo_meter : "N/A"}</li>
                  <div className="divider flt"></div>
                </ul>
              ))}
          </div>
          <div className="sub_details flt">
            {location?.state?.row?.paymentDetails &&
              location?.state?.row?.paymentDetails?.length > 0 && (
                <ul className="">
                  <li>
                    <b>{t("Payment Details")}</b>
                  </li>
                  <li>&nbsp;</li>
                </ul>
              )}
            {location?.state?.row?.paymentDetails &&
              location?.state?.row?.paymentDetails.map((res) => (
                <ul className="looparea flt">
                  <li>
                    {t("Payment Details")} <span>:</span>
                  </li>
                  <li>
                    {res?.Grand_Total
                      ? `₹${commaValidation(res?.Grand_Total)}`
                      : "N/A"}
                  </li>
                  <li>
                    {t("Total Rental other charges")} <span>:</span>
                  </li>
                  <li>
                    {res?.TotalRental_othercharges
                      ? `₹${commaValidation(res?.TotalRental_othercharges)}`
                      : "N/A"}
                  </li>
                  <div className="divider flt"></div>
                </ul>
              ))}
          </div>


          <div className="sub_details flt">
            {location?.state?.row?.rentalDetails &&
              location?.state?.row?.rentalDetails?.length > 0 && (
                <ul>
                  <li>
                    <b>{t("Rental Details")}</b>
                  </li>
                  <li>&nbsp;</li>
                </ul>
              )}
            {location?.state?.row?.rentalDetails &&
              location?.state?.row?.rentalDetails.map((res) => (
                <ul className="looparea flt">
                  <li>
                    {t("EndDateTime")}<span>:</span>
                  </li>

                  <li>
                    {res?.EndDateTime
                      ? moment(res?.EndDateTime).format("DD/MM/YYYY, h:mm:ss a")
                      : "N/A"}
                  </li>
                  <li>
                    {t("StartDateTime")}<span>:</span>
                  </li>
                  <li>
                    {res?.StartDateTime
                      ? moment(res?.StartDateTime).format(
                        "DD/MM/YYYY, h:mm:ss a"
                      )
                      : "N/A"}
                  </li>
                  <div className="divider flt"></div>
                </ul>
              ))}
          </div>

          <div className="sub_details sub_details1 flt">
            {location?.state?.row?.checkinDetails?.length <= 0 &&
              location?.state?.row?.checkoutDetails?.length <= 0 &&
              location?.state?.row?.paymentDetails?.length <= 0 &&
              location?.state?.row?.rentalDetails?.length <= 0 && (
                <b>
                  <span>{t("No Data Found")}</span>{" "}
                </b>
              )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <TopBar />
      <Menu />

      {agreementtiltle()}
      {tableView()}
    </>
  );
};
export default withTranslation() (InvestorListDetails);
