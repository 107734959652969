import "./UserAccess.css";
import { Link } from "react-router-dom";
import TopBar from "../../../_common/topbar/TopBar";
import Menu from "../../../_common/menu/Menu";
import LeftSidebar from "../../../_common/sidebar/LeftSidebar";
import useUserHooks from "./useUserAccessHooks";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import AgreementTab from "./subTab/SetUpAgreements/SetUpAgreement";
import VehicleTab from "./subTab/SetUpVehicle/SetUpVehicle";
import TopRibbonTab from "./subTab/SetUpTopRibbon/SetUpTopRibbon";
import DashboardTab from "./subTab/SetUpDashboard/SetUpDashboard";
import LocationTab from "./subTab/SetUpLocation/SetUpLocation";
import { Loading } from "../../../../assets/images";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

const UserAccess = (props) => {
  const { t } = props;
  const [shownPassword, setShownPassword] = useState(false);
  const [shownConfirm, setShownConfirm] = useState(false);
  const [userTab, setUserTab] = useState(1);
  const userUpdateDisplay = useSelector(
    (state) => state?.userData?.user?.userLevel
  );
  const {
    columns,
    loader,
    totalPages,
    locationArray,
    userId,
    password,
    userName,
    re_password,
    defLocation,
    submitted,
    userLevel,
    id,
    agreementsUA,
    vehiclesUA,
    locationsUA,
    dashboardUA,
    topRibbonUA,
    listTable,
    handleUserIdChange,
    handleUserNameChange,
    handlePasswordChange,
    handleUserLevel,
    handleDefLocationChange,
    handleSubmit,
    handlePageClick,
    handleRePasswordChange,
    handleTopRibbon,
    handleDashboard,
    handleLocation,
    handleVehicle,
    handleAgreement,
    handleClearField,
    handleEditUser,
    handleEditSubmit,
    handleDeleteSubmit,
  } = useUserHooks();

  const userDetails = () => {
    return (
      <>
        <div className="chat_right flt">
          <div className="main_userControl flt">
            <div className="user_cntrl flt">{t("User Control")}</div>
            <div className="user_btn flt">
              {id === "" && (
                <button
                  className="add_btn"
                  onClick={(e) => {
                    handleSubmit(e);
                    setUserTab(1);
                  }}
                >
                  <i class="fas fa-user-plus"></i>
                  {t("Add User")}
                </button>
              )}
              {id !== "" && (
                <>
                  <button
                    className="add_btn"
                    onClick={(e) => {
                      handleEditSubmit(e);
                      setUserTab(1);
                    }}
                  >
                    <i class="fas fa-user-edit"></i>
                    {t("Update User")}
                  </button>
                  <button
                    className="add_btn"
                    onClick={(e) => {
                      handleClearField(e);
                      setUserTab(1);
                    }}
                  >
                    <i class="fas fa-ban"></i>
                    {t("Cancel")}
                  </button>
                  <button
                    className="add_btn"
                    onClick={(e) => {
                      handleDeleteSubmit(e);
                      setUserTab(1);
                    }}
                  >
                    <i class="fas fa-trash-alt"></i>
                    {t("Delete")}
                  </button>
                </>
              )}{" "}
            </div>
          </div>

          {/* {loader === "loading" && (
            <div className="main_load ">
              <img src={Loading} alt="loader" />
            </div>
          )} */}
          {/* {(loader === "isfull" ||
            loader === "isempty" ||
            loader === "notset") && ( */}
          <div className="sec_container">
            <div className="row">
              <div className="col-sm-6 ">
                <div className="main_col flt">
                  <div className="reg_form flt">
                    <div className="user_name_main flt">
                      <div className="form-group flt">
                        <label className="labelName">{t("User ID")} *</label>

                        <input
                          type="text"
                          placeholder="Enter User ID"
                          name="userId"
                          id="userId"
                          onChange={handleUserIdChange}
                          value={userId}
                        />
                        {submitted && !userId && (
                          <div className="req_field">
                            {t("User id is required")}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="user_name_main flt">
                      <div className="form-group flt">
                        <label className="labelName">{t("User Name")} *</label>

                        <input
                          type="text"
                          placeholder="Enter username"
                          name="userName"
                          id="userName"
                          onChange={handleUserNameChange}
                          value={userName}
                        />
                        {submitted && !userName && (
                          <div className="req_field">
                            {t("User name is required")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="password_main flt">
                    <div className="user_name_main flt">
                      <div className="form-group flt">
                        <label className="labelName">{t("Password")} *</label>
                        <div className="eye_class flt">
                          <input
                            type={shownPassword ? "text" : "password"}
                            placeholder="Enter password"
                            name="password"
                            id="password"
                            onChange={handlePasswordChange}
                            value={password}
                          />

                          <i
                            class="fas fa-eye"
                            onClick={() => setShownPassword(!shownPassword)}
                          ></i>
                        </div>

                        {submitted && !password && (
                          <div className="req_field">
                            {t("Password is required")}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="user_name_main flt">
                      <div className="form-group flt">
                        <label className="labelName">
                          {t("Confirm Password ")}*
                        </label>
                        <div className="eye_class flt">
                          <input
                            type={shownConfirm ? "text" : "password"}
                            placeholder="Enter confirm password"
                            name="re_password"
                            id="re_password"
                            onChange={handleRePasswordChange}
                            value={re_password}
                          ></input>
                          <i
                            className="fas fa-eye"
                            onClick={() => setShownConfirm(!shownConfirm)}
                          ></i>
                        </div>
                        {submitted && !re_password && (
                          <div className="req_field">
                            {t("Confirm password is required")}
                          </div>
                        )}{" "}
                      </div>
                    </div>
                  </div>

                  <div className="default_loc flt">
                    <div className="de_location flt">
                      <div className="form-group flt">
                        <label className="labelName">
                          {t("Default Location")} *
                        </label>

                        <select
                          name="defLocation"
                          id="defLocation"
                          onChange={handleDefLocationChange}
                          value={defLocation}
                        >
                          <option value="">{t("Select Location Name")}</option>
                          {locationArray &&
                            locationArray.map((location, index) => (
                              <option key={index}>
                                {location.locationName}
                              </option>
                            ))}
                        </select>
                        {submitted && !defLocation && (
                          <div className="req_field">
                            {t("Location is required")}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="user_level flt">
                      <div className="form-group flt">
                        <label className="labelName">{t("User Level")} *</label>
                        <ul className="main_radio flt">
                          <li className="radio_btn ">
                            <input
                              type="radio"
                              id="Administrator"
                              name="fav_language"
                              value="Administrator"
                              onChange={handleUserLevel}
                              checked={
                                userLevel === "Administrator" ? true : false
                              }
                            />
                            <label for="Administrator">
                              {t("Administrator")}
                            </label>
                          </li>
                          <li className="radio_btn">
                            <input
                              type="radio"
                              id="Common_User"
                              name="fav_language"
                              value="Common User"
                              onChange={handleUserLevel}
                              checked={
                                userLevel === "Common User" ? true : false
                              }
                            />
                            <label for="Common_User">{t("Common User")}</label>
                          </li>
                        </ul>
                        {submitted && !userLevel && (
                          <div className="req_field">
                            {t("User level is required")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="star_input flt">
                    * {t("Please Select User Access & Save")}
                  </div>
                </div>

                <div className="invoice_agree flt">
                  <div className="main_newuser flt">
                    <div className="new_useracc flt">{t("User Access")}</div>
                    <div className="general_informaion flt">
                      <ul className="insurance_info">
                        <li className={userTab === 1 ? `activeborder` : ""}>
                          <Link onClick={() => setUserTab(1)} to="">
                            {t("Agreements")}
                          </Link>
                        </li>

                        <li className={userTab === 2 ? `activeborder` : ""}>
                          <Link onClick={() => setUserTab(2)} to="">
                            {t("Vehicles")}
                          </Link>
                        </li>
                        <li className={userTab === 3 ? `activeborder` : ""}>
                          <Link onClick={() => setUserTab(3)} to="">
                            {t("Location")}{" "}
                          </Link>
                        </li>

                        <li className={userTab === 4 ? `activeborder` : ""}>
                          <Link onClick={() => setUserTab(4)} to="">
                            {t("Dashboard")}{" "}
                          </Link>
                        </li>
                        <li className={userTab === 5 ? `activeborder` : ""}>
                          <Link onClick={() => setUserTab(5)} to="">
                            {t("Top-Ribbon")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="main_make flt">
                    {userTab === 1 && (
                      <AgreementTab
                        agreementsUA={agreementsUA}
                        handleAgreement={handleAgreement}
                      />
                    )}
                    {userTab === 2 && (
                      <VehicleTab
                        vehiclesUA={vehiclesUA}
                        handleVehicle={handleVehicle}
                      />
                    )}
                    {userTab === 3 && (
                      <LocationTab
                        locationsUA={locationsUA}
                        handleLocation={handleLocation}
                      />
                    )}
                    {userTab === 4 && (
                      <DashboardTab
                        dashboardUA={dashboardUA}
                        handleDashboard={handleDashboard}
                      />
                    )}
                    {userTab === 5 && (
                      <TopRibbonTab
                        topRibbonUA={topRibbonUA}
                        handleTopRibbon={handleTopRibbon}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="user_data flt">
                  {/* {loader === "loading" || loader === "notset" ? (
                        <div className="main_load ">
                          <img src={Loading} alt="loader" />
                        </div>
                      ) : ( */}
                  <table className="main_table flt">
                    {userUpdateDisplay === "Administrator" ? (
                      <DataTable
                        columns={columns}
                        data={listTable || []}
                        onRowClicked={(row) => {
                          {
                            setUserTab(1);
                            handleEditUser(row);
                          }
                          setShownConfirm(false);
                          setShownPassword(false);
                        }}
                      />
                    ) : (
                      <DataTable columns={columns} data={listTable || []} />
                    )}
                  </table>
                  {/* )} */}

                  <div>
                    <ReactPaginate
                      nextLabel="&rarr;"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      pageCount={totalPages}
                      previousLabel="&larr;"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
      </>
    );
  };

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      <LeftSidebar />

      {userDetails()}
    </div>
  );
};
export default withTranslation()(UserAccess);
