import * as axios from "../network/Network";

const config = require("../network/api.json");

class ReportService {

  /**
   * Get payment report list
   * @param {*} page 
   * @param {*} from 
   * @param {*} to 
   * @returns 
   */
  static getPaymentReportList(page, from, to) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.report_payments}?page=${page}&from=${from}&to=${to}`,
    });
    return Promise.resolve(result);
  }


  /**
   * get payment total amount
   * @param {*} from 
   * @param {*} to 
   * @returns 
   */
  static getPaymentAmount(from, to) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getPaymentAmount}?from=${from}&to=${to}`,
    });
    return Promise.resolve(result);
  }


  /**
   * get rental report list
   * @param {*} page 
   * @param {*} from 
   * @param {*} to 
   * @returns 
   */
  static getRentalReportList(page, from, to) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getRentalReportList}?page=${page}&from=${from}&to=${to}`,
    });
    return Promise.resolve(result);
  }

  /**
   * get rental total amount
   * @param {*} from 
   * @param {*} to 
   * @returns 
   */
  static getRentalAmount(from, to) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getRentalAmount}?from=${from}&to=${to}`,
    });
    return Promise.resolve(result);
  }


  /**
   * get vehicle expense report list
   * @param {*} page 
   * @param {*} from 
   * @param {*} to 
   * @returns 
   */
  static getvehicleExpenseReportList(page, from, to) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getvehicleExpenseReportList}?page=${page}&from=${from}&to=${to}`,
    });
    return Promise.resolve(result);
  }

  /**
   * get vehicle expense total amount
   * @param {*} from 
   * @param {*} to 
   * @returns 
   */
  static getVehicleExpensePaymentAmount(from, to) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getVehicleExpensePaymentAmount}?from=${from}&to=${to}`,
    });
    return Promise.resolve(result);
  }

  /**
   * get pl expense
   * @param {*} page 
   * @param {*} from 
   * @param {*} to 
   * @returns 
   */
  static getplExpense(page, from, to) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.plExpense}?page=${page}&from=${from}&to=${to}`,
    });
    return Promise.resolve(result);
  }


  /**
   * get pl expense total amount
   * @param {*} from 
   * @param {*} to 
   * @returns 
   */
  static getplExpensePaymentAmount(from, to) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.plTotal}?from=${from}&to=${to}`,
    });
    return Promise.resolve(result);
  }


  /**
   * get investor list
   * @param {*} page 
   * @param {*} from 
   * @param {*} to 
   * @param {*} investorId 
   * @returns 
   */
  static getInvestorReport(page, from, to, investorId) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getInvestorReport}/${investorId}?page=${page}&from=${from}&to=${to}`,
    });
    return Promise.resolve(result);
  }


  /**
   * get pl expense total amount
   * @param {*} from 
   * @param {*} to 
   * @param {*} investorId 
   * @returns 
   */
  static getInvestorPaymentAmount(from, to, investorId) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.investorAmount}/${investorId}?from=${from}&to=${to}`,
    });
    return Promise.resolve(result);
  }


  /**
   * get vehicle info list
   * @param {*} page 
   * @returns 
   */
  static getVehicleInfoList(page) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.vehicleInfoList}?page=${page}`,
    });
    return Promise.resolve(result);
  }
}
export { ReportService };
