import DataTable from "react-data-table-component";
import Menu from "../../../_common/menu/Menu";
import TopBar from "../../../_common/topbar/TopBar";
import "../Investor.css";
import ReactPaginate from "react-paginate";
import useInvestorListHook from "./useInvestorListHook";
import { withTranslation } from "react-i18next";
import { investor } from "../../../../assets/images";
import { Loading } from "../../../../assets/images";

const InvestorList = (props) => {
  const { t } = props;
  const {
    listData,
    totalPages,
    search,
    page,
    loader,
    columns,
    listTable,
    handlePageClick,
    handleRow,
    changeItemHandler,
    changeSearchHandler,
    navigateToAddInvestor,
  } = useInvestorListHook(t);

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const investSearch = () => {
    return (
      <>
        <div className="main_location investor_mainbb2 flt">
          <div className="location">
            <img src={investor} alt="invest" />
            {t("INVESTORS")}
          </div>

          <div className="filler"></div>
          <div className="main_search_dd investor_search_dd">
            <div className="search_l ">
              <select name="item" id="item" onChange={changeItemHandler}>
                <option default value="investorName">
                  {t("Search by Investor Name.")}
                </option>
                <option value="mobile_no">{t("Search by Mobile No.")}</option>
              </select>
            </div>
            <div className="search_b1 ">
              <input
                type="text"
                placeholder={t("Search..")}
                name="search"
                value={search}
                onChange={changeSearchHandler}
              />
              <button className="search_button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div className="fliter_button2 investor_btnb22">

            <button
              onClick={() => {
                navigateToAddInvestor();
              }}
            >
              <i class="fas fa-user-plus"></i>
              {t("Add Investor")}
            </button>
          </div>

        </div>
      </>
    )
  }

  const datatableDetails = () => {
    return (
      <>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="table_loader ">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  {listData?.data?.data?.docs?.length !== 0 ? (
                    <DataTable
                      columns={columns}
                      data={listTable || []}
                      onRowClicked={(row) => handleRow(row)}
                      customStyles={customStyles}
                    />
                  ) : (
                    <div className="no-data">{t("No Data Found")}</div>
                  )}
                  {listData?.data?.data?.docs?.length !== 0 && (
                    <div>
                      <ReactPaginate
                        nextLabel="&rarr;"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalPages}
                        previousLabel="&larr;"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        currentPage={page}
                        forcePage={page - 1}
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  )}{" "}
                </table>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      {investSearch()}
      {datatableDetails()}

    </div>
  );
};
export default withTranslation()(InvestorList);

