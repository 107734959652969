import { useEffect, useState } from "react";
import { alertActions } from "../../../../../../../../actions/alert.actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { StartrentService } from "../../../../../../../../services";
import moment from "moment";

const useBookingPersonalInfoHoook = (location, displayData, bid) => {
  const [id, setId] = useState(
    location?.state?.row?.id ? location?.state?.row?.id : ""
  );
  const navigate = useNavigate();
  const [date_of_birth, setdate_of_birth] = useState(
    id !== "" ? location?.state?.row.date_of_birth : ""
  );
  const [name, setName] = useState(
    id !== "" ? location?.state?.row.fullname : ""
  );
  const [email, setEmail] = useState(
    id !== "" ? location?.state?.row.email : ""
  );
  const [mblno, setMblno] = useState(
    id !== "" ? location?.state?.row.mobileno : ""
  );
  const [altno, setAltno] = useState(
    id !== "" ? location?.state?.row.alternatemobileno : ""
  );
  const [dob, setDob] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState("notset");
  const dispatch = useDispatch();
  const bookid = bid;
  /**
   * get data
   */
  useEffect(() => {
    if (bid) {
      setLoader("loading");
      StartrentService.getAllData(bid)
        .then((res) => {
          if (res?.data?.status?.code === 0) {
            setLoader("isfull");
            setName(res?.data?.data?.fullname);
            setEmail(res?.data?.data?.email);
            setMblno(res?.data?.data?.mobileno);
            setAltno(res?.data?.data?.alternatemobileno);
            setdate_of_birth(res?.data?.data?.date_of_birth);
          } else {
            console.log("error");
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, []);

  /**
   * handle change
   * @param {*} e
   */
  const handleChange = (e) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    }
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
    if (e.target.name === "mblno") {
      setMblno(e.target.value);
    }
    if (e.target.name === "altno") {
      setAltno(e.target.value);
    }
  };

  /**
   * add customer details
   * @param {*} e
   */
  const ContinueInfo = (e) => {
    e.preventDefault();
    setSubmitted(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (name && mblno) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error("Enter valid Email Address"));
      } else if (mblno && !filter.test(mblno)) {
        dispatch(alertActions.error("Enter valid Mobile Number"));
      } else if (altno !== "" && !filter.test(altno)) {
        dispatch(alertActions.error("Enter valid number"));
      } else {
        //api call
        if (bookid) {
          setLoader("loading");
          StartrentService.addCustomerDetails(
            name,
            email,
            mblno,
            altno,
            date_of_birth,
            bookid
          )
            .then((response) => {
              if (response?.data?.status?.code === 0) {
                displayData(1);
                navigate(`?bid=${bid}`, {
                  state: {
                    bookid: response?.data?.data?._id,
                  },
                });
                setLoader("isfull");
                dispatch(alertActions.success(response?.data?.status?.message));
              } else {
                setLoader("isempty");
                dispatch(alertActions.error(response?.data?.status?.message));
              }
            })
            .catch((err) => console.log(err));
        } else {
          setLoader("loading");
          StartrentService.addCustomerDetails(
            name,
            email,
            mblno,
            altno,
            date_of_birth,
            location?.state?.row?.id
          )
            .then((response) => {
              if (response?.data?.status?.code === 0) {
                displayData(1);
                navigate(`/BookingAgreeTab/${response?.data?.data?._id}`, {
                  state: {
                    bookid: response?.data?.data?._id,
                  },
                });
                setLoader("isfull");
                dispatch(alertActions.success(response?.data?.status?.message));
              } else {
                setLoader("isempty");
                dispatch(alertActions.error(response?.data?.status?.message));
              }
            })
            .catch((err) => console.log(err));
        }
      }
    }
  };
  //name required
  const validname = () => {
    if (name === "") {
      return false;
    }
  };
  //email require
  const validemail = () => {
    if (email === "") {
      return false;
    }
  };

  //mbl required
  const validmblno = () => {
    if (mblno === "" || mblno.length < 10) {
      return false;
    }
  };
  //alt mbl
  const validaltno = () => {
    // if (altno === "") {
    //     return false;
    // }
  };

  //dob required
  const validdate_of_birth = () => {
    if (date_of_birth === "") {
      return false;
    }
  };

  const handleChange1 = (e) => {
    setdate_of_birth(moment(e).format("DD/MM/YYYY"));
  };
  return {
    loader,
    date_of_birth,
    name,
    email,
    mblno,
    altno,
    dob,
    submitted,
    setId,
    setDob,
    handleChange,
    handleChange1,
    ContinueInfo,
    validname,
    validemail,
    validmblno,
    validaltno,
    validdate_of_birth,
  };
};
export default useBookingPersonalInfoHoook;
