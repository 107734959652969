import { Calender } from "../../../../../../../assets/images";
import { useState } from "react";
import useEndRentPersonalInfoHoook from "./useEndRentDetails";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Loading } from "../../../../../../../assets/images";

const EndRentDetails = ({ displayData, tab, bookid }) => {
  const [rowinfo, setrowinfo] = useState("");
  const {
    vehicleRes,
    datetimevalue,
    rlocations,
    datetimeendvalue,
    locations,
    helmets_qty,
    travel,
    vehicleClass,
    submitted,
    datavalue,
    returnLocation,
    make,
    model,
    loader,
    onChangeendValue,
    ContinueInfo,
    validdatetimeendvalue,
    navigateToEndRentList,
  } = useEndRentPersonalInfoHoook(displayData, bookid, rowinfo);

  var enddate = validdatetimeendvalue();
  let diffDays;
  let difference;
  if (datetimevalue != "" && datetimeendvalue !== "") {
    var date1 = new Date(datetimevalue);
    var date2 = new Date(datetimeendvalue);
    // diffDays = date2.getDate() - date1.getDate();
    const diffTime = Math.abs(date2 - date1);
    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
  const getRentalDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
            <div className="main_company flt">
              <div className="main_row flt">
                <div className="row">
                  <div className="col-sm-3">
                    <div className=" company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Start Date</label>
                        <div className="main_cal_img">
                          <Datetime
                            dateFormat="DD/MM/YYYY"
                            value={new Date(datetimevalue)}
                            inputProps={{
                              placeholder: "Select Date",
                              disabled: true,
                              readOnly: true,
                              onKeyDown: (e) => e.preventDefault(),
                            }}
                          />
                          <div className="cal_img">
                            <img
                              src={Calender}
                              alt="calender"
                              onChange={Datetime}
                            />
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName"> Return Location</label>

                        <select name="location" value={returnLocation} disabled>
                          <option disable>Please select Return Location</option>
                          {locations
                            ? locations.map((value) => (
                              <option
                                value={value._id}
                                key={value.locationName}
                              >
                                {value.locationName}
                              </option>
                            ))
                            : ""}
                        </select>
                      </div>
                    </div>

                  </div>
                  <div className="col-sm-3">
                    <div className=" company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">End Date <span className="numdays"> No of days : {diffDays ? diffDays : "0"}</span></label>
                        <div className="main_cal_img">
                          <Datetime
                            dateFormat="DD/MM/YYYY"
                            onChange={onChangeendValue}
                            value={new Date(datetimeendvalue)}
                            isValidDate={(currentDate, selectedDate) =>
                              currentDate.isAfter(
                                new Date().getTime() - 24 * 60 * 60 * 1000
                              )
                            }
                          />
                          <div className="cal_img">
                            <img
                              src={Calender}
                              alt="calender"
                              onChange={Datetime}
                            />
                          </div>
                        </div>
                        {submitted && enddate === false && (
                          <div className="invalid-feedback">
                            End Date is required
                          </div>
                        )}
                      </div>
                    </div>


                  </div>
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Travel Location</label>
                        <input
                          className="comp_input"
                          type="text"
                          placeholder="Travel Location"
                          name="Travel"
                          id="Travel"
                          value={travel}
                          readOnly
                        />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName"> Pick Location</label>
                        <select
                          name="Location"
                          id="lang"
                          value={rlocations}
                          disabled
                        >
                          <option selected disabled>
                            Pick Location
                          </option>
                          {locations
                            ? locations.map((value) => (
                              <option
                                value={value._id}
                                key={value.locationName}
                              >
                                {value.locationName}
                              </option>
                            ))
                            : ""}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">

                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Helmet Qty</label>
                        <input
                          className="comp_input"
                          type="number"
                          placeholder="Helmet Qty"
                          name="helmets_qty"
                          id="helmets_qty"
                          value={helmets_qty}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Vehicle Class</label>
                        <input
                          className="comp_input"
                          type="vehicleClass"
                          placeholder="Vehicle Class"
                          name="vehicleClass"
                          id="vehicleClass"
                          value={vehicleClass}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Make</label>
                        <input
                          className="comp_input"
                          type="make"
                          placeholder="Make"
                          name="make"
                          id="make"
                          value={make}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Model</label>
                        <input
                          className="comp_input"
                          type="model"
                          placeholder="Model"
                          name="model"
                          id="model"
                          value={model}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3"></div>
                  <div className="col-sm-3"></div>
                  <div className="col-sm-3"></div>
                </div>

              </div>
              <div className="main_row flt">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="main_car_padding flt">
                      <div className="car_details flt">Old Vehicle Details</div>
                      <ul className="make_details flt">
                        <li>
                          Reg Number<span>:</span>
                        </li>

                        <li>{vehicleRes?.data?.data?.regNumber}</li>
                        <li>
                          Vehicle Class<span>:</span>
                        </li>

                        <li>{vehicleRes?.data?.data?.vehicleclass}</li>
                        <li>
                          Make<span>:</span>
                        </li>

                        <li>{vehicleRes?.data?.data?.vehiclemake}</li>

                        <li>
                          Model<span>:</span>
                        </li>

                        <li>{vehicleRes?.data?.data?.vehiclemodel}</li>

                        <li>
                          Location<span>:</span>
                        </li>

                        <li>{vehicleRes?.data?.data?.location}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="main_ownbutton flt">
                  <div className="own_button flt">
                    <button
                      onClick={() => navigateToEndRentList()}
                      className="backbutton"
                    >
                      Back
                    </button>
                    <button onClick={ContinueInfo} className="submitbutton">
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  };

  const titleDislay = () => {
    return (
      <>
        <div className="Company_name flt">
          <div className="comp_name_details flt">Rental details</div>
        </div>
      </>
    );
  };
  const addRentalInfo = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          {titleDislay()}
          {getRentalDetails()}
        </div>
      </div>
    );
  };
  return <>{datavalue !== 1 && addRentalInfo()}</>;
};
export default EndRentDetails;
