import * as axios from "../network/Network";

const config = require("../network/api.json");

class CustomerService {
  /**
   * Get all customers
   * @param {*} page 
   * @param {*} value 
   * @param {*} search 
   * @returns 
   */
  static getAllCustomers(page, value, search) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getCustomers}?page=${page}&searchby=${value}&value=${search}`,
    });
    return Promise.resolve(result);
  }

  /**
   * Add customer details
   * @param {*} fullname 
   * @param {*} email 
   * @param {*} mobileno 
   * @param {*} alternatemobileno 
   * @param {*} date_of_birth 
   * @param {*} adhar_number 
   * @param {*} drivinglicense_number 
   * @param {*} drivinglicense_expirydate 
   * @param {*} age 
   * @param {*} drivinglicense_frontsideimage 
   * @param {*} drivinglicense_backsideimage 
   * @param {*} id_frontside 
   * @param {*} id_backside 
   * @param {*} alternate_id 
   * @returns 
   */
  static addCustomerDetails(
    fullname,
    email,
    mobileno,
    alternatemobileno,
    date_of_birth,
    adhar_number,
    drivinglicense_number,
    drivinglicense_expirydate,
    age,
    drivinglicense_frontsideimage,
    drivinglicense_backsideimage,
    id_frontside,
    id_backside,
    alternate_id
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("fullname", fullname);
    bodyFormData.append("email", email);
    bodyFormData.append("mobileno", mobileno);
    bodyFormData.append("alternatemobileno", alternatemobileno);
    bodyFormData.append("date_of_birth", date_of_birth);
    bodyFormData.append("adhar_number", adhar_number);
    bodyFormData.append("drivinglicense_number", drivinglicense_number);
    bodyFormData.append("drivinglicense_expirydate", drivinglicense_expirydate);
    bodyFormData.append("age", age);
    bodyFormData.append(
      "drivinglicense_frontsideimage",
      drivinglicense_frontsideimage
    );
    bodyFormData.append(
      "drivinglicense_backsideimage",
      drivinglicense_backsideimage
    );
    bodyFormData.append("id_frontside", id_frontside);
    bodyFormData.append("id_backside", id_backside);
    bodyFormData.append("alternate_id", alternate_id);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addCustomerDetails,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  /**
   * Delete customer details
   * @param {*} id 
   * @returns 
   */
  static deleteCustomerDetails(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deleteCustomerDetails + `/${id}`,
    });
    return Promise.resolve(result);
  }
  /**
   * Edit customer details
   * @param {*} id 
   * @param {*} fullname 
   * @param {*} email 
   * @param {*} mobileno 
   * @param {*} alternatemobileno 
   * @param {*} date_of_birth 
   * @param {*} status 
   * @param {*} adhar_number 
   * @param {*} drivinglicense_number 
   * @param {*} drivinglicense_expirydate 
   * @param {*} age 
   * @param {*} drivinglicense_frontsideimage 
   * @param {*} drivinglicense_backsideimage 
   * @param {*} id_frontside 
   * @param {*} id_backside 
   * @param {*} alternate_id 
   * @returns 
   */
  static editCustomerDetails(
    id,
    fullname,
    email,
    mobileno,
    alternatemobileno,
    date_of_birth,
    status,
    adhar_number,
    drivinglicense_number,
    drivinglicense_expirydate,
    age,
    drivinglicense_frontsideimage,
    drivinglicense_backsideimage,
    id_frontside,
    id_backside,
    alternate_id
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("fullname", fullname);
    bodyFormData.append("email", email);
    bodyFormData.append("mobileno", mobileno);
    bodyFormData.append("alternatemobileno", alternatemobileno);
    bodyFormData.append("date_of_birth", date_of_birth);
    bodyFormData.append("status", status);
    bodyFormData.append("adhar_number", adhar_number);
    bodyFormData.append("drivinglicense_number", drivinglicense_number);
    bodyFormData.append("drivinglicense_expirydate", drivinglicense_expirydate);
    bodyFormData.append("age", age);
    bodyFormData.append(
      "drivinglicense_frontsideimage",
      drivinglicense_frontsideimage
    );
    bodyFormData.append(
      "drivinglicense_backsideimage",
      drivinglicense_backsideimage
    );
    bodyFormData.append("id_frontside", id_frontside);
    bodyFormData.append("id_backside", id_backside);
    bodyFormData.append("alternate_id", alternate_id);
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editCustomerDetails + `/${id}`,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }


  /**
   * Get customer details
   * @param {*} id 
   * @param {*} page 
   * @param {*} value 
   * @param {*} search 
   * @returns 
   */
  static getCustomersHistory(id, page, value, search) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getCustomerHistory}/${id}?page=${page}&searchby=${value}&value=${search}`,
    });
    return Promise.resolve(result);
  }
}
export { CustomerService };
