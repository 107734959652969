import { useState, useEffect } from "react";
import useExchangeRentalDetailsHook from "./useExchangeRentalDetailsHook";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { searchvehicle } from "../../../../../../../assets/images";
import { Calender } from "../../../../../../../assets/images";
import { car_popup } from "../../../../../../../assets/images";
import { useLocation, useNavigate } from "react-router";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Loading } from "../../../../../../../assets/images";

const AddRentalDetailsInfo = ({ setTab, tab, bookid }) => {
  const [rowinfo, setrowinfo] = useState("");
  let order = [];
  let obj = {};
  const [listTable, setListTable] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    setrowinfo(row);
    setdatavalue(3);
  };
  const {
    loader,
    submitted,
    listData,
    totalPages,
    datetimevalue,
    datetimeendvalue,
    rlocations,
    helmets_qty,
    travel,
    vehicleClass,
    datavalue,
    make,
    makeList,
    returnLocation,
    model,
    modelList,
    exchangeDate,
    vehicleRes,
    submit,
    oldVehicleStatus,
    response,
    pop,
    vehId,
    item,
    search,
    changeSearchHandler,
    changeItemHandler,
    handleExchangeDate,
    handlePageClick,
    SearchVehicle,
    setdatavalue,
    continueGetData,
    handleMake,
    handleModel,
    handleOldVehicleStatus,
    handleContinue,
    showPop,
  } = useExchangeRentalDetailsHook(setTab, tab, bookid, location, rowinfo);
  /**
   * table list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs.length > 0) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  let diffDays;
  let difference;
  if (datetimevalue != "" && datetimeendvalue !== "") {
    var date1 = new Date(datetimevalue);
    var date2 = new Date(datetimeendvalue);
    // diffDays = date2.getDate() - date1.getDate();
    const diffTime = Math.abs(date2 - date1);
    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  const addRentailInfo = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          <div className="Company_name flt">
            <div className="comp_name_details flt">Rental Details</div>
          </div>
          {loader === "loading" && (
            <div className="main_load ">
              <img src={Loading} alt="loader" />
            </div>
          )}
          {(loader === "isfull" ||
            loader === "isempty" ||
            loader === "notset") && (
              <>
                <div className="main_company flt">
                  <div className="main_row flt">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              Start Date-Start Time
                            </label>
                            <div className="main_cal_img">
                              <Datetime
                                dateFormat="DD/MM/YYYY"
                                value={new Date(datetimevalue)}
                                inputProps={{
                                  placeholder: "Select Date",
                                  disabled: true,
                                  readOnly: true,
                                  onKeyDown: (e) => e.preventDefault(),
                                }}
                              />
                              <div className="cal_img">
                                <img
                                  src={Calender}
                                  alt="calender"
                                  onChange={Datetime}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                          <label className="labelName">End Date-End Time <span className="numdays"> No of days : {diffDays ? diffDays : "0"}</span></label>
                            <div className="main_cal_img">
                              <Datetime
                                dateFormat="DD/MM/YYYY"
                                value={new Date(datetimeendvalue)}
                                inputProps={{
                                  placeholder: "Select Date",
                                  disabled: true,
                                  readOnly: true,
                                  onKeyDown: (e) => e.preventDefault(),
                                }}
                                icon={Calender}
                              />
                              <div className="cal_img">
                                <img
                                  src={Calender}
                                  alt="calender"
                                  onChange={Datetime}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">Pick Location</label>
                            <input
                              className="comp_input"
                              type="text"
                              placeholder="Pick Location"
                              name="rlocations"
                              id="Travel"
                              value={rlocations}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">Return Location</label>
                            <input
                              className="comp_input"
                              type="text"
                              placeholder="Return Location"
                              name="returnLocation"
                              id="returnLocation"
                              value={returnLocation}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">Helmet Qty</label>
                            <input
                              className="comp_input"
                              type="number"
                              placeholder="Helmet Qty"
                              name="helmets_qty"
                              id="helmets_qty"
                              value={helmets_qty}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">Travel Locations</label>
                            <input
                              className="comp_input"
                              type="text"
                              placeholder="Travel Locations"
                              name="Travel"
                              id="Travel"
                              value={travel}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">Vehicle Class</label>
                            <input
                              className="comp_input"
                              type="text"
                              placeholder="vehicleClass"
                              name="vehicleClass"
                              id="vehicleClass"
                              value={vehicleClass}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">Make *</label>
                            <select
                              name="Vehicle"
                              onChange={handleMake}
                              value={make}
                            >
                              <option value="">Make</option>
                              {makeList &&
                                makeList.map(({ _id, make }) => (
                                  <option value={_id}>{make}</option>
                                ))}
                            </select>
                          </div>
                          {submitted && !make && (
                            <div className="invalid-feedback">
                              Make is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">Model *</label>
                            <select
                              name="Vehicle"
                              onChange={handleModel}
                              value={model}
                            >
                              <option value="">Model</option>
                              {modelList &&
                                modelList.map(({ _id, model }) => (
                                  <option value={_id}>{model}</option>
                                ))}
                            </select>
                          </div>
                          {submitted && !model && (
                            <div className="invalid-feedback">
                              Model is required
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">Exchange Date *</label>

                            <Datetime
                              dateFormat="DD/MM/YYYY"
                              onChange={handleExchangeDate}
                              value={exchangeDate}
                              closeOnSelect={true}
                              timeFormat={false}
                              isValidDate={(currentDate, selectedDate) =>
                                currentDate.isAfter(
                                  new Date().getTime() - 24 * 60 * 60 * 1000
                                )
                              }
                              inputProps={{
                                placeholder: "Select date",
                                disabled: false,
                                readOnly: true,
                                onKeyDown: (e) => e.preventDefault(),
                              }}
                            />
                            <div className="cal_img">
                              <img src={Calender} alt="calender" />
                            </div>
                            {submit && !exchangeDate && (
                              <div className="req_field">
                                Exchange Date is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3"></div>
                      <div className="col-sm-3"></div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">
                        <div className="main_car_padding flt">
                          <div className="car_details flt">
                            Old Vehicle Details
                          </div>
                          <div className="details_car flt">
                            <div className="model_details flt">
                              <ul className="make_details flt">
                                <li>
                                  Reg Number<span>:</span>
                                </li>

                                <li>{vehicleRes?.data?.data?.regNumber}</li>
                                <li>
                                  Vehicle Class<span>:</span>
                                </li>

                                <li>{vehicleRes?.data?.data?.vehicleclass}</li>
                                <li>
                                  Make<span>:</span>
                                </li>

                                <li>{vehicleRes?.data?.data?.vehiclemake}</li>

                                <li>
                                  Model<span>:</span>
                                </li>

                                <li>{vehicleRes?.data?.data?.vehiclemodel}</li>

                                <li>
                                  Location<span>:</span>
                                </li>

                                <li>{vehicleRes?.data?.data?.location}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4"></div>
                      <div className="col-sm-4">
                        <div className="main_ownbutton showavailbtn flt">
                          <div className="own_button1 flt">
                            <button onClick={() => SearchVehicle()}>
                              <img src={searchvehicle} alt="upload" />
                              show Vehicle Available
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {datavalue === 3 && selectvehicles()}
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
    );
  };

  const columns = [
    {
      name: "Stock No",
      selector: (row) => row.srnumber,
    },
    {
      name: "Reg Number",
      selector: (row) => row.regNumber,
    },
    {
      name: "Make",
      selector: (row) => row.make,
    },
    {
      name: "Model",
      selector: (row) => row.model,
    },
    {
      name: "Vehicle Class",
      selector: (row) => row.vclass,
    },
    {
      name: "Location",
      selector: (row) => row.location,
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={
            row.status === "available" ? "button_style" : "button_style1"
          }
        >
          {row.status}
        </div>
      ),
    },
    {
      name: "Select Vehicle",
      selector: (row) => (
        <button className="viewdata" onClick={() => handleRow(row)}>
          Select
        </button>
      ),
    },
  ];

  /**
   * table list
   */
  const list = () => {
    listData.data.data.docs.map((item, index) => {
      obj = {
        srnumber:
          listData.data.data.limit * (listData.data.data.page - 1) + index + 1,
        make: item?.vehiclemake?.[0]?.make,
        model: item?.vehicalmodel?.[0]?.model,
        vclass: item?.vehicleclass?.[0]?.vehicleClass,
        location: item.location?.[0]?.locationName,
        status: item?.vehicle_status,
        regNumber: item?.regNumber,
        id: item?._id,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };



  const tableListDisplay = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          <div className="main_search_b2 booking_newb44 flt">
            <div className="ex_customer">  </div>

            <div className="filler"></div>
            <div className="search_l booking_searchn44">
              <select name="item" id="item" onChange={changeItemHandler}>
                <option default value="regNo">
                  Search by Reg No.
                </option>

              </select>
            </div>
            <div className="search_b1 booking_srchmm ">
              <input
                type="text"
                placeholder="Search.."
                name="search"
                onChange={changeSearchHandler}
                value={search}
              />
              <button className="search_button">
                <i class="fa fa-search"></i>
              </button>
            </div>



          </div>
          <div className="main_tablehead flt">
            <table className="main_table1 flt ">
              {listData?.data?.data?.docs?.length !== 0 ? (
                <DataTable
                  columns={columns}
                  data={listTable || []}
                  customStyles={customStyles}
                />)
                : (
                  <div className="no-data">No Data Found</div>
                )}
            </table>



            {listData?.data?.data?.docs.length !== 0 && (
              <div>
                <ReactPaginate
                  nextLabel="&rarr;"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  pageCount={totalPages}
                  previousLabel="&larr;"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
          </div>
        </div>
        <div className="ownbutton1 flt">
          <div className="own_button flt">
            <button className="submitbutton" onClick={() => setdatavalue(0)}>
              Back
            </button>
          </div>
        </div>
      </div>
    );
  };

  const selectvehicles = () => {
    return (
      <div className="main_car_details exchvehicle_details flt">
        <div className="row">
          <div className="col-sm-4">
            <div className="car_details flt">New Vehicle Details</div>
            <div className="details_car flt">
              <div className="model_details flt">
                <ul className="make_details flt">
                  <li>
                    Reg Number<span>:</span>
                  </li>

                  <li>{rowinfo?.regNumber}</li>
                  <li>
                    Vehicle Class<span>:</span>
                  </li>

                  <li>{rowinfo?.vclass}</li>
                  <li>
                    Make<span>:</span>
                  </li>

                  <li>{rowinfo?.make}</li>

                  <li>
                    Model<span>:</span>
                  </li>

                  <li>{rowinfo?.model}</li>

                  <li>
                    Location<span>:</span>
                  </li>

                  <li>{rowinfo?.location}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="main_ownbutton exch66_btn flt">
            <div className="own_button flt">
              <button onClick={() => handleContinue()} className="submitbutton">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ConfirmExchange = () => {
    return (
      <>
        <div className="modal1">
          <div className="modal" id="myModal">
            <div className="modal-dialog modal-md modal-dialog-centered">
              <div className="modal-content">
                <div className="close_btn flt">
                  <button
                    type="button"
                    className="close_button"
                    data-dismiss="modal"
                    onClick={() => showPop(false)}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="car_img flt">
                        <div className="pop_car flt">
                          <img src={car_popup} alt="popup" />
                        </div>

                        <div className="payment_success1 flt">
                          Update Old Vehicle Status
                        </div>

                        <div className="vehicle_due flt">
                          <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                Choose Old Vehicle Status
                              </label>
                              <div className="make_img flt">
                                <select
                                  name="Vehicle"
                                  id="lang"
                                  onChange={handleOldVehicleStatus}
                                  value={oldVehicleStatus}
                                >
                                  <option value="maintanance">
                                    Maintenance
                                  </option>
                                  <option value="available">Available</option>
                                  <option value="service">Service</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="book_st1 flt">
                            <div className="resend_otp2 flt">
                              <button
                                className="re_button12"
                                onClick={continueGetData}
                              >
                                Confirm Exchange
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const Confirmation = () => {
    return (
      <>
        <div className="modal1">
          <div className="modal" id="myModal">
            <div className="modal-dialog modal-md modal-dialog-centered">
              <div className="modal-content">
                <div className="close_btn flt">
                  <button
                    type="button"
                    className="close_button"
                    data-dismiss="modal"
                    onClick={() => {
                      showPop(false);
                    }}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="car_img flt">
                        <div className="pop_car flt">
                          <img src={car_popup} alt="pop" />
                        </div>

                        <div className="payment_success1 flt">
                          Successfully Exchanged
                        </div>

                        <div className="main_old_vehicle flt">
                          Your Old Vehicle&nbsp;
                          <span>
                            {vehicleRes?.data?.data?.vehiclemake}&nbsp;
                            {vehicleRes?.data?.data?.vehiclemodel}&nbsp;
                            {vehicleRes?.data?.data?.regNumber}&nbsp;
                          </span>
                          Succssfully Exchanged with &nbsp;
                          <span>
                            {rowinfo?.make}&nbsp; {rowinfo?.model}&nbsp;
                            {rowinfo?.regNumber}&nbsp;
                          </span>
                        </div>
                        <div className="book_st1 flt">
                          <div className="resend_otp2 flt">
                            <button
                              className="re_button12"
                              onClick={() => {
                                navigate(`?bid=${bookid}&vid=${vehId}`);
                                setTab(4);
                              }}
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {datavalue !== 1 && addRentailInfo()}
      {datavalue === 1 && tableListDisplay()}
      {pop && ConfirmExchange()}
      {response && Confirmation()}
    </>
  );
};
export default AddRentalDetailsInfo;
