import Menu from "../../../_common/menu/Menu";
import TopBar from "../../../_common/topbar/TopBar";
import { Calender } from "../../../../assets/images";
import { investor } from "../../../../assets/images";
import { Addinvestor } from "../../../../assets/images";
import "../Investor.css";
import useInvestorDetailsHook from "./useInvestorDetailsHook";
import { useLocation } from "react-router";
import { Loading } from "../../../../assets/images";
import { withTranslation } from "react-i18next";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const AddInvestor = (props) => {
  const { t } = props;
  const location = useLocation();

  const {
    handleInvestorName,
    handleMobile_no,
    handleEmail,
    handleAmount,
    handleStart_date,
    handlePeriod,
    handleBankName,
    handleBankHolderName,
    handleBankIfsc,
    handleBankAccountNumber,
    handleSubmit,
    navigateToInvestorList,
    handleDeleteSubmit,
    handleEditSubmit,
    id,
    loader,
    submitted,
    investorName,
    mobile,
    email,
    investmentAmount,
    invStartDate,
    invPeriod,
    bankName,
    bankHolderName,
    bankIfsc,
    bankAccountNumber,
  } = useInvestorDetailsHook(location, t);

  const investorDetails = () => {
    return (
      <>
        <div className="main_location flt">
          <div className="location flt">
            <img src={investor} alt="invest" />
            {t("INVESTORS")}
          </div>
        </div>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="Company_name flt">
              {id === "" && (
                <div className="comp_name_details flt">
                  <img src={Addinvestor} alt="invest" />
                  {t("Add_Investor")}
                </div>
              )}
              {id !== "" && (
                <>
                  <div className="comp_name_details flt">
                    <img src={Addinvestor} alt="invest" />
                    {t("Edit_Investor")}
                  </div>
                  <div className="delete_b flt">
                    <button className="delete_btn" onClick={handleDeleteSubmit}>
                      {t("Delete")}
                    </button>
                  </div>
                </>
              )}
            </div>
            {loader === "loading" && (
              <div className="main_load ">
                <img src={Loading} alt="loader" />
              </div>
            )}
            {(loader === "isfull" ||
              loader === "isempty" ||
              loader === "notset") && (
                <div className="main_company flt">
                  <div className="main_row flt">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className=" company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Investor_Name")} *
                            </label>
                            <input
                              type="text"
                              id="investorName"
                              name="investorName"
                              placeholder={t("Enter_Investor_Name")}
                              onChange={handleInvestorName}
                              value={investorName}
                            />
                            {submitted && !investorName && (
                              <div className="req_field">
                                {t("Investor_name_required")}
                              </div>
                            )}
                          </div>
                        </div>




                      </div>
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Mobile_Number")}{" "}
                            </label>
                            <input
                              type="number"
                              placeholder={t("Enter_Mobile_Number")}
                              name="mobile"
                              onChange={handleMobile_no}
                              id="mobile"
                              value={mobile}
                            />
                          </div>
                        </div>


                      </div>
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">{t("Email ")}</label>
                            <input
                              type="text"
                              placeholder={t("Enter_Email")}
                              name="email"
                              id="email"
                              onChange={handleEmail}
                              value={email}
                            />
                          </div>
                        </div>


                      </div>

                      <div className="col-sm-3"></div>

                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        <div className=" company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Investment_Start_Date")}
                            </label>

                            <Datetime
                              dateFormat="DD/MM/YYYY"
                              onChange={handleStart_date}
                              value={invStartDate}
                              closeOnSelect={true}
                              timeFormat={false}
                              inputProps={{
                                placeholder: "Select From date",
                                disabled: false,
                                readOnly: true,
                                onKeyDown: (e) => e.preventDefault(),
                              }}
                            />
                            <div className="cal_img">
                              <img src={Calender} alt="calender" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className=" company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Investment_Period")}{" "}
                            </label>
                            <input
                              type="text"
                              id="invPeriod"
                              name="invPeriod"
                              placeholder={t("Enter_Investment_Period")}
                              onChange={handlePeriod}
                              value={invPeriod}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">{t("Bank_Name")} </label>
                            <input
                              type="text"
                              placeholder={t("Enter_bank_name")}
                              name="bankName"
                              id="bankName"
                              onChange={handleBankName}
                              value={bankName}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3"></div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Bank_Holder_Name")}{" "}
                            </label>
                            <input
                              type="text"
                              placeholder={t("Enter_bank_holder_name")}
                              name="bankHolderName"
                              id="bankHolderName"
                              value={bankHolderName}
                              onChange={handleBankHolderName}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className=" company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">{t("Bank_No")} </label>
                            <input
                              type="number"
                              id="bankAccountNumber"
                              name="bankAccountNumber"
                              placeholder={t("Enter_bank_a/c_No")}
                              onChange={handleBankAccountNumber}
                              value={bankAccountNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Bank_IFSC_Code")}{" "}
                            </label>
                            <input
                              type="text"
                              placeholder={t("Enter_bank_IFSC_code")}
                              name="bankIfsc"
                              id="bankIfsc"
                              onChange={handleBankIfsc}
                              value={bankIfsc}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3"></div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Investment_Amount")}{" "}
                            </label>
                            <input
                              type="number"
                              placeholder={t("Enter investment Amount")}
                              name="investmentAmount"
                              onChange={handleAmount}
                              id="investmentAmount"
                              value={investmentAmount}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3"></div>
                      <div className="col-sm-3"></div>
                      <div className="col-sm-3"></div>
                    </div>
                    <div className="main_ownbutton flt">
                      <div className="own_button flt">
                        <button
                          className="backbutton"
                          onClick={navigateToInvestorList}
                        >
                          {t("Back")}
                        </button>
                        {id === "" && (
                          <button
                            className="submitbutton"
                            onClick={handleSubmit}
                          >
                            {t("Submit")}
                          </button>
                        )}
                        {id !== "" && (
                          <button
                            className="submitbutton"
                            onClick={handleEditSubmit}
                          >
                            {t("Update")}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      {investorDetails()}
    </div>
  );
};
export default withTranslation()(AddInvestor);
