import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { alertActions } from "../../../../actions";
import { CustomerService } from "../../../../services";
import { useDispatch } from "react-redux";
import moment from "moment";

const useCustomerDetails = (t) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fullname, setFullname] = useState(
    location?.state ? location?.state.row?.fullname : ""
  );
  const [email, setEmail] = useState(
    location?.state ? location?.state.row?.email : ""
  );
  const [mobileno, setMobileNo] = useState(
    location?.state ? location?.state.row?.mobileno : ""
  );
  const [alternatemobileno, setAlternateMobileno] = useState(
    location?.state ? location?.state.row?.alternatemobileno : ""
  );
  const [date_of_birth, setDate_of_birth] = useState(
    location?.state ? location?.state.row?.date_of_birth : ""
  );
  const [adhar_number, setAdhar_number] = useState(
    location?.state ? location?.state.row?.adhar_number : ""
  );
  const [drivinglicense_number, setDrivingLicense_number] = useState(
    location?.state ? location?.state.row?.drivinglicense_number : ""
  );
  const [drivinglicense_expirydate, setDrivingLicense_expirydate] = useState(
    location?.state ? location?.state.row?.drivinglicense_expirydate : ""
  );
  const [status, setStatus] = useState(
    location?.state ? location?.state?.row?.status : ""
  );
  const [alternate_id, setAlternate_id] = useState(
    location?.state ? location?.state?.row?.alternate_id : ""
  );

  const [id, setId] = useState(location?.state ? location?.state.row?.id : "");

  const [submit, setSubmit] = useState(false);
  const [selectedFile1, setSelectedFile1] = useState("");

  const [drivinglicense_frontsideimage, setDrivinglicense_frontsideimage] =
    useState(location?.state?.row?.drivinglicense_frontsideimage);
  const [fileName1, setFileName1] = useState("");

  const [selectedFile2, setSelectedFile2] = useState("");
  const [drivinglicense_backsideimage, setDrivinglicense_backsideimage] =
    useState(location?.state?.row?.drivinglicense_backsideimage);
  const [fileName2, setFileName2] = useState("");

  const [selectedFile3, setSelectedFile3] = useState("");
  const [id_frontside, setId_frontside] = useState(
    location?.state?.row?.id_frontside
  );
  const [fileName3, setFileName3] = useState("");

  const [selectedFile4, setSelectedFile4] = useState("");
  const [id_backside, setId_backside] = useState(
    location?.state?.row?.id_backside
  );
  const [fileName4, setFileName4] = useState("");
  const [loader, setLoader] = useState("notset");

  let age = 0;
  let ageDiff = "";
  if (date_of_birth !== "") {
    const today = new Date();

    const dateParts = date_of_birth.split("/");

    const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

    const diffTime = Math.abs(date - today);

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    ageDiff = Math.round((diffDays / 365) * 10) / 10;

    age = ageDiff > 1 ? ageDiff + "years" : ageDiff + "year";
  }

  /**
   * navigate to list page
   */
  const navigateToListPage = () => {
    navigate("/customers");
  };

  /**
   * handle image
   */
  useEffect(() => {
    if (!selectedFile1) {
      setDrivinglicense_frontsideimage(undefined);
      return;
    }

    const objectUrl1 = URL.createObjectURL(selectedFile1);

    setDrivinglicense_frontsideimage(objectUrl1);
    return () => URL.revokeObjectURL(objectUrl1);
  }, [selectedFile1]);

  useEffect(() => {
    if (!selectedFile2) {
      setDrivinglicense_backsideimage(undefined);
      return;
    }

    const objectUrl2 = URL.createObjectURL(selectedFile2);
    setDrivinglicense_backsideimage(objectUrl2);
    return () => URL.revokeObjectURL(objectUrl2);
  }, [selectedFile2]);

  useEffect(() => {
    if (!selectedFile3) {
      setId_frontside(undefined);
      return;
    }
    const objectUrl3 = URL.createObjectURL(selectedFile3);
    setId_frontside(objectUrl3);
    return () => URL.revokeObjectURL(objectUrl3);
  }, [selectedFile3]);

  useEffect(() => {
    if (!selectedFile4) {
      setId_backside(undefined);
      return;
    }

    const objectUrl4 = URL.createObjectURL(selectedFile4);
    setId_backside(objectUrl4);
    return () => URL.revokeObjectURL(objectUrl4);
  }, [selectedFile4]);

  /**
   * handle change full name
   * @param {*} e
   */
  const handleFullnameChange = (e) => {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setFullname(e.target.value);
    } else if (!e.target.value) {
      setFullname(e.target.value);
    }
  };
  /**
   * Hanlde alternate id
   * @param {*} e
   */
  const handleAlternateId = (e) => {
    setAlternate_id(e.target.value);
  };
  /**
   * handle birth date
   * @param {*} e
   */
  const handleDate_of_birthChange = (e) => {
    setDate_of_birth(moment(e).format("DD/MM/YYYY"));
  };
  /**
   * handle wmail change
   * @param {*} e
   */
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  /**
   * handle mobile no change
   * @param {*} e
   */
  const handleMobileNoChange = (e) => {
    setMobileNo(e.target.value);
  };
  /**
   * handle alternate mobile no change
   * @param {*} e
   */
  const handleAlternatemobilenoChange = (e) => {
    setAlternateMobileno(e.target.value);
  };
  /**
   * handle adhar no
   * @param {*} e
   */
  const handleAdhar_numberChange = (e) => {
    setAdhar_number(e.target.value);
  };
  /**
   * handle driving licence no
   * @param {*} e
   */
  const handleDrivingLicense_numberChange = (e) => {
    setDrivingLicense_number(e.target.value);
  };
  /**
   * handle expiry date
   * @param {*} e
   */
  const handleDrivingLicense_expirydateChange = (e) => {
    setDrivingLicense_expirydate(moment(e).format("DD/MM/YYYY"));
  };
  /**
   * handle accept checkbox
   * @param {*} e
   */
  const changeAcceptCheckboxHandler = (e) => {
    setStatus(e.target.checked ? "Blocklisted" : "Active");
  };
  /**
   * handle image change
   * @param {*} e
   * @returns
   */
  function onSelectFile1(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile1(undefined);
      return;
    }

    setFileName1(e.target.files[0].name);
    setSelectedFile1(e.target.files[0]);
  }
  function onSelectFile2(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile2(undefined);
      return;
    }

    setFileName2(e.target.files[0].name);
    setSelectedFile2(e.target.files[0]);
  }
  function onSelectFile3(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile3(undefined);
      return;
    }

    setFileName3(e.target.files[0].name);
    setSelectedFile3(e.target.files[0]);
  }
  function onSelectFile4(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile4(undefined);
      return;
    }

    setFileName4(e.target.files[0].name);
    setSelectedFile4(e.target.files[0]);
  }

  /**
   * delete api call
   * @param {*} e
   */
  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    CustomerService.deleteCustomerDetails(id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setSubmit(false);
          navigate("/customers");
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * add customer detail
   * @param {*} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

    if (
      fullname &&
      // email &&
      mobileno
      // date_of_birth &&
      // drivinglicense_number &&
      // drivinglicense_expirydate &&
      // age
      // selectedFile1 &&
      // selectedFile2 &&
      // selectedFile3 &&
      // selectedFile4
    ) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error(t("enter_valid_email")));
      } else if (mobileno && !filter.test(mobileno)) {
        dispatch(alertActions.error(t("enter_mobile_no")));
      } else if (adhar_number && !regex.test(adhar_number)) {
        dispatch(alertActions.error(t("enter_adhar_no")));
      } else if (alternatemobileno && !filter.test(alternatemobileno)) {
        dispatch(alertActions.error(t("enter_alt_no")));
      }
      // else if (ageDiff < 18) {
      //   dispatch(alertActions.error(t("age_must_be_greater_than_18")));
      // }
      else {
        setLoader("loading");
        CustomerService.addCustomerDetails(
          fullname,
          email,
          mobileno,
          alternatemobileno,
          date_of_birth,
          adhar_number,
          drivinglicense_number,
          drivinglicense_expirydate,
          age,
          // selectedFile1,
          // selectedFile2,
          // selectedFile3,
          // selectedFile4,
          alternate_id
        )
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setFullname("");
              setEmail("");
              setMobileNo("");
              setAlternateMobileno("");
              setDate_of_birth("");
              setAdhar_number("");
              setDrivingLicense_number("");
              setDrivingLicense_expirydate("");
              setSelectedFile1("");
              setSelectedFile2("");
              setSelectedFile3("");
              setSelectedFile4("");
              setAlternate_id("");
              setLoader("isfull");
              setSubmit(false);
              navigate("/customers");
              dispatch(alertActions.success(response?.data?.status?.message));
            } else {
              setLoader("isempty");
              dispatch(alertActions.error(response?.data?.status?.message));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  /**
   * update customer details api call
   * @param {*} e
   */
  const handleEditSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

    let select1;
    let select2;
    let select3;
    let select4;
    if (selectedFile1 === "") {
      select1 = location?.state?.row?.drivinglicense_frontsideimage;
    } else {
      select1 = selectedFile1;
    }
    if (selectedFile2 === "") {
      select2 = location?.state?.row?.drivinglicense_backsideimage;
    } else {
      select2 = selectedFile2;
    }
    if (selectedFile3 === "") {
      select3 = location?.state?.row?.id_frontside;
    } else {
      select3 = selectedFile3;
    }
    if (selectedFile4 === "") {
      select4 = location?.state?.row?.id_backside;
    } else {
      select4 = selectedFile4;
    }

    if (
      id &&
      fullname &&
      // email &&
      mobileno &&
      // date_of_birth &&
      // drivinglicense_number &&
      // drivinglicense_expirydate &&
      // age &&
      select1 &&
      select2 &&
      select3 &&
      select4
    ) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error(t("enter_valid_email")));
      } else if (mobileno && !filter.test(mobileno)) {
        dispatch(alertActions.error(t("enter_mobile_no")));
      } else if (adhar_number && !regex.test(adhar_number)) {
        dispatch(alertActions.error(t("enter_adhar_no")));
      } else if (alternatemobileno && !filter.test(alternatemobileno)) {
        dispatch(alertActions.error(t("enter_alt_no")));
      }
      //  else if (ageDiff < 18) {
      //   dispatch(alertActions.error(t("age_must_be_greater_than_18")));
      // }
      else {
        setLoader("loading");
        CustomerService.editCustomerDetails(
          id,
          fullname,
          email,
          mobileno,
          alternatemobileno,
          date_of_birth,
          status,
          adhar_number,
          drivinglicense_number,
          drivinglicense_expirydate,
          age,
          select1,
          select2,
          select3,
          select4,
          alternate_id
        )
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setFullname("");
              setEmail("");
              setMobileNo("");
              setAlternateMobileno("");
              setDate_of_birth("");
              setAdhar_number("");
              setDrivingLicense_number("");
              setDrivingLicense_expirydate("");
              setSelectedFile1("");
              setSelectedFile2("");
              setSelectedFile3("");
              setSelectedFile4("");
              setAlternate_id("");
              setStatus("");
              setLoader("isfull");
              setSubmit(false);
              navigate("/customers");
              dispatch(alertActions.success(response?.data?.status?.message));
            } else {
              setLoader("isempty");
              dispatch(alertActions.error(response?.data?.status?.message));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  return {
    navigateToListPage,
    handleFullnameChange,
    handleDate_of_birthChange,
    handleEmailChange,
    handleMobileNoChange,
    handleAlternatemobilenoChange,
    handleAdhar_numberChange,
    handleDrivingLicense_numberChange,
    handleDrivingLicense_expirydateChange,
    handleSubmit,
    onSelectFile1,
    onSelectFile2,
    onSelectFile3,
    onSelectFile4,
    changeAcceptCheckboxHandler,
    handleDeleteSubmit,
    handleEditSubmit,
    handleAlternateId,
    setId,
    loader,
    fullname,
    email,
    mobileno,
    alternatemobileno,
    date_of_birth,
    adhar_number,
    drivinglicense_number,
    drivinglicense_expirydate,
    age,
    fileName1,
    selectedFile1,
    drivinglicense_frontsideimage,
    fileName2,
    selectedFile2,
    drivinglicense_backsideimage,
    fileName3,
    selectedFile3,
    id_frontside,
    fileName4,
    selectedFile4,
    id_backside,
    status,
    id,
    submit,
    alternate_id,
  };
};
export default useCustomerDetails;
