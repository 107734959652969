import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { alertActions } from "../../../../../actions/alert.actions";
import { useDispatch } from "react-redux";
import { SetUpService } from "../../../../../services";

const useCompanyDetailsHook = () => {
  const [ownerName, setOwnerName] = useState("");
  const [vatId, setVatId] = useState("");
  const [city, setCity] = useState("udupi");
  const [country, setCountry] = useState("India");
  const [webPage, setWebPage] = useState("");
  const [address, setAddress] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [states, setStates] = useState("Karnataka");
  const [id, setId] = useState("");
  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState("notset");
  const [selectedFile1, setSelectedFile1] = useState("");
  const [logoImg, setLogoImg] = useState("");
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * call company details
   */
  useEffect(() => {
    displayCompanyDetails();
  }, []);
  /**
   * object url 
   * @param {*} e 
   * @returns 
   */
  useEffect(() => {
    if (!selectedFile1) {
      setLogoImg(undefined);
      return;
    }

    const objectUrl1 = URL.createObjectURL(selectedFile1);

    setLogoImg(objectUrl1);
    return () => URL.revokeObjectURL(objectUrl1);
  }, [selectedFile1]);

  /**
   * handle image
   */
  function onSelectFile1(e) {

    if (!e.target.files || e.target.files.length === 0) {
      setLogoImg(undefined);
      return;
    }

    setFileName(e.target.files[0].name);
    setSelectedFile1(e.target.files[0]);
  }
  /**
   * api call for company details
   */
  const displayCompanyDetails = () => {
    setLoader("loading");
    SetUpService.getCompanyDetails()
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setOwnerName(response?.data?.data?.[0]?.ownerName ? response?.data?.data?.[0]?.ownerName : "");
          setVatId(response?.data?.data?.[0]?.vat_tax_id_number ? response?.data?.data?.[0]?.vat_tax_id_number : "");
          setCity(response?.data?.data?.[0]?.city ? response?.data?.data?.[0]?.city : "udupi");
          setCountry(response?.data?.data?.[0]?.country ? response?.data?.data?.[0]?.country : "India");
          setWebPage(response?.data?.data?.[0]?.webPage ? response?.data?.data?.[0]?.webPage : "");
          setAddress(response?.data?.data?.[0]?.address ? response?.data?.data?.[0]?.address : "");
          setFax(response?.data?.data?.[0]?.faxNumber ? response?.data?.data?.[0]?.faxNumber : "");
          setEmail(response?.data?.data?.[0]?.email ? response?.data?.data?.[0]?.email : "");
          setAddressTwo(response?.data?.data?.[0]?.address2 ? response?.data?.data?.[0]?.address2 : "");
          setZip(response?.data?.data?.[0]?.zip_post_code ? response?.data?.data?.[0]?.zip_post_code : "");
          setPhone(response?.data?.data?.[0]?.phoneNumber ? response?.data?.data?.[0]?.phoneNumber : "");
          setBankAccount(response?.data?.data?.[0]?.bankAccount ? response?.data?.data?.[0]?.bankAccount : "");
          setCompanyName(response?.data?.data?.[0]?.companyName ? response?.data?.data?.[0]?.companyName : "");
          setStates(response?.data?.data?.[0]?.state ? response?.data?.data?.[0]?.state : "Karnataka");
          setId(response?.data?.data?.[0]?._id ? response?.data?.data?.[0]?._id : "");
          setLogoImg(response?.data?.data?.[0]?.logoImg ? response?.data?.data?.[0]?.logoImg : "")
          setLoader("isfull");
        }
      })
      .catch((err) => console.log(err));

  };



  /**
   * handle change
   * @param {*} e 
   */
  const handleCompanyName = (e) => {
    setCompanyName(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleStates = (e) => {
    setStates(e.target.value);
  };
  const handleFax = (e) => {
    setFax(e.target.value);
  };
  const handleWebPage = (e) => {
    setWebPage(e.target.value);
  };
  const handleOwnerName = (e) => {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setOwnerName(e.target.value);
    } else if (!e.target.value) {
      setOwnerName("");
    }
  };
  const handleAddressTwo = (e) => {
    setAddressTwo(e.target.value);
  };
  const handleZip = (e) => {
    setZip(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };
  const handleBank = (e) => {
    setBankAccount(e.target.value);
  };
  const handleVat = (e) => {
    setVatId(e.target.value);
  };
  const handleCity = (e) => {
    setCity(e.target.value);
  };
  const handleCountry = (e) => {
    setCountry(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  /**
   * navigate
   */
  const navigateToCompanyDetails = () => {
    navigate("/setup-company");
  };

  /**
  * handle add submit
  * @param {*} e 
  */
  const handleAddSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (

      companyName &&
      ownerName &&
      vatId &&
      city &&
      country &&
      address &&
      email &&
      zip &&
      phone &&
      states
    ) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error("Enter valid Email Address"));
      } else if (phone && !filter.test(phone)) {
        dispatch(alertActions.error("Enter valid Mobile Number"));
      } else if (zip && zip.length !== 6) {
        dispatch(alertActions.error("Enter valid Pincode number"));
      } else {
        setLoader("loading");
        SetUpService.addCompanyDetails(

          companyName,
          ownerName,
          vatId,
          city,
          country,
          webPage,
          address,
          fax,
          email,
          addressTwo,
          zip,
          phone,
          bankAccount,
          states,
          selectedFile1,
        )
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setLoader("isfull");
              setSubmit(false);
              navigate("/setup-company");
              dispatch(alertActions.success(response?.data?.status?.message));
            } else {
              setLoader("isempty");
              dispatch(alertActions.error(response?.data?.status?.message));
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  };

  /**
   * handle update submit
   * @param {*} e 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let select1

    if (selectedFile1 === "") {
      select1 = logoImg;
    } else {
      select1 = selectedFile1;
    }
    if (
      id &&
      companyName &&
      ownerName &&
      vatId &&
      city &&
      country &&
      address &&
      email &&
      zip &&
      phone &&
      states
    ) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error("Enter valid Email Address"));
      } else if (phone && !filter.test(phone)) {
        dispatch(alertActions.error("Enter valid Mobile Number"));
      } else if (zip && zip.length !== 6) {
        dispatch(alertActions.error("Enter valid Pincode number"));
      } else {
        setLoader("loading");
        SetUpService.updateCompanyDetails(
          id,
          companyName,
          ownerName,
          vatId,
          city,
          country,
          webPage,
          address,
          fax,
          email,
          addressTwo,
          zip,
          phone,
          bankAccount,
          states,
          select1
        )
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setLoader("isfull");
              setSubmit(false);
              navigate("/setup-company");
              dispatch(alertActions.success(response?.data?.status?.message));
            } else {
              setLoader("isempty");
              dispatch(alertActions.error(response?.data?.status?.message));
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  };
  return {
    loader,
    id,
    ownerName,
    vatId,
    city,
    country,
    webPage,
    address,
    fax,
    email,
    addressTwo,
    zip,
    phone,
    bankAccount,
    companyName,
    states,
    submit,
    logoImg,
    fileName,
    selectedFile1,
    onSelectFile1,
    handleAddSubmit,
    navigateToCompanyDetails,
    handleCompanyName,
    handleAddress,
    handleStates,
    handleFax,
    handleWebPage,
    handleOwnerName,
    handleAddressTwo,
    handleZip,
    handlePhone,
    handleBank,
    handleVat,
    handleCity,
    handleCountry,
    handleEmail,
    handleSubmit,
  };
};

export default useCompanyDetailsHook;
