import { useLocation } from "react-router";
import useDocuments from "./useDocuments";
import { Loading } from "../../../../../assets/images";
import { Calender } from "../../../../../assets/images";
import { upload } from "../../../../../assets/images";
import { useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const DocumentsTab = ({ tab, tabId, t }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const {
    loader,
    checkRes,
    insuranceCompanyName,
    insuranceNo,
    insuranceYear,
    insuranceType,
    insuranceAmount,
    insuranceStartDate,
    insuranceExpireDate,
    pucExpiryDate,
    fitnessExpiryDate,
    RCExpiryDate,
    permitExpiryDate,
    id,

    handleInsuranceComapanyName,
    handleInsuranceNo,
    handleInsuranceType,
    handleInsuranceAmount,
    handleInsuranceStartDate,
    handleInsuranceExpireDate,
    handleInsuranceYear,
    handlePucExpiryDate,
    handleFitnessExpiryExpiryDate,
    handleRCExpireDateExpiryDate,
    handlePermitExpiryDate,
    handleDocument,
    handleEditDocument,

    fileName1,
    fileName4,
    fileName5,
    fileName7,
    fileName8,
    showUpload,
    imageDisplay,
    pucExpiryImage,
    fitnessExpImage,
    rcExpImage,
    permitExpImage,
    insuranceExpImage,
    selectedFile1,
    selectedFile4,
    selectedFile5,
    selectedFile7,
    selectedFile8,
    typeDisplay,
    openUpload,
    closeUpload,
    onSelectFile1,
    onSelectFile4,
    onSelectFile5,
    onSelectFile7,
    onSelectFile8,
    showFile,
    handleDelete1,
    handleDelete4,
    handleDelete5,
    handleDelete7,
  } = useDocuments(location, tab, tabId);

  const documentDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {loader === "isfull" ||
          loader === "isempty" ||
          (loader === "notset" && (
            <>
              <div className="main_boxShadow flt">
                <div className="main_emission flt">
                  <div className="Company_name flt">
                    <div className="comp_name_details flt">
                      {t("Insurance_Details")}
                    </div>
                  </div>
                  <div className="main_company flt">
                    <div className="main_row flt">
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Company Name")}
                              </label>
                              <input
                                type="text"
                                placeholder="Company Name"
                                name="insuranceCompanyName"
                                id="insuranceCompanyName"
                                onChange={handleInsuranceComapanyName}
                                value={insuranceCompanyName}
                              />
                            </div>
                          </div>


                        </div>
                        <div className="col-sm-3">
                          <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Insurance_No")}
                              </label>
                              <input
                                type="text"
                                placeholder="Insurance No"
                                name="insuranceNo"
                                id="insuranceNo"
                                onChange={handleInsuranceNo}
                                value={insuranceNo}
                              />
                            </div>
                          </div>


                        </div>

                        <div className="col-sm-3">
                          <div className=" company_name vehicle_document flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Insurance_Start_Date")}
                              </label>

                              <Datetime
                                dateFormat="DD/MM/YYYY"
                                onChange={handleInsuranceStartDate}
                                value={insuranceStartDate}
                                closeOnSelect={true}
                                timeFormat={false}
                                inputProps={{
                                  placeholder: "Select From date",
                                  disabled: false,
                                  readOnly: true,
                                  onKeyDown: (e) => e.preventDefault(),
                                }}
                              />
                              <div className="cal_img">
                                <img src={Calender} alt="calender" />
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="col-sm-3">
                          <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">{t("Year")}</label>
                              <select
                                name="year"
                                id="insuranceYear"
                                value={insuranceYear}
                                onChange={handleInsuranceYear}
                              >
                                {" "}
                                <option default value="">
                                  {t("Select_year")}
                                </option>
                                {Array.apply(0, Array(99)).map(
                                  (item, index) => {
                                    return (
                                      <option value={2000 + index + 1}>
                                        {2000 + index + 1}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-3">  <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Insurance_Type")}
                            </label>
                            <input
                              className="comp_input"
                              type="text"
                              placeholder="Insurance Type"
                              name="insuranceType"
                              id="insuranceType"
                              onChange={handleInsuranceType}
                              value={insuranceType}
                            />
                          </div>
                        </div></div>
                        <div className="col-sm-3"> <div className=" company_name vehicle_document flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Insurance_Expire_Date")}
                            </label>

                            <Datetime
                              dateFormat="DD/MM/YYYY"
                              onChange={handleInsuranceExpireDate}
                              value={insuranceExpireDate}
                              closeOnSelect={true}
                              timeFormat={false}
                              inputProps={{
                                placeholder: "Select From date",
                                disabled: false,
                                readOnly: true,
                                onKeyDown: (e) => e.preventDefault(),
                              }}
                            />
                            <div className="cal_img">
                              <img src={Calender} alt="calender" />
                            </div>
                          </div>
                        </div></div>
                        <div className="col-sm-3"><div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("Insurance_Amount")}
                            </label>
                            <input
                              className="comp_input"
                              type="number"
                              placeholder="Insurance Amount"
                              name="insuranceAmount"
                              id="insuranceAmount"
                              onChange={handleInsuranceAmount}
                              value={insuranceAmount}
                            />
                          </div>
                        </div></div>
                        <div className="col-sm-3"> <div className=" company_name flt">
                          <div className="form-group flt">
                            <div className="jpg_link flt">
                              <label className="labelName">
                                {t("Insurance_Paper_Upload")}
                              </label>
                              {insuranceExpImage !== "" && (
                                <label
                                  className="labelName3"
                                  onClick={() => {
                                    openUpload();
                                    showFile("insuranceExpImage");
                                  }}
                                >
                                  {selectedFile8
                                    ? fileName8
                                    : insuranceExpImage?.split("/")[2]}
                                </label>
                              )}
                            </div>

                            <div className="make_img flt">
                              <button className="up_button">
                                <img src={upload} alt="upload" />
                                <input
                                  type="file"
                                  name="myfile8"
                                  onChange={onSelectFile8}
                                  key={fileName8}
                                  id="myfile1"
                                  accept="image/png,image/jpeg,application/pdf"
                                />
                              </button>
                            </div>
                          </div>
                        </div></div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="E_comp_details12 flt ">
                <div className="main_boxShadow flt">
                  <div className="main_emission flt">
                    <div className="Company_name flt">
                      <div className="comp_name_details flt">
                        {t("Document_Expiry_Details")}
                      </div>
                    </div>
                    <div className="main_company flt">
                      <div className="main_row flt">
                        <div className="row">
                          <div className="col-sm-3">
                            <div className=" company_name vehicle_docexpiry flt">
                              <div className="form-group flt">
                                <div className="jpg_link flt">
                                  <label className="labelName">
                                    {t("PUC_expiry_Date")}
                                  </label>
                                  {pucExpiryImage !== "" && (
                                    <label
                                      className="labelName3"
                                      onClick={() => {
                                        openUpload();
                                        showFile("pucExpiryImage");
                                      }}
                                    >
                                      {selectedFile1
                                        ? fileName1
                                        : pucExpiryImage?.split("/")[2]}
                                    </label>
                                  )}
                                </div>

                                <div className="make_img flt">
                                  <Datetime
                                    dateFormat="DD/MM/YYYY"
                                    onChange={handlePucExpiryDate}
                                    value={pucExpiryDate}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    inputProps={{
                                      placeholder: "Select From date",
                                      disabled: false,
                                      readOnly: true,
                                      onKeyDown: (e) => e.preventDefault(),
                                    }}
                                  />
                                  <div className="cal_img">
                                    <img src={Calender} alt="calender" />
                                  </div>

                                  <button className="up_button">
                                    <img src={upload} alt="upload" />
                                    <input
                                      type="file"
                                      name="myfile1"
                                      onChange={onSelectFile1}
                                      key={fileName1}
                                      id="myfile1"
                                      accept="image/png,image/jpeg,application/pdf"
                                    />
                                  </button>
                                  <button
                                    className="up_button1"
                                    onClick={handleDelete1}
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className=" company_name vehicle_docrcexpiry flt">
                              <div className="form-group flt">
                                <div className="jpg_link flt">
                                  <label className="labelName">
                                    {t("RC_Expiry_Date")}
                                  </label>
                                  {rcExpImage !== "" && (
                                    <label
                                      className="labelName3"
                                      onClick={() => {
                                        openUpload();
                                        showFile("rcExpiryImage");
                                      }}
                                    >
                                      {selectedFile5
                                        ? fileName5
                                        : rcExpImage?.split("/")[2]}
                                    </label>
                                  )}
                                </div>

                                <div className="make_img flt">
                                  <Datetime
                                    dateFormat="DD/MM/YYYY"
                                    onChange={handleRCExpireDateExpiryDate}
                                    value={RCExpiryDate}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    inputProps={{
                                      placeholder: "Select From date",
                                      disabled: false,
                                      readOnly: true,
                                      onKeyDown: (e) => e.preventDefault(),
                                    }}
                                  />
                                  <div className="cal_img">
                                    <img src={Calender} alt="calender" />
                                  </div>
                                  <button className="up_button">
                                    <img src={upload} alt="upload" />
                                    <input
                                      type="file"
                                      name="myfile5"
                                      onChange={onSelectFile5}
                                      key={fileName5}
                                      id="myfile5"
                                      accept="image/png,image/jpeg,application/pdf"
                                    />
                                  </button>
                                  <button
                                    className="up_button1"
                                    onClick={handleDelete5}
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className=" company_name vehicle_docexpiry flt">
                              <div className="form-group flt">
                                <div className="jpg_link flt">
                                  <label className="labelName">
                                    {t("Fitness_Expiry_Date")}
                                  </label>
                                  {fitnessExpImage !== "" && (
                                    <label
                                      className="labelName3"
                                      onClick={() => {
                                        openUpload();
                                        showFile("fitnessExpiryImage");
                                      }}
                                    >
                                      {selectedFile4
                                        ? fileName4
                                        : fitnessExpImage?.split("/")[2]}
                                    </label>
                                  )}
                                </div>

                                <div className="make_img flt">
                                  <Datetime
                                    dateFormat="DD/MM/YYYY"
                                    onChange={handleFitnessExpiryExpiryDate}
                                    value={fitnessExpiryDate}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    inputProps={{
                                      placeholder: "Select From date",
                                      disabled: false,
                                      readOnly: true,
                                      onKeyDown: (e) => e.preventDefault(),
                                    }}
                                  />
                                  <div className="cal_img">
                                    <img src={Calender} alt="calender" />
                                  </div>

                                  <button className="up_button">
                                    <img src={upload} alt="upload" />
                                    <input
                                      type="file"
                                      name="myfile4"
                                      onChange={onSelectFile4}
                                      key={fileName4}
                                      id="myfile4"
                                      accept="image/png,image/jpeg,application/pdf"
                                    />
                                  </button>
                                  <button
                                    className="up_button1"
                                    onClick={handleDelete4}
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className=" company_name vehicle_docrcexpiry flt">
                              <div className="form-group flt">
                                <div className="jpg_link flt">
                                  <label className="labelName">
                                    {t("Permit_Expiry_Date")}
                                  </label>
                                  {permitExpImage !== "" && (
                                    <label
                                      className="labelName3"
                                      onClick={() => {
                                        openUpload();
                                        showFile("permitExpImage");
                                      }}
                                    >
                                      {selectedFile7
                                        ? fileName7
                                        : permitExpImage?.split("/")[2]}
                                    </label>
                                  )}
                                </div>

                                <div className="make_img flt">
                                  <Datetime
                                    dateFormat="DD/MM/YYYY"
                                    onChange={handlePermitExpiryDate}
                                    value={permitExpiryDate}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    inputProps={{
                                      placeholder: "Select From date",
                                      disabled: false,
                                      readOnly: true,
                                      onKeyDown: (e) => e.preventDefault(),
                                    }}
                                  />
                                  <div className="cal_img">
                                    <img src={Calender} alt="calender" />
                                  </div>

                                  <button className="up_button">
                                    <img src={upload} alt="upload" />
                                    <input
                                      type="file"
                                      name="myfile7"
                                      onChange={onSelectFile7}
                                      key={fileName7}
                                      id="myfile7"
                                      accept="image/png,image/jpeg,application/pdf"
                                    />
                                  </button>
                                  <button
                                    className="up_button1"
                                    onClick={handleDelete7}
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="main_Submit_btn flt">
                            <div className="own_button flt">
                              {(checkRes === true || id !== "") && (
                                <button
                                  className="backbutton"
                                  onClick={() => tab(1)}
                                >
                                  {t("Back")}
                                </button>
                              )}
                              {checkRes === false && (
                                <button
                                  className="submitbutton"
                                  onClick={(e) => {
                                    handleDocument(e, tabId);
                                  }}
                                >
                                  {t("Save And Continue")}
                                </button>
                              )}
                              {checkRes === true && (
                                <button
                                  className="submitbutton"
                                  onClick={(e) => {
                                    handleEditDocument(e, tabId);
                                  }}
                                >
                                  {t("Update_Continue")}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showUpload && (
                      <div className="modal1">
                        <div className="modal" id="myModal">
                          <div className="modal-dialog   modal-lg modal-dialog-centered">
                            <div className="modal-content">
                              <div className="close_btn flt">
                                {imageDisplay.startsWith("/upload") && (
                                  <a
                                    href={`${process.env.REACT_APP_MEDIA_DOMAIN}${imageDisplay}`}
                                    className="download_button"
                                  >
                                    <i class="fas fa-download" />
                                    {t("Download")}
                                  </a>
                                )}

                                <button
                                  type="button"
                                  className="close_button"
                                  data-dismiss="modal"
                                  onClick={closeUpload}
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>

                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="car_img flt">
                                      <div className="vehicle_due flt">
                                        {typeDisplay !== "pdf" && (
                                          <>
                                            {imageDisplay.startsWith(
                                              "/upload"
                                            ) && (
                                                <img
                                                  src={`${process.env.REACT_APP_MEDIA_DOMAIN}${imageDisplay}`}
                                                  alt="upload"
                                                />
                                              )}
                                            {!imageDisplay.startsWith(
                                              "/upload"
                                            ) &&
                                              "/upload" && (
                                                <img
                                                  src={`${imageDisplay}`}
                                                  alt="upload"
                                                />
                                              )}
                                          </>
                                        )}
                                        {typeDisplay === "pdf" && (
                                          <>
                                            {imageDisplay.startsWith(
                                              "/upload"
                                            ) && (
                                                <>
                                                  {loading ? (
                                                    <div className="loading flt">
                                                      {t("Loading")}
                                                    </div>
                                                  ) : null}
                                                  <iframe
                                                    src={`https://docs.google.com/viewer?url=${process.env.REACT_APP_MEDIA_DOMAIN}${imageDisplay}&embedded=true`}
                                                    frameborder="0"
                                                    height="500px"
                                                    width="100%"
                                                    title="Download"
                                                    onLoad={() => {
                                                      setLoading(false);
                                                    }}
                                                  ></iframe>
                                                </>
                                              )}
                                            {!imageDisplay.startsWith(
                                              "/upload"
                                            ) &&
                                              "/upload" && (
                                                <>
                                                  <iframe
                                                    src={imageDisplay}
                                                    frameborder="0"
                                                    height="500px"
                                                    width="100%"
                                                    title="Download"
                                                  ></iframe>
                                                </>
                                              )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ))}
      </>
    );
  };
  return <>{documentDetails()}</>;
};
export default DocumentsTab;
