import { Calender } from "../../../../../../../../assets/images";
import usePersonalInfoHoook from "./usePersonalInfoHoook";
import { useLocation } from "react-router";
import { Loading } from "../../../../../../../../assets/images";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const AddPersonalInfo = ({
  displayData,
  tab,
  bookingid,
  setbookingid,
  cid,
  t,
}) => {
  const location = useLocation();

  const {
    date_of_birth,
    name,
    email,
    mblno,
    altno,
    submitted,
    loader,
    ContinueInfo,
    validname,
    validemail,
    validmblno,
    validaltno,
    validdate_of_birth,
    handleChange,
    handleChange1,
  } = usePersonalInfoHoook(
    location,
    displayData,
    tab,
    bookingid,
    setbookingid,
    cid,
    t
  );

  var names = validname();
  var emails = validemail();
  var mbls = validmblno();
  var altnum = validaltno();
  var datebirth = validdate_of_birth();

  /**
   * age calculation
   */
  let age = 0;
  if (date_of_birth !== "" && date_of_birth !== undefined) {
    const today = new Date();
    const arr = date_of_birth?.split("/");
    const date = new Date(arr[2], arr[1] - 1, arr[0]);

    const diffTime = Math.abs(date - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    age = Math.round((diffDays / 365) * 10) / 10 + "years";
  }

  const addPersonal = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          <div className="Company_name flt">
            <div className="comp_name_details flt">{t("add_personal")}</div>
          </div>
          {loader === "loading" && (
            <div className="main_load ">
              <img src={Loading} alt="loader" />
            </div>
          )}
          {(loader === "isfull" ||
            loader === "isempty" ||
            loader === "notset") && (
            <>
              <div className="main_company flt">
                <div className="main_row flt">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Full_Name")} *
                          </label>
                          <input
                            type="text"
                            placeholder={t("Name")}
                            name="name"
                            id="email"
                            onChange={handleChange}
                            value={name}
                            className={
                              submitted && names === false ? " is-invalid" : ""
                            }
                          />
                        </div>
                        {submitted && names === false && (
                          <div className="invalid-feedback">
                            {t("Full_Name_required")}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Email_ID")} </label>
                          <input
                            type="text"
                            placeholder={t("Email_ID")}
                            name="email"
                            id="email"
                            onChange={handleChange}
                            value={email}
                            className={
                              submitted && emails === false ? " is-invalid" : ""
                            }
                          />
                        </div>
                        {/* {submitted && emails === false && (
                            <div className="invalid-feedback">
                              {t("Email_ID_required")}
                            </div>
                          )} */}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Mobile_Number")} *
                          </label>
                          <input
                            type="number"
                            placeholder={t("Mobile_Number")}
                            name="mblno"
                            id="email"
                            onChange={handleChange}
                            value={mblno}
                            className={
                              submitted && mbls === false ? " is-invalid" : ""
                            }
                          />
                        </div>
                        {submitted && mbls === false && (
                          <div className="invalid-feedback">
                            {t("Mobile_Number_required")}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Alternative_Mobile_Number")}
                          </label>
                          <input
                            type="number"
                            placeholder={t("Alternative_Mobile_Number")}
                            name="altno"
                            id="email"
                            onChange={handleChange}
                            value={altno}
                            className={
                              submitted && altnum === false ? " is-invalid" : ""
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className=" company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Date_of_Birth")}
                          </label>

                          <Datetime
                            dateFormat="DD/MM/YYYY"
                            onChange={handleChange1}
                            value={date_of_birth}
                            closeOnSelect={true}
                            timeFormat={false}
                            inputProps={{
                              placeholder: t("Select_From_date"),
                              disabled: false,
                              readOnly: true,
                              onKeyDown: (e) => e.preventDefault(),
                            }}
                          />
                          <div className="cal_img">
                            <img src={Calender} alt="calender" />
                          </div>
                        </div>
                        {/* {submitted && datebirth === false && (
                            <div className="invalid-feedback">
                              {t("Date_of_Birth_required")}
                            </div>
                          )} */}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className=" company_name flt">
                        <div className="agedata flt">
                          <span>
                            {t("Age")} : {age ? age : "0"}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="main_ownbutton flt">
                      <div className="own_button add_personbtn flt">
                        <button onClick={ContinueInfo} className="submitbutton">
                          {t("Continue")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };
  return <div>{addPersonal()}</div>;
};
export default AddPersonalInfo;
