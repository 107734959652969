import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Menu from "../../../../_common/menu/Menu";
import TopBar from "../../../../_common/topbar/TopBar";
import { services } from "../../../../../assets/images";
import useVehicleExpiration from "./useMaintainanceAlert";
import "../../Vehicles.css";
import { Loading } from "../../../../../assets/images";
import { withTranslation } from "react-i18next";

const MaintainanceAlertList = (props) => {
  const { t } = props;
  const {
    listData,
    totalPages,
    page,
    loader,
    columns,
    listTable,
    search,
    changeItemHandler,
    changeSearchHandler,
    handlePageClick,
  } = useVehicleExpiration(props);

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const titleDisplay = () => {
    return (
      <>
        <div className="main_location flt">
          <div className="location flt">
            {" "}
            <img src={services} alt="vehicle" />
            {t("Oil Change Alert Lists")}
          </div>
          <div className="main_search_b1 flt">
            <div className="main_search_dd1">
              <div className="search_l ">
                <select name="item" id="item" onChange={changeItemHandler}>
                  <option default value="regNumber">
                    {t("Search by Reg. No.")}
                  </option>
                  {/* <option value="model">{t("Search by Model")}</option> */}
                </select>
              </div>
              <div className="search_b1 ">
                <input
                  type="text"
                  placeholder={t("Search..")}
                  name="search"
                  value={search}
                  onChange={changeSearchHandler}
                />
                <button className="search_button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const tableDisplay = () => {
    return (
      <>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="table_loader ">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  {listData?.data?.data?.docs?.length !== 0 ? (
                    <DataTable
                      columns={columns}
                      data={listTable || []}
                      customStyles={customStyles}
                    />
                  ) : (
                    <div className="no-data">{t("No Data Found")}</div>
                  )}
                  {listData?.data?.data?.docs.length !== 0 && (
                    <div>
                      <ReactPaginate
                        nextLabel="&rarr;"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalPages}
                        previousLabel="&larr;"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        currentPage={page}
                        forcePage={page - 1}
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  )}{" "}
                </table>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="header_location flt">
      <div className="main_container flt">
        <TopBar />
        <Menu />

        {titleDisplay()}
        {tableDisplay()}
      </div>
    </div>
  );
};
export default withTranslation()(MaintainanceAlertList);
