import Menu from "../../../_common/menu/Menu";
import TopBar from "../../../_common/topbar/TopBar";
import "../Daytask.css";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import sideday from "../../../../assets/images/Sidedaytask.png";
import useDayToDayTaskListHook from "./useDayToDayTaskListHook";
import { Loading } from "../../../../assets/images";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const DayTask = (props) => {
  const { t } = props;
  const {
    navigateToAddTask,
    listData,
    totalPages,
    search,
    page,
    loader,
    columns,
    listTable,
    handlePageClick,
    handleRow,
    changeItemHandler,
    changeSearchHandler,
  } = useDayToDayTaskListHook(props);
  const dashBoardDisplay = useSelector((state) => state?.userData?.user?.dashboardUA)


  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };
  const customStyles1 = {
    rows: {
      style: {
        cursor: "auto",

      },
    },
  };


  const searchDEtails = () => {
    return (
      <>
        <div className="main_location daytoday_main flt">
          <div className="location">
            <img src={sideday} alt="sideday" />
            {t("Day to Day Task")}
          </div>
          <div className="filler"></div>
          <div className="main_search_dd daytoday_search_dd">
            <div className="search_l ">
              <select name="item" id="item" onChange={changeItemHandler}>
                <option default value="vehiclePlateNumber">
                  {t("Search by Plate No.")}
                </option>
              </select>
            </div>
            <div className="search_b1 ">
              <input
                type="text"
                placeholder={t("Search..")}
                name="search"
                value={search}
                onChange={changeSearchHandler}
              />
              <button className="search_button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>

          <div className="fliter_button2 daytoday_btn">
            <button
              onClick={() => {
                navigateToAddTask();
              }}
            >
              <i class="fas fa-user-plus"></i>
              {t("Add Task")}
            </button>
          </div>

        </div>
      </>
    )
  }

  const tableDisplay = () => {
    return (
      <>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="table_loader ">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  <tr className="table_head2">
                    {listData?.data?.data?.docs.length !== 0 ? (
                      <> {dashBoardDisplay?.includes("dayToDayTask") ?
                        <DataTable
                          columns={columns}
                          data={listTable || []}
                          onRowClicked={(row) => handleRow(row)}
                          customStyles={customStyles}
                        /> : <DataTable
                          columns={columns}
                          data={listTable || []}
                          customStyles={customStyles1}
                        />}</>
                    ) : (
                      <div className="no-data">{t("No Data Found")}</div>
                    )}
                  </tr>

                  {listData?.data?.data?.docs.length !== 0 && (
                    <div>
                      <ReactPaginate
                        nextLabel="&rarr;"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalPages}
                        previousLabel="&larr;"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        currentPage={page}
                        forcePage={page - 1}
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  )}
                </table>
              )}
            </div>
          </div>
        </div></>
    )
  }

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />

      {searchDEtails()}
      {tableDisplay()}


    </div>
  );
};
export default withTranslation()(DayTask);
