import { useEffect, useState } from "react";
import { VehicleService } from "../../../../../services/VehicleService";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const useMaintainanceAlert = (props) => {
  const { t } = props;
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState("notset");
  const navigate = useNavigate();
  const [listTable, setListTable] = useState([]);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("regNumber");
  const dashBoardDisplay = useSelector((state) => state?.userData?.user?.dashboardUA)

  const columns = [
    {
      name: "Sr",
      selector: (row) => row.number,
      grow: 0,
      width: "80px",
    },
    {
      name: "Reg No",
      selector: (row) => (row.regNumber ? row.regNumber : "N/A"),
      width: "180px",
    },

    {
      name: "Odometer",
      selector: (row) => (row.ODOMeterNo ? row.ODOMeterNo : "N/A"),
      width: "180px",
    },

    {
      name: "KM At Last Service",
      selector: (row) =>
        row.kms_at_last_service ? row.kms_at_last_service : "N/A",
      width: "200px",
    },
    {
      name: "Service Interval KM",
      selector: (row) =>
        row.Service_Interval_Km ? row.Service_Interval_Km : "N/A",
      width: "200px",
    },
    {
      name: "Last Service Date",
      selector: (row) =>
        row.maintanance_Last_Service_Date
          ? moment(row.maintanance_Last_Service_Date).format("DD/MM/YYYY")
          : "N/A",
      width: "200px",
    },

    {
      name: "Next Service Date",
      selector: (row) =>
        row.maintanance_Next_Service_Date
          ? moment(row.maintanance_Next_Service_Date).format("DD/MM/YYYY")
          : "N/A",
      width: "200px",
    },
    {
      name: "View",
      selector: (row) => (
        dashBoardDisplay?.includes("oilChangeAlert") ?
          <button className="viewdata" onClick={() => handleRow(row)}>
            {t("View")}
          </button> : <button style={{ pointerEvents: "none", opacity: "0.5" }} className="viewdata" onClick={() => handleRow(row)}>
            {t("View")}
          </button>
      ),
      width: "200px",
    },
  ];
  let order = [];
  let obj = {};

  /**
   * list response call
   */
  useEffect(() => {
    vehicleDocResponse(page, item, search);
  }, []);

  /**
   * table list call
   */

  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * search
   * @param {*} e 
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    vehicleDocResponse(page, item, e.target.value);
  };
  /**
   * change item handler
   */
  /**
 * change handler
 * @param {*} e 
 */
  const changeItemHandler = (e) => {
    setSearch("");
    if (e.target.value === "model") {
      setItem(e.target.value);
      vehicleDocResponse(1, e.target.value, "");
    } else {
      setItem(e.target.value);
      vehicleDocResponse(1, e.target.value, "");
    }
  };
  /**
   * table list
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      obj = {
        id: item?._id,
        number:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        ODOMeterNo: item?.ODOMeterNo ? item?.ODOMeterNo : "N/A",
        Service_Interval_Km: item?.maintananceInfo?.maintanance
          ?.Service_Interval_Km
          ? item?.maintananceInfo?.maintanance?.Service_Interval_Km
          : "N/A",
        kms_at_last_service: item?.maintananceInfo?.maintanance
          ?.kms_at_last_service
          ? item?.maintananceInfo?.maintanance?.kms_at_last_service
          : "N/A",
        maintanance_Last_Service_Date:
          item?.maintananceInfo?.maintanance?.maintanance_Last_Service_Date !==
            null ||
            item?.maintananceInfo?.maintanance?.maintanance_Last_Service_Date !==
            ""
            ? item?.maintananceInfo?.maintanance?.maintanance_Last_Service_Date
            : "N/A",
        maintanance_Next_Service_Date:
          item?.maintananceInfo?.maintanance?.maintanance_Next_Service_Date !==
            null ||
            item?.maintananceInfo?.maintanance?.maintanance_Next_Service_Date !==
            ""
            ? item?.maintananceInfo?.maintanance?.maintanance_Next_Service_Date
            : "N/A",
        regNumber: item?.regNumber,
      };
      return order.push(obj);
    });
    if (order.length) setListTable(order);
  };
  /**
   * list api call
   * @param {*} page 
   */
  const vehicleDocResponse = (page, search, value) => {
    setLoader("loading");
    VehicleService.getMaintainanceAlertList(page, search, value)
      .then((response) => {
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        setLoader("isfull");
      })
      .catch((err) => console.log(err));
  };


  /**
   * handle row
   * @param {*} row 
   */
  const handleRow = (row) => {
    navigate(`/vehicle-details?vid=${row.id}`, {
      state: {
        row: row,
      },
    });
  };

  /**
   * handle page change
   * @param {*} e 
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    vehicleDocResponse(e.selected + 1);
  };

  return {
    listData,
    totalPages,
    page,
    loader,
    columns,
    listTable,
    handlePageClick,
    changeSearchHandler,
    changeItemHandler

  };
};
export default useMaintainanceAlert;
