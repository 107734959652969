import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { alertActions } from "../../../../actions";
import { DayToDayService } from "../../../../services";
import moment from "moment";

const useDayToDayTaskListHook = (props) => {
  const { t } = props;
  let [page, setPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("vehiclePlateNumber");
  const [listTable, setListTable] = useState([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState("notset");
  const navigate = useNavigate();
  const dashBoardDisplay = useSelector((state) => state?.userData?.user?.dashboardUA)
  let order = [];
  let obj = {};
  const columns = [
    {
      name: t("Sr_No"),
      selector: (row) => row.number,
      width: "80px"
    },
    {
      name: t("Date"),
      selector: (row) => row.date,
      width: "150px"
    },
    {
      name: t("Vehicle_Number_Plate"),
      selector: (row) => row.vehicleName,
      width: "180px"
    },

    {
      name: t("Description"),
      selector: (row) => row.description,
      width: "250px"
    },
    {
      name: t("Assigned_To"),
      selector: (row) => row.assignedTo,
      width: "180px"
    },
    {
      name: t("Completed"),
      selector: (row) => row.completed,
      width: "150px"
    },
    {
      name: t("Delete"),
      selector: (row) => (
        dashBoardDisplay?.includes("dayToDayTask") ?
          <button className="viewdata" onClick={() => deleteDayTasks(row)}>
            {t("Delete")}
          </button> : <button style={{ pointerEvents: "none", opacity: "0.5" }} className="viewdata" onClick={() => deleteDayTasks(row)}>
            {t("Delete")}
          </button>
      ),
      width: "150px"
    },
  ];
  /**
   * response call
   */
  useEffect(() => {
    dayTasksResponse(page, item, search);
    // eslint-disable-next-line
  }, []);
  /**
   * list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * day to day task response
   * @param {*} page 
   * @param {*} item 
   * @param {*} search 
   */
  const dayTasksResponse = (page, item, search) => {
    setLoader("loading");
    DayToDayService.getDaytoDayDetails(page, item, search.trim())
      .then((response) => {
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        setLoader("isfull");
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * delete day to day task
   * @param {*} row 
   */
  const deleteDayTasks = (row) => {
    setLoader("loading");
    DayToDayService.deleteDayToDayTask(row?.id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          dispatch(alertActions.success(response?.data?.status?.message));
          dayTasksResponse(page, item, search);
          setLoader("isfull");
        } else {
          setLoader("isempty");
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * handle page
   * @param {*} e 
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    dayTasksResponse(e.selected + 1, item, search);
  };

  /**
   * navigate
   */
  const navigateToAddTask = () => {
    navigate("/daytodaytask-detail");
  };

  /**
   * change handler
   * @param {*} e 
   */
  const changeItemHandler = (e) => {
    setSearch("");
    if (e.target.value === "vehiclePlateNumber") {
      setItem(e.target.value);
      dayTasksResponse(page, e.target.value, "");
    }
  };

  /**
   * search handler
   * @param {*} e 
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    dayTasksResponse("", item, e.target.value);
  };


  /**
   * handle row
   * @param {*} row 
   */
  const handleRow = (row) => {
    navigate("/daytodaytask-detail", {
      state: {
        row: row,
      },
    });
  };

  /**
   * list
   */
  const list = () => {
    listData.data.data.docs.map((item, index) => {
      obj = {
        id: item._id,
        number:
          listData.data.data.limit * (listData.data.data.page - 1) + index + 1,
        assignedTo: item.assignedTo,
        date: item.date ? moment(item.date).format("DD/MM/YYYY") : "N/A",
        description: item.description,
        vehicleName: item.vehicleName.regNumber,
        numberPlate: item.vehiclePlateNumber,
        completed: item.completed,
        vehicleId: item.vehicleName._id,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  return {
    listData,
    totalPages,
    search,
    page,
    loader,
    columns,
    listTable,
    handlePageClick,
    handleRow,
    changeItemHandler,
    changeSearchHandler,
    navigateToAddTask,
  };
};
export default useDayToDayTaskListHook;
