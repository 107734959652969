import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AgreementService, VehicleService } from "../../../../../services";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../../../../assets/images";
import moment from "moment";
import { alertActions } from "../../../../../actions";

const useOnRentList = (props) => {
  const { t } = props;
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("mobileno");
  const [loader, setLoader] = useState("notset");
  const [fromDate, setFromDate] = useState("");
  const [noData, setNoData] = useState(false);
  const [locationArray, setLocationArray] = useState([]);
  const authdata = useSelector((state) => state?.userData);
  const [listTable, setListTable] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashBoardDisplay = useSelector(
    (state) => state?.userData?.user?.dashboardUA
  );

  const columns = [
    {
      name: t("Agrmt_No"),
      selector: (row) => row.rentalid,
      width: "150px",
    },
    {
      name: t("Name"),
      selector: (row) => row.fullname,
      width: "250px",
    },

    {
      name: t("Mobile"),
      selector: (row) => row.mobileno,
      width: "150px",
    },
    {
      name: t("Reg._No"),
      selector: (row) => row.vehicleregnumber,
      width: "150px",
    },
    {
      name: t("Model"),
      selector: (row) => row.vehicleDetails,
      width: "150px",
    },
    {
      name: t("Start_D-T"),
      selector: (row) => row.StartDateTime,
      width: "180px",
    },
    {
      name: t("End_D-T"),
      selector: (row) => row.EndDateTime,
      width: "180px",
    },
    {
      name: t("Days"),
      selector: (row) => (row.NoOFDays ? row.NoOFDays : "N/A"),
      width: "150px",
    },

    {
      name: t("Paid_Amt"),
      selector: (row) =>
        row.Advance_Amount !== 0 ? `₹${row.Advance_Amount.toFixed(2)}` : "N/A",
      width: "150px",
    },
    {
      name: t("Bal_Amt"),
      selector: (row) =>
        row.Balance_Amount !== 0 ? `₹${row.Balance_Amount.toFixed(2)}` : "N/A",
      width: "150px",
    },
    {
      name: t("Checkout-Loc"),
      selector: (row) => row.checkoutLocation,
      width: "150px",
    },
    {
      name: t("End-Rent"),
      selector: (row) =>
        dashBoardDisplay?.includes("onRent") ? (
          <button className="viewdata" onClick={() => handleRow(row)}>
            {t("End-Rent")}
          </button>
        ) : (
          <button
            style={{ pointerEvents: "none", opacity: "0.5" }}
            className="viewdata"
            onClick={() => handleRow(row)}
          >
            {t("End-Rent")}
          </button>
        ),
      width: "150px",
    },
    {
      name: t("Extend"),
      selector: (row) =>
        dashBoardDisplay?.includes("onRent") ? (
          <button
            className="viewdata"
            onClick={() => handleRowExtendedDate(row)}
          >
            {t("Extend")}
          </button>
        ) : (
          <button
            style={{ pointerEvents: "none", opacity: "0.5" }}
            className="viewdata"
            onClick={() => handleRowExtendedDate(row)}
          >
            {t("Extend")}
          </button>
        ),
      width: "150px",
    },
    {
      name: t("Download"),
      selector: (row) => (
        <>
          {loader === "loading" && (
            <div className="table_loader ">
              <img src={Loading} alt="loader" />
            </div>
          )}
          {loader !== "loading" && (
            <>
              {dashBoardDisplay?.includes("onRent") ? (
                <button className="viewdata" onClick={() => downloadPdf(row)}>
                  {t("Download")}
                </button>
              ) : (
                <button
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  className="viewdata"
                  onClick={() => downloadPdf(row)}
                >
                  {t("Download")}
                </button>
              )}{" "}
            </>
          )}{" "}
        </>
      ),
      width: "150px",
    },
    {
      name: t("Delete"),
      selector: (row) => (
        dashBoardDisplay?.includes("onRent") ?
          <button className="viewdata" onClick={() => deleteRecord(row)}>
            {t("Delete")}
          </button> : <button style={{ pointerEvents: "none", opacity: "0.5" }} className="viewdata" onClick={() => deleteRecord(row)}>
            {t("Delete")}
          </button>
      ),
      width: "150px"
    },
  ];
  let order = [];
  let obj = {};

  /**
   * list call
   */
  useEffect(() => {
    reservationResponse(page, item, search, fromDate);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (item === "location") {
      selectLocation();
    }
  }, [item]);

  /**
   * call list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);
  /**
   * table list
   */

  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      let startDate = item?.startdate ? new Date(item?.startdate) : "N/A";
      let endDate = item?.enddate ? new Date(item?.enddate) : "N/A";
      obj = {
        number:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        fullname: item?.customername ? item?.customername : "N/A",
        mobileno: item?.customermobileno ? item?.customermobileno : "N/A",
        StartDateTime: startDate ? startDate?.toLocaleString("en-IN") : "N/A",
        EndDateTime: endDate ? endDate?.toLocaleString("en-IN") : "N/A",
        NoOFDays: item?.noofdays ? item?.noofdays : "N/A",
        vehicleDetails: item?.vehicledetailsmodel
          ? item?.vehicledetailsmodel
          : "N/A",

        Advance_Amount: item?.paidamount,
        Balance_Amount: item?.pendingamount,
        id: item?._id,
        vehicleregnumber: item?.vehicleregnumber
          ? item?.vehicleregnumber
          : "N/A",
        checkoutLocation: item?.checkoutLocation
          ? item?.checkoutLocation
          : "N/A",
        rentalid: item?.rentalid ? item?.rentalid : "N/A",
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * list api call
   * @param {*} page
   * @param {*} item
   * @param {*} search
   * @param {*} fromDate
   */
  const reservationResponse = (page, item, search, fromDate) => {
    setLoader("loading");
    AgreementService.getRentList(page, item, search.trim(), fromDate)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setNoData(false);
          setListData(response);
          setTotalPages(response?.data?.data?.totalPages);
          setPage(response?.data?.data?.page);
          setLoader("isfull");
        } else {
          setLoader("empty");
          setNoData(true);
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * get location
   */
  const selectLocation = () => {
    VehicleService.getLocationName()
      .then((response) => {
        setLocationArray(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  /**
   * handle date
   * @param {*} e
   */
  const handleStartDate = (e) => {
    setFromDate(moment(e).format("DD/MM/YYYY"));
    reservationResponse(page, item, search, moment(e).format("DD/MM/YYYY"));
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    navigate(`/EndRentDetailsTab?bid=${row?.id}`);
  };
  /**
   * handle extended row
   * @param {*} row
   */
  const handleRowExtendedDate = (row) => {
    navigate(`/ExtendedDateTab?bid=${row?.id}`);
  };

  /**
   * handle item change
   * @param {*} e
   */
  const changeItemHandler = (e) => {
    setSearch("");
    setFromDate("");

    if (e.target.value === "fullname") {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", "");
    } else if (e.target.value === "model") {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", "");
    } else if (e.target.value === "regNumber") {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", "");
    } else if (e.target.value === "location") {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", fromDate);
    } else if (e.target.value === "agreementno") {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", "");
    } else {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "", "");
    }
  };

  /**
   * delete on rent record
   * @param {*} row 
   */
  const deleteRecord = (row) => {
    setLoader("loading");
    AgreementService.deleteOnRentList(row?.id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          dispatch(alertActions.success(response?.data?.status?.message));
          reservationResponse(page, item, search, fromDate);
          setLoader("isfull");
        } else {
          setLoader("isempty");
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * search handler
   * @param {*} e
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    reservationResponse("", item, e.target.value, fromDate);
  };

  /**
   * handle page
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    reservationResponse(e.selected + 1, item, search, fromDate);
  };
  /**
   * download
   * @param {*} row
   */
  const downloadPdf = (row) => {
    setLoader("loading");
    axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_APIURL}/admin/agreement/booking/download-payment-pdf/${row.id}`,

      method: "GET",
      responseType: "arraybuffer",

      headers: {
        // Add any auth token here
        authorization: authdata?.type + " " + authdata?.token,
        "Content-Type": "blob",
      },
      // Attaching the form data
    }).then((response) => {
      setLoader("isfull");
      const resp = response;
      const link = document.createElement("a");
      const fileName = `${Date.now()}.pdf`;
      link.setAttribute("download", fileName);
      link.href = URL.createObjectURL(new Blob([resp.data]));
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };
  return {
    listData,
    totalPages,
    search,
    page,
    loader,
    fromDate,
    noData,
    item,
    locationArray,
    columns,
    listTable,
    handlePageClick,
    changeItemHandler,
    changeSearchHandler,
    handleStartDate,
  };
};
export default useOnRentList;
