import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { alertActions } from "../../../../../../../../actions";
import { useDispatch } from "react-redux";
import { StartrentService } from "../../../../../../../../services";
import { AgreementService } from "../../../../../../../../services";
import moment from "moment";

const useDocumentDetails = (displayData, cid, bid) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState("notset");
  const [adhar_number, setAdhar_number] = useState("");
  const [drivinglicense_number, setDrivingLicense_number] = useState("");
  const [drivinglicense_expirydate, setDrivingLicense_expirydate] =
    useState("");
  const [status, setStatus] = useState("");

  const [submit, setSubmit] = useState(false);
  const [selectedFile1, setSelectedFile1] = useState("");
  const [drivinglicense_frontsideimage, setDrivinglicense_frontsideimage] =
    useState();
  const [fileName1, setFileName1] = useState("");
  const [selectedFile2, setSelectedFile2] = useState("");
  const [drivinglicense_backsideimage, setDrivinglicense_backsideimage] =
    useState();
  const [fileName2, setFileName2] = useState("");
  const [selectedFile3, setSelectedFile3] = useState("");
  const [id_frontside, setId_frontside] = useState();
  const [fileName3, setFileName3] = useState("");
  const [selectedFile4, setSelectedFile4] = useState("");
  const [id_backside, setId_backside] = useState();
  const [fileName4, setFileName4] = useState("");
  const [alternate_id, setAlternate_id] = useState("");

  /**
   * get booking details
   */
  useEffect(() => {
    setLoader("loading");
    StartrentService.getbook(bid)
      .then((res) => {
        if (res?.data?.status?.code === 0) {
          setLoader("isfull");
          setAdhar_number(res?.data?.data?.adhar_number);
          setDrivingLicense_number(res?.data?.data?.drivinglicense_number);
          setDrivingLicense_expirydate(
            res?.data?.data?.drivinglicense_expirydate
          );
          setDrivinglicense_frontsideimage(
            res?.data?.data?.drivinglicense_frontsideimage
          );
          setDrivinglicense_backsideimage(
            res?.data?.data?.drivinglicense_backsideimage
          );
          setId_frontside(res?.data?.data?.id_frontside);
          setId_backside(res?.data?.data?.id_backside);
          setAlternate_id(res?.data?.data?.alternate_id);
        } else {
          setLoader("isempty");
          console.log("error");
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  /**
   * image
   */
  useEffect(() => {
    if (!selectedFile1) {
      setDrivinglicense_frontsideimage(undefined);
      return;
    }

    const objectUrl1 = URL.createObjectURL(selectedFile1);

    setDrivinglicense_frontsideimage(objectUrl1);
    return () => URL.revokeObjectURL(objectUrl1);
  }, [selectedFile1]);
  useEffect(() => {
    if (!selectedFile2) {
      setDrivinglicense_backsideimage(undefined);
      return;
    }

    const objectUrl2 = URL.createObjectURL(selectedFile2);
    setDrivinglicense_backsideimage(objectUrl2);
    return () => URL.revokeObjectURL(objectUrl2);
  }, [selectedFile2]);
  useEffect(() => {
    if (!selectedFile3) {
      setId_frontside(undefined);
      return;
    }

    const objectUrl3 = URL.createObjectURL(selectedFile3);
    setId_frontside(objectUrl3);
    return () => URL.revokeObjectURL(objectUrl3);
  }, [selectedFile3]);

  useEffect(() => {
    if (!selectedFile4) {
      setId_backside(undefined);
      return;
    }

    const objectUrl4 = URL.createObjectURL(selectedFile4);
    setId_backside(objectUrl4);
    return () => URL.revokeObjectURL(objectUrl4);
  }, [selectedFile4]);

  /**
   * navigate to list page
   */
  const navigateToListPage = () => {
    navigate("/customers");
  };

  /**
   * handle change
   * @param {*} e
   */
  const handleAlternateId = (e) => {
    setAlternate_id(e.target.value);
  };
  const handleAdhar_numberChange = (e) => {
    setAdhar_number(e.target.value);
  };
  const handleDrivingLicense_numberChange = (e) => {
    setDrivingLicense_number(e.target.value);
  };
  const handleDrivingLicense_expirydateChange = (e) => {
    setDrivingLicense_expirydate(moment(e).format("DD/MM/YYYY"));
  };
  const changeAcceptCheckboxHandler = (e) => {
    setStatus(e.target.checked ? "Blacklisted" : "Active");
  };
  function onSelectFile1(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile1(undefined);
      return;
    }

    setFileName1(e.target.files[0].name);
    setSelectedFile1(e.target.files[0]);
  }
  function onSelectFile2(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile2(undefined);
      return;
    }

    setFileName2(e.target.files[0].name);
    setSelectedFile2(e.target.files[0]);
  }
  function onSelectFile3(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile3(undefined);
      return;
    }

    setFileName3(e.target.files[0].name);
    setSelectedFile3(e.target.files[0]);
  }
  function onSelectFile4(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile4(undefined);
      return;
    }

    setFileName4(e.target.files[0].name);
    setSelectedFile4(e.target.files[0]);
  }

  /**
   *save customer details api call
   * @param {*} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    var regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

    let select1;
    let select2;
    let select3;
    let select4;
    if (selectedFile1 === "") {
      select1 = drivinglicense_frontsideimage;
    } else {
      select1 = selectedFile1;
    }
    if (selectedFile2 === "") {
      select2 = drivinglicense_backsideimage;
    } else {
      select2 = selectedFile2;
    }
    if (selectedFile3 === "") {
      select3 = id_frontside;
    } else {
      select3 = selectedFile3;
    }
    if (selectedFile4 === "") {
      select4 = id_backside;
    } else {
      select4 = selectedFile4;
    }

    // if (
    //   drivinglicense_number &&
    //   drivinglicense_expirydate
    //   // select1 &&
    //   // select2 &&
    //   // select3 &&
    //   // select4
    // ) {
    if (adhar_number && !regex.test(adhar_number)) {
      dispatch(alertActions.error("Enter valid Adhar number"));
    } else {
      setLoader("loading");
      AgreementService.storedocuments(
        adhar_number,
        drivinglicense_number,
        drivinglicense_expirydate,
        select1,
        select2,
        select3,
        select4,
        bid,
        alternate_id
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setAdhar_number("");
            setDrivingLicense_number("");
            setDrivingLicense_expirydate("");
            setSelectedFile1("");
            setSelectedFile2("");
            setSelectedFile3("");
            setSelectedFile4("");
            setAlternate_id("");
            setLoader("isfull");
            setSubmit(false);
            displayData(3);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
    // }
  };

  /**
   * update customer details api call
   * @param {*} e
   */
  const handleEditSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    let select1;
    let select2;
    let select3;
    let select4;
    if (drivinglicense_frontsideimage !== "") {
      select1 = drivinglicense_frontsideimage;
    } else {
      select1 = selectedFile1;
    }
    if (drivinglicense_backsideimage !== "") {
      select2 = drivinglicense_backsideimage;
    }
    if (id_frontside !== "") {
      select3 = id_frontside;
    }
    if (id_backside !== "") {
      select4 = id_backside;
    }
    // if (
    //   drivinglicense_number &&
    //   drivinglicense_expirydate
    //   // select1 &&
    //   // select2 &&
    //   // select3 &&
    //   // select4
    // ) {
    setLoader("loading");
    StartrentService.editCustomerDetails(
      cid,
      status,
      adhar_number,
      drivinglicense_number,
      drivinglicense_expirydate,
      // select1,
      // select2,
      // select3,
      // select4,
      alternate_id
    )
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setAdhar_number("");
          setDrivingLicense_number("");
          setDrivingLicense_expirydate("");
          setSelectedFile1("");
          setSelectedFile2("");
          setSelectedFile3("");
          setSelectedFile4("");
          setStatus("");
          setAlternate_id("");
          setLoader("isfull");
          setSubmit(false);
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          setLoader("isempty");
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((err) => console.log(err));
    // }
  };

  return {
    drivinglicense_number,
    drivinglicense_expirydate,
    fileName1,
    selectedFile1,
    drivinglicense_frontsideimage,
    fileName2,
    selectedFile2,
    drivinglicense_backsideimage,
    fileName3,
    selectedFile3,
    id_frontside,
    fileName4,
    selectedFile4,
    id_backside,
    status,
    submit,
    adhar_number,
    loader,
    alternate_id,
    handleAlternateId,
    navigateToListPage,
    handleAdhar_numberChange,
    handleDrivingLicense_numberChange,
    handleDrivingLicense_expirydateChange,
    handleSubmit,
    onSelectFile1,
    onSelectFile2,
    onSelectFile3,
    onSelectFile4,
    changeAcceptCheckboxHandler,
    handleEditSubmit,
  };
};
export default useDocumentDetails;
