import Menu from "../../../../../../_common/menu/Menu";
import TopBar from "../../../../../../_common/topbar/TopBar";
import "../../../../Agreement.css";
import { agreement } from "../../../../../../../assets/images";
import useBookingHooks from "./useStartRentBookingHooks";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router";
import BookingAddPersonalInfo from "../BookingAddPersonalDetails/BookingPersonalInfoTab/BookingAddPersonalInfo";
import { Loading } from "../../../../../../../assets/images";

const Booking = () => {
  const location = useLocation();

  const {
    loader,
    listData,
    totalPages,
    displayData,
    search,
    page,
    columns,
    listTable,
    handlePageClick,
    changeItemHandler,
    changeSearchHandler,
  } = useBookingHooks(location);

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const agreementtiltle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img src={agreement} alt="iagreement" />
          New Agreement
        </div>
      </div>
    );
  };

  const agreementtab = () => {
    return (
      <div className="main_general flt">
        <div className="walk_in">
          <div className="rent_walk">Start Rent-Booking</div>
        </div>
        <div className="main_search_b2 flt">
          <div className="ex_customer flt">Customer Booking List</div>
          <div className="fliter_button2"></div>
          <div className="main_search_dd ">
            <div className="search_l ">
              <select name="item" id="item" onChange={changeItemHandler}>
                <option default value="mobileno">
                  Search by Mobile No.
                </option>

                <option value="fullname">Search by Customer Name.</option>
              </select>
            </div>
            <div className="search_b1 ">
              <input
                type="text"
                placeholder="Search.."
                name="search"
                onChange={changeSearchHandler}
                value={search}
              />
              <button className="search_button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const tableListDisplay = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          <div className="main_tableheadnew flt">
            {loader === "loading" || loader === "notset" ? (
              <div className="table_loader ">
                <img src={Loading} alt="loader" />
              </div>
            ) : (
              <table className="main_table1 flt ">
                {listData?.data?.data?.docs?.length !== 0 ? (
                  <DataTable
                    columns={columns}
                    data={listTable || []}
                    customStyles={customStyles}
                  />
                ) : (
                  <div className="no-data">No Data Found</div>
                )}
                {listData?.data?.data?.docs.length !== 0 && (
                  <div>
                    <ReactPaginate
                      nextLabel="&rarr;"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      pageCount={totalPages}
                      previousLabel="&larr;"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakClassName="page-item"
                      forcePage={page - 1}
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}{" "}
              </table>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />

      {agreementtiltle()}
      {agreementtab()}
      {displayData === 1 && <BookingAddPersonalInfo />}

      {displayData === 0 && tableListDisplay()}
    </div>
  );
};
export default Booking;
