import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { alertActions } from "../../../../../../../../actions";
import { CustomerService } from "../../../../../../../../services";
import { useDispatch } from "react-redux";
import { StartrentService } from "../../../../../../../../services";
import { AgreementService } from "../../../../../../../../services";
import moment from "moment";

const useCustomerDetails = (displayData, bookid) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fullname, setFullname] = useState(
    location?.state ? location?.state.row?.fullname : ""
  );
  const [email, setEmail] = useState(
    location?.state ? location?.state.row?.email : ""
  );
  const [mobileno, setMobileNo] = useState(
    location?.state ? location?.state.row?.mobileno : ""
  );
  const [alternatemobileno, setAlternateMobileno] = useState(
    location?.state ? location?.state.row?.alternatemobileno : ""
  );
  const [date_of_birth, setDate_of_birth] = useState(
    location?.state ? new Date(location?.state.row?.date_of_birth) : ""
  );
  const [adhar_number, setAdhar_number] = useState(
    location?.state ? location?.state.row?.adhar_number : ""
  );
  const [drivinglicense_number, setDrivingLicense_number] = useState(
    location?.state ? location?.state.row?.drivinglicense_number : ""
  );
  const [drivinglicense_expirydate, setDrivingLicense_expirydate] = useState(
    location?.state ? location?.state.row?.drivinglicense_expirydate : ""
  );
  const [status, setStatus] = useState(
    location?.state ? location?.state?.row?.status : ""
  );

  const [id, setId] = useState(location?.state ? location?.state.row?.id : "");

  const [submit, setSubmit] = useState(false);
  const [selectedFile1, setSelectedFile1] = useState("");

  const [drivinglicense_frontsideimage, setDrivinglicense_frontsideimage] =
    useState(location?.state?.row?.drivinglicense_frontsideimage);
  const [fileName1, setFileName1] = useState("");

  const [selectedFile2, setSelectedFile2] = useState("");
  const [drivinglicense_backsideimage, setDrivinglicense_backsideimage] =
    useState(location?.state?.row?.drivinglicense_backsideimage);
  const [fileName2, setFileName2] = useState("");

  const [selectedFile3, setSelectedFile3] = useState("");
  const [id_frontside, setId_frontside] = useState(
    location?.state?.row?.id_frontside
  );
  const [fileName3, setFileName3] = useState("");

  const [selectedFile4, setSelectedFile4] = useState("");
  const [id_backside, setId_backside] = useState(
    location?.state?.row?.id_backside
  );

  const [alternate_id, setAlternate_id] = useState(
    location?.state?.row?.alternate_id
  );
  const [fileName4, setFileName4] = useState("");
  const [loader, setLoader] = useState("notset");
  const bookiddoc = bookid;

  /**
   * get book
   */
  useEffect(() => {
    setLoader("loading");
    StartrentService.getbook(bookiddoc)
      .then((res) => {
        if (res?.data?.status?.code === 0) {
          setLoader("isfull");
          setAdhar_number(res?.data?.data?.adhar_number);
          setDrivingLicense_number(res?.data?.data?.drivinglicense_number);
          setDrivingLicense_expirydate(
            res?.data?.data?.drivinglicense_expirydate
          );
          setDrivinglicense_frontsideimage(
            res?.data?.data?.drivinglicense_frontsideimage
          );
          setDrivinglicense_backsideimage(
            res?.data?.data?.drivinglicense_backsideimage
          );
          setId_frontside(res?.data?.data?.id_frontside);
          setId_backside(res?.data?.data?.id_backside);
          setAlternate_id(res?.data?.data?.alternate_id);
        } else {
          setLoader("isempty");
          console.log("error");
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  /**
   * image
   */
  useEffect(() => {
    if (!selectedFile1) {
      setDrivinglicense_frontsideimage(undefined);
      return;
    }

    const objectUrl1 = URL.createObjectURL(selectedFile1);

    setDrivinglicense_frontsideimage(objectUrl1);
    return () => URL.revokeObjectURL(objectUrl1);
  }, [selectedFile1]);
  useEffect(() => {
    if (!selectedFile2) {
      setDrivinglicense_backsideimage(undefined);
      return;
    }

    const objectUrl2 = URL.createObjectURL(selectedFile2);
    setDrivinglicense_backsideimage(objectUrl2);
    return () => URL.revokeObjectURL(objectUrl2);
  }, [selectedFile2]);
  useEffect(() => {
    if (!selectedFile3) {
      setId_frontside(undefined);
      return;
    }

    const objectUrl3 = URL.createObjectURL(selectedFile3);
    setId_frontside(objectUrl3);
    return () => URL.revokeObjectURL(objectUrl3);
  }, [selectedFile3]);

  useEffect(() => {
    if (!selectedFile4) {
      setId_backside(undefined);
      return;
    }

    const objectUrl4 = URL.createObjectURL(selectedFile4);
    setId_backside(objectUrl4);
    return () => URL.revokeObjectURL(objectUrl4);
  }, [selectedFile4]);
  /**
   * age calculation
   */
  let age = "";
  let ageDiff = "";
  if (date_of_birth !== "") {
    const today = new Date();
    const date = new Date(date_of_birth);
    const diffTime = Math.abs(date - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    ageDiff = Math.round((diffDays / 365) * 10) / 10;
    age = ageDiff > 1 ? ageDiff + "years" : ageDiff + "year";
  }

  /**
   * navigate to list page
   */
  const navigateToListPage = () => {
    navigate("/customers");
  };

  /**
   * handle change
   * @param {*} e
   */
  const handleAlternateId = (e) => {
    setAlternate_id(e.target.value);
  };
  const handleAdhar_numberChange = (e) => {
    setAdhar_number(e.target.value);
  };
  const handleDrivingLicense_numberChange = (e) => {
    setDrivingLicense_number(e.target.value);
  };
  const handleDrivingLicense_expirydateChange = (e) => {
    setDrivingLicense_expirydate(moment(e).format("DD/MM/YYYY"));
  };

  function onSelectFile1(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile1(undefined);
      return;
    }

    setFileName1(e.target.files[0].name);
    setSelectedFile1(e.target.files[0]);
  }
  function onSelectFile2(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile2(undefined);
      return;
    }

    setFileName2(e.target.files[0].name);
    setSelectedFile2(e.target.files[0]);
  }
  function onSelectFile3(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile3(undefined);
      return;
    }

    setFileName3(e.target.files[0].name);
    setSelectedFile3(e.target.files[0]);
  }
  function onSelectFile4(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile4(undefined);
      return;
    }

    setFileName4(e.target.files[0].name);
    setSelectedFile4(e.target.files[0]);
  }

  /**
   * delete api call
   * @param {*} e
   */
  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    setLoader("loading");
    CustomerService.deleteCustomerDetails(id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setSubmit(false);
          setLoader("isfull");
          navigate("/customers");
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          setLoader("isempty");
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * save customer details api call
   * @param {*} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    var filter = /^[0-9]{10}$/;

    var regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

    let select1;
    let select2;
    let select3;
    let select4;
    if (selectedFile1 === "") {
      select1 = drivinglicense_frontsideimage;
    } else {
      select1 = selectedFile1;
    }
    if (selectedFile2 === "") {
      select2 = drivinglicense_backsideimage;
    } else {
      select2 = selectedFile2;
    }
    if (selectedFile3 === "") {
      select3 = id_frontside;
    } else {
      select3 = selectedFile3;
    }
    if (selectedFile4 === "") {
      select4 = id_backside;
    } else {
      select4 = selectedFile4;
    }

    // if (
    //   // drivinglicense_number &&
    //   // drivinglicense_expirydate
    //   // select1 &&
    //   // select2 &&
    //   // select3 &&
    //   // select4
    // ) {
    if (adhar_number && !regex.test(adhar_number)) {
      dispatch(alertActions.error("Enter valid Adhar number"));
    } else if (alternatemobileno && !filter.test(alternatemobileno)) {
      dispatch(alertActions.error("Enter valid alternate mobile number"));
    } else {
      setLoader("loading");
      AgreementService.storedocuments(
        adhar_number,
        drivinglicense_number,
        drivinglicense_expirydate,
        select1,
        select2,
        select3,
        select4,
        bookid,
        alternate_id
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setAdhar_number("");
            setDrivingLicense_number("");
            setDrivingLicense_expirydate("");
            setSelectedFile1("");
            setSelectedFile2("");
            setSelectedFile3("");
            setSelectedFile4("");
            setAlternate_id("");
            displayData(3);
            setSubmit(false);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
    // }
  };

  /**
   * update customer details api call
   * @param {*} e
   */
  const handleEditSubmit = (e) => {
    e.preventDefault();
    var filter = /^[0-9]{10}$/;

    var regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

    setSubmit(true);
    let select1;
    let select2;
    let select3;
    let select4;
    if (drivinglicense_frontsideimage !== "") {
      select1 = drivinglicense_frontsideimage;
    } else {
      select1 = selectedFile1;
    }
    if (drivinglicense_backsideimage !== "") {
      select2 = drivinglicense_backsideimage;
    }
    if (id_frontside !== "") {
      select3 = id_frontside;
    }
    if (id_backside !== "") {
      select4 = id_backside;
    }
    if (
      id &&
      fullname &&
      // email &&
      mobileno &&
      alternatemobileno &&
      // date_of_birth &&
      // adhar_number &&
      // drivinglicense_number &&
      // drivinglicense_expirydate &&
      // age &&
      select1 &&
      select2 &&
      select3 &&
      select4
    ) {
      if (adhar_number && !regex.test(adhar_number)) {
        dispatch(alertActions.error("Enter valid Adhar number"));
      } else if (alternatemobileno && !filter.test(alternatemobileno)) {
        dispatch(alertActions.error("Enter valid alternate mobile number"));
      }
      // else if (ageDiff < 18) {
      //   dispatch(alertActions.error("Age must be greater than 18"));
      // }
      else {
        setLoader("loading");
        StartrentService.editCustomerDetails(
          bookid,
          fullname,
          email,
          mobileno,
          alternatemobileno,
          date_of_birth,
          status,
          adhar_number,
          drivinglicense_number,
          drivinglicense_expirydate,
          age,
          select1,
          select2,
          select3,
          select4,
          alternate_id
        )
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setFullname("");
              setEmail("");
              setMobileNo("");
              setAlternateMobileno("");
              setDate_of_birth("");
              setAdhar_number("");
              setDrivingLicense_number("");
              setDrivingLicense_expirydate("");
              setSelectedFile1("");
              setSelectedFile2("");
              setSelectedFile3("");
              setSelectedFile4("");
              setAlternate_id("");
              setStatus("");
              setSubmit(false);
              setLoader("isfull");
              dispatch(alertActions.success(response?.data?.status?.message));
            } else {
              setLoader("isempty");
              dispatch(alertActions.error(response?.data?.status?.message));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  return {
    handleAlternateId,
    navigateToListPage,
    handleAdhar_numberChange,
    handleDrivingLicense_numberChange,
    handleDrivingLicense_expirydateChange,
    handleSubmit,
    onSelectFile1,
    onSelectFile2,
    onSelectFile3,
    onSelectFile4,
    handleDeleteSubmit,
    handleEditSubmit,
    setId,
    loader,
    alternate_id,
    adhar_number,
    drivinglicense_number,
    drivinglicense_expirydate,
    fileName1,
    selectedFile1,
    drivinglicense_frontsideimage,
    fileName2,
    selectedFile2,
    drivinglicense_backsideimage,
    fileName3,
    selectedFile3,
    id_frontside,
    fileName4,
    selectedFile4,
    id_backside,
    status,
    id,
    submit,
  };
};
export default useCustomerDetails;
