const SetUpAgreement = ({ agreementsUA, handleAgreement }) => {
  let agreementsData = [];
  agreementsData = agreementsUA ? agreementsUA : [];

  const agreementDetails = () => {
    return (
      <>
        <ul className="make_new flt">
          <li className="check_btn3">
            <input type="checkbox" id="Booking" name="Booking" value="Booking"
              checked={
                agreementsData.includes("Booking")}
              onChange={(e) => handleAgreement(e, agreementsData)
              }

            />
            <label for="Booking">Booking</label>
          </li>

          <li className="check_btn3">
            <input type="checkbox" id="startRent" name="startRent" value="startRent" checked={
              agreementsData.includes("startRent")}
              onChange={(e) => handleAgreement(e, agreementsData)
              } />
            <label for="startRent">Start Rent</label>
          </li>

          <li className="check_btn3">
            <input type="checkbox" id="endRent" name="endRent" value="endRent" checked={
              agreementsData.includes("endRent")}
              onChange={(e) => handleAgreement(e, agreementsData)
              } />
            <label for="endRent">End Rent</label>
          </li>

          <li className="check_btn3">
            <input type="checkbox" id="exchange" name="exchange" value="exchange" checked={
              agreementsData.includes("exchange")}
              onChange={(e) => handleAgreement(e, agreementsData)
              } />
            <label for="exchange">Exchange</label>
          </li>

        </ul>
      </>
    )
  }

  return (
    <>
      {agreementDetails()}
    </>
  );
};
export default SetUpAgreement;
