import UseDamages from "./useDamages";
import { Loading } from "../../../../../assets/images";
import { Calender } from "../../../../../assets/images";
import { useLocation } from "react-router";
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const DamageTab = ({ tab, tabId, t }) => {
  const location = useLocation();
  const {
    loader,
    customerName,
    rentalStartDate,
    rentalEndDate,
    damageCostReceived,
    damageRepairCharges,
    checkRes,
    submit,
    id,
    page,
    totalPages,
    list,
    damageId,
    listTable,
    columns,
    handleRow,
    handleCustName,
    handleDamageCostReceived,
    handleRentalStartDate,
    handleDamageRepairCharges,
    handleRentalEndDate,
    handleDamage,
    handleEditDamage,
    handleDamagePageClick,
  } = UseDamages(location, tab, tabId);

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const damageDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
            <>
              <div className="E_comp_details12 flt">
                <div className="main_boxShadow flt">
                  <div classN ame="main_emission flt">
                    <div className="Company_name flt">
                      <div className="comp_name_details flt">
                        {t("Vehicle_Damage_Details")}
                      </div>
                    </div>
                    <div className="main_company flt">
                      <div className="main_row flt">
                        <div className="row">
                          <div className="col-sm-3">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">
                                  {t("Customer_Name")} *
                                </label>
                                <input
                                  type="text"
                                  placeholder="Customer name"
                                  name="customerName"
                                  id="customerName"
                                  onChange={handleCustName}
                                  value={customerName}
                                />
                                {submit && !customerName && (
                                  <div className="req_field">
                                    {t("Customer_Name_required")}
                                  </div>
                                )}
                              </div>
                            </div>

                          </div>
                          {damageId === "" && (
                            <>
                              <div className="col-sm-3">
                                <div className=" company_name vehicle_damage flt">
                                  <div className="form-group flt">
                                    <label className="labelName">
                                      {t("Rental_Start_Date")}
                                    </label>

                                    <Datetime
                                      dateFormat="DD/MM/YYYY"
                                      onChange={handleRentalStartDate}
                                      value={rentalStartDate}
                                      closeOnSelect={true}
                                      timeFormat={false}
                                      isValidDate={(currentDate, selectedDate) =>
                                        currentDate.isAfter(
                                          new Date().getTime() -
                                          24 * 60 * 60 * 1000
                                        )
                                      }
                                      inputProps={{
                                        placeholder: "Select From date",
                                        disabled: false,
                                        readOnly: true,
                                        onKeyDown: (e) => e.preventDefault(),
                                      }}
                                    />
                                    <div className="cal_img">
                                      <img src={Calender} alt="calender" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-3">
                                <div className=" company_name vehicle_rentdamage flt">
                                  <div className="form-group flt">
                                    <label className="labelName">
                                      {t("Rental_End_Date")}
                                    </label>

                                    <Datetime
                                      dateFormat="DD/MM/YYYY"
                                      onChange={handleRentalEndDate}
                                      value={rentalEndDate}
                                      closeOnSelect={true}
                                      timeFormat={false}
                                      isValidDate={(currentDate, selectedDate) =>
                                        currentDate.isAfter(
                                          new Date().getTime() -
                                          24 * 60 * 60 * 1000
                                        )
                                      }
                                      inputProps={{
                                        placeholder: "Select From date",
                                        disabled: false,
                                        readOnly: true,
                                        onKeyDown: (e) => e.preventDefault(),
                                      }}
                                    />
                                    <div className="cal_img">
                                      <img src={Calender} alt="calender" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {damageId !== "" && (
                            <>
                              <div className="col-sm-3">
                                <div className=" company_name flt">
                                  <div className="form-group flt">
                                    <label className="labelName">
                                      {t("Rental_Start_Date")}
                                    </label>

                                    <Datetime
                                      dateFormat="DD/MM/YYYY"
                                      onChange={handleRentalStartDate}
                                      value={rentalStartDate}
                                      closeOnSelect={true}
                                      timeFormat={false}
                                      isValidDate={(
                                        rentalStartDate,
                                        selectedDate
                                      ) =>
                                        rentalStartDate.isAfter(
                                          new Date().getTime() -
                                          24 * 60 * 60 * 1000
                                        )
                                      }
                                      inputProps={{
                                        placeholder: "Select From date",
                                        disabled: false,
                                        readOnly: true,
                                        onKeyDown: (e) => e.preventDefault(),
                                      }}
                                    />
                                    <div className="cal_img">
                                      <img src={Calender} alt="calender" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-3">
                                <div className=" company_name flt">
                                  <div className="form-group flt">
                                    <label className="labelName">
                                      {t("Rental_End_Date")}
                                    </label>
                                    <div className="make_img flt">
                                      <Datetime
                                        dateFormat="DD/MM/YYYY"
                                        onChange={handleRentalEndDate}
                                        value={rentalEndDate}
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        isValidDate={(
                                          rentalStartDate,
                                          selectedDate
                                        ) =>
                                          rentalStartDate.isAfter(
                                            new Date().getTime() -
                                            24 * 60 * 60 * 1000
                                          )
                                        }
                                        inputProps={{
                                          placeholder: "Select From date",
                                          disabled: false,
                                          readOnly: true,
                                          onKeyDown: (e) => e.preventDefault(),
                                        }}
                                      />
                                      <div className="cal_img">
                                        <img src={Calender} alt="calender" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="col-sm-3">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">
                                  {t("Damage_Repair_Charges")}
                                </label>
                                <input
                                  className="comp_input"
                                  type="number"
                                  placeholder="Damage Repair Charges"
                                  name="damageRepairCharges"
                                  id="damageRepairCharges"
                                  onChange={handleDamageRepairCharges}
                                  value={damageRepairCharges}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3"> <div className="company_name flt">
                            <div className="form-group flt">
                              <label className="labelName">
                                {t("Damage_Cost_Received")}
                              </label>
                              <input
                                type="number"
                                placeholder="Damage Cost Received"
                                name="damageCostReceived"
                                id="damageCostReceived"
                                value={damageCostReceived}
                                onChange={handleDamageCostReceived}
                              />
                            </div>
                          </div></div>
                          <div className="col-sm-3"></div>
                          <div className="col-sm-3"></div>
                          <div className="col-sm-3"></div>
                        </div>
                        <div className="main_ownbutton flt">
                          <div className="own_button flt">
                            {damageId === "" && (
                              <button
                                className="submitbutton"
                                onClick={(e) => handleDamage(e, tabId)}
                              >
                                {t("Save")}
                              </button>
                            )}
                            {damageId !== "" && (
                              <button
                                className="submitbutton"
                                onClick={(e) => handleEditDamage(e, tabId)}
                              >
                                {t("Update")}
                              </button>
                            )}
                          </div>
                        </div>
                        <div class="main_general flt">
                          <div className="main_location flt">
                            <div className="location flt">
                              {t("Damage_History")}
                            </div>
                          </div>
                        </div>
                        <div className="E_comp_details flt">
                          <div className="main_boxShadow flt">
                            <div className="main_tablehead flt">
                              <table className="main_table1 flt ">
                                {list?.data?.data?.docs?.length !== 0 ? (
                                  <DataTable
                                    columns={columns}
                                    data={listTable || []}
                                    customStyles={customStyles}
                                    onRowClicked={(row) => handleRow(row)}
                                  />
                                ) : (
                                  <div className="no-data">
                                    {t("No_Data_Found")}
                                  </div>
                                )}
                              </table>
                              {list?.data?.data?.docs?.length !== 0 && (
                                <div>
                                  <ReactPaginate
                                    nextLabel="&rarr;"
                                    onPageChange={handleDamagePageClick}
                                    pageRangeDisplayed={1}
                                    marginPagesDisplayed={1}
                                    pageCount={totalPages}
                                    previousLabel="&larr;"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakClassName="page-item"
                                    currentPage={page}
                                    // forcePage={page1 - 1}
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>


                        <div className="main_ownbutton flt">
                          <div className="own_button flt">
                            {(checkRes === true || id !== "") && (
                              <button
                                className="backbutton"
                                onClick={() => tab(3)}
                              >
                                {t("Back")}
                              </button>
                            )}
                            <button className="backbutton" onClick={() => tab(6)}>
                              {t("Continue")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </>
    );
  };

  return <>{damageDetails()}</>;
};

export default DamageTab;
