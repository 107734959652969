import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Menu from "../../../../_common/menu/Menu";
import TopBar from "../../../../_common/topbar/TopBar";
import { vehicle } from "../../../../../assets/images";
import useVehicleExpiration from "./useVehicleExpiration";
import "../../Vehicles.css";
import { Loading } from "../../../../../assets/images";
import { Calender } from "../../../../../assets/images";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const VehicleExpirationList = (props) => {
  const { t } = props;
  const {
    loader,
    listData,
    totalPages,
    page,
    search,
    item,
    fromDate,
    noData,
    columns,
    listTable,
    handlePageClick,
    handleRow,
    changeSearchHandler,
    changeItemHandler,
    handleStartDate,
  } = useVehicleExpiration();

  const dashBoardDisplay = useSelector(
    (state) => state?.userData?.user?.dashboardUA
  );

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };
  const customStyles1 = {
    rows: {
      style: {
        cursor: "auto",
      },
    },
  };

  const searchDetails = () => {
    return (
      <>
        <div className="main_location flt">
          <div className="location flt">
            {" "}
            <img src={vehicle} alt="vehicle" />
            {t("Vehicle Expiration Details Lists")}
          </div>

          <div className="main_search_b1 vehic_expiryhh flt">
            <div className="main_search_dd12 "></div>

            <div className="search_l ">
              <select name="item" id="item" onChange={changeItemHandler}>
                <option default value="regno">
                  {t("Search by Reg. No.")}
                </option>
                <option value="model">{t("Search by Model.")}</option>
                <option value="expdate">{t("Search by Exp. Date.")}</option>
              </select>
            </div>
            {item !== "expdate" ? (
              <div className="search_b1 ">
                <input
                  type="text"
                  placeholder={t("Search..")}
                  name="search"
                  value={search}
                  onChange={changeSearchHandler}
                />
                <button className="search_button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            ) : (
              <div className="form-group flt">
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  onChange={handleStartDate}
                  value={fromDate}
                  closeOnSelect={true}
                  timeFormat={false}
                  inputProps={{
                    placeholder: "Select date",
                    disabled: false,
                    readOnly: true,
                    onKeyDown: (e) => e.preventDefault(),
                  }}
                />
                <div className="cal_img">
                  <img src={Calender} alt="calender" />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const expirationDetails = () => {
    return (
      <>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="table_loader ">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  {listData?.data?.data?.docs?.length !== 0 &&
                  noData === false ? (
                    <>
                      {dashBoardDisplay?.includes("expirationDetails") ? (
                        <DataTable
                          columns={columns}
                          data={listTable || []}
                          customStyles={customStyles}
                          onRowClicked={(row) => handleRow(row)}
                        />
                      ) : (
                        <DataTable
                          columns={columns}
                          data={listTable || []}
                          customStyles={customStyles1}
                        />
                      )}
                    </>
                  ) : (
                    <div className="no-data">{t("No Data Found")}</div>
                  )}
                  {listData?.data?.data?.docs.length !== 0 &&
                    noData === false && (
                      <div>
                        <ReactPaginate
                          nextLabel="&rarr;"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={1}
                          marginPagesDisplayed={1}
                          pageCount={totalPages}
                          previousLabel="&larr;"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          currentPage={page}
                          forcePage={page - 1}
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    )}{" "}
                </table>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="header_location flt">
      <div className="main_container flt">
        <TopBar />
        <Menu />

        {searchDetails()}
        {expirationDetails()}
      </div>
    </div>
  );
};
export default withTranslation()(VehicleExpirationList);
