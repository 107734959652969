import { alertActions, userLogout } from "../../../actions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const useTopBarHook = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(userLogout());
    history("/");
    dispatch(alertActions.success("Logout Success"));
  };
  return {
    handleLogout,
  };
};
export default useTopBarHook;
