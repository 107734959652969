import { useState } from "react";
import Menu from "../../../_common/menu/Menu";
import TopBar from "../../../_common/topbar/TopBar";
import "../Agreement.css";
import { agreement } from "../../../../assets/images";
import { popup } from "../../../../assets/images";
import { booking } from "../../../../assets/images";
import { exchange } from "../../../../assets/images";
import { startrent } from "../../../../assets/images";
import { endrent } from "../../../../assets/images";
import useAgreementHook from "./useAgreementHook";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

function Agreement(props) {
  const { t } = props;
  const navigate = useNavigate();
  const { bookingDetails, openEndRent, openExchange } = useAgreementHook();
  const [showRent, setShowRent] = useState(false);
  const agreementDisplay = useSelector(
    (state) => state?.userData?.user?.agreementsUA
  );

  const openRent = () => {
    setShowRent(true);
  };
  const closeRent = () => {
    setShowRent(false);
  };

  const goToBookingList = () => {
    navigate("/BookingStartRent");
  };
  const goToWalkinList = () => {
    navigate("/StartRentWalkin");
  };

  const newAgreementtitle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img src={agreement} alt="agreement" />
          {t("New Agreement")}
        </div>
      </div>
    );
  };

  const newAgreement = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_book flt">
          <div className="main_row flt">
            <div className="row">
              <div className="col-xs-6 col-lg-3 col-md-6">
                <div className="mobile_pass flt">
                  <div className="main_boxShadow flt">
                    <div className="Main_img_button flt">
                      <img src={booking} alt="book" />
                      {agreementDisplay?.includes("Booking") ? (
                        <div className="main_booking flt">
                          <button onClick={() => bookingDetails()}>
                            {t("Booking")}
                          </button>
                        </div>
                      ) : (
                        <div
                          style={{ pointerEvents: "none", opacity: "0.5" }}
                          className="main_booking flt"
                        >
                          <button onClick={() => bookingDetails()}>
                            {t("Booking")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 col-lg-3 col-md-6">
                <div className="mobile_pass flt">
                  <div className="main_boxShadow flt">
                    <div className="Main_img_button flt">
                      <img src={startrent} alt="startrnt" />
                      {agreementDisplay?.includes("startRent") ? (
                        <div className="main_booking flt">
                          <button onClick={openRent}>{t("Start-Rent")}</button>
                        </div>
                      ) : (
                        <div
                          style={{ pointerEvents: "none", opacity: "0.5" }}
                          className="main_booking flt"
                        >
                          <button onClick={openRent}>{t("Start-Rent")}</button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 col-lg-3 col-md-6">
                <div className="mobile_pass flt">
                  <div className="main_boxShadow flt">
                    <div className="Main_img_button flt">
                      <img src={exchange} alt="exchange" />
                      {agreementDisplay?.includes("exchange") ? (
                        <div className="main_booking flt">
                          <button onClick={openExchange}>{t("Exchange")}</button>
                        </div>
                      ) : (
                        <div
                          style={{ pointerEvents: "none", opacity: "0.5" }}
                          className="main_booking flt"
                        >
                          <button onClick={openExchange}>{t("Exchange")}</button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 col-lg-3 col-md-6">
                <div className="mobile_pass flt">
                  <div className="main_boxShadow flt">
                    <div className="Main_img_button flt">
                      <img src={endrent} alt="endrent" />
                      {agreementDisplay?.includes("endRent") ? (
                        <div className="main_booking flt">
                          <button onClick={openEndRent}>{t("End-Rent")}</button>
                        </div>
                      ) : (
                        <div
                          style={{ pointerEvents: "none", opacity: "0.5" }}
                          className="main_booking flt"
                        >
                          <button onClick={openEndRent}>{t("End-Rent")}</button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const showpopupmenu = () => {
    return (
      <div className="modal1">
        <div className="modal" id="myModal">
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="close_btn flt">
                <button
                  type="button"
                  className="close_button"
                  data-dismiss="modal"
                  onClick={closeRent}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="car_img flt">
                      <div className="pop_car flt">
                        <img src={popup} alt="popup" />
                      </div>
                      <div className="book_st flt">
                        <div className="main_booking flt">
                          <button onClick={goToBookingList}>{t("Booking")}</button>
                        </div>
                        <div className="main_booking flt">
                          <button onClick={goToWalkinList}>{t("Walk-in")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      {newAgreementtitle()}
      {newAgreement()}
      {showRent && showpopupmenu()}
    </div>
  );
}
export default withTranslation() (Agreement);
