import { useState, useEffect } from "react";
import {
  ExchangeService,
  SetUpService,
} from "../../../../../../../../services";
import { VehicleService } from "../../../../../../../../services";
import { AgreementService } from "../../../../../../../../services";
import { StartrentService } from "../../../../../../../../services";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../../../../../actions/alert.actions";

const useRentalDetailsHook = (displayDatas, cid, bid, rowinfo) => {
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayData, setDisplayData] = useState(0);
  const [dataSelected, setDataSelected] = useState([]);
  const [datetimevalue, setdatetimevalue] = useState();
  const [datetimeendvalue, setdatetimeendvalue] = useState();
  const [locations, setlocations] = useState([]);
  const [rlocations, setrlocations] = useState("");
  const [helmets_qty, sethelmets_qty] = useState("");
  const [travel, settravel] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [returnLocation, setReturnLocation] = useState("");
  const dispatch = useDispatch();
  const [datavalue, setdatavalue] = useState(0);

  const [vehicleClass, setVehicleClass] = useState("");
  const [classId, setClassId] = useState("");
  const [makeId, setMakeId] = useState("");
  const [modelId, setModelId] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");

  const [vehicleList, setVehicleList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [vehicleId, setVehicleId] = useState("");
  const [vehRes, setVehicleRes] = useState("");
  const bookidrent = bid;
  const [loader, setLoader] = useState("notset");
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("regNo");

  /**
   * get veh details
   */
  useEffect(() => {
    if (vehicleId !== "") {
      getVehicleDetails(bid);
    }
    // eslint-disable-next-line
  }, [vehicleId !== ""]);

  /**
   * get rental data
   */
  useEffect(() => {
    if (bookidrent) {
      setLoader("loading");
      StartrentService.getAllRentalData(bookidrent)
        .then((res) => {
          if (res?.data?.status?.code === 0) {
            setLoader("isfull");
            setVehicleClass(res?.data?.data?.[0]?.vehicleclass?.[0]?._id);
            setdatetimevalue(res?.data?.data?.[0]?.StartDateTime);
            setReturnLocation(res?.data?.data?.[0]?.ReturnLocation);
            sethelmets_qty(res?.data?.data?.[0]?.helmetqty);
            setrlocations(res?.data?.data?.[0]?.PickupLocation);
            settravel(res?.data?.data?.[0]?.TravelLocation);
            setSelectedVehicle(res?.data?.data?.[0]?.vehicalClass);
            setdatetimeendvalue(res?.data?.data?.[0]?.EndDateTime);
            setMakeId(res?.data?.data?.[0]?.vehiclemake?.[0]?._id);
            setModelId(res?.data?.data?.[0]?.vehicalmodel?.[0]?._id);
            setClassId(res?.data?.data?.[0]?.vehicleclass?.[0]?._id);
            setMake(res?.data?.data?.[0]?.vehiclemake?.[0]?._id);
            setModel(res?.data?.data?.[0]?.vehicalmodel?.[0]?._id);
            setVehicleId(
              res?.data?.data?.[0]?.vehicalid
                ? res?.data?.data?.[0]?.vehicalid
                : ""
            );
          } else {
            setLoader("isempty");
            console.log("error");
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [bookidrent === ""]);

  /**
   * get location and vehicle class response
   */
  useEffect(() => {
    setLoader("loading");
    VehicleService.getLocationName()
      .then((response) => {
        setLoader("isfull");
        setlocations(response?.data?.data);
      })
      .catch((err) => console.log(err));
    AgreementService.getVehicleClassName()
      .then((response) => {
        setLoader("isfull");
        setVehicleClass(response?.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   * get make list
   */
  useEffect(() => {
    makeListResponse(classId);
  }, [classId]);

  /**
   * get model list
   */
  useEffect(() => {
    modelListResponse(classId, makeId);
  }, [makeId, classId]);

  useEffect(() => {
    selectingVehicle();
  }, [page])

  /**
   * get veh class list
   */
  useEffect(() => {
    vehicleClassListResponse();
  }, []);

  /**
   * api call for vehicle details
   * @param {*} bid
   */
  const getVehicleDetails = (bid) => {
    setLoader("loading");
    ExchangeService.getVehicleDetails(bid)
      .then((res) => {
        setLoader("isfull");
        setVehicleRes(res);
      })
      .catch((err) => console.log(err));
  };

  /**
   * api call for make list
   * @param {*} mid
   */
  const makeListResponse = (mid) => {
    if (mid !== "") {
      SetUpService.getMakeWithoutPage(mid)
        .then((response) => {
          setMakeList(response?.data?.data);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  /**
   * api call for model list
   * @param {*} vid
   * @param {*} mid
   */
  const modelListResponse = (vid, mid) => {
    if (vid !== "" && mid !== "") {
      SetUpService.getModelWithoutPage(vid, mid)
        .then((response) => {
          setModelList(response?.data?.data);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  /**
   * get all vehicle list
   */
  const vehicleClassListResponse = () => {
    SetUpService.getVehicleClassWithoutPage()
      .then((response) => {
        setVehicleList(response?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };
  /**
   * handle change
   * @param {*} e
   */
  const handleVehicleClass = (e) => {
    if (e.target.value !== vehicleClass) {
      setdatavalue(0);
    }
    vehicleList.filter((val, index) =>
      val._id === e.target.value ? setVehicleClass(e.target.value) : ""
    );

    setModel("");
    setMake("");
    setClassId(e.target.value);
    setVehicleClass(e.target.value)
  };
  const handleMake = (e) => {
    if (e.target.value !== make) {
      setdatavalue(0);
    }
    makeList.filter((val, index) =>
      val._id === e.target.value ? setMake(e.target.value) : ""
    );

    setMakeId(e.target.value);
  };
  const handleModel = (e) => {
    if (e.target.value !== make) {
      setdatavalue(0);
    }
    modelList.filter((val, index) =>
      val._id === e.target.value ? setModel(e.target.value) : ""
    );
    setModelId(e.target.value);
  };

  let data;

  /**
   * handle page change
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    searchItem(item, search, e.selected + 1);
  };
  /**
   * navigate to list
   */
  const navigateTohistory = () => {
    setDisplayData(1);
  };

  /**
   * handle change
   * @param {*} e
   */
  const onChangeValue = (e) => {
    setdatetimevalue(e);
  };
  const onChangeendValue = (e) => {
    setdatetimeendvalue(e);
  };
  const changeHandlerlocation = (e) => {
    setReturnLocation(e.target.value);
  };
  const changeHandlerrelocation = (e) => {
    setrlocations(e.target.value);
  };
  const helmets_qtys = (e) => {
    sethelmets_qty(e.target.value);
  };
  const travelLocation = (e) => {
    settravel(e.target.value);
  };
  const changeVehicleClass = (e) => {
    setSelectedVehicle(e.target.value);
  };
  const validdatetimevalue = () => {
    if (datetimevalue === "") {
      return false;
    }
  };
  const validrlocations = () => {
    if (rlocations === "" || selectedVehicle === undefined) {
      return false;
    }
  };
  const validreturnLocation = () => {
    if (returnLocation === "" || selectedVehicle === undefined) {
      return false;
    }
  };
  const validdatetimeendvalue = () => {
    if (datetimeendvalue === "") {
      return false;
    }
  };

  const selectingVehicle = () => {
    setDisplayData(1);
    setSubmitted(true);
    if (datetimeendvalue && datetimevalue && returnLocation && rlocations) {
      if (
        classId &&
        bookidrent &&
        modelId &&
        makeId &&
        make &&
        vehicleClass &&
        model
      ) {
        setLoader("loading");
        StartrentService.getBookingDetailsdata(page, makeId, classId, modelId, item, search)
          .then((response) => {
            setLoader("isfull");
            setListData(response);
            setTotalPages(response?.data?.data?.totalPages);
            setPage(response?.data?.data?.page);
            setSubmitted(false);

            setdatavalue(1);
          })
          .catch((err) => console.log(err));
        // }
      }
    }

  }
  /**
   * search vehicle
   * @param {*} e
   */
  const SearchVehicle = (e) => {
    setDisplayData(1);
    setSubmitted(true);
    if (datetimeendvalue && datetimevalue && returnLocation && rlocations) {
      if (
        classId &&
        bookidrent &&
        modelId &&
        makeId &&
        make &&
        vehicleClass &&
        model
      ) {
        setLoader("loading");
        StartrentService.getBookingDetailsdata(page, makeId, classId, modelId, item, search)
          .then((response) => {
            setLoader("isfull");
            setListData(response);
            setTotalPages(response?.data?.data?.totalPages);
            setPage(response?.data?.data?.page);
            setSubmitted(false);

            setdatavalue(1);
          })
          .catch((err) => console.log(err));
        // }
      }
    }
  };
  /**
     * searchItem
     */
  const searchItem = (item, search, page) => {
    setSubmitted(true);

    StartrentService.getBookingDetailsdata(page, makeId, classId, modelId, item, search.trim())
      .then((response) => {

        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        setSubmitted(false);
      }).catch((err) => console.log(err));

  }

  /**
   * change handler
   * @param {*} e
   */
  const changeItemHandler = (e) => {
    setSearch("");
    if (e.target.value === "regNo") {
      setItem(e.target.value);

      searchItem(e.target.value, search, 1);
    }
  };
  /**
  * search handler
  * @param {*} e
  */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    searchItem(item, e.target.value, "");
  };

  /**
   * tab change
   */
  const ContinueInfo = () => {
    displayDatas(2);
  };

  /**
   * store rentals
   */
  const continueGetData = () => {
    setSubmitted(true);
    if (new Date(datetimeendvalue) < new Date(datetimevalue)) {
      dispatch(
        alertActions.error("End Date Should be Greater than Start Date")
      );
    } else {
      StartrentService.storeRentals(
        datetimevalue,
        datetimeendvalue,
        rlocations,
        returnLocation,
        helmets_qty,
        travel,
        classId,
        makeId,
        modelId,
        bookidrent,
        rowinfo?.location,
        rowinfo.id
      )
        .then((response) => {
          setLoader("loading");
          if (response?.data?.status?.code === 0) {
            dispatch(alertActions.success(response?.data?.status?.message));
            setLoader("isfull");
            setSubmitted(false);
            displayDatas(2);
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return {
    loader,
    submitted,
    listData,
    totalPages,
    dataSelected,
    data,
    datetimevalue,
    datetimeendvalue,
    locations,
    rlocations,
    helmets_qty,
    travel,
    vehicleClass,
    datavalue,
    make,
    makeList,
    vehicleList,
    model,
    modelList,
    classId,
    vehicleId,
    vehRes,
    returnLocation,
    displayData,
    search,
    page,
    setDataSelected,
    handlePageClick,
    navigateTohistory,
    onChangeValue,
    onChangeendValue,
    changeHandlerlocation,
    changeHandlerrelocation,
    helmets_qtys,
    travelLocation,
    SearchVehicle,
    ContinueInfo,
    changeVehicleClass,
    validdatetimevalue,
    validrlocations,
    validreturnLocation,
    validdatetimeendvalue,
    setdatavalue,
    continueGetData,
    handleVehicleClass,
    handleMake,
    handleModel,
    changeSearchHandler,
    changeItemHandler
  };
};
export default useRentalDetailsHook;
