import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import Menu from "../../_common/menu/Menu";
import TopBar from "../../_common/topbar/TopBar";
import { DashBoard } from "../../../assets/images";
import { reserv } from "../../../assets/images";
import { draft } from "../../../assets/images";
import { returns } from "../../../assets/images";
import { onrent } from "../../../assets/images";
import { pending } from "../../../assets/images";
import { services } from "../../../assets/images";
import { Avilable } from "../../../assets/images";
import { Expiration } from "../../../assets/images";
import { Daytask } from "../../../assets/images";
import { Fleet } from "../../../assets/images";
import { maint } from "../../../assets/images";
import useDashboardHook from "./useDashboardHook";
import { Calender } from "../../../assets/images";
import { useNavigate } from "react-router";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ReactPaginate from "react-paginate";
import { withTranslation } from "react-i18next";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useSelector } from "react-redux";

function Dashboard(props) {
  const { t } = props;
  const navigate = useNavigate();
  const {
    countResponse,
    vehicleList,
    classId,
    locationArray,
    page,
    listData,
    toDate,
    fromDate,
    location,
    totalPages,
    listPendingPayments,
    handleEndDate,
    handleStartDate,
    handleVehicleClass,
    changeLocationHandler,
    handlePageClick,
  } = useDashboardHook();
  const [available, setAvailable] = useState("");
  const [rent, setRent] = useState("");
  const [maintainance, setMaitainance] = useState("");
  const [sold, setSold] = useState("");
  const [service, setService] = useState("");
  const dashBoardDisplay = useSelector(
    (state) => state?.userData?.user?.dashboardUA
  );

  const [chartData, setChartData] = useState({
    datasets: [
      {
        data: [
          localStorage.getItem("available"),
          localStorage.getItem("rent"),
          localStorage.getItem("service"),
          localStorage.getItem("sold"),
          localStorage.getItem("maintanance"),
        ],
        backgroundColor: [
          "#003466",
          "#FF8E01",
          "#77A4E5",
          "#E9E9E9",
          "#A4D2FF",
        ],
        borderColor: ["#003466", "#FF8E01", "#77A4E5", "#E9E9E9", "#A4D2FF"],
        borderWidth: 1,
      },
    ],
  });
  ChartJS.register(ArcElement, Tooltip, Legend);
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;

        ctx.restore();
        var fontSize = (height / 200).toFixed(2);

        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "bottom";

        var text =
          localStorage.getItem("available") !== "undefined"
            ? localStorage.getItem("available")
            : "0";
        var textX = Math.round((width - ctx.measureText(text).width) / 2);
        var textY = height / 2.25;

        var text1 = "Available";
        var textX1 = Math.round((width - ctx.measureText(text1).width) / 2);
        var textY1 = height / 1.75;

        ctx.save();
        ctx.fillText(text, textX, textY);
        ctx.fillText(text1, textX1, textY1);
      },
    },
  ];

  useEffect(() => {
    if (classId === "") {
      setAvailable(
        countResponse?.data?.data?.vehicleStatus.length === 0
          ? "0"
          : countResponse?.data?.data?.vehicleStatus?.[0]?.available
      );
      setRent(
        countResponse?.data?.data?.vehicleStatus.length === 0
          ? "0"
          : countResponse?.data?.data?.vehicleStatus?.[0]?.rent
      );
      setMaitainance(
        countResponse?.data?.data?.vehicleStatus.length === 0
          ? "0"
          : countResponse?.data?.data?.vehicleStatus?.[0]?.maintanance
      );
      setSold(
        countResponse?.data?.data?.vehicleStatus.length === 0
          ? "0"
          : countResponse?.data?.data?.vehicleStatus?.[0]?.sold
      );
      setService(
        countResponse?.data?.data?.vehicleStatus.length === 0
          ? "0"
          : countResponse?.data?.data?.vehicleStatus?.[0]?.service
      );
    } else if (classId !== "") {
      setAvailable(
        countResponse?.data?.data?.vehicleStatusForChart.length === 0
          ? "0"
          : countResponse?.data?.data?.vehicleStatusForChart?.[0]?.available
      );
      setRent(
        countResponse?.data?.data?.vehicleStatusForChart.length === 0
          ? "0"
          : countResponse?.data?.data?.vehicleStatusForChart?.[0]?.rent
      );
      setMaitainance(
        countResponse?.data?.data?.vehicleStatusForChart.length === 0
          ? "0"
          : countResponse?.data?.data?.vehicleStatusForChart?.[0]?.maintanance
      );
      setSold(
        countResponse?.data?.data?.vehicleStatusForChart.length === 0
          ? "0"
          : countResponse?.data?.data?.vehicleStatusForChart?.[0]?.sold
      );
      setService(
        countResponse?.data?.data?.vehicleStatusForChart.length === 0
          ? "0"
          : countResponse?.data?.data?.vehicleStatusForChart?.[0]?.service
      );
    }
    // eslint-disable-next-line
  }, [countResponse]);

  useEffect(() => {
    setChartData({
      datasets: [
        {
          data: [available, rent, service, sold, maintainance],
          backgroundColor: [
            "#003466",
            "#FF8E01",
            "#77A4E5",
            "#E9E9E9",
            "#A4D2FF",
          ],
          borderColor: ["#003466", "#FF8E01", "#77A4E5", "#E9E9E9", "#A4D2FF"],
          borderWidth: 1,
        },
      ],
    });
  }, [available, rent, service, sold, maintainance]);

  const dashboardCount = () => {
    return (
      <>
        <div className="main_location flt">
          <div className="location">
            <img src={DashBoard} alt="images" />
            {t("DASHBOARD")}
          </div>
        </div>
        <div className="main_card dashbtm_card flt">
          <div className="row">
            <div className="col-sm-2">

              {dashBoardDisplay?.includes("Bookings") ? (
                <div
                  className="main_reservation flt"
                  onClick={() => navigate("/BookingList")}
                >
                  <div className="right_image">
                    <img src={reserv} alt="images" />
                  </div>
                  <div className="leftsidel">
                    <div className="Zeros">
                      {" "}
                      {countResponse?.data?.data?.reservation}
                    </div>
                    <div className="resrve">{t("Bookings")}</div>
                  </div>
                </div>) : <div
                  className="main_reservation flt"
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  onClick={() => navigate("/BookingList")}
                >
                <div className="right_image">
                  <img src={reserv} alt="images" />
                </div>
                <div className="leftsidel">
                  <div className="Zeros">
                    {" "}
                    {countResponse?.data?.data?.reservation}
                  </div>
                  <div className="resrve">{t("Bookings")}</div>
                </div>
              </div>}
            </div>
            <div className="col-sm-2">
              {dashBoardDisplay?.includes("draftBooking") ? (
                <div
                  className="main_reservation flt"
                  onClick={() => navigate("/Partial-BookingList")}
                >
                  <div className="right_image">
                    <img src={draft} alt="images" />
                  </div>
                  <div className="leftsidel">
                    <div className="Zeros">
                      {countResponse?.data?.data?.reservationForDraft}
                    </div>
                    <div className="resrve"> {t("Draft Bookings")}</div>
                  </div>
                </div>) : <div
                  className="main_reservation flt"
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  onClick={() => navigate("/Partial-BookingList")}
                >
                <div className="right_image">
                  <img src={draft} alt="images" />
                </div>
                <div className="leftsidel">
                  <div className="Zeros">
                    {countResponse?.data?.data?.reservationForDraft}
                  </div>
                  <div className="resrve"> {t("Draft Bookings")}</div>
                </div>
              </div>}
            </div>
            <div className="col-sm-2">
              {dashBoardDisplay?.includes("returns") ? (
                <div
                  className="main_reservation flt"

                  onClick={() => navigate("/ReturnList")}
                >
                  <div className="right_image">
                    <img src={returns} alt="images" />
                  </div>
                  <div className="leftsidel">
                    <div className="Zeros">
                      {" "}
                      {countResponse?.data?.data?.returns}
                    </div>
                    <div className="resrve">{t("Returns")}</div>
                  </div>
                </div>) : <div
                  className="main_reservation flt"
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  onClick={() => navigate("/ReturnList")}
                >
                <div className="right_image">
                  <img src={returns} alt="images" />
                </div>
                <div className="leftsidel">
                  <div className="Zeros">
                    {" "}
                    {countResponse?.data?.data?.returns}
                  </div>
                  <div className="resrve">{t("Returns")}</div>
                </div>
              </div>}
            </div>
            <div className="col-sm-2">
              {dashBoardDisplay?.includes("onRent") ? (
                <div
                  className="main_reservation flt"
                  onClick={() => navigate("/OnRentList")}
                >
                  <div className="right_image">
                    <img src={onrent} alt="images" />
                  </div>
                  <div className="leftsidel">
                    <div className="Zeros">
                      {countResponse?.data?.data?.vehiclesOnRent}
                    </div>
                    <div className="resrve">{t("On-Rent")}</div>
                  </div>
                </div>) : <div
                  className="main_reservation flt"
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  onClick={() => navigate("/OnRentList")}
                >
                <div className="right_image">
                  <img src={onrent} alt="images" />
                </div>
                <div className="leftsidel">
                  <div className="Zeros">
                    {countResponse?.data?.data?.vehiclesOnRent}
                  </div>
                  <div className="resrve">{t("On-Rent")}</div>
                </div>
              </div>}
            </div>
            <div className="col-sm-2">
              {dashBoardDisplay?.includes("vehiclesInFleet") ? (
                <div
                  className="main_reservation flt"
                  onClick={() => navigate("/vehicles")}
                >
                  <div className="right_image">
                    <img src={Fleet} alt="images" />
                  </div>
                  <div className="leftsidel">
                    <div className="Zeros">
                      {countResponse?.data?.data?.vehiclesInFleet}
                    </div>
                    <div className="resrve">{t("Vehicles in Fleet")}</div>
                  </div>
                </div>) : <div
                  className="main_reservation flt"
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  onClick={() => navigate("/vehicles")}
                >
                <div className="right_image">
                  <img src={Fleet} alt="images" />
                </div>
                <div className="leftsidel">
                  <div className="Zeros">
                    {countResponse?.data?.data?.vehiclesInFleet}
                  </div>
                  <div className="resrve">{t("Vehicles in Fleet")}</div>
                </div>
              </div>}
            </div>
            <div className="col-sm-2">
              {dashBoardDisplay?.includes("availableInFleet") ? (
                <div
                  className="main_reservation flt"
                  onClick={() =>
                    navigate("/vehicles-available", {
                      state: { status: "available" },
                    })
                  }
                >
                  <div className="right_image">
                    <img src={Avilable} alt="images" />
                  </div>
                  <div className="leftsidel">
                    <div className="Zeros">
                      {countResponse?.data?.data?.vehicleStatus.length !== 0
                        ? countResponse?.data?.data?.vehicleStatus?.[0].available
                          ? countResponse?.data?.data?.vehicleStatus?.[0]
                            ?.available
                          : "0"
                        : "0"}
                    </div>
                    <div className="resrve">{t("Available in Fleet")}</div>
                  </div>
                </div>) : <div
                  className="main_reservation flt"
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  onClick={() =>
                    navigate("/vehicles-available", {
                      state: { status: "available" },
                    })
                  }
                >
                <div className="right_image">
                  <img src={Avilable} alt="images" />
                </div>
                <div className="leftsidel">
                  <div className="Zeros">
                    {countResponse?.data?.data?.vehicleStatus.length !== 0
                      ? countResponse?.data?.data?.vehicleStatus?.[0].available
                        ? countResponse?.data?.data?.vehicleStatus?.[0]
                          ?.available
                        : "0"
                      : "0"}
                  </div>
                  <div className="resrve">{t("Available in Fleet")}</div>
                </div>
              </div>}
            </div>
            <div className="col-sm-2">

              {dashBoardDisplay?.includes("maintAlerts") ? (
                <div
                  className="main_reservation flt"
                  onClick={() => navigate("/maintenance-alert")}
                >
                  <div className="right_image">
                    <img src={services} alt="images" />
                  </div>
                  <div className="leftsidel">
                    <div className="Zeros">
                      {countResponse?.data?.data?.serviceAlerts}
                    </div>
                    <div className="resrve">{t("Maint. Alerts")}</div>
                  </div>
                </div>) : <div
                  className="main_reservation flt"
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  onClick={() => navigate("/maintenance-alert")}
                >
                <div className="right_image">
                  <img src={services} alt="images" />
                </div>
                <div className="leftsidel">
                  <div className="Zeros">
                    {countResponse?.data?.data?.serviceAlerts}
                  </div>
                  <div className="resrve">{t("Maint. Alerts")}</div>
                </div>
              </div>}
            </div>
            <div className="col-sm-2">
              {dashBoardDisplay?.includes("oilChangeAlert") ? (
                <div
                  className="main_reservation flt"
                  onClick={() => navigate("/oilChange-alert")}
                >
                  <div className="right_image">
                    <img src={services} alt="images" />
                  </div>
                  <div className="leftsidel">
                    <div className="Zeros">
                      {countResponse?.data?.data?.maintananceAlerts}
                    </div>
                    <div className="resrve">{t("Oil Change Alerts")}</div>
                  </div>
                </div>) : <div
                  className="main_reservation flt"
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  onClick={() => navigate("/oilChange-alert")}
                >
                <div className="right_image">
                  <img src={services} alt="images" />
                </div>
                <div className="leftsidel">
                  <div className="Zeros">
                    {countResponse?.data?.data?.maintananceAlerts}
                  </div>
                  <div className="resrve">{t("Oil Change Alerts")}</div>
                </div>
              </div>}
            </div>

            <div className="col-sm-2">

              {dashBoardDisplay?.includes("maintenance") ? (
                <div
                  className="main_reservation flt"
                  onClick={() =>
                    navigate("/vehicles-maintanance", {
                      state: { status: "maintanance" },
                    })
                  }
                >
                  <div className="right_image">
                    <img src={maint} alt="images" />
                  </div>
                  <div className="leftsidel">
                    <div className="Zeros">
                      {countResponse?.data?.data?.vehicleStatus.length !== 0
                        ? countResponse?.data?.data?.vehicleStatus?.[0]
                          ?.maintanance
                          ? countResponse?.data?.data?.vehicleStatus?.[0]
                            ?.maintanance
                          : "0"
                        : "0"}
                    </div>
                    <div className="resrve">{t("In Maintenance")}</div>
                  </div>
                </div>) : <div
                  className="main_reservation flt"
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  onClick={() =>
                    navigate("/vehicles-maintanance", {
                      state: { status: "maintanance" },
                    })
                  }
                >
                <div className="right_image">
                  <img src={maint} alt="images" />
                </div>
                <div className="leftsidel">
                  <div className="Zeros">
                    {countResponse?.data?.data?.vehicleStatus.length !== 0
                      ? countResponse?.data?.data?.vehicleStatus?.[0]
                        ?.maintanance
                        ? countResponse?.data?.data?.vehicleStatus?.[0]
                          ?.maintanance
                        : "0"
                      : "0"}
                  </div>
                  <div className="resrve">{t("In Maintenance")}</div>
                </div>
              </div>}
            </div>
            <div className="col-sm-2">
              {dashBoardDisplay?.includes("expirationDetails") ? (
                <div
                  className="main_reservation flt"
                  onClick={() => navigate("/vehicle-expiration")}
                >
                  <div className="right_image">
                    <img src={Expiration} alt="images" />
                  </div>
                  <div className="leftsidel">
                    <div className="Zeros">
                      {countResponse?.data?.data?.expirationDetails}
                    </div>
                    <div className="resrve">{t("Expiration Details")} </div>
                  </div>
                </div>) : <div
                  className="main_reservation flt"
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  onClick={() => navigate("/vehicle-expiration")}
                >
                <div className="right_image">
                  <img src={Expiration} alt="images" />
                </div>
                <div className="leftsidel">
                  <div className="Zeros">
                    {countResponse?.data?.data?.expirationDetails}
                  </div>
                  <div className="resrve">{t("Expiration Details")} </div>
                </div>
              </div>}
            </div>

            <div className="col-sm-2">
              {dashBoardDisplay?.includes("dayToDayTask") ? (
                <div
                  className="main_reservation flt"
                  onClick={() => navigate("/daytodaytask")}
                >
                  <div className="right_image">
                    <img src={Daytask} alt="images" />
                  </div>
                  <div className="leftsidel">
                    <div className="Zeros">
                      {countResponse?.data?.data?.dayTaskCount}
                    </div>
                    <div className="resrve">{t("Day to Day Tasks")}</div>
                  </div>
                </div>) : <div
                  className="main_reservation flt"
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  onClick={() => navigate("/daytodaytask")}
                >
                <div className="right_image">
                  <img src={Daytask} alt="images" />
                </div>
                <div className="leftsidel">
                  <div className="Zeros">
                    {countResponse?.data?.data?.dayTaskCount}
                  </div>
                  <div className="resrve">{t("Day to Day Tasks")}</div>
                </div>
              </div>}
            </div>
            <div className="col-sm-2">
              {dashBoardDisplay?.includes("pendingPayment") ? (
                <div
                  className="main_reservation flt"
                  onClick={() => navigate("/pending-payment")}
                >
                  <div className="right_image">
                    <img src={pending} alt="images" />
                  </div>
                  <div className="leftsidel">
                    <div className="Zeros">
                      {/* {countResponse?.data?.data?.pendingPayments} */}
                      {listPendingPayments?.pendingPayments}

                      {/* {listPendingPayments} */}
                    </div>
                    <div className="resrve">{t("Pending Payment")}</div>
                  </div>
                </div>) : <div
                  className="main_reservation flt"
                  style={{ pointerEvents: "none", opacity: "0.5" }}
                  onClick={() => navigate("/pending-payment")}
                >
                <div className="right_image">
                  <img src={pending} alt="images" />
                </div>
                <div className="leftsidel">
                  <div className="Zeros">
                    {/* {countResponse?.data?.data?.pendingPayments} */}
                    {listPendingPayments?.pendingPayments}
                  </div>
                  <div className="resrve">{t("Pending Payment")}</div>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </>
    );
  };
  const chartDisplay = () => {
    return (
      <>
        {dashBoardDisplay?.includes("vehicleStatusChart") ? (
          <div className="col-sm-6">
            <div className="main_circle flt">
              <div className="main_vehicle_status flt">
                <div className="main_vehicle_status flt">
                  <div className="v_status">{t("Vehicle Status")}</div>
                  <div className="all_vehicle">
                    <div className="main_vehicle_drop flt">
                      <select
                        name="vehicleClass"
                        id="vehicleClass"
                        onChange={handleVehicleClass}
                      >
                        <option value="">{t("All Vehicle Types")}</option>
                        {vehicleList &&
                          vehicleList.map(({ _id, vehicleClass }) => (
                            <option value={_id}>{vehicleClass}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_avilable_img flt">
                <div className="avile_image flt">
                  <Doughnut
                    data={chartData}
                    plugins={plugins}
                    options={{
                      animation: { duration: 500 },
                      responsive: true,

                      cutout: 100,
                    }}
                  />
                </div>
                <div className="main_color flt">
                  <div className="colors">
                    <ul className="colorss">
                      <li>
                        <div class="color_blue"></div>
                        <span>
                          {t("Available")} -{available}
                        </span>
                      </li>

                      <li>
                        <div class="color_yellow"></div>
                        <span>
                          {t("On Rent")} -{rent}
                        </span>
                      </li>

                      <li>
                        <div class="color_yellow1 "></div>
                        <span>
                          {t("Service")}-{service}
                        </span>
                      </li>

                      <li>
                        <div class="color_yellow2"></div>
                        <span>
                          {t("Sold")}- {sold}
                        </span>
                      </li>
                      <li>
                        <div class="color_yellow3"></div>
                        <span>
                          {t("Maintenance")}-{maintainance}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>) : <div className="col-sm-6"
            style={{ pointerEvents: "none", opacity: "0.5" }}
          >
          <div className="main_circle flt">
            <div className="main_vehicle_status flt">
              <div className="main_vehicle_status flt">
                <div className="v_status">{t("Vehicle Status")}</div>
                <div className="all_vehicle">
                  <div className="main_vehicle_drop flt">
                    <select
                      name="vehicleClass"
                      id="vehicleClass"
                      onChange={handleVehicleClass}
                    >
                      <option value="">{t("All Vehicle Types")}</option>
                      {vehicleList &&
                        vehicleList.map(({ _id, vehicleClass }) => (
                          <option value={_id}>{vehicleClass}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="main_avilable_img flt">
              <div className="avile_image flt">
                <Doughnut
                  data={chartData}
                  plugins={plugins}
                  options={{
                    animation: { duration: 500 },
                    responsive: true,

                    cutout: 100,
                  }}
                />
              </div>
              <div className="main_color flt">
                <div className="colors">
                  <ul className="colorss">
                    <li>
                      <div class="color_blue"></div>
                      <span>
                        {t("Available")} -{available}
                      </span>
                    </li>

                    <li>
                      <div class="color_yellow"></div>
                      <span>
                        {t("On Rent")} -{rent}
                      </span>
                    </li>

                    <li>
                      <div class="color_yellow1 "></div>
                      <span>
                        {t("Service")}-{service}
                      </span>
                    </li>

                    <li>
                      <div class="color_yellow2"></div>
                      <span>
                        {t("Sold")}- {sold}
                      </span>
                    </li>
                    <li>
                      <div class="color_yellow3"></div>
                      <span>
                        {t("Maintenance")}-{maintainance}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </>
    );
  };
  const quicklookup = () => {
    return (
      <>
        {dashBoardDisplay?.includes("quickLookUp") ? (
          <div className="col-sm-6">
            <div className="main_circle flt">
              <div className="main_vehicle_status flt">
                <div className="main_vehicle_status flt">
                  <div className="v_status1">{t("Quick Lookup")}</div>
                </div>
              </div>
              <div className="main_quick_look flt">
                <div className="phone_btn qlookphbtn flt">
                  <div className="main_cal_img search_date">
                    <Datetime
                      dateFormat="DD/MM/YYYY"
                      onChange={handleStartDate}
                      value={new Date(fromDate)}
                      closeOnSelect={true}
                      isValidDate={(currentDate, selectedDate) =>
                        currentDate.isAfter(
                          new Date().getTime() - 24 * 60 * 60 * 1000
                        )
                      }
                      inputProps={{
                        placeholder: "Select From date",
                        disabled: false,
                        readOnly: true,
                        onKeyDown: (e) => e.preventDefault(),
                      }}
                    />
                    <div className="cal_img">
                      <img src={Calender} alt="calender" />
                    </div>
                  </div>

                  <div className="main_cal_img search_daterh">
                    <Datetime
                      dateFormat="DD/MM/YYYY"
                      onChange={handleEndDate}
                      value={new Date(toDate)}
                      closeOnSelect={true}
                      isValidDate={(currentDate, selectedDate) =>
                        currentDate.isAfter(
                          new Date().getTime() - 24 * 60 * 60 * 1000
                        )
                      }
                      inputProps={{
                        placeholder: "Select To date",
                        disabled: false,
                        readOnly: true,
                        onKeyDown: (e) => e.preventDefault(),
                      }}
                    />
                    <div className="cal_img">
                      <img src={Calender} alt="calender" />
                    </div>
                  </div>

                  <div className="search_l search_main7">
                    <select
                      name="search"
                      id="item"
                      value={location}
                      onChange={changeLocationHandler}
                    >
                      <option value="">{t("Select Location Name")}</option>
                      {locationArray &&
                        locationArray.map(({ _id, locationName }) => (
                          <option value={_id}>{locationName}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="main_boxShadow qlook_view flt">
              <div className="main_table9 table-responsive flt">
                <table className="main_table1 qlookup_table">
                  <thead>
                    <tr>
                      <th width="200px;">{t("Vehicle Class")}</th>
                      <th>{t("Model")}</th>
                      <th>{t("Count")}</th>

                    </tr>
                  </thead>

                  {listData?.data?.data?.docs?.length > 0 &&
                    listData?.data?.data?.docs.map((list) => (
                      <tbody>
                        <tr>
                          <td>{list.vehicleclass ? list.vehicleclass : "N/A"}</td>
                          <td>{list?.model ? list?.model : "N/A"}</td>
                          <td>
                            {list?.vehiclecount ? list?.vehiclecount : "N/A"}
                          </td>

                        </tr>
                      </tbody>
                    ))}
                </table>

                {listData?.data?.data?.docs?.length === 0 && (
                  <div className="no-data">{t("No Data Found")}</div>
                )}
                <div>
                  <ReactPaginate
                    nextLabel="&rarr;"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={totalPages}
                    previousLabel="&larr;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    currentPage={page}
                    forcePage={page - 1}
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>) : <div className="col-sm-6"
            style={{ pointerEvents: "none", opacity: "0.5" }}
          >
          <div className="main_circle flt">
            <div className="main_vehicle_status flt">
              <div className="main_vehicle_status flt">
                <div className="v_status1">{t("Quick Lookup")}</div>
              </div>
            </div>
            <div className="main_quick_look flt">
              <div className="phone_btn qlookphbtn flt">
                <div className="main_cal_img search_date">
                  <Datetime
                    dateFormat="DD/MM/YYYY"
                    onChange={handleStartDate}
                    value={new Date(fromDate)}
                    closeOnSelect={true}
                    isValidDate={(currentDate, selectedDate) =>
                      currentDate.isAfter(
                        new Date().getTime() - 24 * 60 * 60 * 1000
                      )
                    }
                    inputProps={{
                      placeholder: "Select From date",
                      disabled: false,
                      readOnly: true,
                      onKeyDown: (e) => e.preventDefault(),
                    }}
                  />
                  <div className="cal_img">
                    <img src={Calender} alt="calender" />
                  </div>
                </div>

                <div className="main_cal_img search_daterh">
                  <Datetime
                    dateFormat="DD/MM/YYYY"
                    onChange={handleEndDate}
                    value={new Date(toDate)}
                    closeOnSelect={true}
                    isValidDate={(currentDate, selectedDate) =>
                      currentDate.isAfter(
                        new Date().getTime() - 24 * 60 * 60 * 1000
                      )
                    }
                    inputProps={{
                      placeholder: "Select To date",
                      disabled: false,
                      readOnly: true,
                      onKeyDown: (e) => e.preventDefault(),
                    }}
                  />
                  <div className="cal_img">
                    <img src={Calender} alt="calender" />
                  </div>
                </div>

                <div className="search_l search_main7">
                  <select
                    name="search"
                    id="item"
                    value={location}
                    onChange={changeLocationHandler}
                  >
                    <option value="">{t("Select Location Name")}</option>
                    {locationArray &&
                      locationArray.map(({ _id, locationName }) => (
                        <option value={_id}>{locationName}</option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="main_boxShadow qlook_view flt">
            <div className="main_table9 table-responsive flt">
              <table className="main_table1 qlookup_table">
                <thead>
                  <tr>
                    <th width="200px;">{t("Vehicle Class")}</th>
                    <th>{t("Model")}</th>
                    <th>{t("Count")}</th>

                  </tr>
                </thead>

                {listData?.data?.data?.docs?.length > 0 &&
                  listData?.data?.data?.docs.map((list) => (
                    <tbody>
                      <tr>
                        <td>{list.vehicleclass ? list.vehicleclass : "N/A"}</td>
                        <td>{list?.model ? list?.model : "N/A"}</td>
                        <td>
                          {list?.vehiclecount ? list?.vehiclecount : "N/A"}
                        </td>

                      </tr>
                    </tbody>
                  ))}
              </table>

              {listData?.data?.data?.docs?.length === 0 && (
                <div className="no-data">{t("No Data Found")}</div>
              )}
              <div>
                <ReactPaginate
                  nextLabel="&rarr;"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  pageCount={totalPages}
                  previousLabel="&larr;"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  currentPage={page}
                  forcePage={page - 1}
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>}
      </>
    );
  };

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      {dashboardCount()}
      <div className="main_card flt">
        <div className="row">
          {chartDisplay()}
          {quicklookup()}
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(Dashboard);
