import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import axios from "axios";
import { ReportService } from "../../../../services";
import moment from "moment";
import { alertActions } from "../../../../actions";

const useVehicleExpenses = (props) => {
  const { t } = props;
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loader, setLoader] = useState("notset");
  const navigate = useNavigate();
  const authdata = useSelector((state) => state?.userData);
  const [listTable, setListTable] = useState([]);
  const [loaderDownLoad, setLoaderDownload] = useState("notset");
  const dispatch = useDispatch();

  const columns = [
    {
      name: "Sr No",
      selector: (row) => row.srnumber,
      // width: "80px"
    },
    {
      name: "Date",
      selector: (row) => row?.date,
      // width: "80px"
    },

    {
      name: "Reg No",
      selector: (row) => row.regNo,
      // width: "180px"
    },

    {
      name: "Vehicle Details",
      selector: (row) => row.Vehicle_Details,
      // width: "250px",
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      // width: "250px",
    },
    {
      name: "Ref/Invoice No.",
      selector: (row) => row?.Invoice,
      // width: "250px",
    },
    // {
    //   name: "ODO Meter",
    //   selector: (row) => row.ODO_Meter,
    //   // width: "180px",
    // },
    {
      name: "Amount",
      selector: (row) => (row.Amount !== 0 ? `₹${row.Amount}` : "N/A"),
    },
    {
      name: "Service Type",
      selector: (row) => row?.service,
    },
    // {
    //   name: "View",
    //   selector: (row) => (
    //     <button className="viewdata" onClick={() => handleRow(row)}>
    //       {t("View")}
    //     </button>
    //   ),

    // },
  ];
  let order = [];
  let obj = {};

  /**
   * call response
   */
  useEffect(() => {
    if (fromDate !== "" || toDate !== "") {
      vehicleExpenseListResponse(page);
    }
    // eslint-disable-next-line
  }, [page, fromDate, toDate]);

  /**
   * list call
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * list
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      obj = {
        srnumber:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,

        Vehicle_Details:
          item?.vehicleClass?.[0]?.vehicleClass +
          " " +
          item?.vehicleMake?.[0]?.make +
          " " +
          item?.vehicleModel?.[0]?.model,
        date: moment.utc(item?.date).format("DD/MM/YYYY"),
        ODO_Meter: item?.ODOMeterNo ? item?.ODOMeterNo : "N/A",
        Amount: item?.totalExp,
        Invoice: item?.invoiceNumber ? item?.invoiceNumber : "N/A",
        description: item?.description ? item?.description : "N/A",
        maintExpense: item?.maintexpenses,
        oilExpense: item?.oilexpenses,
        totalMaintExp: item?.totalMaintExp,
        totalOilExp: item?.totalOilExp,
        service: item?.serviceType,
        id: item._id,
        regNo: item?.regNumber ? item?.regNumber : "N/A",
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * list api call
   */
  const vehicleExpenseListResponse = () => {
    if (toDate) {
      if (Date.parse(fromDate) > Date.parse(toDate)) {
        dispatch(
          alertActions.error("End Date Should be Greater than Start Date")
        );
      } else {
        setLoader("loading");
        ReportService.getvehicleExpenseReportList(page, fromDate, toDate)
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setLoader("isfull");
              getPaymentAmount();
              setListData(response);
              setTotalPages(response?.data?.data?.totalPages);
              setPage(response.data.data.page);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  /**
   * get payment
   */
  const getPaymentAmount = () => {
    ReportService.getVehicleExpensePaymentAmount(fromDate, toDate)
      .then((response) => {
        setTotalAmount(
          response?.data?.data?.docs?.[0]?.total
            ? response?.data?.data?.docs?.[0]?.total
            : 0
        );
      })
      .catch((err) => console.log(err));
  };

  /**
   * download
   */
  const downloadReport = () => {
    setLoaderDownload("loading");
    axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_APIURL}/admin/reports/expenses-excel?from=${fromDate}&to=${toDate}`,

      method: "GET",
      responseType: "arraybuffer",

      headers: {
        // Add any auth token here
        authorization: authdata?.type + " " + authdata?.token,
        "Content-Type": "blob",
      },
      // Attaching the form data
    }).then((response) => {
      setLoaderDownload("isfull");
      const resp = response;
      const link = document.createElement("a");
      const fileName = `${Date.now()}.xlsx`;
      link.setAttribute("download", fileName);
      link.href = URL.createObjectURL(new Blob([resp.data]));
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    navigate("/VehicleExpenseDetails", { state: { row: row } });
  };

  /**
   * handle change
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };
  const handleStartDate = (e) => {
    setFromDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleEndDate = (e) => {
    setToDate(moment(e).format("DD/MM/YYYY"));
  };

  return {
    page,
    loader,
    listData,
    totalPages,
    fromDate,
    toDate,
    totalAmount,
    columns,
    listTable,
    loaderDownLoad,
    handlePageClick,
    handleStartDate,
    handleEndDate,
    downloadReport,
  };
};
export default useVehicleExpenses;
