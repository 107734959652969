import { Calender } from "../../../../../../../../assets/images";
import { Ic_Home14 } from "../../../../../../../../assets/images";
import useDocumentDetails from "./useBookingDocumentDetailsInfo";
import { Loading } from "../../../../../../../../assets/images";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const DocumentDetails = ({ displayData, bookid }) => {
  const {
    loader,
    adhar_number,
    drivinglicense_number,
    drivinglicense_expirydate,
    fileName1,
    selectedFile1,
    drivinglicense_frontsideimage,
    fileName2,
    selectedFile2,
    drivinglicense_backsideimage,
    fileName3,
    selectedFile3,
    id_frontside,
    fileName4,
    selectedFile4,
    id_backside,
    submit,
    alternate_id,
    handleAlternateId,
    handleAdhar_numberChange,
    handleDrivingLicense_numberChange,
    handleDrivingLicense_expirydateChange,
    handleSubmit,
    onSelectFile1,
    onSelectFile2,
    onSelectFile3,
    onSelectFile4,
  } = useDocumentDetails(displayData, bookid);

  /**
   * driving license
   */
  let diffDays;
  var difference;
  if (
    drivinglicense_expirydate !== "" &&
    drivinglicense_expirydate !== undefined
  ) {
    const today = new Date();
    const arr = drivinglicense_expirydate.split("/");
    const date = new Date(arr[2], arr[1] - 1, arr[0]);
    const diffTime = date - today;
    difference = date > today;
    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  const DocumentDetails = () => {
    return (
      <>
        {" "}
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
          <div className="main_boxShadow flt">
            <div className="main_company flt">
              <div className="main_row flt">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Aadhar Number</label>
                        <input
                          type="text"
                          placeholder="Aadhar Number"
                          name="adhar_number"
                          id="adhar_number"
                          value={adhar_number}
                          onChange={handleAdhar_numberChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">
                          Driving Licence Number
                        </label>
                        <input
                          type="text"
                          placeholder="Driving Licence Number"
                          name="drivinglicense_number"
                          id="drivinglicense_number"
                          value={drivinglicense_number}
                          onChange={handleDrivingLicense_numberChange}
                        />
                        {/* {submit && !drivinglicense_number && (
                          <div>Driving Licence Number is required</div>
                        )} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Licence Expiry Date</label>

                        <Datetime
                          dateFormat="DD/MM/YYYY"
                          onChange={handleDrivingLicense_expirydateChange}
                          value={drivinglicense_expirydate}
                          closeOnSelect={true}
                          timeFormat={false}
                          inputProps={{
                            placeholder: "Select date",
                            disabled: false,
                            readOnly: true,
                            onKeyDown: (e) => e.preventDefault(),
                          }}
                        />
                        <div className="cal_img">
                          <img src={Calender} alt="calender" />
                        </div>
                        {/* {submit && !drivinglicense_expirydate && (
                          <div>Licence Expiry Date is required</div>
                        )} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">ID Number</label>
                        <input
                          type="text"
                          placeholder="ID Number"
                          name="alternate_id"
                          id="alternate_id"
                          value={alternate_id}
                          onChange={handleAlternateId}
                        />
                      </div>
                    </div>
                  </div>
                  {difference === true && (
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">&nbsp;</label>
                          <div className="exp_date flt">
                            Expiry days Remaining : {diffDays} days
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {difference === false && (
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">&nbsp;</label>
                          <div className="exp_date flt">Already Expired</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group flt">
                      <label className="labelName">DL Front Side Image *</label>
                    </div>

                    <div className="upload_img flt">
                      <div className="upload_img_btn flt">
                        <button>
                          {!selectedFile1 && drivinglicense_frontsideimage && (
                            <img
                              src={`${process.env.REACT_APP_MEDIA_DOMAIN}${drivinglicense_frontsideimage}`}
                              width="100%"
                              height="150"
                              alt="upload"
                            />
                          )}

                          {selectedFile1 && (
                            <img
                              src={drivinglicense_frontsideimage}
                              width="100%"
                              height="150"
                              alt="upload"
                            />
                          )}

                          {!selectedFile1 && !drivinglicense_frontsideimage && (
                            <img src={Ic_Home14} alt="upload" />
                          )}

                          {drivinglicense_frontsideimage === "" && (
                            <div className="browse_logo_img">
                              DL Front Side Image
                            </div>
                          )}
                        </button>

                        <input
                          type="file"
                          name="myfile1"
                          onChange={onSelectFile1}
                          key={fileName1}
                          id="myfile1"
                          accept="image/png,image/jpeg"
                        />
                        {/* 
                          {submit &&
                            selectedFile1 &&
                            !drivinglicense_frontsideimage && (
                              <div>DL Front Side Image is required</div>
                            )} */}
                      </div>{" "}
                    </div>
                    <div className="main_downld flt">
                      {drivinglicense_frontsideimage && (
                        <a
                          href={`${process.env.REACT_APP_MEDIA_DOMAIN}${drivinglicense_frontsideimage}`}
                        >
                          Download
                        </a>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="form-group flt">
                      <label className="labelName">DL Back Side Image </label>
                    </div>

                    <div className="upload_img flt">
                      <div className="upload_img_btn flt">
                        <button>
                          {!selectedFile2 && drivinglicense_backsideimage && (
                            <img
                              src={`${process.env.REACT_APP_MEDIA_DOMAIN}${drivinglicense_backsideimage}`}
                              width="100%"
                              height="150"
                              alt="upload"
                            />
                          )}

                          {selectedFile2 && (
                            <img
                              src={drivinglicense_backsideimage}
                              width="100%"
                              height="150"
                              alt="upload"
                            />
                          )}

                          {!selectedFile2 && !drivinglicense_backsideimage && (
                            <img src={Ic_Home14} alt="upload" />
                          )}
                          {drivinglicense_backsideimage === "" && (
                            <div className="browse_logo_img">
                              DL Back Side Image
                            </div>
                          )}
                        </button>

                        <input
                          type="file"
                          name="myfile2"
                          onChange={onSelectFile2}
                          key={fileName2}
                          id="myfile2"
                          accept="image/png,image/jpeg"
                        />
                        {/* {submit &&
                            selectedFile2 &&
                            !drivinglicense_backsideimage && (
                              <div>DL Back Side Image is required</div>
                            )} */}
                      </div>
                    </div>
                    <div className="main_downld flt">
                      {drivinglicense_backsideimage && (
                        <a
                          href={`${process.env.REACT_APP_MEDIA_DOMAIN}${drivinglicense_backsideimage}`}
                        >
                          Download
                        </a>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="form-group flt">
                      <label className="labelName">ID Front Side *</label>
                    </div>
                    <div className="upload_img flt">
                      <div className="upload_img_btn flt">
                        <button>
                          {!selectedFile3 && id_frontside && (
                            <img
                              src={`${process.env.REACT_APP_MEDIA_DOMAIN}${id_frontside}`}
                              width="100%"
                              height="150"
                              alt="upload"
                            />
                          )}
                          {selectedFile3 && (
                            <img
                              src={id_frontside}
                              width="100%"
                              height="150"
                              alt="upload"
                            />
                          )}

                          {!selectedFile3 && !id_frontside && (
                            <img src={Ic_Home14} alt="upload" />
                          )}
                          {id_frontside === "" && (
                            <div className="browse_logo_img">ID Front Side</div>
                          )}
                        </button>
                        <input
                          type="file"
                          name="myfile3"
                          onChange={onSelectFile3}
                          key={fileName3}
                          id="myfile3"
                          accept="image/png,image/jpeg"
                        />
                        {/* {submit && selectedFile3 && !id_frontside && (
                            <div>ID Front Side Image is required</div>
                          )} */}
                      </div>
                    </div>
                    <div className="main_downld flt">
                      {id_frontside && (
                        <a
                          href={`${process.env.REACT_APP_MEDIA_DOMAIN}${id_frontside}`}
                        >
                          Download
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group flt">
                      <label className="labelName">ID Back Side *</label>
                    </div>
                    <div className="upload_img flt">
                      <div className="upload_img_btn flt">
                        <button>
                          {!selectedFile4 && id_backside && (
                            <img
                              src={`${process.env.REACT_APP_MEDIA_DOMAIN}${id_backside}`}
                              width="100%"
                              height="150"
                              alt="upload"
                            />
                          )}
                          {!selectedFile4 && !id_backside && (
                            <img src={Ic_Home14} alt="upload" />
                          )}{" "}
                          {selectedFile4 && (
                            <img
                              src={id_backside}
                              width="100%"
                              height="150"
                              alt="upload"
                            />
                          )}
                          {id_backside === "" && (
                            <div className="browse_logo_img">ID Back Side</div>
                          )}
                        </button>
                        <input
                          type="file"
                          name="myfile4"
                          onChange={onSelectFile4}
                          key={fileName4}
                          id="myfile4"
                          accept="image/png,image/jpeg"
                        />
                        {/* {submit && selectedFile4 && !id_backside && (
                            <div>ID Back Side Image is required</div>
                          )} */}
                      </div>
                    </div>
                    <div className="main_downld flt">
                      {id_backside && (
                        <a
                          href={`${process.env.REACT_APP_MEDIA_DOMAIN}${id_backside}`}
                        >
                          Download
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <div className="main_ownbutton flt">
                  <div className="own_button flt">
                    <button
                      className="backbutton"
                      onClick={() => {
                        displayData(1);
                      }}
                    >
                      Back
                    </button>

                    <button className="submitbutton" onClick={handleSubmit}>
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return <div className="E_comp_details flt">{DocumentDetails()}</div>;
};
export default DocumentDetails;
