import { useEffect, useState } from "react";
import { alertActions } from "../../../../../../../../actions/alert.actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { AgreementService } from "../../../../../../../../services/AgreementService";
import { StartrentService } from "../../../../../../../../services";
import moment from "moment";

const usePersonalInfoHoook = (
  location,
  displayData,
  tab,
  bookingid,
  setbookingid,
  cid1,
  t
) => {
  const [id, setId] = useState(
    location?.state?.row?.id ? location?.state?.row?.id : ""
  );
  const navigate = useNavigate();
  const [date_of_birth, setdate_of_birth] = useState(
    id !== "" ? location?.state?.row.date_of_birth : ""
  );
  const [name, setName] = useState(
    id !== "" ? location?.state?.row.fullname : ""
  );
  const [email, setEmail] = useState(
    id !== "" ? location?.state?.row.email : ""
  );
  const [mblno, setMblno] = useState(
    id !== "" ? location?.state?.row.mobileno : ""
  );
  const [altno, setAltno] = useState(
    id !== "" ? location?.state?.row.alternatemobileno : ""
  );
  const [age, setAge] = useState(id !== "" ? location?.state?.row.age : "");
  const [dob, setDob] = useState();
  const [submitted, setSubmitted] = useState(false);
  const bookid = location.search.split("=")[2];
  const cid = location.search.split("=")[1]?.substring(0, 24);
  const [loader, setLoader] = useState("notset");
  const dispatch = useDispatch();

  useEffect(() => {
    if (bookid) {
      setLoader("loading");
      StartrentService.getAllData(bookid)
        .then((res) => {
          if (res?.data?.status?.code === 0) {
            setName(res?.data?.data?.fullname);
            setEmail(res?.data?.data?.email);
            setMblno(res?.data?.data?.mobileno);
            setAltno(res?.data?.data?.alternatemobileno);
            setdate_of_birth(res?.data?.data?.date_of_birth);
            ageDifference(res?.data?.data?.date_of_birth);
            setLoader("isfull");
          } else {
            console.log("error");
            setLoader("isempty");
          }
        })
        .catch((err) => console.log(err));
    } else {
      if (cid) {
        setLoader("loading");
        StartrentService.getCustData(bookid === undefined ? cid : bookid)
          .then((res) => {
            if (res?.data?.status?.code === 0) {
              setName(res?.data?.data?.fullname);
              setEmail(res?.data?.data?.email);
              setMblno(res?.data?.data?.mobileno);
              setAltno(res?.data?.data?.alternatemobileno);
              setdate_of_birth(res?.data?.data?.date_of_birth);
              setLoader("isfull");
            } else {
              console.log("error");
              setLoader("isempty");
            }
          })
          .catch((err) => console.log(err));
      }
    }
    // eslint-disable-next-line
  }, []);

  /**
   * age calculation
   * @param {*} date_of_birth
   */
  const ageDifference = (date_of_birth) => {
    let ageDiff = "";
    if (date_of_birth !== "" && date_of_birth !== undefined) {
      const today = new Date();
      const dateParts = date_of_birth.split("/");

      const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

      const diffTime = Math.abs(date - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      ageDiff = Math.round((diffDays / 365) * 10) / 10;
      setAge(ageDiff > 1 ? ageDiff + " " + "years" : ageDiff + " " + " year");
    }
  };
  /**
   * handle change
   * @param {*} e
   */
  const handleChange = (e) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    }
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
    if (e.target.name === "mblno") {
      setMblno(e.target.value);
    }
    if (e.target.name === "altno") {
      setAltno(e.target.value);
    }
  };

  /**
   * update personal info
   * @param {*} e
   */
  const ContinueInfo = (e) => {
    e.preventDefault();
    setSubmitted(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (name && mblno) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error(t("enter_valid_email")));
      } else if (mblno && !filter.test(mblno)) {
        dispatch(alertActions.error(t("enter_mobile_no")));
      } else if (altno !== "" && !filter.test(altno)) {
        dispatch(alertActions.error(t("enter_alt_no")));
      } else {
        if (bookid) {
          setLoader("loading");
          AgreementService.personalinfo_update(
            name,
            email,
            mblno,
            altno,
            date_of_birth,
            bookid
          )
            .then((response) => {
              if (response?.data?.status?.code === 0) {
                setbookingid(response?.data?.data?._id);
                setSubmitted(false);
                setLoader("isfull");
                displayData(1);

                dispatch(alertActions.success(response?.data?.status?.message));
              } else {
                setLoader("isempty");
                dispatch(alertActions.error(response?.data?.status?.message));
              }
            })
            .catch((err) => console.log(err));
        } else {
          //api call
          if (location?.state?.row?.id) {
            setLoader("loading");
            AgreementService.addExistingCustomerDetails(
              name,
              email,
              mblno,
              altno,
              date_of_birth,
              location?.state?.row?.id
            )
              .then((response) => {
                if (response?.data?.status?.code === 0) {
                  setbookingid(response?.data?.data?._id);
                  setSubmitted(false);
                  setLoader("isfull");
                  displayData(1);

                  navigate(
                    `/agreeTab?cid=${cid}&bid=${response?.data?.data?._id}`,
                    {
                      state: {
                        bookid: response?.data?.data?._id,
                      },
                    }
                  );
                  dispatch(
                    alertActions.success(response?.data?.status?.message)
                  );
                } else {
                  setLoader("isempty");
                  dispatch(alertActions.error(response?.data?.status?.message));
                }
              })
              .catch((err) => console.log(err));
          } else {
            setLoader("loading");
            AgreementService.addCustomerDetails(
              name,
              email,
              mblno,
              altno,
              date_of_birth
            )
              .then((response) => {
                if (response?.data?.status?.code === 0) {
                  setbookingid(response?.data?.data?._id);
                  setSubmitted(false);
                  setLoader("isfull");
                  displayData(1);
                  navigate(`/agreeTab/${response?.data?.data?._id}`, {
                    state: {
                      bookid: response?.data?.data?._id,
                    },
                  });

                  dispatch(
                    alertActions.success(response?.data?.status?.message)
                  );
                } else {
                  setLoader("isempty");
                  dispatch(alertActions.error(response?.data?.status?.message));
                }
              })
              .catch((err) => console.log(err));
          }
        }
      }
    }
  };
  //name required
  /**
   *
   * @returns
   */
  const validname = () => {
    if (name === "") {
      return false;
    }
  };

  const validemail = () => {
    if (email === "") {
      return false;
    }
  };

  const validmblno = () => {
    if (mblno === "" || mblno?.length < 10) {
      return false;
    }
  };

  const validaltno = () => {};

  const validdate_of_birth = () => {
    if (date_of_birth === "") {
      return false;
    }
  };
  /**
   * handle change
   * @param {*} e
   */
  const handleChange1 = (e) => {
    setAge("");
    setdate_of_birth(moment(e).format("DD/MM/YYYY"));
    ageDifference(moment(e).format("DD/MM/YYYY"));
  };
  return {
    date_of_birth,
    name,
    email,
    mblno,
    altno,
    dob,
    handleChange,
    handleChange1,
    ContinueInfo,
    submitted,
    validname,
    validemail,
    validmblno,
    validaltno,
    validdate_of_birth,
    age,
    loader,
  };
};
export default usePersonalInfoHoook;
