import { searchvehicle } from "../../../../../../../../assets/images";
import { Calender } from "../../../../../../../../assets/images";
import { useState, useEffect } from "react";
import useRentalDetailsHook from "./useWalkinRentalDetailsHook";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Loading } from "../../../../../../../../assets/images";

const AddRentalDetailsInfo = ({ displayData, cid, bid }) => {
  const [rowinfo, setrowinfo] = useState("");

  const {
    loader,
    make,
    makeList,
    vehicleList,
    model,
    modelList,
    listData,
    totalPages,
    datetimevalue,
    datetimeendvalue,
    locations,
    helmets_qty,
    travel,
    vehicleClass,
    submitted,
    datavalue,
    classId,
    vehRes,
    vehicleId,
    rlocations,
    returnLocation,
    search,
    page,
    changeItemHandler,
    changeSearchHandler,
    handleVehicleClass,
    handleMake,
    handleModel,
    handlePageClick,
    onChangeValue,
    onChangeendValue,
    changeHandlerrelocation,
    changeHandlerlocation,
    helmets_qtys,
    travelLocation,
    SearchVehicle,
    continueGetData,
    setdatavalue,
  } = useRentalDetailsHook(displayData, cid, bid, rowinfo);
  const columns = [
    {
      name: "Stk No",
      selector: (row) => row.srnumber,
      width: "80px"
    },
    {
      name: "Reg No",
      selector: (row) => row.regNumber,
      width: "150px"
    },
    {
      name: "Make",
      selector: (row) => row.make,
    },
    {
      name: "Model",
      selector: (row) => row.model,
    },
    {
      name: "Vehicle Class",
      selector: (row) => row.vclass,
      width: "150px"
    },
    {
      name: "Location",
      selector: (row) => row.location,
      width: "150px"
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={
            row.status === "available" ? "button_style" : "button_style1"
          }
        >
          {row.status}
        </div>
      ),
      width: "150px"
    },
    {
      name: "Select Vehicle",
      selector: (row) => (
        <button className="viewdata" onClick={() => handleRow(row)}>
          Select
        </button>
      ),
      width: "150px"
    },
  ];

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const [listTable, setListTable] = useState([]);
  let order = [];
  let obj = {};
  let diffDays;
  let difference;
  if (datetimevalue != "" && datetimeendvalue !== "") {
    var date1 = new Date(datetimevalue);
    var date2 = new Date(datetimeendvalue);
    // diffDays = date2.getDate() - date1.getDate();
    const diffTime = Math.abs(date2 - date1);
    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
  /**
   * get table list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * get vehicle
   */
  useEffect(() => {
    if (vehicleId !== "") {
      setdatavalue(3);
    }
    // eslint-disable-next-line
  }, [vehicleId !== ""]);

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    setrowinfo(row);
    setdatavalue(3);
  };

  const addRentailInfo = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          <div className="Company_name flt">
            <div className="comp_name_details flt">Add Rental details</div>
          </div>
          {loader === "loading" && (
            <div className="main_load ">
              <img src={Loading} alt="loader" />
            </div>
          )}
          {(loader === "isfull" ||
            loader === "isempty" ||
            loader === "notset") && (
              <div className="main_company flt">
                <div className="main_row flt">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className=" company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            Start Date-Start Time *
                          </label>
                          <div className="main_cal_img">
                            <Datetime
                              dateFormat="DD/MM/YYYY"
                              onChange={onChangeValue}
                              value={new Date(datetimevalue)}
                              // isValidDate={(currentDate, selectedDate) =>
                              //   currentDate.isAfter(
                              //     new Date().getTime() - 24 * 60 * 60 * 1000
                              //   )
                              // }
                              inputProps={{
                                placeholder: "Select Date",
                                disabled: false,
                                readOnly: true,
                                onKeyDown: (e) => e.preventDefault(),
                              }}
                            />
                            <div className="cal_img">
                              <img
                                src={Calender}
                                alt="calender"
                                onChange={Datetime}
                              />
                            </div>
                          </div>
                          {submitted && !datetimevalue && (
                            <div className="invalid-feedback">
                              Start Date-Start time is required
                            </div>
                          )}
                        </div>
                      </div>




                    </div>
                    <div className="col-sm-3">
                      <div className=" company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">End Date-End Time *<span className="numdays"> No of days : {diffDays ? diffDays : "0"}</span></label>
                          <div className="main_cal_img">
                            <Datetime
                              dateFormat="DD/MM/YYYY"
                              onChange={onChangeendValue}
                              value={new Date(datetimeendvalue)}
                              // isValidDate={(currentDate, selectedDate) =>
                              //   currentDate.isAfter(
                              //     new Date().getTime() - 24 * 60 * 60 * 1000
                              //   )
                              // }
                              inputProps={{
                                placeholder: "Select Date",
                                disabled: false,
                                readOnly: true,
                                onKeyDown: (e) => e.preventDefault(),
                              }}
                            />
                            <div className="cal_img">
                              <img
                                src={Calender}
                                alt="calender"
                                onChange={Datetime}
                              />
                            </div>
                          </div>
                          {submitted && !datetimeendvalue && (
                            <div className="invalid-feedback">
                              End Date-End Time is required
                            </div>
                          )}
                        </div>
                      </div>

                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName"> Pick Location *</label>
                          <select
                            name="Location"
                            id="lang"
                            onChange={changeHandlerrelocation}
                            value={rlocations}
                          >
                            <option value="">Pick Location</option>
                            {locations
                              ? locations.map((value) => (
                                <option
                                  value={value._id}
                                  key={value.locationName}
                                >
                                  {value.locationName}
                                </option>
                              ))
                              : ""}
                          </select>
                        </div>
                        {submitted && !rlocations && (
                          <div className="invalid-feedback">
                            Pick Location is required
                          </div>
                        )}
                      </div>

                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName"> Return Location *</label>

                          <select
                            name="location"
                            onChange={changeHandlerlocation}
                            value={returnLocation}
                          >
                            <option value="">
                              Please select Return Location
                            </option>
                            {locations
                              ? locations.map((value) => (
                                <option
                                  value={value._id}
                                  key={value.locationName}
                                >
                                  {value.locationName}
                                </option>
                              ))
                              : ""}
                          </select>
                        </div>
                        {submitted && !returnLocation && (
                          <div className="invalid-feedback">
                            Return Location is required
                          </div>
                        )}
                      </div>

                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-3"><div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Helmet Qty</label>
                        <select
                          name="helmets_qty"
                          id="helmets_qty"
                          onChange={helmets_qtys}
                          value={helmets_qty}
                        >
                          <option value="">Select Helmet Qty</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </select>
                      </div>
                    </div></div>
                    <div className="col-sm-3"><div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Travel Location</label>
                        <input
                          className="comp_input"
                          type="text"
                          placeholder="Travel Loaction"
                          name="Travel"
                          id="Travel"
                          value={travel}
                          onChange={travelLocation}
                        />
                      </div>
                    </div></div>
                    <div className="col-sm-3"> <div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Vehicle Class *</label>
                        <select
                          name="vehicleClass"
                          id="vehicleClass"
                          onChange={handleVehicleClass}
                          value={vehicleClass}
                        >
                          <option value="">Select Vehicle Class</option>
                          {vehicleList &&
                            vehicleList.map(({ _id, vehicleClass }) => (
                              <option value={_id}>{vehicleClass}</option>
                            ))}
                        </select>
                      </div>
                      {submitted && !classId && (
                        <div className="invalid-feedback">
                          Vehicle Class is required
                        </div>
                      )}
                    </div></div>
                    <div className="col-sm-3"><div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Make *</label>
                        <select
                          name="make"
                          id="make"
                          onChange={handleMake}
                          value={make}
                        >
                          <option value="">Select Make</option>
                          {makeList &&
                            makeList.map(({ _id, make }) => (
                              <option value={_id}>{make}</option>
                            ))}
                        </select>
                      </div>
                      {submitted && !make && (
                        <div className="invalid-feedback">Make is required</div>
                      )}
                    </div></div>
                  </div>

                  <div className="row">
                    <div className="col-sm-3"><div className="company_name flt">
                      <div className="form-group flt">
                        <label className="labelName">Model *</label>
                        <select
                          name="model"
                          id="model"
                          onChange={handleModel}
                          value={model}
                        >
                          <option value="">Select Model</option>
                          {modelList &&
                            modelList.map(({ _id, model }) => (
                              <option value={_id}>{model}</option>
                            ))}
                        </select>
                      </div>
                      {submitted && !model && (
                        <div className="invalid-feedback">
                          Model is required
                        </div>
                      )}
                    </div></div>
                    <div className="col-sm-3"></div>
                    <div className="col-sm-3"></div>
                    <div className="col-sm-3"></div>
                  </div>
                  <div className="delete_b flt">
                    <button className="delete_btn12" onClick={SearchVehicle}>
                      <img src={searchvehicle} alt="vehicle" />
                      Show Vehicle Available
                    </button>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  };
  /**
   * table list
   */
  const list = () => {
    listData.data.data.docs.map((item, index) => {
      obj = {
        srnumber:
          listData.data.data.limit * (listData.data.data.page - 1) + index + 1,
        make: item?.vehiclemake?.[0]?.make,
        model: item?.vehicalmodel?.[0]?.model,
        vclass: item?.vehicleclass?.[0]?.vehicleClass,
        location: item.location?.[0]?.locationName,
        status: item?.vehicle_status,
        regNumber: item?.regNumber,
        id: item?._id,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };
  const headerDisply = () => {
    return (
      <div className="booking_srchmain flt">
        <div className="main_search_b2 booking_newb44 flt">
          <div className="ex_customer">Vehicles List</div>

          <div className="filler"></div>
          <div className="search_l booking_searchn44">
            <select name="item" id="item"
              onChange={changeItemHandler}
            >
              <option value="regNo">Search by Reg No.</option>

            </select>
          </div>
          <div className="search_b1 booking_srchmm ">
            <input
              type="text"
              placeholder="Search.."
              name="search"
              onChange={changeSearchHandler}
              value={search}
            />
            <button className="search_button">
              <i class="fa fa-search"></i>
            </button>
          </div>



        </div>
      </div>
    )
  }
  const tableListDisplay = () => {
    return (
      <>
        {headerDisply()}
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="main_load ">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  {listData?.data?.data?.docs?.length !== 0 ? (
                    <DataTable
                      columns={columns}
                      data={listTable || []}
                      customStyles={customStyles}
                    />
                  ) : (
                    <div className="no-data">No Data Found</div>
                  )}

                  {listData?.data?.data?.docs.length !== 0 && (
                    <div>
                      <ReactPaginate
                        nextLabel="&rarr;"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalPages}
                        previousLabel="&larr;"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        forcePage={page - 1}
                      />
                    </div>
                  )}
                </table>
              )}
            </div>
          </div>
          <div className="ownbutton1 flt">
            <div className="own_button flt">
              <button className="submitbutton" onClick={() => setdatavalue(0)}>
                Back
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  const selectvehicles = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          <div className="main_car_details flt">
            <div className="row">
              <div className="col-sm-4">
                <div className="car_details flt">Selected Vehicle</div>
                <div className="details_car flt">
                  <div className="model_details flt">
                    <ul className="make_details flt">
                      <li>
                        Reg Number<span>:</span>
                      </li>

                      <li>
                        {rowinfo?.regNumber
                          ? rowinfo?.regNumber
                          : vehRes?.data?.data?.regNumber}
                      </li>
                      <li>
                        Vehicle Class<span>:</span>
                      </li>
                      <li>
                        {rowinfo?.vclass
                          ? rowinfo?.vclass
                          : vehRes?.data?.data?.vehicleclass}
                      </li>
                      <li>
                        Make<span>:</span>
                      </li>

                      <li>
                        {rowinfo?.make
                          ? rowinfo?.make
                          : vehRes?.data?.data?.vehiclemake}
                      </li>

                      <li>
                        Model<span>:</span>
                      </li>

                      <li>
                        {rowinfo?.model
                          ? rowinfo?.model
                          : vehRes?.data?.data?.vehiclemodel}
                      </li>

                      <li>
                        Location<span>:</span>
                      </li>

                      <li>
                        {rowinfo?.location
                          ? rowinfo?.location
                          : vehRes?.data?.data?.location}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="main_ownbutton flt">
                <div className="own_button flt">
                  <button
                    onClick={() => setdatavalue(1)}
                    className="backbutton"
                  >
                    Back
                  </button>
                  <button onClick={continueGetData} className="submitbutton">
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  };
  return (
    <>
      {loader === "loading" && (
        <div className="main_load ">
          <img src={Loading} alt="loader" />
        </div>
      )}
      {datavalue !== 1 && addRentailInfo()}
      {datavalue === 1 && tableListDisplay()}
      {datavalue === 3 && selectvehicles()}
    </>
  );
};
export default AddRentalDetailsInfo;
