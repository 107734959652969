import useVehicleCostHooks from "./useVehicleCostHooks";
import { withTranslation } from "react-i18next";
import { Loading } from "../../../../../assets/images";

const VehicleCost = (props) => {
  const { t } = props;
  const {
    loader,
    vehicleList,
    classId,
    makeList,
    makeId,
    model,
    submit,
    displaythirty,
    thirtyArray,
    modelList,
    extraCharge,
    handleVehClass,
    handleMake,
    handleSubmit,
    handleModel,
    handleshowSubmit,
    handleExtraChange,
    handleArrayCost,
    handleArrayKm,
  } = useVehicleCostHooks();


  const costDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" || loader === "isempty" || loader === "notset") && (
          <div className="main_row flt">
            <div className="row">
              <div className="col-sm-3">
                <div className="company_name flt">
                  <div className="form-group flt">
                    <label className="labelName">{t("Vehicle Class")} *</label>
                    <select
                      name="vehClass"
                      id="vehClass"
                      onChange={handleVehClass}
                      value={classId}
                    >
                      <option value="">{t("Select Vehicle Class")}</option>
                      {vehicleList &&
                        vehicleList.map(({ _id, vehicleClass }) => (
                          <option value={_id}>{vehicleClass}</option>
                        ))}
                    </select>
                    {submit && classId === "" && (
                      <div className="req_field">{t("Vehicle Class is required")}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="company_name flt">
                  <div className="form-group flt">
                    <label className="labelName">{t("Make")} *</label>
                    <select
                      name="make"
                      id="make"
                      onChange={handleMake}
                      value={makeId}
                    >
                      <option value="">{t("Select Make")}</option>
                      {makeList &&
                        makeList.map(({ _id, make }) => (
                          <option value={_id}>{make}</option>
                        ))}
                    </select>
                    {submit && makeId === "" && (
                      <div className="req_field">{t("Make is required")}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="company_name flt">
                  <div className="form-group flt">
                    <label className="labelName">{t("Model")} *</label>
                    <select
                      name="model"
                      id="model"
                      onChange={handleModel}
                      value={model}

                    >
                      <option value="">{t("Select Model")}</option>
                      {modelList &&
                        modelList.map(({ _id, model }) => (
                          <option value={_id}>{model}</option>
                        ))}
                    </select>

                    {submit && !model && (
                      <div className="req_field">{t("Model is required")}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="edit_details1 edit_details1bb flt">
                  <button className="edit_button1" onClick={handleshowSubmit}>
                    <i class="fas fa-save"></i>{t("Show Data")}
                  </button>
                </div>
              </div>
            </div>

            {displaythirty && (
              <>
                <div className="main_company flt">
                  <div className="main_boxShadow flt">
                    <div className="rental_det flt">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="main_rental_details flt">
                            {t("30 Days Rate Details")}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-8">
                            {thirtyArray.map(({ id, Day, Km, Cost }, index) => {
                              return (
                                <div className="row" key={id}>
                                  <div className="col-sm-4">
                                    <div className="company_name flt">
                                      <div className="form-group flt">
                                        <label className="labelName">{t("Day")}</label>

                                        <input
                                          name="day"
                                          value={index + 1}
                                          readOnly
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <div className="company_name flt">
                                      <div className="form-group flt">
                                        <label className="labelName">{t("KM")}</label>
                                        <input
                                          type="number"
                                          placeholder="Enter Km"
                                          name="km"
                                          value={Km}
                                          id="email"
                                          onChange={(e) => {
                                            handleArrayKm(e, index);
                                          }}

                                          onWheelCapture={(e) => {
                                            e.target.blur();
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
                                              e.preventDefault();
                                            }
                                            console.log(e)
                                          }}
                                          autoComplete="off"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <div className="company_name flt">
                                      <div className="form-group flt">
                                        <div className="main_button_col flt ">
                                          <label className="labelName">
                                            {t("Cost")}
                                          </label>
                                        </div>
                                        <div className="make_img flt">
                                          <i class="fas fa-rupee-sign"></i>
                                          <input
                                            type="number"
                                            placeholder="Enter cost"
                                            name="cost"
                                            id="cost"
                                            value={Cost}
                                            onChange={(e) => {
                                              handleArrayCost(e, index);
                                            }}
                                            onWheelCapture={(e) => {
                                              e.target.blur();
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
                                                e.preventDefault();
                                              }
                                            }}
                                            autoComplete="off"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="col-sm-4">
                            <div className="main_monthly flt">
                              <div className="main_boxShadow flt">
                                <div className="Company_name_week flt">
                                  <div className="action_label">
                                    <div className="comp_name_details flt">
                                      {t("Extra km charges")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="company_name flt">
                                  <div className="form-group flt">
                                    <label className="labelName">{t("KM")}</label>
                                    <input
                                      type="text"
                                      placeholder="Enter KM"
                                      name="extraKm"
                                      id="extraKm"
                                      value={extraCharge.Km}
                                      onChange={handleExtraChange}
                                      onWheelCapture={(e) => {
                                        e.target.blur();
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
                                          e.preventDefault();
                                        }
                                      }}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="company_name flt">
                                  <div className="form-group flt">
                                    <label className="labelName">{t("Cost")}</label>
                                    <div className="make_img flt">
                                      <i class="fas fa-rupee-sign"></i>
                                      <input
                                        type="text"
                                        placeholder="Enter Cost"
                                        name="extraCost"
                                        id="extraCost"
                                        value={extraCharge.Cost}
                                        onChange={handleExtraChange}
                                        onWheelCapture={(e) => {
                                          e.target.blur();
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
                                            e.preventDefault();
                                          }
                                        }}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button className="edit_button1" onClick={handleSubmit}>
                            <i class="fas fa-save"></i>{t("Save")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </>
    )
  }
  return (
    <>
      {costDetails()}
    </>
  );
};
export default withTranslation()(VehicleCost);
