import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SetUpService } from "../../../../../services";

const useLocationListHook = () => {
  let [page, setPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);
  let navigate = useNavigate();

  const columns = [
    {
      name: "Loc. Code",
      selector: (row) => row.locationcode,
      width: "150px"
    },
    {
      name: "Loc. Name",
      selector: (row) => row.locationname,
      width: "180px"
    },

    {
      name: "Address",
      selector: (row) => row.address,
      width: "450px"
    },
    {
      name: "Address 2",
      selector: (row) => row.addresstwo,
      width: "250px"
    },
    {
      name: "Phone No.",
      selector: (row) => row.phonenumber,
      width: "180px"
    },
    {
      name: "Fax",
      selector: (row) => row.fax,
      width: "180px"
    },
    {
      name: "State",
      selector: (row) => row.state,
      width: "180px"
    },
    {
      name: "Zip Code",
      selector: (row) => row.zip,
      width: "180px"
    },
    {
      name: "City",
      selector: (row) => row.city,
      width: "180px"
    },
  ];

  let order = [];
  let obj = {};

  /**
   * call list response
   */
  useEffect(() => {
    locationResponse(page);
  }, [page]);

  /**
   * call list table response
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * list
   */
  const list = () => {
    listData.data.data.docs.map((item, index) => {
      obj = {
        id: item._id,
        locationcode: item.locationCode,
        locationname: item.locationName,
        address: item.address,
        addresstwo: item.addressTwo,
        phonenumber: item.phoneNumber,
        fax: item.fax,
        state: item.states,
        zip: item.zip,
        city: item.city,
        email: item.email,
        ownername: item.ownerName,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };
  /**
   * list api call
   * @param {*} page 
   */
  const locationResponse = (page) => {
    setLoader("loading");
    SetUpService.getAllLocations(page)
      .then((response) => {
        setListData(response);
        setTotalPages(response.data.data.totalPages);
        setPage(response.data.data.page);
        setLoader("isfull");
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };
  /**
   * handle page
   * @param {*} e 
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };

  /**
   * handle navigate
   * @param {*} e 
   */
  const navigateToLocationDetails = (e) => {
    navigate("/setup-locationdetails");
  };

  /**
   * get data
   * @param {*} row 
   */
  function handleEditLocation(row) {
    navigate("/setup-locationdetails", {
      state: {
        editLocation: row,
      },
    });
  }
  return {
    loader,
    totalPages,
    columns,
    listTable,
    page,
    handlePageClick,
    navigateToLocationDetails,
    handleEditLocation,
  };
};
export default useLocationListHook;
