import { v4 as uuidv4 } from "uuid";
export const arrayOfObjects = [
  {
    id: uuidv4(),
    Day: 1,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 2,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 3,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 4,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 5,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 6,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 7,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 8,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 9,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 10,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 11,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 12,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 13,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 14,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 15,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 16,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 17,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 18,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 19,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 20,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 21,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 22,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 23,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 24,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 25,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 26,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 27,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 28,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 29,
    Km: "",
    Cost: "",
  },
  {
    id: uuidv4(),
    Day: 30,
    Km: "",
    Cost: "",
  },
];
