import "../Company.css";
import Ic_Home13 from "../../../../../assets/images/Ic_Home13.png";
import TopBar from "../../../../_common/topbar/TopBar";
import Menu from "../../../../_common/menu/Menu";
import LeftSidebar from "../../../../_common/sidebar/LeftSidebar";
import useCompanyListHook from "./useCompanyListHook";
import { withTranslation } from "react-i18next";
import { Loading } from "../../../../../assets/images";

const CompanyList = (props) => {
  const { t } = props;
  const {
    id,
    ownerName,
    vatId,
    city,
    country,
    webPage,
    address,
    fax,
    email,
    addressTwo,
    zip,
    phone,
    bankAccount,
    states,
    companyName,
    loader,
    navigateToEditCompanyDetails,
  } = useCompanyListHook();

  const comapanyName = () => {
    return (
      <>
        <div className="company_hedding flt">
          <div className="company_icon flt">
            <img src={Ic_Home13} alt="image_avilable" />
            {t("Company")}
          </div>

          <div className="edit_details flt">
            {id === "" ?
              <button
                className="edit_button"
                onClick={navigateToEditCompanyDetails}
              >
                <i class="fas fa-edit"></i>{t("Add Company")}
              </button> : <button
                className="edit_button"
                onClick={navigateToEditCompanyDetails}
              >
                <i class="fas fa-edit"></i>{t("Edit Company")}
              </button>}
          </div>
        </div></>
    )
  }
  const comapnyDetails = () => {
    return (<>
      {loader === "loading" && (
        <div className="main_load ">
          <img src={Loading} alt="loader" />
        </div>
      )}
      {(loader === "isfull" ||
        loader === "isempty" ||
        loader === "notset") && (

          <div className="rr_bros_head flt">
            <div className="main_boxShadow flt">
              <div className="rr_bross_headder flt">
                <div className="rr_bross_contant flt">
                  <div className="rr_bross flt">{companyName}</div>

                  <div className="right_side_owner flt">
                    <div className="owner_name flt">{t("Owner Name")}</div>
                    <div className="owner_nametag">{ownerName}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <div className="owner_details flt">
                      <ul className="details_of flt">
                        <li>
                          {t("VAT / Tax ID Number")} <span>:</span>
                        </li>
                        <li>{vatId}</li>

                        <li>
                          {t("City")} <span>:</span>
                        </li>
                        <li>{city}</li>
                        <li>
                          {t("Country")} <span>:</span>
                        </li>
                        <li>{country}</li>
                        <li>
                          {t("Web Page")} <span>:</span>
                        </li>
                        <li>{webPage}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="owner_details flt">
                      <ul className="details_of flt">
                        <li>
                          {t("Address")} <span>:</span>
                        </li>
                        <li>{address}</li>

                        <li>
                          {t("State")}<span>:</span>
                        </li>
                        <li>{states}</li>
                        <li>
                          {t("Fax Number")} <span>:</span>
                        </li>
                        <li>{fax}</li>
                        <li>
                          {t("Email")} <span>:</span>
                        </li>
                        <li>{email}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="owner_details flt">
                      <ul className="details_of flt">
                        <li>
                          {t("Address 2 ")}<span>:</span>
                        </li>
                        <li>{addressTwo} </li>
                        <li>
                          {t("Zip / Postal")} <span>:</span>
                        </li>
                        <li>{zip}</li>
                        <li>
                          {t("Phone Number")} <span>:</span>
                        </li>
                        <li>{phone}</li>
                        <li>
                          {t("Bank Account")} <span>:</span>
                        </li>
                        <li>{bankAccount}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}</>)
  }

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      <LeftSidebar />
      <div className="chat_right1 flt">
        <div className="main_com_header flt">
          <div className="company_header flt">

            {comapanyName()}

            {comapnyDetails()}

          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation() (CompanyList);
