import useEndRentPaymentHook from "./useEndRentPaymentHook";
import { atmcard } from "../../../../../../../assets/images";
import { cards } from "../../../../../../../assets/images";
import { payment } from "../../../../../../../assets/images";
import { Ic_Home14 } from "../../../../../../../assets/images";
import { Calender } from "../../../../../../../assets/images";
import { payment_succesfull } from "../../../../../../../assets/images";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../../../../actions";
import PaymentTable from "../../../PaymentTable";
import { Loading } from "../../../../../../../assets/images";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const EndRentPayment = ({ displayData, bookid }) => {
  const [showPay, setShowPay] = useState(false);
  const {
    loader,
    NoOfDays,
    RentalCharges,
    HelmetCharges,
    LuggageCharges,
    GrandTotal,
    gst,
    TotalRental,
    Damages,
    ExKmCharges,
    OtherCharges,
    submitted,
    carddate,
    advanceamount,
    paymentType,
    balanceamount,
    paymentdetails,
    cardno,
    cvv,
    expiry,
    holdername,
    cardType,
    transactionid,
    fileName1,
    paymentImage,
    selectedFile1,
    displayMain,
    dueService,
    ref_number,
    paymentRes,
    acceptCheck,
    gstRate,
    payment_dueDate,
    paymentArray,
    handlePaymentDueDate,
    handleGst,
    changeAcceptCheckboxHandler,
    goBack,
    makepaymentContinue,
    handleCardDate,
    changeHandling,
    onSelectFile1,
    creditPayment,
    navigateToCheckIn,
    handleChange,
    makepayment,
    handleDue,
    handlePopup,
    handleFaults,
  } = useEndRentPaymentHook(displayData, bookid);
  const dispatch = useDispatch();

  /**
   * payment cal
   */
  let diffBalance;
  if (paymentRes) {
    diffBalance = GrandTotal - paymentRes?.data?.[0]?.paidamount;
  }
  /**
   * show pop up
   * @param {*} e
   */
  const showPopUp = (e) => {
    if (RentalCharges === "") {
      dispatch(alertActions.error("Rental charge is required"));
    } else if (payment_dueDate === "") {
      dispatch(alertActions.error("Payment Due Date is required"));
    } else {
      makepaymentContinue(e);
    }
  };

  const paymentDetailsInfo = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          <div className="Company_name flt">
            <div className="comp_name_details flt">Payment Details</div>
          </div>
          {loader === "loading" && (
            <div className="main_load ">
              <img src={Loading} alt="loader" />
            </div>
          )}
          {(loader === "isfull" ||
            loader === "isempty" ||
            loader === "notset") && (
            <>
              <div className="main_payment flt">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="main_boxShadow flt">
                      <div className="main_helmet_padd flt">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="company_name1 flt">
                              <div className="form-group1 flt">
                                <div className="info_circle flt">
                                  <label className="labelName2">
                                    Number of Days
                                  </label>

                                  {/* <div className="info_pop flt">
                                      <i class="fas fa-info-circle"></i>
                                      <div className="hover_me">EZ-RENTAL</div>
                                    </div> */}
                                </div>
                                <div className="make_img1 flt">
                                  <input
                                    type="number"
                                    placeholder=""
                                    name="NoOfDays"
                                    value={NoOfDays}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="company_name1 flt">
                              <div className="form-group1 flt">
                                <label className="labelName2">
                                  Rental Charges
                                </label>
                                <div className="make_img1 flt">
                                  <i class="fas fa-rupee-sign"></i>
                                  <input
                                    type="number"
                                    placeholder=""
                                    name="RentalCharges"
                                    value={RentalCharges}
                                    onChange={handleChange}
                                    onWheelCapture={(e) => {
                                      e.target.blur();
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        e.keyCode === 38 ||
                                        e.keyCode === 40 ||
                                        e.keyCode === 37 ||
                                        e.keyCode === 39
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="top_helmet_pad flt">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="pay_mode flt">
                                <div className="helmet_change flt">
                                  Helmet Charges
                                </div>
                                <div className="company_name4 flt">
                                  <div className="form-group4 flt">
                                    <div className="make_img4 flt">
                                      <i class="fas fa-rupee-sign"></i>
                                      <input
                                        type="number"
                                        placeholder=""
                                        name="HelmetCharges"
                                        value={HelmetCharges}
                                        onChange={handleChange}
                                        onWheelCapture={(e) => {
                                          e.target.blur();
                                        }}
                                        onKeyDown={(e) => {
                                          if (
                                            e.keyCode === 38 ||
                                            e.keyCode === 40 ||
                                            e.keyCode === 37 ||
                                            e.keyCode === 39
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="pay_mode flt">
                                <div className="helmet_change flt">
                                  Luggage Charges
                                </div>
                                <div className="company_name4 flt">
                                  <div className="form-group4 flt">
                                    <div className="make_img4 flt">
                                      <i class="fas fa-rupee-sign"></i>
                                      <input
                                        type="number"
                                        placeholder=""
                                        name="LuggageCharges"
                                        value={LuggageCharges}
                                        onChange={handleChange}
                                        onWheelCapture={(e) => {
                                          e.target.blur();
                                        }}
                                        onKeyDown={(e) => {
                                          if (
                                            e.keyCode === 38 ||
                                            e.keyCode === 40 ||
                                            e.keyCode === 37 ||
                                            e.keyCode === 39
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="pay_mode flt">
                                <div className="helmet_change flt">
                                  Other Charges
                                </div>
                                <div className="company_name4 flt">
                                  <div className="form-group4 flt">
                                    <div className="make_img4 flt">
                                      <i class="fas fa-rupee-sign"></i>
                                      <input
                                        type="number"
                                        placeholder=""
                                        name="OtherCharges"
                                        value={OtherCharges}
                                        onChange={handleChange}
                                        onWheelCapture={(e) => {
                                          e.target.blur();
                                        }}
                                        onKeyDown={(e) => {
                                          if (
                                            e.keyCode === 38 ||
                                            e.keyCode === 40 ||
                                            e.keyCode === 37 ||
                                            e.keyCode === 39
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="pay_mode flt">
                                <div className="helmet_change flt">
                                  Ex Km Charges
                                </div>
                                <div className="company_name4 flt">
                                  <div className="form-group4 flt">
                                    <div className="make_img4 flt">
                                      <i class="fas fa-rupee-sign"></i>
                                      <input
                                        type="number"
                                        placeholder=""
                                        name="ExKmCharges"
                                        value={ExKmCharges}
                                        onChange={handleChange}
                                        onWheelCapture={(e) => {
                                          e.target.blur();
                                        }}
                                        onKeyDown={(e) => {
                                          if (
                                            e.keyCode === 38 ||
                                            e.keyCode === 40 ||
                                            e.keyCode === 37 ||
                                            e.keyCode === 39
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="pay_mode flt">
                                <div className="helmet_change flt">Damages</div>
                                <div className="company_name4 flt">
                                  <div className="form-group4 flt">
                                    <div className="make_img1 flt">
                                      <i class="fas fa-rupee-sign"></i>
                                      <input
                                        type="number"
                                        placeholder=""
                                        name="Damages"
                                        value={Damages}
                                        onChange={handleChange}
                                        onWheelCapture={(e) => {
                                          e.target.blur();
                                        }}
                                        onKeyDown={(e) => {
                                          if (
                                            e.keyCode === 38 ||
                                            e.keyCode === 40 ||
                                            e.keyCode === 37 ||
                                            e.keyCode === 39
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="total_rental flt">
                          <div className="col-sm-12">
                            <div className="pay_mode flt">
                              <div className="helmet_change flt">
                                Total Rental & Other Charges
                              </div>
                              <div className="company_name4 flt">
                                <div className="form-group4 flt">
                                  <div className="make_img4 flt">
                                    <i class="fas fa-rupee-sign"></i>
                                    <input
                                      type="number"
                                      placeholder=""
                                      name="TotalRental"
                                      value={TotalRental}
                                      readonly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="pay_mode flt">
                              <div className="helmet_change flt">
                                GST {gstRate}% &nbsp;&nbsp;&nbsp;
                                <input
                                  type="checkbox"
                                  id="password1"
                                  name="acceptCheck"
                                  value={acceptCheck}
                                  onClick={changeAcceptCheckboxHandler}
                                  checked={acceptCheck}
                                />
                              </div>

                              {acceptCheck === true && (
                                <div className="company_name4 flt">
                                  <div className="form-group4 flt">
                                    <div className="make_img4 flt">
                                      <i class="fas fa-rupee-sign"></i>
                                      <input
                                        type="number"
                                        placeholder=""
                                        name="gst"
                                        value={gst}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {acceptCheck === false && (
                                <div className="company_name4 flt">
                                  <div className="form-group4 flt">
                                    <div className="make_img4 flt">
                                      <i class="fas fa-rupee-sign"></i>
                                      <input
                                        type="number"
                                        placeholder=""
                                        name="gst"
                                        value={gst}
                                        onChange={handleGst}
                                        onWheelCapture={(e) => {
                                          e.target.blur();
                                        }}
                                        onKeyDown={(e) => {
                                          if (
                                            e.keyCode === 38 ||
                                            e.keyCode === 40 ||
                                            e.keyCode === 37 ||
                                            e.keyCode === 39
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="pay_mode flt">
                              <div className="helmet_change flt">
                                Grand Total
                              </div>
                              <div className="company_name4 flt">
                                <div className="form-group4 flt">
                                  <div className="make_img4 flt">
                                    <i class="fas fa-rupee-sign"></i>
                                    <input
                                      type="number"
                                      placeholder=""
                                      name="GrandTotal"
                                      value={GrandTotal}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="main_grand_total flt">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">Due Date *</label>
                                <div className="make_img flt">
                                  <Datetime
                                    dateFormat="DD/MM/YYYY"
                                    onChange={handlePaymentDueDate}
                                    value={payment_dueDate}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    inputProps={{
                                      placeholder: "Select date",
                                      disabled: false,
                                      readOnly: true,
                                      onKeyDown: (e) => e.preventDefault(),
                                    }}
                                  />
                                  <div className="cal_img">
                                    <img src={Calender} alt="calender" />
                                  </div>
                                </div>
                                {submitted && !payment_dueDate && (
                                  <div className="req_field">
                                    Payment Due Date is required
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="grand_ttl flt">
                              <div className="main_paid_class flt">
                                <ul className="paid_class flt">
                                  <li>
                                    Total Paid Amount<span>:</span>
                                  </li>
                                  {paymentRes?.data?.length === 0 && (
                                    <li className="pay_lst">0</li>
                                  )}
                                  {paymentRes?.data?.length > 0 && (
                                    <li className="pay_lst">{`₹${(paymentRes?.data[0]?.paidamount).toFixed(
                                      2
                                    )}`}</li>
                                  )}
                                  <li>
                                    Total Pending<span>:</span>
                                  </li>
                                  {paymentRes?.data?.length === 0 && (
                                    <li className="pay_lst">
                                      {GrandTotal ? `₹${GrandTotal}` : 0}
                                    </li>
                                  )}
                                  {paymentRes?.data?.length > 0 && (
                                    <li className="pay_lst">
                                      {`₹${(
                                        GrandTotal -
                                        paymentRes?.data?.[0]?.paidamount
                                      ).toFixed(2)}`}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="main_ownbutton flt">
                      <div className="own_button flt">
                        <button
                          className="submitbutton"
                          onClick={() => navigateToCheckIn()}
                        >
                          Back
                        </button>
                      </div>
                      {diffBalance !== 0 && (
                        <div className="own_button flt">
                          <button
                            className="submitbutton"
                            onClick={(e) => makepayment(e)}
                          >
                            Make Payment
                          </button>
                        </div>
                      )}
                      <div className="own_button flt">
                        <button
                          className="submitbutton"
                          onClick={(e) => showPopUp(e)}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };
  const paymentDetailsInfopage2 = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
          <div className="E_comp_details flt">
            <div className="main_boxShadow flt">
              <div className="Company_name flt">
                <div className="comp_name_details flt">Payment</div>
              </div>

              <div className="row">
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Date</label>
                      <div className="make_img flt">
                        <Datetime
                          dateFormat="DD/MM/YYYY"
                          onChange={handleCardDate}
                          value={carddate}
                          closeOnSelect={true}
                          timeFormat={false}
                          // isValidDate={(currentDate, selectedDate) =>
                          //   currentDate.isAfter(
                          //     new Date().getTime() - 24 * 60 * 60 * 1000
                          //   )
                          // }
                          inputProps={{
                            placeholder: "Select date",
                            disabled: false,
                            readOnly: true,
                            onKeyDown: (e) => e.preventDefault(),
                          }}
                        />
                        <div className="cal_img">
                          <img src={Calender} alt="calender" />
                        </div>
                      </div>
                      {submitted && !carddate && (
                        <div className="req_field">Date is required</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Payment Type</label>
                      <div className="make_img flt">
                        <select
                          name="paymentType"
                          value={paymentType}
                          onChange={changeHandling}
                          id="lang"
                        >
                          <option value="">Select Payment Type</option>
                          <option value="card">Card</option>
                          <option value="cash">Cash</option>
                          <option value="online">online</option>
                        </select>
                      </div>
                      {submitted && !paymentType && (
                        <div className="req_field">
                          Payment Type is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Payment Amount</label>
                      <div className="make_img flt">
                        <i className="fas fa-rupee-sign"></i>
                        <input
                          type="text"
                          placeholder="Payment Amount"
                          name="advanceamount"
                          id="email"
                          value={advanceamount}
                          onChange={changeHandling}
                        />
                      </div>
                      {submitted && !advanceamount && (
                        <div className="req_field">
                          Payment Amount is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Grand Total Amount</label>
                      <div className="make_img flt">
                        <i className="fas fa-rupee-sign"></i>
                        <input
                          type="text"
                          placeholder="Grand Total Amount"
                          name="paymentamount"
                          id="email"
                          value={GrandTotal}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Total Paid Amount</label>
                      <div className="make_img flt">
                        <i className="fas fa-rupee-sign"></i>
                        <input
                          type="text"
                          placeholder="Total Paid Amount"
                          name="advanceamount"
                          id="email"
                          value={
                            paymentRes?.data?.length === 0
                              ? 0
                              : (paymentRes?.data?.[0]?.paidamount).toFixed(2)
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Total Due Amount</label>
                      <div className="make_img flt">
                        <i className="fas fa-rupee-sign"></i>
                        <input
                          type="text"
                          placeholder="Total Due Amount"
                          name="balanceamount"
                          id="email"
                          value={
                            paymentRes?.data?.length === 0
                              ? GrandTotal
                              : (paymentRes?.data?.[0]?.pendingamount).toFixed(
                                  2
                                )
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Payment Details</label>
                      <div className="make_img flt">
                        <div className="make_img flt">
                          <select
                            name="paymentdetails"
                            value={paymentdetails}
                            onChange={changeHandling}
                            id="lang"
                          >
                            <option value="">Select Payment Details</option>

                            {paymentArray &&
                              paymentArray?.data?.data?.map(
                                ({ paymentType }) => (
                                  <option value={paymentType}>
                                    {paymentType}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                      </div>
                      {submitted && !paymentdetails && (
                        <div className="req_field">
                          Payment Details is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Balance Amount</label>
                      <div className="make_img flt">
                        <i className="fas fa-rupee-sign"></i>
                        <input
                          type="text"
                          placeholder="Balance Amount"
                          name="balanceamount"
                          id="email"
                          value={balanceamount}
                          onChange={changeHandling}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Reference Number</label>
                      <div className="make_img flt">
                        <input
                          type="text"
                          placeholder="Reference Number"
                          name="ref_number"
                          id="ref_number"
                          value={ref_number}
                          onChange={changeHandling}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3"></div>
                <div className="col-sm-3"></div>
                <div className="col-sm-3"></div>
              </div>
              {paymentType === "card" && creditdebitinfo()}
              {paymentType === "online" && onlinePayment()}

              <div className="main_row flt">
                <div className="row">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-6">
                    <div className="main_ownbutton flt">
                      <div className="own_button flt">
                        <button className="backbutton" onClick={goBack}>
                          Back
                        </button>
                        <button
                          className="submitbutton"
                          onClick={creditPayment}
                        >
                          Confirm Payment or Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  const creditdebitinfo = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
          <div className="main_headbox flt">
            <div className="main_boxShadow flt">
              <div className="main_headbox2 flt ">
                <div className="main_atm_card flt">
                  <div className="atm_info flt">
                    <label className="credit_card">
                      <img src={atmcard} alt="atmcard" />
                      Credit / Debit Card Info
                    </label>
                  </div>
                  <div className="main_row debit_card_box flt">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="main_pay_debit flt">
                          <div className="row">
                            <div className="col-sm-8">
                              <div className="main_card_type flt">
                                <div className="company_name8 flt">
                                  <div className="form-group flt">
                                    <label className="labelName">
                                      Card Type
                                    </label>
                                    <div className="make_img flt">
                                      <select
                                        value={cardType}
                                        onChange={changeHandling}
                                        name="cardType"
                                        id="lang"
                                      >
                                        <option value="card" disable>
                                          Select Card Type
                                        </option>
                                        <option value="credit">
                                          {" "}
                                          Credit Card
                                        </option>
                                        <option value="debit">
                                          Debit Card
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="main_ex_date flt">
                              <div className="row">
                                <div className="col-sm-8">
                                  <div className="company_name8 flt">
                                    <div className="form-group flt">
                                      <label className="labelName">
                                        Card Type
                                      </label>
                                      <div className="make_img flt">
                                        <select
                                          value={cardType}
                                          onChange={changeHandling}
                                          name="cardType"
                                          id="lang"
                                        >
                                          <option value="card" disable>
                                            Select Card Type
                                          </option>
                                          <option value="credit">
                                            {" "}
                                            Credit Card
                                          </option>
                                          <option value="debit">
                                            Debit Card
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="main_ex_date flt">
                                <div className="row">
                                  <div className="col-sm-8">
                                    <div className="company_name8 flt">
                                      <div className="form-group flt">
                                        <label className="labelName">
                                          Card Number#
                                        </label>
                                        <div className="make_im flt">
                                          <input
                                            type="text"
                                            placeholder="Valid Card Number"
                                            onChange={changeHandling}
                                            name="cardno"
                                            value={cardno}
                                          />
                                          <img src={cards} alt="card" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-sm-4">
                                    <div className="main_cvv_head flt">
                                      <div className="cvv_number flt">
                                        <div className="cvv_date">
                                          <label className="cvv_num">CVV</label>
                                          <input
                                            type="number"
                                            placeholder="CVV"
                                            name="cvv"
                                            id="email"
                                            value={cvv}
                                            onChange={changeHandling}
                                          />
                                        </div>
                                        <div className="ex_date">
                                          <label className="cvv_num">
                                            Expiry Date
                                          </label>
                                          <input
                                            type="number"
                                            placeholder="MM/YY"
                                            name="expiry"
                                            value={expiry}
                                            id="email"
                                            onChange={changeHandling}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-8">
                                <div className="company_name8 flt">
                                  <div className="form-group flt">
                                    <label className="labelName">
                                      Card Holder Name
                                    </label>
                                    <div className="make_im flt">
                                      <input
                                        type="text"
                                        placeholder=""
                                        name="holdername"
                                        value={holdername}
                                        onChange={changeHandling}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="money_image flt">
                            <img src={payment} alt="payment" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  const onlinePayment = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
          <div className="E_comp_details flt">
            <div className="main_boxShadow flt">
              <div className="row">
                <div className="main_headbox flt">
                  <div className="main_headbox2 flt ">
                    <div className="main_atm_card flt">
                      <div className="atm_info flt">
                        <label className="credit_card">
                          <img src={atmcard} alt="atmcard" />
                          Transaction
                        </label>
                      </div>
                      <div className="main_row flt">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="main_pay_debit1 flt">
                              <div className="row">
                                <div className="col-sm-8">
                                  <div className="main_card_type flt">
                                    <div className="company_name8 flt">
                                      <div className="form-group flt">
                                        <label className="labelName">
                                          Transaction ID
                                        </label>
                                        <div className="make_im flt">
                                          <input
                                            type="text"
                                            placeholder=""
                                            name="transactionid"
                                            value={transactionid}
                                            onChange={changeHandling}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="company_name flt">
                                    <div className="form-group flt">
                                      <label className="labelName">
                                        Upload Transaction Screenshot
                                      </label>
                                    </div>
                                  </div>
                                  <div className="upload_img1 flt">
                                    <div className="upload_img_btn1 flt">
                                      <button>
                                        {selectedFile1 && (
                                          <img
                                            src={paymentImage}
                                            width="102%"
                                            height="220"
                                            alt="pay"
                                          />
                                        )}
                                        {!selectedFile1 && (
                                          <img src={Ic_Home14} alt="upload" />
                                        )}
                                      </button>
                                      <input
                                        type="file"
                                        name="myfile"
                                        onChange={onSelectFile1}
                                        key={fileName1}
                                        id="myfile2"
                                        accept="image/png,image/jpeg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="money_image flt">
                              <img src={payment} alt="pay" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const paymentPopUp = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
          <div className="modal1">
            <div className="modal" id="myModal">
              <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">
                  <div className="close_btn flt">
                    <button
                      type="button"
                      className="close_button"
                      data-dismiss="modal"
                      onClick={() => setShowPay(false)}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="car_img flt">
                          <div className="pop_car flt">
                            <img src={payment_succesfull} alt="pay" />
                          </div>
                          <div className="payment_success flt">
                            Payment Succesfull
                          </div>
                          <div className="vehicle_due flt">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">
                                  Vehicle Due For Service
                                </label>
                                <div className="make_img flt">
                                  <select
                                    name="Vehicle"
                                    id="lang"
                                    onChange={handleDue}
                                    value={dueService}
                                  >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="company_namee flt">
                              <div className="form-group flt">
                                <label className="labelName">
                                  Faults in Vehicle
                                </label>
                                <textarea
                                  name="w3review"
                                  row="6"
                                  cols="50"
                                  onChange={handleFaults}
                                />
                              </div>
                            </div>

                            <div className="book_st1 flt">
                              <div className="resend_otp1 flt">
                                <button
                                  className={
                                    dueService === "no"
                                      ? "re_button1"
                                      : "re_button1 re_button2"
                                  }
                                  onClick={(e) => handlePopup(e, 0)}
                                >
                                  Checkin Vehicle
                                </button>
                                <button
                                  className={
                                    dueService === "yes"
                                      ? "re_button1"
                                      : "re_button1 re_button2"
                                  }
                                  onClick={(e) => handlePopup(e, 1)}
                                >
                                  Checkin Vehicle & Move to Maintenance
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {displayMain === 0 && paymentDetailsInfo()}
      {displayMain === 1 && paymentDetailsInfopage2()}
      {showPay && paymentPopUp()}
      {displayMain === 0 && loader !== "loading" && <PaymentTable />}
    </>
  );
};
export default EndRentPayment;
