import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { alertActions } from "../../../../../actions/alert.actions";
import { useDispatch } from "react-redux";
import { SetUpService } from "../../../../../services";

const useLocationDetailsHook = (locationDetail) => {
  let lc = locationDetail?.state?.editLocation?.locationcode;
  let add1 = locationDetail?.state?.editLocation?.address;
  let fax1 = locationDetail?.state?.editLocation?.fax;
  let city1 = locationDetail?.state?.editLocation?.city;
  let lcname = locationDetail?.state?.editLocation?.locationname;
  let add2 = locationDetail?.state?.editLocation?.addresstwo;
  let state1 = locationDetail?.state?.editLocation?.state;
  let email1 = locationDetail?.state?.editLocation?.email;
  let phno = locationDetail?.state?.editLocation?.phonenumber;
  let owname = locationDetail?.state?.editLocation?.ownername;
  let zip1 = locationDetail?.state?.editLocation?.zip;
  let locId = locationDetail?.state?.editLocation?.id;
  const [locationCode, setLocationCode] = useState(lc ? lc : "");
  const [address, setAddress] = useState(add1 ? add1 : "");
  const [fax, setFax] = useState(fax1 ? fax1 : "");
  const [city, setCity] = useState(city1 ? city1 : "");
  const [locationName, setLocationName] = useState(lcname ? lcname : "");
  const [addressTwo, setAddressTwo] = useState(add2 ? add2 : "");
  const [states, setStates] = useState(state1 ? state1 : "Karnataka");
  const [email, setEmail] = useState(email1 ? email1 : "");
  const [phoneNumber, setPhoneNumber] = useState(phno ? phno : "");
  const [ownerName, setOwnerName] = useState(owname ? owname : "");
  const [zip, setZip] = useState(zip1 ? zip1 : "");
  const [id, setId] = useState(locId ? locId : "");
  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState("notset");

  let navigate = useNavigate();

  const dispatch = useDispatch();
  /**
   * navigate
   * @param {*} e 
   */
  const navigateToLocationDetails = (e) => {
    navigate("/setup-locationdetails");
  };


  /**
   * location Details
   * @param {*} e 
   */
  const navigateToLocation = (e) => {
    navigate("/setup-location");
  };

  /**
   * handle change
   * @param {*} e 
   */
  const handleLocationCode = (e) => {
    setLocationCode(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleFax = (e) => {
    setFax(e.target.value);
  };
  const handleCity = (e) => {
    setCity(e.target.value);
  };
  const handleLocationName = (e) => {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setLocationName(e.target.value);
    } else if (!e.target.value) {
      setLocationName("");
    }
  };
  const handleAddressTwo = (e) => {
    setAddressTwo(e.target.value);
  };
  const handleState = (e) => {
    setStates(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleOwnerName = (e) => {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setOwnerName(e.target.value);
    } else if (!e.target.value) {
      setOwnerName("");
    }
  };
  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handleZip = (e) => {
    setZip(e.target.value);
  };
  /**
   * get info
   * @param {*} row 
   */
  function handleEditLocation(row) {
    navigate("/editlocation", {
      state: {
        editLocation: row,
      },
    });
  }


  /**
   * edit api call
   * @param {*} e 
   */
  const handleEditSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (
      id &&
      locationCode &&
      address &&
      fax &&
      locationName &&
      phoneNumber &&
      zip &&
      states &&
      city
    ) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error("Enter valid Email Address"));
      } else if (phoneNumber && !filter.test(phoneNumber)) {
        dispatch(alertActions.error("Enter valid Mobile Number"));
      } else if (zip && zip.length !== 6) {
        dispatch(alertActions.error("Enter valid Pincode number"));
      } else {
        setLoader("loading");
        SetUpService.editLocation(
          id,
          locationCode,
          address,
          fax,
          city,
          locationName,
          addressTwo,
          states,
          email,
          phoneNumber,
          ownerName,
          zip
        )
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setLocationCode("");
              setAddress("");
              setFax("");
              setCity("");
              setLocationName("");
              setAddressTwo("");
              setStates("");
              setEmail("");
              setPhoneNumber("");
              setOwnerName("");
              setZip("");
              setLoader("isfull");
              setSubmit(false);
              navigate("/setup-location");
              dispatch(alertActions.success(response?.data?.status?.message));
            } else {
              setLoader("isempty");
              dispatch(alertActions.error(response?.data?.status?.message));
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }


  };

  /**
   * add api call
   * @param {*} e 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (
      locationCode &&
      address &&
      fax &&
      locationName &&
      phoneNumber &&
      zip &&
      states &&
      city
    ) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error("Enter valid Email Address"));
      } else if (phoneNumber && !filter.test(phoneNumber)) {
        dispatch(alertActions.error("Enter valid Mobile Number"));
      } else if (zip && zip.length !== 6) {
        dispatch(alertActions.error("Enter valid Pincode number"));
      } else {
        setLoader("loading");
        SetUpService.addLocation(
          locationCode,
          address,
          fax,
          city,
          locationName,
          addressTwo,
          states,
          email,
          phoneNumber,
          ownerName,
          zip
        )
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setLocationCode("");
              setAddress("");
              setFax("");
              setCity("");
              setLocationName("");
              setAddressTwo("");
              setStates("");
              setEmail("");
              setPhoneNumber("");
              setOwnerName("");
              setZip("");
              setLoader("isfull");
              setSubmit(false);
              navigate("/setup-location");
              dispatch(alertActions.success(response?.data?.status?.message));
            } else {
              setLoader("isempty");
              dispatch(alertActions.error(response?.data?.status?.message));
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }


  };


  /**
   * delete api call
   * @param {*} e 
   */
  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    SetUpService.deleteLocations(locId)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setSubmit(false);
          navigate("/setup-location");
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });


  };
  return {
    navigateToLocationDetails,
    navigateToLocation,
    handleLocationCode,
    handleAddress,
    handleFax,
    handleCity,
    handleLocationName,
    handleAddressTwo,
    handleState,
    handleEmail,
    handleOwnerName,
    handlePhoneNumber,
    handleZip,
    handleSubmit,
    handleEditLocation,
    handleDeleteSubmit,
    handleEditSubmit,
    setId,
    submit,
    locationCode,
    address,
    fax,
    city,
    locationName,
    addressTwo,
    states,
    email,
    phoneNumber,
    ownerName,
    zip,
    id,
    loader,


  };
};
export default useLocationDetailsHook;
