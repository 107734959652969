import { useEffect, useState } from "react";
import { VehicleService } from "../../../../services";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

const useVehicleList = () => {
  const locationStatus = useLocation();
  const [loader, setLoader] = useState("notset");
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("regNum");
  const [listTable, setListTable] = useState([]);
  const [status, setStatus] = useState(
    locationStatus?.state ? locationStatus?.state?.status : ""
  );

  const navigate = useNavigate();
  const columnsAvailable = [
    {
      name: "Sr",
      selector: (row) => row.number,
      grow: 0,
      width: "80px",
    },

    {
      name: "Reg.no",
      selector: (row) => row.regNumber,
      width: "300px",
    },


    {
      name: "Model",
      selector: (row) => row.model,
      grow: 1
    },

    {
      name: "Location",
      selector: (row) => row.location,
      grow: 1
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={
            row.vehicle_status === "available" ? "button_style_available" : ""
          }
        >
          {row.vehicle_status === "available" ? "Available" : ""}
        </div>
      ),
      grow: 1
    },
  ];

  const columns = [
    {
      name: "Stock No.",
      selector: (row) => row.stockNumber,
      width: "100px",
    },
    {
      name: "Reg No.",
      selector: (row) => row.regNumber,
      width: "150px",
    },

    {
      name: "Make",
      selector: (row) => row.make,
      width: "150px",
    },
    {
      name: "Model",
      selector: (row) => row.model,
      width: "150px",
    },
    {
      name: "Year",
      selector: (row) => row.year,
      width: "150px",
    },
    {
      name: "ODO Meter No",
      selector: (row) => row.ODOMeterNo,
      width: "150px",
    },
    {
      name: "Color",
      selector: (row) => row.color,
      width: "150px",
    },

    {
      name: "Vehicle Class",
      selector: (row) => row.vehicleClass,
      width: "150px",
    },

    {
      name: "Transmission",
      selector: (row) => row.transmission,
      width: "150px",
    },

    {
      name: "Location",
      selector: (row) => row.location,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={
            row.vehicle_status === "available"
              ? "button_style_available"
              : row.vehicle_status === "onrent"
                ? "button_style_onrent"
                : row.vehicle_status === "sold"
                  ? "button_style_sold"
                  : row.vehicle_status === "maintanance"
                    ? "button_style_maintainance"
                    : row.vehicle_status === "service"
                      ? "button_style_service"
                      : ""
          }
        >
          {row.vehicle_status === "available"
            ? "Available"
            : row.vehicle_status === "onrent"
              ? "On Rent"
              : row.vehicle_status === "maintanance"
                ? "In Maintenance"
                : row.vehicle_status === "sold"
                  ? "Sold"
                  : row.vehicle_status === "service"
                    ? "In Service"
                    : ""}
        </div>
      ),
      width: "150px",
    },
  ];
  const columnsMaint = [
    {
      name: "Sr",
      selector: (row) => row.number,
      width: "80px",
    },

    {
      name: "Reg. No",
      selector: (row) => row.regNumber,
      width: "260px"
    },


    {
      name: "Model",
      selector: (row) => row.model,
      grow: 1
    },

    {
      name: "Location",
      selector: (row) => row.location,
      grow: 1
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={
            row.vehicle_status === "maintanance"
              ? "button_style_maintainance"
              : ""
          }
        >
          {row.vehicle_status === "maintanance" ? "In Maintenance" : ""}
        </div>

      ),
      grow: 1
    },
  ];

  let order = [];
  let obj = {};

  /**
   * calling list api depends on status
   */
  useEffect(() => {
    if (status === "available") {
      vehicleResponse(page, item, search, status);
    } else if (status === "maintanance") {
      vehicleResponse(page, item, search, status);
    } else if (status === "service") {
      vehicleResponse(page, item, search, status);
    } else if (status === "sold") {
      vehicleResponse(page, "item", search, status);
    } else if (status === "") {
      vehicleResponse(page, item, search, status);
    }
    // eslint-disable-next-line
  }, []);
  /**
   * table list call
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * list data
   */
  const list = () => {
    listData.data.data.docs.map((item, index) => {
      obj = {
        id: item._id,
        number:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        stockNumber: item.stockNumber,
        regNumber: item.regNumber,
        make: item?.make?.[0]?.make,
        makeId: item?.make?.[0]?._id,
        model: item?.model?.[0]?.model,
        modelId: item?.model?.[0]?._id,
        year: item?.year,
        color: item?.color,
        vehicleClass: item?.vehicleClass?.[0]?.vehicleClass,
        vehicleClassId: item?.vehicleClass?.[0]?._id,
        transmission: item?.transmission,
        dateSold: item.dateSold,
        location: item?.location?.[0]?.locationName,
        ODOMeterNo: item?.ODOMeterNo,
        availabilty: item?.availabilty,
        basis: item?.basis,
        chasisNumber: item?.chasisNumber,
        fuelTypeCapacity: item?.fuelTypeCapacity,
        investorName: item?.investorName,
        purchasePrice: item?.purchasePrice,
        salePrice: item?.salePrice,
        startDate: item?.startDate,
        vehicle_status: item?.vehicle_status,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };
  /**
   * api for list
   * @param {*} page 
   * @param {*} item 
   * @param {*} search 
   * @param {*} status 
   */
  const vehicleResponse = (page, item, search, status) => {
    setLoader("loading");
    VehicleService.getAllVehicles(page, item, search.trim(), status).then(
      (response) => {
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        setLoader("isfull");
      }
    ).catch((err) => console.log(err));;
  };


  /**
   * handle row
   * @param {*} row 
   */
  const handleRow = (row) => {
    navigate(`/vehicle-details?vid=${row.id}`, {
      state: {
        row: row,
      },
    });
  };


  /**
   * change handler
   * @param {*} e 
   */
  const changeItemHandler = (e) => {
    setSearch("");
    if (e.target.value === "stockNum") {
      setItem(e.target.value);
      vehicleResponse(1, e.target.value, "", status);
    }
    else if (e.target.value === "basis") {
      setItem(e.target.value);
      vehicleResponse(1, e.target.value, "", status);
    } else {
      setItem(e.target.value);
      vehicleResponse(1, e.target.value, "", status);
    }
  };

  /**
   * search handler
   * @param {*} e 
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    vehicleResponse("", item, e.target.value, status);
  };

  /**
   * navigate
   */
  const navigateToVehicleDetails = () => {
    navigate("/vehicle-details");
  };

  /**
   * handle page click
   * @param {*} e 
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    vehicleResponse(e.selected + 1, item, search, status);
  };

  return {
    listData,
    totalPages,
    search,
    page,
    loader,
    item,
    columns,
    listTable,
    columnsAvailable,
    columnsMaint,
    setStatus,
    handlePageClick,
    navigateToVehicleDetails,
    handleRow,
    changeItemHandler,
    changeSearchHandler,
  };
};
export default useVehicleList;
