import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Menu from "../../_common/menu/Menu";
import TopBar from "../../_common/topbar/TopBar";
import Payment from "./Payments/Payment";
import Rentals from "./Rentals/Rentals";
import Investers from "./Investor/InvestorReport";
import VehicleExpenses from "./VehicleExpenses/VehicleExpenses";
import VehiclePL from "./VehiclePL/VehiclePL";
import VehicleInfos from "./VehicleInfo/VehicleInfo";
import { withTranslation } from "react-i18next";

const Reports = (props) => {
  const { t } = props;

  const location = useLocation();
  const [displayTab, setDisplayTab] = useState(location?.state?.val ? location?.state?.val : 0);
  const changeDisplay = (val) => {
    if (val === 0) {
      setDisplayTab(0);
    }
    if (val === 1) {
      setDisplayTab(1);
    }
    if (val === 2) {
      setDisplayTab(2);
    }
    if (val === 3) {
      setDisplayTab(3);
    }
    if (val === 4) {
      setDisplayTab(4);
    }
    if (val === 5) {
      setDisplayTab(5);
    }
  };
  const renderPayment = () => {
    return <Payment />;
  };
  const renderRentals = () => {
    return <Rentals />;
  };
  const renderInvesters = () => {
    return <Investers />;
  };
  const renderVehicleExpenses = () => {
    return <VehicleExpenses />;
  };
  const VehiclePLs = () => {
    return <VehiclePL />;
  };
  const VehicleInfo = () => {
    return <VehicleInfos />;
  };
  const renderTab = () => {
    return (
      <div className="main_vehicle_info main_vehicle_info1 payment_rlist_tabs flt">
        <div className="main_vehicle flt">
          <ul className="vehicle_info vehicle_info1">
            <li
              className={displayTab === 0 ? "menuActive" : ""}
              onClick={() => changeDisplay(0)}
            >
              <Link to="">{t("Payments")}</Link>
            </li>
            <li
              className={displayTab === 1 ? "menuActive" : ""}
              onClick={() => changeDisplay(1)}
            >
              <Link to="">{t("Rentals")}</Link>
            </li>
            <li
              className={displayTab === 2 ? "menuActive" : ""}
              onClick={() => changeDisplay(2)}
            >
              <Link to="">{t("Investors")}</Link>
            </li>
            <li
              className={displayTab === 3 ? "menuActive" : ""}
              onClick={() => changeDisplay(3)}
            >
              <Link to="">{t("Vehicle Expense")}</Link>
            </li>
            <li
              className={displayTab === 4 ? "menuActive" : ""}
              onClick={() => changeDisplay(4)}
            >
              <Link to="">{t("Vehicle P&L")}</Link>
            </li>
            <li
              className={displayTab === 5 ? "menuActive" : ""}
              onClick={() => changeDisplay(5)}
            >
              <Link to="">{t("Vehicle Info")}</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  };
  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      {renderTab()}
      <div className="cooming_soon">
        {displayTab === 0 && renderPayment()}
        {displayTab === 1 && renderRentals()}
        {displayTab === 2 && renderInvesters()}
        {displayTab === 3 && renderVehicleExpenses()}
        {displayTab === 4 && VehiclePLs()}
        {displayTab === 5 && VehicleInfo()}
      </div>
    </div>
  );
};
export default withTranslation() (Reports);
