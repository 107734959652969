import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { agreements_report } from "../../../../assets/images";
import "./VehicleInfo.css";
import { Loading } from "../../../../assets/images";
import useVehicleInfoHooks from "./useVehicleInfoHooks";
import { withTranslation } from "react-i18next";

const VehicleInfo = (props) => {
  const { t } = props;
  const {
    loader,
    listData,
    totalPages,
    page,
    columns,
    listTable,
    loaderDownLoad,
    downloadReport,
    handlePageClick,
  } = useVehicleInfoHooks();

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };



  const agreementtab = () => {
    return (
      <div className="main_general flt">
        <div className="main_search_b2 main_search_b22 vhinfo_list flt">
          <div className="ex_customer ex_cust">{t("Vehicle Information List")}</div>
          <div className="filler"></div>

          <div className="main_search_dd main_search_dd1 downloadAlign">

            <div className="fliter_button2 ">
              <button
                onClick={downloadReport}
              >
                <i class="fa fa-download" aria-hidden="true"></i>
                {t("Download")}
              </button>
            </div>


          </div>
        </div>
      </div>
    );
  };

  const tableListDisplay = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_location flt"></div>
        <div className="main_boxShadowouter flt">
          <div className="main_tablehead flt">
            {loaderDownLoad === "loading" || loader === "loading" || loader === "notset" ? (
              <div className="table_loader ">
                <img src={Loading} alt="loader" />
              </div>
            ) : (
              <table className="main_table1 flt ">
                {listData?.data?.data?.docs.length !== 0 ? (
                  <DataTable
                    columns={columns}
                    data={listTable || []}
                    customStyles={customStyles}
                  />
                ) : (
                  <div className="no-data">{t("No Data Found")}</div>
                )}

                {listData?.data?.data?.docs.length !== 0 && (
                  <div>
                    <ReactPaginate
                      nextLabel="&rarr;"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      pageCount={totalPages}
                      previousLabel="&larr;"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakClassName="page-item"
                      currentPage={page}
                      forcePage={page - 1}
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}  </table>)}
          </div>


        </div>
      </div>
    );
  };

  const agreementtiltle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img src={agreements_report} alt="agreement" />
          {t("REPORTS")}
        </div>
      </div>
    );
  };

  return (
    <div>
      {agreementtiltle()}
      {agreementtab()}
      {tableListDisplay()}
    </div>
  );
};
export default withTranslation()(VehicleInfo);
