import "../Company.css";
import { Ic_Home13 } from "../../../../../assets/images";
import { Ic_Home14 } from "../../../../../assets/images";
import TopBar from "../../../../_common/topbar/TopBar";
import Menu from "../../../../_common/menu/Menu";
import LeftSidebar from "../../../../_common/sidebar/LeftSidebar";
import useCompanyDetailsHook from "./useCompanyDetailsHook";
import { Loading } from "../../../../../assets/images";
import { withTranslation } from "react-i18next";

const CompanyDetails = (props) => {
  const { t } = props;
  const {
    loader,
    id,
    submit,
    ownerName,
    vatId,
    city,
    country,
    webPage,
    address,
    fax,
    email,
    addressTwo,
    zip,
    phone,
    bankAccount,
    companyName,
    states,
    logoImg,
    fileName,
    selectedFile1,
    onSelectFile1,
    handleAddSubmit,
    navigateToCompanyDetails,
    handleCompanyName,
    handleAddress,
    handleStates,
    handleFax,
    handleWebPage,
    handleOwnerName,
    handleAddressTwo,
    handleZip,
    handlePhone,
    handleBank,
    handleVat,
    handleCity,
    handleCountry,
    handleEmail,
    handleSubmit,
  } = useCompanyDetailsHook();


  const titleDetails = () => {
    return (
      <>
        <div className="company_Head flt">
          <div className="company_main_name flt">
            <div className="company_name1 flt">
              <img src={Ic_Home13} alt="Ic_Home13" />
              {t("COMPANY")}
            </div>
          </div>
        </div>
      </>
    )
  }

  const companyDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (

            <div className="E_comp_details flt">
              <div className="main_boxShadow flt">

                <div className="main_company flt">
                  <div className="main_row flt">
                    <div className="row">
                      <div className="col-sm-9">

                        <div className="row">
                          <div className="col-sm-4">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Company Name")} *</label>
                                <input
                                  className="comp_input"
                                  type="text"
                                  placeholder="Company Name"
                                  name="companyName"
                                  id="companyName"
                                  value={companyName}

                                  onChange={handleCompanyName}
                                />
                                {submit && !companyName && (
                                  <div className="req_field">
                                    {t("Company Name is required")}
                                  </div>
                                )}
                              </div>
                            </div>




                          </div>
                          <div className="col-sm-4">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Owner Name")} *</label>
                                <input
                                  type="text"
                                  placeholder="Owner Name"
                                  name="ownerName"
                                  id="ownerName"
                                  value={ownerName}

                                  onChange={handleOwnerName}
                                />
                                {submit && !ownerName && (
                                  <div className="req_field">
                                    {t("Owner Name is required")}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">
                                  {t("VAT / Tax ID Number")} *
                                </label>
                                <input
                                  className="comp_input"
                                  type="text"
                                  placeholder="VAT / Tax ID Number"
                                  name="vatId"
                                  id="vatId"
                                  value={vatId}

                                  onChange={handleVat}
                                />
                                {submit && !vatId && (
                                  <div className="req_field">
                                    {t("VAT / Tax ID Number is required")}
                                  </div>
                                )}
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-4">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Address")} *</label>
                                <input
                                  className="comp_input"
                                  type="text"
                                  placeholder="Address"
                                  name="address"
                                  id="address"
                                  value={address}

                                  onChange={handleAddress}
                                />
                                {submit && !address && (
                                  <div className="req_field">{t("Address is required")}</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Address 2")}</label>
                                <input
                                  className="comp_input"
                                  type="text"
                                  placeholder="Address 2"
                                  name="addressTwo"
                                  id="addressTwo"
                                  value={addressTwo}

                                  onChange={handleAddressTwo}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("City")} *</label>
                                <input

                                  type="city"
                                  placeholder="City"
                                  name="city"
                                  id="city"
                                  value={city}

                                  onChange={handleCity}
                                />
                                {submit && !city && (
                                  <div className="req_field">{t("City is required")}</div>
                                )}
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("State")}*</label>
                                <select
                                  name="states"
                                  id="states"
                                  value={states}
                                  onChange={handleStates}
                                >
                                  <option default value="Karnataka">{t("Karnataka")}</option>
                                  <option value="kerala">{t("kerala")}</option>
                                  <option value="tamilnadu">{t("tamilnadu")}</option>
                                  <option value="goa">{t("goa")}</option>
                                  <option value="Maharastra">{t("Maharastra")}</option>
                                  <option value="west bangal">{t("west bangal")}</option>
                                  <option value="jammu&kashmir">{t("jammu&kashmir")}</option>
                                  <option value="gujarat">{t("gujarat")}</option>
                                </select>
                                {submit && !states && (
                                  <div className="req_field">{t("State is required")}</div>
                                )}
                              </div>

                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Zip / Postal")} *</label>
                                <input
                                  className="comp_input"
                                  type="number"
                                  placeholder="Zip / Postal"
                                  name="zip"
                                  id="zip"
                                  value={zip}

                                  onChange={handleZip}
                                />
                                {submit && !zip && (
                                  <div className="req_field">{t("Zip is required")}</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Country")} *</label>
                                <select
                                  name="country"
                                  id="country"
                                  value={country}

                                  onChange={handleCountry}
                                >
                                  <option value="India">{t("India")}</option>
                                  <option value="China">{t("china")}</option>
                                  <option value="japan">{t("japan")}</option>
                                  <option value="UAE">{t("UAE")}</option>
                                  <option value="USA">{t("USA")}</option>
                                  <option value="canada">{t("canada")}</option>
                                  <option value="afganistan">{t("afganistan")}</option>
                                  <option value="nepal">{t("nepal")}</option>
                                </select>
                                {submit && country === "" && (
                                  <div className="req_field">{t("Country is required")}</div>
                                )}
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Fax Number")}</label>
                                <input
                                  type="number"
                                  placeholder="fax"
                                  name="fax"
                                  id="fax"
                                  value={fax}

                                  onChange={handleFax}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Phone Number")} *</label>
                                <input
                                  type="number"
                                  placeholder="Phone Number"
                                  name="phone"
                                  id="phone"
                                  value={phone}

                                  onChange={handlePhone}
                                />
                                {submit && !phone && (
                                  <div className="req_field">
                                    {t("Phone Number is required")}
                                  </div>
                                )}
                              </div>
                            </div>

                          </div>
                          <div className="col-sm-4">
                            <div className="comp_details flt">
                              <div className="company_name flt">
                                <div className="form-group flt">
                                  <label className="labelName">{t("Email")} *</label>
                                  <input
                                    type="text"
                                    placeholder="Email"
                                    name="email"
                                    id="email"
                                    value={email}

                                    onChange={handleEmail}
                                  />
                                  {submit && !email && (
                                    <div className="req_field">{t("Email is required")}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Web Page")}</label>
                                <input
                                  className="comp_input"
                                  type="text"
                                  placeholder="Web Page"
                                  name="webPage"
                                  id="webPage"
                                  value={webPage}

                                  onChange={handleWebPage}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className=" company_name flt">
                              <div className="form-group flt">
                                <label className="labelName">{t("Bank Account")}</label>
                                <input
                                  type="number"
                                  placeholder="Account Number"
                                  name="bankAccount"
                                  id="bankAccount"
                                  value={bankAccount}

                                  onChange={handleBank}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4"></div>
                        </div>



                      </div>



                      <div className="col-sm-3">
                        <div className="upload_img flt">
                          <div className="upload_img_btn flt">

                            <button>
                              {selectedFile1 && (
                                < img
                                  src={logoImg}
                                  width="100%"
                                  height="150"
                                  alt="pay"
                                />
                              )}
                              {!selectedFile1 && logoImg !== "" && (
                                < img
                                  src={`${process.env.REACT_APP_MEDIA_DOMAIN}${logoImg}`}
                                  width="100%"
                                  height="150"
                                  alt="pay"
                                />
                              )}
                              {!selectedFile1 && logoImg === "" && (
                                <>
                                  <img src={Ic_Home14} alt="pay" />
                                  <div className="browse_logo_img">
                                    {t("Browse Logo Image")}
                                  </div></>
                              )}


                            </button>
                            <input
                              type="file"
                              name="myfile"
                              onChange={onSelectFile1}
                              key={fileName}
                              id="myfile2"
                              accept="image/png,image/jpeg"
                            />
                          </div>
                        </div>

                        <div className="main_ownbutton flt">
                          <div className="own_button flt">
                            <button
                              className="backbutton"
                              onClick={navigateToCompanyDetails}
                            >
                              {t("Back")}
                            </button>
                            {id === "" ?
                              <button
                                className="submitbutton"
                                onClick={handleAddSubmit}
                              >
                                {t("Submit")}
                              </button> : <button
                                className="submitbutton"
                                onClick={handleSubmit}
                              >
                                {t("Update")}
                              </button>}
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>

              </div>
            </div>)
        }</>
    )
  }
  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      <LeftSidebar />
      <div className="chat_right1 flt">
        {titleDetails()}
        {companyDetails()}

      </div>
    </div>
  );
};

export default withTranslation()(CompanyDetails);
