import * as axios from "../network/Network";

const config = require("../network/api.json");

class InvoiceService {

  /**
   * Get closed agreement
   * @param {*} page 
   * @param {*} value 
   * @param {*} search 
   * @param {*} from 
   * @param {*} to 
   * @returns 
   */
  static getClosedAgreementList(page, value, search, from, to) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getClosedAgreements}?page=${page}&searchby=${value}&value=${search}&from=${from}&to=${to}`,
    });
    return Promise.resolve(result);
  }
}
export { InvoiceService };
