import { useState, useEffect } from "react";
import { ReportService } from "../../../../services";
import axios from "axios";
import { useSelector } from "react-redux";

const useVehicleInfoHooks = () => {
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);
  const [loaderDownLoad, setLoaderDownload] = useState("notset")
  const authdata = useSelector((state) => state?.userData);

  const columns = [
    {
      name: "Stock No.",
      selector: (row) => row.stockNumber,
      width: "150px"
    },
    {
      name: "Reg No",
      selector: (row) => row.regNumber,
      width: "150px"

    },
    {
      name: "ODO. No ",
      selector: (row) => row.ODOMeterNo,
      width: "150px"

    },

    {
      name: "Vehicle Class",
      selector: (row) => row.vehicleClass,
      width: "150px"
    },

    {
      name: "Make",
      selector: (row) => row.make,
      width: "150px"
    },
    {
      name: "Model",
      selector: (row) =>
        row.model,
      width: "150px"
    },
    {
      name: "Color",
      selector: (row) => row.color,
      width: "150px"
    },
    {
      name: "Year",
      selector: (row) => row.year,
      width: "150px"
    },
    {
      name: "Transmission",
      selector: (row) => row.transmission,
      width: "150px"
    },
    {
      name: "Location",
      selector: (row) => row.location,
      width: "150px"
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={
            row.vehicle_status === "available"
              ? "button_style_available"
              : row.vehicle_status === "onrent"
                ? "button_style_onrent"
                : row.vehicle_status === "sold"
                  ? "button_style_sold"
                  : row.vehicle_status === "maintanance"
                    ? "button_style_maintainance"
                    : row.vehicle_status === "service"
                      ? "button_style_service"
                      : ""
          }
        >
          {row.vehicle_status === "available"
            ? "Available"
            : row.vehicle_status === "onrent"
              ? "On Rent"
              : row.vehicle_status === "maintanance"
                ? "In Maintenance"
                : row.vehicle_status === "sold"
                  ? "Sold"
                  : row.vehicle_status === "service"
                    ? "In Service"
                    : ""}
        </div>
      ),
      width: "150px"
    },
  ];

  let order = [];
  let obj = {};
  /**
   * list call
   */
  useEffect(() => {
    vehicleInfoListResponse(page);
  }, [page]);

  /**
   * table list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * table list
   */
  const list = () => {
    listData.data.data.docs.map((item, index) => {

      obj = {
        stockNumber: item?.stockNumber ? item?.stockNumber : "N/A",
        regNumber: item?.regNumber ? item?.regNumber : "N/A",
        vehicleClass: item?.vehicleClass?.[0]?.vehicleClass ? item?.vehicleClass?.[0]?.vehicleClass : "N/A",
        make: item?.make?.[0]?.make ? item?.make?.[0]?.make : "N/A",
        model: item?.model?.[0]?.model ? item?.model?.[0]?.model : "N/A",
        ODOMeterNo: item?.ODOMeterNo ? item?.ODOMeterNo : "N/A",
        color: item?.color ? item?.color : "N/A",
        year: item?.year ? item?.year : "N/A",
        transmission: item?.transmission ? item?.transmission : "N/A",
        location: item?.location?.[0]?.locationName ? item?.location?.[0]?.locationName : "N/A",
        vehicle_status: item?.vehicle_status ? item?.vehicle_status : "N/A"

      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * list api call
   */
  const vehicleInfoListResponse = () => {
    setLoader("loading");
    ReportService.getVehicleInfoList(page)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setLoader("isfull");
          setListData(response);
          setTotalPages(response?.data?.data?.totalPages);
          setPage(response?.data?.data?.page);
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * handle page change
   * @param {*} e 
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };

  /**
   * download report
   */
  const downloadReport = () => {
    setLoaderDownload("loading");
    axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_APIURL}/admin/reports/v-excel`,

      method: "GET",
      responseType: "arraybuffer",

      headers: {
        // Add any auth token here
        authorization: authdata?.type + " " + authdata?.token,
        "Content-Type": "blob",
      },
      // Attaching the form data
    }).then((response) => {
      setLoaderDownload("isfull");
      const resp = response;
      const link = document.createElement("a");
      const fileName = `${Date.now()}.xlsx`;
      link.setAttribute("download", fileName);
      link.href = URL.createObjectURL(new Blob([resp.data]));
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  return {
    loader,
    listData,
    totalPages,
    page,
    columns,
    listTable,
    loaderDownLoad,
    downloadReport,
    handlePageClick,
  };
};
export default useVehicleInfoHooks;
