import "../../../assets/css/style.css";
import "./Login.css";
import { login } from "../../../assets/images";
import { mobile_logo } from "../../../assets/images";
import useLoginHooks from "./useLoginHooks";
import { Loading } from "../../../assets/images";
import { withTranslation } from "react-i18next";

function Login(props) {
  const { t } = props;
  const {
    loader,
    userName,
    password,
    submitted,
    handleUserIdChange,
    handlePasswordChange,
    handleSubmit,
  } = useLoginHooks();



  const loginDetails = () => {
    return (
      <>

        {
          loader === "loading" && (
            <div className="main_load ">
              <img src={Loading} alt="loader" />
            </div>
          )
        }
        {
          (loader === "isfull" || loader === "isempty" || loader === "notset") && (
            <div className="login_page flt">
              <div className="login_box">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="login_left flt">
                      <img
                        src={mobile_logo}
                        className="hide_desktop mobile_logo"
                        alt="mobile"
                      />
                      <img src={login} alt="login" className="hide_mobile" />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="login_right flt">
                      <div className="login_head flt ">{t("User Login")} </div>
                      <form className="flt">
                        <div className="form-group flt">
                          <div className="reqst flt">
                            <div className="user_id">{t("User Name")}</div>
                            <input
                              type="text"
                              placeholder=" "
                              value={userName}
                              onChange={handleUserIdChange}
                              autoComplete="off"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleSubmit(e);
                                }
                              }}
                            />
                          </div>
                          {submitted && !userName && (
                            <div className="required">{t("User Name is required")}</div>
                          )}
                        </div>
                        <div className="form-group flt">
                          <div className="password">{t("Password")}</div>
                          <input
                            type="password"
                            placeholder=""
                            onChange={handlePasswordChange}
                            autoComplete="off"
                            value={password}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSubmit(e);
                              }
                            }}
                          />
                          {submitted && !password && (
                            <div>{t("Password is required")}</div>
                          )}
                        </div>
                      </form>
                      <div className="login_btn flt">
                        <button type="button" onClick={handleSubmit}>
                          {t("Login")}
                        </button>
                      </div>
                      <div className="version">{t("Version: 4.1.5.1")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </>
    )
  }
  return (
    loginDetails()
  );
}

export default withTranslation()(Login);
