import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { alertActions } from "../../../../actions";
import { AgreementService } from "../../../../services";
import axios from "axios";
import { Loading } from "../../../../assets/images";
import moment from "moment";

const useAgreementHook = () => {
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("mobileno");

  const [listData1, setListData1] = useState([]);
  let [page1, setPage1] = useState(1);
  const [totalPages1, setTotalPages1] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const bid = location?.search?.split("bid")[1]?.substring(1, 25);
  const [loader, setLoader] = useState("notset");
  const dispatch = useDispatch();
  const [upState, setUpState] = useState(false);
  const authdata = useSelector((state) => state?.userData);
  const [listTable, setListTable] = useState([]);

  const columns = [
    {
      name: "Ref. No ",
      selector: (row) => row.Reference_number,
      width: "180px",
    },
    {
      name: "Paid Amt.",
      selector: (row) => row.Payment_Amount,
      width: "220px",
    },

    {
      name: "Pymt Meth",
      selector: (row) => (row.Payment_type ? row.Payment_type : "N/A"),
      width: "220px",
    },
    {
      name: "Pymt. Date",
      selector: (row) =>
        row.Payment_Date
          ? moment(row.Payment_Date).format("DD/MM/YYYY")
          : "N/A",
      width: "220px",
    },
    {
      name: "Discount",
      selector: (row) => (row.discountCharges ? row.discountCharges : "N/A"),
      width: "220px",
    },
    {
      name: "Delete",
      selector: (row) => (
        <button className="viewdata" onClick={() => deletePaymentHistory(row)}>
          Delete
        </button>
      ),
      width: "220px",
    },
    {
      name: "Download",
      selector: (row) => (
        <>
          {loader === "loading" && (
            <div className="table_loader ">
              <img src={Loading} alt="loader" />
            </div>
          )}
          {loader !== "loading" && (
            <button className="viewdata" onClick={() => downloadPdf(row)}>
              Download
            </button>
          )}
        </>
      ),
      width: "220px",
    },
  ];

  let order = [];
  let obj = {};

  /**
   * booking list call
   */
  useEffect(() => {
    if (!bid) {
      reservationResponse(page, "mobileno", "", "");
    }
    // eslint-disable-next-line
  }, [page]);

  /**
   * payment list call
   */
  useEffect(() => {
    if (bid) {
      paymentResponse(page1, bid);
    }
  }, [page1, bid]);

  /**
   * table list
   */
  useEffect(() => {
    if (listData1?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData1?.data?.data?.docs]);

  /**
   * table list
   */
  const list = () => {
    listData1?.data?.data?.docs.map((item, index) => {
      console.log("data", listData1.data);

      obj = {
        id: item?._id,

        Reference_number: item?.Reference_number,
        Payment_Amount: `₹${item?.Payment_Amount}`,

        Payment_type: item?.Payment_type,

        Payment_Date: item?.Payment_Date,
        discountCharges: item?.discountCharges,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * payment list
   * @param {*} page1
   * @param {*} bid
   */
  const paymentResponse = (page1, bid) => {
    setLoader("loading");
    AgreementService.getPaymentList(page1, bid)
      .then((response) => {
        setLoader("isfull");

        setListData1(response);
        setTotalPages1(response?.data?.data?.totalPages);
        setPage1(response?.data?.data?.page);
      })
      .catch((err) => console.log(err));
  };

  /**
   * booking list api call
   * @param {*} page
   * @param {*} item
   * @param {*} search
   */
  const reservationResponse = (page, item, search) => {
    setLoader("loading");
    AgreementService.getReturnList(page, item, search)
      .then((response) => {
        setLoader("isfull");

        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
      })
      .catch((err) => console.log(err));
  };

  /**
   * delete payment history
   *
   * @param {*} row
   */
  const deletePaymentHistory = (row) => {
    setLoader("loading");
    AgreementService.deletePaymentHistory(row?.id)
      .then((response) => {
        setUpState(!upState);

        if (response?.data?.status?.code === 0) {
          dispatch(alertActions.success(response?.data?.status?.message));
          paymentResponse(page1, bid);
          setLoader("isfull");
        } else {
          setLoader("isempty");
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
    // setUpState(false);
  };

  /**
   * change item handle
   * @param {*} e
   */
  const changeItemHandler = (e) => {
    setSearch("");

    if (e.target.value === "fullname") {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "");
    } else {
      setItem(e.target.value);
      reservationResponse(1, e.target.value, "");
    }
  };
  /**
   * search handle
   * @param {*} e
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    reservationResponse("", item, e.target.value);
  };

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    reservationResponse(e.selected + 1, item, search);
  };
  const handlePageClick1 = (e) => {
    setPage(e.selected + 1);
    paymentResponse(e.selected + 1, bid);
  };
  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    navigate(`/EndRentDetailsTab?bid=${row?.id}`);
  };

  const bookingDetails = () => {
    navigate("/booking");
  };
  const openEndRent = () => {
    navigate("/EndrentList");
  };
  const openExchange = () => {
    navigate("/exchange-list");
  };

  localStorage.setItem("upState", upState);

  /**
   * download
   * @param {*} row
   */
  const downloadPdf = (row) => {
    setLoader("loading");
    axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_APIURL}/admin/agreement/booking/download-history-pdf/${row.id}`,

      method: "GET",
      responseType: "arraybuffer",

      headers: {
        // Add any auth token here
        authorization: authdata?.type + " " + authdata?.token,
        "Content-Type": "blob",
      },
      // Attaching the form data
    }).then((response) => {
      setLoader("isfull");
      const resp = response;
      const link = document.createElement("a");
      const fileName = `${Date.now()}.pdf`;
      link.setAttribute("download", fileName);
      link.href = URL.createObjectURL(new Blob([resp.data]));
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };
  return {
    listData,
    totalPages,
    search,
    page,
    listData1,
    totalPages1,
    page1,
    loader,
    upState,
    columns,
    listTable,
    handlePageClick,
    changeItemHandler,
    changeSearchHandler,
    handleRow,
    bookingDetails,
    openEndRent,
    openExchange,
    handlePageClick1,
  };
};
export default useAgreementHook;
