import TopBar from "../../../_common/topbar/TopBar";
import { Link } from "react-router-dom";
import "../VehiclesDetails.css";
import { useEffect, useState } from "react";
import { VehicleService } from "../../../../services";
import Menu from "../../../_common/menu/Menu";
import GeneralInfo from "./General_Info/General_Info";
import DocumentsTab from "./Documents/documents";
import { useLocation } from "react-router-dom";
import useGeneralInfo from "./General_Info/useGeneralInfo";
import MaintananceTab from "./Maintanance/Maintanace";
import DamageTab from "./Damages/damages";
import SoldTab from "./Sold/sold";
import { withTranslation } from "react-i18next";

const VehicleDetails = (props) => {
  const { t } = props;
  const { handleDeleteVehicleSubmit } = useGeneralInfo();

  const location = useLocation();
  const vehicleId = location.search.split("=")[1]?.substring(0, 24);

  const [tab, setTab] = useState(1);
  const [tabId, setTabId] = useState(vehicleId);

  const [check, setCheck] = useState(vehicleId || tabId ? true : false);

  const vehId = vehicleId;
  // const [listTable, setListTable] = useState([]);
  const [regNo, setRegNo] = useState("");
  const [makeNo, setMakeNo] = useState("");
  const [modalNo, setModalNo] = useState("");
  const [yearNo, setYearNo] = useState("");

  /**
   * get vehicle single info
   */
  useEffect(() => {
    if (vehId !== null && vehId !== undefined && vehId !== "undefined") {
      VehicleService.getSingleInfoVehicle(vehId)
        .then((response) => {
          setRegNo(response?.data?.data?.regNumber);
          setMakeNo(response?.data?.data?.make);
          setModalNo(response?.data?.data?.model);
          setYearNo(response?.data?.data?.year);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [vehId !== null, regNo !== "", tab === 7]);

  const headerNames = () => {
    return (
      <>
        <div className="main_location flt edit_vehicle_fix">
          {check === false ? (
            <div className="location flt">VEHICLES</div>
          ) : (
            <div className="location flt">EDIT VEHICLES</div>
          )}
          {/* {check && (
          <div className="delete_b flt">
            <button
              className="delete_btn"
              onClick={(e) => handleDeleteVehicleSubmit(e, tabId)}
            >
              Delete Vehicle
            </button>
          </div>
        )} */}
        </div>
      </>
    );
  };

  const getTabInfo = () => {
    return (
      <>
        <div className="main_general flt">
          <div className="main_vehicle_info flt">
            <div className="main_vehicle flt">
              <ul className="vehicle_info">
                <li>
                  <div className="vehicle_infor">Vehicle Information</div>
                </li>
              </ul>
            </div>
          </div>

          <div className="general_informaion flt">
            <ul className="insurance_info">
              <li className={tab === 1 ? `activeborder` : ""}>
                <Link
                  onClick={() => check && setTab(1)}
                  to={`/vehicle-details?vid=${vehicleId || ""}`}
                >
                  General Information
                </Link>
              </li>
              <li className={tab === 2 ? `activeborder` : ""}>
                <Link
                  onClick={() => check && setTab(2)}
                  to={`/vehicle-details?vid=${vehicleId || ""}`}
                >
                  Documents
                </Link>
              </li>
              <li className={tab === 3 ? `activeborder` : ""}>
                <Link
                  onClick={() => check && setTab(3)}
                  to={`/vehicle-details?vid=${vehicleId || ""}`}
                >
                  Maintenance & Expenses
                </Link>
              </li>
              <li className={tab === 4 ? `activeborder` : ""}>
                <Link
                  onClick={() => check && setTab(4)}
                  to={`/vehicle-details?vid=${vehicleId || ""}`}
                >
                  Damage
                </Link>
              </li>
              <li className={tab === 6 ? `activeborder` : ""}>
                <Link
                  onClick={() => check && setTab(6)}
                  to={`/vehicle-details?vid=${vehicleId || ""}`}
                >
                  Vehicles Sold
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  };

  const tabDisplay = () => {
    return (
      <>
        <div className="E_comp_details flt">
          {tab === 1 && (
            <GeneralInfo tab={setTab} setTabId={setTabId} tabId={tabId} t={t} />
          )}
          {tab === 2 && (
            <DocumentsTab
              tab={setTab}
              setTabId={setTabId}
              tabId={tabId}
              t={t}
            />
          )}
          {tab === 3 && (
            <MaintananceTab
              tab={setTab}
              setTabId={setTabId}
              tabId={tabId}
              t={t}
            />
          )}
          {tab === 4 && (
            <DamageTab tab={setTab} setTabId={setTabId} tabId={tabId} t={t} />
          )}
          {tab === 6 && (
            <SoldTab tab={setTab} setTabId={setTabId} tabId={tabId} t={t} />
          )}
        </div>
      </>
    );
  };
  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />

      {headerNames()}
      {getTabInfo()}
      {tabDisplay()}
    </div>
  );
};

export default withTranslation()(VehicleDetails);
