import { useEffect, useState } from "react";
import { VehicleService } from "../../../../../services/VehicleService";
import { useNavigate } from "react-router-dom";
import { alertActions } from "../../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const useServiceAlerts = (props) => {
  const { t } = props;
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState("notset");
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("regno");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listTable, setListTable] = useState([]);
  const dashBoardDisplay = useSelector((state) => state?.userData?.user?.dashboardUA)

  const columns = [
    {
      name: "Sr",
      selector: (row) => row.number,
      grow: 0,
      width: "80px",
    },
    {
      name: "Reg.No",
      selector: (row) => row.regNumber,
      width: "150px",
    },
    {
      name: "Odo Reading",
      selector: (row) => row.odometer,
      width: "150px",
    },
    {
      name: "Model",
      selector: (row) => row.model,
      width: "150px",
    },
    {
      name: "Km at Last ser.",
      selector: (row) => row.kms_at_last_service,
      width: "150px",
    },
    {
      name: "ser. Interval km",
      selector: (row) => row.Service_Interval_Km,
      width: "150px",
    },
    {
      name: "Last ser. Date",
      selector: (row) => row.maintanance_Last_Service_Date,
      width: "180px",
    },
    {
      name: "Next ser. Date",
      selector: (row) => row.maintanance_Next_Service_Date,
      width: "180px",
    },
    {
      name: "Oil Next ser. Date",
      selector: (row) => row.Oil_service_Next_Service_Date,
      width: "180px",
    },

    {
      name: "Description",
      selector: (row) => row.description,
      width: "150px",
    },

    {
      name: "View",
      selector: (row) => (
        dashBoardDisplay?.includes("maintAlerts") ?
          <button className="viewdata" onClick={() => handleRow(row)}>
            {t("View")}
          </button> : <button style={{ pointerEvents: "none", opacity: "0.5" }} className="viewdata" onClick={() => handleRow(row)}>
            {t("View")}
          </button>
      ),
      width: "150px",
    },
    {
      name: "Delete",
      selector: (row) => (
        dashBoardDisplay?.includes("maintAlerts") ?
          <button
            className="viewdata"
            onClick={() => deleteMaintenanceAlert(row)}
          >
            {t("Delete")}
          </button> : <button style={{ pointerEvents: "none", opacity: "0.5" }}
            className="viewdata"
            onClick={() => deleteMaintenanceAlert(row)}
          >
            {t("Delete")}
          </button>
      ),
      width: "150px",
    },
  ];

  let order = [];
  let obj = {};

  /**
   * call list api
   */
  useEffect(() => {
    vehicleDocResponse(page, item, search);
    // eslint-disable-next-line
  }, []);

  /**
   * table list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * table list call
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      obj = {
        id: item?._id,
        number:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        vehicleID: item?.vehicleid,
        Description: item?.group?.Oil_service_expense?.Description,
        model: item?.model ? item?.model : "N/A",
        regNumber: item?.regNumber ? item?.regNumber : "N/A",
        description: item?.description ? item?.description : "N/A",
        location: item?.location ? item?.location : "N/A",
        status: item?.status ? item?.status : "N/A",
        odometer: item?.odometer ? item?.odometer : "N/A",
        kms_at_last_service: item?.vehicleMaintanances?.[0]?.maintanance
          ?.kms_at_last_service
          ? item?.vehicleMaintanances?.[0]?.maintanance?.kms_at_last_service
          : "N/A",
        Service_Interval_Km: item?.vehicleMaintanances?.[0]?.maintanance
          ?.kms_at_last_service
          ? item?.vehicleMaintanances?.[0]?.maintanance?.kms_at_last_service
          : "N/A",
        maintanance_Last_Service_Date: item?.vehicleMaintanances?.[0]
          ?.maintanance?.maintanance_Last_Service_Date
          ? moment(
            item?.vehicleMaintanances?.[0]?.maintanance
              ?.maintanance_Last_Service_Date
          ).format("DD/MM/YYYY, h:mm:ss a")
          : "N/A",

        maintanance_Next_Service_Date: item?.vehicleMaintanances?.[0]
          ?.maintanance?.maintanance_Next_Service_Date
          ? moment(
            item?.vehicleMaintanances?.[0]?.maintanance
              ?.maintanance_Next_Service_Date
          ).format("DD/MM/YYYY, h:mm:ss a")
          : "N/A",
        Oil_service_Next_Service_Date: item?.oilservice?.Oil_service_expense
          ?.Oil_service_Next_Service_Date
          ? moment(
            item?.oilservice?.Oil_service_expense
              ?.Oil_service_Next_Service_Date
          ).format("DD/MM/YYYY, h:mm:ss a")
          : "N/A",
      };
      return order.push(obj);
    });
    if (order.length) setListTable(order);
  };

  /**
   * list api call
   * @param {*} page 
   * @param {*} item 
   * @param {*} search 
   */
  const vehicleDocResponse = (page, item, search) => {
    setLoader("loading");
    VehicleService.getServiceAlertList(page, item, search)
      .then((response) => {
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        setLoader("isfull");
      })
      .catch((err) => console.log(err));
  };


  /**
   * change handler
   * @param {*} e 
   */
  const changeItemHandler = (e) => {
    setSearch("");
    if (e.target.value === "model") {
      setItem(e.target.value);
      vehicleDocResponse(1, e.target.value, "");
    } else {
      setItem(e.target.value);
      vehicleDocResponse(1, e.target.value, "");
    }
  };

  /**
   * search handler
   * @param {*} e 
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    vehicleDocResponse("", item, e.target.value);
  };

  /**
   * handle row
   * @param {*} row 
   */
  const handleRow = (row) => {
    navigate(`/vehicle-details?vid=${row.vehicleID}`, {
      state: {
        row: row,
      },
    });
  };

  /**
   * delete Maintenance
   * @param {*} row 
   */
  const deleteMaintenanceAlert = (row) => {
    setLoader("loading");
    VehicleService.deleteMaintenanceAlertList(row?.id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          dispatch(alertActions.success(response?.data?.status?.message));
          vehicleDocResponse(page, item, search);
          setLoader("isfull");
        } else {
          setLoader("isempty");
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * handle page change
   * @param {*} e 
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    vehicleDocResponse(e.selected + 1, item, search);
  };

  return {
    listData,
    totalPages,
    page,
    loader,
    search,
    columns,
    listTable,
    changeItemHandler,
    changeSearchHandler,
    handlePageClick,

  };
};
export default useServiceAlerts;
