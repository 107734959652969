const SetUpVehicle = ({ vehiclesUA, handleVehicle }) => {
  let vehicleData = [];
  vehicleData = vehiclesUA ? vehiclesUA : [];

  const vehicleDetails = () => {
    return (
      <>
        <ul className="make_new flt">
          <li className="check_btn3">
            <input type="checkbox" id="view" name="view" value="view" checked={
              vehicleData.includes("view")}
              onChange={(e) => handleVehicle(e, vehicleData)
              } />
            <label for="view">View</label>
          </li>

          <li className="check_btn3">
            <input type="checkbox" id="update" name="update" value="update" checked={
              vehicleData.includes("update")}
              onChange={(e) => handleVehicle(e, vehicleData)
              } />
            <label for="update">Update</label>
          </li>
        </ul>
      </>
    )
  }
  return (
    <>
      {vehicleDetails()}
    </>
  );
};
export default SetUpVehicle;
