import { useLocation } from "react-router";
import useSold from "./useSold";
import { Loading } from "../../../../../assets/images";
import { Calender } from "../../../../../assets/images";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const SoldTab = ({ tab, tabId, t }) => {
  const location = useLocation();

  const {
    loader,
    soldCustomerName,
    soldDate,
    salesPrice,
    workCost,
    fittingCharges,
    otherCost,
    checkRes,
    id,
    handleSoldCustomerName,
    handleSoldDate,
    handleSalesPrice,
    handleWorkCost,
    handleFittingCharges,
    handleOtherCost,
    handleVehicleSold,
    handleEditVehicleSold,
  } = useSold(location, tab, tabId);

  const soldDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
            <>
              <div className="main_vehicle_sold flt">
                <div className="E_comp_details1 flt">
                  <div className="main_boxShadow flt">
                    <div classN ame="main_emission flt">
                      <div className="Company_name flt">
                        <div className="comp_name_details flt">
                          {t("Vehicle_sold_details")}
                        </div>
                      </div>
                      <div className="main_company flt">
                        <div className="main_row flt">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="company_name flt">
                                <div className="form-group flt">
                                  <label className="labelName">
                                    {t("Customer_Name")}
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Customer Name"
                                    name="soldCustomerName"
                                    id="soldCustomerName"
                                    onChange={handleSoldCustomerName}
                                    value={soldCustomerName}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className=" company_name vehicle_sold flt">
                                <div className="form-group flt">
                                  <label className="labelName">
                                    {t("Sold_Date")}
                                  </label>
                                  <div className="make_img flt">
                                    <Datetime
                                      dateFormat="DD/MM/YYYY"
                                      onChange={handleSoldDate}
                                      value={soldDate}
                                      closeOnSelect={true}
                                      timeFormat={false}
                                      inputProps={{
                                        placeholder: "Select From date",
                                        disabled: false,
                                        readOnly: true,
                                        onKeyDown: (e) => e.preventDefault(),
                                      }}
                                    />
                                    <div className="cal_img">
                                      <img src={Calender} alt="calender" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-3">
                              <div className="company_name flt">
                                <div className="form-group flt">
                                  <label className="labelName">
                                    {t("Sale_Price")}
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="Sale Price"
                                    name="salesPrice"
                                    id="salesPrice"
                                    onChange={handleSalesPrice}
                                    value={salesPrice}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="E_comp_details12 flt">
                  <div className="main_boxShadow flt">
                    <div classN ame="main_emission flt">
                      <div className="Company_name flt">
                        <div className="comp_name_details flt">
                          {t("Cost_Selling")}
                        </div>
                      </div>
                      <div className="main_company flt">
                        <div className="main_row flt">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="company_name flt">
                                <div className="form-group flt">
                                  <label className="labelName">
                                    {t("RTO_Work_Cost")}
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="RTO Work Cost"
                                    name="workCost"
                                    id="workCost"
                                    onChange={handleWorkCost}
                                    value={workCost}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className=" company_name flt">
                                <div className="form-group flt">
                                  <label className="labelName">
                                    {t("Fitting_Charges")}
                                  </label>
                                  <div className="make_img flt">
                                    <input
                                      type="number"
                                      id="fittingCharges"
                                      name="fittingCharges"
                                      onChange={handleFittingCharges}
                                      placeholder="Fitting Charges"
                                      value={fittingCharges}
                                    ></input>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-3">
                              <div className="company_name flt">
                                <div className="form-group flt">
                                  <label className="labelName">
                                    {t("Other_Cost")}
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="Other Cost"
                                    name="otherCost"
                                    id="otherCost"
                                    onChange={handleOtherCost}
                                    value={otherCost}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-3"></div>
                            <div className="main_ownbutton flt">
                              <div className="own_button flt">
                                {(checkRes === true || id !== "") && (
                                  <button
                                    className="backbutton"
                                    onClick={() => tab(4)}
                                  >
                                    {t("Back")}
                                  </button>
                                )}
                                {checkRes === false && (
                                  <button
                                    className="submitbutton"
                                    onClick={(e) => handleVehicleSold(e, tabId)}
                                  >
                                    {t("Submit")}
                                  </button>
                                )}
                                {checkRes === true && (
                                  <button
                                    className="submitbutton"
                                    onClick={(e) =>
                                      handleEditVehicleSold(e, tabId)
                                    }
                                  >
                                    {t("Update")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </>
    );
  };
  return <>{soldDetails()}</>;
};
export default SoldTab;
