import * as axios from "../network/Network";

const config = require("../network/api.json");

class SetUpService {
  /**
   * addUser
   * @param {*} userId 
   * @param {*} userName 
   * @param {*} password 
   * @param {*} defLocation 
   * @param {*} userLevel 
   * @param {*} roleId 
   * @param {*} agreementsUA 
   * @param {*} vehiclesUA 
   * @param {*} locationsUA 
   * @param {*} dashboardUA 
   * @param {*} topRibbonUA 
   * @returns 
   */
  static addUser(userId, userName, password, defLocation, userLevel, roleId, agreementsUA, vehiclesUA, locationsUA, dashboardUA, topRibbonUA) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addUser,
      data: { userId, userName, password, defLocation, userLevel, roleId, agreementsUA, vehiclesUA, locationsUA, dashboardUA, topRibbonUA },
    });
    return Promise.resolve(result);
  }

  /**
   * edit user
   * @param {*} id 
   * @param {*} userId 
   * @param {*} userName 
   * @param {*} password 
   * @param {*} defLocation 
   * @param {*} userLevel 
   * @param {*} roleId 
   * @param {*} agreementsUA 
   * @param {*} vehiclesUA 
   * @param {*} locationsUA 
   * @param {*} dashboardUA 
   * @param {*} topRibbonUA 
   * @returns 
   */
  static editUser(
    id,
    userId,
    userName,
    password,
    defLocation,
    userLevel,
    roleId,
    agreementsUA,
    vehiclesUA,
    locationsUA,
    dashboardUA,
    topRibbonUA
  ) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editUser + `/${id}`,
      data: { userId, userName, password, defLocation, userLevel, roleId, agreementsUA, vehiclesUA, locationsUA, dashboardUA, topRibbonUA },
    });
    return Promise.resolve(result);
  }

  /**
   * delete user
   * @param {*} id 
   * @returns 
   */
  static deleteUser(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deleteUser + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * update company details
   * @param {*} id 
   * @param {*} companyName 
   * @param {*} ownerName 
   * @param {*} vat_tax_id_number 
   * @param {*} city 
   * @param {*} country 
   * @param {*} webPage 
   * @param {*} address 
   * @param {*} faxNumber 
   * @param {*} email 
   * @param {*} address2 
   * @param {*} zip_post_code 
   * @param {*} phoneNumber 
   * @param {*} bankAccount 
   * @param {*} state 
   * @returns 
   */
  static updateCompanyDetails(
    id,
    companyName,
    ownerName,
    vat_tax_id_number,
    city,
    country,
    webPage,
    address,
    faxNumber,
    email,
    address2,
    zip_post_code,
    phoneNumber,
    bankAccount,
    state,
    logoImg
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("companyName", companyName);
    bodyFormData.append("ownerName", ownerName);
    bodyFormData.append("vat_tax_id_number", vat_tax_id_number);
    bodyFormData.append("city", city);
    bodyFormData.append("country", country);
    bodyFormData.append("webPage", webPage);
    bodyFormData.append("address", address);
    bodyFormData.append("faxNumber", faxNumber);
    bodyFormData.append("email", email);
    bodyFormData.append("address2", address2);
    bodyFormData.append("zip_post_code", zip_post_code);
    bodyFormData.append("phoneNumber", phoneNumber);
    bodyFormData.append("bankAccount", bankAccount);
    bodyFormData.append("state", state);
    bodyFormData.append("logoImg", logoImg);

    const result = axios.appRequest({
      method: axios.PUT,
      url: `${config.user.updatecompanydetails}/${id}`,
      data: bodyFormData
    });
    return Promise.resolve(result);
  }
  /**
   * add company details
   * @param {*} id 
   * @param {*} companyName 
   * @param {*} ownerName 
   * @param {*} vat_tax_id_number 
   * @param {*} city 
   * @param {*} country 
   * @param {*} webPage 
   * @param {*} address 
   * @param {*} faxNumber 
   * @param {*} email 
   * @param {*} address2 
   * @param {*} zip_post_code 
   * @param {*} phoneNumber 
   * @param {*} bankAccount 
   * @param {*} state 
   * @returns 
   */
  static addCompanyDetails(
    companyName,
    ownerName,
    vat_tax_id_number,
    city,
    country,
    webPage,
    address,
    faxNumber,
    email,
    address2,
    zip_post_code,
    phoneNumber,
    bankAccount,
    state,
    logoImg
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("companyName", companyName);
    bodyFormData.append("ownerName", ownerName);
    bodyFormData.append("vat_tax_id_number", vat_tax_id_number);
    bodyFormData.append("city", city);
    bodyFormData.append("country", country);
    bodyFormData.append("webPage", webPage);
    bodyFormData.append("address", address);
    bodyFormData.append("faxNumber", faxNumber);
    bodyFormData.append("email", email);
    bodyFormData.append("address2", address2);
    bodyFormData.append("zip_post_code", zip_post_code);
    bodyFormData.append("phoneNumber", phoneNumber);
    bodyFormData.append("bankAccount", bankAccount);
    bodyFormData.append("state", state);
    bodyFormData.append("logoImg", logoImg);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addcompanydetails,
      data: bodyFormData
    });
    return Promise.resolve(result);
  }

  /**
   * add location
   * @param {*} locationCode 
   * @param {*} address 
   * @param {*} fax 
   * @param {*} city 
   * @param {*} locationName 
   * @param {*} addressTwo 
   * @param {*} states 
   * @param {*} email 
   * @param {*} phoneNumber 
   * @param {*} ownerName 
   * @param {*} zip 
   * @returns 
   */
  static addLocation(
    locationCode,
    address,
    fax,
    city,
    locationName,
    addressTwo,
    states,
    email,
    phoneNumber,
    ownerName,
    zip
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addLocation,
      data: {
        locationCode,
        address,
        fax,
        city,
        locationName,
        addressTwo,
        states,
        email,
        phoneNumber,
        ownerName,
        zip,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * edit location
   * @param {*} id 
   * @param {*} locationCode 
   * @param {*} address 
   * @param {*} fax 
   * @param {*} city 
   * @param {*} locationName 
   * @param {*} addressTwo 
   * @param {*} states 
   * @param {*} email 
   * @param {*} phoneNumber 
   * @param {*} ownerName 
   * @param {*} zip 
   * @returns 
   */
  static editLocation(
    id,
    locationCode,
    address,
    fax,
    city,
    locationName,
    addressTwo,
    states,
    email,
    phoneNumber,
    ownerName,
    zip
  ) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editLocation + `/${id}`,
      data: {
        locationCode,
        address,
        fax,
        city,
        locationName,
        addressTwo,
        states,
        email,
        phoneNumber,
        ownerName,
        zip,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * Delete location
   * @param {*} id 
   * @returns 
   */
  static deleteLocations(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deleteLocation + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * Get all users
   * @param {*} page 
   * @returns 
   */
  static getAllUsers(page) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getallusers}?page=${page}`,
    });
    return Promise.resolve(result);
  }

  /**
   * Get all locations
   * @param {*} page 
   * @returns 
   */
  static getAllLocations(page) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getalllocations}?page=${page}`,
    });
    return Promise.resolve(result);
  }

  /**
   * Get company details
   * @returns 
   */
  static getCompanyDetails() {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getcompanydetails}`,
    });
    return Promise.resolve(result);
  }

  /**
   * get vehicle class
   * @param {*} page 
   * @returns 
   */
  static getVehicleClass(page) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getVehicleClass + `?page=${page}`,
    });
    return Promise.resolve(result);
  }

  /**
   * add vehicle class
   * @param {*} vehicleClass 
   * @returns 
   */
  static addVehicleClass(vehicleClass) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addVehicleClass,
      data: { vehicleClass },
    });
    return Promise.resolve(result);
  }

  /**
   * edit vehicle class
   * @param {*} id 
   * @param {*} vehicleClass 
   * @returns 
   */
  static editVehicleClass(id, vehicleClass) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editVehicleClass + `/${id}`,
      data: { vehicleClass },
    });
    return Promise.resolve(result);
  }

  /**
   * get vehicle class without page
   * @returns 
   */
  static getVehicleClassWithoutPage() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getVehicleClassWithoutPage,
    });
    return Promise.resolve(result);
  }

  /**
   * add make
   * @param {*} vehicleClassId 
   * @param {*} make 
   * @returns 
   */
  static addMake(vehicleClassId, make) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addMake,
      data: { vehicleClassId, make },
    });
    return Promise.resolve(result);
  }

  /**
   * get make
   * @param {*} page 
   * @returns 
   */
  static getMake(page) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getMake + `?page=${page}`,
    });
    return Promise.resolve(result);
  }

  /**
   * edit make
   * @param {*} id 
   * @param {*} vehicleClassId 
   * @param {*} make 
   * @returns 
   */
  static editMake(id, vehicleClassId, make) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editMake + `/${id}`,
      data: { vehicleClassId, make },
    });
    return Promise.resolve(result);
  }

  /**
   * get make without page
   * @param {*} id 
   * @returns 
   */
  static getMakeWithoutPage(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getMakeWithoutPage + `?classId=${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * add model
   * @param {*} vehicleClassId 
   * @param {*} vehicleMakeId 
   * @param {*} model 
   * @returns 
   */
  static addModal(vehicleClassId, vehicleMakeId, model) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addModel,
      data: { vehicleClassId, vehicleMakeId, model },
    });
    return Promise.resolve(result);
  }

  /**
   * get model
   * @param {*} page 
   * @returns 
   */
  static getModel(page) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getModel + `?page=${page}`,
    });
    return Promise.resolve(result);
  }

  /**
   * edit model
   * @param {*} id 
   * @param {*} vehicleClassId 
   * @param {*} vehicleMakeId 
   * @param {*} model 
   * @returns 
   */
  static editModel(id, vehicleClassId, vehicleMakeId, model) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editModel + `/${id}`,
      data: { vehicleClassId, vehicleMakeId, model },
    });
    return Promise.resolve(result);
  }

  /**
   * get model without page
   * @param {*} vid 
   * @param {*} mid 
   * @returns 
   */
  static getModelWithoutPage(vid, mid) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getModelWithoutPage + `?classId=${vid}&makeId=${mid}`,
    });
    return Promise.resolve(result);
  }

  /**
   * add general info
   * @param {*} id 
   * @param {*} checkNextMain 
   * @param {*} nextMaintanance 
   * @param {*} gstRate 
   * @returns 
   */
  static addGeneralInfo(
    id,
    checkNextMain,
    nextMaintanance,
    checkNextOil,
    nextOil,
    checkNextPuc,
    nextPuc,
    checkNextRc,
    nextRc,
    checkNextFitness,
    nextFitness,
    checkNexPermit,
    nextPermit,
    checkNextInsurance,
    nextInsurance,
    gstRate
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addGeneralInfo + `/${id}`,
      data: {
        checkNextMain,
        nextMaintanance,
        checkNextOil,
        nextOil,
        checkNextPuc,
        nextPuc,
        checkNextRc,
        nextRc,
        checkNextFitness,
        nextFitness,
        checkNexPermit,
        nextPermit,
        checkNextInsurance,
        nextInsurance,
        gstRate,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * get general info
   * @returns 
   */
  static getGeneralInfo() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getGeneralInfo,
    });
    return Promise.resolve(result);
  }


  /**
   * add payment type
   * @param {*} paymentType 
   * @returns 
   */
  static addPaymentType(paymentType) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addPaymentType,
      data: { paymentType },
    });
    return Promise.resolve(result);
  }

  /**
   * edit payment type
   * @param {*} id 
   * @param {*} paymentType 
   * @returns 
   */
  static editPaymentType(id, paymentType) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editPaymentType + `/${id}`,
      data: { paymentType },
    });
    return Promise.resolve(result);
  }

  /**
   * get payment type
   * @param {*} page 
   * @returns 
   */
  static getPaymentType(page) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getPaymentType + `?page=${page}`,
    });
    return Promise.resolve(result);
  }


  /**
   * add vehicle expense
   * @param {*} expenseType 
   * @returns 
   */
  static addVehicleExpense(expenseType) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addVehicleExpense,
      data: { expenseType },
    });
    return Promise.resolve(result);
  }

  /**
   * edit payment type
   * @param {*} id 
   * @param {*} expenseType 
   * @returns 
   */
  static editVehicleExpense(id, expenseType) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editVehicleExpense + `/${id}`,
      data: { expenseType },
    });
    return Promise.resolve(result);
  }


  /**
   * get payment type
   * @param {*} page 
   * @returns 
   */
  static getVehicleExpense(page) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getVehicleExpense + `?page=${page}`,
    });
    return Promise.resolve(result);
  }


  /**
   * ADD vehicle charge
   * @param {*} id 
   * @param {*} chargeObject 
   * @returns 
   */
  static addCostCharge(id, chargeObject) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addCostCharge + `/${id}`,
      data: chargeObject,
    });
    return Promise.resolve(result);
  }

  /**
   * get vehicle charge
   * @param {*} id 
   * @returns 
   */
  static getCostCharge(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getCostCharge + `/${id}`,
    });
    return Promise.resolve(result);
  }


  /**
   * delete model record
   * @param {*} id 
   * @returns 
   */
  static deleteModel(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deleteModel + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * delete payment type
   * @param {*} id 
   * @returns 
   */
  static deletePaymentType(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deletePaymentType + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * delete veh expense type
   * @param {*} id 
   * @returns 
   */
  static deleteVehicleExpnseType(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deleteVehExpnseType + `/${id}`,
    });
    return Promise.resolve(result);
  }
}
export { SetUpService };
