import useVehiclePLHooks from "./useVehiclePLHooks";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { agreements_report } from "../../../../assets/images";
import { rep } from "../../../../assets/images";
import { Calender } from "../../../../assets/images";
import { Loading } from "../../../../assets/images";
import { commaValidation } from "../../../../utility";
import { withTranslation } from "react-i18next";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const VehiclePL = (props) => {
  const { t } = props;
  const {
    page,
    loader,
    listData,
    totalPages,
    fromDate,
    toDate,
    totalAmount,
    columns,
    listTable,
    loaderDownLoad,
    handlePageClick,
    handleStartDate,
    handleEndDate,
    downloadReport,
  } = useVehiclePLHooks(props);

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const agreementtab = () => {
    return (
      <div className="main_general flt">
        <div className="main_search_b2 main_search_b22 rental_mainpay flt">
          <div className="ex_customer ex_cust">
            {t("Vehicle P&L Revenue Report")}
          </div>
      <div className="filler"></div>
          <div className="main_search_dd main_search_dd1 rentalpayment">
            <div className=" company_name company_name2 ">
              <div className="form-group flt">
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  onChange={handleStartDate}
                  value={fromDate}
                  closeOnSelect={true}
                  timeFormat={false}
                  inputProps={{
                    placeholder: "Select From date",
                    disabled: false,
                    readOnly: true,
                    onKeyDown: (e) => e.preventDefault(),
                  }}
                />
                <div className="cal_img">
                  <img src={Calender} alt="calender" />
                </div>
              </div>
            </div>
            <div className=" company_name company_name2">
              <div className="form-group flt">
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  onChange={handleEndDate}
                  value={toDate}
                  closeOnSelect={true}
                  timeFormat={false}
                  inputProps={{
                    placeholder: "Select From date",
                    disabled: false,
                    readOnly: true,
                    onKeyDown: (e) => e.preventDefault(),
                  }}
                />
                <div className="cal_img">
                  <img src={Calender} alt="calender" />
                </div>
              </div>
            </div>

            {(fromDate !== "" || toDate !== "") && (
              <div className="fliter_button2">
                <button onClick={downloadReport}>
                  <i class="fa fa-download" aria-hidden="true"></i>
                  {t("Download")}
                </button>
              </div>
            )}
            <div className="total_amt">
              <div className="resp">
                {" "}
                <img src={rep} alt="rep" />
              </div>

              <ul>
                <li>
                  {t("Total Amount")}
                  <span className="colon_span">:</span>
                </li>
                <li>{`₹${commaValidation(totalAmount)}`}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const tableListDisplay = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_location flt"></div>
        <div className="main_boxShadowouter flt">
          {(toDate !== "" || fromDate !== "") &&
            (loader === "loading" || loaderDownLoad === "loading") && (
              <div className="table_loader ">
                <img src={Loading} alt="loader" />
              </div>
            )}
          {loader !== "loading" && (
            <>
              <div className="main_tablehead flt">
                <table className="main_table1 flt ">
                  {listData?.data?.data?.docs.length !== 0 ? (
                    <DataTable
                      columns={columns}
                      data={listTable || []}
                      customStyles={customStyles}
                    />
                  ) : (
                    <div className="no-data">{t("No Data Found")}</div>
                  )}
                </table>
                {listData?.data?.data?.docs.length !== 0 && (
                  <div>
                    <ReactPaginate
                      nextLabel="&rarr;"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      pageCount={totalPages}
                      previousLabel="&larr;"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      currentPage={page}
                      forcePage={page - 1}
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const agreementtiltle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img src={agreements_report} alt="agreements" />
          {t("REPORTS")}
        </div>
      </div>
    );
  };

  return (
    <div>
      {agreementtiltle()}
      {agreementtab()}
      {tableListDisplay()}
    </div>
  );
};
export default withTranslation()(VehiclePL);
