import Menu from "../..//../_common/menu/Menu";
import LeftSidebar from "../../../_common/sidebar/LeftSidebar";
import TopBar from "../../../_common/topbar/TopBar";
import "./Generals.css";
import useGeneralHook from "./useGeneralHook";
import { withTranslation } from "react-i18next";
import { Loading } from "../../../../assets/images";


function Generals(props) {
  const { t } = props;
  const {
    loader,
    inspection,
    checkInspection,
    gstRate,
    submit,
    checkNextOil,
    nextOil,
    checkNextPuc,
    nextPuc,
    checkNextRc,
    nextRc,
    checkNextFitness,
    nextFitness,
    checkNexPermit,
    nextPermit,
    checkNextInsurance,
    nextInsurance,
    handleGstRate,
    changeCheckInspectionHandler,
    handleInspectionDate,
    handleSubmit,
    changeCheckOilChangeDateHandler,
    changeCheckNextPucDateHandler,
    changeCheckNextRcDateHandler,
    changeCheckNextFitnessDateHandler,
    changeCheckPermitDateHandler,
    changeCheckInsuranceDateHandler,
    handleOilChangeDate,
    handleNextPucDate,
    handleNextRcDate,
    handleNextFitnessDate,
    handleNextPermitDate,
    handleNextInsuranceDate
  } = useGeneralHook();

  const generalDetails = () => {
    return (
      <>
        <div className="flt">
          <LeftSidebar />
          {loader === "loading" && (
            <div className="main_load ">
              <img src={Loading} alt="loader" />
            </div>
          )}
          {(loader === "isfull" ||
            loader === "isempty" ||
            loader === "notset") && (
              <div className="chat_right1 flt">
                <div className="company_hedding flt">
                  <div className="company_icon flt">{t("General Options")}</div>
                  <div className="edit_details flt">
                    <button onClick={handleSubmit} className="edit_button">
                      <i class="fas fa-save"></i>{t("Save")}
                    </button>
                  </div>
                </div>

                <div className="main_padding flt">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="main_generals flt">
                        <div className="gen-plnnrdiv flt">
                          <div className="general-main flt">
                            <div className="general-plannr flt">
                              {t("Vehicle Reminders in Planner")}
                            </div>
                          </div>
                          <div className="general-form flt">
                            <div className="grneral-inspec flt">
                              <div className="general-chckbox flt">
                                <div className="gen-check flt">
                                  <label className="inspect flt">
                                    {t("Next Maint. Date")}
                                  </label>

                                  <div className="check_show flt">
                                    <input
                                      type="checkbox"
                                      id="checkInspection"
                                      name="checkInspection"
                                      value={checkInspection}
                                      onClick={changeCheckInspectionHandler}
                                      checked={checkInspection}
                                    />

                                    <label className="show"> {t("Show")} </label>
                                  </div>
                                </div>
                                <div className="gen-advnce flt">
                                  <div className="gen-check flt">
                                    <div className="inspect-inpt flt">
                                      <input
                                        type="number"
                                        id="inspection"
                                        name="inspection"
                                        value={inspection}
                                        onChange={handleInspectionDate}
                                      />
                                    </div>
                                    <label className="advance">
                                      {t("Days in Advance")}
                                    </label>
                                  </div>
                                </div>
                              </div>


                              <div className="general-chckbox flt">
                                <div className="gen-check flt">
                                  <label className="inspect flt">
                                    {" "}
                                    {t("Next Oil Change Date")}{" "}
                                  </label>
                                  <div className="check_show flt">
                                    <input
                                      type="checkbox"
                                      id="checkNextOil"
                                      name="checkNextOil"
                                      value={checkNextOil}
                                      onClick={changeCheckOilChangeDateHandler}
                                      checked={checkNextOil}
                                    />
                                    <label className="show">{t("Show")}</label>
                                  </div>
                                </div>
                                <div className="gen-advnce flt">
                                  <div className="gen-check flt">
                                    <div className="inspect-inpt flt">
                                      <input
                                        type="number"
                                        id="nextOil"
                                        name="nextOil"
                                        value={nextOil}
                                        onChange={handleOilChangeDate}
                                      />
                                    </div>
                                    <label className="advance">
                                      {t("Days in Advance")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="general-chckbox flt">
                                <div className="gen-check flt">
                                  <label className="inspect flt">
                                    {" "}
                                    {t("Next PUC Expiry Date")}{" "}
                                  </label>
                                  <div className="check_show flt">
                                    <input
                                      type="checkbox"
                                      id="checkNextPuc"
                                      name="checkNextPuc"
                                      value={checkNextPuc}
                                      onClick={changeCheckNextPucDateHandler}
                                      checked={checkNextPuc}
                                    />
                                    <label className="show">{t("Show")}</label>
                                  </div>
                                </div>
                                <div className="gen-advnce flt">
                                  <div className="gen-check flt">
                                    <div className="inspect-inpt flt">
                                      <input
                                        type="number"
                                        id="nextPuc"
                                        name="nextPuc"
                                        value={nextPuc}
                                        onChange={handleNextPucDate}
                                      />
                                    </div>
                                    <label className="advance">
                                      {t("Days in Advance")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="general-chckbox flt">
                                <div className="gen-check flt">
                                  <label className="inspect flt">
                                    {" "}
                                    {t("Next RC Expiry Date")}{" "}
                                  </label>
                                  <div className="check_show flt">
                                    <input
                                      type="checkbox"
                                      id="checkNextRc"
                                      name="checkNextRc"
                                      value={checkNextRc}
                                      onClick={changeCheckNextRcDateHandler}
                                      checked={checkNextRc}
                                    />
                                    <label className="show">{t("Show")}</label>
                                  </div>
                                </div>
                                <div className="gen-advnce flt">
                                  <div className="gen-check flt">
                                    <div className="inspect-inpt flt">
                                      <input
                                        type="number"
                                        id="nextRc"
                                        name="nextRc"
                                        value={nextRc}
                                        onChange={handleNextRcDate}
                                      />
                                    </div>
                                    <label className="advance">
                                      {t("Days in Advance")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="general-chckbox flt">
                                <div className="gen-check flt">
                                  <label className="inspect flt">
                                    {" "}
                                    {t("Next Fitness Expiry Date")}{" "}
                                  </label>
                                  <div className="check_show flt">
                                    <input
                                      type="checkbox"
                                      id="checkNextFitness"
                                      name="checkNextFitness"
                                      value={checkNextFitness}
                                      onClick={changeCheckNextFitnessDateHandler}
                                      checked={checkNextFitness}
                                    />
                                    <label className="show">{t("Show")}</label>
                                  </div>
                                </div>
                                <div className="gen-advnce flt">
                                  <div className="gen-check flt">
                                    <div className="inspect-inpt flt">
                                      <input
                                        type="number"
                                        id="nextFitness"
                                        name="nextFitness"
                                        value={nextFitness}
                                        onChange={handleNextFitnessDate}
                                      />
                                    </div>
                                    <label className="advance">
                                      {t("Days in Advance")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="general-chckbox flt">
                                <div className="gen-check flt">
                                  <label className="inspect flt">
                                    {" "}
                                    {t("Next Permit Expiry Date")}{" "}
                                  </label>
                                  <div className="check_show flt">
                                    <input
                                      type="checkbox"
                                      id="checkNexPermit"
                                      name="checkNexPermit"
                                      value={checkNexPermit}
                                      onClick={changeCheckPermitDateHandler}
                                      checked={checkNexPermit}
                                    />
                                    <label className="show">{t("Show")}</label>
                                  </div>
                                </div>
                                <div className="gen-advnce flt">
                                  <div className="gen-check flt">
                                    <div className="inspect-inpt flt">
                                      <input
                                        type="number"
                                        id="nextPermit"
                                        name="nextPermit"
                                        value={nextPermit}
                                        onChange={handleNextPermitDate}
                                      />
                                    </div>
                                    <label className="advance">
                                      {t("Days in Advance")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="general-chckbox flt">
                                <div className="gen-check flt">
                                  <label className="inspect flt">
                                    {" "}
                                   {t("Next Insurance Expiry Date")} {" "}
                                  </label>
                                  <div className="check_show flt">
                                    <input
                                      type="checkbox"
                                      id="checkNextInsurance"
                                      name="checkNextInsurance"
                                      value={checkNextInsurance}
                                      onClick={changeCheckInsuranceDateHandler}
                                      checked={checkNextInsurance}
                                    />
                                    <label className="show">{t("Show")}</label>
                                  </div>
                                </div>
                                <div className="gen-advnce flt">
                                  <div className="gen-check flt">
                                    <div className="inspect-inpt flt">
                                      <input
                                        type="number"
                                        id="nextInsurance"
                                        name="nextInsurance"
                                        value={nextInsurance}
                                        onChange={handleNextInsuranceDate}
                                      />
                                    </div>
                                    <label className="advance">
                                      {t("Days in Advance")}
                                    </label>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                    <div className="col-sm-6">


                      <div className="gen-mainregnl flt">
                        <div className="gen-reginl flt">
                          <div className="gen-reginlsettng flt">{t("Tax Settings")}</div>
                        </div>

                        <div className="gen-mainDstnce flt">

                          <div className="Curency flt">
                            <div className="gen-Curency flt"> {t("Rate in(%)")} * </div>
                            <div className="coverstion-inpt flt">
                              <input
                                type="number"
                                id="gstRate"
                                name="gstRate"
                                placeholder="Enter GST Rate"
                                value={gstRate}
                                onChange={handleGstRate}
                                className="dstnce-Strngselct"
                              />
                            </div>
                            {submit && !gstRate && (
                              <div className="req_field1">{t("GST Rate is required")}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </>
    )
  }

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      {generalDetails()}
    </div>
  );
}
export default withTranslation() (Generals);
