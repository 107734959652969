import moment from "moment";
import { useEffect, useState } from "react";
import {
  DayToDayService,
  SetUpService,
  VehicleService,
} from "../../../services";

const useDashboardHook = () => {
  const [countResponse, setCountResponse] = useState("");
  const [vehicleList, setVehicleList] = useState([]);
  const [classId, setClassId] = useState("");
  const [vehicleClass, setVehicleClass] = useState("");
  const [locationArray, setLocationArray] = useState([]);
  const [page, setPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [listData, setListData] = useState([]);
  const [location, setLocation] = useState("");
  const [listPendingPayments, setlistPendingPayments] = useState([]);

  let r,
    sum = 0,
    temp;
  var n = 10;

  temp = n;
  while (n > 0) {
    r = n % 10;
    sum = sum * 10 + r;
    n = n / 10;
  }
  console.log(sum + " " + temp);

  /**
   * get dashboard count
   */
  useEffect(() => {
    dashboardCount(classId);
    vehicleClassListResponse();
    pendingPayments(classId);
    // eslint-disable-next-line
  }, [classId]);

  /**
   * get location
   */
  useEffect(() => {
    selectLocation();
  }, []);

  useEffect(() => {
    if (page !== undefined) {
      getQuickLocationList(page, fromDate, toDate, location);
    }
  }, [page, fromDate, toDate, location]);

  /**
   * get quick location
   * @param {*} page
   * @param {*} fromDate
   * @param {*} toDate
   * @param {*} location
   */
  const getQuickLocationList = (page, fromDate, toDate, location) => {
    VehicleService.getQuickLookup(
      page,
      moment(fromDate).toISOString(),
      moment(toDate).toISOString(),
      location
    )
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setListData(response);
          setTotalPages(response?.data?.data?.totalPages);
          setPage(response?.data?.data?.page);
        }
      })
      .catch((err) => console.log(err));
  };
  /**
   * call location
   */
  const selectLocation = () => {
    VehicleService.getLocationName()
      .then((response) => {
        setLocationArray(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  /**
   * Dashboard count
   */
  const dashboardCount = () => {
    DayToDayService.getDashboardCounts(classId)
      .then((response) => {
        setCountResponse(response);
        if (classId === "") {
          localStorage.setItem(
            "available",
            countResponse?.data?.data?.vehicleStatus.length === 0
              ? "0"
              : response?.data?.data?.vehicleStatus?.[0]?.available
          );
        } else if (classId !== "") {
          localStorage.setItem(
            "available",
            response?.data?.data?.vehicleStatusForChart.length === 0
              ? "0"
              : response?.data?.data?.vehicleStatusForChart?.[0]?.available
          );
        }
      })
      .catch((err) => console.log(err));
  };

  //get pending payments in dashboard
  const pendingPayments = () => {
    DayToDayService.getPendingPayments(classId)
      .then((response) => {
        setlistPendingPayments(response?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * get all vehicle list
   */
  const vehicleClassListResponse = () => {
    SetUpService.getVehicleClassWithoutPage()
      .then((response) => {
        setVehicleList(response?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * handle vehicle class
   * @param {*} e
   */
  const handleVehicleClass = (e) => {
    vehicleList.filter((val, index) =>
      val._id === e.target.value ? setVehicleClass(e.target.value) : ""
    );

    setClassId(e.target.value);
  };
  /**
   * handle change
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };
  const handleStartDate = (e) => {
    setFromDate(e);
  };
  const handleEndDate = (e) => {
    setToDate(e);
  };

  const changeLocationHandler = (e) => {
    setLocation(e.target.value);
  };
  return {
    countResponse,
    vehicleList,
    classId,
    locationArray,
    page,
    listData,
    toDate,
    fromDate,
    location,
    totalPages,
    vehicleClass,
    listPendingPayments,
    handleEndDate,
    handleStartDate,
    handleVehicleClass,
    changeLocationHandler,
    handlePageClick,
  };
};
export default useDashboardHook;
