import { useEffect, useState } from "react";
import { SetUpService } from "../../../../../services";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../../actions";

const useVehicleMakeTabHook = () => {
  const [vehicleList, setVehicleList] = useState([]);
  const [vehClass, setVehClass] = useState("");
  const [vehId, setVehId] = useState("");
  const [make, setMake] = useState("");
  const [page2, setPage2] = useState(1);
  const [listData2, setListData2] = useState([]);
  const [totalPages2, setTotalPages2] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [id, setId] = useState("");
  const [loader, setLoader] = useState("notset");
  const [listTable2, setListTable2] = useState([]);

  const dispatch = useDispatch();

  const columns = [
    {
      name: "Sr No.",
      selector: (row) => row.number,
    },
    {
      name: "Vehicle Class",
      selector: (row) => row.vehicleClass,
    },
    {
      name: "Make",
      selector: (row) => row.make,
    },
  ];
  let order = [];
  let obj = {};
  /**
   * get vehicle class
   */
  useEffect(() => {
    vehicleClassListResponse();
  }, []);

  /**
   * get make class
   */
  useEffect(() => {
    makeListResponse(page2);
  }, [page2]);
  /**
   * call table list
   */
  useEffect(() => {
    if (listData2?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData2?.data?.data?.docs]);

  /**
   * get table list data
   */
  const list = () => {
    listData2.data.data.docs.map((item, index) => {
      obj = {
        id: item._id,
        number:
          listData2.data.data.limit * (listData2.data.data.page - 1) +
          index +
          1,
        vehicleClass: item?.vehicleClass?.[0]?.vehicleClass,
        vehicleId: item?.vehicleClass?.[0]?._id,
        make: item.make,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable2(order);
  };

  /**
   * handle veh class
   * @param {*} e 
   */
  const handleVehClass = (e) => {
    setVehId(e.target.value);
  };
  const handleMake = (e) => {
    setMake(e.target.value);
  };
  const handlePageClick2 = (e) => {
    setPage2(e.selected + 1);
  };

  /**
   * get row
   * @param {*} row 
   */
  const handleEditMake = (row) => {
    setVehId(row?.vehicleId);
    setVehClass(row?.vehicleClass);
    setMake(row?.make);
    setId(row?.id);
  };

  /**
   * get all vehicle list
   */
  const vehicleClassListResponse = () => {
    SetUpService.getVehicleClassWithoutPage()
      .then((response) => {
        setVehicleList(response?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * get make list
   * @param {*} page2 
   */
  const makeListResponse = (page2) => {
    setLoader("loading");
    SetUpService.getMake(page2)
      .then((response) => {
        setLoader("isfull");
        setListData2(response);
        setTotalPages2(response?.data?.data?.totalPages);
        setPage2(response?.data?.data?.page);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * add api call
   * @param {*} e 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (vehId && make) {
      setLoader("loading");
      SetUpService.addMake(vehId, make)
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            makeListResponse(page2);
            setVehClass("");
            setVehId("");
            setMake("");
            setLoader("isfull");
            setSubmit(false);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  /**
   * edit api call
   * @param {*} e 
   */
  const handleEditSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (id && vehId && make) {
      setLoader("loading");
      SetUpService.editMake(id, vehId, make)
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            makeListResponse(page2);
            setVehClass("");
            setVehId("");
            setMake("");
            setId("");
            setLoader("isfull");
            setSubmit(false);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };
  return {
    loader,
    vehicleList,
    make,
    totalPages2,
    vehId,
    submit,
    id,
    columns,
    listTable2,
    page2,
    vehClass,
    handleMake,
    handleVehClass,
    handleSubmit,
    handlePageClick2,
    handleEditMake,
    handleEditSubmit,
  };
};
export default useVehicleMakeTabHook;
