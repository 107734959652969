import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { userLogout } from "../../../../actions";
import { alertActions } from "../../../../actions/alert.actions";
import { SetUpService, VehicleService } from "../../../../services";

const useUserHooks = () => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [defLocation, setDefLocation] = useState("");
  const [userLevel, setUserLevel] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [listData, setListData] = useState([]);
  const [locationArray, setLocationArray] = useState([]);
  const [re_password, setRe_password] = useState("");
  const [id, setId] = useState("");
  const [roleId, setRoleId] = useState();
  const [locArrayId, setLocArrayId] = useState("");
  const [agreementsUA, setAgreementUA] = useState([]);
  const [vehiclesUA, setVehiclesUA] = useState([]);
  const [locationsUA, setLocationsUA] = useState([]);
  const [dashboardUA, setDashboardUA] = useState([]);
  const [topRibbonUA, setTopRibbonUA] = useState([]);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  // const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);

  const columns = [
    {
      name: "User ID ",
      selector: (row) => row.userid,
      width: "150px",
    },
    {
      name: "User Name",
      selector: (row) => row.username,
      width: "150px",
    },
    {
      name: "Location",
      selector: (row) => row.location,
      width: "150px",
    },
    {
      name: "Level",
      selector: (row) => row.level,
      width: "180px",
    },
  ];

  let order = [];
  let obj = {};
  /**
   * get location name
   */
  useEffect(() => {
    VehicleService.getLocationName()
      .then((response) => {
        setLocationArray(response?.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   * call table list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs.length]);
  /**
   * table list
   */
  const list = () => {
    listData.data.data.docs.map((item, index) => {
      obj = {
        id: item._id,
        userid: item.userId,
        username: item.userName,
        level: item.userLevel,
        location: item?.defLocation?.[0]?.locationName,
        password: item.originalPassword,
        roleId: item.roleId,
        locArrayId: item?.defLocation?.[0]?._id,
        agreementsUA: item?.agreementsUA ? item?.agreementsUA : [],
        vehiclesUA: item?.vehiclesUA ? item?.vehiclesUA : [],
        locationsUA: item?.locationsUA ? item?.locationsUA : [],
        dashboardUA: item?.dashboardUA ? item?.dashboardUA : [],
        topRibbonUA: item?.topRibbonUA ? item?.topRibbonUA : [],
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };
  /**
   * handle change
   * @param {*} e
   */
  function handleUserIdChange(e) {
    setUserId(e.target.value);
  }

  function handleUserNameChange(e) {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setUserName(e.target.value);
    } else if (!e.target.value) {
      setUserName("");
    }
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  function handleDefLocationChange(e) {
    locationArray.filter((val, index) =>
      val.locationName === e.target.value ? setLocArrayId(val._id) : ""
    );

    setDefLocation(e.target.value);
  }

  function handleUserLevel(e) {
    setUserLevel(e.target.value);
    if (e.target.value === "Administrator") {
      setRoleId(99);
      agreementsUA.push("Booking", "startRent", "endRent", "exchange");
      vehiclesUA.push("view", "update");
      locationsUA.push("accessToLocations");
      dashboardUA.push(
        "Bookings",
        "draftBooking",
        "returns",
        "onRent",
        "maintAlerts",
        "oilChangeAlert",
        "vehiclesInFleet",
        "availableInFleet",
        "expirationDetails",
        "dayToDayTask",
        "vehicleStatusChart",
        "quickLookUp",
        "pendingPayment",
        "maintenance"
      );
      topRibbonUA.push(
        "newAgreement",
        "dayToDayTask",
        "vehicles",
        "invoices",
        "customers",
        "investors",
        "reports",
        "setUp"
      );
    } else {
      setRoleId(2);
      setAgreementUA([]);
      setVehiclesUA([]);
      setLocationsUA([]);
      setDashboardUA([]);
      setTopRibbonUA([]);
    }
  }

  function handleRePasswordChange(e) {
    setRe_password(e.target.value);
  }

  /**
   * handle clear
   * @param {*} e
   */
  function handleClearField(e) {
    e.preventDefault();
    setRe_password("");
    setUserId("");
    setUserName("");
    setPassword("");
    setSubmitted(false);
    setDefLocation("");
    setUserLevel("");
    setId("");
    setRoleId();
    setAgreementUA([]);
    setVehiclesUA([]);
    setLocationsUA([]);
    setDashboardUA([]);
    setTopRibbonUA([]);
  }
  useEffect(() => {
    listResponse(page);
  }, [submitted, page]);

  /**
   * list api call
   * @param {*} page
   */
  function listResponse(page) {
    // setLoader("loading");
    SetUpService.getAllUsers(page)
      .then((response) => {
        setListData(response);

        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        // setLoader("isfull");
      })
      .catch((err) => console.log(err));
  }

  // function handlePageClick =(e)=> {
  //   setPage(e.selected + 1);
  // }

  const handlePageClick = (e) => {
    console.log("22", e);
    setPage(e.selected + 1);
  };
  /**
   * handle agreement
   * @param {*} e
   * @param {*} arr
   */
  const handleAgreement = (e, arr = []) => {
    var updatedList = [...arr];
    if (e.target.checked) {
      updatedList = [...arr, e.target.value];
    } else {
      updatedList.splice(arr.indexOf(e.target.value), 1);
    }

    setAgreementUA(updatedList);
  };

  /**
   * handle vehicle
   * @param {*} e
   * @param {*} arr
   */
  const handleVehicle = (e, arr = []) => {
    var updatedList = [...arr];
    if (e.target.checked) {
      updatedList = [...arr, e.target.value];
    } else {
      updatedList.splice(arr.indexOf(e.target.value), 1);
    }

    setVehiclesUA(updatedList);
  };

  /**
   * handle top ribbon
   * @param {*} e
   * @param {*} arr
   */
  const handleTopRibbon = (e, arr = []) => {
    var updatedList = [...arr];
    if (e.target.checked) {
      updatedList = [...arr, e.target.value];
    } else {
      updatedList.splice(arr.indexOf(e.target.value), 1);
    }

    setTopRibbonUA(updatedList);
  };

  /**
   * handle dashboard
   * @param {*} e
   * @param {*} arr
   */
  const handleDashboard = (e, arr = []) => {
    var updatedList = [...arr];
    if (e.target.checked) {
      updatedList = [...arr, e.target.value];
    } else {
      updatedList.splice(arr.indexOf(e.target.value), 1);
    }

    setDashboardUA(updatedList);
  };

  /**
   * handle location
   * @param {*} e
   * @param {*} arr
   */
  const handleLocation = (e, arr = []) => {
    var updatedList = [...arr];
    if (e.target.checked) {
      updatedList = [...arr, e.target.value];
    } else {
      updatedList.splice(arr.indexOf(e.target.value), 1);
    }

    setLocationsUA(updatedList);
  };

  /**
   * fetch each row of userAcess
   * @param {*} row
   */
  function handleEditUser(row) {
    setUserId(row?.userid);
    setUserName(row?.username);
    setPassword(row?.password);
    setDefLocation(row?.location);
    setUserLevel(row?.level);
    setId(row?.id);
    setRe_password(row?.password);
    setRoleId(row?.roleId);
    setLocArrayId(row?.locArrayId);
    setAgreementUA(row?.agreementsUA);
    setVehiclesUA(row?.vehiclesUA);
    setLocationsUA(row?.locationsUA);
    setDashboardUA(row?.dashboardUA);
    setTopRibbonUA(row?.topRibbonUA);
  }

  /**
   * add user api call
   * @param {*} e
   */
  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    if (
      userId &&
      userName &&
      password &&
      locArrayId &&
      userLevel &&
      re_password &&
      roleId &&
      defLocation
    ) {
      if (password !== re_password) {
        dispatch(alertActions.error("Both password must match"));
      } else {
        // setLoader("loading");
        SetUpService.addUser(
          userId,
          userName,
          password,
          locArrayId,
          userLevel,
          roleId,
          agreementsUA,
          vehiclesUA,
          locationsUA,
          dashboardUA,
          topRibbonUA
        )
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setUserData(response);
              setSubmitted(false);
              setRe_password("");
              setUserId("");
              setUserName("");
              setPassword("");
              // setLoader("isfull");
              setSubmitted(false);
              setDefLocation("");
              setUserLevel("");
              setRoleId();
              setLocArrayId("");
              setAgreementUA([]);
              setVehiclesUA([]);
              setLocationsUA([]);
              setDashboardUA([]);
              setTopRibbonUA([]);

              dispatch(alertActions.success(response?.data?.status?.message));
            } else {
              // setLoader("isempty");
              // console.log(loader);

              dispatch(alertActions.error(response?.data?.status?.message));
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  }
  /**
   * edit user api call
   * @param {*} e
   */
  function handleEditSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    if (
      id &&
      userId &&
      userName &&
      password &&
      locArrayId &&
      userLevel &&
      re_password &&
      roleId &&
      defLocation
    ) {
      if (password !== re_password) {
        dispatch(alertActions.error("Both password must match"));
      } else {
        // setLoader("loading");
        SetUpService.editUser(
          id,
          userId,
          userName,
          password,
          locArrayId,
          userLevel,
          roleId,
          agreementsUA,
          vehiclesUA,
          locationsUA,
          dashboardUA,
          topRibbonUA
        )
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setRe_password("");
              setUserId("");
              setUserName("");
              setPassword("");
              // setLoader("isfull");
              setSubmitted(false);
              setDefLocation("");
              setUserLevel("");
              setId("");
              setRoleId();
              setDefLocation("");
              setAgreementUA([]);
              setVehiclesUA([]);
              setLocationsUA([]);
              setDashboardUA([]);
              setTopRibbonUA([]);

              dispatch(alertActions.success(response?.data?.status?.message));
              userLogoutTask();
            } else {
              // setLoader("isempty");
              dispatch(alertActions.error(response?.data?.status?.message));
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  }
  /**
   * user logout
   */
  const userLogoutTask = () => {
    dispatch(alertActions.error("Please login"));
    setTimeout(() => {
      dispatch(userLogout());
      navigate("/login");
    }, 100);
  };

  /**
   * delete user api call
   * @param {*} e
   */
  function handleDeleteSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    // setLoader("loading");
    SetUpService.deleteUser(id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setSubmitted(false);
          setRe_password("");
          setUserId("");
          setUserName("");
          setPassword("");
          setSubmitted(false);
          setDefLocation("");
          setUserLevel("");
          setRoleId();
          setId("");
          setAgreementUA([]);
          setDashboardUA([]);
          setLocationsUA([]);
          setVehiclesUA([]);
          setTopRibbonUA([]);
          // setLoader("isfull");
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }
  return {
    userLevel,
    userData,
    page,
    totalPages,
    submitted,
    locationArray,
    userId,
    password,
    userName,
    re_password,
    defLocation,
    id,
    agreementsUA,
    vehiclesUA,
    locationsUA,
    dashboardUA,
    topRibbonUA,
    columns,
    listTable,
    handleUserIdChange,
    handleUserNameChange,
    handlePasswordChange,
    handleUserLevel,
    handleDefLocationChange,
    handleSubmit,
    listResponse,
    setPage,
    handlePageClick,
    handleRePasswordChange,
    handleEditUser,
    handleTopRibbon,
    handleDashboard,
    handleLocation,
    handleVehicle,
    handleAgreement,
    handleClearField,
    handleEditSubmit,
    handleDeleteSubmit,
  };
};
export default useUserHooks;
