import { fuel } from "../../../../../../../assets/images";
import { odo } from "../../../../../../../assets/images";
import useEndCheckoutHook from "./EndCheckOutHook";
const CheckOut = ({ displayData, tab, bookid }) => {
  const {
    KMLimit,
    odometer,
    fuellevel,
    helmetqty,
    submit,
    Changehandler,
    checkOutStore,
    navigateToRentalDetails,
  } = useEndCheckoutHook(displayData, bookid);

  const PersonalDetails = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          <div className="main_company flt">
            <div className="main_row flt">
              <div className="row">
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">KM Limit</label>
                      <input
                        type="number"
                        placeholder="KM Limit"
                        name="KMLimit"
                        id="KMLimit"
                        value={KMLimit}
                        readOnly
                      />

                      {submit && !KMLimit && (
                        <div className="req_field">KM Limit is required</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">ODO Meter</label>
                      <div className="make_img12 flt">
                        <img src={odo} alt="odometer" />
                        <input
                          type="number"
                          placeholder="ODO Meter"
                          name="odometer"
                          id="odometer"
                          value={odometer}
                          readOnly
                        />
                      </div>
                      {submit && !odometer && (
                        <div className="req_field">ODO Meter is required</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Fuel Level</label>
                      <div className="make_img12 flt">
                        <img src={fuel} alt="fuel" />
                        <input
                          type="text"
                          placeholder="Fuel Level"
                          name="fuellevel"
                          id="fuellevel"
                          value={fuellevel}
                          readOnly
                        />
                      </div>
                      {submit && !fuellevel && (
                        <div className="req_field">Fuel Level is required</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="company_name flt">
                    <div className="form-group flt">
                      <label className="labelName">Helmet Numbers</label>
                      <input
                        type="text"
                        placeholder="Helmet Numbers"
                        name="helmetqty"
                        id="helmetqty"
                        value={helmetqty}
                        onChange={Changehandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_ownbutton flt">
                <div className="own_button flt">
                  <button
                    className="backbutton"
                    onClick={navigateToRentalDetails}
                  >
                    Back
                  </button>
                  <button className="submitbutton" onClick={checkOutStore}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <>{PersonalDetails()}</>;
};
export default CheckOut;
