import * as axios from "../network/Network";

const config = require("../network/api.json");

class EndrentService {

  /**
   * Get rent list
   * @param {*} page 
   * @param {*} searchby 
   * @param {*} value 
   * @returns 
   */
  static getrentlist(page, searchby, value) {
    const result = axios.appRequest({
      method: axios.GET,
      url:
        config.user.getendDetails +
        `?page=${page}&searchby=${searchby}&value=${value}`,
    });
    return Promise.resolve(result);
  }

  /**
   * Get all rental data
   * @param {*} id 
   * @returns 
   */
  static getAllRentalData(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.rental_details + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * Get check in
   * @param {*} id 
   * @returns 
   */
  static getCheckin(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getCheckin + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * Get checkout details
   * @param {*} id 
   * @returns 
   */
  static getCheckoutDetails(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getCheckOut + `/${id}`,
    });
    return Promise.resolve(result);
  }


  /**
   * Checkout store
   * @param {*} Odo_meter 
   * @param {*} Fuel_level 
   * @param {*} id 
   * @returns 
   */
  static store_checkout(Odo_meter, Fuel_level, id) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.checkinstore + `/${id}`,
      data: { Odo_meter, Fuel_level },
    });
    return Promise.resolve(result);
  }

  /**
   * Store check in vehicle
   * @param {*} bid 
   * @param {*} payment_status 
   * @param {*} vehicle_due_for_service 
   * @param {*} vehicle_maintanance 
   * @param {*} faults_in_vehicle 
   * @returns 
   */
  static store_checkinVehicle(
    bid,
    payment_status,
    vehicle_due_for_service,
    vehicle_maintanance,
    faults_in_vehicle
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.checkinsubmit + `/${bid}`,
      data: {
        payment_status,
        vehicle_due_for_service,
        vehicle_maintanance,
        faults_in_vehicle,
      },
    });
    return Promise.resolve(result);
  }


  /**
   * get payment
   * @param {*} id 
   * @returns 
   */
  static getPayment(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.paymentdetail + `/${id}`,
    });
    return Promise.resolve(result);
  }
}
export { EndrentService };
