import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../../actions";
import { VehicleService } from "../../../../../services";
import moment from "moment";

const useDocuments = (location, tab, tabId) => {
  const [id, setId] = useState(
    location?.state?.row?.id ? location?.state?.row?.id : tabId
  );
  const [checkRes, setCheckRes] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [insuranceCompanyName, setInsuranceCompanyName] = useState("");
  const [insuranceNo, setInsuranceNo] = useState("");
  const [insuranceYear, setInsuranceYear] = useState("");
  const [insuranceType, setInsuranceType] = useState("");
  const [insuranceAmount, setInsuranceAmount] = useState("");
  const [insuranceStartDate, setInsuranceStartDate] = useState("");
  const [insuranceExpireDate, setInsuranceExpireDate] = useState("");
  const [pucExpiryDate, setPucExpiryDate] = useState("");
  const [fitnessExpiryDate, setFitnessExpiryDate] = useState("");
  const [RCExpiryDate, setRCExpiryDate] = useState("");
  const [permitExpiryDate, setPermitExpiryDate] = useState("");

  const [fileName1, setFileName1] = useState("");
  const [selectedFile1, setSelectedFile1] = useState("");
  const [pucExpiryImage, setPucExpiryImage] = useState("");
  const [fileName4, setFileName4] = useState("");
  const [selectedFile4, setSelectedFile4] = useState("");
  const [fitnessExpImage, setFitnessExpImage] = useState("");
  const [fileName5, setFileName5] = useState("");
  const [selectedFile5, setSelectedFile5] = useState("");
  const [rcExpImage, setRcExpImage] = useState("");
  const [fileName7, setFileName7] = useState("");
  const [selectedFile7, setSelectedFile7] = useState("");
  const [permitExpImage, setPermitExpImage] = useState("");

  const [showUpload, setShowUpload] = useState(false);
  const [imageDisplay, setImageDisplay] = useState("");
  const [type1, setType1] = useState("");
  const [type4, setType4] = useState("");
  const [type5, setType5] = useState("");
  const [type7, setType7] = useState("");
  const [typeDisplay, setTypeDisplay] = useState("");
  const [loader, setLoader] = useState("notset");
  const [fileName8, setFileName8] = useState("");
  const [selectedFile8, setSelectedFile8] = useState("");
  const [insuranceExpImage, setInsuranceExpImage] = useState("");
  const [type8, setType8] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedFile1) {
      setPucExpiryImage(undefined);
      return;
    }
    const objectUrl1 = URL.createObjectURL(selectedFile1);
    setPucExpiryImage(objectUrl1);
    return () => URL.revokeObjectURL(objectUrl1);
  }, [selectedFile1]);

  useEffect(() => {
    if (!selectedFile4) {
      setFitnessExpImage(undefined);
      return;
    }
    const objectUrl4 = URL.createObjectURL(selectedFile4);
    setFitnessExpImage(objectUrl4);
    return () => URL.revokeObjectURL(objectUrl4);
  }, [selectedFile4]);

  useEffect(() => {
    if (!selectedFile5) {
      setRcExpImage(undefined);
      return;
    }
    const objectUrl5 = URL.createObjectURL(selectedFile5);
    setRcExpImage(objectUrl5);
    return () => URL.revokeObjectURL(objectUrl5);
  }, [selectedFile5]);

  useEffect(() => {
    if (!selectedFile7) {
      setPermitExpImage(undefined);
      return;
    }
    const objectUrl7 = URL.createObjectURL(selectedFile7);
    setPermitExpImage(objectUrl7);
    return () => URL.revokeObjectURL(objectUrl7);
  }, [selectedFile7]);

  useEffect(() => {
    if (!selectedFile8) {
      setInsuranceExpImage(undefined);
      return;
    }
    const objectUrl8 = URL.createObjectURL(selectedFile8);
    setInsuranceExpImage(objectUrl8);
    return () => URL.revokeObjectURL(objectUrl8);
  }, [selectedFile8]);

  /**
   * call get gen info
   */
  useEffect(() => {
    if (tabId !== "undefined" && tabId !== undefined) {
      getSingleDocInfo();
    }

    // eslint-disable-next-line
  }, [tab === 2, tabId !== "", tabId !== "undefined"]);

  /**
   * handle show vehicle
   */
  const openUpload = () => {
    setShowUpload(true);
  };
  const closeUpload = () => {
    setShowUpload(false);
  };

  /**
   * handle change image
   * @param {*} e
   * @returns
   */
  function onSelectFile1(e) {
    if (e?.target?.files[0]?.name?.includes(".pdf")) {
      setType1("pdf");
    } else {
      setType1("image");
    }
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile1(undefined);
      return;
    }
    setFileName1(e.target.files[0].name);
    setSelectedFile1(e.target.files[0]);
  }

  function onSelectFile4(e) {
    if (e?.target?.files[0]?.name?.includes(".pdf")) {
      setType4("pdf");
    } else {
      setType4("image");
    }
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile4(undefined);
      return;
    }
    setFileName4(e.target.files[0].name);
    setSelectedFile4(e.target.files[0]);
  }
  function onSelectFile5(e) {
    if (e?.target?.files[0]?.name?.includes(".pdf")) {
      setType5("pdf");
    } else {
      setType5("image");
    }
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile5(undefined);
      return;
    }
    setFileName5(e.target.files[0].name);
    setSelectedFile5(e.target.files[0]);
  }

  function onSelectFile7(e) {
    if (e?.target?.files[0]?.name?.includes(".pdf")) {
      setType7("pdf");
    } else {
      setType7("image");
    }
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile7(undefined);
      return;
    }
    setFileName7(e.target.files[0].name);
    setSelectedFile7(e.target.files[0]);
  }

  function onSelectFile8(e) {
    if (e?.target?.files[0]?.name?.includes(".pdf")) {
      setType8("pdf");
    } else {
      setType8("image");
    }
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile8(undefined);
      return;
    }
    setFileName8(e.target.files[0].name);
    setSelectedFile8(e.target.files[0]);
  }
  const handleInsuranceComapanyName = (e) => {
    setInsuranceCompanyName(e.target.value);
  };

  const handleInsuranceNo = (e) => {
    setInsuranceNo(e.target.value);
  };

  const handleInsuranceYear = (e) => {
    setInsuranceYear(e.target.value);
  };
  const handleInsuranceType = (e) => {
    setInsuranceType(e.target.value);
  };

  const handleInsuranceAmount = (e) => {
    setInsuranceAmount(e.target.value);
  };

  const handleInsuranceStartDate = (e) => {
    setInsuranceStartDate(moment(e).format("DD/MM/YYYY"));
  };

  const handleInsuranceExpireDate = (e) => {
    setInsuranceExpireDate(moment(e).format("DD/MM/YYYY"));
  };

  const handlePucExpiryDate = (e) => {
    setPucExpiryDate(moment(e).format("DD/MM/YYYY"));
  };

  const handleFitnessExpiryExpiryDate = (e) => {
    setFitnessExpiryDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleRCExpireDateExpiryDate = (e) => {
    setRCExpiryDate(moment(e).format("DD/MM/YYYY"));
  };

  const handlePermitExpiryDate = (e) => {
    setPermitExpiryDate(moment(e).format("DD/MM/YYYY"));
  };

  /**
   * show file
   * @param {*} file
   */
  function showFile(file) {
    if (file === "pucExpiryImage") {
      setImageDisplay(pucExpiryImage);
      if (selectedFile1 !== "") {
        setTypeDisplay(type1);
      } else {
        if (pucExpiryImage.includes(".pdf")) {
          setTypeDisplay("pdf");
        } else {
          setTypeDisplay("image");
        }
      }
    } else if (file === "fitnessExpiryImage") {
      setImageDisplay(fitnessExpImage);
      if (selectedFile4 !== "") {
        setTypeDisplay(type4);
      } else {
        if (fitnessExpImage.includes(".pdf")) {
          setTypeDisplay("pdf");
        } else {
          setTypeDisplay("image");
        }
      }
    } else if (file === "rcExpiryImage") {
      setImageDisplay(rcExpImage);
      if (selectedFile5 !== "") {
        setTypeDisplay(type5);
      } else {
        if (rcExpImage.includes(".pdf")) {
          setTypeDisplay("pdf");
        } else {
          setTypeDisplay("image");
        }
      }
    } else if (file === "permitExpImage") {
      setImageDisplay(permitExpImage);
      if (selectedFile7 !== "") {
        setTypeDisplay(type7);
      } else {
        if (permitExpImage.includes(".pdf")) {
          setTypeDisplay("pdf");
        } else {
          setTypeDisplay("image");
        }
      }
    } else if (file === "insuranceExpImage") {
      setImageDisplay(insuranceExpImage);
      if (selectedFile8 !== "") {
        setTypeDisplay(type8);
      } else {
        if (insuranceExpImage.includes(".pdf")) {
          setTypeDisplay("pdf");
        } else {
          setTypeDisplay("image");
        }
      }
    }
  }

  /**
   * handle delete
   */
  function handleDelete1() {
    setSelectedFile1("");
    setImageDisplay("");
    setPucExpiryImage("");
    setFileName1("");
  }

  function handleDelete4() {
    setSelectedFile4("");
    setImageDisplay("");
    setFitnessExpImage("");
    setFileName4("");
  }
  function handleDelete5() {
    setSelectedFile5("");
    setRcExpImage("");
    setImageDisplay("");
    setFileName5("");
  }

  function handleDelete7() {
    setSelectedFile7("");
    setPermitExpImage("");
    setImageDisplay("");
    setFileName7("");
  }

  /**
   * get doc info
   */
  const getSingleDocInfo = () => {
    VehicleService.getSingleVehicleDoc(tabId)
      .then((response) => {
        setCheckRes(true);

        setInsuranceCompanyName(
          response?.data?.data?.insuranceDetails?.insuranceCompanyName
            ? response?.data?.data?.insuranceDetails?.insuranceCompanyName
            : ""
        );
        setInsuranceNo(
          response?.data?.data?.insuranceDetails?.insuranceNo
            ? response?.data?.data?.insuranceDetails?.insuranceNo
            : ""
        );
        setInsuranceYear(
          response ? response?.data?.data?.insuranceDetails?.insuranceYear : ""
        );
        setInsuranceType(
          response?.data?.data?.insuranceDetails?.insuranceType
            ? response?.data?.data?.insuranceDetails?.insuranceType
            : ""
        );
        setInsuranceAmount(
          response?.data?.data?.insuranceDetails?.insuranceAmount
            ? response?.data?.data?.insuranceDetails?.insuranceAmount
            : ""
        );
        setInsuranceStartDate(
          response?.data?.data?.insuranceDetails?.insuranceStartDate
            ? moment(
                response?.data?.data?.insuranceDetails?.insuranceStartDate
              ).format("DD/MM/YYYY")
            : ""
        );
        setInsuranceExpireDate(
          response?.data?.data?.insuranceDetails?.insuranceExpireDate
            ? moment(
                response?.data?.data?.insuranceDetails?.insuranceExpireDate
              ).format("DD/MM/YYYY")
            : ""
        );

        setPucExpiryDate(
          response?.data?.data?.documentDetails?.pucExpiryDate
            ? moment(
                response?.data?.data?.documentDetails?.pucExpiryDate
              ).format("DD/MM/YYYY")
            : ""
        );

        setFitnessExpiryDate(
          response?.data?.data?.documentDetails?.fitnessExpiryDate
            ? moment(
                response?.data?.data?.documentDetails?.fitnessExpiryDate
              ).format("DD/MM/YYYY")
            : ""
        );
        setRCExpiryDate(
          response?.data?.data?.documentDetails?.RCExpiryDate
            ? moment(
                response?.data?.data?.documentDetails?.RCExpiryDate
              ).format("DD/MM/YYYY")
            : ""
        );

        setPermitExpiryDate(
          response?.data?.data?.documentDetails?.permitExpiryDate
            ? moment(
                response?.data?.data?.documentDetails?.permitExpiryDate
              ).format("DD/MM/YYYY")
            : ""
        );
        setPucExpiryImage(response?.data?.data?.documentDetails?.pucExpImage);

        setFitnessExpImage(
          response?.data?.data?.documentDetails?.fitnessExpImage
        );
        setRcExpImage(response?.data?.data?.documentDetails?.rcExpImage);

        setPermitExpImage(
          response?.data?.data?.documentDetails?.permitExpImage
        );
        setInsuranceExpImage(
          response?.data?.data?.insuranceDetails?.insuranceExpImage
        );
      })
      .catch((err) => console.log(err));
  };
  /**
   * add doc
   * @param {*} e
   * @param {*} tabId
   */
  const handleDocument = (e, tabId) => {
    e.preventDefault();

    setSubmit(true);
    let select1;
    let select4;
    let select5;
    let select7;
    let select8;
    if (selectedFile1 === "") {
      select1 = pucExpiryImage;
    } else {
      select1 = selectedFile1;
    }

    if (selectedFile4 === "") {
      select4 = fitnessExpImage;
    } else {
      select4 = selectedFile4;
    }
    if (selectedFile5 === "") {
      select5 = rcExpImage;
    } else {
      select5 = selectedFile5;
    }

    if (selectedFile7 === "") {
      select7 = permitExpImage;
    } else {
      select7 = selectedFile7;
    }
    if (selectedFile8 === "") {
      select8 = insuranceExpImage;
    } else {
      select8 = selectedFile8;
    }
    if (tabId) {
      setLoader("loading");
      VehicleService.addvehicleDocument(
        tabId,
        insuranceCompanyName,
        insuranceNo,
        insuranceYear,
        insuranceType,
        insuranceAmount,
        insuranceStartDate,
        insuranceExpireDate,
        pucExpiryDate,
        fitnessExpiryDate,
        RCExpiryDate,
        permitExpiryDate,
        select1,
        select4,
        select5,
        select7,
        select8
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setSubmit(false);
            tab(3);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * handle edit documnet
   * @param {*} e
   * @param {*} tabId
   */
  const handleEditDocument = (e, tabId) => {
    e.preventDefault();
    setSubmit(true);
    let select1;
    let select4;
    let select5;
    let select7;
    let select8;
    if (selectedFile1 === "") {
      select1 = pucExpiryImage;
    } else {
      select1 = selectedFile1;
    }

    if (selectedFile4 === "") {
      select4 = fitnessExpImage;
    } else {
      select4 = selectedFile4;
    }
    if (selectedFile5 === "") {
      select5 = rcExpImage;
    } else {
      select5 = selectedFile5;
    }

    if (selectedFile7 === "") {
      select7 = permitExpImage;
    } else {
      select7 = selectedFile7;
    }
    if (selectedFile8 === "") {
      select8 = insuranceExpImage;
    } else {
      select8 = selectedFile8;
    }
    if (tabId) {
      setLoader("loading");

      VehicleService.editvehicleDocument(
        tabId,
        insuranceCompanyName,
        insuranceNo,
        insuranceYear,
        insuranceType,
        insuranceAmount,
        insuranceStartDate,
        insuranceExpireDate,
        pucExpiryDate,
        fitnessExpiryDate,
        RCExpiryDate,
        permitExpiryDate,
        select1,
        select4,
        select5,
        select7,
        select8
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setSubmit(false);
            tab(3);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return {
    loader,
    insuranceCompanyName,
    insuranceNo,
    insuranceYear,
    insuranceType,
    insuranceAmount,
    insuranceStartDate,
    insuranceExpireDate,
    pucExpiryDate,
    fitnessExpiryDate,
    RCExpiryDate,
    permitExpiryDate,
    submit,
    checkRes,
    id,

    handleInsuranceComapanyName,
    handleInsuranceNo,
    handleInsuranceType,
    handleInsuranceAmount,
    handleInsuranceStartDate,
    handleInsuranceExpireDate,
    handleInsuranceYear,
    handlePucExpiryDate,
    handleFitnessExpiryExpiryDate,
    handleRCExpireDateExpiryDate,
    handlePermitExpiryDate,
    handleDocument,
    getSingleDocInfo,
    handleEditDocument,

    fileName1,
    fileName4,
    fileName5,
    fileName7,
    fileName8,
    showUpload,
    imageDisplay,
    pucExpiryImage,
    fitnessExpImage,
    rcExpImage,
    permitExpImage,
    insuranceExpImage,
    selectedFile1,
    selectedFile4,
    selectedFile5,
    selectedFile7,
    selectedFile8,
    typeDisplay,
    setId,
    openUpload,
    closeUpload,
    onSelectFile1,
    onSelectFile4,
    onSelectFile5,
    onSelectFile7,
    onSelectFile8,
    showFile,
    handleDelete1,
    handleDelete4,
    handleDelete5,
    handleDelete7,
  };
};

export default useDocuments;
