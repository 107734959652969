import CheckOutHook from "./CheckOutHook";
import { fuel } from "../../../../../../../../assets/images";
import { odo } from "../../../../../../../../assets/images";
import { Loading } from "../../../../../../../../assets/images";

const CheckOut = ({ displayData, tab, bookid }) => {
  const {
    loader,
    KMLimit,
    odometer,
    fuellevel,
    submit,
    helmetqty,
    Changehandler,
    checkOutStore,
  } = CheckOutHook(displayData, bookid);

  const PersonalDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" ||
          loader === "isempty" ||
          loader === "notset") && (
          <div className="E_comp_details flt">
            <div className="main_boxShadow flt">
              <div className="main_company flt">
                <div className="main_row flt">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">KM Limit</label>
                          <input
                            type="number"
                            placeholder="KM Limit"
                            name="KMLimit"
                            id="KMLimit"
                            value={KMLimit}
                            onChange={Changehandler}
                          />

                          {submit && !KMLimit && (
                            <div className="req_field">
                              KM Limit is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">ODO Meter</label>
                          <div className="make_img12 flt">
                            <img src={odo} alt="ado" />
                            <input
                              type="number"
                              placeholder="odometer"
                              name="odometer"
                              id="odometer"
                              value={odometer}
                              onChange={Changehandler}
                            />
                          </div>
                          {submit && !odometer && (
                            <div className="req_field">
                              ODO Meter is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">Fuel Level</label>
                          <div className="make_img12 flt">
                            <img src={fuel} alt="fuel" />
                            <select
                              name="fuellevel"
                              id="fuellevel"
                              onChange={Changehandler}
                              value={fuellevel}
                            >
                              <option value="">Select Fuel Level</option>
                              <option value="full">Full</option>
                              <option value="half">Half</option>
                              <option value="reserve">Reserve</option>
                            </select>
                          </div>
                          {submit && !fuellevel && (
                            <div className="req_field">
                              Fuel Level is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                   
                    <div className="col-sm-3">
                        <div className="company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">Helmet Numbers</label>
                            <input
                              type="text"
                              placeholder="Helmet Numbers"
                              name="helmetqty"
                              id="helmetqty"
                              value={helmetqty}
                              onChange={Changehandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  
                  <div className="main_ownbutton flt">
                    <div className="own_button flt">
                      <button
                        className="backbutton"
                        onClick={() => displayData(3)}
                      >
                        Back
                      </button>
                      <button className="submitbutton" onClick={checkOutStore}>
                        CheckOut
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  return <>{PersonalDetails()}</>;
};
export default CheckOut;
