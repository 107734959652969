import { searchvehicle } from "../../../../../../../../assets/images";
import { Calender } from "../../../../../../../../assets/images";
import { useState, useEffect } from "react";
import useRentalDetailsHook from "./useRentalDetailsHook";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Loading } from "../../../../../../../../assets/images";

const AddRentalDetailsInfo = ({ displayData, tab, bookingid, cid, t }) => {
  const [rowinfo, setrowinfo] = useState("");
  const [show, setShow] = useState(true);
  const {
    loader,
    listData,
    totalPages,
    datetimevalue,
    rlocations,
    datetimeendvalue,
    locations,
    helmets_qty,
    travel,
    vehicleClass,
    returnLocation,
    selectedVehicle,
    submitted,
    datavalue,
    make,
    model,
    page,
    handlePageClick,
    onChangeValue,
    onChangeendValue,
    changeHandlerrelocation,
    changeHandlerlocation,
    helmets_qtys,
    travelLocation,
    SearchVehicle,
    changeVehicleClass,
    validselectedVehicle,
    setdatavalue,
    continueStore,
  } = useRentalDetailsHook(displayData, bookingid, rowinfo, t);
  var validvehicle = validselectedVehicle();
  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };
  let order = [];
  let obj = {};
  let diffDays;
  if (datetimevalue != "" && datetimeendvalue !== "") {
    var date1 = new Date(datetimevalue);
    var date2 = new Date(datetimeendvalue);
    // diffDays = date2.getDate() - date1.getDate();
    const diffTime = Math.abs(date2 - date1);
    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
  const [listTable, setListTable] = useState([]);
  const columns = [
    {
      name: t("Sr_No"),
      selector: (row) => row.srnumber,
    },
    {
      name: t("Make"),
      selector: (row) => row.make,
    },
    {
      name: t("Model"),
      selector: (row) => row.model,
    },
    {
      name: t("Vehicle_Count"),
      selector: (row) => (row.vehiclecount <= 0 ? 0 : row.vehiclecount),

      width: "150px",
    },

    {
      name: t("Select_Vehicle"),
      selector: (row) =>
        show && row.vehiclecount > 0 ? (
          <button className="viewdata" onClick={() => handleRow(row)}>
            Select
          </button>
        ) : (
          <button setShow={false} disabled>
            Select
          </button>
        ),
      width: "150px",
    },
  ];

  /**
   * tab details
   */
  useEffect(() => {
    if (make !== "") {
      setdatavalue(3);
    }
    // eslint-disable-next-line
  }, [make !== ""]);
  /**
   * call table list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    setrowinfo(row);
    setdatavalue(3);
  };

  const addRentailInfo = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          <div className="Company_name flt">
            <div className="comp_name_details flt">
              {t("Add_Rental_details")}
            </div>
          </div>
          {loader === "loading" && (
            <div className="main_load ">
              <img src={Loading} alt="loader" />
            </div>
          )}
          {(loader === "isfull" ||
            loader === "isempty" ||
            loader === "notset") && (
            <>
              <div className="main_company flt">
                <div className="main_row flt">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className=" company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Start_Date_Start_Time")} *
                          </label>
                          <div className="main_cal_img">
                            <Datetime
                              dateFormat="DD/MM/YYYY"
                              onChange={onChangeValue}
                              value={new Date(datetimevalue)}
                              // isValidDate={(currentDate, selectedDate) =>
                              //   currentDate.isAfter(
                              //     new Date().getTime() - 24 * 60 * 60 * 1000
                              //   )
                              // }
                              inputProps={{
                                placeholder: t("Select Date"),
                                disabled: false,
                                readOnly: true,
                                onKeyDown: (e) => e.preventDefault(),
                              }}
                            />
                            <div className="cal_img">
                              <img src={Calender} alt="calender" />
                            </div>
                          </div>

                          {submitted && !datetimevalue && (
                            <div className="invalid-feedback">
                              {t("Start Date Start Time required")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className=" company_name flt">
                          <div className="form-group flt">
                            <label className="labelName">
                              {t("End_Date_End_Time")} *{" "}
                              <span className="numdays">
                                {" "}
                                No of days : {diffDays ? diffDays : "0"}
                              </span>
                            </label>
                            <div className="main_cal_img">
                              <Datetime
                                dateFormat="DD/MM/YYYY"
                                onChange={onChangeendValue}
                                value={new Date(datetimeendvalue)}
                                // isValidDate={(currentDate, selectedDate) =>
                                //   currentDate.isAfter(
                                //     new Date().getTime() - 24 * 60 * 60 * 1000
                                //   )
                                // }
                                inputProps={{
                                  placeholder: t("Select Date"),
                                  disabled: false,
                                  readOnly: true,
                                  onKeyDown: (e) => e.preventDefault(),
                                }}
                              />

                              <div className="cal_img">
                                <img
                                  src={Calender}
                                  alt="calender"
                                  onChange={Datetime}
                                />
                              </div>
                            </div>
                            {submitted && !datetimeendvalue && (
                              <div className="invalid-feedback">
                                {t("End Date End Time required")}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {" "}
                            {t("Pick_Location")} *
                          </label>
                          <select
                            name="Location"
                            id="lang"
                            value={rlocations}
                            onChange={changeHandlerrelocation}
                          >
                            <option value="">{t("Pick_Location")}</option>
                            {locations
                              ? locations.map((value) => (
                                  <option
                                    value={value._id}
                                    key={value.locationName}
                                  >
                                    {value.locationName}
                                  </option>
                                ))
                              : ""}
                          </select>
                        </div>
                        {submitted && !rlocations && (
                          <div className="invalid-feedback">
                            {t("Pick_Location_required")}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {" "}
                            {t("Return_Location")} *
                          </label>

                          <select
                            name="location"
                            onChange={changeHandlerlocation}
                            value={returnLocation}
                          >
                            <option value="">
                              {t("select_Return_Location")}
                            </option>
                            {locations
                              ? locations.map((value) => (
                                  <option
                                    value={value._id}
                                    key={value.locationName}
                                  >
                                    {value.locationName}
                                  </option>
                                ))
                              : ""}
                          </select>
                        </div>
                        {submitted && !returnLocation && (
                          <div className="invalid-feedback">
                            {t("Return_Location_required")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">{t("Helmet_Qty")}</label>
                          <select
                            name="helmets_qty"
                            id="helmets_qty"
                            onChange={helmets_qtys}
                            value={helmets_qty}
                          >
                            <option value="">{t("Select_Helmet_Qty")}</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Travel_Locations")}
                          </label>
                          <input
                            className="comp_input"
                            type="text"
                            placeholder={t("Travel_Locations")}
                            name="Travel"
                            id="Travel"
                            value={travel}
                            onChange={travelLocation}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            {t("Vehicle Class")} *
                          </label>
                          <select
                            name="Vehicle"
                            onChange={changeVehicleClass}
                            value={selectedVehicle}
                          >
                            <option>{t("Vehicle Class")}</option>
                            {vehicleClass &&
                              vehicleClass.map(({ _id, vehicleClass }) => (
                                <option value={_id}>{vehicleClass}</option>
                              ))}
                          </select>
                        </div>
                        {submitted && validvehicle === false && (
                          <div className="invalid-feedback">
                            {t("Vehicle Class is required")}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
                <div className="delete_b flt">
                  <button
                    className={
                      datavalue === 3 ? "delete_btn123" : "delete_btn12"
                    }
                    onClick={() => SearchVehicle()}
                  >
                    <img src={searchvehicle} alt="calender" />
                    {t("Show_Available_Vehicle")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  /**
   * table list
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      obj = {
        srnumber:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        make: item?.make,
        makeid: item?.makeid,
        vehicleclassid: item?.vehicleclassid,
        model: item?.model,
        modelid: item?.modelid,
        vehiclecount: item?.vehiclecount,
        status: item?.vehiclestatus,
        // id: item?._id,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  const tableListDisplay = () => {
    return (
      <>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="main_load ">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  <DataTable
                    columns={columns}
                    data={listTable || []}
                    customStyles={customStyles}
                  />
                </table>
              )}
              {listData?.data?.data?.docs.length !== 0 && (
                <div>
                  <ReactPaginate
                    nextLabel="&rarr;"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={totalPages}
                    previousLabel="&larr;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                    forcePage={page - 1}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="ownbutton1 flt">
            <div className="own_button flt">
              <button className="submitbutton" onClick={() => setdatavalue(0)}>
                {t("Back")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  const selectvehicles = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          <div className="main_car_details flt">
            <div className="row">
              <div className="col-sm-4">
                <div className="main_car_padding flt ">
                  <div className="car_details flt">{t("Selected_Vehicle")}</div>
                  <div className="details_car flt">
                    <div className="model_details flt">
                      <ul className="make_details flt">
                        <li>
                          {t("Make")}
                          <span>:</span>
                        </li>

                        <li>{rowinfo?.make ? rowinfo?.make : make}</li>
                        <li>
                          {t("Model")}
                          <span>:</span>
                        </li>

                        <li>{rowinfo?.model ? rowinfo?.model : model}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main_ownbutton flt">
                <div className="own_button flt">
                  <button
                    onClick={() => setdatavalue(1)}
                    className="backbutton"
                  >
                    {t("Back")}
                  </button>
                  <button onClick={continueStore} className="submitbutton">
                    {t("Continue")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {datavalue !== 1 && addRentailInfo()}
      {datavalue === 1 && tableListDisplay()}
      {datavalue === 3 && selectvehicles()}
    </>
  );
};
export default AddRentalDetailsInfo;
