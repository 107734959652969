import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../../../actions";
import { SetUpService, VehicleService } from "../../../../../services";
import moment from "moment";

const useGeneralInfo = (location, tab, tabId) => {
  const [id, setId] = useState("");
  const [stockNumber, setStockNumber] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [chasisNumber, setChasisNumber] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("2000");
  const [ODOMeterNo, setOdo] = useState("");
  const [color, setColor] = useState("");
  const [transmission, setTransmission] = useState("automatic");
  const [vehicleClass, setVehicleClass] = useState("");
  const [startDate, setStartDate] = useState("");
  const [dateSold, setDateSold] = useState("");
  const [fuelTypeCapacity, setFuelTypeCapacity] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [locations, setLocations] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [investorName, setInvestorName] = useState("");
  const [availabilty, setAvailability] = useState("available");
  const [status, setStatus] = useState("");
  const [basis, setBasis] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [investorArray, setInvestorArray] = useState([]);
  const [locationArray, setLocationArray] = useState([]);
  const [showVehicle, setShowVehicle] = useState(false);
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [checkRes, setCheckRes] = useState(false);
  const [vehicleStatus, setVehicleStatus] = useState("");
  const fuelTypeCapacityRef = useRef();
  const colorRef = useRef();
  const yearRef = useRef();
  const transmissionRef = useRef();
  const locationRef = useRef();
  const [vehicleList, setVehicleList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [classId, setClassId] = useState("");
  const [makeId, setMakeId] = useState("");
  const [modelId, setModelId] = useState("");
  const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    {
      name: "Make",
      selector: (row) => row.make,
    },
    {
      name: "Model",
      selector: (row) => row.model,
    },
    {
      name: "Year",
      selector: (row) => row.year,
    },
  ];

  let order = [];
  let obj = {};

  /**
   * make list
   */
  useEffect(() => {
    makeListResponse(classId);
  }, [classId, vehicleClass]);

  /**
   * model list
   */
  useEffect(() => {
    modelListResponse(classId, makeId);
  }, [classId, vehicleClass, makeId, make]);

  /**
   * get gen info
   */
  useEffect(() => {
    if (tabId !== "undefined") {
      getSingleGenInfo();
    }
    // eslint-disable-next-line
  }, [tab === 1]);

  /**
   * get class list
   */
  useEffect(() => {
    vehicleClassListResponse();
    // eslint-disable-next-line
  }, []);
  /**
   * get veh response
   */
  useEffect(() => {
    vehicleResponse(page, "stockNum", "", "");
  }, [page]);

  /**
   * get investor and loc name
   *
   */
  useEffect(() => {
    VehicleService.getInvestorName()
      .then((response) => {
        setInvestorArray(response?.data?.data);
      })
      .catch((err) => console.log(err));
    VehicleService.getLocationName()
      .then((response) => {
        setLocationArray(response?.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   * call table list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * table list
   */
  const list = () => {
    listData?.data?.data?.docs.map((item) => {
      obj = {
        make: item?.make?.[0]?.make,
        makeId: item?.make?.[0]?._id,
        model: item?.model?.[0]?.model,
        modelId: item?.model?.[0]?._id,
        vehicleClass: item?.vehicleClass?.[0]?.vehicleClass,
        vehicleClassId: item?.vehicleClass?.[0]?._id,
        year: item.year,
        color: item.color,
        transmission: item.transmission,
        fuelTypeCapacity: item.fuelTypeCapacity,
      };

      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * get all vehicle list
   */
  const vehicleClassListResponse = () => {
    SetUpService.getVehicleClassWithoutPage()
      .then((response) => {
        setVehicleList(response?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  /**
   * get all make list
   * @param {*} mid
   */
  const makeListResponse = (mid) => {
    if (mid !== "") {
      SetUpService.getMakeWithoutPage(mid)
        .then((response) => {
          setMakeList(response?.data?.data);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  /**
   * get all model list
   * @param {*} vid
   * @param {*} mid
   */
  const modelListResponse = (vid, mid) => {
    if (vid !== "" && mid !== "") {
      SetUpService.getModelWithoutPage(vid, mid)
        .then((response) => {
          setModelList(response?.data?.data);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  /**
   * get single gen info
   */
  const getSingleGenInfo = () => {
    if (tabId) {
      if (tabId !== undefined) {
        setLoader("loading");
        VehicleService.getSingleInfoVehicle(tabId)

          .then((response) => {
            setCheckRes(true);
            setId(response?.data?.data?.[0]?._id);
            setStockNumber(response?.data?.data?.[0]?.stockNumber);
            setRegNumber(response?.data?.data?.[0]?.regNumber);
            setChasisNumber(response?.data?.data?.[0]?.chasisNumber);
            setMakeId(response?.data?.data?.[0]?.vehicleClassMake?.[0]?._id);
            setModelId(response?.data?.data?.[0]?.vehicleClassModel?.[0]?._id);
            setMake(response?.data?.data?.[0]?.vehicleClassMake?.[0]?._id);
            setModel(response?.data?.data?.[0]?.vehicleClassModel?.[0]?._id);
            setYear(response?.data?.data?.[0]?.year);
            setOdo(response?.data?.data?.[0]?.ODOMeterNo?.toString());
            setColor(response?.data?.data?.[0]?.color);
            setTransmission(response?.data?.data?.[0]?.transmission);
            setVehicleClass(
              response?.data?.data?.[0]?.vehicleClassName?.[0]?._id
            );
            setClassId(response?.data?.data?.[0]?.vehicleClassName?.[0]?._id);
            setStartDate(
              response?.data?.data?.[0]?.startDate
                ? response?.data?.data?.[0]?.startDate
                : ""
            );
            setDateSold(
              response?.data?.data?.[0]?.dateSold
                ? response?.data?.data?.[0]?.dateSold
                : ""
            );
            setFuelTypeCapacity(response?.data?.data?.[0]?.fuelTypeCapacity);
            setSalePrice(response?.data?.data?.[0]?.salePrice);
            setLocations(response?.data?.data?.[0]?.location?.[0]._id);
            setPurchasePrice(response?.data?.data?.[0]?.purchasePrice);
            setInvestorName(response?.data?.data?.[0]?.investorName);
            setBasis(response?.data?.data?.[0]?.basis);
            setAvailability(
              response?.data?.data?.[0]?.vehicle_status === "onrent"
                ? "onrent"
                : response?.data?.data?.[0]?.vehicle_status
            );
            setVehicleStatus(response?.data?.data?.[0]?.vehicle_status);
            setLoader("isfull");
          })
          .catch((err) => console.log({ err }));
      }
    } else {
      if (tabId !== undefined) {
        setLoader("loading");
        VehicleService.getSingleInfoVehicle(tabId)

          .then((response) => {
            setCheckRes(true);
            setId(response?.data?.data?.[0]?._id);
            setStockNumber(response?.data?.data?.[0]?.stockNumber);
            setRegNumber(response?.data?.data?.[0]?.regNumber);
            setChasisNumber(response?.data?.data?.[0]?.chasisNumber);
            setMakeId(response?.data?.data?.[0]?.make?.[0]?._id);
            setModelId(response?.data?.data?.[0]?.model?.[0]?._id);
            setMake(response?.data?.data?.[0]?.make?.[0]?._id);
            setModel(response?.data?.data?.[0]?.model?.[0]?._id);
            setYear(response?.data?.data?.[0]?.year);
            setOdo(response?.data?.data?.[0]?.ODOMeterNo);
            setColor(response?.data?.data?.[0]?.color);
            setTransmission(response?.data?.data?.[0]?.transmission);
            setVehicleClass(response?.data?.data?.[0]?.vehicleClass?.[0]?._id);
            setClassId(response?.data?.data?.[0]?.vehicleClass?.[0]?._id);
            setStartDate(
              response?.data?.data?.[0]?.startDate
                ? response?.data?.data?.[0]?.startDate
                : ""
            );
            setDateSold(
              response?.data?.data?.[0]?.dateSold
                ? response?.data?.data?.[0]?.dateSold
                : ""
            );
            setFuelTypeCapacity(response?.data?.data?.[0]?.fuelTypeCapacity);
            setSalePrice(response?.data?.data?.[0]?.salePrice);
            setLocations(response?.data?.data?.[0]?.location?.[0]._id);
            setPurchasePrice(response?.data?.data?.[0]?.purchasePrice);
            setInvestorName(response?.data?.data?.[0]?.investorName);
            setBasis(response?.data?.data?.[0]?.basis);
            setAvailability(
              response?.data?.data?.[0]?.vehicle_status === "onrent"
                ? "onrent"
                : response?.data?.data?.[0]?.availabilty
            );
            setVehicleStatus(response?.data?.data?.[0]?.vehicle_status);
            setLoader("isfull");
          })
          .catch((err) => console.log({ err }));
      }
    }
  };

  /**
   * call list api
   * @param {*} page
   * @param {*} item
   * @param {*} search
   * @param {*} status
   */
  const vehicleResponse = (page, item, search, status) => {
    VehicleService.getAllVehicles(page, item, search, status).then(
      (response) => {
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
      }
    );
  };

  /**
   * handle change
   * @param {*} e
   */
  const handleStockNumber = (e) => {
    setStockNumber(e.target.value);
  };

  const handleRegNumber = (e) => {
    setRegNumber(e.target.value);
  };
  const handleChasisNumber = (e) => {
    setChasisNumber(e.target.value);
  };
  const handleMake = (e) => {
    setModel("");
    setModelId("");
    makeList.filter((val, index) =>
      val._id === e.target.value ? setMake(e.target.value) : ""
    );

    setMakeId(e.target.value);
  };
  const handleModel = (e) => {
    modelList.filter((val, index) =>
      val._id === e.target.value ? setModel(e.target.value) : ""
    );
    setModelId(e.target.value);
  };
  const handleYear = (e) => {
    setYear(e.target.value);
  };
  const handleODOMeterNo = (e) => {
    setOdo(e.target.value);
  };
  const handleColor = (e) => {
    setColor(e.target.value);
  };
  const handleTransmission = (e) => {
    setTransmission(e.target.value);
  };
  const handleVehicleClass = (e) => {
    vehicleList.filter((val, index) =>
      val._id === e.target.value ? setVehicleClass(e.target.value) : ""
    );

    setModel("");
    setModelId("");
    setMake("");
    setMakeId("");
    setClassId(e.target.value);
  };
  const handleStartDate = (e) => {
    setStartDate(moment(e).format("DD/MM/YYYY"));
  };
  const handleDateSold = (e) => {
    setDateSold(moment(e).format("DD/MM/YYYY"));
  };
  const handleFuelTypeCapacity = (e) => {
    setFuelTypeCapacity(e.target.value);
  };
  const handleSalePrice = (e) => {
    setSalePrice(e.target.value);
  };
  const handleLocation = (e) => {
    setLocations(e.target.value);
  };
  const handlePurchasePrice = (e) => {
    setPurchasePrice(e.target.value);
  };
  const handleInvestorName = (e) => {
    setInvestorName(e.target.value);
  };

  const handleBasis = (e, arr = []) => {
    var updatedList = [...arr];
    if (e.target.checked) {
      updatedList = [...arr, e.target.value];
    } else {
      updatedList.splice(arr.indexOf(e.target.value), 1);
    }

    setBasis(updatedList);
  };

  const handleAvailabilty = (e) => {
    setAvailability(e.target.value);
  };

  const openVehicle = () => {
    setShowVehicle(true);
  };
  const closeVehicle = () => {
    setShowVehicle(false);
  };

  const navigateToVehiclesListsPage = () => navigate("/vehicles");

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleModalRow = (row) => {
    fuelTypeCapacityRef.current.value = row?.fuelTypeCapacity;

    colorRef.current.value = row?.color;
    yearRef.current.value = row?.year;

    setVehicleClass(row?.vehicleClassId);
    setClassId(row?.vehicleClassId);
    setMake(row?.makeId);
    setModel(row?.modelId);
    setMakeId(row?.makeId);
    setModelId(row?.modelId);
    setYear(row?.year);
    setColor(row?.color);
    setTransmission(row?.transmission);
    setFuelTypeCapacity(row?.fuelTypeCapacity);
  };

  /**
   * add gen info
   * @param {*} e
   * @param {*} tab
   * @param {*} setTabId
   */
  const handleGeneralInfo = (e, tab, setTabId) => {
    e.preventDefault();
    setSubmit(true);

    if (
      stockNumber &&
      regNumber &&
      chasisNumber &&
      makeId &&
      modelId &&
      year &&
      color &&
      transmission &&
      classId &&
      fuelTypeCapacity &&
      locations &&
      investorName &&
      availabilty &&
      ODOMeterNo
    ) {
      setLoader("loading");
      VehicleService.addvehicleGeneralInfo(
        stockNumber,
        regNumber,
        chasisNumber,
        makeId,
        modelId,
        year,
        ODOMeterNo,
        color,
        transmission,
        classId,
        startDate,
        dateSold,
        fuelTypeCapacity,
        salePrice,
        locations,
        purchasePrice,
        investorName,
        basis,
        availabilty
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setSubmit(false);
            tab(2);

            setTabId(response?.data?.data?._id);
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * handle edit info
   * @param {*} e
   */
  const handleEditGeneralInfo = (e) => {
    e.preventDefault();

    setSubmit(true);
    if (
      id &&
      stockNumber &&
      regNumber &&
      chasisNumber &&
      makeId &&
      modelId &&
      year &&
      color &&
      transmission &&
      classId &&
      fuelTypeCapacity &&
      locations &&
      investorName &&
      availabilty &&
      ODOMeterNo
    ) {
      setLoader("loading");
      VehicleService.editvehicleGeneralInfo(
        id,
        stockNumber,
        regNumber,
        chasisNumber,
        makeId,
        modelId,
        year,
        ODOMeterNo,
        color,
        transmission,
        classId,
        startDate,
        dateSold,
        fuelTypeCapacity,
        salePrice,
        locations,
        purchasePrice,
        investorName,
        basis,
        availabilty
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setSubmit(false);
            tab(2);

            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * handle delete info
   * @param {*} e
   * @param {*} tabId
   */
  const handleDeleteVehicleSubmit = (e, tabId) => {
    e.preventDefault();
    setSubmit(true);

    VehicleService.deleteVehicle(tabId)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setSubmit(false);
          navigate("/vehicles");
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((err) => console.log(err));
  };

  return {
    submit,
    stockNumber,
    regNumber,
    chasisNumber,
    make,
    model,
    year,
    ODOMeterNo,
    color,
    transmission,
    vehicleClass,
    startDate,
    dateSold,
    fuelTypeCapacity,
    salePrice,
    locations,
    purchasePrice,
    investorName,
    basis,
    availabilty,
    loader,
    fuelTypeCapacityRef,
    colorRef,
    yearRef,
    transmissionRef,
    locationRef,
    investorArray,
    locationArray,
    showVehicle,
    listData,
    totalPages,
    id,
    checkRes,
    vehicleList,
    makeList,
    modelList,
    status,
    makeId,
    modelId,
    columns,
    listTable,
    vehicleStatus,
    setStatus,
    navigateToVehiclesListsPage,
    handleStockNumber,
    handleRegNumber,
    handleChasisNumber,
    handleMake,
    handleModel,
    handleYear,
    handleODOMeterNo,
    handleColor,
    handleVehicleClass,
    handleTransmission,
    handleStartDate,
    handleDateSold,
    handleFuelTypeCapacity,
    handlePurchasePrice,
    handleSalePrice,
    handleLocation,
    handleAvailabilty,
    handleInvestorName,
    handleBasis,
    handleGeneralInfo,
    handlePageClick,
    handleModalRow,
    handleEditGeneralInfo,
    handleDeleteVehicleSubmit,
    openVehicle,
    closeVehicle,
    setShowVehicle,
  };
};
export default useGeneralInfo;
