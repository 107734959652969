import useBookingHistrory from "./useBookingHistrory";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import TopBar from "../../../../../../_common/topbar/TopBar";
import Menu from "../../../../../../_common/menu/Menu";
import { agreement } from "../../../../../../../assets/images";
import { arrow } from "../../../../../../../assets/images";
import { cust } from "../../../../../../../assets/images";
import { Loading } from "../../../../../../../assets/images";
import "../../../../Agreement.css";
import { withTranslation } from "react-i18next";

const BookingHistrory = (props) => {
  const { t } = props;
  const {
    listData,
    search,
    totalPages,
    loader,
    columns,
    listTable,
    handlePageClick,
    navigateToList,
    changeItemHandler,
    changeSearchHandler,
  } = useBookingHistrory(props);

  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const tableListDisplay = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_location flt">
          <div className="location flt">
            <img src={arrow} alt="arrow" onClick={navigateToList} />
            <img src={cust} alt="cust" />
            {t("Customer_History")}
          </div>
          <div className="main_search_b1 flt">
            <div className="main_search_dd1">
              <div className="search_l ">
                <select name="item" id="item" onChange={changeItemHandler}>
                  <option default value="mobileno">
                    {t("Search by Mobile No.")}
                  </option>

                  <option value="fullname">{t("Search by Customer name.")}</option>
                </select>
              </div>
              <div className="search_b1 ">
                <input
                  type="text"
                  placeholder={t("Search..")}
                  name="search"
                  value={search}
                  onChange={changeSearchHandler}
                />
                <button className="search_button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="main_boxShadow flt">
          <div className="main_tableheadnew flt">
            {loader === "loading" || loader === "notset" ? (
              <div className="table_loader ">
                <img src={Loading} alt="loader" />
              </div>
            ) : (
              <table className="main_table1 flt ">
                {listData?.data?.data?.docs?.length !== 0 ? (
                  <DataTable
                    columns={columns}
                    data={listTable || []}
                    customStyles={customStyles}
                  />
                ) : (
                  <div className="no-data">{t("No Data Found")}</div>
                )}
                {listData?.data?.data?.docs.length !== 0 && (
                  <div>
                    <ReactPaginate
                      nextLabel="&rarr;"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      pageCount={totalPages}
                      previousLabel="&larr;"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                )}{" "}
              </table>
            )}
          </div>
        </div>
      </div>
    );
  };

  const agreementtiltle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img src={agreement} alt="agreement" />
          {t("New Agreement")}
        </div>
      </div>
    );
  };

  return (
    <div>
      <TopBar />
      <Menu />

      {agreementtiltle()}
      {tableListDisplay()}
    </div>
  );
};
export default withTranslation()(BookingHistrory);
