import { useState, useEffect } from "react";
import { VehicleService } from "../../../../../../../../services";
import { AgreementService } from "../../../../../../../../services";
import { StartrentService } from "../../../../../../../../services";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../../../../../actions/alert.actions";
import { useLocation } from "react-router";
import moment from "moment";

const useRentalDetailsHook = (displayDatas, bookid, rowinfo, t) => {
  const location = useLocation();

  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayData, setDisplayData] = useState(0);
  const [dataSelected, setDataSelected] = useState([]);
  const [datetimevalue, setdatetimevalue] = useState("");
  const [datetimeendvalue, setdatetimeendvalue] = useState("");
  const [locations, setlocations] = useState([]);
  const [rlocations, setrlocations] = useState("");
  const [helmets_qty, sethelmets_qty] = useState("");
  const [travel, settravel] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [vehicleClass, setVehicleClass] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [returnLocation, setReturnLocation] = useState("");
  const dispatch = useDispatch();
  const [datavalue, setdatavalue] = useState(0);
  const [vmake, setVmake] = useState("");
  const [vmodel, setVmodel] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const bookidrent = location.search.split("=")[2];
  const [loader, setLoader] = useState("notset");

  let data;
  /**
   * response call
   */
  useEffect(() => {
    if (selectedVehicle !== "") {
      bookingResponse(page, selectedVehicle, datetimevalue, datetimeendvalue);
    }
    // eslint-disable-next-line
  }, [page]);
  /**
   * get rental details
   */
  useEffect(() => {
    if (bookidrent) {
      StartrentService.getAllRentalData(bookidrent)
        .then((res) => {
          setLoader("loading");
          if (res?.data?.status?.code === 0) {
            setdatetimevalue(res?.data?.data?.[0]?.StartDateTime);
            setReturnLocation(res?.data?.data?.[0]?.ReturnLocation);
            sethelmets_qty(res?.data?.data?.[0]?.helmetqty);
            setrlocations(res?.data?.data?.[0]?.PickupLocation);
            settravel(res?.data?.data?.[0]?.TravelLocation);
            setSelectedVehicle(res?.data?.data?.[0]?.vehicalClass);
            setdatetimeendvalue(res?.data?.data?.[0]?.EndDateTime);
            setVmake(res?.data?.data?.[0]?.vehicalMake);
            setVmodel(res?.data?.data?.[0]?.vehicalModel);
            setMake(res?.data?.data?.[0]?.vehiclemake?.[0]?.make);
            setModel(res?.data?.data?.[0]?.vehicalmodel?.[0]?.model);
            setLoader("isfull");
          } else {
            console.log("error");
            setLoader("isempty");
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, []);

  /**
   * get vehicle and location list
   */
  useEffect(() => {
    VehicleService.getLocationName()
      .then((response) => {
        setlocations(response?.data?.data);
      })
      .catch((err) => console.log(err));
    AgreementService.getVehicleClassName()
      .then((response) => {
        setVehicleClass(response?.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   * api call for get details
   * @param {*} page
   * @param {*} id
   */
  const bookingResponse = (page, id, datetimevalue, datetimeendvalue) => {
    setLoader("loading");

    AgreementService.getBookingDetails(
      page,
      id,
      moment(datetimevalue).toISOString(),
      moment(datetimeendvalue).toISOString()

    )
      .then((response) => {
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        setdatavalue(1);
        setLoader("isfull");
      })
      .catch((err) => console.log(err));
  };

  /**
   * handle change
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };

  const navigateTohistory = () => {
    setDisplayData(1);
  };

  const onChangeValue = (e) => {

    setdatetimevalue(e);
  };
  const onChangeendValue = (e) => {

    setdatetimeendvalue(e);
  };
  const changeHandlerlocation = (e) => {
    setReturnLocation(e.target.value);
  };
  const changeHandlerrelocation = (e) => {
    setrlocations(e.target.value);
  };
  const helmets_qtys = (e) => {
    sethelmets_qty(e.target.value);
  };
  const travelLocation = (e) => {
    settravel(e.target.value);
  };
  const changeVehicleClass = (e) => {

    if (e.target.value !== selectedVehicle) {
      setdatavalue(0);
    }
    vehicleClass.filter((val, index) =>
      val._id === e.target.value ? setSelectedVehicle(e.target.value) : ""

    );
  };

  const validdatetimevalue = () => {
    if (datetimevalue === "" || datetimevalue === undefined) {
      return false;
    }
  };
  const validrlocations = () => {
    if (rlocations === "" || selectedVehicle === undefined) {
      return false;
    }
  };
  const validreturnLocation = () => {
    if (returnLocation === "" || selectedVehicle === undefined) {
      return false;
    }
  };
  const validdatetimeendvalue = () => {
    if (datetimeendvalue === "" || datetimeendvalue === undefined) {
      return false;
    }
  };

  const validselectedVehicle = () => {
    if (selectedVehicle === "" || selectedVehicle === undefined) {
      return false;
    }
  };

  /**
   * search vehicle
   * @param {*} e
   */
  const SearchVehicle = (e) => {
    setDisplayData(1);
    setSubmitted(true);
    if (datetimeendvalue && datetimevalue && returnLocation && rlocations && selectedVehicle) {
      if (selectedVehicle && location?.state?.id) {
        setLoader("loading");

        StartrentService.getBookingDetailsdata(
          page,
          vmake,
          selectedVehicle,
          vmodel,
          location?.state?.row?.id
        )
          .then((response) => {

            setListData(response);

            setTotalPages(response?.data?.data?.totalPages);
            setPage(response?.data?.data?.page);
            setSubmitted(false);
            setLoader("isfull");
            setdatavalue(1);
          })
          .catch((err) => console.log(err));
      } else {

        bookingResponse(page, selectedVehicle, datetimevalue, datetimeendvalue);
      }
    }
  };

  const ContinueInfo = () => {
    displayDatas(2);
  };

  /**
   * api call for store rentals
   */
  const continueStore = () => {
    setSubmitted(true);
    if (new Date(datetimeendvalue) < new Date(datetimevalue)) {
      dispatch(
        alertActions.error(t("End_Date_Should_be_Greater_than_Start_Date"))
      );
    } else {
      setLoader("loading");
      AgreementService.storeRentals(
        datetimevalue,
        datetimeendvalue,
        rlocations,
        returnLocation,
        helmets_qty,
        travel,
        rowinfo.vehicleclassid,
        rowinfo.makeid,
        rowinfo.modelid,
        bookidrent ? bookidrent : ""
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            dispatch(alertActions.success(response?.data?.status?.message));
            setLoader("isfull");
            setSubmitted(false);
            displayDatas(2);

            setdatavalue(1);
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return {
    loader,
    submitted,
    listData,
    totalPages,
    dataSelected,
    data,
    datetimevalue,
    datetimeendvalue,
    locations,
    rlocations,
    helmets_qty,
    travel,
    vehicleClass,
    datavalue,
    selectedVehicle,
    returnLocation,
    make,
    model,
    page,
    handlePageClick,
    navigateTohistory,
    onChangeValue,
    onChangeendValue,
    changeHandlerlocation,
    changeHandlerrelocation,
    helmets_qtys,
    travelLocation,
    SearchVehicle,
    ContinueInfo,
    changeVehicleClass,
    validdatetimevalue,
    validrlocations,
    validreturnLocation,
    validdatetimeendvalue,
    validselectedVehicle,
    setdatavalue,
    continueStore,
  };
};
export default useRentalDetailsHook;
