import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import useVehicleModelTabHook from "./useVehicleModelTabHook";
import { withTranslation } from "react-i18next";
import { Loading } from "../../../../../assets/images";

const VehicleModelTab = (props) => {
  const { t } = props;
  const {
    loader,
    vehicleList,
    vehId,
    makeList,
    makeId,
    model,
    totalPages3,
    submit,
    id,
    page3,
    columns,
    listTable3,
    handleVehClass,
    handleMake,
    handleSubmit,
    handleModel,
    handleEditModel,
    handlePageClick3,
    handleEditSubmit,

  } = useVehicleModelTabHook(props);

  const modelDetails = () => {
    return (<>
      {loader === "loading" && (
        <div className="main_load ">
          <img src={Loading} alt="loader" />
        </div>
      )}
      {(loader === "isfull" || loader === "isempty" || loader === "notset") && (
        <div className="main_row flt">
          <div className="row">
            <div className="col-sm-3">
              <div className="company_name flt">
                <div className="form-group flt">
                  <label className="labelName">{t("Vehicle Class")} *</label>
                  <select
                    name="vehClass"
                    id="vehClass"
                    onChange={handleVehClass}
                    value={vehId}
                  >
                    <option value="">{t("Select Vehicle Class")}</option>
                    {vehicleList &&
                      vehicleList.map(({ _id, vehicleClass }) => (
                        <option value={_id}>{vehicleClass}</option>
                      ))}
                  </select>
                  {submit && vehId === "" && (
                    <div className="req_field">{t("Vehicle Class is required")}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="company_name flt">
                <div className="form-group flt">
                  <label className="labelName">{t("Make")} *</label>
                  <select
                    name="make"
                    id="make"
                    onChange={handleMake}
                    value={makeId}
                  >
                    <option value="">{t("Select Make")}</option>
                    {makeList &&
                      makeList.map(({ _id, make }) => (
                        <option value={_id}>{make}</option>
                      ))}
                  </select>
                  {submit && makeId === "" && (
                    <div className="req_field">{t("Make is required")}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="company_name flt">
                <div className="form-group flt">
                  <label className="labelName">{t("Model")} *</label>
                  <input
                    type="model"
                    placeholder="Enter Model"
                    name="model"
                    id="model"
                    value={model}
                    onChange={handleModel}
                  />
                  {submit && model === "" && (
                    <div className="req_field">{t("Model is required")}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="edit_details1 edit_details1bb flt">
                {id === "" && (
                  <button className="edit_button1" onClick={handleSubmit}>
                    <i class="fas fa-save"></i>{t("Save")}
                  </button>
                )}
                {id !== "" && (
                  <button className="edit_button1" onClick={handleEditSubmit}>
                    <i class="fas fa-save"></i>{t("Update")}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-lg-7">
              <div className="main_tab_ flt">
                <div className="main_boxShadow flt">
                  <div className="main_tablehead flt">
                    {loader === "loading" || loader === "notset" ? (
                      <div className="table_loader ">
                        <img src={Loading} alt="loader" />
                      </div>
                    ) : (
                      <table className="main_table1 flt ">
                        {listTable3.length > 0 ? (
                          <DataTable
                            columns={columns}
                            data={listTable3 || []}
                            onRowClicked={(row) => handleEditModel(row)}
                          />
                        ) : (
                          <div className="no-data">{t("No Data Found")}</div>
                        )}

                        {listTable3.length > 0 && (
                          <ReactPaginate
                            nextLabel="&rarr;"
                            onPageChange={handlePageClick3}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={1}
                            pageCount={totalPages3}
                            previousLabel="&larr;"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                            forcePage={page3 - 1}
                          />
                        )}
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>)
  }




  return (
    <>
      {modelDetails()}
    </>
  );
};
export default withTranslation()(VehicleModelTab);
