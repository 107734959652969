import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Menu from "../../../../_common/menu/Menu";
import TopBar from "../../../../_common/topbar/TopBar";
import { onRent } from "../../../../../assets/images";
import useOnRentList from "./useOnRentList";
import { Loading } from "../../../../../assets/images";
import { Calender } from "../../../../../assets/images";
import { withTranslation } from "react-i18next";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const OnRentList = (props) => {
  const { t } = props;
  const {
    listData,
    totalPages,
    search,
    page,
    loader,
    fromDate,
    noData,
    item,
    locationArray,
    columns,
    listTable,
    returnDate,
    handlePageClick,
    changeItemHandler,
    changeSearchHandler,
    handleStartDate,
    handleReturnDate
  } = useOnRentList(props);
  /**
   * table style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "auto",
      },
    },
  };

  const searchDetails = () => {
    return (
      <>
        <div className="main_location flt">
          <div className="location flt">
            {" "}
            <img src={onRent} alt="images" />
            {t("On Rent Lists")}
          </div>

          <div className="main_search_b1 checkout_search9 flt">
            <div className="search_l search_l22">
              <select name="item" id="item" onChange={changeItemHandler}>
                <option default value="mobileno">
                  {t("Search by Mobile No.")}
                </option>
                <option value="fullname">
                  {t("Search by Customer name.")}
                </option>
                <option value="model">{t("Search by Model.")}</option>
                <option value="regNumber">
                  {t("Search by Registration No.")}
                </option>
                <option value="location">{t("Search by CheckOut-Loc.")}</option>
                <option value="agreementno">
                  {t("Search by Agreement No.")}
                </option>
                <option value="returnDate">
                  {t("returndate")}
                </option>
              </select>
            </div>
            {item !== "location" && item !== "returnDate" && (
              <div className="search_b1 ">
                <input
                  type="text"
                  placeholder={t("Search..")}
                  name="search"
                  value={search}
                  onChange={changeSearchHandler}
                />
                <button className="search_button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            )}
            {item === "location" && item !== "returnDate" && (
              <>
                <div className="search_l search_main7">
                  <select
                    name="search"
                    id="item"
                    onChange={changeSearchHandler}
                  >
                    <option value="">{t("Select Location Name")}</option>
                    {locationArray &&
                      locationArray.map(({ _id, locationName }) => (
                        <option value={locationName}>{locationName}</option>
                      ))}
                  </select>
                </div>
                <div className="main_cal_img search_date flt">
                  <Datetime
                    dateFormat="DD/MM/YYYY"
                    onChange={handleStartDate}
                    value={fromDate}
                    closeOnSelect={true}
                    timeFormat={false}
                    inputProps={{
                      placeholder: t("Select date"),
                      disabled: false,
                      readOnly: true,
                      onKeyDown: (e) => e.preventDefault(),
                    }}
                  />
                  <div className="cal_img">
                    <img src={Calender} alt="calender" />
                  </div>
                </div>
              </>
            )}
            {item === "returnDate" && (
              <>
                {/* <div className="form-group flt">
                  <Datetime
                    dateFormat="DD/MM/YYYY"
                    onChange={handleReturnDate}
                    value={returnDate}
                    closeOnSelect={true}
                    timeFormat={false}
                    inputProps={{
                      placeholder: t("Select_Return_date"),
                      disabled: false,
                      readOnly: true,
                      onKeyDown: (e) => e.preventDefault(),
                    }}
                  />
                  <div className="cal_img">
                    <img src={Calender} alt="calender" />
                  </div>
                </div> */}
                <div className="main_cal_img search_date flt">
                  <Datetime
                    dateFormat="DD/MM/YYYY"
                    onChange={handleStartDate}
                    value={fromDate}
                    closeOnSelect={true}
                    timeFormat={false}
                    inputProps={{
                      placeholder: t("Select date"),
                      disabled: false,
                      readOnly: true,
                      onKeyDown: (e) => e.preventDefault(),
                    }}
                  />
                  <div className="cal_img">
                    <img src={Calender} alt="calender" />
                  </div>
                </div>

              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const tableDetails = () => {
    return (
      <>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="table_loader ">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  {listData?.data?.data?.docs?.length !== 0 &&
                    noData === false ? (
                    <DataTable
                      columns={columns}
                      data={listTable || []}
                      customStyles={customStyles}
                    />
                  ) : (
                    <div className="no-data">{t("No Data Found")}</div>
                  )}
                  {listData?.data?.data?.docs.length !== 0 &&
                    noData === false && (
                      <div>
                        <ReactPaginate
                          nextLabel="&rarr;"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={1}
                          marginPagesDisplayed={1}
                          pageCount={totalPages}
                          previousLabel="&larr;"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          currentPage={page}
                          forcePage={page - 1}
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    )}{" "}
                </table>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="header_location flt">
      <div className="main_container flt">
        <TopBar />
        <Menu />
        {searchDetails()}
        {tableDetails()}
      </div>
    </div>
  );
};
export default withTranslation()(OnRentList);
