import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { InvoiceService, VehicleService } from "../../../../services";
import axios from "axios";
import { Loading } from "../../../../assets/images";
import moment from "moment";
import { alertActions } from "../../../../actions";

const useInvoiceListHook = (props) => {
  const { t } = props;
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("mobileno");
  const [loader, setLoader] = useState("notset");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [noData, setNoData] = useState(false);
  const [locationArray, setLocationArray] = useState([]);
  const authdata = useSelector((state) => state?.userData);
  const [listTable, setListTable] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    {
      name: t("Agrmt_No"),
      selector: (row) => row.rentalid,
      width: "100px"
    },
    {
      name: t("Name"),
      selector: (row) => row.fullname,
      width: "150px"
    },
    {
      name: t("Reg. No"),
      selector: (row) => row.regNumber,
      width: "150px"
    },

    {
      name: t("Mobile"),
      selector: (row) => row.mobileno,
      width: "150px"
    },
    {
      name: t("Model"),
      selector: (row) => row.vehicleDetails,
      width: "150px"
    },
    {
      name: t("Start_D-T"),
      selector: (row) => row.StartDateTime,
      width: "180px"
    },
    {
      name: t("End_D-T"),
      selector: (row) => row.EndDateTime,
      width: "180px"
    },
    {
      name: t("Days"),
      selector: (row) => (row.NoOFDays ? row.NoOFDays : "N/A"),
      width: "150px"
    },

    {
      name: t("Paid_Amt"),
      selector: (row) =>
        row.Advance_Amount !== 0 ? `₹${row.Advance_Amount.toFixed(2)}` : "N/A",
      width: "150px"
    },
    {
      name: t("Bal_Amt"),
      selector: (row) =>
        row.Balance_Amount !== 0 ? `₹${row.Balance_Amount.toFixed(2)}` : "N/A",
      width: "150px"
    },
    {
      name: t("Checkout-Loc"),
      selector: (row) => row.pickupLocation,
      width: "150px"
    },
    {

      name: t("View"),
      selector: (row) => (
        <button className="viewdata" onClick={() => handleRow(row)}>
          {t("View")}
        </button>
      ),
      width: "150px"
    },
    {
      name: t("Download"),
      selector: (row) => (
        <>
          {loader === "loading" && (
            <div className="table_loader ">
              <img src={Loading} alt="loader" />
            </div>
          )}
          {loader !== "loading" && (
            <>
              <button className="viewdata" onClick={() => downloadPdf(row)}>
                {t("Download")}
              </button>
            </>
          )}
        </>
      ),
      width: "150px"
    },
  ];

  let order = [];
  let obj = {};
  /**
   * call response
   */
  useEffect(() => {
    closedAgreementResponse(page, item, search, fromDate, toDate);
    // eslint-disable-next-line
  }, []);
  /**
   * location setting state
   */
  useEffect(() => {
    if (item === "location") {
      selectLocation();
    }
  }, [item]);

  /**
   * list call
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * list
   */
  const list = () => {
    listData?.data?.data?.docs.map((item, index) => {
      let startDate = item?.startdate ? new Date(item?.startdate) : "N/A";
      let endDate = item?.enddate ? new Date(item?.enddate) : "N/A";
      obj = {
        id: item?._id,
        number:
          listData?.data?.data?.limit * (listData?.data?.data?.page - 1) +
          index +
          1,
        fullname: item?.customername ? item?.customername : "N/A",
        regNumber: item?.regNumber ? item?.regNumber : "N/A",
        mobileno: item?.customermobileno ? item?.customermobileno : "N/A",
        StartDateTime: startDate?.toLocaleString("en-IN"),
        EndDateTime: endDate?.toLocaleString("en-IN"),
        NoOFDays: item?.noofdays ? item?.noofdays : "N/A",
        vehicleDetails: item?.vehicledetailsmodel
          ? item?.vehicledetailsmodel
          : "N/A",

        Advance_Amount: item?.paidamount,
        Balance_Amount: item?.pendingamount,
        pickupLocation: item?.pickupLocation ? item?.pickupLocation : "N/A",
        rentalid: item?.rentalid ? item?.rentalid : "N/A",
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * list response
   * @param {*} page
   * @param {*} item
   * @param {*} search
   * @param {*} fromDate
   * @param {*} toDate
   */
  const closedAgreementResponse = (page, item, search, fromDate, toDate) => {
    if (toDate) {
      if (fromDate > toDate) {
        dispatch(
          alertActions.error("End Date Should be Greater than Start Date")
        );
      } else {
        setLoader("loading");
        InvoiceService.getClosedAgreementList(
          page,
          item,
          search.trim(),
          fromDate,
          toDate
        )
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              setNoData(false);
              setListData(response);
              setTotalPages(response?.data?.data?.totalPages);
              setPage(response?.data?.data?.page);
              setLoader("isfull");
            } else {
              setLoader("empty");
              setNoData(true);
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      setLoader("loading");
      InvoiceService.getClosedAgreementList(
        page,
        item,
        search.trim(),
        fromDate,
        toDate
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setNoData(false);
            setListData(response);
            setTotalPages(response?.data?.data?.totalPages);
            setPage(response?.data?.data?.page);
            setLoader("isfull");
          } else {
            setLoader("empty");
            setNoData(true);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * location api call
   */
  const selectLocation = () => {
    VehicleService.getLocationName()
      .then((response) => {
        setLocationArray(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  const handleStartDate = (e) => {
    setFromDate(moment(e).format("DD/MM/YYYY"));
    closedAgreementResponse(
      page,
      item,
      "",
      moment(e).format("DD/MM/YYYY"),
      toDate
    );
  };
  const handleEndDate = (e) => {
    setToDate(moment(e).format("DD/MM/YYYY"));
    closedAgreementResponse(
      page,
      item,
      "",
      fromDate,
      moment(e).format("DD/MM/YYYY")
    );
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    navigate(`/EndRentDetailsTab?bid=${row?.id}`);
  };

  /**
   * change handler
   * @param {*} e
   */
  const changeItemHandler = (e) => {
    setSearch("");
    setFromDate("");
    setToDate("");

    if (e.target.value === "fullname") {
      setItem(e.target.value);
      closedAgreementResponse(1, e.target.value, "", fromDate, toDate);
    } else if (e.target.value === "model") {
      setItem(e.target.value);
      closedAgreementResponse(1, e.target.value, "", fromDate, toDate);
    } else if (e.target.value === "location") {
      setItem(e.target.value);
      closedAgreementResponse(1, e.target.value, "", fromDate, toDate);
    } else if (e.target.value === "date") {
      setItem(e.target.value);

      closedAgreementResponse(1, e.target.value, "", fromDate, toDate);
    } else if (e.target.value === "agreementno") {
      setItem(e.target.value);

      closedAgreementResponse(1, e.target.value, "", fromDate, toDate);
    } else {
      setItem(e.target.value);
      closedAgreementResponse(1, e.target.value, "", fromDate, toDate);
    }
  };

  /**
   * search handler
   * @param {*} e
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    closedAgreementResponse("", item, e.target.value, fromDate, toDate);
  };

  /**
   * handle page
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    closedAgreementResponse(e.selected + 1, item, search, fromDate, toDate);
  };

  /**
   * download pdf
   * @param {*} row
   */
  const downloadPdf = (row) => {
    setLoader("loading");

    axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_APIURL}/admin/agreement/booking/download-payment-pdf/${row.id}`,

      method: "GET",
      responseType: "arraybuffer",

      headers: {
        // Add any auth token here
        authorization: authdata?.type + " " + authdata?.token,
        "Content-Type": "blob",
      },
      // Attaching the form data
    }).then((response) => {
      setLoader("isfull");
      const resp = response;
      const link = document.createElement("a");
      const fileName = `${Date.now()}.pdf`;
      link.setAttribute("download", fileName);
      link.href = URL.createObjectURL(new Blob([resp.data]));
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  return {
    loader,
    listData,
    totalPages,
    search,
    page,
    item,
    fromDate,
    toDate,
    noData,
    locationArray,
    columns,
    listTable,
    handlePageClick,
    changeItemHandler,
    changeSearchHandler,
    handleStartDate,
    handleEndDate,
  };
};
export default useInvoiceListHook;
