import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { CustomerService } from "../../../../../../../services";

const useBookingHooks = (location) => {
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayData, setDisplayData] = useState(0);
  const [search, setSearch] = useState("");
  const [item, setItem] = useState("drivinglicense_number");
  const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);

  const columns = [
    {
      name: "Sr No",
      selector: (row) => row.srnumber,

      width: "80px",
    },
    {
      name: "Customer Name",
      selector: (row) => row.fullname,
      width: "220px",
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobileno,
      width: "180px",
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      width: "300px",
    },
    {
      name: "Driving Licence No",
      selector: (row) => row.drivinglicense_number,
      width: "200px",
    },
    {
      name: "No. of Rents",
      selector: (row) => row.no_of_rents,
      width: "150px",
    },
    {

      name: "Status",
      selector: (row) => (
        <div
          className={row.status === "Active" ? "button_style" : "button_style1"}
        >
          {row.status}
        </div>
      ),
      width: "150px",
    },
    {

      name: "History",
      selector: (row) => (
        <button className="viewdata" onClick={() => navigateTohistoryData(row)}>
          view
        </button>
      ),
      width: "150px",
    },
    {
      name: "Select Customer",

      selector: (row) => (
        <>
          {row.status === "Active" && (
            <button className="viewdata" onClick={() => handleRow(row)}>
              {" "}
              Select
            </button>
          )}
        </>
      ),
      width: "150px",
    },
  ];

  let order = [];
  let obj = {};
  /**
   * list response
   */
  useEffect(() => {
    bookingResponse(page, item, search);
    // eslint-disable-next-line
  }, []);

  /**
   * table list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * table list
   */
  const list = () => {
    listData.data.data.docs.map((item, index) => {
      obj = {
        srnumber:
          listData.data.data.limit * (listData.data.data.page - 1) + index + 1,
        fullname: item.fullname,
        mobileno: item.mobileno,
        email: item.email,
        drivinglicense_number: item.drivinglicense_number,
        num_rents: item.noofrents,
        status: item.status,
        alternatemobileno: item.alternatemobileno,
        date_of_birth: item.date_of_birth,
        id: item._id,
        no_of_rents: item?.no_of_rents,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * list api call
   * @param {*} page
   * @param {*} item
   * @param {*} search
   */
  const bookingResponse = (page, item, search) => {
    setLoader("loading");
    CustomerService.getAllCustomers(page, item, search)
      .then((response) => {
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        setLoader("isfull");
      })
      .catch((err) => console.log(err));
  };

  /**
   * change handler
   * @param {*} e
   */
  const changeItemHandler = (e) => {
    setSearch("");
    if (e.target.value === "mobileno") {
      setItem(e.target.value);

      bookingResponse(1, e.target.value, "");
    } else if (e.target.value === "email") {
      setItem(e.target.value);

      bookingResponse(1, e.target.value, "");
    } else if (e.target.value === "fullname") {
      setItem(e.target.value);

      bookingResponse(1, e.target.value, "");
    } else {
      setItem(e.target.value);
      bookingResponse(1, e.target.value, "");
    }
  };

  /**
   * search handler
   * @param {*} e
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    bookingResponse("", item, e.target.value);
  };

  /**
   * navigate
   */
  const navigateTohistory = () => {
    setDisplayData(1);
  };

  /**
   * tab change
   * @param {*} val
   */
  const personalData = (val) => {
    setDisplayData(val);
  };

  /**
   * handle row
   * @param {*} row
   */
  const handleRow = (row) => {
    navigate(`/WalkInOtpscreen?cid=${row.id}`, {
      state: {
        row: row,
      },
    });
  };

  /**
   * handle change page
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    bookingResponse(e.selected + 1, item, search);
  };

  /**
   * navigation
   * @param {*} val
   */
  const navigateToaddAgreement = (val) => {
    navigate("/WalkinBookingAgreeTab");
  };
  const navigateToAddCustomer = () => {
    navigate("/WalkinAddCustomer");
  };
  const navigateTohistoryData = (row) => {
    navigate("/WalkinBookingHistrory", {
      state: {
        row: row,
      },
    });
  };

  return {
    listData,
    displayData,
    totalPages,
    page,
    loader,
    search,
    columns,
    listTable,
    setDisplayData,
    handlePageClick,
    navigateToaddAgreement,
    navigateTohistory,
    personalData,
    navigateToAddCustomer,
    changeItemHandler,
    changeSearchHandler,
  };
};
export default useBookingHooks;
