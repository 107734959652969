import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { alertActions } from "../../../../../../../actions";
import {
  AgreementService,
  ExchangeService,
} from "../../../../../../../services";

const useExchangeNewVehicleCheckout = (setTab, tab, bookid) => {
  const [KMLimit, setKMLimit] = useState("");
  const [odometer, setOdometer] = useState("");
  const [fuellevel, setFuellevel] = useState("");
  const [helmetqty, setHelmetqty] = useState("");
  const [vehicleRes, setVehicleRes] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState("notset");
  const dispatch = useDispatch();
  const location = useLocation();

  /**
   * vehicle details
   */
  useEffect(() => {
    getVehicleDetails();
    // eslint-disable-next-line
  }, []);

  /**
   * api call for vehicle details
   */
  const getVehicleDetails = () => {
    setLoader("loading");
    ExchangeService.getVehicleDetails(bookid)
      .then((res) => {
        setLoader("isfull");

        setOdometer(res?.data?.data?.ODOMeterNo.toString());

        setVehicleRes(res);
      })
      .catch((err) => console.log(err));
    kmUpdate(bookid);
  };

  /**
   * km update
   * @param {*} bookid
   */
  const kmUpdate = (bookid) => {
    setLoader("loading");
    AgreementService.no_of_days(bookid)
      .then((res) => {
        if (res?.data?.status?.code === 0) {
          setLoader("isfull");
          if (res?.data?.data?.[0]?.vehicleCharges.length !== 0) {
            let selectedkm =
              res?.data?.data?.[0]?.vehicleCharges?.[0]?.RatePerDay[
              res?.data?.data?.[0]?.noofdays - 1
              ];
            let selecetdKmRate = selectedkm?.Cost ? selectedkm?.Km : "";
            setKMLimit(selecetdKmRate);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * handle change
   * @param {*} e
   */
  const Changehandler = (e) => {
    if (e.target.name === "KMLimit") {
      setKMLimit(e.target.value);
    }
    if (e.target.name === "odometer") {
      setOdometer(e.target.value);
    }
    if (e.target.name === "fuellevel") {
      setFuellevel(e.target.value);
    }
    if (e.target.name === "helmetqty") {
      setHelmetqty(e.target.value);
    }
  };
  /**
   * checkout store
   */
  const checkOutStore = () => {
    setSubmit(true);

    if (KMLimit && odometer && fuellevel) {
      setLoader("loading");
      ExchangeService.addCheckoutDetails(
        bookid,
        KMLimit,
        odometer,
        fuellevel,
        helmetqty,
        location?.search?.split("=")[2]?.substring(0, 24)
      )
        .then((res) => {
          if (res?.data?.status?.code === 0) {
            setSubmit(false);
            setLoader("isfull");
            dispatch(alertActions.success(res?.data?.status?.message));
            setTab(5);
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(res?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return {
    loader,
    KMLimit,
    odometer,
    fuellevel,
    helmetqty,
    vehicleRes,
    submit,
    Changehandler,
    checkOutStore,
  };
};
export default useExchangeNewVehicleCheckout;
