import Menu from "../../../../_common/menu/Menu";
import TopBar from "../../../../_common/topbar/TopBar";
import "../../Customer.css";
import { Customers } from "../../../../../assets/images";
import useCustomersList from "./useCustomersList";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { withTranslation } from "react-i18next";
import { Loading } from "../../../../../assets/images";

const CustomerLists = (props) => {
  const { t } = props;
  const {
    listData,
    totalPages,
    page,
    loader,
    search,
    columns,
    listTable,
    handlePageClick,
    navigateToaddCustomer,
    handleRow,
    changeItemHandler,
    changeSearchHandler,
  } = useCustomersList(props);

  /**
   * datatable style
   */
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const customerSearch = () => {
    return (
      <>
        <div className="main_location customer_listb33 flt">
          <div className="location">
            <img src={Customers} alt="customer" />
            {t("CUSTOMER")}
          </div>

          <div className="filler"></div>
            <div className="main_search_dd clist_search_dd">
              <div className="search_l ">
                <select name="item" id="item" onChange={changeItemHandler}>
                  <option default value="drivinglicense_number">
                    {t("Search by Licence No.")}
                  </option>
                  <option value="mobileno">{t("Search by Mobile No.")}</option>
                  <option value="email">{t("Search by Email Id.")}</option>
                  <option value="fullname">{t("Search by Customer Name.")}</option>
                </select>
              </div>
              <div className="search_b1 ">
                <input
                  type="text"
                  placeholder={t("Search..")}
                  name="search"
                  onChange={changeSearchHandler}
                  value={search}
                />
                <button className="search_button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div className="fliter_button2 clist_btn">
              <button
                onClick={() => {
                  navigateToaddCustomer();
                }}
              >
                <i class="fas fa-user-plus"></i>
                {t("Add Customer")}
              </button>
            </div>
        </div>
      </>
    )
  }

  const datatableDetails = () => {
    return (
      <>
        <div className="E_comp_details flt">
          <div className="main_boxShadow flt">
            <div className="main_tablehead flt">
              {loader === "loading" || loader === "notset" ? (
                <div className="table_loader ">
                  <img src={Loading} alt="loader" />
                </div>
              ) : (
                <table className="main_table1 flt ">
                  {listData?.data?.data?.docs?.length !== 0 ? (
                    <DataTable
                      columns={columns}
                      data={listTable || []}
                      onRowClicked={(row) => {
                        handleRow(row);
                      }}
                      customStyles={customStyles}
                    />
                  ) : (
                    <div className="no-data">{t("No Data Found")}</div>
                  )}
                  {listData?.data?.data?.docs?.length !== 0 && (
                    <div>
                      <ReactPaginate
                        nextLabel="&rarr;"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalPages}
                        previousLabel="&larr;"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        currentPage={page}
                        forcePage={page - 1}
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  )}
                </table>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      {customerSearch()}
      {datatableDetails()}
    </div>
  );
};
export default withTranslation() (CustomerLists);
