import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../../../actions";
import { SetUpService } from "../../../../../services";
import { arrayOfObjects } from "./util";
import { produce } from "immer";

const useVehicleCostHooks = () => {
  const [vehicleList, setVehicleList] = useState([]);
  const [vehClass, setVehClass] = useState("");
  const [classId, setClassId] = useState("");
  const [makeList, setMakeList] = useState([]);
  const [modelId, setModelId] = useState("");
  const [make, setMake] = useState("");
  const [makeId, setMakeId] = useState("");
  const [modelList, setModelList] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [model, setModel] = useState("");
  const [loader, setLoader] = useState("notset");
  const [thirtyArray, setThirtyArray] = useState(arrayOfObjects);
  const [displaythirty, setDisplayThirty] = useState(false);
  const [extraCharge, setExtraCharge] = useState({
    Km: "",
    Cost: "",
  });

  const dispatch = useDispatch();

  /**
   * vehicle class list response
   */
  useEffect(() => {
    vehicleClassListResponse();
  }, []);

  /**
   * make list response
   */
  useEffect(() => {
    if (classId !== "") {
      makeListResponse(classId);
    }
    // eslint-disable-next-line
  }, [classId !== "", vehClass !== ""]);

  /**
   * model list response
   */
  useEffect(() => {
    modelListResponse(classId, makeId);
  }, [classId, vehClass, makeId, make]);

  /**
   * handle submit
   */
  const handleshowSubmit = () => {
    setSubmit(true);
    if (classId && makeId && modelId) {
      setLoader("loading");
      SetUpService.getCostCharge(modelId)
        .then((response) => {
          setSubmit(false);
          setDisplayThirty(true);
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setExtraCharge({
              Km: response?.data?.data?.[0]?.Extra?.Km,
              Cost: response?.data?.data?.[0]?.Extra?.Cost,
            });
            setThirtyArray(
              response?.data?.data?.[0]?.RatePerDay
                ? response?.data?.data?.[0]?.RatePerDay
                : thirtyArray
            );
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };


  /**
   * handle veh class
   * @param {*} e 
   */
  const handleVehClass = (e) => {
    setMakeId("");
    setModelId("");
    setMake("");
    setModel("");
    makeListResponse(e.target.value);
    setDisplayThirty(false);
    setThirtyArray(arrayOfObjects);
    setExtraCharge({ Km: "", Cost: "" });
    setClassId(e.target.value);
    setVehClass(e.target.value);
  };


  /**
   * handle make
   * @param {*} e 
   */
  const handleMake = (e) => {
    setModel("");
    setModelId("");
    setDisplayThirty(false);
    setThirtyArray(arrayOfObjects);
    setExtraCharge({ Km: "", Cost: "" });
    makeList.filter((val, index) =>
      val._id === e.target.value ? setMake(e.target.value) : ""
    );

    setMakeId(e.target.value);
  };

  /**
   * handle model
   * @param {*} e 
   */
  const handleModel = (e) => {
    setThirtyArray(arrayOfObjects);
    setDisplayThirty(false);
    setExtraCharge({ Km: "", Cost: "" });
    modelList.filter((val, index) =>
      val._id === e.target.value ? setModel(e.target.value) : ""
    );
    setModelId(e.target.value);
  };

  /**
   * handle 30 array
   * @param {*} e 
   * @param {*} index 
   */
  const handleArrayCost = (e, index) => {
    const Cost = parseInt(e.target.value);
    setThirtyArray((thirtyArray) =>
      produce(thirtyArray, (v) => {
        v[index].Cost = Cost;
      })
    );
  };
  const handleArrayKm = (e, index) => {
    const Day = index + 1;
    const Km = parseInt(e.target.value);
    setThirtyArray((thirtyArray) =>
      produce(thirtyArray, (v) => {
        v[index].Day = Day;
        v[index].Km = Km;
      })
    );
  };

  /**
   * handle extra charge
   * @param {*} e 
   */
  const handleExtraChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setExtraCharge((preValue) => {
      if (name === "extraKm") {
        return {
          Km: value,
          Cost: preValue.Cost,
        };
      } else if (name === "extraCost") {
        return {
          Km: preValue.Km,
          Cost: value,
        };
      }
    });
  };

  /**
   * get all vehicle list
   */
  const vehicleClassListResponse = () => {
    SetUpService.getVehicleClassWithoutPage()
      .then((response) => {
        setVehicleList(response?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };
  
  /**
   * get all make list
   * @param {*} mid 
   */
  const makeListResponse = (mid) => {
    SetUpService.getMakeWithoutPage(mid)
      .then((response) => {
        setMakeList(response?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };
  
  /**
   * get all model list
   * @param {*} vid 
   * @param {*} mid 
   */
  const modelListResponse = (vid, mid) => {
    if (vid !== "" && mid !== "") {
      SetUpService.getModelWithoutPage(vid, mid)
        .then((response) => {
          setModelList(response?.data?.data);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  /**
   * add api call
   * @param {*} e 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    let chargeObject = {
      vehicleClass: classId,
      vehicleMake: makeId,
      vehicleModel: modelId,

      RatePerDay: thirtyArray,
      Extra: extraCharge,
    };
    setLoader("loading");
    SetUpService.addCostCharge(modelId, chargeObject)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setLoader("isfull");
          setSubmit(false);
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          setLoader("isempty");
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((err) => console.log(err));
  };

  return {
    loader,
    vehicleList,
    classId,
    makeList,
    makeId,
    model,
    submit,
    displaythirty,
    thirtyArray,
    modelList,
    extraCharge,
    handleVehClass,
    handleMake,
    handleSubmit,
    handleModel,
    handleshowSubmit,
    handleExtraChange,
    handleArrayCost,
    handleArrayKm,
  };
};
export default useVehicleCostHooks;
