import * as axios from "../network/Network";

const config = require("../network/api.json");

class InvestorService {
  /**
   * Get all investors
   * @param {*} page 
   * @param {*} value 
   * @param {*} search 
   * @returns 
   */
  static getAllInvestors(page, value, search) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.getInvestors}?page=${page}&searchby=${value}&value=${search}`,
    });
    return Promise.resolve(result);
  }

  /**
   * Delete investors
   * @param {*} id 
   * @returns 
   */
  static deleteInvestors(id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deleteInvestor + `/${id}`,
    });
    return Promise.resolve(result);
  }

  /**
   * 
   * @param {*} investorName 
   * @param {*} mobile_no 
   * @param {*} email 
   * @param {*} amount 
   * @param {*} start_date 
   * @param {*} period 
   * @param {*} bank_Name 
   * @param {*} bank_HolderName 
   * @param {*} bank_Ifsc 
   * @param {*} bank_AccountNumber 
   * @returns 
   */
  static addInvestor(
    investorName,
    mobile_no,
    email,
    amount,
    start_date,
    period,
    bank_Name,
    bank_HolderName,
    bank_Ifsc,
    bank_AccountNumber
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addInvestor,
      data: {
        investorName,
        mobile_no,
        email,
        amount,
        start_date,
        period,
        bank_Name,
        bank_HolderName,
        bank_Ifsc,
        bank_AccountNumber,
      },
    });
    return Promise.resolve(result);
  }

  /**
   * Edit investor
   * @param {*} id 
   * @param {*} investorName 
   * @param {*} mobile_no 
   * @param {*} email 
   * @param {*} amount 
   * @param {*} start_date 
   * @param {*} period 
   * @param {*} bank_Name 
   * @param {*} bank_HolderName 
   * @param {*} bank_Ifsc 
   * @param {*} bank_AccountNumber 
   * @returns 
   */
  static editInvestor(
    id,
    investorName,
    mobile_no,
    email,
    amount,
    start_date,
    period,
    bank_Name,
    bank_HolderName,
    bank_Ifsc,
    bank_AccountNumber
  ) {
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.editInvestor + `/${id}`,
      data: {
        investorName,
        mobile_no,
        email,
        amount,
        start_date,
        period,
        bank_Name,
        bank_HolderName,
        bank_Ifsc,
        bank_AccountNumber,
      },
    });
    return Promise.resolve(result);
  }
}
export { InvestorService };
