import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import useVehicleMakeTabHook from "./useVehicleMakeTabHook";
import { Loading } from "../../../../../assets/images";
import { withTranslation } from "react-i18next";

const VehicleMakeTab = (props) => {
  const { t } = props;


  const {
    loader,
    vehicleList,
    make,
    totalPages2,
    submit,
    vehId,
    id,
    columns,
    listTable2,
    page2,
    handleMake,
    handleVehClass,
    handleSubmit,
    handlePageClick2,
    handleEditMake,
    handleEditSubmit,
  } = useVehicleMakeTabHook();


  const makeDetails = () => {
    return (
      <>
        {loader === "loading" && (
          <div className="main_load ">
            <img src={Loading} alt="loader" />
          </div>
        )}
        {(loader === "isfull" || loader === "isempty" || loader === "notset") && (
          <div className="main_row flt">
            <div className="row">
              <div className="col-sm-3">
                <div className="company_name flt">
                  <div className="form-group flt">
                    <label className="labelName">{t("Vehicle Class")}</label>
                    <select
                      name="vehClass"
                      id="vehClass"
                      onChange={handleVehClass}
                      value={vehId}
                    >
                      <option value="">{t("Select Vehicle Class")}</option>
                      {vehicleList &&
                        vehicleList.map(({ _id, vehicleClass }) => (
                          <option value={_id}>{vehicleClass}</option>
                        ))}
                    </select>
                    {submit && !vehId && (
                      <div className="req_field">{t("Vehicle Class is required")}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="company_name flt">
                  <div className="form-group flt">
                    <label className="labelName">{t("Make")} *</label>
                    <input
                      type="text"
                      placeholder="Enter Make"
                      name="make"
                      id="make"
                      value={make}
                      onChange={handleMake}
                    />
                    {submit && !make && (
                      <div className="req_field">{t("Make is required")}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-2">
                <div className="edit_details1 edit_details1bb flt">
                  {id === "" && (
                    <button className="edit_button1" onClick={handleSubmit}>
                      <i class="fas fa-save"></i>{t("Save")}
                    </button>
                  )}
                  {id !== "" && (
                    <button className="edit_button1" onClick={handleEditSubmit}>
                      <i class="fas fa-save"></i>{t("Update")}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-lg-6">
                <div className="main_tab_ flt">
                  <div className="main_boxShadow flt">
                    <div className="main_tablehead flt">
                      {loader === "loading" || loader === "notset" ? (
                        <div className="table_loader ">
                          <img src={Loading} alt="loader" />
                        </div>
                      ) : (
                        <table className="main_table1 flt ">
                          {listTable2.length > 0 ? (
                            <DataTable
                              columns={columns}
                              data={listTable2 || []}
                              onRowClicked={(row) => handleEditMake(row)}
                            />
                          ) : (
                            <div className="no-data">{t("No Data Found")}</div>
                          )}
                          {listTable2.length > 0 && (
                            <ReactPaginate
                              nextLabel="&rarr;"
                              onPageChange={handlePageClick2}
                              pageRangeDisplayed={1}
                              marginPagesDisplayed={1}
                              pageCount={totalPages2}
                              previousLabel="&larr;"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                              forcePage={page2 - 1}
                            />
                          )}
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }


  return (
    <>
      {makeDetails()}
    </>
  );
};

export default withTranslation()(VehicleMakeTab);
