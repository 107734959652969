import { useEffect, useState } from "react";
import { userLogin } from "../../../actions/index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { alertActions } from "../../../actions/alert.actions";

const useLoginHooks = () => {
  const dispatch = useDispatch();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState("notset");
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.data?.status?.code === 0) {
      setUsername("");
      setPassword("");
      setSubmitted(false);
      setLoader("isfull");
      navigate("/");
      dispatch(alertActions.success(userData?.data?.status?.message));
    } else {
      setLoader("isempty");
      dispatch(alertActions.error(userData?.data?.status?.message));
    }
    // eslint-disable-next-line
  }, [userData]);

  function handleUserIdChange(e) {
    setUsername(e.target.value);
  }
  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  /**
   * login api call
   * @param {*} e
   */
  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    if (userName && password) {
      setLoader("loading");
      dispatch(userLogin(userName, password))
        .then((response) => {
          setUserData(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  }

  return {
    loader,
    userName,
    password,
    submitted,
    handleUserIdChange,
    handlePasswordChange,
    handleSubmit,
    userData,
  };
};
export default useLoginHooks;
