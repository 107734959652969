import { useEffect, useState } from "react";
import {
  AgreementService,
  SetUpService,
  StartrentService,
} from "../../../../../../../../services";
import { alertActions } from "../../../../../../../../actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import moment from "moment";

const PaymentDetailsHooks = (displayData, bookid, t) => {
  const [NoOfDays, setNoOfDays] = useState("");
  const [RentalCharges, setRentalCharges] = useState("");
  const [HelmetCharges, setHelmetCharges] = useState("");
  const [LuggageCharges, setLuggageCharges] = useState("");
  const [GrandTotal, setGrandTotal] = useState("");
  const [gst, setgst] = useState("");
  const [TotalRental, setTotalRental] = useState("");
  const [Damages, setDamages] = useState("");
  const [ExKmCharges, setExKmCharges] = useState("");
  const [OtherCharges, setOtherCharges] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [carddate, setCardDate] = useState("");
  const [advanceamount, setadvanceamount] = useState("");
  const [paymentType, setpaymentType] = useState("");
  const [balanceamount, setbalanceamount] = useState("");
  const [paymentdetails, setpaymentdetails] = useState("");
  const [paymentamount, setpaymentamount] = useState("");
  const [cardno, setcardno] = useState("");
  const [cvv, setcvv] = useState("");
  const [expiry, setexpiry] = useState("");
  const [holdername, setholdername] = useState("");
  const [cardType, setcardType] = useState("");
  const [transactionid, settransactionid] = useState("");
  const [fileName1, setFileName1] = useState("");
  const [selectedFile1, setSelectedFile1] = useState("");
  const [paymentImage, setPaymentImage] = useState("");
  const [displayMain, setDisplayMain] = useState(0);
  const [ref_number, setRefNumber] = useState("");
  const [paymentRes, setPaymentRes] = useState("");
  const [loader, setLoader] = useState("notset");
  const [acceptCheck, setAcceptCheck] = useState(false);
  const [gstRate, setGstRate] = useState();
  const [payment_dueDate, setPayment_DueDate] = useState("");
  const [paymentArray, setPaymentArray] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [newResponseData, setResponseData] = useState("");

  // const [paidAmount,setPaidAmount]=useState(0)
  const bookidpay = bookid;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * call due list
   */
  useEffect(() => {
    getDueList(bookid);
    // eslint-disable-next-line
  }, [bookid, displayMain === 0, localStorage.getItem("upState")]);

  /**
   * call gst rate
   */
  useEffect(() => {
    handleGstAmount();
    // eslint-disable-next-line
  }, [TotalRental]);

  /**
   * get payment type
   */
  useEffect(() => {
    AgreementService.getPaymentTypeName()
      .then((response) => {
        setPaymentArray(response);
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   * get infof
   */
  useEffect(() => {
    SetUpService.getGeneralInfo()
      .then((response) => {
        setLoader("loading");
        if (response?.data?.data?.docs.length !== 0) {
          setLoader("isfull");
          setGstRate(
            response?.data?.data?.docs?.[0]?.RegionalSettings?.gstRate
          );
        }
      })
      .catch((err) => console.log(err));

    AgreementService.getPayment(bookidpay)
      .then((res) => {
        console.log("bookimngngyh ", res);
        setLoader("loading");
        if (res?.data?.status?.code === 0) {
          setLoader("isfull");
          setAcceptCheck(
            res?.data?.data?.acceptCheck ? res?.data?.data?.acceptCheck : false
          );
          setNoOfDays(
            res?.data?.data?.NoOFDays ? res?.data?.data?.NoOFDays : 0
          );
          setRentalCharges(
            res?.data?.data?.RentalCharges ? res?.data?.data?.RentalCharges : 0
          );
          setHelmetCharges(
            res?.data?.data?.HelmetCharges ? res?.data?.data?.HelmetCharges : 0
          );
          setLuggageCharges(
            res?.data?.data?.LagguageCharges
              ? res?.data?.data?.LagguageCharges
              : 0
          );
          setGrandTotal(
            res?.data?.data?.Grand_Total ? res?.data?.data?.Grand_Total : 0
          );
          setgst(res?.data?.data?.GST ? res?.data?.data?.GST : 0);
          setTotalRental(
            res?.data?.data?.TotalRental_othercharges
              ? res?.data?.data?.TotalRental_othercharges
              : 0
          );
          setDamages(res?.data?.data?.Damages ? res?.data?.data?.Damages : 0);
          setDiscount(
            res?.data?.data?.discountCharges
              ? res?.data?.data?.discountCharges
              : 0
          );
          setExKmCharges(
            res?.data?.data?.ExkmCharges ? res?.data?.data?.ExkmCharges : 0
          );
          setOtherCharges(
            res?.data?.data?.OtherCharges ? res?.data?.data?.OtherCharges : 0
          );
          setPayment_DueDate(
            res?.data?.data?.payment_dueDate
              ? moment(res?.data?.data?.payment_dueDate).format("DD/MM/YYYY")
              : ""
          );
          // setPaidAmount(paymentRes?.data?.[0]?.paidamount ? paymentRes?.data?.[0]?.paidamount:0)
          // setPaidTotal(res?.data?.data?.Paid_Total ? res?.data?.data?.Paid_Total:0 )
        } else {
          console.log("error");
        }
      })
      .catch((err) => console.log(err));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    AgreementService.no_of_days(bookidpay)
      .then((res) => {
        setLoader("loading");
        if (res?.data?.status?.code === 0) {
          setLoader("isfull");
          setNoOfDays(res?.data?.data?.[0]?.noofdays);
          if (res?.data?.data?.[0]?.vehicleCharges.length !== 0) {
            let selectedDay =
              res?.data?.data?.[0]?.vehicleCharges?.[0]?.RatePerDay[
                res?.data?.data?.[0]?.noofdays - 1
              ];
            let selecetdCost = selectedDay?.Cost ? selectedDay?.Cost : 0;
            setResponseData(selecetdCost);

            // setRentalCharges(selecetdCost);
            if (!paymentRes?.data?.[0]?.paidamount && paymentRes !== "") {
              setRentalCharges(selecetdCost);
            }
            // if (RentalCharges == "") {
            //   setTotalRental(
            //     Number(
            //       Number(
            //         !paymentRes?.data?.[0]?.paidamount
            //           ? Number(Number(0) + Number(selecetdCost))
            //           : Number(
            //               Number(paymentRes?.data?.[0]?.pendingamount) +
            //                 Number(paymentRes?.data?.[0]?.paidamount)
            //             )
            //       )
            //     )
            //   );
            // } else {
            //   setTotalRental(Number(RentalCharges + selecetdCost));
            // }
          }
        }
      })
      .catch((err) => console.log(err));
  }, [NoOfDays]);

  useEffect(() => {
    let newValue =
      Number(RentalCharges) +
      Number(HelmetCharges) +
      Number(Damages) +
      Number(LuggageCharges) +
      Number(OtherCharges) +
      Number(ExKmCharges);
    if (RentalCharges !== newValue) {
      setTotalRental(newValue);
    } else {
      setTotalRental(RentalCharges);
    }
  }, [newResponseData, RentalCharges]);

  useEffect(() => {
    if (!bookid) {
      navigate("/booking");
    }
    // eslint-disable-next-line
  }, [bookid]);

  /**
   * handle image
   */
  useEffect(() => {
    if (!selectedFile1) {
      setPaymentImage(undefined);
      return;
    }

    const objectUrl1 = URL.createObjectURL(selectedFile1);

    setPaymentImage(objectUrl1);
    return () => URL.revokeObjectURL(objectUrl1);
  }, [selectedFile1]);

  /**
   * get due list
   * @param {*} bookid
   */
  const getDueList = (bookid) => {
    setLoader("loading");
    AgreementService.getDueList(bookid)
      .then((response) => {
        setLoader("isfull");
        setPaymentRes(response?.data);

        localStorage.setItem("upState", "false");
      })
      .catch((err) => console.log(err));
  };

  /**
   * handle checkbox
   * @param {*} e
   */

  // new Changes
  function changeAcceptCheckboxHandler(e) {
    setAcceptCheck(!acceptCheck);
    if (e.target.checked === true) {
      setgst(Number((TotalRental * gstRate) / 100));

      var grandDisplay = Number(
        (TotalRental * gstRate) / 100 + TotalRental
      ).toFixed(2);

      setGrandTotal(Number(grandDisplay) - Number(discount));
    } else {
      if (e.target.checked === false) {
        setgst("");
        setGrandTotal(Number(TotalRental) - Number(discount));
      }
    }
  }

  /**
   * handle due date
   * @param {*} e
   */
  const handlePaymentDueDate = (e) => {
    setPayment_DueDate(moment(e).format("DD/MM/YYYY"));
  };

  /**
   * handle change
   * @param {*} e
   */
  const handleChange = (e) => {
    var values = 0;
    if (e.target.name === "NoOfDays") {
      setNoOfDays(e.target.value);
    }
    if (e.target.name === "discount") {
      setDiscount(e.target.value);
      if (acceptCheck) {
        values = Number(TotalRental) + Number(gst) - Number(e.target.value);
      } else {
        values = Number(TotalRental) - Number(e.target.value);
      }
    }
    if (e.target.name === "RentalCharges") {
      setTotalRental(
        Number(TotalRental) - Number(RentalCharges) + Number(e.target.value)
      );
      setRentalCharges(e.target.value);
      values =
        Number(TotalRental) - Number(RentalCharges) + Number(e.target.value);
    }
    if (e.target.name === "HelmetCharges") {
      setTotalRental(
        Number(TotalRental) - Number(HelmetCharges) + Number(e.target.value)
      );
      setHelmetCharges(e.target.value);
      values =
        Number(TotalRental) - Number(HelmetCharges) + Number(e.target.value);
    }
    if (e.target.name === "LuggageCharges") {
      setTotalRental(
        Number(TotalRental) - Number(LuggageCharges) + Number(e.target.value)
      );
      setLuggageCharges(e.target.value);
      values =
        Number(TotalRental) - Number(LuggageCharges) + Number(e.target.value);
    }

    if (e.target.name === "Damages") {
      setTotalRental(
        Number(TotalRental) - Number(Damages) + Number(e.target.value)
      );
      setDamages(e.target.value);
      values = Number(TotalRental) - Number(Damages) + Number(e.target.value);
    }
    if (e.target.name === "ExKmCharges") {
      setTotalRental(
        Number(TotalRental) - Number(ExKmCharges) + Number(e.target.value)
      );
      setExKmCharges(e.target.value);
      values =
        Number(TotalRental) - Number(ExKmCharges) + Number(e.target.value);
    }
    if (e.target.name === "OtherCharges") {
      setTotalRental(
        Number(TotalRental) - Number(OtherCharges) + Number(e.target.value)
      );
      setOtherCharges(e.target.value);
      values =
        Number(TotalRental) - Number(OtherCharges) + Number(e.target.value);
    }

    setGrandTotal(Number(values));
  };

  /**
   * handle gst amount
   */

  // new changes
  const handleGstAmount = () => {
    var values = 0;
    if (acceptCheck === false) {
      if (Number(TotalRental - discount) >= 0) {
        values = Number(TotalRental - discount);
        setGrandTotal(Number(values));
      } else {
        setGrandTotal(0);
      }
    } else {
      setgst(Number((TotalRental * gstRate) / 100));

      var grandDisplay = Number(
        (TotalRental * gstRate) / 100 + TotalRental
      ).toFixed(2);
      if (Number(grandDisplay) - Number(discount) >= 0) {
        setGrandTotal(Number(grandDisplay) - Number(discount));
      } else {
        setGrandTotal(0);
      }
    }
  };

  /**
   * handle gst
   * @param {*} e
   */
  // new Changes
  const handleGst = (e) => {
    var values = 0;
    if (acceptCheck === false) {
      setgst(e.target.value);

      values = Number(TotalRental) + Number(e.target.value) - Number(discount);
      setGrandTotal(Number(values));
    } else {
      setgst(Number((TotalRental * gstRate) / 100));

      var grandDisplay = Number(
        (TotalRental * gstRate) / 100 + TotalRental - Number(discount)
      ).toFixed(2);

      setGrandTotal(grandDisplay);
    }
  };

  const handleCardDate = (e) => {
    setCardDate(moment(e).format("DD/MM/YYYY"));
  };

  /**
   * handle change
   * @param {*} e
   */
  const changeHandling = (e) => {
    if (e.target.name === "advanceamount") {
      setadvanceamount(e.target.value);
      var tot;
      if (paymentRes?.data?.length === 0) {
        tot = Number(GrandTotal) - 0 - Number(e.target.value);
        setbalanceamount(tot.toFixed(2));
      } else if (paymentRes?.data?.length > 0) {
        var tot1 = paymentRes?.data?.[0]?.paidamount;
        tot = Number(GrandTotal) - tot1 - Number(e.target.value);
        setbalanceamount(tot.toFixed(2));
      }
    }
    if (e.target.name === "paymentType") {
      setpaymentType(e.target.value);
    }

    if (e.target.name === "paymentdetails") {
      setpaymentdetails(e.target.value);
    }
    if (e.target.name === "paymentamount") {
      setpaymentamount(e.target.value);
    }
    if (e.target.name === "cardno") {
      setcardno(e.target.value);
    }
    if (e.target.name === "cvv") {
      setcvv(e.target.value);
    }
    if (e.target.name === "expiry") {
      setexpiry(e.target.value);
    }
    if (e.target.name === "holdername") {
      setholdername(e.target.value);
    }
    if (e.target.name === "cardType") {
      setcardType(e.target.value);
    }
    if (e.target.name === "transactionid") {
      settransactionid(e.target.value);
    }
    if (e.target.name === "ref_number") {
      setRefNumber(e.target.value);
    }
  };
  /**
   * navigate
   * @param {*} e
   */
  const navigateToAgreement = (e) => {
    if (RentalCharges === "" || RentalCharges === 0) {
      dispatch(alertActions.error(t("Rental_charge_is_required")));
    } else if (payment_dueDate === "") {
      dispatch(alertActions.error(t("Payment_Due_Date_is_required")));
    } else {
      makepayment(e);
      // getPayment
      navigate("/agreement");
    }
  };
  function onSelectFile1(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile1(undefined);
      return;
    }

    setFileName1(e.target.files[0].name);
    setSelectedFile1(e.target.files[0]);
  }

  /**
   * make payment
   * @param {*} e
   */
  const makepayment = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!RentalCharges) {
      dispatch(alertActions.error(t("Rental charge is required")));
    } else if (payment_dueDate === "") {
      dispatch(alertActions.error(t("Payment_Due_Date_is_required")));
    } else {
      setLoader("loading");
      AgreementService.makePayment(
        NoOfDays,
        RentalCharges,
        HelmetCharges,
        LuggageCharges,
        OtherCharges,
        ExKmCharges,
        Damages,
        TotalRental,
        gst,
        GrandTotal,
        bookidpay,
        acceptCheck,
        paymentRes?.data?.[0]?.paidamount,
        payment_dueDate
      )
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setLoader("isfull");
            setSubmitted(false);
            dispatch(alertActions.success(response?.data?.status?.message));
            setDisplayMain(1);
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const goBack = () => {
    // getPayment()
    setDisplayMain(0);
  };
  /**
   * credit payment
   * @param {*} e
   */
  const creditPayment = (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log();
    if (balanceamount < 0) {
      dispatch(alertActions.error(t("Enter_Proper_amount")));
    } else {
      if (carddate && paymentType && advanceamount && paymentdetails) {
        if (paymentType === "cash") {
          setLoader("loading");
          StartrentService.makePaymentCash(
            carddate,
            paymentType,
            paymentdetails,
            advanceamount,
            ref_number,
            bookidpay,
            Number(discount)
          )
            .then((response) => {
              if (response?.data?.status?.code === 0) {
                setDisplayMain(0);
                setadvanceamount("");
                setbalanceamount("");
                setCardDate("");
                setpaymentType("");
                setpaymentdetails("");
                setSubmitted(false);
                setLoader("isfull");
                dispatch(alertActions.success(response?.data?.status?.message));
              } else {
                setLoader("isempty");
                dispatch(alertActions.error(response?.data?.status?.message));
              }
            })
            .catch((err) => console.log(err));
        } else if (paymentType === "card") {
          setLoader("loading");
          StartrentService.makePaymentCard(
            carddate,
            paymentType,
            paymentdetails,
            advanceamount,
            cardType,
            cardno,
            holdername,
            cvv,
            expiry,
            ref_number,
            bookidpay,
            Number(discount)
          )
            .then((response) => {
              if (response?.data?.status?.code === 0) {
                setDisplayMain(0);
                setadvanceamount("");
                setbalanceamount("");
                setCardDate("");
                setpaymentType("");
                setpaymentdetails("");
                setLoader("isfull");
                setSubmitted(false);
                dispatch(alertActions.success(response?.data?.status?.message));
              } else {
                setLoader("isempty");
                dispatch(alertActions.error(response?.data?.status?.message));
              }
            })
            .catch((err) => console.log(err));
        } else {
          if (selectedFile1 === "") {
            setLoader("loading");
            StartrentService.makePaymentOnline(
              carddate,
              paymentType,
              paymentdetails,
              advanceamount,
              transactionid,
              paymentImage,
              ref_number,
              bookidpay,
              Number(discount)
            )
              .then((response) => {
                if (response?.data?.status?.code === 0) {
                  setDisplayMain(0);
                  setadvanceamount("");
                  setbalanceamount("");
                  setCardDate("");
                  setpaymentType("");
                  setPaymentImage("");
                  setSelectedFile1("");
                  setpaymentdetails("");
                  setLoader("isfull");
                  setSubmitted(false);
                  dispatch(
                    alertActions.success(response?.data?.status?.message)
                  );
                } else {
                  setLoader("isempty");
                  dispatch(alertActions.error(response?.data?.status?.message));
                }
              })
              .catch((err) => console.log(err));
          } else {
            setLoader("loading");
            StartrentService.makePaymentOnline(
              carddate,
              paymentType,
              paymentdetails,
              advanceamount,
              transactionid,
              selectedFile1,
              ref_number,
              bookidpay,
              Number(discount)
            )
              .then((response) => {
                if (response?.data?.status?.code === 0) {
                  setDisplayMain(0);
                  setadvanceamount("");
                  setbalanceamount("");
                  setCardDate("");
                  setpaymentType("");
                  setPaymentImage("");
                  setSelectedFile1("");
                  setpaymentdetails("");
                  setLoader("isfull");
                  setSubmitted(false);
                  dispatch(
                    alertActions.success(response?.data?.status?.message)
                  );
                } else {
                  setLoader("isempty");
                  dispatch(alertActions.error(response?.data?.status?.message));
                }
              })
              .catch((err) => console.log(err));
            // }
          }
        }
      }
    }
  };

  return {
    loader,
    NoOfDays,
    RentalCharges,
    HelmetCharges,
    LuggageCharges,
    GrandTotal,
    gst,
    TotalRental,
    Damages,
    ExKmCharges,
    OtherCharges,
    submitted,
    carddate,
    advanceamount,
    paymentType,
    balanceamount,
    paymentdetails,
    paymentamount,
    cardno,
    cvv,
    expiry,
    holdername,
    cardType,
    transactionid,
    payment_dueDate,
    fileName1,
    paymentImage,
    selectedFile1,
    displayMain,
    paymentRes,
    acceptCheck,
    gstRate,
    paymentArray,
    discount,
    handleChange,
    changeHandling,
    creditPayment,
    onSelectFile1,
    handleCardDate,
    makepayment,
    handleGst,
    goBack,
    navigateToAgreement,
    changeAcceptCheckboxHandler,
    handlePaymentDueDate,
  };
};
export default PaymentDetailsHooks;
