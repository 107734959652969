import { Calender } from "../../../../../../../../assets/images";
import useBookingPersonalInfoHoook from "./useBookingPersonalInfoHoook";
import { useLocation } from "react-router";
import { Loading } from "../../../../../../../../assets/images";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const BookingAddPersonalInfo = ({ displayData, tab, bookid }) => {
  const location = useLocation();
  const {
    loader,
    date_of_birth,
    name,
    email,
    mblno,
    altno,
    submitted,
    ContinueInfo,
    validname,
    validemail,
    validmblno,
    validaltno,
    validdate_of_birth,
    handleChange,
    handleChange1,
  } = useBookingPersonalInfoHoook(location, displayData, bookid);

  var names = validname();
  var emails = validemail();
  var mbls = validmblno();
  var altnum = validaltno();
  var datebirth = validdate_of_birth();
  /**
   * age calculation
   */
  let age;
  let ageDiff = "";
  if (date_of_birth !== "" && date_of_birth !== undefined) {
    const today = new Date();
    const dateParts = date_of_birth.split("/");

    const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

    const diffTime = Math.abs(date - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    ageDiff = Math.round((diffDays / 365) * 10) / 10;
    age = ageDiff > 1 ? ageDiff + " " + "years" : ageDiff + " " + " year";
  }

  const addPersonal = () => {
    return (
      <div className="E_comp_details flt">
        <div className="main_boxShadow flt">
          <div className="Company_name flt">
            <div className="comp_name_details flt">
              Add Personal Information
            </div>
          </div>
          {loader === "loading" && (
            <div className="main_load ">
              <img src={Loading} alt="loader" />
            </div>
          )}
          {(loader === "isfull" ||
            loader === "isempty" ||
            loader === "notset") && (
            <>
              <div className="main_company flt">
                <div className="main_row flt">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">Full Name *</label>
                          <input
                            type="text"
                            placeholder="Name"
                            name="name"
                            id="email"
                            onChange={handleChange}
                            value={name}
                            className={
                              submitted && names === false ? " is-invalid" : ""
                            }
                          />
                        </div>
                        {submitted && names === false && (
                          <div className="invalid-feedback">
                            Name is required
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">Email ID </label>
                          <input
                            type="text"
                            placeholder="Email ID"
                            name="email"
                            id="email"
                            onChange={handleChange}
                            value={email}
                            className={
                              submitted && emails === false ? " is-invalid" : ""
                            }
                          />
                        </div>
                        {/* {submitted && emails === false && (
                          <div className="invalid-feedback">
                            Email ID is required
                          </div>
                        )} */}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">Mobile Number *</label>
                          <input
                            type="number"
                            placeholder="Mobile Number"
                            name="mblno"
                            id="email"
                            onChange={handleChange}
                            value={mblno}
                            className={
                              submitted && mbls === false ? " is-invalid" : ""
                            }
                          />
                        </div>
                        {submitted && mbls === false && (
                          <div className="invalid-feedback">
                            Mobile Number is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="company_name flt">
                        <div className="form-group flt">
                          <label className="labelName">
                            Alternative Mobile Number
                          </label>
                          <input
                            type="number"
                            placeholder="Alternative Mobile Number"
                            name="altno"
                            id="email"
                            onChange={handleChange}
                            value={altno}
                            className={
                              submitted && altnum === false ? " is-invalid" : ""
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className=" company_name baddpersonal flt">
                        <div className="form-group flt">
                          <label className="labelName">Date of Birth </label>

                          <Datetime
                            dateFormat="DD/MM/YYYY"
                            onChange={handleChange1}
                            value={date_of_birth}
                            closeOnSelect={true}
                            timeFormat={false}
                            inputProps={{
                              placeholder: "Select date",
                              disabled: false,
                              readOnly: true,
                              onKeyDown: (e) => e.preventDefault(),
                            }}
                          />
                          <div className="cal_img">
                            <img src={Calender} alt="calender" />
                          </div>
                        </div>
                        {/* {submitted && datebirth === false && (
                          <div className="invalid-feedback">
                            Date of Birth is required
                          </div>
                        )} */}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className=" company_name flt">
                        <div className="agedata flt">
                          <span>Age : {age ? age : "0"}</span>
                        </div>
                      </div>
                    </div>

                    <div className="main_ownbutton flt">
                      <div className="own_button flt">
                        <button onClick={ContinueInfo} className="submitbutton">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };
  return <div>{addPersonal()}</div>;
};
export default BookingAddPersonalInfo;
