import { rental } from "../../../assets/images";
import "./TopBar.css";
import useTopBarHook from "./useTopBarHook";
import { useSelector } from "react-redux";

function TopBar() {
  const { handleLogout } = useTopBarHook();
  const userName = useSelector((state) => state?.userData?.user?.userName);
  return (
    <div className="top_cont flt">
      <img src={rental} alt="rental" />

      <div className="main_icons">
        <div className="icons">
          {/* <i className="fas fa-bell"></i>
          <i className="fas fa-cog"></i> */}

          <button type="button">
            <div className="name_user">Hello, {userName}</div>
            {/* <div className="user_name">CR</div> */}
          </button>
          <button className="logout" type="button" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
export default TopBar;
