import Menu from "../../../../_common/menu/Menu";
import TopBar from "../../../../_common/topbar/TopBar";
import "../Location.css";
import { Location1 } from "../../../../../assets/images";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import LeftSidebar from "../../../../_common/sidebar/LeftSidebar";
import useLocationListHook from "./useLocationListHook";
import { Loading } from "../../../../../assets/images";
import { withTranslation } from "react-i18next";

const Location = (props) => {
  const { t } = props;
  const {
    totalPages,
    loader,
    columns,
    listTable,
    page,
    handlePageClick,
    navigateToLocationDetails,
    handleEditLocation,
  } = useLocationListHook();

  return (
    <div className="main_container flt">
      <TopBar />
      <Menu />
      <LeftSidebar />
      <div className="chat_right1 flt">
        <div className="header_location flt">
          <div className="main_location flt">
            <div className="location flt">
              <img src={Location1} alt="location" />
              {t("LOCATION")}
            </div>

            <div className="fliter_button flt">
              <button onClick={navigateToLocationDetails}>
                <i class="fas fa-map-marker-alt"></i>
                {t("Add Location")}
              </button>
            </div>
          </div>

          <div className="E_comp_details flt">
            <div className="main_boxShadow flt">
              <div className="main_tablehead flt">
                {loader === "loading" || loader === "notset" ? (
                  <div className="main_load ">
                    <img src={Loading} alt="loader" />
                  </div>
                ) : (
                  <table className="main_table1 flt ">
                    <DataTable
                      columns={columns}
                      data={listTable || []}
                      onRowClicked={(row) => handleEditLocation(row)}
                    />
                    {listTable.length > 0 && (
                      <ReactPaginate
                        nextLabel="&rarr;"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={totalPages}
                        previousLabel="&larr;"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        forcePage={page - 1}
                      />
                    )}
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation() (Location);
