import { agreements } from "../../../../assets/images";
import Menu from "../../../_common/menu/Menu";
import TopBar from "../../../_common/topbar/TopBar";
import { useLocation, useNavigate } from "react-router";
import "./VehicleExpenses.css";
import { commaValidation } from "../../../../utility";
import moment from "moment";
import { withTranslation } from "react-i18next";

const VehicleExpenseDetails = (props) => {
  const { t } = props;
  const location = useLocation();

  const navigate = useNavigate();
  const moveToExpense = () => {
    navigate("/Report", { state: { val: 3 } });
  };
  const agreementtiltle = () => {
    return (
      <div className="main_location flt">
        <div className="location flt">
          <img onClick={moveToExpense} src={agreements} alt="agreements" />
          {t("Vehicle Expense")}
        </div>
      </div>
    );
  };
  const tableView = () => {
    return (
      <div className="dataView_main flt">
        <div className="dataView_title flt"></div>
        <div className="data title flt">
          <div className="sub_details flt">
            {location?.state?.row?.oilExpense &&
              location?.state?.row?.oilExpense?.length > 0 && (
                <ul>
                  <li>
                    <b>{t("Oil Expense")} </b>
                  </li>
                  <li>&nbsp;</li>
                </ul>
              )}
            {location?.state?.row?.oilExpense &&
              location?.state?.row?.oilExpense.map((res) => (
                <ul className="looparea flt">
                  <li>
                    {t("Amount")} <span>:</span>
                  </li>
                  <li>
                    {res?.Oil_service_expense?.Amount
                      ? `₹${commaValidation(res?.Oil_service_expense?.Amount)}`
                      : "N/A"}
                  </li>
                  <li>
                    {t("Description")} <span>:</span>
                  </li>
                  <li>
                    {res?.Oil_service_expense.Description
                      ? res?.Oil_service_expense.Description
                      : "N/A"}
                  </li>
                  <li>
                    {t("Garage")} <span>:</span>
                  </li>
                  <li>
                    {res?.Oil_service_expense.Garage
                      ? res?.Oil_service_expense.Garage
                      : "N/A"}
                  </li>
                  <li>
                    {t("InvoiceNumber")} <span>:</span>
                  </li>
                  <li>
                    {res?.Oil_service_expense.InvoiceNumber
                      ? res?.Oil_service_expense.InvoiceNumber
                      : "N/A"}
                  </li>
                  <li>
                    {t("Km_Reading")} <span>:</span>
                  </li>
                  <li>
                    {res?.Oil_service_expense.Km_Reading
                      ? res?.Oil_service_expense.Km_Reading
                      : "N/A"}
                  </li>
                  <li>
                    {t("Oil_service_Date")} <span>:</span>
                  </li>
                  <li>
                    {res?.Oil_service_expense.Oil_service_Date
                      ? moment(
                        res?.Oil_service_expense.Oil_service_Date
                      ).format("DD/MM/YYYY, h:mm:ss a")
                      : "N/A"}
                  </li>
                  <li>
                    {t("Oil_service_Next_Service_Date")} <span>:</span>
                  </li>
                  <li>
                    {res?.Oil_service_expense?.Oil_service_Next_Service_Date
                      ? moment(
                        res?.Oil_service_expense
                          ?.Oil_service_Next_Service_Date
                      ).format("DD/MM/YYYY, h:mm:ss a")
                      : "N/A"}
                  </li>
                  <li>
                    {t("Payment_Method ")}<span>:</span>
                  </li>
                  <li>
                    {res?.Oil_service_expense?.Payment_Method
                      ? res?.Oil_service_expense?.Payment_Method
                      : "N/A"}
                  </li>
                  <div className="divider flt"></div>
                </ul>
              ))}
          </div>
          <div className="sub_details flt">
            {location?.state?.row?.maintExpense &&
              location?.state?.row?.maintExpense?.length > 0 && (
                <ul>
                  <li>
                    <b>{t("Maintenance Expense")} </b>
                  </li>
                  <li>&nbsp;</li>
                </ul>
              )}
            {location?.state?.row?.maintExpense &&
              location?.state?.row?.maintExpense.map((res) => (
                <ul className="looparea flt">
                  <li>
                    {t("Maintainence_Expense_Amount")} <span>:</span>
                  </li>
                  <li>
                    {res?.Maintainence_Expense?.Maintainence_Expense_Amount
                      ? `₹${commaValidation(
                        res?.Maintainence_Expense?.Maintainence_Expense_Amount
                      )}`
                      : "N/A"}
                  </li>
                  <li>
                    {t("Maintainence_Expense_Date")} <span>:</span>
                  </li>
                  <li>
                    {res?.Maintainence_Expense.Maintainence_Expense_Date
                      ? moment(
                        res?.Maintainence_Expense.Maintainence_Expense_Date
                      ).format("DD/MM/YYYY, h:mm:ss a")
                      : "N/A"}
                  </li>
                  <li>
                    {t("Maintainence_Expense_Description")} <span>:</span>
                  </li>
                  <li>
                    {res?.Maintainence_Expense.Maintainence_Expense_Description
                      ? res?.Maintainence_Expense
                        .Maintainence_Expense_Description
                      : "N/A"}
                  </li>
                  <li>
                    {t("Maintainence_Expense_Garage ")}<span>:</span>
                  </li>
                  <li>
                    {res?.Maintainence_Expense.Maintainence_Expense_Garage
                      ? res?.Maintainence_Expense.Maintainence_Expense_Garage
                      : "N/A"}
                  </li>
                  <li>
                    {t("Maintainence_Expense_Invoice")} <span>:</span>
                  </li>
                  <li>
                    {res?.Maintainence_Expense.Maintainence_Expense_Invoice
                      ? res?.Maintainence_Expense.Maintainence_Expense_Invoice
                      : "N/A"}
                  </li>
                  <li>
                    {t("Maintainence_Expense_Km_Reading")} <span>:</span>
                  </li>
                  <li>
                    {res?.Maintainence_Expense.Maintainence_Expense_Km_Reading
                      ? res?.Maintainence_Expense
                        .Maintainence_Expense_Km_Reading
                      : "N/A"}
                  </li>
                  <li>
                    {t("Maintainence_Expense_Payment_Method")} <span>:</span>
                  </li>
                  <li>
                    {res?.Maintainence_Expense
                      ?.Maintainence_Expense_Payment_Method
                      ? res?.Maintainence_Expense
                        ?.Maintainence_Expense_Payment_Method
                      : "N/A"}
                  </li>
                  <li>
                    {t("Maintainence_Expense_Service_Type")} <span>:</span>
                  </li>
                  <li>
                    {res?.Maintainence_Expense
                      ?.Maintainence_Expense_Service_Type
                      ? res?.Maintainence_Expense
                        ?.Maintainence_Expense_Service_Type
                      : "N/A"}
                  </li>
                  <div className="divider flt"></div>
                </ul>
              ))}
          </div>
          <div className="sub_details sub_details1 flt">
            {location?.state?.row?.maintExpense?.length <= 0 &&
              location?.state?.row?.oilExpense?.length <= 0 && (
                <b>
                  <span>{t("No Data Found")}</span>{" "}
                </b>
              )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <TopBar />
      <Menu />

      {agreementtiltle()}
      {tableView()}
    </>
  );
};
export default withTranslation() (VehicleExpenseDetails);
